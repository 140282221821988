import React from "react";
import { connect } from "react-redux";
import { Link, generatePath } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { getPlans } from "actions/plan/actions";

import Routes from "routes";
import Spinner from "components/shared/spinner";

const WelcomeAdvertise = (props) => {
  const user = props.payload;

  const welcomeMessage = (user) => {
    if (user) {
      const companyName = user.company ? user.company.name : "Plane Mover";
      const plan = user.company ? user.company.plan : user.plan;

      switch (user.role.value) {
        case "company-admin":
          return welcomeCompanyAdmin(plan, companyName);
        case "sales-rep":
        case "sales-rep-view":
          return welcomeSalesRep(companyName);
        default:
          return welcomeAdvertiser(plan);
      }
    }

    return <Spinner />;
  };

  const welcomeAdvertiser = (plan) => {
    return (
      <div>
        <div className="account-complete">
          <p className="sub-header">Your account is complete!</p>
          <p>
            <span>You are now a </span>
            <span className="selected-plan">
              <span className="plan-name">{plan.title}</span>
              <span> ($</span>
              <span>{plan.price}</span>
              <span>/mo)</span>
            </span>
            <span> member of Plane Mover.</span>
          </p>
        </div>
        <div className="create-first-listing">
          <p>Are you ready to create your first listing?</p>
          <div className="listing-buttons">
            <Link
              className="btn btn-red"
              to={generatePath(Routes.forSaleEdit, {
                listing: null,
                step: null,
              })}
            >
              Aircraft for Sale
            </Link>
            <Link
              className="btn btn-red"
              to={generatePath(Routes.wantedEdit, {
                listing: null,
                step: null,
              })}
            >
              Aircraft Wanted
            </Link>
            <Link
              className="btn btn-red"
              to={generatePath(Routes.servicesEdit, {
                listing: null,
                step: null,
              })}
            >
              Services
            </Link>
          </div>
        </div>
        <div className="start-browsing">
          <p>Want to create a listing later?</p>
          <Link className="btn btn-green" to={Routes.home}>
            Start Browsing
          </Link>
        </div>
      </div>
    );
  };

  const welcomeSalesRep = (companyName) => {
    return (
      <div>
        <div className="account-complete">
          <p className="sub-header">
            Your account has been submitted for verification
          </p>
          <p>
            <span>
              You've requested to join {companyName}. The Company Administrator
              has been notified of your request, and your account is pending
              activation. Once activated, you'll be able to login and manage
              your listing.
            </span>
          </p>
        </div>
        <div className="start-browsing">
          <p>While you are waiting, browse some listings!</p>
          <Link className="btn btn-green" to={Routes.home}>
            Start Browsing
          </Link>
        </div>
      </div>
    );
  };

  const welcomeCompanyAdmin = (plan, companyName) => {
    return (
      <div>
        <div className="account-complete">
          <p className="sub-header">Your account is complete!</p>
          <p>
            <span>{companyName} is now a </span>
            <span className="selected-plan">
              <span className="plan-name">{plan.title}</span>
              <span> ($</span>
              <span>{plan.price}</span>
              <span>/mo)</span>
            </span>
            <span> member of Plane Mover.</span>
          </p>
        </div>
        <div className="create-first-listing">
          <p>Are you ready to create your first listing?</p>
          <div className="listing-buttons">
            <Link
              className="btn btn-red"
              to={generatePath(Routes.forSaleEdit, {
                listing: null,
                step: null,
              })}
            >
              Aircraft for Sale
            </Link>
            <Link
              className="btn btn-red"
              to={generatePath(Routes.wantedEdit, {
                listing: null,
                step: null,
              })}
            >
              Aircraft Wanted
            </Link>
            <Link
              className="btn btn-red"
              to={generatePath(Routes.servicesEdit, {
                listing: null,
                step: null,
              })}
            >
              Services
            </Link>
          </div>
        </div>
        <div className="start-browsing">
          <p>Want to create a listing later?</p>
          <Link className="btn btn-green" to={Routes.home}>
            Start Browsing
          </Link>
        </div>
      </div>
    );
  };

  return (
    <Row className="welcome-advertise">
      <Col sm={12}>
        <h2 className="page-title">
          Welcome to <strong>Plane Mover</strong>
        </h2>
        {welcomeMessage(user)}
      </Col>
    </Row>
  );
};

const keyStateToProps = (state) => {
  return {
    user: state.user,
    plans: state.plans,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    getPlans: () => dispatch(getPlans()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(WelcomeAdvertise);
