import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Form, Button, Row, Col } from "react-bootstrap";

import { Formik } from "formik";
import { object, string, ref } from "yup";

import { openLogin } from "actions/modal/actions";

const schema = object().shape({
  email: string()
    .required("Email Address is required")
    .email("Invalid email address"),
  confirmEmail: string()
    .required("Confirm Email is required")
    .oneOf([ref("email")], "Both email need to be the same"),
});

class EmailForm extends Component {
  handleSubmit(email, blitzes) {
    const userInfo = {
      email,
      blitzes,
    };
    this.props.changeUserInfo(userInfo);
    this.props.onChangeNextStep();
  }

  render() {
    const { userInfo } = this.props;
    return (
      <Formik
        validationSchema={schema}
        onSubmit={({ email, blitzes }) => this.handleSubmit(email, blitzes)}
        initialValues={{
          email: userInfo.email,
          confirmEmail: userInfo.email,
          blitzes: false,
        }}
      >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
          <Form className="account-form" onSubmit={handleSubmit}>
            <Form.Group controlId="email">
              <Form.Label className="account-form-label">
                Email Address
              </Form.Label>
              <Form.Control
                className="account-form-input"
                type="text"
                placeholder="johndoe@email.com"
                name="email"
                value={values.email}
                onChange={handleChange}
                isValid={touched.email && !errors.email}
                isInvalid={touched.email && !!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="confirmEmail">
              <Form.Label className="account-form-label">
                Confirm Email
              </Form.Label>
              <Form.Control
                autoComplete="off"
                autoCorrect="off"
                spellCheck="off"
                className="account-form-input"
                type="text"
                placeholder="johndoe@email.com"
                name="confirmEmail"
                value={values.confirmEmail}
                onChange={handleChange}
                isValid={touched.confirmEmail && !errors.confirmEmail}
                isInvalid={touched.email && !!errors.confirmEmail}
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirmEmail}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group
              controlId="checkbox"
              className="d-flex justify-content-center"
            >
              <Form.Check
                name="blitzes"
                type="checkbox"
                value={values.blitzes}
                onChange={handleChange}
                label="Yes! Send me Plane Mover email Blitzes. Trust us, you’ll want them."
              />
            </Form.Group>

            <Row>
              <Col xs={12} sm={4}></Col>
              <Col xs={12} sm={4} className="d-flex justify-content-center">
                <Button className="btn-green" type="submit">
                  GET STARTED
                </Button>
              </Col>
              <Col
                xs={12}
                sm={4}
                className="d-flex justify-content-center justify-content-sm-end mt-3 mt-sm-0"
              >
                <div>
                  <span>Already have an account? </span>
                  <a
                    href="#signin"
                    onClick={() => this.props.openLogin()}
                    className="account-modal-link"
                  >
                    Log In
                  </a>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

EmailForm.propTypes = {
  userInfo: PropTypes.shape({
    email: PropTypes.string.isRequired,
  }),
  changeUserInfo: PropTypes.func,
  onChangeNextStep: PropTypes.func,
  openLogin: PropTypes.func,
};

const keyDispatchToProps = (dispatch) => {
  return {
    openLogin: () => dispatch(openLogin()),
  };
};

export default connect(null, keyDispatchToProps)(EmailForm);
