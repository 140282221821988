import React from "react";
import Button from "react-bootstrap/Button";

export default class SubmittedListingsPage extends React.Component {
  goToPromotion() {
    this.props.history.push("/admin/promotion");
  }
  goToSchedule() {
    this.props.history.push("/admin/promotion/6");
  }
  render() {
    return (
      <section
        id="app-page-home"
        className="app-content app-main submitted-listing"
      >
        <div className="container">
          <div className="row">
            <div className="col">
              <div className={"title mb-2 submitted-title"}>
                Promote <strong>Listings</strong>
              </div>
              <div className={"submited-details"}>
                This promotion request has been submitted. See details below.
              </div>

              <div className="container bg-light p-4 mt-5 w-75 mx-0 submitted-container">
                <div className={"mb-3 submitted-content"}>
                  <h6>
                    REQUESTED: Aircraft For Sale -
                    <span className="text-green"> Falcon 2000S - #730</span>
                  </h6>
                </div>
                <div className={"submitted-content"}>
                  <div className={"mb-1"}>Items selected:</div>
                  <div className={"mb-4"}>
                    1. Feature on the homepage - March 9 to 15, 2019
                  </div>

                  <div className={"mb-4"}>
                    2. Feature your listing in search results - March 9 to 15,
                    2019
                  </div>

                  <div>
                    3. Sponsor in another listing type - March 9 to 15, 2019
                  </div>
                  <div className={"text-green"}>
                    &nbsp;&nbsp;&nbsp;&nbsp;Aircraft For Sale - Jets
                  </div>

                  <div className={"my-4"}>4. Buy a Blitz - March 9, 2019</div>

                  <div>5. Promote on Social Media - March 9, 2019</div>
                </div>
              </div>
            </div>
          </div>

          <div className={"mt-5 submit-button-group"}>
            <Button
              className={"submit-btn-gray w-40 mr-4"}
              onClick={() => this.goToPromotion()}
            >
              NEW PROMOTION
            </Button>
            <Button
              className={"submit-btn-gray "}
              onClick={() => this.goToSchedule()}
            >
              GO TO SCHEDULE
            </Button>
          </div>
        </div>
      </section>
    );
  }
}
