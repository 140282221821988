import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";
import axios from "axios";

import Routes, { createTitle } from "routes";

import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import Spinner from "components/shared/spinner";

import { Formik } from "formik";

import { getCompanies } from "actions/companies/actions";
import { parseAxiosError } from "utils/errors";

const yup = require("yup");

function AddUser(props) {
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    document.title = createTitle(`Add New User`, Routes);

    getCompanies();

    axios.get("/masterData?keys=accountRole").then((response) => {
      const masterData = response.data.rows;
      setRoles(masterData.filter((data) => data.key === "accountRole"));
      return true;
    });
  }, []);

  const handleRegistration = (values, setSubmitting) => {
    setSubmitting(true);

    axios
      .post("/accounts/create", values)
      .then((response) => {
        // Successfully created User. Redirect to edit page
        props.history.push(
          generatePath(Routes.accountSettings, {
            email: response.data.account.email,
            tab: undefined,
          })
        );
      })
      .catch((err) => {
        console.error("Error Creating Account");
        console.error(err);
        setError(parseAxiosError(err));
        setSubmitting(false);
      });
  };

  const availableRoles = (company) => {
    if (company) {
      // Only show roles available to Company users
      return roles.filter((r) =>
        ["company-admin", "sales-rep", "sales-rep-view"].includes(r.value)
      );
    }

    // Only show non-company roles
    return roles.filter((r) => ["advertiser"].includes(r.value));
  };

  return (
    <section
      id="app-sitesettings"
      className="app-content app-main account-settings"
    >
      <Container>
        <Row mb={4}>
          <Col xs>
            <div className="title">
              ADD <span className="bold">NEW USER</span>
            </div>
          </Col>
        </Row>
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={yup.object().shape({
            name: yup.string().required("Users must have a name"),
            email: yup
              .string()
              .required("Email is required")
              .email("Invalid email"),
            password: yup
              .string()
              .required("Password is required")
              .min(8, "Password must be at least 8 characters"),
            role: yup.string().required("A User Role must be selected"),
          })}
          onSubmit={(vals, { setSubmitting }) =>
            handleRegistration(vals, setSubmitting)
          }
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form
              onSubmit={isSubmitting ? (e) => e.preventDefault() : handleSubmit}
              className="account-form add-new"
              disabled
            >
              {isSubmitting && (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}
              {error && <Alert variant="danger">{error}</Alert>}
              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <Form.Label className="account-form-label">
                    Full Name
                  </Form.Label>
                  <Form.Control
                    className="account-form-input"
                    type="text"
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="John J. Doe, III"
                  />
                  {touched.name && errors.name && (
                    <Form.Text className="account-form-error">
                      {errors.name}
                    </Form.Text>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} md={6}>
                  <Form.Label className="account-form-label">
                    Email Address
                  </Form.Label>
                  <Form.Control
                    className="account-form-input"
                    type="text"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Johndoe@email.com"
                  />
                  {touched.email && errors.email && (
                    <Form.Text className="account-form-error">
                      {errors.email}
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group as={Col} xs={12} md={6}>
                  <Form.Label className="account-form-label">
                    Password
                  </Form.Label>
                  <Form.Control
                    className="account-form-input"
                    type="password"
                    id="password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Enter your password"
                  />
                  {touched.password && errors.password && (
                    <Form.Text className="account-form-error">
                      {errors.password}
                    </Form.Text>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} md={6}>
                  <Form.Label className="account-form-label">
                    Company
                  </Form.Label>
                  <Form.Control
                    as="select"
                    id="company"
                    name="company"
                    value={values.company}
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue("role", "");
                    }}
                    onBlur={handleBlur}
                  >
                    <option></option>
                    {props.companies
                      //.sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((opt, i) => (
                        <option key={`role-${opt.slug}`} value={opt.slug}>
                          {opt.name}
                        </option>
                      ))}
                  </Form.Control>
                  {touched.company && errors.company && (
                    <Form.Text className="account-form-error">
                      {errors.company}
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group as={Col} xs={12} md={6}>
                  <Form.Label className="account-form-label">Role</Form.Label>
                  <Form.Control
                    as="select"
                    id="role"
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option></option>
                    {availableRoles(values.company)
                      //.sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((opt, i) => (
                        <option key={`role-${i}`} value={opt.value}>
                          {opt.title}
                        </option>
                      ))}
                  </Form.Control>
                  {touched.role && errors.role && (
                    <Form.Text className="account-form-error">
                      {errors.role}
                    </Form.Text>
                  )}
                </Form.Group>
              </Form.Row>

              <div className="d-md-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between justify-content-md-end mt-3 mt-md-0">
                  <Button
                    className="btn btn-green"
                    type="submit"
                    disabled={
                      !isValid ||
                      (Object.keys(touched).length === 0 &&
                        touched.constructor === Object)
                    }
                  >
                    Add User
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </section>
  );
}

const keyStateToProps = (state) => {
  return {
    user: state.user,
    companies: state.companies,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    getCompanies: dispatch(getCompanies()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(AddUser);
