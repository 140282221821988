import React, { useState } from "react";
import { connect } from "react-redux";

import { Modal, Row, Col, Alert, Button, Form } from "react-bootstrap";

import Spinner from "components/shared/spinner";
import { closeForgotPassword } from "actions/modal/actions";
import { forgotPassword } from "actions/user/actions";

import { Formik } from "formik";
import { object, string, ref } from "yup";

import { ReactComponent as CloseIcon } from "images/icons/close-circle.svg";

import { parseAxiosError } from "utils/errors";

const schema = object().shape({
  email: string()
    .required("Email Address is required")
    .email("Invalid email address"),
  confirmEmail: string()
    .required("Confirm Email is required")
    .oneOf([ref("email")], "Both email need to be the same"),
});

const ForgotPassword = (props) => {
  const { show, handleClose, forgotPassword } = props;

  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (email) => {
    setLoading(true);

    forgotPassword(email)
      .then((res) => {
        setSuccess(res.message);
      })
      .catch((err) => {
        setError(parseAxiosError(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const content = (error, success, loading) => {
    if (loading) {
      return <Spinner />;
    }

    if (error) {
      return <Alert variant="danger">{error}</Alert>;
    }

    if (success) {
      return (
        <>
          <Alert variant="success">{success}</Alert>
          <Row>
            <Col xs={12} className="d-flex justify-content-center">
              <Button className="btn-green" onClick={handleClose}>
                Close
              </Button>
            </Col>
          </Row>
        </>
      );
    }

    return (
      <div>
        <div className="login-footer-text text-center">
          Enter your email address to receive a link that will allow you to
          reset your password.
        </div>
        <Formik
          validationSchema={schema}
          onSubmit={({ email }) => handleSubmit(email)}
          initialValues={{
            email: "",
            confirmEmail: "",
          }}
        >
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form className="account-form" onSubmit={handleSubmit}>
              <Form.Group controlId="email">
                <Form.Label className="account-form-label">
                  Email Address
                </Form.Label>
                <Form.Control
                  className="account-form-input"
                  type="text"
                  placeholder="johndoe@email.com"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  isValid={touched.email && !errors.email}
                  isInvalid={touched.email && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="confirmEmail">
                <Form.Label className="account-form-label">
                  Confirm Email
                </Form.Label>
                <Form.Control
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="off"
                  className="account-form-input"
                  type="text"
                  placeholder="johndoe@email.com"
                  name="confirmEmail"
                  value={values.confirmEmail}
                  onChange={handleChange}
                  isValid={touched.confirmEmail && !errors.confirmEmail}
                  isInvalid={touched.email && !!errors.confirmEmail}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmEmail}
                </Form.Control.Feedback>
              </Form.Group>

              <Row>
                <Col xs={12} className="d-flex justify-content-center">
                  <Button className="btn-green" type="submit">
                    SEND VERIFICATION LINK
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <div className="account-modal signup">
        <button className="btn account-modal-close" onClick={handleClose}>
          <CloseIcon />
        </button>

        <div className="account-modal-title">
          FORGOT YOUR <span className="bold">PASSWORD?</span>
        </div>
        {content(error, success, loading)}
      </div>
    </Modal>
  );
};

const keyStateToProps = (state) => {
  return {
    show: state.modal.openForgotPassword,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    handleClose: () => dispatch(closeForgotPassword()),
    forgotPassword: (email) => dispatch(forgotPassword(email)),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(ForgotPassword);
