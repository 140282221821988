import React, { Component } from "react";
import { Route, Switch, Link, generatePath } from "react-router-dom";

import Plans from "./sections/plans";
import SaleHighlights from "./sections/sale-highlights";
import WantedHighlights from "./sections/wanted-highlights";
import MakesModels from "./sections/makes-models";
import Advertising from "./sections/advertising";
import Policies from "./sections/policies";

import Routes from "routes";

class SiteSettings extends Component {
  constructor(props) {
    super(props);

    this.sections = [
      {
        isDefault: true,
        slug: "subscriptions",
        title: "Subscription Plans",
        component: Plans
      },
      {
        slug: "sale-highlights",
        title: "ACFS Highlights",
        component: SaleHighlights
      },
      {
        slug: "wanted-highlights",
        title: "ACW Highlights",
        component: WantedHighlights
      },
      {
        slug: "makes-models",
        title: "Makes & Models",
        component: MakesModels
      },
      {
        slug: "advertising",
        title: "Advertising Page",
        component: Advertising
      },
      {
        slug: "policies",
        title: "Policies",
        component: Policies
      }
    ];
  }
  //const list = ["edit subscriptions", "EDIT ACFS HIGHLIGHTS", "EDIT ACW HIGHLIGHTS", "ADD NEW AIRCRAFT", "EDIT ADVERTISING PAGE", "EDIT POLICIES"]

  getTabClass = section => {
    if (
      this.props.match.params.section === section ||
      (!this.props.match.params.section && section === "subscriptions")
    ) {
      return "menu-item active";
    }
    return "menu-item";
  };

  SiteSettingsNav = active => {
    return (
      <ul className="tabbed-nav site-settings__nav site-settings-nav">
        {this.sections.map((section, i) => (
          <li
            key={`site-setting-nav-${section.slug}`}
            className={this.getTabClass(section.slug)}
          >
            <Link
              to={generatePath(Routes.siteSettings, {
                section: section.slug
              })}
            >
              <span className="item-num">{i}</span>
              <span className="item-name">{section.title}</span>
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  renderPage() {
    // const { activeNav } = this.state;
    // switch (activeNav) {
    //   case 0:
    //     return <Subscription />;
    //   case 1:
    //     return <ACFS />;
    //   case 2:
    //     return <ACW />;
    //   case 3:
    //     return <AireCraft />;
    //   case 4:
    //     return <Advertising />;
    //   case 5:
    //     return <Policies />;
    //   default:
    //     return <Subscription />;
    // }
  }

  render() {
    return (
      <section
        id="app-sitesettings"
        className="app-content app-main site-settings"
      >
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col-12 col-md">
              <div className="site-settings__title">
                SITE <span>SETTINGS</span>
              </div>
            </div>
            <div className="col-12 col-md-8 col-lg-9">
              {this.SiteSettingsNav()}
            </div>
          </div>
          <Switch>
            {this.sections.slice().reverse().map((section, i) => (
              <Route
                key={`site-setting-route-${section.slug}`}
                exact={!section.isDefault}
                path={generatePath(Routes.siteSettings, {
                  section: section.isDefault ? null : section.slug
                })}
                component={section.component}
              />
            ))}
          </Switch>
        </div>
      </section>
    );
  }
}

export default SiteSettings;
