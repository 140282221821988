export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";

export const GET_USER = "GET_USER";
export const SET_USER = "SET_USER";

export const SIGN_UP = "SIGN_UP";
export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const CHECK_PASSWORD_TOKEN = "CHECK_PASSWORD_TOKEN";
export const RESET_PASSWORD = "RESET_PASSWORD";

export const VERIFICATION = "VERIFICATION";
export const FINALIZE_ACCOUNT = "FINALIZE_ACCOUNT";

export const UPDATE_PASSWORD = "UPDATE_PASSWORD";
export const UPDATE_PROFILE = "UPDATE_PROFILE";
export const UPDATE_AVATAR = "UPDATE_AVATAR";

export const ADD_ACCOUNT_PAYMENT = "ADD_ACCOUNT_PAYMENT";
export const DELETE_ACCOUNT_PAYMENT = "DELETE_ACCOUNT_PAYMENT";

export const UPGRADE_ACCOUNT_PLAN = "UPGRADE_ACCOUNT_PLAN";
