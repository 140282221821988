const CARDS = {
  visa: "^4",
  amex: "^(34|37)",
  mastercard: "^5[1-5]",
  discover: "^6011",
  //unionpay: '^62',
  //troy: '^9792',
  //diners: '^(30[0-5]|36)'
};

const cardType = (cardNumber) => {
  const number = cardNumber;
  let re;
  for (const [card, pattern] of Object.entries(CARDS)) {
    re = new RegExp(pattern);
    if (number.match(re) != null) {
      return card;
    }
  }

  return "visa"; // default type
};
export default cardType;
