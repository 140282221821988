import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";

import Routes from "routes";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, generatePath } from "react-router-dom";
import classNames from "classnames";

import { ListingLinkAndTitle, UserLink } from "utils/links";

import { parseAxiosError } from "utils/errors";
import { getDashboard } from "actions/dashboard/actions";

import Spinner from "components/shared/spinner";
import { getNotifications } from "actions/notification/actions";

import { ReactComponent as UserIcon } from "images/icons/user.svg";
import { ReactComponent as MailIcon } from "images/icons/mail.svg";
import { ReactComponent as FavoriteIcon } from "images/icons/heart.svg";
import { ReactComponent as AirplaneIcon } from "images/icons/airplane-ascent.svg";
import { ReactComponent as DollarIcon } from "images/icons/dollar.svg";
import { ReactComponent as PromotionIcon } from "images/icons/promotions.svg";
import { ReactComponent as PublishedIcon } from "images/icons/check.svg";
import { ReactComponent as UnpublishedIcon } from "images/icons/archive.svg";
import { ReactComponent as CompanyIcon } from "images/icons/briefcase.svg";
import { getName } from "utils/user";

const DashboardPage = (props) => {
  const { userInfo, notifications, dashboard } = props;
  const { getDashboard, getNotifications } = props;

  const [error, setError] = useState();

  useEffect(() => {
    getNotifications();
    getDashboard().catch((err) => {
      const msg = parseAxiosError(err);
      setError(msg);
    });
  }, [getNotifications, getDashboard]);

  const notificationsPanel = (notifications) => {
    return (
      <Row className="notifications">
        <Col className="notifications_content ml-2 justify-content-between">
          {recentNotifications(notifications)}
          <div className="notifications_btn">
            <Link className="btn btn-green" to={Routes.notifications}>
              SEE ALL
            </Link>
          </div>
        </Col>
      </Row>
    );
  };

  const recentNotifications = (notifications) => {
    if (notifications && notifications.length > 0) {
      return (
        <div>
          <h5 className="font-weight-bold">Recent Notifications</h5>
          <ul className="notifications_list">
            {notifications.map((n, i) => formattedNotification(n, i))}
          </ul>
        </div>
      );
    }

    return <h5 className="font-weight-bold">You have no new notifications.</h5>;
  };

  const formattedNotification = (notification, index) => {
    switch (notification.action.value) {
      case "favorite":
        return (
          <li
            key={`notification-${index}`}
            className="notification notification_favorite favorite"
          >
            <FavoriteIcon />
            <span>
              {`New favorite from ${getName(notification.from)} on Listing `}
            </span>
            <ListingLinkAndTitle listing={notification.object} />
          </li>
        );
      case "inquiry":
        return (
          <li
            key={`notification-${index}`}
            className="notification notification_inquiry inquiry"
          >
            <MailIcon />
            <Link to={generatePath(Routes.inbox)}>
              {`New Inquiry from ${notification.object.name}`}
            </Link>
            <span>{` on Listing `}</span>
            <ListingLinkAndTitle listing={notification.object.listing} />
          </li>
        );
      case "listing":
        return (
          <li
            key={`notification-${index}`}
            className="notification notification_new_listing new_listing"
          >
            <AirplaneIcon />
            <span>{`New Listing from ${getName(notification.from)}: `}</span>
            <ListingLinkAndTitle listing={notification.object} />
          </li>
        );
      case "pending-listing":
        return (
          <li
            key={`notification-${index}`}
            className="notification notification_pending_listing pending_listing"
          >
            <AirplaneIcon />
            <span>
              {`Pending Listing from ${getName(notification.from)}: `}
            </span>
            <ListingLinkAndTitle listing={notification.object} />
          </li>
        );
      case "published":
        return (
          <li
            key={`notification-${index}`}
            className="notification notification_published published"
          >
            <PublishedIcon />
            <span>
              {`Listing Published by ${getName(notification.from)}: `}
            </span>
            <ListingLinkAndTitle listing={notification.object} />
          </li>
        );
      case "unpublished":
        return (
          <li
            key={`notification-${index}`}
            className="notification notification_unpublished unpublished"
          >
            <UnpublishedIcon />
            <span>{`Listing Removed by ${getName(notification.from)}: `}</span>
            <ListingLinkAndTitle listing={notification.object} />
          </li>
        );
      case "sold":
        return (
          <li
            key={`notification-${index}`}
            className="notification notification_sold sold"
          >
            <DollarIcon />
            <span>
              {`Listing Marked as Sold by ${getName(notification.from)}: `}
            </span>
            <ListingLinkAndTitle listing={notification.object} />
          </li>
        );
      case "promotion":
        return (
          <li
            key={`notification-${index}`}
            className="notification notification_sold sold"
          >
            <PromotionIcon />
            <span>
              {`${
                notification.object.type.title
              } Promotion Requested by ${getName(
                notification.from
              )} for Listing: `}
            </span>

            <ListingLinkAndTitle listing={notification.object.listing} />
          </li>
        );

      case "updated-plan":
        return (
          <li
            key={`notification-${index}`}
            className="notification notification_plan plan"
          >
            <UserIcon />
            <span>
              {`${notification.object.name} has upgraded their Plan to `}
            </span>
            <Link
              to={generatePath(Routes.companyEdit, {
                company: notification.object.slug,
                tab: "plan",
              })}
            >
              {notification.object.plan.title}
            </Link>
          </li>
        );
      case "company-payment":
        return (
          <li
            key={`notification-${index}`}
            className="notification notification_payment payment"
          >
            <DollarIcon />
            <span>{`${notification.object.name} has upgraded their `}</span>
            <Link
              to={generatePath(Routes.companyEdit, {
                company: notification.object.slug,
                tab: "billing",
              })}
            >
              Payment Information
            </Link>
          </li>
        );
      case "updated-subscription":
        return (
          <li
            key={`notification-${index}`}
            className="notification notification_subscription subscription"
          >
            <UserIcon />
            <span>
              {`${getName(
                notification.object
              )} has upgraded their Subscription to `}
            </span>
            <Link to={UserLink(notification.object.email, "plan")}>
              {notification.object.plan.title}
            </Link>
          </li>
        );
      case "user-payment":
        return (
          <li
            key={`notification-${index}`}
            className="notification notification_payment payment"
          >
            <DollarIcon />
            <span>{`${getName(notification.object)} has upgraded their `}</span>
            <Link to={UserLink(notification.object.email, "payment")}>
              Payment Information
            </Link>
          </li>
        );
      case "new-user":
        return (
          <li
            key={`notification-${index}`}
            className="notification notification_new_user new_user"
          >
            <UserIcon />
            <span>{`New User Created: `}</span>
            <Link to={UserLink(notification.object.email)}>
              {getName(notification.object)}
            </Link>
          </li>
        );
      case "pending-user":
        console.log(notification);
        let msg = `New User Pending: `;
        let link = UserLink(notification.from.email);
        if (notification.to && notification.to.company) {
          msg = `New User Pending for ${notification.to.company.name}: `;
          link = generatePath(Routes.companyEdit, {
            company: notification.to.company.slug,
            tab: "reps",
          });
        }

        return (
          <li
            key={`notification-${index}`}
            className="notification notification_pending_user pending_user"
          >
            <UserIcon />
            <span>{msg}</span>
            <Link to={link}>{getName(notification.object)}</Link>
          </li>
        );
      case "new-company":
        return (
          <li
            key={`notification-${index}`}
            className="notification notification_new_company new_company"
          >
            <CompanyIcon />
            <span>{`New Company Created: `}</span>
            <Link
              to={generatePath(Routes.companyEdit, {
                company: notification.object.slug,
                tab: undefined,
              })}
            >
              {notification.object.name}
            </Link>
          </li>
        );
      default:
        return (
          <li
            key={`notification-${index}`}
            className="notification notification_subscription subscription"
          >
            <span>{`${notification.action.title}`}</span>
          </li>
        );
    }
  };

  const dashboardBlock = (label, total, delta, color) => {
    return (
      <div
        className={classNames(
          "dashboard_box",
          "d-flex",
          "justify-content-center",
          "align-items-center",
          "flex-column",
          `border-${color}`
        )}
      >
        <h1>{total}</h1>
        <span>{label}</span>
        {deltaStat(delta)}
      </div>
    );
  };

  const deltaStat = (number) => {
    const num = number > -1 ? `+${number}` : number;
    return (
      <span
        className={`subtitle ${number > -1 ? "text-green" : "text-red"}`}
      >{`${num} in the last seven days`}</span>
    );
  };

  const listingsBlocks = () => {
    return (
      <>
        {dashboardBlock(
          "Premium For Sale Listings",
          dashboard.listings.sales.total,
          dashboard.listings.sales.delta,
          "red"
        )}
        {dashboardBlock(
          "Premium Wanted Listings",
          dashboard.listings.wanted.total,
          dashboard.listings.wanted.delta,
          "red"
        )}
        {dashboardBlock(
          "Premium Services Listings",
          dashboard.listings.service.total,
          dashboard.listings.service.delta,
          "red"
        )}
      </>
    );
  };

  const superAdminDashboard = () => {
    return (
      <>
        <Row>
          <Col className="dashboard">
            {dashboardBlock(
              "Listings Currently Live",
              dashboard.listings.live.total,
              dashboard.listings.live.delta,
              "green"
            )}
            {dashboardBlock(
              "Favorites have been Updated",
              dashboard.favorites.total,
              dashboard.favorites.delta,
              "green"
            )}
            <div className="dashboard_box d-flex justify-content-center align-items-center flex-column border-black">
              <h1>{dashboard.inquiries.unread}</h1>
              <span>New Inquiries</span>
              <Link className="btn btn-red" to={Routes.inbox}>
                REVIEW
              </Link>
            </div>
            {listingsBlocks()}
          </Col>
        </Row>
        <Row>
          <Col className="general_buttons w-100">
            <Link className="btn btn-sm btn-green" to={Routes.forSaleManage}>
              LISTINGS
            </Link>
            <Link className="btn btn-sm btn-green" to={Routes.forSaleManage}>
              DRAFTS
            </Link>
            <Link className="btn btn-sm btn-red" to={Routes.inbox}>
              INBOX
            </Link>
            <Link
              className="btn btn-sm btn-red"
              to={generatePath(Routes.accountSettings)}
            >
              ACCOUNT SETTINGS
            </Link>
            <Link className="btn btn-sm btn-gray" to={Routes.favorites}>
              FAVORITES
            </Link>
            {/* <Link className="btn btn-sm btn-gray" to={Routes.mySavedSearches}>
              SAVED SEARCHES
            </Link> */}
          </Col>
        </Row>
      </>
    );
  };

  const companyAdminDashboard = () => {
    return (
      <>
        <Row>
          <Col className="dashboard">
            {dashboardBlock(
              "Live Listings",
              dashboard.listings.live.total,
              dashboard.listings.live.delta,
              "green"
            )}
            {dashboardBlock(
              "Sales Reps",
              dashboard.reps.total,
              dashboard.reps.delta,
              "green"
            )}

            <div className="dashboard_box d-flex justify-content-center align-items-center flex-column border-black">
              <h1>{dashboard.inquiries.unread}</h1>
              <span>New Inquiries</span>
              <Link className="btn btn-red" to={Routes.inbox}>
                REVIEW
              </Link>
            </div>
            {listingsBlocks()}
          </Col>
        </Row>
        <Row>
          <Col className="general_buttons w-100">
            <Link className="btn btn-sm btn-green" to={Routes.inbox}>
              INBOX
            </Link>
            <Link className="btn btn-sm btn-green" to={Routes.promotionsNew}>
              PROMOTIONS
            </Link>
            <Link
              className="btn btn-sm btn-red"
              to={generatePath(Routes.accountSettings)}
            >
              ACCOUNT SETTINGS
            </Link>
            <Link
              className="btn btn-sm btn-red"
              to={generatePath(Routes.companyEdit, {
                company: userInfo.company.slug,
                tab: null,
              })}
            >
              COMPANY SETTINGS
            </Link>
            <Link
              className="btn btn-sm btn-gray"
              to={generatePath(Routes.favorites, { type: null })}
            >
              FAVORITES
            </Link>
            {/* <Link className="btn btn-sm btn-gray" to={Routes.mySavedSearches}>
              SAVED SEARCHES
            </Link> */}
          </Col>
        </Row>
      </>
    );
  };

  const salesRepDashboard = () => {
    return (
      <>
        <Row>
          <Col className="dashboard">
            {dashboardBlock(
              "Listings Currently Live",
              dashboard.listings.live.total,
              dashboard.listings.live.delta,
              "green"
            )}
            {dashboardBlock(
              "Drafts in Review",
              dashboard.listings.pending.total,
              dashboard.listings.pending.delta,
              "green"
            )}

            <div className="dashboard_box d-flex justify-content-center align-items-center flex-column border-black">
              <h1>{dashboard.inquiries.unread}</h1>
              <span>New Inquiries</span>
              <Link className="btn btn-red" to={Routes.inbox}>
                REVIEW
              </Link>
            </div>
            {listingsBlocks()}
          </Col>
        </Row>
        <Row>
          <Col className="general_buttons w-100">
            <Link className="btn btn-sm btn-green" to={Routes.forSaleManage}>
              LISTINGS
            </Link>
            <Link
              className="btn btn-sm btn-green"
              to={generatePath(Routes.accountSettings)}
            >
              ACCOUNT SETTINGS
            </Link>
            <Link className="btn btn-sm btn-red" to={Routes.inbox}>
              INBOX
            </Link>
            <Link className="btn btn-sm btn-red" to={Routes.promotion}>
              PROMOTIONS
            </Link>
            <Link className="btn btn-sm btn-gray" to={Routes.favorites}>
              FAVORITES
            </Link>
            {/* <Link className="btn btn-sm btn-gray" to={Routes.mySavedSearches}>
              SAVED SEARCHES
            </Link> */}
          </Col>
        </Row>
      </>
    );
  };

  const advertiserDashboard = () => {
    return (
      <>
        <Row>
          <Col className="dashboard">
            {dashboardBlock(
              "Listings Currently Live",
              dashboard.listings.live.total,
              dashboard.listings.live.delta,
              "green"
            )}
            {dashboardBlock(
              "Favorites Updated",
              dashboard.favorites.total,
              dashboard.favorites.delta,
              "green"
            )}
            <div className="dashboard_box d-flex justify-content-center align-items-center flex-column border-black">
              <h1>{dashboard.inquiries.unread}</h1>
              <span>New Inquiries</span>
              <Link className="btn btn-red" to={Routes.inbox}>
                REVIEW
              </Link>
            </div>
            {listingsBlocks()}
          </Col>
        </Row>
        <Row>
          <Col className="general_buttons w-100">
            <Link className="btn btn-sm btn-green" to={Routes.forSaleManage}>
              LISTINGS
            </Link>
            <Link className="btn btn-sm btn-green" to={Routes.forSaleManage}>
              DRAFTS
            </Link>
            <Link className="btn btn-sm btn-red" to={Routes.inbox}>
              INBOX
            </Link>
            <Link
              className="btn btn-sm btn-red"
              to={generatePath(Routes.accountSettings)}
            >
              ACCOUNT SETTINGS
            </Link>
            <Link className="btn btn-sm btn-gray" to={Routes.favorites}>
              FAVORITES
            </Link>
            {/* <Link className="btn btn-sm btn-gray" to={Routes.mySavedSearches}>
              SAVED SEARCHES
            </Link> */}
          </Col>
        </Row>
      </>
    );
  };

  const browserDashboard = () => {
    return (
      <>
        <Row>
          <Col className="dashboard">
            <Col className="overlay">
              <div className="inner">
                <h2>
                  See Data on Your Listings and get <br />
                  Premium Listing Placement
                </h2>
                <Link
                  to={generatePath(Routes.accountSettings, {
                    email: userInfo.email,
                    tab: "plan",
                  })}
                  className="btn btn-green"
                >
                  Upgrade
                </Link>
              </div>
            </Col>

            <div className="dashboard_box d-flex justify-content-center align-items-center flex-column border-green">
              <h1>6</h1>
              <span>Listings Currently Live</span>
              <span className="subtitle text-green">
                +1 in the last seven days
              </span>
            </div>
            <div className="dashboard_box d-flex justify-content-center align-items-center flex-column border-green">
              <h1>2</h1>
              <span>Favorites Updated</span>
              <span className="subtitle text-red">
                -2 in the last seven days
              </span>
            </div>
            <div className="dashboard_box d-flex justify-content-center align-items-center flex-column border-black">
              <h1>2</h1>
              <span>New Inquiries</span>
              <Link className="btn btn-red" to={Routes.inbox}>
                REVIEW
              </Link>
            </div>
            <div className="dashboard_box d-flex justify-content-center align-items-center flex-column border-red">
              <h1>5</h1>
              <span>Premium for sale Listings</span>
              <span className="subtitle text-green">
                +1 in the last seven days
              </span>
            </div>
            <div className="dashboard_box d-flex justify-content-center align-items-center flex-column border-red">
              <h1>1</h1>
              <span>Premium wanted listings</span>
              <span className="subtitle text-green">
                +0 in the last seven days
              </span>
            </div>
            <div className="dashboard_box d-flex justify-content-center align-items-center flex-column border-red">
              <h1>0</h1>
              <span>Premium Services Listings</span>
              <span className="subtitle text-green">
                +0 in the last seven days
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col className="general_buttons w-100">
            <Link
              className="btn btn-sm btn-green"
              to={generatePath(Routes.accountSettings)}
            >
              ACCOUNT SETTINGS
            </Link>
            <Link className="btn btn-sm btn-green" to={Routes.inbox}>
              INBOX
            </Link>
            <Link className="btn btn-sm btn-red" to={Routes.favorites}>
              FAVORITES
            </Link>
            {/* <Link className="btn btn-sm btn-red" to={Routes.mySavedSearches}>
              SAVED SEARCHES
            </Link> */}
            <span className="disclaimer">
              You may only create Five fREE listings. UPGRADE YOUR ACCOUNT SO
              YOUR SALES CAN SOAR EVEN FURTHER.
            </span>
            <Link
              className="btn btn-sm btn-green"
              to={generatePath(Routes.accountSettings, {
                email: userInfo.email,
                tab: "plan",
              })}
            >
              UPGRADE
            </Link>
          </Col>
        </Row>
      </>
    );
  };

  const drawDashboard = (dashboard) => {
    if (userInfo.role && userInfo.role.value) {
      switch (userInfo.role.value) {
        case "super-admin":
          return superAdminDashboard();
        case "company-admin":
          return companyAdminDashboard();
        case "sales-rep":
          return salesRepDashboard();
        case "sales-rep-view":
        case "advertiser":
        default:
          return advertiserDashboard();
      }
    }
    return browserDashboard();
  };

  const content = (error, dashboard, notifications) => {
    if (error) {
      return (
        <div
          id={"dashboard-detail-error"}
          className={classNames(
            "dashboard-detail",
            "dashboard-edit",
            "dashboard-detail-error"
          )}
        >
          {error}
        </div>
      );
    }
    if (!_.isEmpty(dashboard)) {
      return (
        <div
          id={`dashboard-detail`}
          className={classNames(
            "dashboard-detail",
            "dashboard-edit",
            "dashboard-detail-results"
          )}
        >
          {notificationsPanel(notifications)}
          {drawDashboard(dashboard)}
        </div>
      );
    }

    return (
      <div
        id={`dashboard-detail-loading`}
        className={classNames(
          "dashboard-detail",
          "dashboard-edit",
          "dashboard-detail-loading"
        )}
      >
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  };

  return (
    <section id="app-page-home" className="app-content app-main admin-homePage">
      <div className="container">
        <div className="welcome">
          WELCOME <span className="font-weight-bold">{getName(userInfo)}</span>{" "}
        </div>
        {content(error, dashboard, notifications)}
      </div>
    </section>
  );
};

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
    dashboard: state.dashboard,
    notifications: state.notifications,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    getDashboard: () => dispatch(getDashboard()),
    getNotifications: () => dispatch(getNotifications()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(DashboardPage);
