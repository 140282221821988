import React from "react";
import { connect } from "react-redux";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Breadcrumbs from "components/frontend/listings/_shared/breadcrumbs";

import ImagePlaceholder from "images/listings/placeholder.png";
import routes from "routes";

import {
  openContactForm,
  openMessageModal,
  closeMessageModal,
} from "actions/modal/actions";

import { dateDiffInDays } from "utils/dates";

const pluralize = require("pluralize");

function Header(props) {
  const { listing } = props;

  const breadcrumbData = [
    {
      text: "Wanted",
      link: routes.wanted,
    },
  ];
  const aircraftType = listing.wantedDetails.model.type;
  if (aircraftType)
    breadcrumbData.push({
      text: pluralize(aircraftType.title),
      link: `${routes.wanted}?types=${aircraftType.value}`,
    });

  const aircraftMake = listing.wantedDetails.model.make;
  if (aircraftMake)
    breadcrumbData.push({
      text: aircraftMake.name,
      link: `${routes.wanted}?make=${aircraftMake.slug}`,
    });

  const aircraftModel = listing.wantedDetails.model;
  if (aircraftModel)
    breadcrumbData.push({
      text: aircraftModel.name,
    });

  const imageCss = () => {
    if (listing.images && listing.images.length > 0) {
      const first = listing.images.slice(0, 1).shift();
      return {
        backgroundImage: `url('${first.url}')`,
        backgroundColor: "transparent",
      };
    } else if (listing.thumbnail) {
      return {
        backgroundImage: `url('${listing.thumbnail}')`,
        backgroundColor: "transparent",
      };
    } else {
      return {};
    }
  };

  const listingTitle = () => {
    return <h1 className="title">{listing.title}</h1>;
  };

  const highlights = () => {
    // Price <span>Deal Pending</span>
    const highlightsArray = listing.highlights;
    if (highlightsArray) {
      return highlightsArray.map((type) => type.name);
    }
    return [];
  };

  const headerImage = () => {
    const showRibbon = ["sold", "removed", "draft", "pending"].includes(
      listing.status.value
    );
    let showSubtext = ["removed", "draft", "pending"].includes(
      listing.status.value
    );
    if (
      listing.status.value === "sold" &&
      dateDiffInDays(listing.unPublishedAt, new Date()) > 30
    ) {
      showSubtext = true;
    }
    return (
      <div
        id="listing-image"
        className="listing-image image"
        style={imageCss()}
      >
        {showRibbon && (
          <div className={`corner-ribbon top-left ${listing.status.value}`}>
            <div className="headline">{listing.status.title}</div>
            {showSubtext && (
              <div className="subtext">Only You Can See This Page</div>
            )}
          </div>
        )}
        <img src={ImagePlaceholder} alt="" className="img-fluid" />
      </div>
    );
  };

  return (
    <header className="for-sale-detail-header detail-header">
      <Container>
        <Row>
          <Col>
            <Breadcrumbs data={breadcrumbData} />
            {listingTitle()}
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={4} className="listing-data pt-4">
            <div id="listing-image-mobile" className="d-md-none row">
              {headerImage()}
            </div>
            {(listing.level.value !== "basic" || true) && (
              <ul className="highlights-list">
                {highlights().map((item, i) => (
                  <li key={i} className="highlight">
                    {item}
                  </li>
                ))}
              </ul>
            )}
            <Row className="action-buttons my-5 d-flex justify-content-center justify-content-md-start">
              <Col xs={8} sm={6} md={12} lg={8} xl={7}>
                <div>
                  <button
                    className="btn btn-primary btn-wide mb-3 contact-button"
                    onClick={() => props.openContactForm(listing)}
                  >
                    Contact
                  </button>
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            md={8}
            id="listing-image-desktop"
            className="right d-none d-md-flex p-0"
          >
            {headerImage()}
          </Col>
        </Row>
      </Container>
    </header>
  );
}

const keyDispatchToProps = (dispatch) => {
  return {
    openContactForm: (listing) => dispatch(openContactForm(listing)),
    openMessageModal: (message, actions, css, showClose) =>
      dispatch(openMessageModal({ message, actions, css, showClose })),
    closeMessageModal: () => dispatch(closeMessageModal()),
  };
};

export default connect(null, keyDispatchToProps)(Header);
