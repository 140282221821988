export const OPEN_LOGIN = "OPEN_LOGIN";
export const CLOSE_LOGIN = "CLOSE_LOGIN";
export const OPEN_SIGNUP = "OPEN_SIGNUP";
export const CLOSE_SIGNUP = "CLOSE_SIGNUP";
export const OPEN_FORGOT_PASSWORD = "OPEN_FORGOT_PASSWORD";
export const CLOSE_FORGOT_PASSWORD = "CLOSE_FORGOT_PASSWORD";
export const OPEN_CONTACTFORM = "OPEN_CONTACTFORM";
export const CLOSE_CONTACTFORM = "CLOSE_CONTACTFORM";
export const OPEN_MESSAGE_MODAL = "OPEN_MESSAGE_MODAL";
export const CLOSE_MESSAGE_MODAL = "CLOSE_MESSAGE_MODAL";
export const OPEN_NEWSLETTER_MODAL = "OPEN_NEWSLETTER_MODAL";
export const CLOSE_NEWSLETTER_MODAL = "CLOSE_NEWSLETTE_MODAL";
