import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";
//import { Accordion, Card, Button, useAccordionToggle } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import { CSSTransition } from "react-transition-group";

import Routes from "routes";
import Avatar from "components/shared/avatar";

import { toggleUserSidebar, toggleMobileMenu } from "actions/app/actions";
import { openLogin } from "actions/modal/actions";

import MobileMenu from "./mobileMenu";

// Images
import LogoWhite from "images/plane-mover-logo-white.png";
import LogoHorizontalWhite from "images/plane-mover-horizontal-logo-white.png";
import LogoRed from "images/plane-mover-logo-red.png";
import LogoHorizontalRed from "images/plane-mover-horizontal-logo-red.png";

// Icons
import { ReactComponent as SearchIcon } from "images/icons/search.svg";
import { ReactComponent as MenuIcon } from "images/icons/menu.svg";
import { ReactComponent as LanguageIcon } from "images/icons/flag-us.svg";

const Header = (props) => {
  const { userInfo, showMobileMenu, location } = props;
  const { openLogin, toggleMobileMenu, toggleUserSidebar } = props;

  const Logo = (path) => {
    switch (path) {
      case "/":
        return (
          <>
            <img
              id="app-header-logo-desktop"
              src={LogoRed}
              alt="Plane Mover"
              className="app-logo img-fluid d-none d-md-block"
            />
            <img
              id="app-header-logo-mobile"
              src={LogoHorizontalRed}
              alt="Plane Mover"
              className="app-logo img-fluid d-md-none"
            />
          </>
        );

      default:
        return (
          <>
            <img
              id="app-header-logo-desktop"
              src={LogoWhite}
              alt="Plane Mover"
              className="app-logo img-fluid d-none d-md-block"
            />
            <img
              id="app-header-logo-mobile"
              src={LogoHorizontalWhite}
              alt="Plane Mover"
              className="app-logo img-fluid d-md-none"
            />
          </>
        );
    }
  };

  return (
    <header id="app-header" className="app-header">
      <CSSTransition
        in={showMobileMenu}
        timeout={250}
        classNames="mobile-menu-transition"
        unmountOnExit
      >
        <MobileMenu />
      </CSSTransition>

      <Container>
        <div className="row">
          <div id="app-header-logo-col" className="col-4 app-logo-col">
            <Link to="/" title="Plane Mover">
              <Logo path={location.pathname} />
            </Link>
          </div>
          <div
            id="app-header-menu-col"
            className="col-8 app-menu-col text-right d-none d-md-block"
          >
            <div id="app-header-menu-top" className="app-menu-top">
              <Link to={Routes.advertise} className="menu-item menu-text">
                Advertise
              </Link>
              {_.isEmpty(userInfo) && (
                <button onClick={openLogin} className="menu-item menu-text">
                  Log In
                </button>
              )}
              <a href={Routes.null} className="menu-item menu-icon d-none">
                <SearchIcon />
              </a>
              <a
                href={Routes.null}
                className="menu-item menu-icon language-button d-none"
              >
                <LanguageIcon />
              </a>

              {!_.isEmpty(userInfo) && userInfo.profile && (
                <a
                  href={Routes.null}
                  className="menu-item menu-icon avatar-button"
                  onClick={toggleUserSidebar}
                >
                  <Avatar
                    name={userInfo.profile.name}
                    src={userInfo.profile.profileImage}
                    size={32}
                  />
                </a>
              )}
            </div>
            <div id="app-header-menu-bottom" className="app-menu-bottom">
              <Link to={Routes.forSale} className="menu-item menu-text">
                For Sale
              </Link>
              <Link to={Routes.services} className="menu-item menu-text">
                Services
              </Link>
              <Link to={Routes.wanted} className="menu-item menu-text">
                Wanted
              </Link>
            </div>
          </div>
          <div
            id="app-mobile-menu-col"
            className="col app-mobile-menu-col text-right d-md-none"
          >
            <span
              id="app-mobile-menu-trigger"
              className="mobile-menu-trigger"
              onClick={toggleMobileMenu}
            >
              <MenuIcon />
            </span>
          </div>
        </div>
      </Container>
    </header>
  );
};

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
    showMobileMenu: state.app.showMobileMenu,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    openLogin: () => dispatch(openLogin()),
    toggleUserSidebar: () => dispatch(toggleUserSidebar()),
    toggleMobileMenu: () => dispatch(toggleMobileMenu()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(Header);
