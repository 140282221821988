import React, { useEffect } from "react";
import { connect } from "react-redux";
import { generatePath } from "react-router-dom";

import Routes, { createTitle } from "routes";

import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Spinner from "components/shared/spinner";

import { Formik } from "formik";

import { getPlans } from "actions/plan/actions";
import { addCompany } from "actions/companies/actions";
import { openMessageModal, closeMessageModal } from "actions/modal/actions";
import { parseAxiosError } from "utils/errors";

const yup = require("yup");

function AddCompany(props) {
  console.log(props);
  const { plans, history } = props;
  const { getPlans, addCompany, openMessageModal, closeMessageModal } = props;

  useEffect(() => {
    document.title = createTitle(`Add New Company`, Routes);

    getPlans();
  }, [getPlans]);

  const handleCreation = (values, setSubmitting) => {
    setSubmitting(true);

    addCompany(values)
      .then((response) => {
        history.push({
          pathname: generatePath(Routes.companyEdit, {
            company: response.slug,
            tab: null,
          }),
        });
      })
      .catch((err) => {
        console.error(err);
        openMessageModal(parseAxiosError(err), () => (
          <Button
            className="btn btn-white"
            onClick={() => {
              closeMessageModal();
            }}
          >
            Okay
          </Button>
        ));
      });

    // axios
    //   .post("/accounts/create", values)
    //   .then((response) => {
    //     // Successfully created User. Redirect to edit page
    //     props.history.push(
    //       generatePath(Routes.accountSettings, {
    //         email: response.data.account.email,
    //         tab: undefined,
    //       })
    //     );
    //   })
    //   .catch((err) => {
    //     console.error("Error Creating Account");
    //     console.error(err);
    //     setError(parseAxiosError(err));
    //     setSubmitting(false);
    //   });
  };

  return (
    <section
      id="app-sitesettings"
      className="app-content app-main account-settings"
    >
      <Container>
        <Row mb={4}>
          <Col xs>
            <div className="title">
              ADD <span className="bold">NEW COMPANY</span>
            </div>
          </Col>
        </Row>
        <Formik
          initialValues={{
            name: "",
            website: "",
            plan: "",
          }}
          validationSchema={yup.object().shape({
            name: yup.string().required("Companies must have a name"),
            website: yup.string().url("Invalid URL"),
            plan: yup.string().required("A Company Plan must be selected"),
          })}
          onSubmit={(vals, { setSubmitting }) =>
            handleCreation(vals, setSubmitting)
          }
        >
          {({
            values,
            touched,
            errors,
            handleChange,
            handleBlur,
            handleSubmit,
            isValid,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form
              onSubmit={isSubmitting ? (e) => e.preventDefault() : handleSubmit}
              className="account-form add-new"
              disabled
            >
              {isSubmitting && (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}
              <Form.Row>
                <Form.Group as={Col} xs={12}>
                  <Form.Label className="account-form-label">
                    Company Name
                  </Form.Label>
                  <Form.Control
                    className="account-form-input"
                    type="text"
                    id="name"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Corporation Corp, Inc."
                  />
                  {touched.name && errors.name && (
                    <Form.Text className="account-form-error">
                      {errors.name}
                    </Form.Text>
                  )}
                </Form.Group>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col} xs={12} md={6}>
                  <Form.Label className="account-form-label">Plan</Form.Label>
                  <Form.Control
                    as="select"
                    id="plan"
                    name="plan"
                    value={values.plan}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option></option>
                    {plans
                      //.sort((a, b) => (a.name > b.name ? 1 : -1))
                      .map((opt, i) => (
                        <option key={`role-${opt.slug}`} value={opt.slug}>
                          {opt.title}
                        </option>
                      ))}
                  </Form.Control>
                  {touched.plan && errors.plan && (
                    <Form.Text className="account-form-error">
                      {errors.plan}
                    </Form.Text>
                  )}
                </Form.Group>
                <Form.Group as={Col} xs={12} md={6}>
                  <Form.Label className="account-form-label">
                    Website
                  </Form.Label>
                  <Form.Control
                    className="account-form-input"
                    type="text"
                    id="website"
                    name="website"
                    value={values.website}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="http://www.corporation.com"
                  />
                  {touched.website && errors.website && (
                    <Form.Text className="account-form-error">
                      {errors.website}
                    </Form.Text>
                  )}
                </Form.Group>
              </Form.Row>

              <div className="d-md-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center justify-content-between justify-content-md-end mt-3 mt-md-0">
                  <Button
                    className="btn btn-green"
                    type="submit"
                    disabled={
                      !isValid ||
                      (Object.keys(touched).length === 0 &&
                        touched.constructor === Object)
                    }
                  >
                    Add Company
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Container>
    </section>
  );
}

const keyStateToProps = (state) => {
  return {
    plans: state.plans,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    getPlans: () => dispatch(getPlans()),
    addCompany: (company) => dispatch(addCompany(company)),
    openMessageModal: (message, actions) =>
      dispatch(openMessageModal({ message, actions })),
    closeMessageModal: () => dispatch(closeMessageModal()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(AddCompany);
