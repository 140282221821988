import React, { Fragment } from "react";
import axios from "axios";
import { Formik } from "formik";
//import { object, string, ref } from "yup";

import { Button, Row, Col, Form } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import Spinner from "components/shared/spinner";

import SaveButtonText from "components/backend/listings/fields/saveButtonText";
import { connect } from "react-redux";
import { getCountries, getStates } from "actions/masterData/actions";
import TextareaAutosize from "react-textarea-autosize";
const yup = require("yup");
const NUMERIC_REGEXP = /^\d*\.?\d*$/;

class Step1 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      error: undefined,
      categories: [],
      category: undefined,
      companies: [],
      company: undefined,
      reps: [],
      rep: undefined,
      accounts: [],
      account: undefined,
      pastedText: "",
    };
    this.textarea = React.createRef();
  }

  componentDidMount = () => {
    // Create array of promises to run prior to displaying the form
    const proms = [];

    this.props.getCountries();
    this.props.getStates();

    proms.push(
      axios.get("/masterData?keys=serviceCategory").then((response) => {
        const masterData = response.data.rows;
        this.setState({
          categories: masterData.filter(
            (data) => data.key === "serviceCategory"
          ),
        });
        return true;
      })
    );

    const { userInfo } = this.props;
    if (userInfo && userInfo.role && userInfo.role.value) {
      if (userInfo.role.value === "super-admin") {
        // Get all companies
        proms.push(
          axios.get("/company?limit=-1").then((response) => {
            this.setState({ companies: response.data.data.rows });
          }),
          axios.get("/accounts?company=null&limit=-1").then((response) => {
            this.setState({ reps: response.data.data.rows });
          })
        );

        // Get set company, if set
        const companySlug = this.props.values.company;
        if (companySlug) {
          proms.push(
            axios.get(`/company/${companySlug}`).then((response) => {
              this.setState({ company: response.data.data });

              const repEmail = this.props.values.account;
              if (repEmail) {
                this.setState({
                  rep: response.data.data.accounts
                    .filter((a) => a.email === repEmail)
                    .shift(),
                });
              }
            })
          );
        }
      }

      if (userInfo.role.value === "company-admin") {
        const companySlug = this.props.values.company || userInfo.company.slug;
        if (companySlug) {
          proms.push(
            axios.get(`/company/${companySlug}`).then((response) => {
              this.setState({ company: response.data.data });

              const repEmail = this.props.values.account;
              if (repEmail) {
                this.setState({
                  rep: response.data.data.accounts
                    .filter((a) => a.email === repEmail)
                    .shift(),
                });
              }
            })
          );
        }
      }
    }

    Promise.all(proms)
      .then(() => {
        // Finished Loading
        this.setState({ isLoading: false, error: undefined });
      })
      .catch((err) => {
        this.setState({ isLoading: false, error: err });
      })
      .finally(() => {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 500);
      });
  };

  handleValidation = (values) => {};

  handleChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (value === undefined || value === "") value = null;

    this.props.onChange(name, value);

    const newText = e.target.value;
    this.setState({ text: newText });
   this.props.onChange(newText, this.props.name);
  };
    handlePaste = (e) => {
      e.preventDefault();
      const pastedText = e.clipboardData.getData("text/plain");
      const singleLineText= pastedText;
      // const singleLineText = pastedText.replace(/\n/g, " ").replace(/\r/g, "");
      
      // Combine the pasted text with the existing description
      const newDescription = this.props.values.description
        ? this.props.values.description + " " + singleLineText
        : singleLineText;
  
      this.props.onChange("description", newDescription);
  
      // Clear the pasted text state
      this.setState({ pastedText: "" });
   
 
  };
  handleCheckbox = (e, dataset) => {
    // Parse into name/value array
    const value = e.currentTarget.value;
    let valueArr = this.props.values[dataset] || [];
    if (e.currentTarget.checked) {
      if (!valueArr.includes(value)) valueArr.push(value);
    } else {
      // Remove this value from our params
      valueArr.splice(valueArr.indexOf(value), 1);
    }

    this.props.onChange(dataset, valueArr);
  };

  handleIntChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (value === undefined || value === "" || isNaN(value)) {
      value = null;
    } else {
      value = parseInt(value.match(NUMERIC_REGEXP).join(""));
    }

    this.props.onChange(name, value);
  };

  handleFloatChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (value === undefined || value === "" || isNaN(value)) {
      value = null;
    } else {
      value = value.match(NUMERIC_REGEXP).join("");
    }

    this.props.onChange(name, value);
  };

  companyOrAccountRow = (values, touched, errors, formikChange) => {
    const { userInfo } = this.props;
    if (userInfo && userInfo.role && userInfo.role.value) {
      if (
        userInfo.role.value === "super-admin" ||
        userInfo.role.value === "company-admin"
      ) {
        return (
          <>
            <Form.Label className="subHeader">
              Who is selling this plane?
            </Form.Label>
            {userInfo.role.value === "super-admin" &&
              this.companyRow(
                values.company,
                touched.company,
                errors.company,
                formikChange
              )}
            {this.accountRow(
              values.account,
              touched.account,
              errors.account,
              formikChange
            )}
          </>
        );
      }
    }
  };

  companyRow = (value, touched, error, formikChange) => {
    return (
      <Form.Row>
        <Form.Group className="d-flex" as={Col} md={5} lg={5}>
          <Form.Label>Company:</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => {
              formikChange(e);
              this.handleCompanySelection(e);
            }}
            name="company"
            value={value}
            isInvalid={touched && !!error}
          >
            <option value="">No Company</option>
            {this.state.companies.map((opt, i) => (
              <option key={i} value={opt.slug}>
                {opt.name}
              </option>
            ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="d-flex" as={Col} md={3} lg={3}>
          <Form.Label>Phone: {this.companyPhoneLabel()}</Form.Label>
        </Form.Group>

        <Form.Group className="d-flex" as={Col} md={3} lg={3}>
          <Form.Label>Email: {this.companyEmailLabel()}</Form.Label>
        </Form.Group>
      </Form.Row>
    );
  };

  handleCompanySelection = (e) => {
    const companySlug = e.target.value;
    this.props.onChange("company", companySlug);

    this.setState({ company: undefined, rep: undefined }, () => {
      // Fetch specific Make
      if (companySlug) {
        axios.get(`/company/${companySlug}`).then((response) => {
          this.setState({ company: response.data.data });
        });
      }
    });
  };

  accountRow = (value, touched, error, formikChange) => {
    return (
      <Form.Row>
        <Form.Group className="d-flex" as={Col} md={5} lg={5}>
          <Form.Label>Sales Rep:</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => {
              const email = e.target.value;
              const pool = this.state.compamy
                ? this.state.company.accounts
                : this.state.reps;
              formikChange(e);

              this.handleRepSelection(email, pool);
            }}
            name="account"
            value={value}
            isInvalid={touched && !!error}
          >
            {this.repDropdown(this.state.company, this.state.reps)}
          </Form.Control>
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Col} className="align-self-center" md={3} lg={3}>
          <Form.Label>Phone: {this.repPhoneLabel()}</Form.Label>
        </Form.Group>

        <Form.Group as={Col} className="align-self-center" md={3} lg={3}>
          <Form.Label>Email: {this.repEmailLabel()}</Form.Label>
        </Form.Group>
      </Form.Row>
    );
  };

  repDropdown = (company, accounts) => {
    if (company) {
      // Map List
      const options = company.accounts.map((opt, i) => (
        <option key={i} value={opt.email}>
          {opt.profile.name}
        </option>
      ));
      return [<option key="select-rep">Select a Rep</option>, ...options];
    } else if (accounts) {
      // Map List
      const options = accounts.map((opt, i) => (
        <option key={i} value={opt.email}>
          {opt.profile.name}
        </option>
      ));
      return [<option key="select-rep">Select a Rep</option>, ...options];
    } else {
      return <option disabled>Select a Company first</option>;
    }
  };

  handleRepSelection = (email, pool) => {
    this.props.onChange("account", email);

    this.setState({
      rep: pool.filter((a) => a.email === email).shift(),
    });
  };

  companyPhoneLabel = () => {
    if (this.state.company) {
      const publicProfile = this.state.company.profiles
        .filter((p) => p.type.value === "public")
        .shift();
      if (publicProfile && publicProfile.phone) {
        return <span className="text-green">{publicProfile.phone}</span>;
      }
    }
    return (
      <span className="text-gray">
        <em>Unknown</em>
      </span>
    );
  };

  companyEmailLabel = () => {
    if (this.state.company) {
      const publicProfile = this.state.company.profiles
        .filter((p) => p.type.value === "public")
        .shift();
      if (publicProfile && publicProfile.email) {
        return <span className="text-green">{publicProfile.email}</span>;
      }
    }
    return (
      <span className="text-gray">
        <em>Unknown</em>
      </span>
    );
  };

  repPhoneLabel = () => {
    if (this.state.rep) {
      const repProfile = this.state.rep.profile;
      if (repProfile && repProfile.phone) {
        return <span className="text-green">{repProfile.phone}</span>;
      }
    }
    return (
      <span className="text-gray">
        <em>Unknown</em>
      </span>
    );
  };

  repEmailLabel = () => {
    if (this.state.rep && this.state.rep.email) {
      return <span className="text-green">{this.state.rep.email}</span>;
    }
    return (
      <span className="text-gray">
        <em>Unknown</em>
      </span>
    );
  };

  render() {
    const { userInfo } = this.props;
    const { description } = this.props.values;
    const { pastedText } = this.state;
    const displayValue =
      description === null || description === undefined
        ? ""
        : description + pastedText;
    const schema = yup.object().shape({
      title: yup.string().required("A Title must be provided"),
      category: yup.string().required("A Categpry must be selected"),
      account: yup.string().when("$user", {
        is: () =>
          userInfo.role.value === "super-admin" ||
          userInfo.role.value === "company-admin",
        then: yup.string().required("You must select a Rep"),
      }),
    });

    if (this.state.error) {
      return (
        <div id="step-1-error" className="stepper-error step-1-error error">
          <h1 className="listings-title">{this.state.error.message}</h1>
          <p>{this.ErrorMessage(this.state.error)}</p>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div
          id="step-1-loading"
          className="stepper-loading step-1-loading loading"
        >
          <Spinner />
        </div>
      );
    } else {
      return (
        <Fragment>
          <Formik
            validationSchema={schema}
            //onSubmit={values => this.handleSubmit(values)}
            onSubmit={this.handleValidation}
            initialValues={{
              title: this.props.values.title || "",
              category: this.props.values.category || "",
              company: this.props.values.company || "",
              account: this.props.values.account || "",
            }}
            validateOnChange={true}
            validateOnMount={true}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isSubmitting,
              setSubmitting,
              validateForm,
              isValid,
            }) => (
              <Form onSubmit={handleSubmit}>
                <Row className="stepper_1">
                  <Col>
                    <Form.Label className="subHeader">
                      Tell Us About Your Service
                    </Form.Label>
                    {this.companyOrAccountRow(
                      values,
                      touched,
                      errors,
                      handleChange
                    )}

                    <Form.Row className="stepper_1-specifically d-flex">
                      <Form.Group className="d-flex" as={Col} md={4}>
                        <Form.Label>Category:</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={(e) => {
                            handleChange(e);
                            this.handleChange(e);
                          }}
                          name="category"
                          value={values.category || ""}
                          isInvalid={touched.category && !!errors.category}
                        >
                          <option></option>
                          {this.state.categories.map((cat, i) => {
                            return (
                              <option value={cat.value} key={i}>
                                {cat.title}
                              </option>
                            );
                          }, this)}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.category}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Form.Group className="d-flex" as={Col} md={6}>
                        <Form.Label>Title:</Form.Label>
                        <Form.Control
                          as="input"
                          type="text"
                          name="title"
                          placeholder="XXXX"
                          onChange={(e) => {
                            handleChange(e);
                            this.handleChange(e);
                          }}
                          value={values.title || ""}
                          isInvalid={touched.title && !!errors.title}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.title}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Form.Row>
                    <Form.Row>
                      <Form.Group className="d-flex" as={Col} md={4}>
                        <Form.Label>City:</Form.Label>
                        <Form.Control
                          as="input"
                          type="text"
                          name="city"
                          value={this.props.values.city || ""}
                          placeholder="XXXX"
                          onChange={this.handleChange}
                        />
                      </Form.Group>
                      <Form.Group className="d-flex" as={Col} md={4}>
                        <Form.Label>State/Providence:</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.handleChange}
                          name="state"
                          value={this.props.values.state || ""}
                        >
                          <option></option>
                          {this.props.states.map((opt, i) => (
                            <option key={i} value={opt.value}>
                              {opt.title}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                      <Form.Group className="d-flex" as={Col} md={4}>
                        <Form.Label>Country:</Form.Label>
                        <Form.Control
                          as="select"
                          onChange={this.handleChange}
                          name="country"
                          value={this.props.values.country || ""}
                        >
                          <option></option>
                          {this.props.countries.map((opt, i) => (
                            <option key={i} value={opt.value}>
                              {opt.title}
                            </option>
                          ))}
                        </Form.Control>
                      </Form.Group>
                    </Form.Row>
                    <Form.Group className="w-100">
                      <Form.Label>Brief Description:</Form.Label>
                      <TextareaAutosize
                        ref={this.textarea}
                        className="form-control"
                        rows="4"
                        name="description"
                        value={displayValue}//{this.state.text}
                        placeholder="XXXX"
                        onChange={this.handleChange}
                        onPaste={this.handlePaste}
                       // onInput={this.handleInput}
                      />
                    </Form.Group>

                    <Row className="stepper_btns">
                      <Col md="auto">
                        <Button
                          type="submit"
                          className="btn-red m-b-15"
                          disabled={isSubmitting}
                          onClick={(e) => {
                            handleSubmit(e);
                            if (isValid) {
                              this.props.onNext().then(() => {
                                setSubmitting(false);
                              });
                            } else {
                              setSubmitting(false);
                            }
                          }}
                        >
                          Add Some Media
                        </Button>
                      </Col>
                      <Col md="auto">
                        <Button
                          type="submit"
                          className="btn-green"
                          disabled={isSubmitting}
                          onClick={(e) => {
                            handleSubmit(e);
                            if (isValid) {
                              this.props.onSave().then(() => {
                                setSubmitting(false);
                              });
                            } else {
                              setSubmitting(false);
                            }
                          }}
                        >
                          {SaveButtonText(this.props.values.status)}
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </Fragment>
      );
    }
  }
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
    countries: state.masterData.countries,
    states: state.masterData.states,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    getCountries: () => dispatch(getCountries()),
    getStates: () => dispatch(getStates()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(Step1);
