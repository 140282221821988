import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import axios from "axios";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import configureStore from "./store/configureStore";

import { getUser } from "actions/user/actions";
// import { getCompanies } from 'actions/companies/actions';
// import { getCountry, getState } from 'actions/masterData/actions';
import { setAppReady, showCookiesNotice } from "actions/app/actions";

// Set axios default
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// Disable Caching on Requests
axios.defaults.headers.common["Cache-Control"] = "no-cache";
axios.defaults.headers.common["Pragma"] = "no-cache";
axios.defaults.headers.common["Expires"] = "0";
const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

// Add axios default interceptor for handling 403 errors
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // Do something with response error
    if (
      error.response.config.url !== "accounts/me" &&
      (error.response.status === 401 || error.response.status === 403)
    ) {
      // Delete the token and force re-login?
      if (localStorage.getItem("token")) {
        delete axios.defaults.headers.common["Authorization"];
        localStorage.removeItem("token");
      }

      // If not on login page, redirect
      if (window.location.pathname !== "/login") {
        const redirect = window.location.pathname;
        window.location.replace(`/login?redirect=${redirect}`);
      }
    }
    // return Error object with Promise
    return Promise.reject(error);
  }
);

// Redux config
const store = configureStore();

// Call default API
store.dispatch(getUser()).then(() => {
  //await store.dispatch(getCompanies());
  //await store.dispatch(getCountry());
  //await store.dispatch(getState());
  store.dispatch(showCookiesNotice());
  store.dispatch(setAppReady());
});

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
