export const GET_LISTING = "GET_LISTING";
export const SET_LISTING = "SET_LISTING";
export const CLEAR_LISTING = "CLEAR_LISTING";

export const ADD_LISTING = "ADD_LISTING";
export const UPDATE_LISTING = "UPDATE_LISTING";
export const SAVE_LISTING = "SAVE_LISTING";
export const UPDATE_LISTING_STATUS = "UPDATE_LISTING_STATUS";

export const ADD_LISTING_IMAGE = "ADD_LISTING_IMAGE";
export const UPDATE_LISTING_IMAGE = "UPDATE_LISTING_IMAGE";

export const ADD_LISTING_SPECSHEET = "ADD_LISTING_SPECSHEET";
export const UPDATE_LISTING_SPECSHEET = "UPDATE_LISTING_SPECSHEET";
export const UPDATE_STATUS_LISTING = "UPDATE_STATUS_LISTING";

