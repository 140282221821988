import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, generatePath } from "react-router-dom";
import PropTypes from "prop-types";

import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Favorite from "components/frontend/listings/_shared/favorite";
import Breadcrumbs from "components/frontend/listings/_shared/breadcrumbs";

import ImagePlaceholder from "images/listings/placeholder.png";
import { openContactForm } from "actions/modal/actions";
import routes from "routes";

const pluralize = require("pluralize");

class ListingCard extends Component {
  priceDisplay = (result) => {
    const { price, salePrice } = result.saleDetails;

    let salePriceDisplay;
    if (salePrice) {
      switch (salePrice.value) {
        case "price":
          if (price) {
            salePriceDisplay = new Intl.NumberFormat("en-us", {
              style: "currency",
              currency: "USD",
            }).format(price);
          } else {
            salePriceDisplay = "Unknown";
          }

          break;
        default:
          salePriceDisplay = salePrice.title;
          break;
      }
    } else if (price) {
      salePriceDisplay = new Intl.NumberFormat("en-us", {
        style: "currency",
        currency: "USD",
      }).format(price);
    }

    if (salePriceDisplay) {
      return (
        <div className="meta-row">
          <span className="meta-label">Price:</span>{" "}
          <span className="meta-value">{salePriceDisplay}</span>
        </div>
      );
    }
    return <></>;
  };

  breadcrumbs = (listing) => {
    const breadcrumbData = [];
    const aircraftType = listing.saleDetails.model.type;
    if (aircraftType)
      breadcrumbData.push({
        text: pluralize(aircraftType.title),
        link: `${routes.forSale}?types=${aircraftType.value}`,
      });

    const aircraftMake = listing.saleDetails.model.make;
    if (aircraftMake)
      breadcrumbData.push({
        text: aircraftMake.name,
        link: `${routes.forSale}?make=${aircraftMake.slug}`,
      });

    const aircraftModel = listing.saleDetails.model;
    if (aircraftModel)
      breadcrumbData.push({
        text: aircraftModel.name,
        link: `${routes.forSale}?make=${aircraftMake.slug}&model=${aircraftModel.id}`,
      });

    return breadcrumbData;
  };

  render() {
    const { listing } = this.props;
    const listingType = listing.type.value || "unknown";
    const modelType = listing.saleDetails.model.type.value || "unknown";
    const imageCss = listing.thumbnail
      ? { backgroundImage: "url(" + listing.thumbnail + ")" }
      : {};
    return (
      <Card className={`my-favorites__card ${listingType} ${modelType}`}>
        <Favorite listing={listing} />
        <Link
          to={generatePath(routes.forSaleDetail, {
            listing: listing.slug,
          })}
        >
          <Card.Img
            className="my-favorites__card__img listing-image"
            variant="top"
            src={ImagePlaceholder}
            style={imageCss}
          />
        </Link>
        <Card.Body className="my-favorites__card__body card-body">
          <Row className="mb-3 header-row">
            <Col xs={6}>
              <Card.Text className="my-favorites__card__text">
                <Link
                  to={generatePath(routes.forSaleDetail, {
                    listing: listing.slug,
                  })}
                >
                  {listing.title}
                </Link>
              </Card.Text>
            </Col>
            <Col xs={6}>{this.priceDisplay(listing)}</Col>
          </Row>

          <Row className="align-items-end flex-column-reverse flex-sm-row footer-row">
            <Col xs={12} className="my-3">
              <Card.Text className="my-favorites__card__text">
                Broker: {listing.account.profile.name || <em>Unknown</em>}
              </Card.Text>
              {listing.account.profile.phone && (
                <Card.Text>
                  <a
                    href={`tel:${listing.account.profile.phone || null}`}
                    className="my-favorites__card__info"
                  >
                    {listing.account.profile.phone || <em>Unknown</em>}
                  </a>
                </Card.Text>
              )}

              <div>
                <button
                  className="btn btn-primary btn-sm mt-2"
                  onClick={() => this.props.openContactForm(listing)}
                >
                  contact
                </button>
              </div>
            </Col>
            <Col xs={12}>
              <Breadcrumbs data={this.breadcrumbs(listing)} />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

ListingCard.propTypes = {
  listing: PropTypes.object.isRequired,
};

const keyDispatchToProps = (dispatch) => {
  return {
    openContactForm: (listing) => dispatch(openContactForm(listing)),
  };
};

export default connect(null, keyDispatchToProps)(ListingCard);
