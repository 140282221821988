import React from "react";

import InformationBlock from "components/frontend/listings/for-sale/shared/informationBlock";

export default function Exterior(props) {
  const { listing } = props;

  // exteriorDetails: "Matterhorn White with Black Pearl on lower body and engines and Charcoal Pearl main stripe detail. Further livery details in mid and light silver."
  // exteriorPaintMonth: null
  // exteriorPaintYear: null

  const exteriorCondition = () => {
    if (listing.exteriorCondition) {
      return listing.exteriorCondition.title;
    }
    return <em>Unknown</em>;
  };

  const exteriorDate = () => {
    if (listing.exteriorPaintYear) {
      let output = listing.exteriorPaintYear.toString();
      if (listing.exteriorPaintMonth) {
        const date = new Date(
          new Date().getFullYear() + 1,
          listing.exteriorPaintMonth - 1,
          1
        );
        const month = date.toLocaleString("default", { month: "long" });
        output = `${month} ${output}`;
      }

      return output;
    }

    return <em>Unknown Date</em>;
  };

  const exteriorDetails = () => {
    if (listing.exteriorDetails) {
      return (
        <p
          dangerouslySetInnerHTML={{ __html: listing.exteriorDetails }}
          className="white-space-pre"
        />
      );
    }
    return <></>;
  };

  return (
    (listing.exteriorCondition ||
      listing.exteriorPaintYear ||
      listing.exteriorDetails) && (
      <InformationBlock name="exterior" title="Exterior">
        {(listing.exteriorCondition || listing.exteriorPaintYear) && (
          <ul className="for-sale-detail-exterior-list detail-aiframe-list list no-bullets mb-4">
            <li>
              <strong>Condition:</strong> {exteriorCondition()} -{" "}
              {exteriorDate()}
            </li>
          </ul>
        )}

        {listing.exteriorDetails && exteriorDetails()}
      </InformationBlock>
    )
  );
}
