import React from "react";
import { generatePath } from "react-router-dom";

import ListingsItem from "components/frontend/listings/_shared/listingItem";
import Routes from "routes";
import trimText from "utils/trimText";

export default function SaleListItem(props) {
  const { listing, plan, featured, company } = props;

  const usePlan = plan || listing.company.plan;
  const useCompany = company || listing.company;

  let listingMode = "basic";

  if (usePlan && usePlan.rank > 0) {
    listingMode = "subscriber";
  }
  if (featured || listing.isFeatured > 0) {
    // Set display type to Subscriber or Basic/Free
    listingMode = "featured";
  }

  return (
    <ListingsItem
      className="wanted-listing for-sale-listing"
      listing={listing}
      image={listing.thumbnail}
      title={listing.title}
      description={
        listing.description ? trimText(listing.description, 250) : undefined
      }
      link={generatePath(Routes.wantedDetail, {
        listing: listing.slug,
      })}
      type={listing.wantedDetails.model.type.value}
      mode={listingMode}
      account={listing.account}
      company={useCompany}
    >
      {listing.wantedDetails.yearMin && (
        <div className="meta-row meta-year">
          <span className="meta-label">Minimum Year:</span>{" "}
          <span className="meta-value">{listing.wantedDetails.yearMin}</span>
        </div>
      )}

      {listing.wantedDetails.yearMax && (
        <div className="meta-row meta-year">
          <span className="meta-label">Maximum Year:</span>{" "}
          <span className="meta-value">{listing.wantedDetails.yearMax}</span>
        </div>
      )}
    </ListingsItem>
  );
}
