import React from "react";
import { Link, generatePath } from "react-router-dom";
import Routes from "routes";

export const planLimitText = (user) => {
  if (
    ["company-admin", "sales-rep", "sales-rep-view"].includes(
      user.role.value
    ) &&
    user.company.plan
  ) {
    return (
      <span>
        Your company currently has a{" "}
        <strong className="text-green text-uppercase">
          {user.company.plan.title} Plan
        </strong>{" "}
        which allows{" "}
        <strong className="text-green text-uppercase">
          {user.company.plan.premiumListings} ACTIVE LISTING(S)
        </strong>
        . Upgrade to add more listings.
      </span>
    );
  }

  if (["advertiser"].includes(user.role.value) && user.plan) {
    return (
      <span>
        You currently have a{" "}
        <strong className="text-green text-uppercase">
          {user.plan.title} Plan
        </strong>{" "}
        which allows{" "}
        <strong className="text-green text-uppercase">
          {user.plan.premiumListings} ACTIVE LISTING(S)
        </strong>
        . Upgrade to add more listings.
      </span>
    );
  }
};

export const managePlanButton = (user) => {
  if (
    ["company-admin", "sales-rep", "sales-rep-view"].includes(
      user.role.value
    ) &&
    user.company.plan
  ) {
    return (
      <Link
        className="btn btn-primary btn-green mb-3 mt-4"
        to={generatePath(Routes.companyEdit, {
          company: user.company.slug,
          tab: "plan",
        })}
      >
        Manage Company Plan
      </Link>
    );
  }

  if (["advertiser"].includes(user.role.value) && user.plan) {
    return (
      <Link
        className="btn btn-primary btn-green mb-3 mt-4"
        to={generatePath(Routes.accountSettings, {
          email: user.email,
          tab: "plan",
        })}
      >
        Manage My Plan
      </Link>
    );
  }
};
