/* eslint-disable no-fallthrough */
import React from "react";
import axios from "axios";
import queryString from "query-string";
import moment from "moment";

import Routes, { createTitle } from "routes";
import Table from "components/shared/table";
import MultiSelectFilter from "components/shared/multiSelectFilter";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link, generatePath } from "react-router-dom";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import Pagination from "components/frontend/listings/_shared/pagination";
import { getName } from "utils/user";

class CompaniesManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      fetchUrl: false,
      isLoading: true,
      results: [],
      error: undefined,
      count: 0,
      pages: 0,
      selected: [],
      filterPlans: [],
      filterCompanies: [],
    };

    this.fetchUrl = `/company`;
  }

  componentDidMount() {
    document.title = createTitle("All Companies", Routes);

    axios.get("/plans").then((response) => {
      const filterPlans = response.data.data.map((data) => {
        return {
          value: data.slug,
          title: data.title,
        };
      });

      this.setState({ filterPlans });
    });

    axios.get("/company?limit=0").then((response) => {
      const filterCompanies = response.data.data.rows.map((company) => {
        return {
          value: company.slug,
          title: company.name,
        };
      });
      this.setState({ filterCompanies });
    });

    this.fetchResults();
  }

  componentDidUpdate() {
    this.fetchResults();
  }

  getSearchParams() {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    const newParams = {};

    // Limit
    if (currentParams["show"]) {
      newParams["limit"] = currentParams["show"] || 10;
    }

    return { ...currentParams, ...newParams };
  }

  parseRequestUrl() {
    const fetchUrl = this.fetchUrl;
    const searchParams = this.getSearchParams();
    // Add Params
    if (searchParams) {
      // We have search params. Parse them!
      const searchParamsUrl = queryString.stringify(searchParams, {
        arrayFormat: "comma",
      });
      return `${fetchUrl}?${searchParamsUrl}`;
    }

    // Return URL
    return fetchUrl;
  }

  fetchResults() {
    let fetchUrl = this.parseRequestUrl();
    if (this.state.fetchUrl !== fetchUrl) {
      // Fetch URL has changed. Update state and get new results
      this.setState(
        {
          fetchUrl: fetchUrl,
          isLoading: true,
          results: [],
          error: undefined,
          count: 0,
          pages: 0,
        },
        () => {
          if (fetchUrl) {
            axios
              .get(fetchUrl)
              .then((response) => {
                const results = response.data.data.rows;
                let companies = [];
                if (results) {
                  companies = results.map((i) => {
                    const createdDate = i.createdAt
                      ? moment(i.createdAt).calendar()
                      : undefined;
                    const updatedDate = i.updatedAt
                      ? moment(i.updatedAt).calendar()
                      : undefined;

                    const accountTotals = i.accounts ? i.accounts.length : 0;
                    let primaryAccountName = "";
                    const primaryAccount = i.accounts
                      ? i.accounts.filter((a) => a.isPrimary === 1).shift()
                      : undefined;
                    if (primaryAccount) {
                      primaryAccountName = getName(primaryAccount);
                    }
                    return {
                      id: i.id,
                      name: i.name,
                      slug: i.slug,
                      plan: i.plan.title,
                      saleListings: i.saleListingsCount,
                      wantedListings: i.wantedListingsCount,
                      serviceListings: i.serviceListingsCount,
                      totalListings: i.totalListingsCount,
                      admin: primaryAccountName,
                      reps: accountTotals,
                      createdAt: createdDate,
                      updatedAt: updatedDate,
                      oStatus: i.status,
                      oAccounts: i.accounts,
                      oPlan: i.plan,
                    };
                  });
                }

                this.setState(
                  {
                    isLoading: false,
                    error: undefined,
                    results: companies,
                    count: response.data.data.count,
                    pages: response.data.data.pageCount,
                  },
                  () => {
                    this.props.onFetchSuccess &&
                      this.props.onFetchSuccess(this.state);
                  }
                );
              })
              .catch((error) => {
                this.setState(
                  {
                    isLoading: false,
                    results: [],
                    error: error,
                    count: 0,
                    pages: 0,
                  },
                  () => {
                    this.props.onFetchError &&
                      this.props.onFetchError(this.state);
                  }
                );
              });
          }
        }
      );
    }
  }

  handlePageLimitChange = (event) => {
    const target = event.currentTarget;

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, show: target.value };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };
  getPageLimit = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const pageLimit = currentParams["show"] || 10;
    return parseInt(pageLimit);
  };
  handlePageChange = (pager) => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, page: pager.currentPage };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };
  getPage = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const page = currentParams["page"] || 1;
    return parseInt(page);
  };

  handleSearchChange = (e) => {
    this.setState({ search: e.target.value });
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, keyword: this.state.search };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };

  pageActions(status) {
    return (
      <Link
        className="btn btn-green m-b-15"
        to={generatePath(Routes.forSaleEdit, {
          listing: null,
          step: null,
        })}
      >
        + New
      </Link>
    );
  }

  render() {
    const columns = [
      {
        dataField: "name",
        text: "Company",
        sort: false,
        headerFormatter: (column, colIndex) => (
          <MultiSelectFilter
            column={column}
            items={this.state.filterCompanies}
          />
        ),
      },
      {
        dataField: "plan",
        text: "Plan",
        sort: true,
        headerFormatter: (column, colIndex) => (
          <MultiSelectFilter column={column} items={this.state.filterPlans} />
        ),
      },
      {
        dataField: "saleListings",
        text: "ACFS",
        sort: false,
      },
      {
        dataField: "wantedListings",
        text: "ACW",
        sort: false,
      },
      {
        dataField: "serviceListings",
        text: "SVC",
        sort: false,
      },
      {
        dataField: "totalListings",
        text: "Total",
        sort: false,
      },
      {
        dataField: "admin",
        text: "Main Admin",
        sort: false,
      },
      {
        dataField: "reps",
        text: "Reps",
        sort: false,
      },
      {
        dataField: "createdAt",
        text: "Created",
        sort: true,
      },
      {
        dataField: "updatedAt",
        text: "Updated",
        sort: true,
      },
    ];

    return (
      <section id="app-manage-table" className="app-admin-sales app-main">
        <div className="d-flex justify-content-between m-b-15">
          <div className="title">
            <span>
              All <strong>Companies</strong>
            </span>
          </div>
          <Col className="d-flex flex-column align-items-end">
            <Link to={Routes.companyAdd} className="btn btn-primary btn-red">
              + NEW
            </Link>
          </Col>
        </div>
        <div className="table-layout" id="table-sales">
          <header className="table-top table-header">
            <Row>
              <Col
                sm={6}
                className="table-header-actions table-actions justify-content-center justify-content-sm-start"
              ></Col>
              <Col
                sm={6}
                className="table-header-search table-search justify-content-center justify-content-sm-end"
              >
                <Form onSubmit={this.handleSearchSubmit}>
                  <Form.Control
                    as="input"
                    placeholder="Search"
                    onChange={this.handleSearchChange}
                  />
                  <button type="submit" className="btn btn-icon">
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </Form>
              </Col>
            </Row>
          </header>
          <Table
            id="sales"
            keyField="id"
            //columnHeader={columns}
            columns={columns}
            data={this.state.results}
            //data={this.state.listings}
            // selectRow={{
            //   mode: "checkbox",
            //   clickToSelect: false,
            //   clickToExpand: true,
            //   selected: this.state.selected,
            //   //onClick: this.handleOnClick,
            //   onSelect: this.handleOnSelect,
            //   onSelectAll: this.handleOnSelectAll
            // }}
            expandRow={{
              onlyOneExpanding: true,
              renderer: (row) => {
                return (
                  <div className="row-actions">
                    <Link
                      to={generatePath(Routes.company, {
                        company: row.slug,
                      })}
                      className="btn btn-green"
                      target="_blank"
                    >
                      View
                    </Link>
                    <Link
                      to={generatePath(Routes.companyEdit, {
                        company: row.slug,
                        tab: null,
                      })}
                      className="btn btn-secondary"
                    >
                      Edit
                    </Link>
                  </div>
                );
              },
            }}
          />
          <footer className="table-bottom table-footer">
            <Row>
              <Col
                sm={6}
                className="table-footer-stats table-stats justify-content-center justify-content-sm-start"
              >
                <span className="row-count">
                  {this.state.count} total records. {this.state.results.length}{" "}
                  rows showing ({this.state.selected.length} selected)
                </span>
              </Col>
              <Col
                sm={6}
                className="table-footer-pagination table-pagination text-sm-right justify-content-center justify-content-sm-end"
              >
                <Pagination
                  totalRecords={this.state.count}
                  pageLimit={this.getPageLimit()}
                  pageNeighbours={1}
                  onPageChanged={this.handlePageChange}
                  currentPage={this.getPage()}
                />
              </Col>
            </Row>
          </footer>
        </div>
      </section>
    );
  }
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {};
};

export default connect(keyStateToProps, keyDispatchToProps)(CompaniesManage);
