/* eslint-disable no-fallthrough */
import React, { useState } from "react";
import axios from "axios";
import queryString from "query-string";
import moment from "moment";

import Routes, { createTitle } from "routes";
import Table from "components/shared/table";
import MultiSelectFilter from "components/shared/multiSelectFilter";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { connect } from "react-redux";

import Pagination from "components/frontend/listings/_shared/pagination";

import { openMessageModal, closeMessageModal } from "actions/modal/actions";

class MakesModelsManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fetchUrl: false,
      isLoading: true,
      results: [],
      error: undefined,
      count: 0,
      pages: 0,
      filterTypes: [],
      filterSizes: [],
      filterMakes: []
    };

    this.fetchUrl = `/models`;
  }

  componentDidMount() {
    document.title = createTitle("Manage Makes and Models", Routes);

    axios.get("/masterData?keys=aircraftType,aircraftSize").then(response => {
      const masterData = response.data.rows;

      const filterTypes = masterData
        .filter(data => data.key === "aircraftType")
        .map(data => {
          return {
            value: data.value,
            title: data.title
          };
        });
      const filterSizes = masterData
        .filter(data => data.key === "aircraftSize")
        .map(data => {
          return {
            value: data.value,
            title: data.title
          };
        });

      this.setState({ filterTypes, filterSizes });
    });
    axios.get("/makes?limit=0").then(response => {
      const filterMakes = response.data.data.rows.map(make => {
        return {
          value: make.slug,
          title: make.name
        };
      });

      this.setState({ filterMakes });
    });

    this.fetchResults();
  }

  componentDidUpdate() {
    this.fetchResults();
  }

  getSearchParams() {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma"
    });

    const newParams = {};

    // Limit
    if (currentParams["show"]) {
      newParams["limit"] = currentParams["show"] || 10;
    }

    return { ...currentParams, ...newParams };
  }

  parseRequestUrl() {
    const fetchUrl = this.fetchUrl;
    const searchParams = this.getSearchParams();
    // Add Params
    if (searchParams) {
      // We have search params. Parse them!
      const searchParamsUrl = queryString.stringify(searchParams, {
        arrayFormat: "comma"
      });
      return `${fetchUrl}?${searchParamsUrl}`;
    }

    // Return URL
    return fetchUrl;
  }

  fetchResults(force = false) {
    let fetchUrl = this.parseRequestUrl();
    if (this.state.fetchUrl !== fetchUrl || force) {
      // Fetch URL has changed. Update state and get new results
      this.setState(
        {
          fetchUrl: fetchUrl,
          isLoading: true,
          results: [],
          error: undefined,
          count: 0,
          pages: 0
        },
        () => {
          if (fetchUrl) {
            axios
              .get(fetchUrl)
              .then(response => {
                const results = response.data.data.rows;
                let listings = [];
                if (results) {
                  listings = results.map(i => {
                    const createdDate = i.createdAt
                      ? moment(i.createdAt).calendar()
                      : undefined;
                    const updatedDate = i.updatedAt
                      ? moment(i.updatedAt).calendar()
                      : undefined;
                    return {
                      id: i.id,
                      make: i.make.name,
                      model: i.name,
                      type: i.type.title,
                      size: i.size.title,
                      listings: null,
                      createdAt: createdDate,
                      updatedAt: updatedDate,
                      oMake: i.make,
                      oType: i.type,
                      oSize: i.size
                    };
                  });
                }

                this.setState(
                  {
                    isLoading: false,
                    error: undefined,
                    results: listings,
                    count: response.data.data.count,
                    pages: response.data.data.pageCount
                  },
                  () => {
                    this.props.onFetchSuccess &&
                      this.props.onFetchSuccess(this.state);
                  }
                );
              })
              .catch(error => {
                this.setState(
                  {
                    isLoading: false,
                    results: [],
                    error: error,
                    count: 0,
                    pages: 0
                  },
                  () => {
                    this.props.onFetchError &&
                      this.props.onFetchError(this.state);
                  }
                );
              });
          }
        }
      );
    }
  }

  handlePageLimitChange = event => {
    const target = event.currentTarget;

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma"
    });
    const updatedObj = { ...currentParams, show: target.value };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma"
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString()
    });
  };
  getPageLimit = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma"
    });
    const pageLimit = currentParams["show"] || 10;
    return parseInt(pageLimit);
  };
  handlePageChange = pager => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma"
    });
    const updatedObj = { ...currentParams, page: pager.currentPage };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma"
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString()
    });
  };
  getPage = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma"
    });
    const page = currentParams["page"] || 1;
    return parseInt(page);
  };

  handleSearchChange = e => {
    this.setState({ search: e.target.value });
  };

  handleSearchSubmit = e => {
    e.preventDefault();

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma"
    });
    const updatedObj = { ...currentParams, keyword: this.state.search };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma"
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString()
    });
  };

  pageTitle(status) {
    switch (status) {
      case "archived":
        return (
          <span>
            Aircraft For Sale <strong>Archive</strong>
          </span>
        );
      default:
        return (
          <span>
            Aircraft <strong>For Sale</strong>
          </span>
        );
    }
  }

  pageActions(status) {
    return (
      <>
        <Button
          className="btn btn-green ml-2"
          onClick={e => this.AddEditMakeModal()}
        >
          + New Make
        </Button>
        <Button
          className="btn btn-green ml-2"
          onClick={e => this.AddEditModelModal()}
        >
          + New Model
        </Button>
      </>
    );
  }

  addEditMake = make => {
    if (make && make.name) {
      if (make.slug) {
        // This is an existing Make. Update it.
        axios
          .put(`/makes/${make.slug}`, make)
          .then(response => {
            const updatedMake = response.data.data;

            // Update Make Filters
            const filterMakes = this.state.filterMakes.map(m => {
              if (m.value === make.slug) {
                return {
                  title: updatedMake.name,
                  value: updatedMake.slug
                };
              }
              return m;
            });

            // Update Result Makes
            const results = this.state.results.map(r => {
              if (r.oMake.slug === make.slug) {
                r.make = updatedMake.name;
                r.oMake = {
                  id: updatedMake.id,
                  name: updatedMake.name,
                  slug: updatedMake.slug
                };
              }
              return r;
            });

            this.setState({ filterMakes, results });
          })
          .catch(err => {
            console.error(err);
            this.props.openMessageModal(
              `Error Updating Make: ${JSON.stringify(err)}`,
              () => (
                <button
                  className="btn btn-green"
                  onClick={() => {
                    this.props.closeMessageModal();
                  }}
                >
                  Okay
                </button>
              )
            );
          });
      } else {
        // This is a new Make. Add it.
        axios
          .post(`/makes/`, make)
          .then(response => {
            const addedMake = response.data.data;

            // Update Make Filters
            const filterMakes = this.state.filterMakes.slice();
            filterMakes.push({
              title: addedMake.name,
              value: addedMake.slug
            });

            // Sort alphabetically
            filterMakes.sort((a, b) => (a.title > b.title ? 1 : -1));

            this.setState({ filterMakes });
          })
          .catch(err => {
            console.error(err);
            this.props.openMessageModal(
              `Error Adding Make: ${JSON.stringify(err)}`,
              () => (
                <button
                  className="btn btn-green"
                  onClick={() => {
                    this.props.closeMessageModal();
                  }}
                >
                  Okay
                </button>
              )
            );
          });
      }
    }
  };

  addEditModel = model => {
    if (model && model.name) {
      if (model.id) {
        // This is an existing Model. Update it.
        axios
          .put(`/makes/${model.make.value}/${model.id}`, {
            name: model.name,
            type: model.type.value,
            size: model.size ? model.size.value : null
          })
          .then(response => {
            this.fetchResults(true);
          })
          .catch(err => {
            console.error(err);
            this.props.openMessageModal(
              `Error Updating Model: ${JSON.stringify(err)}`,
              () => (
                <button
                  className="btn btn-green"
                  onClick={() => {
                    this.props.closeMessageModal();
                  }}
                >
                  Okay
                </button>
              )
            );
          });
      } else {
        // This is a new Make. Add it.
        axios
          .post(`/makes/${model.make.value}`, {
            name: model.name,
            type: model.type.value,
            size: model.size ? model.size.value : null
          })
          .then(response => {
            this.fetchResults(true);
          })
          .catch(err => {
            console.error(err);
            this.props.openMessageModal(
              `Error Adding Model: ${JSON.stringify(err)}`,
              () => (
                <button
                  className="btn btn-green"
                  onClick={() => {
                    this.props.closeMessageModal();
                  }}
                >
                  Okay
                </button>
              )
            );
          });
      }
    }
  };

  deleteModel = id => {
    axios
      .delete(`/models/${id}`)
      .then(response => {
        this.fetchResults(true);
      })
      .catch(err => {
        this.props.openMessageModal(
          `Error Deleting Model: ${JSON.stringify(err)}`,
          () => (
            <button
              className="btn btn-green"
              onClick={() => {
                this.props.closeMessageModal();
              }}
            >
              Okay
            </button>
          )
        );
      });
  };

  AddEditMakeModal = make => {
    this.props.openMessageModal(
      () => (
        <AddEditMakeForm
          make={make}
          onChange={make => {
            this.addEditMake(make);
            this.props.closeMessageModal();
          }}
        />
      ),
      () => <></>,
      true
    );
  };

  AddEditModelModal = model => {
    this.props.openMessageModal(
      () => (
        <AddEditModelForm
          id={model ? model.id : undefined}
          name={model ? model.model : undefined}
          make={
            model && model.oMake
              ? { value: model.oMake.slug, title: model.oMake.name }
              : undefined
          }
          makes={this.state.filterMakes}
          type={model ? model.oType : undefined}
          types={this.state.filterTypes}
          size={model ? model.oSize : undefined}
          sizes={this.state.filterSizes}
          onChange={model => {
            this.addEditModel(model);
            this.props.closeMessageModal();
          }}
        />
      ),
      () => <></>,
      true
    );
  };

  DeleteModelModal = model => {
    this.props.openMessageModal(
      `Are you sure you want to delete ${model.make} ${model.model}?`,
      () => (
        <>
          <Button
            className="btn btn-green"
            onClick={e => {
              this.deleteModel(model.id);
              this.props.closeMessageModal();
            }}
          >
            Delete
          </Button>
          <Button
            className="btn btn-secondary"
            onClick={e => {
              this.props.closeMessageModal();
            }}
          >
            Cancel
          </Button>
        </>
      ),
      true
    );
  };

  render() {
    const columns = [
      {
        dataField: "model",
        text: "Model",
        sort: true
      },
      {
        dataField: "make",
        text: "Make",
        sort: true,
        headerFormatter: (column, colIndex) => (
          <MultiSelectFilter column={column} items={this.state.filterMakes} />
        )
      },
      {
        dataField: "type",
        text: "Type",
        sort: true,
        headerFormatter: (column, colIndex) => (
          <MultiSelectFilter column={column} items={this.state.filterTypes} />
        )
      },
      {
        dataField: "size",
        text: "Size",
        sort: true,
        headerFormatter: (column, colIndex) => (
          <MultiSelectFilter column={column} items={this.state.filterSizes} />
        )
      },
      {
        dataField: "listings",
        text: "Listings",
        sort: false
      },
      {
        dataField: "createdAt",
        text: "Created",
        sort: true
      },
      {
        dataField: "updatedAt",
        text: "Updated",
        sort: true
      }
    ];

    return (
      <section
        id="app-settings-makes-models"
        className="app-settings-makes-models"
      >
        <div className="d-flex justify-content-end mb-3">
          {this.pageActions(this.props.status)}
        </div>
        <div className="table-layout" id="table-sales">
          <Table
            id="sales"
            keyField="id"
            columns={columns}
            data={this.state.results}
            expandRow={{
              onlyOneExpanding: true,
              renderer: row => {
                return (
                  <div className="row-actions">
                    <Button
                      className="btn btn-green"
                      onClick={() => {
                        this.AddEditMakeModal(row.oMake);
                      }}
                    >
                      Edit Make
                    </Button>
                    <Button
                      className="btn btn-green"
                      onClick={() => {
                        this.AddEditModelModal(row);
                      }}
                    >
                      Edit Model
                    </Button>
                    <Button
                      className="btn btn-red"
                      onClick={() => {
                        this.DeleteModelModal(row);
                      }}
                    >
                      Delete Model
                    </Button>
                  </div>
                );
              }
            }}
          />
          <footer className="table-bottom table-footer">
            <Row>
              <Col
                sm={6}
                className="table-footer-stats table-stats justify-content-center justify-content-sm-start"
              >
                <span className="row-count">
                  {this.state.count} total records. {this.state.results.length}{" "}
                  rows showing
                </span>
              </Col>
              <Col
                sm={6}
                className="table-footer-pagination table-pagination text-sm-right justify-content-center justify-content-sm-end"
              >
                <Pagination
                  totalRecords={this.state.count}
                  pageLimit={this.getPageLimit()}
                  pageNeighbours={1}
                  onPageChanged={this.handlePageChange}
                  currentPage={this.getPage()}
                />
              </Col>
            </Row>
          </footer>
        </div>
      </section>
    );
  }
}

const keyStateToProps = state => {
  return {};
};

const keyDispatchToProps = dispatch => {
  return {
    openMessageModal: (message, actions, showClose) =>
      dispatch(openMessageModal({ message, actions, showClose })),
    closeMessageModal: () => dispatch(closeMessageModal())
  };
};

function AddEditMakeForm(props) {
  const [make, setMake] = useState(props.make);

  return (
    <div>
      <h2 className="mb-4">
        {make && make.slug ? "Edit Make" : "Add New Make"}
      </h2>
      <Form.Control
        as="input"
        type="text"
        name="make"
        onChange={e => {
          const name = e.target.value;
          setMake({ ...make, name });
        }}
        value={make ? make.name : ""}
      ></Form.Control>
      {make && make.name !== "" && (
        <button
          className="btn btn-green btn-lg"
          onClick={() => {
            props.onChange(make);
          }}
        >
          Save Make
        </button>
      )}
    </div>
  );
}

function AddEditModelForm(props) {
  const [make, setMake] = useState(props.make);
  const [type, setType] = useState(props.type);
  const [size, setSize] = useState(props.size);
  const [name, setName] = useState(props.name);

  return (
    <div>
      <h2 className="mb-4">{name ? "Edit Model" : "Add New Model"}</h2>
      <Form.Group>
        <Row>
          <Col xs={12} sm={4} md={3} lg={2} className="text-left text-sm-right">
            <Form.Label className="mr-3">Make:</Form.Label>
          </Col>
          <Col xs={12} sm={8} md={9} lg={10}>
            <Form.Control
              as="select"
              onChange={e => {
                const val = e.currentTarget.value;
                setMake(props.makes.filter(m => m.value === val).shift());
              }}
              name="make"
              value={make ? make.value : ""}
            >
              <option></option>
              {props.makes.map((make, i) => {
                return (
                  <option value={make.value} key={i}>
                    {make.title}
                  </option>
                );
              }, this)}
            </Form.Control>
          </Col>
        </Row>
      </Form.Group>
      {make && (
        <Form.Group>
          <Row>
            <Col
              xs={12}
              sm={4}
              md={3}
              lg={2}
              className="text-left text-sm-right"
            >
              <Form.Label className="mr-3">Type:</Form.Label>
            </Col>
            <Col xs={12} sm={8} md={9} lg={10}>
              <Form.Control
                as="select"
                onChange={e => {
                  const val = e.currentTarget.value;
                  setType(props.types.filter(t => t.value === val).shift());
                }}
                name="type"
                value={type ? type.value : ""}
              >
                <option></option>
                {props.types.map((type, i) => {
                  return (
                    <option value={type.value} key={i}>
                      {type.title}
                    </option>
                  );
                }, this)}
              </Form.Control>
            </Col>
          </Row>
        </Form.Group>
      )}

      {type && type.value === "jet" && (
        <Form.Group>
          <Row>
            <Col
              xs={12}
              sm={4}
              md={3}
              lg={2}
              className="text-left text-sm-right"
            >
              <Form.Label className="mr-3">Size:</Form.Label>
            </Col>
            <Col xs={12} sm={8} md={9} lg={10}>
              <Form.Control
                as="select"
                onChange={e => {
                  const val = e.currentTarget.value;
                  setSize(props.sizes.filter(s => s.value === val).shift());
                }}
                name="size"
                value={size ? size.value : ""}
              >
                <option></option>
                {props.sizes.map((size, i) => {
                  return (
                    <option value={size.value} key={i}>
                      {size.title}
                    </option>
                  );
                }, this)}
              </Form.Control>
            </Col>
          </Row>
        </Form.Group>
      )}

      {make && type && (
        <Form.Group>
          <Row>
            <Col xs={12} sm={4} md={3} lg={2}>
              <Form.Label className="mr-3">Name:</Form.Label>
            </Col>
            <Col xs={12} sm={8} md={9} lg={10}>
              <Form.Control
                as="input"
                type="text"
                name="name"
                onChange={e => {
                  const name = e.target.value;
                  setName(name);
                }}
                value={name || ""}
              ></Form.Control>
            </Col>
          </Row>
        </Form.Group>
      )}

      {make && type && name && (
        <button
          className="btn btn-green btn-lg"
          onClick={() => {
            props.onChange({
              id: props.id,
              name,
              make,
              type,
              size
            });
          }}
        >
          Save Model
        </button>
      )}
    </div>
  );
}

export default connect(keyStateToProps, keyDispatchToProps)(MakesModelsManage);
