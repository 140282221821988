import React, { useState } from "react";
import Col from 'react-bootstrap/Col';
import Card from './card';

export default function SearchCardList() {
  const [limit, setLimit] = useState(4);
  const data = [0, 1, 3, 4, 5, 6];
  return (
    <div className="search-block__list">
      {
        data.slice(0, limit).map((item, i) => <Card key={i} />)
      }
      {
        data.length > limit && 
        (<Col className="d-flex justify-content-center">
          <button className="search-block__list__load-more" onClick={() => setLimit(data.length)}>see all 24 matches</button>
        </Col>)
      }
    </div>
  );
}