import React, { PureComponent, Fragment } from "react";
import { connect } from "react-redux";
import axios from "axios";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "components/shared/spinner";

import { Formik } from "formik";
import { object, string } from "yup";

import stage from "../stage";

import { finalizeAccount } from "actions/user/actions";

const schema = object().shape({
  companyType: string().required("Please select 1 option"),
  selectedCompany: string().when("companyType", {
    is: (val) => (val && val === "exist" ? true : false),
    then: string().required("Please select company"),
  }),
});

class SelectedAdvertise extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accountType: {
        isLoaded: false,
      },
      companies: [],
    };
  }

  componentDidMount() {
    const registration = JSON.parse(localStorage.getItem("registration"));

    this.setState({
      accountType: {
        ...registration.accountType,
        isLoaded: true,
      },
      email: registration.email,
    });

    axios.get("/company?limit=0").then((response) => {
      this.setState({ companies: response.data.data.rows });
    });
  }

  handleSubmit(values, setSubmitting) {
    setSubmitting(true);

    switch (values.companyType) {
      case "new":
        // New company. Advance to create screen
        setSubmitting(false);
        this.handleChangeStage(values, stage.newCompany);
        break;
      case "exist":
        const salesRepInfo = {
          company: values.selectedCompany,
          role: "sales-rep-view",
        };

        // Login user, update account info, and proceed to Welcome screen
        this.props
          .finalizeAccount(this.props.code, salesRepInfo)
          .then((data) => {
            localStorage.removeItem("registration");
            setSubmitting(false);
            this.props.handleChangeStage(stage.welcome, data);
          });
        break;
      case "individual":
      default:
        // New Individual. Advance to create Payment screen
        const advertiserInfo = {
          role: "advertiser",
          ...values,
        };
        setSubmitting(false);
        this.handleChangeStage(advertiserInfo, stage.payment);
        break;
    }
  }

  handleChangeStage(values, nextStage) {
    let registration = JSON.parse(localStorage.getItem("registration"));
    registration.stage = nextStage;
    registration.accountType = values;
    localStorage.setItem("registration", JSON.stringify(registration));
    this.props.handleChangeStage(nextStage);
  }

  render() {
    const { accountType } = this.state;
    if (accountType.isLoaded) {
      return (
        <Row className="selected-advertise">
          <Col sm={12} md={6}>
            <h2 className="page-title">
              Thank you for <strong>Verifying your email!</strong>
            </h2>
            <Formik
              validationSchema={schema}
              onSubmit={(values, { setSubmitting }) =>
                this.handleSubmit(values, setSubmitting)
              }
              initialValues={{
                companyType: accountType.companyType || "",
                selectedCompany: accountType.selectedCompany || "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
                touched,
                isSubmitting,
              }) => (
                <Form
                  onSubmit={
                    isSubmitting
                      ? (e) => {
                          e.preventDefault();
                        }
                      : handleSubmit
                  }
                >
                  <Form.Group>
                    <Form.Label as="legend">
                      Check to see if your company already exists in our system.
                    </Form.Label>
                    <div className="company-type exist-company">
                      <Form.Check
                        custom
                        id="exist"
                        type="radio"
                        name="companyType"
                        label="Company:"
                        checked={values.companyType === "exist"}
                        value="exist"
                        onChange={handleChange}
                        isInvalid={!!errors.companyType}
                      />
                      <div className="selected-company">
                        <Form.Control
                          disabled={values.companyType !== "exist"}
                          name="selectedCompany"
                          value={values.selectedCompany}
                          onChange={handleChange}
                          as="select"
                          isInvalid={
                            touched.selectedCompany && !!errors.selectedCompany
                          }
                        >
                          <option value="">Select Company</option>
                          {this.state.companies.map((company, key) => (
                            <option value={company.slug} key={key}>
                              {company.name}
                            </option>
                          ))}
                        </Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.selectedCompany}
                        </Form.Control.Feedback>
                      </div>
                    </div>
                    <Form.Check
                      custom
                      id="new"
                      type="radio"
                      name="companyType"
                      label="My company is not in your system."
                      checked={values.companyType === "new"}
                      value="new"
                      onChange={handleChange}
                      className="company-type"
                      isInvalid={!!errors.companyType}
                    />
                    <Form.Check
                      custom
                      id="individual"
                      type="radio"
                      name="companyType"
                      label="I am an individual without a company."
                      checked={values.companyType === "individual"}
                      value="individual"
                      onChange={handleChange}
                      className="company-type"
                      isInvalid={!!errors.companyType}
                      feedback={errors.companyType}
                    />
                  </Form.Group>
                  {values.companyType === "exist" && values.selectedCompany ? (
                    <Fragment>
                      <p className="sub-header notified">
                        Your company administrator will be notified of your
                        request for access.
                      </p>
                      <div className="buttons">
                        <Button
                          className="btn-green continue-button"
                          type="submit"
                        >
                          start browsing
                        </Button>
                      </div>
                    </Fragment>
                  ) : (
                    <div className="buttons">
                      <Button
                        className="btn-green continue-button"
                        type="submit"
                        disabled={!values.companyType}
                      >
                        continue
                      </Button>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      );
    } else {
      return <Spinner />;
    }
  }
}

const keyStateToProps = (state) => {
  return {};
};

const keyDispatchToProps = (dispatch) => {
  return {
    finalizeAccount: (code, accountInfo) =>
      dispatch(finalizeAccount(code, accountInfo)),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(SelectedAdvertise);
