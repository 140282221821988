import { SET_LISTS } from "actions/email/actionTypes";

const initialState = [];

export default function masterDataReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case SET_LISTS:
      return payload;
    default:
      return state;
  }
}
