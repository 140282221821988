import { SET_NOTIFICATIONS } from "actions/notification/actionTypes";

const initialState = [];

export default function notificationReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case SET_NOTIFICATIONS:
      return [...payload].slice(0, 10);
    default:
      return state;
  }
}
