import axios from "axios";
import apis from "apis";
import {
  GET_COMPANIES,
  SET_COMPANIES,
  ADD_COMPANY,
  REGISTER_COMPANY,
  UPDATE_COMPANY,
  ADD_COMPANY_LOGO,
  ADD_COMPANY_PAYMENT,
  DELETE_COMPANY_PAYMENT,
  UPGRADE_COMPANY_PLAN,
} from "./actionTypes.js";

export function getCompanies() {
  return function (dispatch) {
    dispatch({
      type: GET_COMPANIES,
    });

    return axios({
      method: "get",
      url: `${apis.company}?limit=0`,
    })
      .then((response) => {
        const { data } = response.data;
        return dispatch(setCompanies(data.rows));
      })
      .catch((error) => {});
  };
}

export function setCompanies(companies) {
  return {
    type: SET_COMPANIES,
    payload: companies,
  };
}

export const addCompany = (company) => (dispatch) => {
  dispatch({
    type: ADD_COMPANY,
  });

  return axios({
    method: "post",
    url: apis.company,
    data: company,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

export const registerCompany = (company, code) => (dispatch) =>
  new Promise(function (resolve, reject) {
    dispatch({
      type: REGISTER_COMPANY,
    });
    // Function is expected to return a promise
    axios({
      method: "post",
      url: `${apis.register}/${code}/company`,
      data: company,
    })
      .then((response) => {
        const { data } = response.data;
        //return dispatch(setCompanies(data));
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export function updateCompany(company) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_COMPANY,
    });

    return axios({
      method: "put",
      url: apis.company,
      data: company,
    })
      .then((response) => {
        const { data } = response.data;
        return dispatch(setCompanies(data));
      })
      .catch((error) => {});
  };
}

export const addLogo = (slug, logo, code) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: ADD_COMPANY_LOGO,
    });
    // Function is expected to return a promise
    let formdata = new FormData();
    formdata.append("image", logo);

    let url = `/company/${slug}/logo`;
    if (code) url = `${apis.register}/${code}/company/${slug}/logo`;

    axios
      .post(url, formdata, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        const { response } = res.data;
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

export const addPayment = (slug, payment) => (dispatch) => {
  dispatch({
    type: ADD_COMPANY_PAYMENT,
  });

  return axios({
    method: "put",
    url: `${apis.company}/${slug}/payment`,
    data: payment,
  })
    .then((response) => {
      const { payment } = response.data;
      return payment;
    })
    .catch((error) => {
      throw error;
    });
};

export const deletePayment = (slug) => (dispatch) => {
  dispatch({
    type: DELETE_COMPANY_PAYMENT,
  });

  return axios({
    method: "delete",
    url: `${apis.company}/${slug}/payment`,
  })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
};

export const upgradePlan = (slug, plan) => (dispatch) => {
  dispatch({
    type: UPGRADE_COMPANY_PLAN,
  });

  return axios({
    method: "put",
    url: `${apis.company}/${slug}/plan`,
    data: { plan },
  }).then((response) => {
    const { data } = response.data;
    //dispatch(setCompanies(data));
    return data;
  });
};

export const companyRedirectCheck = (legacyId) => {
  return axios.get(`/company/redirect/${legacyId}`).then((res) => {
    const { result } = res.data;
    return result.slug;
  });
};
