import React from 'react';
import { Form } from "react-bootstrap";

const Input = (props) => {
    if (props.as === 'select') {
        return (<>
            <Form.Control
                as={props.as}
                name={props.name}
                value={props.value}
                onChange={props.handleChange}
            >
                {props.options.map((opt, i) => {
                    return (<option key={i}>{opt}</option>)
                })}
            </Form.Control>
        </>
        )
    } else if (props.as === 'input') {
        return (
            <>
                <Form.Control
                    as={props.as}
                    name={props.name}
                    type={props.type}
                    value={props.value}
                    onChange={props.handleChange}
                    placeholder={props.placeholder}
                />
            </>
        )
    } else if (props.as === 'checkbox') {
        return (
            <>
                <Form.Label className="custom_checkbox">
                    <input
                        id={props.name}
                        name={props.name}
                        onChange={props.handleChange}
                        value={props.value}
                        checked={props.selectedOptions.indexOf(props.value) > -1}
                        type={props.as}
                    />
                    <span className="checkmark"></span>
                </Form.Label>
                <Form.Label className="text-capitalize">{props.title}</Form.Label>
            </>
        )
    } else if (props.as === 'textarea') {
        return (
            <>
                <Form.Control
                    as={props.as}
                    rows={props.rows}
                    name={props.name}
                    value={props.value}
                    onChange={props.handleChange}
                    placeholder={props.placeholder}
                />
            </>
        )
    }
}

export default Input;