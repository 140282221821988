import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import _ from "lodash";

const routes = {
  // Config
  separator: " | ",
  site: "Plane Mover, Inc",
  domain: "http://www.planemover.com",
  null: "#",
  login: "/login",
  forbidden: "/forbidden",

  // Frontend - Pages
  home: "/",
  advertise: "/advertise",
  pricing: "/advertise/subscriptions",
  privacy: "/privacy-policy",
  terms: "/terms-and-conditions",

  // Frontend - Listings
  forSale: "/for-sale",
  forSaleDetail: "/for-sale/:listing",

  services: "/services",
  servicesDetail: "/services/:listing",

  wanted: "/wanted",
  wantedDetail: "/wanted/:listing",

  company: "/company/:company",
  companyWanted: "/company/:company/wanted",
  companyServices: "/company/:company/services",
  companyManage: "/admin/companies",
  companyAdd: "/admin/company/add",
  companyEdit: "/admin/company/:company?/:tab?",

  // Frontend - Account
  verification: "/account/verification/:code",
  registration: "/account/registration/:code",
  resetPassword: "/account/password/:code",

  // Backend
  admin: "/admin",
  notifications: "/admin/notifications",

  forSaleManage: "/admin/for-sale", // Backend List of "My Aircraft"
  forSaleEdit: "/admin/for-sale/edit/:listing?/:step?", // Backend Add/Edit for "My Aircraft"
  forSaleStatus: "/admin/for-sale/:listing/status/:status?", // Backend Add/Edit for "My Aircraft"
  forSaleArchive: "/admin/for-sale/archive", // Backend Archive for "My Aircraft"

  servicesManage: "/admin/services", // Backend List of "My Services"
  servicesEdit: "/admin/services/edit/:listing?/:step?", // Backend Add/Edit for "My Services"
  servicesStatus: "/admin/services/:listing/status/:status?", // Backend Add/Edit for "My Services"
  servicesArchive: "/admin/services/archive", // Backend Archive for "My Services"

  wantedManage: "/admin/wanted", // Backend List of "My Wanted"
  wantedEdit: "/admin/wanted/edit/:listing?/:step?", // Backend Add/Edit for "My Wanted"
  wantedStatus: "/admin/wanted/:listing/status/:status?", // Backend Add/Edit for "My Services"
  wantedArchive: "/admin/wanted/archive", // Backend Archive for "My Wanted"

  // Promotions
  promotions: "/admin/promotions/:section?",
  promotionsNew: "/admin/promotions/new",
  promotionsSubmitted: "/admin/promotions/new/complete",
  promotionsEdit: "/admin/promotions/edit/:promotion?",

  // Inbox
  inbox: "/admin/inbox",
  inboxArchived: "/admin/inbox/archive",

  usersManage: "/admin/users",
  usersAdd: "/admin/users/add",
  accountSettings: "/admin/account-settings/:email?/:tab?",

  favorites: "/admin/favorites/:type?",
  mySavedSearches: "/admin/my-saved-searches",

  siteSettings: "/admin/site-settings/:section?",
};
export default routes;

export const createTitle = (pageTitle, routes) => {
  return pageTitle + routes.separator + routes.site;
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const loginRedirect = (origin) => {
  let redirect = routes.login;
  if (origin) {
    redirect = `${redirect}?redirect=${origin}`;
  }
  return redirect;
};

export const UserRoute = connect(mapStateToProps)(
  ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        user={rest.userInfo}
        render={(props) => {
          if (!_.isEmpty(rest.userInfo)) {
            return <Component {...props} />;
          }
          console.warn("Unauthorized to access this page");
          return <Redirect to={loginRedirect(props.location.pathname)} />;
        }}
      />
    );
  }
);
export const SalesRepViewRoute = connect(mapStateToProps)(
  ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (!_.isEmpty(rest.userInfo)) {
            if (
              rest.userInfo.role &&
              rest.userInfo.role.value &&
              rest.userInfo.role.value &&
              [
                "advertiser",
                "sales-rep-view",
                "sales-rep",
                "company-admin",
                "super-admin",
              ].includes(rest.userInfo.role.value)
            ) {
              return <Component {...props} />;
            }
            console.warn("Account forbidden to access this page");
            return <Redirect to={routes.forbidden} />;
          }

          console.warn("Unauthorized to access this page");
          return <Redirect to={loginRedirect(props.location.pathname)} />;
        }}
      />
    );
  }
);

export const SalesRepRoute = connect(mapStateToProps)(
  ({ component: Component, render: Render, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (!_.isEmpty(rest.userInfo)) {
            if (
              rest.userInfo.role &&
              rest.userInfo.role.value &&
              [
                "advertiser",
                "sales-rep",
                "company-admin",
                "super-admin",
              ].includes(rest.userInfo.role.value)
            ) {
              if (Component) {
                return <Component {...props} />;
              } else if (Render) {
                return Render();
              }
            }
            console.warn("Account forbidden to access this page");
            return <Redirect to={routes.forbidden} />;
          }

          console.warn("Unauthorized to access this page");
          return <Redirect to={loginRedirect(props.location.pathname)} />;
        }}
      />
    );
  }
);

export const CompanyAdminRoute = connect(mapStateToProps)(
  ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (!_.isEmpty(rest.userInfo)) {
            if (
              rest.userInfo.role &&
              rest.userInfo.role.value &&
              ["company-admin", "super-admin"].includes(
                rest.userInfo.role.value
              )
            ) {
              return <Component {...props} />;
            }
            console.warn("Account forbidden to access this page");
            return <Redirect to={routes.forbidden} />;
          }
          console.warn("Unauthorized to access this page");
          return <Redirect to={loginRedirect(props.location.pathname)} />;
        }}
      />
    );
  }
);

export const SuperAdminRoute = connect(mapStateToProps)(
  ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (!_.isEmpty(rest.userInfo)) {
            if (
              rest.userInfo.role &&
              rest.userInfo.role.value &&
              rest.userInfo.role.value === "super-admin"
            ) {
              return <Component {...props} />;
            }
            console.warn("Account forbidden to access this page");
            return <Redirect to={routes.forbidden} />;
          }
          console.warn("Unauthorized to access this page");
          return <Redirect to={loginRedirect(props.location.pathname)} />;
        }}
      />
    );
  }
);
