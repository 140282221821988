import React, { Component } from 'react';
import { withBreakpoints } from 'react-breakpoints';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import SearchTitle from './searchTitle';
import SearchActions from './searchActions';
import SearchFilter from './searchFilter';
import SearchCardSlider from './searchCardSlider';
import SearchCardList from './searchCardList';

class SearchBlock extends Component {
  render() {
    const { breakpoints, currentBreakpoint } = this.props;

    return (
      <Container className="search-block">
        <Row className="search-block__header">
          <SearchTitle
            value="Dad’s Birthday"
          />
          <SearchActions />
          <SearchFilter />
        </Row>
        <Row>
          {
            breakpoints[currentBreakpoint] < breakpoints.forMobileUp ? <SearchCardList /> :  <SearchCardSlider />
          }
        </Row>
      </Container>
    );
  }
}

SearchBlock.propTypes = {
};

export default withBreakpoints(SearchBlock);