import React from "react";
import { Link, generatePath } from "react-router-dom";
import { connect } from "react-redux";

import { openLogin, openNewsletterModal } from "actions/modal/actions";
import { logOut } from "actions/user/actions";
import Routes from "routes";

import LogoWhite from "images/plane-mover-logo-white.png";
import NBAALogo from "images/content/NBAA-member.png";

class Footer extends React.Component {
  handleClickLogin() {
    this.props.openLogin();
  }

  handleClickLogout() {
    this.props.logOut();
  }

  handleClickNewsletter() {
    this.props.openNewsletter();
  }

  render() {
    const { userInfo } = this.props;
    return (
      <footer id="app-footer" className="app-footer">
        <div className="container">
          <div className="row">
            <div
              id="app-footer-center"
              className="col-12 col-md-6 order-md-1 col-lg-8 text-center mb-5"
            >
              <span>Your Destination for Aviation Advertising Since 2006</span>
            </div>

            <div
              id="app-footer-left"
              className="col-12 col-sm-6 col-md-3 order-md-0 col-lg-2 text-left"
            >
              <div id="app-footer-menu" className="app-footer-menu-row">
                <ul
                  id="app-footer-menu-primary"
                  className="footer-menu primary"
                >
                  <li>
                    {!userInfo && (
                      <button
                        onClick={() => this.handleClickLogin()}
                        className="menu-item menu-text"
                      >
                        Log In
                      </button>
                    )}
                    {userInfo && (
                      <button
                        className="menu-item menu-text"
                        onClick={this.handleClickLogout}
                      >
                        Log Out
                      </button>
                    )}
                  </li>
                  <li>
                    {!userInfo && (
                      <button
                        onClick={() => this.handleClickNewsletter()}
                        className="menu-item menu-text"
                      >
                        Get Email Blitzes
                      </button>
                    )}
                    {userInfo && (
                      <Link
                        to={generatePath(Routes.accountSettings, {
                          email: userInfo.email,
                          step: "email",
                        })}
                        className="menu-item menu-text"
                      >
                        Get Email Blitzes
                      </Link>
                    )}
                  </li>
                  <li>
                    <a href="mailto:sales@planemover.com?subject=Inquiry from Website">
                      Contact
                    </a>
                  </li>
                </ul>
                <ul
                  id="app-footer-menu-secondary"
                  className="footer-menu secondary"
                >
                  <li>
                    <Link to={Routes.terms}>Terms &amp; Conditions</Link>
                  </li>
                  <li>
                    <Link to={Routes.privacy}>Privacy Policy</Link>
                  </li>
                </ul>
              </div>
              <div className="app-footer-logos mb-5">
                <a
                  href="https://nbaa.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="footer-logo-link logo-link"
                  title="National Business Aviation Association Member"
                >
                  <img
                    src={NBAALogo}
                    alt="National Business Aviation Association logo"
                  />
                </a>
              </div>
            </div>

            <div
              id="app-footer-right"
              className="col-12 col-sm-6 col-md-3 order-md-2 col-lg-2 text-center"
            >
              <div id="app-footer-right-logo-row">
                <Link to="/" title="Plane Mover">
                  <img
                    id="app-header-logo"
                    src={LogoWhite}
                    alt="Plane Mover"
                    className="app-logo img-fluid"
                  />
                </Link>
              </div>
              <div id="app-footer-right-copyright-row">
                <div className="copyright">
                  Copyright 2019 Plane Mover, Inc. All Rights Reserved.
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    openLogin: () => dispatch(openLogin()),
    logOut: () => dispatch(logOut()),
    openNewsletter: () => dispatch(openNewsletterModal()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(Footer);
