import React from "react";
import { connect } from "react-redux";
import Favorite from "components/frontend/listings/_shared/favorite";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EmailShareButton } from "react-share";

import { setToast } from "actions/toast/actions.js";

const SocialButtons = (props) => {
  const { listing, setToast } = props;
  return (
    <div className="btn-social-list">
      <EmailShareButton
        url={window.location.href}
        className="btn btn-social"
        subject={`PlaneMover: ${listing.title}`}
        body={`Check out this listing on PlaneMover.com:\n\r${listing.title}\n\r${window.location.href}`}
      >
        <FontAwesomeIcon icon={faEnvelope} />
      </EmailShareButton>

      <button
        className="btn btn-social"
        onClick={() => {
          navigator.clipboard.writeText(window.location.href);
          setToast({
            type: "success",
            message: "Link Copied to Clipboard",
          });
        }}
      >
        <FontAwesomeIcon icon={faLink} />
      </button>
      <Favorite {...props} />
    </div>
  );
};

const keyStateToProps = (state) => {
  return {};
};

const keyDispatchToProps = (dispatch) => {
  return {
    setToast: (payload) => dispatch(setToast(payload)),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(SocialButtons);
