import React, { Fragment } from "react";
import Slider from "react-slick";
import Carousel, { Modal, ModalGateway } from "react-images";

import { ReactComponent as ZoomInIcon } from "images/icons/zoom-in.svg";
import CarouselPlaceholder from "images/listings/carousel.png";

const settings = {
	className: "image-carousel",
	dots: false,
	infinite: false,
	slidesToShow: 4,
	slidesToScroll: 4,
	arrows: false,
	responsive: [
		{
			breakpoint: 991,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3,
				initialSlide: 2
			}
		},
		{
			breakpoint: 767,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		},
		{
			breakpoint: 575,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1
			}
		}
	]
};

export default class ImageCarousel extends React.Component {
	state = {
		selectedIndex: 0,
		lightboxIsOpen: false
	};

	toggleLightbox = selectedIndex => {
		this.setState(prevState => ({
			lightboxIsOpen: !prevState.lightboxIsOpen,
			selectedIndex
		}));
	};

	render() {
		const { images } = this.props;
		const { selectedIndex, lightboxIsOpen } = this.state;
		const count = images.length;

		const modalImages = images.map(i => ({src: i.url}))

		return (
			<Fragment>
				<Slider {...settings}>
					{images.map((item, i) => (
						<ImageItem key={i} image={item} count={count} index={i} toggleLightbox={this.toggleLightbox} />
					))}
				</Slider>
				<ModalGateway>
					{lightboxIsOpen && (
						<Modal onClose={this.toggleLightbox}>
							<Carousel currentIndex={selectedIndex} views={modalImages} />
						</Modal>
					)}
				</ModalGateway>
				
			</Fragment>
		);
	}
}

function ImageItem(props) {
	const {image, index, count} = props;
	return (
		<div className="image-item" style={{backgroundImage: `url('${image.url}')`}}>
			<div className="count">
				<span className="image-index">{index + 1}</span>
				<span className="image-of"> of </span>
				<span className="image-count">{count}</span>
			</div>
			<button className="btn btn-tertiary zoom-button" onClick={() => props.toggleLightbox(index)}>
				<ZoomInIcon />
			</button>
			<img src={CarouselPlaceholder} alt={image.uid} />
		</div>
	);
}
