import React from "react";

import InformationBlock from "components/frontend/listings/for-sale/shared/informationBlock";

export default function Airframe(props) {
  const { listing } = props;
  const airframeMaint = () => {
    if (listing.airframeMaintenance) {
      return listing.airframeMaintenance.title;
    }
    return <em>Unknown</em>;
  };
  const totalTime = () => {
    if (listing.totalTime) {
      const formattedString = new Intl.NumberFormat("en-us").format(
        listing.totalTime
      );
      return `${formattedString} hours`;
    }
    return <em>Unknown</em>;
  };
  const landings = () => {
    if (listing.landings) {
      const formattedString = new Intl.NumberFormat("en-us").format(
        listing.landings
      );
      return `${formattedString}`;
    }
    return <em>Unknown</em>;
  };
  return (
    (listing.airframeMaintenance || listing.totalTime || listing.landings) && (
      <InformationBlock name="airframe" title="Airframe Information">
        <ul className="for-sale-detail-airframe-list detail-aiframe-list list no-bullets">
          {listing.airframeMaintenance && (
            <li>
              <strong>Airframe Maintenance Program:</strong> {airframeMaint()}
            </li>
          )}
          {listing.totalTime && (
            <li>
              <strong>Total Time:</strong> {totalTime()}
            </li>
          )}
          {listing.landings && (
            <li>
              <strong>Landings:</strong> {landings()}
            </li>
          )}
        </ul>
      </InformationBlock>
    )
  );
}
