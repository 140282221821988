import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function InformationBlock(props) {
	return (
		<div className={`for-sale-detail-${props.name} detail-${props.name}`}>
			<Container className="py-4 for-sale-detail-information-block detail-information-block">
				<Row className={`for-sale-detail-${props.name}-header detail-${props.name}-header`}>
					<Col>
						<h3 className={`detail-${props.name}-title ${props.name}-title mb-3`}>{props.title}</h3>
					</Col>
				</Row>
				<Row className={`for-sale-detail-${props.name}-body detail-${props.name}-body`}>
					<Col>
						{props.children}
					</Col>
				</Row>
			</Container>
		</div>
	);
}
