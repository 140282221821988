import React, { useState, useEffect } from "react";
import { Redirect, generatePath } from "react-router";
import queryString from "query-string";
import Routes, { createTitle } from "routes";
import { handleAxiosError } from "components/shared/errors";

import { listingRedirectCheck } from "actions/listing/actions";
import { companyRedirectCheck } from "actions/companies/actions";

import Spinner from "components/shared/spinner";

export default (props) => {
  const { location, history } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [redirect, setRedirect] = useState();

  const currentParams = queryString.parse(location.search, {
    arrayFormat: "comma",
  });

  useEffect(() => {
    setLoading(true);
    document.title = createTitle("Loading...", Routes);

    if (currentParams["rrn"]) {
      listingRedirectCheck(currentParams["rrn"])
        .then((slug) => {
          setError();
          const redirectUrl = generatePath(Routes.forSaleDetail, {
            listing: slug,
          });
          setRedirect(<Redirect to={redirectUrl} />);
          //history.push(redirectUrl);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (currentParams["filter_ACCOMP"]) {
      companyRedirectCheck(currentParams["filter_ACCOMP"])
        .then((slug) => {
          setError();
          const redirectUrl = generatePath(Routes.company, {
            company: slug,
          });
          setRedirect(<Redirect to={redirectUrl} />);
          //history.push(redirectUrl);
        })
        .catch((error) => {
          setError(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setRedirect(<Redirect to={Routes.home} />);
    }
  }, [currentParams, history]);

  const content = (loading, error, redirect) => {
    if (redirect) {
      return redirect;
    }

    if (error) {
      return handleAxiosError(error);
    }

    if (loading) {
      return (
        <div id="legacy-redirect-loading">
          <Spinner />
        </div>
      );
    }
  };

  return content(loading, error, redirect);
};
