import React, { Component } from "react";
import axios from "axios";

import { Row, Col, Button } from "react-bootstrap";
import Spinner from "components/shared/spinner";
import FormControl from "react-bootstrap/FormControl";

import { ReactComponent as DoneIcon } from "images/icons/check.svg";

import EditableInput from "components/backend/shared/editableInput";
import RichTextEditor from "components/shared/richEditor";

import stripHTML from "utils/stripHTML";

class Plans extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      results: [],
      error: undefined,
    };
  }

  componentDidMount() {
    axios.get("/plans").then((response) => {
      this.setState({
        isLoading: false,
        error: undefined,
        results: response.data.data,
      });
    });
  }

  content() {
    if (this.state.error) {
      // Error
      return (
        <div className="plans plans-error error">
          <h1 className="favorite-title">{this.state.error.message}</h1>
          <p>{this.ErrorMessage(this.state.error)}</p>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div className="form-loading edit-loading loading">
          <Spinner />
        </div>
      );
    } else if (this.state.results) {
      return <this.EditContent />;
    }
  }

  //   getSettingValue = section => {
  //     const thisSetting = this.state.results
  //       .filter(s => s.section === section)
  //       .shift();
  //     if (thisSetting) return thisSetting.content;
  //     return;
  //   };

  //   setSettingValue = (section, content) => {
  //     let updatedSettings = this.state.results;
  //     let previousResults = this.state.results;
  //     let updatedSetting;
  //     const thisSetting = this.state.results
  //       .filter(s => s.section === section)
  //       .shift();
  //     if (thisSetting) {
  //       // Update Setting
  //       updatedSettings = this.state.results.map((s, i) => {
  //         if (s.section === section) {
  //           // Update
  //           updatedSetting = s;
  //           updatedSetting.content = content;
  //           return updatedSetting;
  //         }
  //         return s;
  //       });
  //     } else {
  //       // Add New Setting
  //       updatedSetting = {
  //         group: this.state.group,
  //         section,
  //         title: null,
  //         content
  //       };
  //       updatedSettings.push(updatedSetting);
  //     }

  //     this.setState({ results: updatedSettings }, () => {
  //       axios
  //         .put(`/settings/${this.state.group}/${section}`, updatedSetting)
  //         .catch(error => {
  //           this.setState({ results: previousResults });
  //         });
  //     });
  //   };

  setPlanValue = (slug, value) => {
    const previousState = this.state.results.slice();
    let planToUpdate;
    const updatedPlans = this.state.results.map((p) => {
      if (p.slug === slug) {
        planToUpdate = { ...p, ...value };
        return planToUpdate;
      }
      return p;
    });
    this.setState({ results: updatedPlans }, () => {
      axios.put(`/plans/${slug}`, value).catch((error) => {
        this.setState({ results: previousState });
      });
    });
  };

  handleTextEditorChange = (name, key, value) => {
    let cleanedValue = stripHTML(value).trim();
    if (!cleanedValue) {
      value = null;
    }

    this.setPlanValue(name, { [key]: value });
  };

  EditablePriceInput = (slug, key, label, price, readOnly = false) => {
    return (
      <EditableInput
        readOnly={readOnly}
        label={label}
        value={readOnly ? "N/A" : price}
        onChange={(value) => {
          const parsedPrice = value ? Number.parseFloat(value).toFixed(2) : 0.0;
          this.setPlanValue(slug, { [key]: parsedPrice });
        }}
        renderValue={(value) => {
          const parsedPrice = new Intl.NumberFormat("en-us", {
            style: "currency",
            currency: "USD",
          }).format(value || 0.0);

          return <span>{readOnly ? "N/A" : parsedPrice}</span>;
        }}
        renderInput={({ setEdit, setValue, getValue, saveValue }) => (
          <div className="d-flex">
            <FormControl
              className="editable-input-group-control input-group-control control"
              type="number"
              min="0.00"
              step="5.00"
              value={getValue()}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              onBlur={(e) => {
                saveValue();
              }}
              onKeyUp={(e) => (e.keyCode === 13 ? saveValue() : () => {})}
            />
            <DoneIcon
              className="editable-input-group-done input-group-done group-done done"
              onClick={() => {
                saveValue();
              }}
            />
          </div>
        )}
      />
    );
  };

  EditContent = () => {
    const { results } = this.state;
    return (
      <section className="plans">
        <div className="site-settings__subtitle mb">
          Edit Subscription Plans
        </div>
        <div className="plans__form">
          {results.map((p) => (
            <div
              key={`plan-${p.slug}`}
              id={`plan-${p.slug}`}
              className="plans__form__block subscription__block mb-4"
            >
              <Row>
                <Col id={`plan-${p.slug}-title`} xs={12}>
                  <EditableInput
                    label="Title"
                    value={p.title || ""}
                    onChange={(value) => {
                      this.setPlanValue(p.slug, { title: value });
                    }}
                  />
                </Col>
                <Col id={`plan-${p.slug}-price`} xs={12}>
                  {this.EditablePriceInput(
                    p.slug,
                    "price",
                    "Monthly Price",
                    p.price
                  )}
                </Col>
                <Col id={`plan-${p.slug}-description`} xs={12} className="mb-4">
                  <EditableInput
                    label="Brief Description"
                    value={p.description || ""}
                    renderValue={(value) => (
                      <p
                        dangerouslySetInnerHTML={{ __html: value }}
                        className="white-space-pre"
                      />
                    )}
                    renderInput={({ setEdit, setValue, getValue }) => (
                      <>
                        <RichTextEditor
                          name="description"
                          value={p.description || ""}
                          onChange={(value, name) => {
                            console.log(value);
                            setValue(value);
                          }}
                        />
                        <Button
                          className="mt-1"
                          onClick={(e) => {
                            console.log(getValue());
                            this.handleTextEditorChange(
                              p.slug,
                              "description",
                              getValue()
                            );
                            setEdit(false);
                          }}
                        >
                          Done
                        </Button>
                      </>
                    )}
                    onChange={() => {}}
                  />
                </Col>
                <Col id={`plan-${p.slug}-internal-promotions`} xs={12} sm={6}>
                  <h4>Internal Promotions</h4>
                  <Row>
                    <Col id={`plan-${p.slug}-internal-homepage`} xs={12}>
                      {this.EditablePriceInput(
                        p.slug,
                        "homepageDisplayCost",
                        "Homepage Display Upgrade",
                        p.homepageDisplayCost,
                        p.slug === "free"
                      )}
                    </Col>
                    <Col id={`plan-${p.slug}-internal-listing`} xs={12}>
                      {this.EditablePriceInput(
                        p.slug,
                        "featuredListingCost",
                        "Featured Listing",
                        p.featuredListingCost,
                        p.slug === "free"
                      )}
                    </Col>
                    <Col id={`plan-${p.slug}-internal-sponsored`} xs={12}>
                      {this.EditablePriceInput(
                        p.slug,
                        "sponsoredAdCost",
                        "Sponsored Ad",
                        p.sponsoredAdCost,
                        p.slug === "free"
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col id={`plan-${p.slug}-external-promotions`} xs={12} sm={6}>
                  <h4>External Promotions</h4>
                  <Row>
                    <Col id={`plan-${p.slug}-external-blitz`} xs={12}>
                      {this.EditablePriceInput(
                        p.slug,
                        "blitzEmailCost",
                        "Blitz Email Broadcast",
                        p.blitzEmailCost
                      )}
                    </Col>
                    <Col id={`plan-${p.slug}-external-facebook`} xs={12}>
                      {this.EditablePriceInput(
                        p.slug,
                        "socialFacebookCost",
                        "Social Media - Facebook",
                        p.socialFacebookCost
                      )}
                    </Col>
                    <Col id={`plan-${p.slug}-external-twitter`} xs={12}>
                      {this.EditablePriceInput(
                        p.slug,
                        "socialTwitterCost",
                        "Social Media - Twitter",
                        p.socialTwitterCost
                      )}
                    </Col>
                    <Col id={`plan-${p.slug}-external-instagram`} xs={12}>
                      {this.EditablePriceInput(
                        p.slug,
                        "socialInstagramCost",
                        "Social Media - Instagram",
                        p.socialInstagramCost
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          ))}
        </div>
      </section>
    );
  };

  render() {
    return (
      <section id="plans-settings" className="plans-settings">
        {this.content()}
      </section>
    );
  }
}

export default Plans;
