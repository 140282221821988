import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal, Form, Col } from "react-bootstrap";

import { Formik } from "formik";
import { object, string } from "yup";

import Captcha from "components/shared/captcha";
import Spinner from "components/shared/spinner";

import { closeContactForm } from "actions/modal/actions";
import { submitContactForm } from "actions/contactForm/actions";
import { openMessageModal, closeMessageModal } from "actions/modal/actions";

import { ReactComponent as CloseIcon } from "images/icons/close-circle.svg";

class ContactForm extends Component {
  handleSubmit(name, email, phone, message, setSubmitting) {
    const contactData = {
      name: name,
      email: email,
      phone: phone,
      message: message,
      //listing: this.props.listing
    };

    this.props
      .submitContactForm(this.props.listing.slug, contactData)
      .then((res) => {
        setSubmitting(false);
        if (this.props.openMessageModal) {
          this.props.openMessageModal("Inquiry Submitted!", () => (
            <>
              <button
                className="btn btn-primary btn-green"
                onClick={() => {
                  this.props.closeMessageModal();
                }}
              >
                Okay
              </button>
            </>
          ));
        }
      })
      .catch((err) => {
        setSubmitting(false);
        if (this.props.openMessageModal) {
          this.props.openMessageModal(
            `Error Submitting Inquiry: ${err}. Please try again later, or contact us if you continue to experience problems.`,
            () => (
              <button
                className="btn btn-primary btn-green"
                onClick={() => {
                  this.props.closeMessageModal();
                }}
              >
                Okay
              </button>
            )
          );
        }
      });
  }

  accountName = (listing) => {
    if (
      listing &&
      listing.account &&
      listing.account.profile &&
      listing.account.profile.name
    )
      return listing.account.profile.name;
    return "";
  };

  companyName = (listing) => {
    if (listing && listing.company && listing.company.name)
      return listing.company.name;
    return "";
  };

  phone = (listing) => {
    if (listing) {
      if (
        listing.account &&
        listing.account.profile &&
        listing.account.profile.phone
      )
        return `Sales Rep Phone Number: ${listing.account.profile.phone}`;

      if (listing.account.profiles) {
        const publicProfile = listing.account.profiles
          .filter((p) => p.type.value === "public")
          .shift();
        if (publicProfile && publicProfile.phone)
          return `Company Phone Number: ${publicProfile.phone}`;
      }
    }
    return "";
  };

  render() {
    const { show, handleClose, listing } = this.props;

    return (
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <div className="contact-modal">
          <button className="btn contact-modal-close" onClick={handleClose}>
            <CloseIcon />
          </button>
          <div className="contact-modal-text">
            <span className="bold">{this.accountName(listing)}</span>
          </div>
          <div className="contact-modal-text">{this.companyName(listing)}</div>
          <div className="contact-modal-text">{this.phone(listing)}</div>
          <Formik
            initialValues={{
              name: "",
              email: "",
              phone: "",
              message: "",
              captcha: "",
            }}
            validationSchema={object().shape({
              name: string().required("Name is required"),
              email: string()
                .required("Email is required")
                .email("Invalid email"),
              message: string().required("Message is required"),
              captcha: string().required("You must prove you're not a robot"),
            })}
            onSubmit={({ name, email, phone, message }, { setSubmitting }) =>
              this.handleSubmit(name, email, phone, message, setSubmitting)
            }
          >
            {(props) => {
              const {
                values,
                touched,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                setFieldValue,
              } = props;

              return (
                <Form
                  onSubmit={
                    isSubmitting ? (e) => e.preventDefault() : handleSubmit
                  }
                  className="contact-form"
                >
                  {isSubmitting && <Spinner />}
                  <Form.Group>
                    <Form.Label className="contact-form-label">
                      Full Name:
                    </Form.Label>
                    <Form.Control
                      className="contact-form-input"
                      type="text"
                      id="name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="John Doe"
                    />
                    {touched.name && errors.name && (
                      <Form.Text className="contact-form-error">
                        {errors.name}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="contact-form-label">
                      Email Address:
                    </Form.Label>
                    <Form.Control
                      className="contact-form-input"
                      type="text"
                      id="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="example@email.com"
                    />
                    {touched.email && errors.email && (
                      <Form.Text className="contact-form-error">
                        {errors.email}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Row>
                    <Form.Group as={Col} md="4">
                      <Form.Label className="contact-form-label">
                        Phone Number:
                      </Form.Label>
                      <Form.Control
                        className="contact-form-input"
                        type="text"
                        id="phone"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="XXX-XXX-XXXX"
                      />
                    </Form.Group>
                  </Form.Row>
                  <Form.Group>
                    <Form.Label className="contact-form-label">
                      Message:
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      rows="4"
                      className="contact-form-input"
                      type="text"
                      id="message"
                      name="message"
                      value={values.message}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {touched.message && errors.message && (
                      <Form.Text className="contact-form-error">
                        {errors.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Captcha
                      onChange={(val) => {
                        setFieldValue("captcha", val);
                      }}
                    />
                    {errors.captcha && (
                      <Form.Text className="contact-form-error">
                        {errors.captcha}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <div className="row mt-5 d-flex align-items-center justify-content-between">
                    <div className="col d-flex align-items-center justify-content-end">
                      <button className="btn btn-green" type="submit">
                        submit
                      </button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </Modal>
    );
  }
}

const keyStateToProps = (state) => {
  return {
    show: state.modal.contactForm.open,
    listing: state.modal.contactForm.listing,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    handleClose: () => dispatch(closeContactForm()),
    submitContactForm: async (slug, data) =>
      await dispatch(submitContactForm(slug, data)),
    openMessageModal: (message, actions) =>
      dispatch(openMessageModal({ message, actions })),
    closeMessageModal: () => dispatch(closeMessageModal()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(ContactForm);
