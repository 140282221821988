import axios from "axios";
import apis from "apis";
import { GET_PLANS, SET_PLANS } from "./actionTypes.js";

export const getPlans = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    console.log("get planssssss");
    const { plans } = getState();
    dispatch({
      type: GET_PLANS,
    });

    if (plans && plans.length > 0) resolve(plans); // We already have plans in state. Ignore.

    axios({
      method: "get",
      url: apis.plans,
    })
      .then((response) => {
        const { data } = response.data;
        dispatch(setPlans(data.sort((a, b) => (a.rank > b.rank ? 1 : -1))));
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export function setPlans(plans) {
  return {
    type: SET_PLANS,
    payload: plans,
  };
}
