import React from "react";
import _ from "lodash";
import Table from "react-bootstrap/Table";

import InformationBlock from "components/frontend/listings/for-sale/shared/informationBlock";

export default function Engine(props) {
  const { listing } = props;

  const engineManufacturer = () => {
    if (listing.engineManufacturer) {
      return listing.engineManufacturer;
    }
    return <em>Unknown</em>;
  };

  const engineMaintenance = () => {
    if (listing.engineMaintenance) {
      return listing.engineMaintenance.title;
    }
    return <em>Unknown</em>;
  };

  const filteredEngines = (engines) => {
    const eng = engines
      .sort((a, b) => a.engineSeq - b.engineSeq)
      .filter((e) => {
        if (
          !_.isNil(e.cycles) ||
          !_.isNil(e.totalTime) ||
          !_.isNil(e.timeSinceMajor) ||
          !_.isNil(e.timeSinceMid)
        ) {
          return true;
        }
        return false;
      });

    return eng;
  };

  const engines = () => {
    if (listing.engines) {
      let rows = filteredEngines(listing.engines);

      if (!_.isEmpty(rows)) {
        rows = rows.reduce(
          (row, engine) => {
            return {
              showTotalTime: !_.isNil(engine.totalTime)
                ? true
                : row.showTotalTime,
              showCycles: !_.isNil(engine.cycles) ? true : row.showCycles,
              showTimeMajor: !_.isNil(engine.timeSinceMajor)
                ? true
                : row.showTimeMajor,
              showTimeMinor: !_.isNil(engine.timeSinceMid)
                ? true
                : row.showTimeMinor,
            };
          },
          {
            showTotalTime: false,
            showCycles: false,
            showTimeMajor: false,
            showTimeMinor: false,
          }
        );

        const table = {
          thead: [<th key="headLabel" className="label-column"></th>],
          totalTime: [
            <td key="totalTimeLabel" className="label-column">
              Total Time:
            </td>,
          ],
          cycles: [
            <td key="cyclesLabel" className="label-column">
              Cycles:
            </td>,
          ],
          timeMajor: [
            <td key="timeMajorLabel" className="label-column">
              Time Since Major:
            </td>,
          ],
          timeMinor: [
            <td key="timeMinorLabel" className="label-column">
              Time Since Minor:
            </td>,
          ],
        };

        const results = filteredEngines(listing.engines).reduce(
          (table, engine) => {
            // Add Header row
            table.thead.push(
              <th
                key={`headLabelEngine${engine.engineSeq}`}
                className="value-column"
              >
                Engine {engine.engineSeq}
              </th>
            );

            // Add Total Time row
            const thisTotalTime = !_.isNil(engine.totalTime)
              ? new Intl.NumberFormat("en-us").format(engine.totalTime)
              : "";
            table.totalTime.push(
              <td
                key={`totalTimeLabelEngine${engine.engineSeq}`}
                className="value-column"
              >
                {thisTotalTime}
              </td>
            );

            // Add Cycles row
            const thisCycles = !_.isNil(engine.cycles)
              ? new Intl.NumberFormat("en-us").format(engine.cycles)
              : "";
            table.cycles.push(
              <td
                key={`cyclesLabelEngine${engine.engineSeq}`}
                className="value-column"
              >
                {thisCycles}
              </td>
            );

            // Add Time Major row
            const thisTimeMajor = !_.isNil(engine.timeSinceMajor)
              ? new Intl.NumberFormat("en-us").format(engine.timeSinceMajor)
              : "";
            table.timeMajor.push(
              <td
                key={`timeMajorLabelEngine${engine.engineSeq}`}
                className="value-column"
              >
                {thisTimeMajor}
              </td>
            );

            // Add Time Minor row
            const thisTimeMinor = !_.isNil(engine.timeSinceMid)
              ? new Intl.NumberFormat("en-us").format(engine.timeSinceMid)
              : "";
            table.timeMinor.push(
              <td
                key={`timeMinorLabelEngine${engine.engineSeq}`}
                className="value-column"
              >
                {thisTimeMinor}
              </td>
            );

            return table;
          },
          table
        );

        return (
          <Table striped bordered hover className="unwide">
            <thead>
              <tr>{results.thead}</tr>
            </thead>
            <tbody>
              {rows.showTotalTime && <tr>{results.totalTime}</tr>}
              {rows.showCycles && <tr>{results.cycles}</tr>}
              {rows.showTimeMajor && <tr>{results.timeMajor}</tr>}
              {rows.showTimeMinor && <tr>{results.timeMinor}</tr>}
            </tbody>
          </Table>
        );
      }
    }
    return <></>;
  };
  return (
    (listing.engineManufacturer ||
      listing.engineMaintenance ||
      listing.engineAdditional ||
      listing.engineOnCondition ||
      !_.isEmpty(filteredEngines(listing.engines))) && (
      <InformationBlock name="engine" title="Engine Details">
        <ul className="for-sale-detail-engine-list detail-engine-list list no-bullets mb-4">
          {listing.engineManufacturer && (
            <li>
              <strong>Engine(s) Model:</strong> {engineManufacturer()}
            </li>
          )}
          {listing.engineMaintenance && (
            <li>
              <strong>Engine Maintenance Plan:</strong> {engineMaintenance()}
            </li>
          )}
          {listing.engineOnCondition && (
            <li>
              <strong>Engines On-Condition</strong>
            </li>
          )}
        </ul>

        {listing.engines.length > 0 && engines()}

        {listing.engineAdditional && (
          <>
            <strong>Additional Engine Information:</strong>
            <p
              dangerouslySetInnerHTML={{ __html: listing.engineAdditional }}
              className="white-space-pre"
            />
          </>
        )}
      </InformationBlock>
    )
  );
}
