/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";

import Routes, { createTitle } from "routes";

import {
  Row,
  Col,
  Button,
  ToggleButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import Spinner from "components/shared/spinner";

import Timeline from "react-calendar-timeline";
// make sure you include the timeline stylesheet or the timeline will not be styled
import "react-calendar-timeline/lib/Timeline.css";
import moment from "moment";

import { ReactComponent as ArrowLeft } from "images/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "images/icons/arrow-right.svg";

export default function ScheduledPromotions(props) {
  const [timeStart, setTimeStart] = useState(
    moment().startOf("week").valueOf()
  );
  const [timeEnd, setTimeEnd] = useState(
    moment().endOf("week").add(1, "month").valueOf()
  );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(undefined);

  const [items, setItems] = useState([]);

  /// Groups
  const defaultGroups = [
    {
      title: "Homepage Featured",
      id: "homepage",
    },
    {
      title: "Category Featured",
      id: "featured",
    },
    {
      title: "Sponsored Ad",
      id: "sponsored",
    },
    {
      title: "Blitz",
      id: "blitz",
    },
    {
      title: "Social Media",
      id: "social",
      items: [
        {
          title: "Facebook",
          id: "social-facebook",
        },
        {
          title: "Twitter",
          id: "social-twitter",
        },
        {
          title: "Instagram",
          id: "social-instagram",
        },
      ],
    },
  ];

  const getPlan = (promotion) => {
    if (promotion.company && promotion.company.plan)
      return promotion.company.plan;
    if (promotion.account && promotion.account.plan)
      return promotion.account.plan;
    return { title: "Unknown", price: 0.0 };
  };

  const fetchGroups = () => {
    return axios
      .get(
        "/masterData?keys=listingType,aircraftType,aircraftSize,serviceCategory"
      )
      .then((response) => {
        const masterData = response.data.rows;

        const rebuiltGroups = defaultGroups.map((g) => {
          switch (g.id) {
            case "featured":
            case "sponsored":
              return {
                ...g,
                items: masterData
                  .filter((data) => data.key === "listingType")
                  .map((t) => {
                    const typeStack = {
                      title: t.title,
                      id: `${g.id}-${t.value}`,
                    };

                    switch (t.value) {
                      case "sale":
                      case "wanted":
                        const saleWantedItems = masterData
                          .filter((data) => data.key === "aircraftType")
                          .map((at) => {
                            const aircraftTypeStack = {
                              title: at.title,
                              id: `${g.id}-${t.value}-${at.value}`,
                            };

                            switch (at.value) {
                              case "jet":
                                const jetSizes = masterData
                                  .filter((data) => data.key === "aircraftSize")
                                  .map((as) => ({
                                    title: as.title,
                                    id: `${g.id}-${t.value}-${at.value}-${as.value}`,
                                  }));
                                aircraftTypeStack.items = jetSizes;
                                break;
                              default:
                                break;
                            }
                            return aircraftTypeStack;
                          });
                        typeStack.items = saleWantedItems;
                        break;
                      case "service":
                        const serviceItems = masterData
                          .filter((data) => data.key === "serviceCategory")
                          .map((sc) => ({
                            title: sc.title,
                            id: `${g.id}-${t.value}-${sc.value}`,
                          }));
                        typeStack.items = serviceItems;
                        break;
                      default:
                        break;
                    }
                    return typeStack;
                  }),
              };
            case "blitz":
            case "social":
            case "homepage":
            default:
              break;
          }
          return g;
        });

        setGroups(flatten(rebuiltGroups));
      });
  };

  const fetchItems = (startRange, endRange) => {
    return axios
      .get("/promotions", {
        params: {
          status: "active",
          active: true,
          limit: 0,
          minDate: moment(startRange || timeStart).valueOf(),
          maxDate: moment(endRange || timeEnd).valueOf(),
        },
      })
      .then((res) => {
        const promos = res.data.data.rows;

        const timelineItems = promos.map((p) => {
          // Calculate Group
          let group = p.type.value;
          let end_time = moment(p.endDate).valueOf();
          switch (p.type.value) {
            case "featured":
              group = `${group}-${p.listing.type.value}`;
              switch (p.listing.type.value) {
                case "service":
                  if (
                    p.listing.serviceDetails &&
                    p.listing.serviceDetails.category
                  ) {
                    group = `${group}-${p.listing.serviceDetails.category.value}`;
                  }
                  break;
                case "wanted":
                  if (
                    p.listing.wantedDetails &&
                    p.listing.wantedDetails.model
                  ) {
                    group = `${group}-${p.listing.wantedDetails.model.type.value}`;

                    if (p.listing.wantedDetails.model.type.value === "jet") {
                      group = `${group}-${p.listing.wantedDetails.model.size.value}`;
                    }
                  }
                  break;
                case "sale":
                  if (p.listing.saleDetails && p.listing.saleDetails.model) {
                    group = `${group}-${p.listing.saleDetails.model.type.value}`;

                    if (p.listing.saleDetails.model.type.value === "jet") {
                      group = `${group}-${p.listing.saleDetails.model.size.value}`;
                    }
                  }
                  break;
                default:
                  break;
              }

              break;
            case "sponsored":
              group = `${group}-${p.categoryType.value}`;
              if (p.category) {
                group = `${group}-${p.category.value}`;
                if (p.subcategory) {
                  group = `${group}-${p.subcategory.value}`;
                }
              }
              break;
            case "social-facebook":
            case "social-twitter":
            case "social-instagram":
            case "blitz":
              // For "End Date" to be the Start Date
              end_time = moment(p.startDate).endOf("day").valueOf();
              break;
            default:
              break;
          }
          const thisPlan = getPlan(p);
          return {
            id: p.id,
            group,
            title: `${p.title} - ${thisPlan.title} Level`,
            start_time: moment(p.startDate).valueOf(),
            end_time,
            itemProps: {
              className: `plan-${thisPlan.slug}`,
            },
          };
        });

        setItems(timelineItems);
      });
  };

  let depth = 0;

  const flatten = (items, parent) => {
    const arr = Array.isArray(items) ? items : [items];
    return arr.reduce((acc, item) => {
      if (parent) {
        item.root = false;
        item.parent = parent.id;
      } else {
        item.root = true;
      }
      item.stackItems = true;
      item.depth = depth;
      item.open = true;

      acc.push(item);
      if (item.items) {
        item.children = true;
        depth++;
        acc = acc.concat(flatten(item.items, item));
        depth--;
        delete item.items;
      }
      return acc;
    }, []);
  };

  const [groups, setGroups] = useState([]);

  const toggleGroup = (id) => {
    let altered = false;
    const updatedGroups = groups.map((g) => {
      if (g.id === id && g.children) {
        altered = true;
        return { ...g, open: !g.open };
      }
      return g;
    });
    if (altered) setGroups(updatedGroups);
  };

  const getAncestors = (item) => {
    const arr = Array.isArray(item) ? item : [item];
    return arr.reduce((acc, item) => {
      acc.push(item);
      if (item.parent) {
        const parent = groups.filter((g) => g.id === item.parent).shift();
        acc = acc.concat(getAncestors(parent));
      }
      return acc;
    }, []);
  };

  const isVisible = (item) => {
    const ancestors = getAncestors(item).slice(1);
    return ancestors.reduce((visible, node) => {
      if (visible && !node.open) {
        return false;
      }
      return visible;
    }, true);
  };

  const newGroups = groups
    .filter((g) => isVisible(g))
    .map((group) => {
      return Object.assign({}, group, {
        title: (
          <div
            onClick={() => toggleGroup(group.id)}
            style={{ paddingLeft: 24 * group.depth }}
            className={`${group.id} depth-${group.depth} ${
              group.children ? "has-children" : "no-children"
            } ${group.open ? "open" : "closed"}`}
          >
            {group.title}{" "}
            {group.children ? (
              group.open ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
                </svg>
              )
            ) : null}
          </div>
        ),
      });
    });

  const handleItemMove = (itemId, dragTime, newGroupOrder) => {
    const movedItems = items.map((item) => {
      if (item.id === itemId) {
        const start_time = dragTime;
        const end_time = dragTime + (item.end_time - item.start_time);
        axios.put(`/promotion/${itemId}`, {
          startDate: start_time,
          endDate: end_time,
        });
        return Object.assign({}, item, {
          start_time,
          end_time,
        });
      }
      return item;
    });

    setItems(movedItems);
  };

  const handleItemResize = (itemId, time, edge) => {
    const resizedItems = items.map((item) => {
      if (item.id === itemId) {
        const start_time = edge === "left" ? time : item.start_time;
        const end_time = edge === "left" ? item.end_time : time;
        axios.put(`/promotion/${itemId}`, {
          startDate: start_time,
          endDate: end_time,
        });
        return Object.assign({}, item, {
          start_time,
          end_time,
        });
      }

      return item;
    });

    setItems(resizedItems);
  };

  const handlePrev = () => {
    const zoom = timeEnd - timeStart;
    setTimeStart(timeStart - zoom);
    setTimeEnd(timeEnd - zoom);
  };

  const handleNext = () => {
    const zoom = timeEnd - timeStart;
    setTimeStart(timeStart + zoom);
    setTimeEnd(timeEnd + zoom);
  };

  useEffect(() => {
    document.title = createTitle(`Schedule Promotions`, Routes);

    fetchGroups()
      .then(() =>
        fetchItems().then(() => {
          setLoading(false);
          setError(false);
        })
      )
      .catch((groupsError) => {
        setLoading(false);
        setError(groupsError);
      });
  }, []);

  const renderContent = () => {
    return (
      <section className="schedule promotion-schedule">
        <header>
          <Row>
            <Col xs={12} sm={8}>
              <div className="pager">
                <Button className="btn-icon" onClick={handlePrev}>
                  <ArrowLeft />
                </Button>
                {moment(timeStart).format("MMM D, YYYY")}-
                {moment(timeEnd).format("MMM D, YYYY")}
                <Button className="btn-icon" onClick={handleNext}>
                  <ArrowRight />
                </Button>
              </div>
            </Col>
            <Col xs={12} sm={4} className="text-left text-sm-right">
              <ToggleButtonGroup
                type="radio"
                name="options"
                defaultValue="month"
              >
                <ToggleButton
                  value="day"
                  variant="outline-secondary"
                  className={"toggle-btn text-black-50"}
                  onClick={() => {
                    setTimeStart(moment().startOf("week").valueOf());
                    setTimeEnd(moment().endOf("week").valueOf());
                  }}
                >
                  <div className="top-buttons-lb">Day</div>
                </ToggleButton>
                <ToggleButton
                  value="week"
                  variant="outline-secondary"
                  className={"toggle-btn text-black-50"}
                  onClick={() => {
                    setTimeStart(moment().startOf("week").valueOf());
                    setTimeEnd(moment().endOf("week").add(2, "week").valueOf());
                  }}
                >
                  <div className="top-buttons-lb">Week</div>
                </ToggleButton>
                <ToggleButton
                  value="month"
                  variant="outline-secondary"
                  className={"toggle-btn text-black-50"}
                  onClick={() => {
                    setTimeStart(moment().startOf("week").valueOf());
                    setTimeEnd(
                      moment().startOf("week").add(1, "month").valueOf()
                    );
                  }}
                >
                  <div className="top-buttons-lb">Month</div>
                </ToggleButton>
                <ToggleButton
                  value="quarter"
                  variant="outline-secondary"
                  className={"toggle-btn text-black-50"}
                  onClick={() => {
                    setTimeStart(moment().startOf("week").valueOf());
                    setTimeEnd(
                      moment().endOf("week").add(3, "month").valueOf()
                    );
                  }}
                >
                  <div className="top-buttons-lb">Quarter</div>
                </ToggleButton>
                <ToggleButton
                  value="year"
                  variant="outline-secondary"
                  className={"toggle-btn text-black-50"}
                  onClick={() => {
                    setTimeStart(moment().startOf("week").valueOf());
                    setTimeEnd(moment().add(1, "year").valueOf());
                  }}
                >
                  <div className="top-buttons-lb">Year</div>
                </ToggleButton>
              </ToggleButtonGroup>
            </Col>
          </Row>
        </header>
        <main>
          <Row>
            <Col>
              <Timeline
                groups={newGroups}
                items={items}
                //keys={keys}
                sidebarWidth={180}
                canResize="both"
                canMove
                canChangeGroup={false}
                //lineHeight={64}
                //itemRenderer={itemRenderer}
                itemsSorted
                //itemTouchSendsClick={false}
                stackItems
                itemHeightRatio={0.95}
                visibleTimeStart={timeStart}
                visibleTimeEnd={timeEnd}
                //   horizontalLineClassNamesForGroup={(group) =>
                //     group.root ? ["row-root"] : []
                //   }
                dragSnap={1000 * 86400}
                minZoom={1000 * 86400 * 7}
                maxZoom={1000 * 86400 * 365}
                onItemMove={handleItemMove}
                onItemResize={handleItemResize}
                onBoundsChange={(canvasTimeStart, canvasTimeEnd) => {
                  //fetchItems(canvasTimeStart, canvasTimeEnd);
                }}
                onTimeChange={(
                  visibleTimeStart,
                  visibleTimeEnd,
                  updateScrollCanvas
                ) => {
                  setTimeStart(visibleTimeStart);
                  setTimeEnd(visibleTimeEnd);
                  //updateScrollCanvas(visibleTimeStart, visibleTimeEnd);
                }}
              ></Timeline>
            </Col>
          </Row>
        </main>
      </section>
    );
  };

  if (error) {
    return (
      <div id="promotion-error" className="stepper-error promotion-error error">
        <h1 className="listings-title">{error.message}</h1>
        <p>{error.message}</p>
      </div>
    );
  } else if (loading) {
    return (
      <div
        id="promotion-loading"
        className="stepper-loading promotion-loading loading"
      >
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  } else {
    return renderContent();
  }
}
