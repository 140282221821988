import React, { Component } from "react";
import axios from "axios";
import queryString from "query-string";

import Routes, { createTitle } from "routes";

import { Container, Row, Col, Button } from "react-bootstrap";
import Spinner from "components/shared/spinner";
import { Link } from "react-router-dom";

import { ReactComponent as TrashIcon } from "images/icons/archive.svg";
import { ReactComponent as RestoreIcon } from "images/icons/unarchive.svg";
import { ReactComponent as UnreadIcon } from "images/icons/mail.svg";
import { ReactComponent as ReadIcon } from "images/icons/mail-read.svg";
import { ReactComponent as FlagIcon } from "images/icons/flag.svg";

import Pagination from "components/frontend/listings/_shared/pagination";
import FilterDropdown from "components/frontend/listings/_shared/filterDropdown";

import MessagePopup from "./messagePopup";
import InboxTable from "./inboxTable";

class InboxPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchUrl: false,
      isLoading: true,
      results: [],
      error: undefined,
      count: 0,
      pages: 0,
      selected: [],
      popup: false,
    };

    this.sorting = [
      { sort: null, order: null, label: "Newest" },
      { sort: "created", order: "ASC", label: "Oldest" },
      { sort: "listing", order: "ASC", label: "Listing: A to Z" },
      { sort: "listing", order: "DESC", label: "Listing: Z to A" },
      { sort: "sender", order: "DESC", label: "Sender: A to Z" },
      { sort: "sender", order: "ASC", label: "Sender: Z to A" },
    ];

    this.paging = [
      { count: 10, label: "10 per page" },
      { count: 25, label: "25 per page" },
      { count: 50, label: "50 per page" },
      { count: 100, label: "100 per page" },
    ];

    this.fetchUrl = this.props.status
      ? `/inquiries/${this.props.status}`
      : `/inquiries`;
  }
  componentDidMount() {
    document.title = createTitle(
      this.props.status ? `Inquiry Archive` : "Inquiry Inbox",
      Routes
    );
    this.fetchResults();
  }

  componentDidUpdate() {
  //  this.fetchResults();
  }

  getSearchParams() {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    const newParams = {};

    // Limit
    if (currentParams["show"]) {
      newParams["limit"] = currentParams["show"] || 10;
    }

    return { ...currentParams, ...newParams };
  }

  parseRequestUrl() {
    const fetchUrl = this.fetchUrl;
    const searchParams = this.getSearchParams();
    // Add Params
    if (searchParams) {
      // We have search params. Parse them!
      const searchParamsUrl = queryString.stringify(searchParams, {
        arrayFormat: "comma",
      });
      return `${fetchUrl}?${searchParamsUrl}`;
    }

    // Return URL
    return fetchUrl;
  }

  handlePageLimitChange = (event) => {
    const target = event.currentTarget;

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, show: target.value };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };
  getPageLimit = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const pageLimit = currentParams["show"] || 10;
    return parseInt(pageLimit);
  };
  handlePageChange = (pager) => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, page: pager.currentPage };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };
  getPage = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const page = currentParams["page"] || 1;
    return parseInt(page);
  };

  fetchResults() {
    let fetchUrl = this.parseRequestUrl();
    if (this.state.fetchUrl !== fetchUrl) {
      // Fetch URL has changed. Update state and get new results
      this.setState(
        {
          fetchUrl: fetchUrl,
          isLoading: true,
          results: [],
          selected: [],
          error: undefined,
          count: 0,
          pages: 0,
        },
        () => {
          if (fetchUrl) {
            axios
              .get(fetchUrl)
              .then((response) => {
                const results = response.data.data.rows;

                this.setState(
                  {
                    isLoading: false,
                    error: undefined,
                    results: results,
                    count: response.data.data.count,
                    pages: response.data.pageCount,
                  },
                  () => {
                    this.props.onFetchSuccess &&
                      this.props.onFetchSuccess(this.state);
                  }
                );
              })
              .catch((error) => {
                this.setState(
                  {
                    isLoading: false,
                    results: [],
                    error: error,
                    count: 0,
                    pages: 0,
                  },
                  () => {
                    this.props.onFetchError &&
                      this.props.onFetchError(this.state);
                  }
                );
              });
          }
        }
      );
    }
  }

  handleSelect = (id, selected) => {
    if (selected) {
      if (!this.state.selected.includes(id))
        this.setState({ selected: [...this.state.selected, id] });
    } else {
      this.setState({
        selected: this.state.selected.filter((x) => x !== id),
      });
    }
  };

  handleSelectAll = (selected) => {
    if (selected) {
      this.setState({ selected: this.state.results.map((i) => i.id) });
    } else {
      this.setState({ selected: [] });
    }
  };

  showPopup = (inquiry) => {
    inquiry.status = { value: "read" };
    this.handleInquiryUpdate({ status: "read" }, inquiry.id);
    this.setState({
      popup: inquiry,
    });
  };
  closePopup = () => {
    this.setState({
      popup: false,
    });
  };

  handleOrderByChange = (event) => {
    const target = event.currentTarget;

    const sort = this.sorting[target.value];

    if (sort) {
      const currentParams = queryString.parse(this.props.location.search, {
        arrayFormat: "comma",
      });
      const updatedObj = {
        ...currentParams,
        sort: sort.sort,
        order: sort.order,
      };
      const updatedParams = queryString.stringify(updatedObj, {
        arrayFormat: "comma",
      });

      this.props.history.push({
        pathname: this.props.location.pathname,
        search: "?" + new URLSearchParams(updatedParams).toString(),
      });
    }
  };

  getOrderBy = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    if (currentParams["sort"] && currentParams["order"]) {
      for (const [i, v] of this.sorting.entries()) {
        if (
          v.sort === currentParams["sort"] &&
          v.order === currentParams["order"]
        ) {
          return i;
        }
      }
    }
    return 0;
  };

  handleInquiryUpdate = (payload, ids) => {
    let useIds = this.state.selected;

    if (ids) {
      if (Array.isArray(ids)) {
        useIds = ids;
      } else {
        useIds = [ids];
      }
    }
    if (useIds && useIds.length > 0) {
      this.setState(
        {
          isLoading: true,
          fetchUrl: undefined,
          error: undefined,
        },
        () => {
          axios
            .put(`/inquiries`, { ids: useIds, ...payload })
            .then((response) => {
                this.fetchResults();
            })
            .catch((err) => {
              this.setState({
                isLoading: false,
                results: [],
                error: err,
                count: 0,
                pages: 0,
              });
            });
        }
      );
    }
  };

  actionButtons = (status, inquiry) => {
    const inquiryId = inquiry ? inquiry.id : undefined;
    const archiveButton = () => {
      if (
        (!inquiry && status !== "archived") ||
        (inquiry && inquiry.status.value !== "archived")
      )
        return (
          <Button
            className="btn-square btn-white"
            onClick={() =>
              this.handleInquiryUpdate({ status: "archived" }, inquiryId)
            }
          >
            <TrashIcon />
            <span className="label">Archive</span>
          </Button>
        );
    };
    const unarchiveButton = () => {
      if (
        (!inquiry && status === "archived") ||
        (inquiry && inquiry.status.value === "archived")
      )
        return (
          <Button
            className="btn-square btn-white"
            onClick={() =>
              this.handleInquiryUpdate({ status: "read" }, inquiryId)
            }
          >
            <RestoreIcon />
            <span className="label">Unarchive</span>
          </Button>
        );
    };
    const unreadButton = () => {
      if (
        (!inquiry && status !== "archived") ||
        (inquiry && inquiry.status.value === "read")
      )
        return (
          <Button
            className="btn-square btn-white"
            onClick={() => {
              this.handleInquiryUpdate({ status: "unread" }, inquiryId)
              this.closePopup()
            }}
          >
            <UnreadIcon />
            <span className="label">Mark as Unread</span>
          </Button>
        );
    };
    const readButton = () => {
      if (
        (!inquiry && status !== "archived") ||
        (inquiry && inquiry.status.value === "unread")
      )
        return (
          <Button
            className="btn-square btn-white"
            onClick={() =>
              this.handleInquiryUpdate({ status: "read" }, inquiryId)
            }
          >
            <ReadIcon />
            <span className="label">Mark as Read</span>
          </Button >
        );
    };
    const flagButton = () => {
      if ((!inquiry && status !== "archived") || (inquiry && inquiry.flag))
        return (
          <Button
            className="btn-square btn-white"
            onClick={() => this.handleInquiryUpdate({ flag: true }, inquiryId)}
          >
            <FlagIcon />
            <span className="label">Flag</span>
          </Button>
        );
    };
    switch (status) {
      default:
        return (
          <div className="action-buttons inquiry-actions active-actions">
            {archiveButton()}
            {unarchiveButton()}
            {unreadButton()}
            {readButton()}
            {flagButton()}
          </div>
        );
    }
  };

  content = () => {
    if (this.state.error) {
      // Error
      return (
        <div className="edit-error form-error error">
          <h1 className="inquiry-title">{this.state.error.message}</h1>
          <p>{this.ErrorMessage(this.state.error)}</p>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div className="form-loading edit-loading loading">
          <Spinner />
        </div>
      );
    } else if (this.state.results) {
      if (this.state.results.length > 0) {
        return (
          <>
            <InboxTable
              data={this.state.results}
              selected={this.state.selected}
              onSelect={this.handleSelect}
              onSelectAll={this.handleSelectAll}
              showPopup={this.showPopup}
            />
            {this.state.pages > 1 && (
              <div className="d-flex flex-row justify-content-end mt-3">
                <FilterDropdown
                  id="inquiry-paging"
                  name="show"
                  label="View:"
                  onChange={this.handlePageLimitChange}
                  value={this.getPageLimit()}
                >
                  {this.paging.map((paging, i) => {
                    return (
                      <option value={paging.count} key={i}>
                        {paging.label}
                      </option>
                    );
                  })}
                </FilterDropdown>
                <Pagination
                  totalRecords={this.state.count}
                  pageLimit={this.getPageLimit()}
                  pageNeighbours={1}
                  onPageChanged={this.handlePageChange}
                  currentPage={this.getPage()}
                />
              </div>
            )}
          </>
        );
      }

      return (
        <div className="no-results">
          <h2 className="inquiry-title">No Inquiries</h2>
          <p>You don't have any inquiries</p>
        </div>
      );
    }
  };

  render() {
    return (
      <section id="inbox" className="app-content app-main inbox-page">
        {this.state.popup && (
          <MessagePopup
            inquiry={this.state.popup}
            closePopup={this.closePopup}
            //onInquiryUpdate={}
            actionButtons={this.actionButtons}
          />
        )}
        <Container>
          <header>
            <Row className="mb-3">
              <Col xs={6}>
                <div className="inbox-page__title">
                  {this.props.status ? "ARCHIVED" : "ALL"}{" "}
                  <span>INQUIRIES</span>
                </div>
              </Col>
              <Col xs={6} className="text-right">
                {!this.props.status && (
                  <Link to={Routes.inboxArchived} className="btn btn-green">
                    View Archived
                  </Link>
                )}
                {this.props.status && (
                  <Link to={Routes.inbox} className="btn btn-green">
                    Back to Inbox
                  </Link>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={8}>
                {this.actionButtons(this.props.status)}
              </Col>
              <Col xs={12} sm={4} className="text-left text-sm-right">
                <FilterDropdown
                  id="inquiry-sort"
                  name="sort-by"
                  label="Sort:"
                  onChange={this.handleOrderByChange}
                  value={this.getOrderBy()}
                >
                  {this.sorting.map(function (sort, i) {
                    return (
                      <option value={i} key={i}>
                        {sort.label}
                      </option>
                    );
                  }, this)}
                </FilterDropdown>
              </Col>
            </Row>
          </header>
          <main>
            <Row>
              <Col>{this.content()}</Col>
            </Row>
          </main>
        </Container>
      </section>
    );
  }
}

export default InboxPage;
