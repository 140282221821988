import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withBreakpoints } from 'react-breakpoints';

import SearchBlockList from './searchBlockList';
import Pagination from 'components/shared/pagination';

class MySavedSearchesContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      perPage: 5,
      pageCount: 0,
      data: []
    }
  }

  componentDidMount() {
    this.calcPageCount(this.state.perPage)
    this.sliceData(this.props.selectedPage)
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.data !== this.props.data) {
      this.calcPageCount(this.state.perPage)
      this.sliceData(this.props.selectedPage)
    }

    if(prevProps.selectedPage !== this.props.selectedPage) {
      this.sliceData(this.props.selectedPage)
    }

    if(prevState.pageCount !== this.state.pageCount) {
      this.sliceData(this.props.selectedPage)
    }
  }

  calcPageCount(perPage) {
    const { selectedPage } = this.props;
    let pageCount = this.props.data.length / perPage;
    if(pageCount !== parseInt(pageCount)) {
      pageCount = parseInt(pageCount) + 1
    }

    if(selectedPage >= pageCount) {
      this.props.handlePageClick(pageCount - 1)
    }
    this.setState({ pageCount: pageCount })
  }

  sliceData(selectedPage) {
    const { perPage } = this.state;

    const data = this.props.data.slice(selectedPage * perPage, (selectedPage + 1)  * perPage);
    this.setState({ data: data })
  }

  onChangePerPageView(data) {
    const perPage = data[0].value;
    this.calcPageCount(perPage);

    this.setState({ perPage: perPage })
  }

  render() {
    const { pageValues, breakpoints, selectedPage, currentBreakpoint } = this.props;
    const { data, perPage, pageCount } = this.state;
    let column = perPage / 2
    
    if(breakpoints[currentBreakpoint] < breakpoints.forMobileUp) {
      column = 1;
    } else if (breakpoints.forMobileUp === breakpoints[currentBreakpoint]) {
      column = 2;
    }

    return (
      <div className="my-saved-searches__content">
        <SearchBlockList
          perPage={perPage}
          column={column}
          data={data}
        />
        <Pagination
          handlePageClick={(data) => this.props.handlePageClick(data.selected)}
          onChangePerPageLimit={(data) => this.onChangePerPageView(data)}
          pageValues={pageValues}
          perPage={perPage}
          pageCount={pageCount}
          selectedPage={selectedPage}
        />
      </div>
    );
  }
}

MySavedSearchesContent.propTypes = {
  pageValues: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  selectedPage: PropTypes.number.isRequired,
  handlePageClick: PropTypes.func.isRequired,
};

export default withBreakpoints(MySavedSearchesContent);