import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { Modal, Row, Col, Alert } from "react-bootstrap";

import Spinner from "components/shared/spinner";
import { closeSignup } from "actions/modal/actions";
import { signUp } from "actions/user/actions";

import EmailForm from "./emailForm";
import NameForm from "./nameForm";
import PasswordForm from "./passwordForm";
import ResendEmail from "./resendEmail";
import Stepper from "components/shared/stepper";

import { ReactComponent as CloseIcon } from "images/icons/close-circle.svg";

import { parseAxiosError } from "utils/errors";

const stepsData = ["EMAIL ADDRESS", "NAME • ZIP CODE", "PASSWORD"];

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStep: 0,
      error: undefined,
      success: undefined,
      submitting: false,
      userInfo: {
        email: "",
        name: "",
        zip: "",
        password: "",
        blitzes: false,
      },
    };
  }

  onChangeNextStep() {
    const { currentStep } = this.state;
    const nextStep = currentStep + 1;
    this.setState({
      currentStep: nextStep,
    });
  }

  jumpToStep = (step) => {
    if (step < this.state.currentStep)
      this.setState({
        currentStep: step,
      });
  };

  stepContent() {
    switch (this.state.currentStep) {
      case 0:
        return (
          <EmailForm
            userInfo={this.state.userInfo}
            changeUserInfo={(infoItem) => this.changeUserInfo(infoItem)}
            onChangeNextStep={() => this.onChangeNextStep()}
          />
        );
      case 1:
        return (
          <NameForm
            userInfo={this.state.userInfo}
            changeUserInfo={(infoItem) => this.changeUserInfo(infoItem)}
            onChangeNextStep={() => this.onChangeNextStep()}
          />
        );
      case 2:
        return (
          <PasswordForm
            userInfo={this.state.userInfo}
            changeUserInfo={(infoItem) => this.changeUserInfo(infoItem)}
            submitUser={() => this.submitUser()}
            onChangeNextStep={() => this.onChangeNextStep()}
          />
        );
      case 3:
        return (
          <ResendEmail
            userInfo={this.state.userInfo}
            sendEmail={() => this.sendEmail()}
          />
        );
      default:
        return;
    }
  }

  changeUserInfo(infoItem) {
    const { userInfo } = this.state;
    const nextUserInfo = {
      ...userInfo,
      ...infoItem,
    };
    this.setState({
      userInfo: nextUserInfo,
      error: undefined,
      success: undefined,
    });
  }

  submitUser() {
    const { userInfo } = this.state;
    this.setState({ error: undefined, submitting: true }, () => {
      return this.props
        .signUp(userInfo)
        .then((res) => {
          this.setState(
            {
              error: undefined,
              submitting: false,
            },
            () => {
              this.onChangeNextStep();
            }
          );
        })
        .catch((err) => {
          console.error("Error Registering User");
          const msg = parseAxiosError(err);
          console.error(msg);
          this.setState({
            error: msg,
            submitting: false,
            currentStep: 0,
          });
        });
    });
  }

  sendEmail() {
    const { userInfo } = this.state;
    this.setState({ error: undefined, success: undefined }, () => {
      axios
        .post("accounts/verification/resend", { email: userInfo.email })
        .then((res) => {
          this.setState({ success: "Verification Email Resent Successfully" });
        })
        .catch((err) => {
          const msg = parseAxiosError(err);
          this.setState({ error: msg });
        });
    });
  }

  render() {
    const { show, handleClose } = this.props;
    const { currentStep, finishStep } = this.state;

    return (
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <div className="account-modal signup">
          <button className="btn account-modal-close" onClick={handleClose}>
            <CloseIcon />
          </button>
          {finishStep < 3 ? (
            <div className="account-modal-title">
              CREATE AN <span className="bold">ACCOUNT</span>
            </div>
          ) : (
            <div className="account-modal-title">
              <span className="bold">before we takeoff</span>
            </div>
          )}
          <Row className="justify-content-md-center mb-4">
            <Col sm={12} md={8}>
              <Stepper
                data={stepsData}
                currentStep={currentStep}
                onChangeStep={this.jumpToStep}
              />
            </Col>
          </Row>
          {this.state.submitting && (
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          )}
          {this.state.error && (
            <Alert variant="danger">{this.state.error}</Alert>
          )}
          {this.state.success && (
            <Alert variant="success">{this.state.success}</Alert>
          )}
          {this.stepContent()}
        </div>
      </Modal>
    );
  }
}

const keyStateToProps = (state) => {
  return {
    show: state.modal.openSignup,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    handleClose: () => dispatch(closeSignup()),
    signUp: async (userInfo) => await dispatch(signUp(userInfo)),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(Signup);
