import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { faHeart} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Img from 'images/content/my-saved-searches/card_img.png'

class SearchCard extends Component {
  render() {
    return (
      <Card className="search-card">
        <Card.Img className="search-card__img" variant="top" src={Img} />
        <Card.Body className="search-card__body">
          <button className="like-button">
            <FontAwesomeIcon icon={faHeart} />
          </button>
          <Row className="pr-5">
            <Col className="col-12">
              <Card.Text className="search-card__title">Make Model SN#123456</Card.Text>
            </Col>
            <Col className="col-12">
                <Card.Text className="search-card__text text-nowrap">Uploaded: 21 hours ago</Card.Text>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col className="col-12">
              <Card.Text className="search-card__text">
                Company: Global Jet Sales
              </Card.Text>
              <div>
                <span>Broker: John Smith - </span>
                <span className="search-card__info">
                  8138461679
                </span>
                <span> or </span>
                <span className="search-card__info">
                  john@gjs.com
                </span>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  }
}

export default SearchCard;