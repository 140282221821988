import React, { useState } from "react";

import { Row, Col, Form, Tab } from "react-bootstrap";

import MultiImageBrickUpload from "components/backend/shared/multiImageBrickUpload";
import PromoteCalendar from "components/backend/promotions/shared/calendar";
import TextareaAutosize from "react-textarea-autosize";
const SocialTab = (props) => {
  const [social, setSocial] = useState([]);

  const handleCheckbox = (evt) => {
    const value = evt.target.value;
    const checked = evt.target.checked;
    const arr = social.splice(0);

    if (checked) {
      // Add this value to our params
      if (!arr.includes(value)) arr.push(value);
    } else {
      // Remove this value from our params
      arr.splice(arr.indexOf(value), 1);
    }

    setSocial(arr);
    sendChange("social", arr);
  };
  const handleChange = (evt) => {
    sendChange(evt.target.name, evt.target.value);
  };

  const sendChange = (name, value) => {
    if (props.onChange) {
      props.onChange(name, value);
    }
  };
  return (
    <Tab.Pane eventKey="#social">
      {!props.active && <div className="form-overlay"></div>}
      <div className="form-content feature-detail py-2 px-4">
        <div className={"mt-2"}>
          Highlights: &nbsp;
          <span className="text-green">{props.promotion.highlights}</span>
        </div>

        <Row className={"mt-4"}>
          <Col xs={12}>
            <h6>1. Any additional features you’d like to highlight?</h6>
          </Col>
          <Col xs={12}>
            <TextareaAutosize
              className={"w-100 mt-2"}
              rows="3"
              name="content"
              onChange={handleChange}
            />
          </Col>
        </Row>

        <Row className={"mt-4"}>
          <Col xs={12}>
            <div className={"pt-2"}>
              <h6>
                2. Attach featured photos &nbsp;
                <label>Limit 3</label>
              </h6>
              <MultiImageBrickUpload
                images={props.promotion.images.map((i) => i.url)}
                maxImages={3}
                onChange={(images) => {
                  console.log("Changed images");
                  console.log(images);
                  if (props.onImagesChange) {
                    props.onImagesChange(images);
                  }
                }}
              />
            </div>
          </Col>
        </Row>

        <Row className={"mt-4"}>
          <Col xs={12}>
            <h6>3. Please select desired promotion day.</h6>
            <div className="text-uppercase my-1">
              No dates are guaranteed and all are subject to review, approval,
              and availability of Plane Mover. A Plane Mover Representative will
              contact you with confirmation.
            </div>
          </Col>
          <Col className="Calendar-div" xs={12}>
            <PromoteCalendar
              selectSingle
              onChange={(date) => {
                if (props.onChange) {
                  props.onChange("startDate", date);
                }
              }}
            />
          </Col>
        </Row>

        <Row className={"mt-4"}>
          <Col xs={12}>
            <h6>4. Select your channels:</h6>
            <div
              className={"d-flex justify-content-between px-2 select-channel"}
            >
              <Form.Check
                custom
                id="social-facebook"
                type="checkbox"
                value="facebook"
                label="Facebook"
                checked={social ? social.includes("facebook") : false}
                onChange={handleCheckbox}
              />
              <Form.Check
                custom
                id="social-instagram"
                type="checkbox"
                value="instagram"
                label="Instagram"
                checked={social ? social.includes("instagram") : false}
                onChange={handleCheckbox}
              />
              <Form.Check
                custom
                id="social-twitter"
                type="checkbox"
                value="twitter"
                label="Twitter"
                checked={social ? social.includes("twitter") : false}
                onChange={handleCheckbox}
              />
            </div>
          </Col>
        </Row>
        <Row className={"mt-4"}>
          <Col xs={12}>
            <h6>5. Comments:</h6>
            <TextareaAutosize
              className={"w-100 mt-2"}
              rows="4"
              name="comments"
              onChange={handleChange}
            />
          </Col>
        </Row>
      </div>
    </Tab.Pane>
  );
};

export default SocialTab;
