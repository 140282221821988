import React, { useEffect, useState, useRef } from "react";

import { Container, Row, Col } from "react-bootstrap";

import SocialButtons from "components/frontend/listings/_shared/socialButtons";

import { ReactComponent as AirplaneAscentIcon } from "images/icons/airplane-ascent.svg";
import { ReactComponent as TimeIcon } from "images/icons/time.svg";
import { ReactComponent as LocationIcon } from "images/icons/location.svg";
import { ReactComponent as UserIcon } from "images/icons/user.svg";

export default function SaleInformation(props) {
  const { listing } = props;

  const [expanded, setExpanded] = useState(false);
  //const [showExpander, setShowExpander] = useState(false);
  const descriptionBox = useRef(null);
  const [totalLines, setTotalLines] = useState(3);

  useEffect(() => {
    // const el = descriptionBox.current;
    // if (el && el.offsetHeight < el.scrollHeight) {
    // setShowExpander(true);
    // }
    if (descriptionBox.current) {
      if(props.listing.description!==null){    
      const maxHeight = descriptionBox.current.scrollHeight;
      const lineHeight = parseFloat(getComputedStyle(descriptionBox.current).lineHeight);
      const maxLines = Math.floor(maxHeight / lineHeight);
      setTotalLines(maxLines + (props.listing.description.split("\n").length - 1));
    }
    }
  }, [props.listing.description]);
  let listingMode = listing.level.value;
  if (listingMode !== "featured") {
    // Set display type to Subscriber or Basic/Free
    if (
      listing.company &&
      listing.company.plan &&
      listing.company.plan.rank &&
      listing.company.plan.rank > 0
    ) {
      listingMode = "subscriber";
    } else {
      listingMode = "basic";
    }
  }

  const location = () => {
    const locArr = [];
    if (listing.city) locArr.push(listing.city);
    if (listing.state) locArr.push(listing.state.title);
    if (listing.country) locArr.push(listing.country.title);

    if (locArr.length > 0) {
      return (
        <span>
          Located in: <strong>{locArr.join(", ")}</strong>
        </span>
      );
    }

    return <></>;
  };
  const year = () => {
    if (listing.saleDetails.year) {
      return listing.saleDetails.year;
    }
    return <em>Unknown</em>;
  };
  const totalTime = () => {
    if (listing.saleDetails.totalTime) {
      const formattedString = new Intl.NumberFormat("en-us").format(
        listing.saleDetails.totalTime
      );
      return `${formattedString} Hours`;
    }
    return <em>Unknown</em>;
  };
  const landings = () => {
    if (listing.saleDetails.landings) {
      const formattedString = new Intl.NumberFormat("en-us").format(
        listing.saleDetails.landings
      );
      return `${formattedString}`;
    }
    return <em>Unknown</em>;
  };
  const passengers = () => {
    if (listing.saleDetails.passengers) {
      const formattedString = new Intl.NumberFormat("en-us").format(
        listing.saleDetails.passengers
      );
      return `${formattedString} Seats`;
    }
    return <em>Unknown</em>;
  };
  return (
    <div className="for-sale-detail-information detail-information">
      <Container className="py-5">
        <Row className="for-sale-detail-information-header detail-information-header">
          <Col className="d-md-flex align-items-center justify-content-between">
            <h2 className="detail-information-title information-title mb-4 mb-md-0">
              Aircraft <strong>Information</strong>
            </h2>
            <SocialButtons listing={listing} />
          </Col>
        </Row>

        <Row className="mt-4 mx-n5">
          {listing.saleDetails.year && (
            <Col
              id="detail-information-year"
              xs={12}
              sm={6}
              lg={3}
              className="detail-information-stack mb-5 px-5"
            >
              <div className="up">
                <AirplaneAscentIcon />
                <span>Year</span>
              </div>
              <div className="down">{year()}</div>
            </Col>
          )}

          {listing.saleDetails.totalTime && (
            <Col
              id="detail-information-total-time"
              xs={12}
              sm={6}
              lg={3}
              className="detail-information-stack mb-5 px-5"
            >
              <div className="up">
                <TimeIcon />
                <span>Total Time</span>
              </div>
              <div className="down">{totalTime()}</div>
            </Col>
          )}

          {listing.saleDetails.landings && (
            <Col
              id="detail-information-landings"
              xs={12}
              sm={6}
              lg={3}
              className="detail-information-stack mb-5 px-5"
            >
              <div className="up">
                <LocationIcon />
                <span>Landings</span>
              </div>
              <div className="down">{landings()}</div>
            </Col>
          )}

          {listing.saleDetails.passengers && (
            <Col
              id="detail-information-passengers"
              xs={12}
              sm={6}
              lg={3}
              className="detail-information-stack mb-5 px-5"
            >
              <div className="up">
                <UserIcon />
                <span>Passengers</span>
              </div>
              <div className="down">{passengers()}</div>
            </Col>
          )}
        </Row>
        {console.log("listingMode", listingMode)
        }
        {/* {listingMode !== "basic" && ( */}
        <>
          <Row>
            <Col className="detail-information-location information-location">
              {location()}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="detail-information-description information-description pb-4">
              <div
                // className={`overflow-container ${numberOfLines > 3 ?expanded? "expanded" : "collapsed":''}`}
                // className="overflow-container"
                className={`overflow-container ${expanded ? "expanded" : "collapsed"}`}


                ref={descriptionBox}
              >
                {/* {props.listing.description} */}
                {listing.description &&
                  listing.description.split("\n").map((line, i) => {
                    return <p key={`description-line-${i}`}>{line}</p>;
                  })}
              </div>
              {totalLines > 3 && (
                <div className="overflow-button text-center mt-3">
                  <button
                    onClick={(e) => {
                      setExpanded(!expanded);
                    }}
                    className="btn btn-black read-more-button"
                  >
                    {console.log("expanded" + expanded)}
                    {expanded ? "Read Less" : "Read More"}
                  </button>
                </div>
              )}
            </Col>
          </Row>
        </>
        {/* )} */}
      </Container>
    </div>
  );
}
