import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "components/shared/spinner";
import { Formik } from "formik";

import { openMessageModal, closeMessageModal } from "actions/modal/actions";
import { encrypt } from "utils/crypto";

import RoleRenderer from "components/shared/roleRenderer";
import CreditCardForm from "components/shared/creditCard";
import CreditCardView from "components/shared/creditCard/view";
import CreditCard from "components/shared/creditCard/components/card";

import { parseAxiosError } from "utils/errors";
import { addPayment, deletePayment } from "actions/user/actions";

import cardType from "utils/cards";

const initialCardState = {
  cardNumber: "",
  cardHolder: "",
  cardMonth: "",
  cardYear: "",
  cardCvv: "",
  isCardFlipped: false,
};

function PaymentContent(props) {
  const {
    user,
    openMessageModal,
    closeMessageModal,
    deletePayment,
    addPayment,
  } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [payment, setPayment] = useState();
  const [card, setCard] = useState();

  const cardDisplay = (data) => {
    let number;
    switch (data.type) {
      case "discover":
        number = "6011";
        break;
      case "mastercard":
        number = "5111";
        break;
      case "amex":
        number = "3411";
        break;
      case "visa":
      default:
        number = "4111";
        break;
    }

    const expiration = new Date(data.expiration);

    return {
      cardHolder: data.holder,
      cardNumber: `${number} 1111 1111 ${data.lastFour}`,
      cardMonth: (expiration.getMonth() + 1).toString().padStart(2, "0"),
      cardYear: expiration.getFullYear().toString().substr(2, 2),
      cardCvv: 0,
    };
  };

  useEffect(() => {
    if (user && user.email) {
      axios
        .get(`accounts/${user.email}/payment`)
        .then((response) => {
          const { payment } = response.data;
          if (payment) {
            setPayment(payment);
            setCard(cardDisplay(payment));
          }
          setLoading(false);
        })
        .catch((err) => {
          setError(parseAxiosError(err));
          setLoading(false);
        });
    } else {
      setError("No User Provided!");
      setLoading(false);
    }
  }, [user]);

  const createPaymentPayload = (values) => {
    const card = {
      number: values.cardNumber,
      cvv: values.cardCvv,
    };
    const encryptedCard = encrypt(card);

    // const decryptedCard = decrypt(
    //   encryptedCard.data,
    //   encryptedCard.key,
    //   encryptedCard.iv
    // );
    // Generate encrypted card data
    return {
      type: cardType(values.cardNumber),
      holder: values.cardHolder,
      card: encryptedCard,
      //decryptedCard: JSON.parse(decryptedCard),
      expiration: new Date(values.cardYear, values.cardMonth - 1),
      lastFour: values.cardNumber.replace(/\s/g, "").slice(-4),
    };
  };

  const handleFormSubmit = (values, setSubmitting) => {
    addPayment(user.email, createPaymentPayload(values))
      .then((payment) => {
        closeMessageModal();
        setPayment(payment);
        setCard(cardDisplay(payment));
      })
      .catch((err) => {
        openMessageModal(
          "Error Adding Card. Please contact an administrator.",
          () => (
            <Button
              className="btn btn-white"
              onClick={() => {
                closeMessageModal();
              }}
            >
              Okay
            </Button>
          )
        );
      });
  };

  const handleAddCardChanged = (evt, setFieldValue) => {
    const { name, value } = evt.target;
    setFieldValue(name, value);
  };

  const addCardModal = () => {
    let cardValid = false;
    openMessageModal(
      <Formik
        initialValues={initialCardState}
        validateOnChange={true}
        validate={(values) => {
          return cardValid;
        }}
        onSubmit={(values, { setSubmitting, validateForm }) => {
          validateForm().then((vals) => {
            handleFormSubmit(values, setSubmitting);
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValid,
          setFieldValue,
          validateForm,
          /* and other goodies */
        }) => {
          return (
            <Form
              className="sales-content plan-and-bill"
              onSubmit={handleSubmit}
            >
              <CreditCardForm
                style={{ marginTop: "-100px" }}
                initialState={initialCardState}
                onChange={(e) => handleAddCardChanged(e, setFieldValue)}
                values={values}
                touched={touched}
                errors={errors}
                onValidation={(valid) => {
                  cardValid = valid;
                  validateForm();
                }}
              />
              <Button
                className="btn btn-green mr-3"
                type="submit"
                disabled={isSubmitting || !cardValid}
              >
                Add Card
              </Button>
              <Button
                className="btn btn-white"
                onClick={() => {
                  closeMessageModal();
                }}
              >
                Cancel
              </Button>
            </Form>
          );
        }}
      </Formik>,
      () => <></>
    );
  };

  const removeCardModal = () => {
    openMessageModal(
      "Are you sure you want to remove the billing card used with this account?",
      () => (
        <>
          <Button
            className="btn btn-green"
            onClick={() => {
              deleteCard();
            }}
          >
            Yes, Remove
          </Button>
          <Button
            className="btn btn-white"
            onClick={() => {
              closeMessageModal();
            }}
          >
            No, Cancel
          </Button>
        </>
      )
    );
  };

  const deleteCard = () => {
    deletePayment(user.email)
      .then(() => {
        setCard(undefined);
        setPayment(undefined);
        closeMessageModal();
      })
      .catch((err) => {
        openMessageModal(
          "Error Deleting Card. Please contact an administrator.",
          () => (
            <Button
              className="btn btn-white"
              onClick={() => {
                closeMessageModal();
              }}
            >
              Okay
            </Button>
          )
        );
      });
  };

  const content = () => {
    if (error) {
      // Error
      return (
        <div className="edit-error form-error error">
          <h1 className="favorite-title">Error!</h1>
          <p>{error}</p>
        </div>
      );
    } else if (loading) {
      return (
        <div className="form-loading edit-loading loading">
          <Spinner />
        </div>
      );
    } else {
      return (
        <div className="edit-card">
          {!card && <Button onClick={addCardModal}>Add Card</Button>}
          {card && (
            <>
              <CreditCard
                style={{ marginLeft: 0 }}
                values={card}
                isCardFlipped={false}
              />
              <Button onClick={removeCardModal} className="btn mr-3">
                Delete Card
              </Button>
              <RoleRenderer role="super-admin">
                <CreditCardView card={payment} />
              </RoleRenderer>
            </>
          )}
        </div>
      );
    }
  };

  return (
    <div className="profile">
      <Row>
        <Col xs={12}>
          <div className="subtitle">Add a payment method to your account.</div>
          {content()}
        </Col>
      </Row>
    </div>
  );
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    openMessageModal: (message, actions) =>
      dispatch(openMessageModal({ message, actions })),
    closeMessageModal: () => dispatch(closeMessageModal()),
    addPayment: (email, payment) => dispatch(addPayment(email, payment)),
    deletePayment: (email) => dispatch(deletePayment(email)),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(PaymentContent);
