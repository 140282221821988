import React from "react";
import classNames from "classnames";

export default class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      checked:
        typeof this.props.checked === "boolean" ? this.props.checked : false,
      disabled:
        typeof this.props.disabled === "boolean" ? this.props.disabled : false,
      onLabel: this.props.onLabel || "On",
      offLabel: this.props.offLabel || "Off",
      onStyle: this.props.onStyle || "primary",
      offStyle: this.props.offStyle || "light",
      size: this.props.size || "",
      style: this.props.style || "",
      width: this.props.width || null,
      height: this.props.height || null,
    };
  }

  componentDidUpdate(_, prevState) {
    const { checked } = this.props;
    if (typeof checked === "boolean" && checked !== prevState.checked) {
      this.setState({ checked });
    }
  }

  toggle = (event) => {
    this.state.checked ? this.off() : this.on();
  };
  off = () => {
    if (!this.state.disabled) {
      this.setState({
        checked: false,
      });
      if (this.props.onChange) this.props.onChange(false);
    }
  };
  on = () => {
    if (!this.state.disabled) {
      this.setState({
        checked: true,
      });
      if (this.props.onChange) this.props.onChange(true);
    }
  };
  enable = () => {
    this.setState({
      disabled: false,
    });
  };
  disable = () => {
    this.setState({
      disabled: true,
    });
  };

  render = () => {
    let switchStyle = {};
    switchStyle.width = this.state.width
      ? (switchStyle.width = this.state.width + "px")
      : null;
    switchStyle.height = this.state.height
      ? (switchStyle.height = this.state.height + "px")
      : null;

    let labelStyle = {};
    if (this.state.height)
      labelStyle.lineHeight = "calc(" + this.state.height + "px * 0.8)";

    return (
      <div
        className={classNames(
          "switch",
          "btn",
          this.state.checked
            ? "on btn-" + this.state.onStyle
            : "off btn-" + this.state.offStyle,
          this.state.size ? " btn-" + this.state.size : "",
          this.state.style ? " " + this.state.style : ""
        )}
        style={switchStyle}
        onClick={this.toggle}
      >
        <div className="switch-group">
          <span
            className={
              "switch-on btn btn-" +
              this.state.onStyle +
              (this.state.size ? " btn-" + this.state.size : "")
            }
            style={labelStyle}
          >
            {this.state.onLabel}
          </span>
          <span
            className={
              "switch-off btn btn-" +
              this.state.offStyle +
              (this.state.size ? " btn-" + this.state.size : "")
            }
            style={labelStyle}
          >
            {this.state.offLabel}
          </span>
          <span
            className={
              "switch-handle btn btn-light" +
              (this.state.size ? "btn-" + this.state.size : "")
            }
          />
        </div>
      </div>
    );
  };
}
