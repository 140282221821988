import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import SimilarItem from "components/frontend/listings/_shared/similarItem";

const SimilarListings = (props) => {
  const { similar } = props;
  const count = props.count || 6;

  if (similar && similar.count > 0) {
    return (
      <aside id="also-interested-row" className="pt-5 also-interested">
        <Container>
          <Row>
            <Col xs={12} className="also-interested-column pt-4">
              <h3 className="mb-4">
                You May Also Be <strong>Interested In...</strong>
              </h3>
              <Row>
                {similar.slice(0, count).map((s, i) => {
                  return (
                    <Col key={i} xs={12} sm={6} lg={4}>
                      <SimilarItem listing={s} />
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      </aside>
    );
  }

  return <></>;
};

export default SimilarListings;
