import React from "react";
import PropTypes from "prop-types";
import Avatar from "components/shared/avatar";
import { Row, Col, Form } from "react-bootstrap";
import moment from "moment";
import { Link, generatePath } from "react-router-dom";

import { ReactComponent as FlagIcon } from "images/icons/flag.svg";
import { ReactComponent as FlagFilledIcon } from "images/icons/flag-filled.svg";

import Routes from "routes";

class PromotionTable extends React.Component {
  listingLink = (type, slug) => {
    switch (type) {
      case "wanted":
        return generatePath(Routes.wantedDetail, {
          listing: slug,
        });
      case "service":
        return generatePath(Routes.servicesDetail, {
          listing: slug,
        });
      case "sale":
      default:
        return generatePath(Routes.forSaleDetail, {
          listing: slug,
        });
    }
  };

  flagIcon = (promotion) => {
    if (promotion.flag) {
      return (
        <FlagFilledIcon
          title="Unflag"
          onClick={(e) => {
            e.stopPropagation();
            this.props.onUpdate({ flag: false }, promotion.id);
          }}
        />
      );
    }

    return (
      <FlagIcon
        title="Flag"
        onClick={(e) => {
          e.stopPropagation();
          this.props.onUpdate({ flag: true }, promotion.id);
        }}
      />
    );
  };

  getPlan = (promotion) => {
    if (promotion.company && promotion.company.plan)
      return promotion.company.plan;
    if (promotion.account && promotion.account.plan)
      return promotion.account.plan;
    return { title: "Unknown", price: 0.0 };
  };

  cost = (promotion) => {
    const plan = this.getPlan(promotion);
    let price = 0;
    if (plan) {
      switch (promotion.type.value) {
        case "homepage":
          price = plan.homepageDisplayCost;
          break;
        case "featured":
          price = plan.featuredListingCost;
          break;
        case "sponsored":
          price = plan.sponsoredAdCost;
          break;
        case "blitz":
          price = plan.blitzEmailCost;
          break;
        case "social-instagram":
          price = plan.socialInstagramCost;
          break;
        case "social-facebook":
          price = plan.socialFacebookCost;
          break;
        case "social-twitter":
          price = plan.socialTwitterCost;
          break;
        default:
          price = plan.price;
          break;
      }
    }
    return new Intl.NumberFormat("en-us", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(price);
  };

  getCategory = (listing) => {
    console.log(listing);
    try {
      switch (listing.type.value) {
        case "wanted":
          return listing.wantedDetails.model.type.title;
        case "service":
          return listing.serviceDetails.category.title;
        case "sale":
          return listing.saleDetails.model.type.title;
        default:
          return "Unknown";
      }
    } catch (ex) {
      return "Unknown";
    }
  };

  render() {
    return (
      <>
        <div className="inbox-table">
          <Row className="header">
            <Col>
              <Form.Group className="checkbox-wrapper">
                <Form.Check
                  type="checkbox"
                  label="Select All"
                  onChange={(evt) =>
                    this.props.onSelect &&
                    this.props.onSelectAll(evt.target.checked)
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          {this.props.data.map((promotion, key) => {
            const flaggedClass = promotion.flag ? "flagged" : "unflagged";
            const plan = this.getPlan(promotion);

            return (
              <Row
                key={key}
                className={`item-row promotion-row status-${promotion.status.value} ${flaggedClass}`}
                onClick={() => {
                  if (this.props.showPopup) {
                    this.props.showPopup(promotion);
                  }
                }}
              >
                <div className="column-wrapper">
                  <Col
                    xs={{ span: 12, order: 0 }}
                    md="auto"
                    className="select-column item-column"
                  >
                    <div>
                      <Form.Check
                        type="checkbox"
                        checked={
                          this.props.selected
                            ? this.props.selected.includes(promotion.id)
                            : false
                        }
                        onChange={(evt) =>
                          this.props.onSelect &&
                          this.props.onSelect(promotion.id, evt.target.checked)
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      />
                    </div>
                    <div>{this.flagIcon(promotion)}</div>
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    md={5}
                    lg={{ span: 4, order: 1 }}
                    className="from-column d-flex item-column mx-n1"
                  >
                    <div className="from-avatar px-1">
                      <Avatar
                        name={promotion.account.profile.name}
                        src={promotion.account.profile.profileImage}
                        size={32}
                      />
                    </div>
                    <div className="from-info px-1">
                      <div className="from-name">
                        <span className="label">From:</span>{" "}
                        <span className="value">
                          {promotion.account.profile.name}
                        </span>
                      </div>
                      {promotion.account.email && (
                        <div className="from-email">
                          <span className="label">Email:</span>{" "}
                          <a
                            href={`mailto:${promotion.account.email}`}
                            className="value"
                          >
                            {promotion.account.email}
                          </a>
                        </div>
                      )}
                      {promotion.account.profile.phone && (
                        <div className="from-phone">
                          <span className="label">Phone:</span>{" "}
                          <a
                            href={`tel:${promotion.account.profile.phone}`}
                            className="value"
                          >
                            {promotion.account.profile.phone}
                          </a>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    md={5}
                    lg={{ span: 3, order: 2 }}
                    className="meta-column item-column mx-n1"
                  >
                    <div>Cost: {this.cost(promotion)}</div>
                    <div>Level: {plan.title}</div>
                    <div>{moment(promotion.createdAt).calendar()}</div>
                  </Col>
                  <Col
                    xs={12}
                    md={12}
                    lg={{ span: 4, order: 3 }}
                    className="listing-column item-column"
                  >
                    <Row>
                      <Col xs={12} md={6} lg={12} className="listing">
                        <span className="label">Listing:</span>{" "}
                        <Link
                          to={this.listingLink(
                            promotion.listing.type.value,
                            promotion.listing.slug
                          )}
                          className="value"
                        >
                          {promotion.listing.title}
                        </Link>
                        <div>Type: {promotion.listing.type.title}</div>
                        <div>
                          Category: {this.getCategory(promotion.listing)}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    md={12}
                    lg={{ span: 12, order: 4 }}
                    className="message-column item-column"
                  >
                    <div className="message-title">
                      <span className="status label">
                        {promotion.status.title}:
                      </span>{" "}
                      - {promotion.title}
                    </div>
                    <div className="message-subtitle">
                      Subhead - {promotion.subtitle}
                    </div>
                    <div className="message-notes">
                      Comments - {promotion.comments}
                    </div>
                  </Col>
                </div>
              </Row>
            );
          })}
        </div>
      </>
    );
  }
}

PromotionTable.propTypes = {
  data: PropTypes.array,
  selected: PropTypes.array,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  showPopup: PropTypes.func,
};

export default PromotionTable;
