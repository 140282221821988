import React from "react";
import Form from "react-bootstrap/Form";

export default class FilterCheckbox extends React.Component {
	render() {
		return (
			<Form.Group controlId={this.props.id} className="filter-checkbox">
				<Form.Check custom type="checkbox" label={this.props.label} onChange={this.props.onChange} checked={this.props.checked} value={this.props.value} />
			</Form.Group>
		);
	}
}
