import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

import { ReactComponent as CloseIcon } from "images/icons/close-circle.svg";
import { closeMessageModal } from "actions/modal/actions";

class MessageModal extends Component {
  render() {
    const { show, message, actions, handleClose, css, showClose } = this.props;

    return (
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        centered
        className={css || ""}
      >
        <div className="message-modal">
          {showClose && (
            <button className="btn close" onClick={handleClose}>
              <CloseIcon />
            </button>
          )}
          <div className="message">{typeof(message) === 'function' ? message() : message}</div>
          {actions && <div className="actions">{actions()}</div>}
        </div>
      </Modal>
    );
  }
}

const keyStateToProps = state => {
  return {
    show: state.modal.messageModal.open,
    message: state.modal.messageModal.message,
    actions: state.modal.messageModal.actions,
    css: state.modal.messageModal.css,
    showClose: state.modal.messageModal.showClose
  };
};

const keyDispatchToProps = dispatch => {
  return {
    handleClose: () => dispatch(closeMessageModal())
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(MessageModal);
