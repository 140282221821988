import React, { Fragment } from "react";
import { connect } from "react-redux";
import { Link, generatePath } from "react-router-dom";

import ReactPlayer from "react-player";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Breadcrumbs from "components/frontend/listings/_shared/breadcrumbs";

import routes from "routes";

import {
  openContactForm,
  openMessageModal,
  closeMessageModal,
} from "actions/modal/actions";

const pluralize = require("pluralize");

const ServicesHeader = (props) => {
  const { company, listing } = props;
  let profile;

  if (company && company.profiles) {
    profile = props.company.profiles
      .filter((p) => p.type.value === "public")
      .shift();
  }

  const breadcrumbData = [
    {
      text: "Services",
      link: routes.services,
    },
  ];
  const serviceCategory = listing.serviceDetails.category;
  if (serviceCategory)
    breadcrumbData.push({
      text: pluralize(serviceCategory.title),
      link: `${routes.services}?category=${serviceCategory.value}`,
    });

  const listingTitle = () => {
    return <h1 className="title">{listing.title}</h1>;
  };

  const openVideoModal = () => {
    if (props.openMessageModal) {
      props.openMessageModal(
        <div className="video-player-wrapper">
          <ReactPlayer url={listing.video} width="100%" height="auto" />
        </div>,
        null,
        "video-modal",
        true
      );
    }
  };

  const addr = [];
  if (listing.city) addr.push(listing.city);
  if (listing.city && listing.state) addr.push(",");
  if (listing.state) addr.push(listing.state.value);
  if (listing.zip) addr.push(listing.zip);
  if (listing.country) addr.push(listing.country.title);

  return (
    <header className="company-detail-header detail-header">
      <Container className="pb-5">
        <Row>
          <Col>
            <Breadcrumbs data={breadcrumbData} />
            {listingTitle()}
          </Col>
        </Row>
        <Row className="mt-4">
          {company && company.logo && (
            <Col sm={5} md={4} lg={3} className="logo-column mb-4">
              <div className="company-logo">
                <div className="company-logo-centered">
                  <img
                    src={company.logo}
                    alt={`${company.name} Logo`}
                    className="img-fluid"
                  />
                </div>
              </div>
            </Col>
          )}
          <Col sm={7} md={8} lg={3} className="company-column mb-4">
            {company && (
              <h2 className="title company-name mt-0">{company.name}</h2>
            )}

            <ul className="service-tags">
              {listing.serviceDetails.category && (
                <li className="sub-title category">
                  Category:{" "}
                  <span className="bold">
                    {listing.serviceDetails.category.title}
                  </span>
                </li>
              )}
              {addr.length > 0 && (
                <li className="sub-title location">
                  Located at{" "}
                  <address className="bold">
                    {addr.map((l, i) => {
                      if (typeof l === "string")
                        return <Fragment key={i}>{l} </Fragment>;
                      return l;
                    })}
                  </address>
                </li>
              )}
            </ul>
          </Col>
          <Col sm={12} md={6} lg={3} className="mb-4">
            <div className="info">
              {profile && profile.phone && (
                <div className="detail-header-info company-phone">
                  Company Phone:{" "}
                  <a href={`tel:${profile.phone}`}>{profile.phone}</a>
                </div>
              )}

              {profile && profile.fax && (
                <div className="detail-header-info company-fax">
                  Fax Number: <a href={`tel:${profile.fax}`}>{profile.fax}</a>
                </div>
              )}
              {profile && profile.email && (
                <div className="detail-header-info sales-email">
                  Email: <a href={`mailto:${profile.email}`}>{profile.email}</a>
                </div>
              )}

              {company && company.website && (
                <div className="detail-header-info website">
                  Website:{" "}
                  <a
                    href={company.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {company.website}
                  </a>
                </div>
              )}
            </div>
          </Col>
          <Col sm={12} md={6} lg={3} className="action-column mb-4">
            <ul className="detail-contact-company-contact-list contact-company-contact-list company-contact-list">
              {listing &&
                listing.account &&
                listing.account.profile &&
                listing.account.profile.name && (
                  <li className="detail-contact-company-contact-item contact-company-contact-item company-contact-item">
                    <span>Sales Rep: </span>
                    <span>{listing.account.profile.name}</span>
                  </li>
                )}
              {listing &&
                listing.account &&
                listing.account.profile &&
                listing.account.profile.phone && (
                  <li className="detail-contact-company-contact-item contact-company-contact-item company-contact-item">
                    <span>Phone: </span>
                    <a href={`tel:${listing.account.profile.phone}`}>
                      {listing.account.profile.phone}
                    </a>
                  </li>
                )}
            </ul>
            <div>
              <button
                className="btn btn-primary mb-3 contact-button"
                onClick={() => props.openContactForm(listing)}
              >
                Contact
              </button>
            </div>
            {listing.document && (
              <div>
                <a
                  href={listing.document}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary mb-3 download-button"
                >
                  Download PDF
                </a>
              </div>
            )}

            {listing.video && (
              <div>
                <button
                  className="btn btn-primary mb-3 contact-button"
                  onClick={openVideoModal}
                >
                  View Video
                </button>
              </div>
            )}

            {listing.video360 && (
              <div>
                <a
                  href={listing.video360}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary mb-3 download-button"
                >
                  360&deg; Video
                </a>
              </div>
            )}
            {listing && listing.company && listing.company.slug && (
              <Link
                to={generatePath(routes.companyServices, {
                  company: listing.company.slug,
                })}
                className="btn btn-primary mb-4"
              >
                See All Company Listings
              </Link>
            )}
          </Col>
        </Row>
      </Container>
    </header>
  );
};

const keyDispatchToProps = (dispatch) => {
  return {
    openContactForm: (listing) => dispatch(openContactForm(listing)),
    openMessageModal: (message, actions, css, showClose) =>
      dispatch(openMessageModal({ message, actions, css, showClose })),
    closeMessageModal: () => dispatch(closeMessageModal()),
  };
};

export default connect(null, keyDispatchToProps)(ServicesHeader);
