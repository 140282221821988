import React from "react";

import InformationBlock from "components/frontend/listings/for-sale/shared/informationBlock";

export default function Propeller(props) {
  const { listing } = props;

  return (
    listing.propeller && (
      <InformationBlock name="propeller" title="Propeller Details">
        <p
          dangerouslySetInnerHTML={{ __html: listing.propeller }}
          className="white-space-pre"
        />
      </InformationBlock>
    )
  );
}
