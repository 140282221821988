import React, { Component } from "react";
import PropTypes from "prop-types";

import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import classNames from "classnames";

import { ReactComponent as EditIcon } from "images/icons/edit.svg";
import { ReactComponent as TrashIcon } from "images/icons/trash.svg";
import { ReactComponent as DoneIcon } from "images/icons/check.svg";

class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: props.edit || false,
      value: props.value,
    };

    this.controlRef = React.createRef();
  }

  saveValue = () => {
    if (this.state.value !== this.props.value) {
      this.props.onChange(this.state.value);
    }

    this.setEdit(false);
  };

  setValue = (value) => {
    this.setState({ value });
  };

  getValue = () => {
    return this.state.value;
  };

  setEdit = (mode) => {
    if (!this.props.readOnly) this.setState({ isEdit: mode });
  };

  render() {
    const { label, renderValue, renderInput, readOnly, className, showDelete } =
      this.props;
    const { value, isEdit } = this.state;

    return (
      <InputGroup className={classNames("editable-input-group", className)}>
        <Form.Label className="editable-input-group-label input-group-label group-label label">
          {label}:
        </Form.Label>
        {!readOnly && isEdit ? (
          <div className="editable-input-group-editing input-group-editing group-editing editing">
            {renderInput ? (
              <div className="editable-input-group-control input-group-control control">
                {renderInput({
                  setEdit: this.setEdit,
                  setValue: this.setValue,
                  getValue: this.getValue,
                  saveValue: this.saveValue,
                })}
              </div>
            ) : (
              <>
                <FormControl
                  ref={this.controlRef}
                  className="editable-input-group-control input-group-control control"
                  value={value}
                  onChange={(e) => this.setState({ value: e.target.value })}
                  onBlur={() => this.saveValue()}
                  onKeyUp={(e) =>
                    e.keyCode === 13 ? this.saveValue() : () => {}
                  }
                />
                <DoneIcon
                  className="editable-input-group-done input-group-done group-done done"
                  onClick={() => this.saveValue()}
                />
              </>
            )}
          </div>
        ) : (
          <div className="editable-input-group-reading input-group-reading group-reading reading">
            <div className="editable-input-group-read-value input-group-read-value group-read-value read-value value">
              {renderValue
                ? renderValue(value, {
                    setEdit: this.setEdit,
                    setValue: this.setValue,
                    getValue: this.getValue,
                    saveValue: this.saveValue,
                  })
                : value}
            </div>
            {!readOnly && (
              <EditIcon
                className="editable-input-group-read-edit input-group-read-edit group-read-edit read-edit edit"
                onClick={() =>
                  this.setState({ isEdit: true }, () => {
                    this.controlRef &&
                      this.controlRef.current &&
                      this.controlRef.current.focus();
                  })
                }
              />
            )}
            {!readOnly && showDelete && (
              <TrashIcon
                className="editable-input-group-read-delete input-group-read-delete group-read-delete read-delete delete"
                onClick={() =>
                  this.setState({ isEdit: true }, () => {
                    this.controlRef &&
                      this.controlRef.current &&
                      this.controlRef.current.focus();
                  })
                }
              />
            )}
          </div>
        )}
      </InputGroup>
    );
  }
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  renderInput: PropTypes.func,
  renderValue: PropTypes.func,
};

export default Input;
