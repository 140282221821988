import React from "react";
import axios from "axios";
import queryString from "query-string";
import { generatePath } from "react-router-dom";
import classNames from "classnames";

import { Container, Row, Col, Collapse, Button } from "react-bootstrap";

import Spinner from "components/shared/spinner";
import CompanyHeader from "components/frontend/company/shared/header.js";
import Listings from "components/frontend/listings/_shared/listings";

import SaleListItem from "components/frontend/listings/for-sale/shared/listItem";
import WantedListItem from "components/frontend/listings/wanted/shared/listItem";
import ServiceListItem from "components/frontend/listings/services/shared/listItem";

import FilterGroup from "components/frontend/listings/_shared/filterGroup";
import FilterCheckbox from "components/frontend/listings/_shared/filterCheckbox";
import FilterDropdown from "components/frontend/listings/_shared/filterDropdown";
import FilterControl from "components/frontend/listings/_shared/filterControl";

import { ReactComponent as ArrowUp } from "images/icons/arrow-up.svg";
import { ReactComponent as AirplaneAscentIcon } from "images/icons/airplane-ascent.svg";
import { ReactComponent as AirplaneDescentIcon } from "images/icons/airplane-descent.svg";
import { ReactComponent as ServicesIcon } from "images/icons/services.svg";

import Routes, { createTitle } from "routes";
import TabRoutes from "components/shared/tabRoutes";
class CompanyProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      results: undefined,
      error: undefined,
      showReps: false,
      filterTypes: [],
      filterSizes: [],
      filterMakes: [],
      filterModels: [],
      filterCategories: [],
    };
  }

  componentDidMount = () => {
    document.title = createTitle("Loading...", Routes);
    const slug = this.props.match.params.company;

    axios
      .get("/masterData?keys=aircraftType,aircraftSize,serviceCategory")
      .then((response) => {
        const masterData = response.data.rows;
        this.setState({
          filterTypes: masterData.filter((data) => data.key === "aircraftType"),
          filterSizes: masterData.filter((data) => data.key === "aircraftSize"),
          filterCategories: masterData.filter(
            (data) => data.key === "serviceCategory"
          ),
        });
      });
    axios.get("/makes?limit=0").then((response) => {
      this.setState({ filterMakes: response.data.data.rows });
    });
    axios.get("/models?limit=0").then((response) => {
      this.setState({ filterModels: response.data.data.rows });
    });

    axios
      .get(`/company/${slug}`)
      .then((response) => {
        document.title = createTitle(response.data.data.name, Routes);
        this.setState({
          isLoading: false,
          error: undefined,
          results: response.data.data,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          results: undefined,
          error: this.ErrorMessage(error),
        });
      });
  };

  ErrorMessage = (error) => {
    if (error.response) {
      // Response Error
      return error.response.data.message;
    } else if (error.request) {
      return "Unable to connect. Please try again later.";
    } else {
      return "Unknown Error.";
    }
  };

  getSearchParams = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    const newParams = {};

    // Limit
    if (currentParams["show"]) {
      newParams["limit"] = currentParams["show"] || 10;
    }

    return { ...currentParams, ...newParams };
  };

  resetFilterParams = () => {
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "",
    });
  };

  CompanyRepAccordion = () => {
    if (
      this.state.results.accounts.filter(
        (a) => a.email && a.totalListingsCount > 0
      ).length > 0
    ) {
      return (
        <aside className="company-reps">
          <Container className="py-5">
            <Row>
              <Col>
                <div className={this.state.showReps ? "open" : "closed"}>
                  <Button
                    onClick={() =>
                      this.setState({ showReps: !this.state.showReps })
                    }
                    className="btn-invisible"
                    aria-controls="sales-rep-collapse"
                    aria-expanded={this.state.showReps}
                  >
                    <h2>
                      Sales <strong>Reps</strong>
                      <span className="caret">
                        <ArrowUp />
                      </span>
                    </h2>
                  </Button>
                  <Collapse in={this.state.showReps}>
                    <Row id="sales-rep-collapse" className="reps-columns">
                      {this.state.results.accounts
                        .filter(
                          (a) =>
                            a.email &&
                            a.profile &&
                            a.profile.name &&
                            a.totalListingsCount > 0
                        )
                        .map((a) => (
                          <Col
                            key={a.email}
                            xs={12}
                            sm={6}
                            md={4}
                            lg={3}
                            className="rep-column mb-4"
                          >
                            <h4 className="rep-name">{a.profile.name}</h4>
                            {a.profile.phone && (
                              <div className="rep-phone">
                                <a href={`tel:+${a.profile.phone}`}>
                                  {a.profile.phone}
                                </a>
                              </div>
                            )}
                          </Col>
                        ))}
                    </Row>
                  </Collapse>
                </div>
              </Col>
            </Row>
          </Container>
        </aside>
      );
    }

    return <></>;
  };

  CompanySaleFilters = () => {
    const years = (start, end) =>
      Array.from({ length: end - start }, (v, k) => k + start);

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    const selectedTypes = currentParams["types"]
      ? currentParams["types"].split(",")
      : [];

    return (
      <div className="filters-pane">
        <FilterGroup
          id="type-filters"
          name="types"
          label="Types"
          onChange={this.handleFilterChange}
        >
          {this.state.filterTypes.map((type) => (
            <FilterCheckbox
              key={type.value}
              value={type.value}
              label={type.title}
            />
          ))}
        </FilterGroup>
        {selectedTypes.includes("jet") && (
          <FilterGroup
            id="size-filters"
            name="size"
            label="Size"
            onChange={this.handleFilterChange}
          >
            {this.state.filterSizes.map((size) => (
              <FilterCheckbox
                key={size.value}
                value={size.value}
                label={size.title}
              />
            ))}
          </FilterGroup>
        )}

        <FilterGroup
          id="make-filters"
          name="make"
          label="Make"
          onChange={this.handleFilterChange}
          className="scrollable"
        >
          {this.state.filterMakes.map((make) => (
            <FilterCheckbox
              key={make.slug}
              value={make.slug}
              label={make.name}
            />
          ))}
        </FilterGroup>
        {currentParams["make"] && (
          <FilterGroup
            id="model-filters"
            name="model"
            label="Model"
            onChange={this.handleFilterChange}
            className="scrollable"
          >
            {this.state.filterModels
              .filter((m) => currentParams["make"].includes(m.make.slug))
              .map((model) => (
                <FilterCheckbox
                  key={model.id}
                  value={model.id}
                  label={model.name}
                />
              ))}
          </FilterGroup>
        )}

        <FilterGroup id="year-filters" label="Years">
          <FilterDropdown id="year-min-filter" name="yearMin" label="Min">
            <option></option>
            {years(1900, new Date().getFullYear() + 2)
              .reverse()
              .map((year, i) => {
                return (
                  <option value={year} key={i}>
                    {year}
                  </option>
                );
              }, this)}
          </FilterDropdown>
          <FilterDropdown id="year-max-filter" name="yearMax" label="Max">
            <option></option>
            {years(1900, new Date().getFullYear() + 2)
              .reverse()
              .map((year, i) => {
                return (
                  <option value={year} key={i}>
                    {year}
                  </option>
                );
              }, this)}
          </FilterDropdown>
        </FilterGroup>

        {this.state.results.accounts.filter(
          (a) => a.saleListingsCount > 0 && a.email
        ).length > 0 && (
          <FilterGroup
            id="rep-filters"
            name="rep"
            label="Reps"
            onChange={this.handleFilterChange}
            className="scrollable company-filters"
          >
            {this.state.results.accounts
              .filter((r) => r.saleListingsCount > 0 && r.email)
              .map((rep) => (
                <FilterCheckbox
                  key={rep.email}
                  value={rep.email}
                  label={rep.profile.name}
                />
              ))}
          </FilterGroup>
        )}
        <FilterGroup id="price-filters" label="Price">
          <FilterControl
            id="price-min-filter"
            name="priceMin"
            label="Min"
            type="number"
            prepend="$"
          />
          <FilterControl
            id="price-max-filter"
            name="priceMax"
            label="Max"
            type="number"
            prepend="$"
          />
        </FilterGroup>
      </div>
    );
  };

  CompanySaleListings = () => {
    const params = this.getSearchParams();
    return (
      <Row className="mb-5">
        <Col xs={12} sm={{ span: 8, order: 2 }} md={9} lg={10}>
          <Listings
            className="for-sale-listings"
            url="/listings/type/sale"
            params={{
              ...params,
              company: this.state.results.slug,
            }}
            showOrderBy={true}
          >
            {(result, i) => (
              <SaleListItem
                listing={result}
                company={result.company}
                plan={result.company.plan}
                key={i}
              />
            )}
          </Listings>
        </Col>
        <Col
          sm={{ span: 4, order: 1 }}
          md={3}
          lg={2}
          className="d-none d-sm-block"
        >
          <aside id="sidebar-filters" className="for-sale-filters filters">
            <h3>Filter By</h3>
            {this.CompanySaleFilters()}
            <footer className="filters-footer text-center mt-3">
              <button
                className="btn btn-primary btn-green"
                onClick={this.resetFilterParams}
              >
                Reset
              </button>
            </footer>
          </aside>
        </Col>
      </Row>
    );
  };

  CompanyWantedFilters = () => {
    const years = (start, end) =>
      Array.from({ length: end - start }, (v, k) => k + start);

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    const selectedTypes = currentParams["types"]
      ? currentParams["types"].split(",")
      : [];

    return (
      <div className="filters-pane">
        <FilterGroup
          id="type-filters"
          name="types"
          label="Types"
          onChange={this.handleFilterChange}
        >
          {this.state.filterTypes.map((type) => (
            <FilterCheckbox
              key={type.value}
              value={type.value}
              label={type.title}
            />
          ))}
        </FilterGroup>
        {selectedTypes.includes("jet") && (
          <FilterGroup
            id="size-filters"
            name="size"
            label="Size"
            onChange={this.handleFilterChange}
          >
            {this.state.filterSizes.map((size) => (
              <FilterCheckbox
                key={size.value}
                value={size.value}
                label={size.title}
              />
            ))}
          </FilterGroup>
        )}

        <FilterGroup
          id="make-filters"
          name="make"
          label="Make"
          onChange={this.handleFilterChange}
          className="scrollable"
        >
          {this.state.filterMakes.map((make) => (
            <FilterCheckbox
              key={make.slug}
              value={make.slug}
              label={make.name}
            />
          ))}
        </FilterGroup>
        {currentParams["make"] && (
          <FilterGroup
            id="model-filters"
            name="model"
            label="Model"
            onChange={this.handleFilterChange}
            className="scrollable"
          >
            {this.state.filterModels
              .filter((m) => currentParams["make"].includes(m.make.slug))
              .map((model) => (
                <FilterCheckbox
                  key={model.id}
                  value={model.id}
                  label={model.name}
                />
              ))}
          </FilterGroup>
        )}

        <FilterGroup id="year-filters" label="Years">
          <FilterDropdown id="year-min-filter" name="yearMin" label="Min">
            <option></option>
            {years(1900, new Date().getFullYear() + 2)
              .reverse()
              .map((year, i) => {
                return (
                  <option value={year} key={i}>
                    {year}
                  </option>
                );
              }, this)}
          </FilterDropdown>
          <FilterDropdown id="year-max-filter" name="yearMax" label="Max">
            <option></option>
            {years(1900, new Date().getFullYear() + 2)
              .reverse()
              .map((year, i) => {
                return (
                  <option value={year} key={i}>
                    {year}
                  </option>
                );
              }, this)}
          </FilterDropdown>
        </FilterGroup>

        {this.state.results.accounts.filter(
          (a) => a.wantedListingsCount > 0 && a.email
        ).length > 0 && (
          <FilterGroup
            id="rep-filters"
            name="rep"
            label="Reps"
            onChange={this.handleFilterChange}
            className="scrollable company-filters"
          >
            {this.state.results.accounts
              .filter((r) => r.wantedListingsCount > 0 && r.email)
              .map((rep) => (
                <FilterCheckbox
                  key={rep.email}
                  value={rep.email}
                  label={rep.profile.name}
                />
              ))}
          </FilterGroup>
        )}
      </div>
    );
  };

  CompanyWantedListings = () => {
    const params = this.getSearchParams();
    return (
      <Row className="mb-5">
        <Col xs={12} sm={{ span: 8, order: 2 }} md={9} lg={10}>
          <Listings
            className="for-sale-listings"
            url="/listings/type/wanted"
            params={{
              ...params,
              company: this.state.results.slug,
            }}
            showOrderBy={true}
            // resultCountMessage={
            //   <span>
            //     Aircraft <strong>For Sale</strong>
            //   </span>
            // }
          >
            {(result, i) => (
              <WantedListItem
                listing={result}
                company={result.company}
                plan={result.company.plan}
                key={i}
              />
            )}
          </Listings>
        </Col>
        <Col
          sm={{ span: 4, order: 1 }}
          md={3}
          lg={2}
          className="d-none d-sm-block"
        >
          <aside id="sidebar-filters" className="for-sale-filters filters">
            <h3>Filter By</h3>
            {this.CompanyWantedFilters()}
            <footer className="filters-footer text-center mt-3">
              <button
                className="btn btn-primary btn-green"
                onClick={this.resetFilterParams}
              >
                Reset
              </button>
            </footer>
          </aside>
        </Col>
      </Row>
    );
  };

  CompanyServiceFilters = () => {
    return (
      <div className="filters-pane">
        <FilterGroup
          id="category-filters"
          name="category"
          label="Categories"
          onChange={this.handleFilterChange}
        >
          {this.state.filterCategories.map((type) => (
            <FilterCheckbox
              key={type.value}
              value={type.value}
              label={type.title}
            />
          ))}
        </FilterGroup>

        {this.state.results.accounts.filter(
          (a) => a.serviceListingsCount > 0 && a.email
        ).length > 0 && (
          <FilterGroup
            id="rep-filters"
            name="rep"
            label="Reps"
            onChange={this.handleFilterChange}
            className="scrollable company-filters"
          >
            {this.state.results.accounts
              .filter((r) => r.serviceListingsCount > 0 && r.email)
              .map((rep) => (
                <FilterCheckbox
                  key={rep.email}
                  value={rep.email}
                  label={rep.profile.name}
                />
              ))}
          </FilterGroup>
        )}
      </div>
    );
  };

  CompanyServiceListings = () => {
    const params = this.getSearchParams();
    return (
      <Row className="mb-5">
        <Col xs={12} sm={{ span: 8, order: 2 }} md={9} lg={10}>
          <Listings
            className="for-sale-listings"
            url="/listings/type/service"
            params={{
              ...params,
              company: this.state.results.slug,
            }}
            showOrderBy={true}
          >
            {(result, i) => (
              <ServiceListItem
                listing={result}
                company={result.company}
                plan={result.company.plan}
                key={i}
              />
            )}
          </Listings>
        </Col>
        <Col
          sm={{ span: 4, order: 1 }}
          md={3}
          lg={2}
          className="d-none d-sm-block"
        >
          <aside id="sidebar-filters" className="for-sale-filters filters">
            <h3>Filter By</h3>
            {this.CompanyServiceFilters()}
            <footer className="filters-footer text-center mt-3">
              <button
                className="btn btn-primary btn-green"
                onClick={this.resetFilterParams}
              >
                Reset
              </button>
            </footer>
          </aside>
        </Col>
      </Row>
    );
  };

  render() {
    if (this.state.error) {
      return (
        <div
          id={"company-detail-error"}
          className={classNames("company-detail", "company-detail-error")}
        >
          <Container className="py-5">
            <Row>
              <Col>
                <h1 className="listings-title">{this.state.error}</h1>
              </Col>
            </Row>
          </Container>
        </div>
      );
    } else if (this.state.results) {
      const company = this.state.results;
      return (
        <div
          id={`${company.slug}-detail`}
          className={classNames("company-detail", "company-detail-results")}
        >
          <section className="app-main listing-detail company-detail">
            <CompanyHeader company={company} />
            <this.CompanyRepAccordion />

            <Container>
              <TabRoutes
                tabs={[
                  {
                    route: Routes.company,
                    url: generatePath(Routes.company, {
                      company: company.slug,
                    }),
                    icon: AirplaneAscentIcon,
                    title: "For Sale",
                    component: this.CompanySaleListings,
                  },
                  {
                    route: Routes.companyWanted,
                    url: generatePath(Routes.companyWanted, {
                      company: company.slug,
                    }),
                    icon: AirplaneDescentIcon,
                    title: "Wanted",
                    component: this.CompanyWantedListings,
                  },

                  {
                    route: Routes.companyServices,
                    url: generatePath(Routes.companyServices, {
                      company: company.slug,
                    }),
                    icon: ServicesIcon,
                    title: "Services",
                    component: this.CompanyServiceListings,
                  },
                ]}
              />
            </Container>
          </section>
        </div>
      );
    } else {
      return (
        <div
          id={`company-detail-loading`}
          className={classNames("company-detail", "company-detail-loading")}
        >
          <Spinner />
        </div>
      );
    }
  }
}

export default CompanyProfile;
