import React from "react";

import { Row, Col, Tab } from "react-bootstrap";

import PromoteCalendar from "components/backend/promotions/shared/calendar";
import TextareaAutosize from "react-textarea-autosize";
const FeaturedTab = (props) => {
  const handleChange = (evt) => {
    props.onChange && props.onChange(evt.target.name, evt.target.value);
  };

  return (
    <Tab.Pane eventKey="#featured">
      {!props.active && <div className="form-overlay"></div>}
      <div className="form-content feature-detail py-2 px-4">
        <h6 className={"mt-2"}>
          Category:{" "}
          <span className="text-green">
            {
              // TODO: Add Category
            }
          </span>
        </h6>
        <Row className={"mt-2"}>
          <Col xs={12}>
            <div className={"pb-3"}>
              <h6>1. Please select desired promotion week.</h6>

              <div className="text-uppercase my-3">
                No dates are guaranteed and all are subject to review, approval,
                and availability of Plane Mover. A Plane Mover Representative
                will contact you with confirmation.
              </div>
            </div>
          </Col>
          <Col className={"Calendar-div"} xs={12}>
            <PromoteCalendar
              onChange={(date) => {
                if (props.onChange) {
                  props.onChange("startDate", date);
                }
              }}
            />
          </Col>
        </Row>
        <Row className={"mt-4"}>
          <Col className={"Calendar-div"} xs={12}>
            <h6>2. Comments:</h6>
            <TextareaAutosize
              className={"w-100 mt-3"}
              rows="5"
              name="comments"
              onChange={handleChange}
            />
          </Col>
        </Row>
      </div>
    </Tab.Pane>
  );
};

export default FeaturedTab;
