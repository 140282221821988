import React from "react";
import { Link } from "react-router-dom";

export default function Breadcrumb(props) {
	const list = props.data? props.data : [];
	return (
		<ul className="breadcrumbs bread-crumb">
			{list.map((item, i) => (
				<li key={i} className="breadcrumb-item"><BreadcrumbItem text={item.text} link={item.link} /></li>
			))}
		</ul>
	);
}

function BreadcrumbItem(props) {
	if (props.link) {
		return (
			<Link to={props.link} className="breadcrumb-link">
				<BreadcrumbText text={props.text} />
			</Link>
		);
	} else {
		return (
			<BreadcrumbText text={props.text} />
		);
	}
}

function BreadcrumbText(props) {
	return (<span className="breadcrumb-text">{props.text}</span>);
}
