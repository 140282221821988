import React, { Component } from "react";
import axios from "axios";
import queryString from "query-string";

import Routes, { createTitle } from "routes";

import { Container, Row, Col } from "react-bootstrap";
import Spinner from "components/shared/spinner";

import Pagination from "components/frontend/listings/_shared/pagination";
import FilterDropdown from "components/frontend/listings/_shared/filterDropdown";

import StandaloneTabs from "components/shared/standaloneTabs";
import ListingCard from "components/backend/shared/listingCard";

const tabList = ["FOR SALE", "WANTED", "SERVICE"];

class FavoritesManage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchUrl: false,
      isLoading: true,
      results: [],
      error: undefined,
      count: 0,
      pages: 0,
      activeNav: 0,
    };

    this.sorting = [
      { sort: null, order: null, label: "Newest" },
      { sort: "created", order: "ASC", label: "Oldest" },
      { sort: "listing", order: "ASC", label: "Listing: A to Z" },
      { sort: "listing", order: "DESC", label: "Listing: Z to A" },
      { sort: "sender", order: "DESC", label: "Sender: A to Z" },
      { sort: "sender", order: "ASC", label: "Sender: Z to A" },
    ];

    this.paging = [
      { count: 12, label: "12 per page" },
      { count: 24, label: "24 per page" },
      { count: 48, label: "48 per page" },
      { count: 96, label: "96 per page" },
    ];

    this.fetchUrl = this.props.type
      ? `/accounts/me/favorites/${this.props.type}`
      : `/accounts/me/favorites/sale`;
  }

  componentDidMount() {
    document.title = createTitle(
      this.props.status ? `Inquiry Archive` : "Inquiry Inbox",
      Routes
    );
    this.fetchResults();
  }

  componentDidUpdate() {
    this.fetchResults();
  }

  getSearchParams() {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    const newParams = {};

    // Limit
    if (currentParams["show"]) {
      newParams["limit"] = currentParams["show"] || 12;
    }

    return { ...currentParams, ...newParams };
  }

  parseRequestUrl() {
    const fetchUrl = this.fetchUrl;
    const searchParams = this.getSearchParams();
    // Add Params
    if (searchParams) {
      // We have search params. Parse them!
      const searchParamsUrl = queryString.stringify(searchParams, {
        arrayFormat: "comma",
      });
      return `${fetchUrl}?${searchParamsUrl}`;
    }

    // Return URL
    return fetchUrl;
  }

  handlePageLimitChange = (event) => {
    const target = event.currentTarget;

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, show: target.value };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };
  getPageLimit = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const pageLimit = currentParams["show"] || 12;
    return parseInt(pageLimit);
  };
  handlePageChange = (pager) => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, page: pager.currentPage };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };
  getPage = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const page = currentParams["page"] || 1;
    return parseInt(page);
  };

  handleOrderByChange = (event) => {
    const target = event.currentTarget;

    const sort = this.sorting[target.value];

    if (sort) {
      const currentParams = queryString.parse(this.props.location.search, {
        arrayFormat: "comma",
      });
      const updatedObj = {
        ...currentParams,
        sort: sort.sort,
        order: sort.order,
      };
      const updatedParams = queryString.stringify(updatedObj, {
        arrayFormat: "comma",
      });

      this.props.history.push({
        pathname: this.props.location.pathname,
        search: "?" + new URLSearchParams(updatedParams).toString(),
      });
    }
  };

  getOrderBy = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    if (currentParams["sort"] && currentParams["order"]) {
      for (const [i, v] of this.sorting.entries()) {
        if (
          v.sort === currentParams["sort"] &&
          v.order === currentParams["order"]
        ) {
          return i;
        }
      }
    }
    return 0;
  };

  fetchResults() {
    let fetchUrl = this.parseRequestUrl();
    if (this.state.fetchUrl !== fetchUrl) {
      // Fetch URL has changed. Update state and get new results
      this.setState(
        {
          fetchUrl: fetchUrl,
          isLoading: true,
          results: [],
          selected: [],
          error: undefined,
          count: 0,
          pages: 0,
        },
        () => {
          if (fetchUrl) {
            axios
              .get(fetchUrl)
              .then((response) => {
                const results = response.data.data.rows;

                this.setState(
                  {
                    isLoading: false,
                    error: undefined,
                    results: results,
                    count: response.data.data.count,
                    pages: response.data.data.pageCount,
                  },
                  () => {
                    this.props.onFetchSuccess &&
                      this.props.onFetchSuccess(this.state);
                  }
                );
              })
              .catch((error) => {
                this.setState(
                  {
                    isLoading: false,
                    results: [],
                    error: error,
                    count: 0,
                    pages: 0,
                  },
                  () => {
                    this.props.onFetchError &&
                      this.props.onFetchError(this.state);
                  }
                );
              });
          }
        }
      );
    }
  }

  content = () => {
    if (this.state.error) {
      // Error
      return (
        <div className="edit-error form-error error">
          <h1 className="favorite-title">{this.state.error.message}</h1>
          <p>{this.ErrorMessage(this.state.error)}</p>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div className="form-loading edit-loading loading">
          <Spinner />
        </div>
      );
    } else if (this.state.results) {
      if (this.state.results.length > 0) {
        return (
          <>
            <Row className="favorites-cards align-items-stretch mx-n2">
              {this.state.results.map((item, i) => (
                <Col key={i} xs={12} md={6} lg={4} xl={3} className="mb-4 px-2">
                  <ListingCard listing={item} />
                </Col>
              ))}
            </Row>
            <Row className="justify-content-end mt-3">
              <FilterDropdown
                id="favorite-paging"
                name="show"
                label="View:"
                onChange={this.handlePageLimitChange}
                value={this.getPageLimit()}
              >
                {this.paging.map((paging, i) => {
                  return (
                    <option value={paging.count} key={i}>
                      {paging.label}
                    </option>
                  );
                })}
              </FilterDropdown>
              <Pagination
                totalRecords={this.state.count}
                pageLimit={this.getPageLimit()}
                pageNeighbours={1}
                onPageChanged={this.handlePageChange}
                currentPage={this.getPage()}
              />
            </Row>
          </>
        );
      }

      return (
        <div className="no-results">
          <h2 className="favorite-title">No Favorites</h2>
          <p>You don't have any favorited listing! Go add some!</p>
        </div>
      );
    }
  };

  handleNavItemClick(num) {
    if (num !== this.state.activeNav) {
      this.setState({ activeNav: num });
    }
  }

  handlePageClick = (num) => {
    let { selectedPages } = this.state;
    selectedPages[this.state.activeNav] = num;
    this.setState({ selectedPages: selectedPages });
  };

  render() {
    const { activeNav } = this.state;

    //const data =  FavoritesData[activeNav];
    //const selectedPage = selectedPages[activeNav];

    return (
      <section id="my-favorites" className="app-content app-main my-favorites">
        <Container>
          <Row className="mb-5">
            <Col xs={12} className="position-relative">
              <div className="my-favorites__title">
                MY <span>FAVORITES</span>
              </div>
              <StandaloneTabs
                list={tabList}
                active={activeNav}
                handleNavItemClick={(num) => this.handleNavItemClick(num)}
              />
            </Col>
          </Row>
          {this.content()}
        </Container>
      </section>
    );
  }
}

export default FavoritesManage;
