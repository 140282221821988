import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import Routes from "routes";

export default class SaleLinks extends Component {
	render() {
		return (
			<Row {...this.props} className={ 'sale-links ' + this.props.className}>
				<Col xs={12} sm={6}>
					<Link to={{ pathname: Routes.forSale, search: "?types=jet"}} className="btn">
						Jets
					</Link>
					<Link to={{ pathname: Routes.forSale, search: "?types=turboprop"}} className="btn">
						Turbo Props
					</Link>
					<Link to={{ pathname: Routes.forSale, search: "?types=single-piston"}} className="btn">
						Single Pistons
					</Link>
				</Col>
				<Col xs={12} sm={6}>
					<Link to={{ pathname: Routes.forSale, search: "?types=twin-piston"}} className="btn">
						Twin Pistons
					</Link>
					<Link to={{ pathname: Routes.forSale, search: "?types=helicopter"}} className="btn">
						Helicopters
					</Link>
					<Link to={Routes.forSale} className="btn">
						All Aircraft
					</Link>
				</Col>
			</Row>
		);
	}
}
