import { compose, applyMiddleware, createStore } from "redux"
import thunk from "redux-thunk"
import thunkMiddleware from 'redux-thunk'
import promise from "redux-promise-middleware"

import rootReducer from "reducers"


// Redux DevTool configration
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?   
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({}) : compose;

// Redux store configration
export default function configureStore() {
  const store = createStore(
    rootReducer,
    composeEnhancers(
      applyMiddleware(
        thunkMiddleware,
        promise,
        thunk
      )
    )
  )

  return store;
}