import axios from "axios";
import { SUBMIT_CONTACTFORM } from "./actionTypes.js";

import { closeContactForm } from "actions/modal/actions";

export function submitContactForm(slug, form) {
  return function(dispatch) {
    dispatch({
      type: SUBMIT_CONTACTFORM
    });

    return new Promise((resolve, reject) => {
      axios({
        method: "post",
        url: `listings/${slug}/inquiry`,
        data: form
      })
        .then(() => {
          dispatch(closeContactForm());
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  };
}
