import React from "react";
import parse from "html-react-parser";
import { Form } from "react-bootstrap";

export default (props) => {
  function priceFormatter(price) {
    const prices = price.split(".");
    if (Number(prices[1]) === 0) {
      return prices[0];
    } else {
      return price;
    }
  }

  const selectable =
    typeof props.selectable !== "undefined" ? props.selectable : true;

  return (
    <div
      className={`plan-card plan-card-${props.type} ${
        props.disabled ? "plan-disabled" : "plan-enabled"
      } ${props.mostPopular ? "most-popular" : ""}`}
    >
      {props.mostPopular && (
        <div className="most-popular-header">Most Popular!</div>
      )}
      <div className="header">
        {selectable && (
          <Form.Check
            custom
            type="radio"
            label=""
            id={props.type}
            name="plan"
            // disabled={props.passed}
            checked={props.currentPlan === props.type}
            onChange={props.handleChange}
            disabled={props.disabled}
            value={props.type}
          />
        )}

        <h2 className="title">{props.title}</h2>
      </div>
      <h2 className="plan-price">
        <span>$</span>
        <span>{priceFormatter(props.price)}</span>
        <span>/month</span>
      </h2>
      <div className="plan-list">
        <div className="description">{parse(props.description)}</div>
        <div className="promotions">
          <div className="internal">
            <div className="promotions-title">Internal Promotions</div>
            <div>
              <span>Homepage Display Upgrade: </span>
              <span>
                {props.homepageDisplayCost
                  ? `$${props.homepageDisplayCost}`
                  : "N/A"}
              </span>
            </div>
            <div>
              <span>Featured Listing: </span>
              <span>
                {props.featuredListingCost
                  ? `$${props.featuredListingCost}`
                  : "N/A"}
              </span>
            </div>
            <div>
              <span>Sponsored Ad: </span>
              <span>
                {props.sponsoredAdCost ? `$${props.sponsoredAdCost}` : "N/A"}
              </span>
            </div>
          </div>
          <div className="external">
            <div className="promotions-title">External Promotions</div>
            <div>
              <span>Blitz Email Broadcast: </span>
              <span>
                {props.blitzEmailCost ? `$${props.blitzEmailCost}` : "N/A"}
              </span>
            </div>
            <div>
              <span>Social Media Post - Facebook: </span>
              <span>
                {props.socialFacebookCost
                  ? `$${props.socialFacebookCost}`
                  : "N/A"}
              </span>
            </div>
            <div>
              <span>Social Media Post - Twitter: </span>
              <span>
                {props.socialTwitterCost
                  ? `$${props.socialTwitterCost}`
                  : "N/A"}
              </span>
            </div>
            <div>
              <span>Social Media Post - Instagram: </span>
              <span>
                {props.socialInstagramCost
                  ? `$${props.socialInstagramCost}`
                  : "N/A"}
              </span>
            </div>
          </div>
          {props.footerRender && (
            <footer className="footer d-flex mt-4 justify-content-center">
              {props.footerRender()}
            </footer>
          )}
        </div>
      </div>
    </div>
  );
};
