import {
  SET_APP_READY,
  OPEN_USER_SIDEBAR,
  CLOSE_USER_SIDEBAR,
  TOGGLE_USER_SIDEBAR,
  OPEN_MOBILE_MENU,
  CLOSE_MOBILE_MENU,
  TOGGLE_MOBILE_MENU,
  SHOW_COOKIES_NOTICE,
  HIDE_COOKIES_NOTICE,
} from "actions/app/actionTypes";

const initialState = {
  appReady: false,
  showUserSidebar: false,
  showMobileMenu: false,
  showCookiesNotice: false,
};

export default function appReducer(state = initialState, action) {
  const { type } = action;

  switch (type) {
    case SET_APP_READY:
      return Object.assign({}, state, {
        appReady: true,
      });
    case OPEN_USER_SIDEBAR:
      return Object.assign({}, state, {
        showUserSidebar: true,
      });
    case CLOSE_USER_SIDEBAR:
      return Object.assign({}, state, {
        showUserSidebar: false,
      });
    case TOGGLE_USER_SIDEBAR:
      return Object.assign({}, state, {
        showUserSidebar: !state.showUserSidebar,
      });
    case OPEN_MOBILE_MENU:
      return Object.assign({}, state, {
        showMobileMenu: true,
      });
    case CLOSE_MOBILE_MENU:
      return Object.assign({}, state, {
        showMobileMenu: false,
      });
    case TOGGLE_MOBILE_MENU:
      return Object.assign({}, state, {
        showMobileMenu: !state.showMobileMenu,
      });
    case SHOW_COOKIES_NOTICE:
      return Object.assign({}, state, {
        showCookiesNotice: true,
      });
    case HIDE_COOKIES_NOTICE:
      return Object.assign({}, state, {
        showCookiesNotice: false,
      });
    default:
      return state;
  }
}
