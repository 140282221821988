import React from "react";
import { Formik } from "formik";

import { Button, Row, Col, Form } from "react-bootstrap";
import RichTextEditor from "components/shared/richEditor";
import SaveButtonText from "components/backend/listings/fields/saveButtonText";
import stripHTML from "utils/stripHTML";
import { connect } from "react-redux";

// const yup = require("yup");

class Step5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      additionalFeatureInfo: {},
    };
  }

  componentDidMount = () => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  };

  handleValidation = (values) => {};

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.props.onChange(name, value);
  };

  handleCheckbox = (e, dataset) => {
    // Parse into name/value array
    const value = e.currentTarget.value;
    let valueArr = this.props.values[dataset] || [];
    if (e.currentTarget.checked) {
      // Add this value to our params
      if (!valueArr.includes(value)) valueArr.push(value);
    } else {
      // Remove this value from our params
      valueArr.splice(valueArr.indexOf(value), 1);
    }

    this.props.onChange(dataset, valueArr);
  };

  textEditorHandler = (value, name) => {
    let cleanedValue = stripHTML(value).trim();
    if (!cleanedValue) {
      value = null;
    }

    this.props.onChange(name, value);
  };

  render() {
    return (
      <Formik
        //validationSchema={schema}
        //onSubmit={values => this.handleSubmit(values)}
        onSubmit={this.handleValidation}
        initialValues={{}}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          isSubmitting,
          setSubmitting,
          validateForm,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <div className="stepper_4">
              <Form.Label className="subHeader">
                Things that make this one stand out?
              </Form.Label>
              <Row className="m-b-15">
                <Col>
                  <Form.Label className="line-height">
                    Additional equipment or features:
                  </Form.Label>
                  <RichTextEditor
                    name="additional"
                    value={this.props.values.additional || ""}
                    onChange={this.textEditorHandler}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label className="line-height">Weights:</Form.Label>
                  <RichTextEditor
                    name="weights"
                    value={this.props.values.weights || ""}
                    onChange={this.textEditorHandler}
                  />
                </Col>
              </Row>
              <Row className="stepper_btns">
                <Col md="auto">
                  <Button
                    type="submit"
                    className="btn-red m-b-15"
                    disabled={isSubmitting}
                    onClick={(e) => {
                      handleSubmit(e);
                      if (isValid) {
                        this.props.onNext().then(() => {
                          setSubmitting(false);
                        });
                      } else {
                        setSubmitting(false);
                      }
                    }}
                  >
                    let's talk looks
                  </Button>
                </Col>
                <Col md="auto">
                  <Button
                    className="btn-green"
                    disabled={isSubmitting}
                    onClick={(e) => {
                      handleSubmit(e);
                      if (isValid) {
                        this.props.onSave().then(() => {
                          setSubmitting(false);
                        });
                      } else {
                        setSubmitting(false);
                      }
                    }}
                  >
                    {SaveButtonText(this.props.values.status)}
                  </Button>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

export default connect(keyStateToProps)(Step5);
