/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, generatePath } from "react-router-dom";
import Routes, { createTitle } from "routes";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import Spinner from "components/shared/spinner";
import Step1 from "./stepper/step1";
import Step2 from "./stepper/step2";

import { openMessageModal, closeMessageModal } from "actions/modal/actions";
import {
  clearListing,
  getListing,
  saveListing,
  updateListing,
} from "actions/listing/actions";
import { parseAxiosError } from "utils/errors";

const stepperInitialState = [
  {
    id: "overview",
    title: "Overview",
    success: false,
  },
  {
    id: "media",
    title: "Media",
    success: false,
  },
];

const ServiceEdit = (props) => {
  const {
    listing,
    getListing,
    clearListing,
    updateListing,
    saveListing,
    user,
    match,
    history,
    openMessageModal,
    closeMessageModal,
  } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [slug, setSlug] = useState();
  const [stepper, setStepper] = useState(stepperInitialState);
  const [currentStep, setCurrentStep] = useState("overview");

  useEffect(() => {
    // Initial Load
    clearListing();

    // Set Slug, if any
    if (match.params.listing) {
      setSlug(match.params.listing);
      getListing("service", match.params.listing)
        .then((listing) => {
          document.title = createTitle(`Edit ${listing.title}`, Routes);
          setLoading(false);
        })
        .catch((err) => {
          setError(parseAxiosError(err));
        });
    } else {
      document.title = createTitle("Add New Avionics Service", Routes);
      setLoading(false);
    }
  }, [clearListing, getListing]);

  useEffect(() => {
    checkStepStatuses(stepper);
    if (match.params.step) {
      setCurrentStep(match.params.step);
    }
  }, [match.params.step]);

  // Direct the user to the specified step
  const goToStep = (step, newSlug) => {
    history.push(
      generatePath(Routes.servicesEdit, {
        ...match.params,
        listing: newSlug || slug,
        step,
      })
    );
  };

  // User has clicked one of the stepper bubbles
  const handleToStep = (step) => {
    saveListingData().then((newSlug) => {
      goToStep(step, newSlug);
      setLoading(false);
    });
  };

  // User has clicked the Next button
  const handleNext = () => {
    return new Promise((resolve, reject) => {
      saveListingData().then((newSlug) => {
        // State updated. Proceed to next step.
        switch (currentStep) {
          case "media":
            switch (user.role.value) {
              case "sales-rep":
                history.push(
                  generatePath(Routes.servicesStatus, {
                    ...match.params,
                    listing: newSlug,
                    status: "pending",
                  })
                );
                break;
              case "super-admin":
              case "company-admin":
              default:
                history.push(
                  generatePath(Routes.servicesStatus, {
                    ...match.params,
                    listing: newSlug,
                    status: "published",
                  })
                );
                break;
            }

            break;

          case "overview":
          default:
            goToStep("media", newSlug);
            break;
        }
      });

      setLoading(false);
      resolve();
    });
  };

  // User has clicked the Update/Save button
  const handleSave = () => {
    return new Promise((resolve, reject) => {
      return saveListingData()
        .then((newSlug) => {
          resolve();
          openMessageModal("Listing Saved!", () => (
            <>
              <button
                className="btn btn-primary btn-green"
                onClick={() => {
                  goToStep(currentStep, newSlug);
                  setLoading(false);
                  closeMessageModal();
                }}
              >
                Continue Editing
              </button>
              <Link
                className="btn btn-secondary btn-red"
                to={Routes.servicesManage}
                onClick={() => {
                  setLoading(false);
                  closeMessageModal();
                }}
              >
                Exit to Listings
              </Link>
              <Link
                className="btn btn-secondary btn-red"
                to={generatePath(Routes.servicesDetail, {
                  listing: newSlug,
                })}
                onClick={() => {
                  setLoading(false);
                }}
                target="_blank"
              >
                View Listing
              </Link>
            </>
          ));
        })
        .catch((err) => reject());
    });
  };

  const saveListingData = () => {
    setLoading(true);
    return saveListing("service", listing)
      .then((updated) => {
        return updated.slug;
      })
      .catch((err) => {
        const msg = parseAxiosError(err);
        openMessageModal(
          `Error Saving: ${msg}. Please try saving again later, or contact us if you continue to experience problems.`,
          () => (
            <button
              className="btn btn-green"
              onClick={() => {
                closeMessageModal();
                setLoading(false);
              }}
            >
              Okay
            </button>
          )
        );

        throw err;
      });
  };

  const handleChange = (name, value) => {
    updateListing(name, value);
  };

  const checkStepStatuses = (stepper) => {
    const updatedStepper = stepper.map((step, i) => {
      switch (step.id) {
        case "media":
          step.success = checkStep2Status();
          break;
        case "overview":
        default:
          step.success = checkStep1Status();
          break;
      }
      return step;
    });

    setStepper(updatedStepper);
  };

  const checkStep1Status = () => {
    const requiredFields = ["title", "category"];

    return checkStepFields(requiredFields, null);
  };

  const checkStep2Status = () => {
    const optionalFields = ["images", "video", "video360"];

    return checkStepFields(null, optionalFields);
  };

  const checkStepFields = (required, optional) => {
    if (listing) {
      if (required) {
        // We have required fields. We don't need to worry about optional ones
        return Object.keys(listing)
          .filter((key) => required.includes(key))
          .reduce((passed, key) => {
            // If we have a value for this key, AND all previous checks have passed, allow it to continue with TRUE
            if (passed && listing[key]) {
              if (Array.isArray(listing[key]) && listing[key].length <= 0) {
                return false; // If this is an array, return FALSE if it is empty
              }

              return true;
            }
            // But if we have a previous FALSE, then we are still false
            return false;
          }, true); // Set initial check to TRUE, or all future checks will fail
      } else {
        // Parse optional
        return Object.keys(listing)
          .filter((key) => optional.includes(key))
          .reduce((passed, key) => {
            // If we have a value for this key, we've met at least 1 optional value. Passed!
            if (listing[key]) {
              if (Array.isArray(listing[key])) {
                if (listing[key].listing > 0) {
                  return true; // If this is an array, return TRUE if it is not empty
                }
              } else {
                return true;
              }
            }
            // Otherwise, return the original value and continue
            return passed;
          }, false); // Set initial check to FALSE, since we only need a single initial TRUE to succeed
      }
    }
  };

  const drawStepper = (stepper, currentStep) => {
    return stepper.map((step, index) => {
      const isCurrent = step.id === currentStep;
      const isFinished = step.success || false;
      return (
        <div key={index}>
          <div className={`d-grid ${isCurrent ? "active" : ""}`}>
            {index === 0 && <span></span>}
            {index !== 0 && <label></label>}
            <div
              className={`step ${isCurrent ? "active" : ""}`}
              onClick={() => handleToStep(step.id)}
            >
              {step.success ? <FontAwesomeIcon icon={faCheck} /> : ""}
            </div>
            {index !== stepper.length - 1 && <label></label>}
            {index === stepper.length - 1 && <span></span>}
          </div>
          <p
            className={`text-center ${isFinished ? "text-green " : ""} ${
              isCurrent ? ".d-block .d-sm-none" : "d-none d-md-block d-lg-block"
            }`}
          >
            {step.title}
          </p>
        </div>
      );
    });
  };

  const drawStep = (step) => {
    switch (step) {
      case "media":
        return (
          <Step2
            onNext={handleNext}
            onChange={handleChange}
            onSave={handleSave}
            values={listing || {}}
          />
        );
      case "overview":
      default:
        return (
          <Step1
            onNext={handleNext}
            onChange={handleChange}
            onSave={handleSave}
            values={listing || {}}
          />
        );
    }
  };

  const content = (loading, listing, error) => {
    if (error) {
      return (
        <div className="edit-error form-error error">
          <h1 className="listings-title">Error!</h1>
          <p>{error}</p>
        </div>
      );
    }

    if (loading) {
      return (
        <div className="form-loading edit-loading loading">
          <Spinner />
        </div>
      );
    }

    // Form Content
    return (
      <div className="edit-error form-error error">
        <div className="info-custom-stepper">
          <div className="display">{drawStepper(stepper, currentStep)}</div>
        </div>
        {drawStep(currentStep)}
      </div>
    );
  };

  return (
    <section
      id="app-manage-table-info"
      className="app-content app-main listings-manage listings-manage-list listings-section"
    >
      <div className="container stepper">
        <div className="title">
          Avionics Services <strong>Info</strong>
        </div>

        {content(loading, listing, error)}
      </div>
    </section>
  );
};

const keyStateToProps = (state) => {
  return {
    user: state.user,
    listing: state.listing,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    openMessageModal: (message, actions) =>
      dispatch(openMessageModal({ message, actions })),
    closeMessageModal: () => dispatch(closeMessageModal()),
    clearListing: () => dispatch(clearListing()),
    saveListing: (type, payload) => dispatch(saveListing(type, payload)),
    getListing: (type, slug) => dispatch(getListing(type, slug)),
    updateListing: (name, value) => dispatch(updateListing(name, value)),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(ServiceEdit);
