import React from "react";
import { connect } from "react-redux";

import EditableInput from "../shared/editableInput";
import SingleImageUpload from "../shared/singleImageUpload";
import RoleRenderer from "components/shared/roleRenderer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { addLogo } from "actions/companies/actions";

import ProfileBlock from "./profileBlock";

function EditCompanyPublicProfile(props) {
  const { company, profile, onCompanyChange, onProfileChange, onLogoChange } =
    props;

  const handleCompanyChange = (value) => {
    if (onCompanyChange) onCompanyChange(value);
  };

  const handleLogoChange = (value) => {
    // TODO: link logo changes to function and endpoint
    if (onLogoChange) onLogoChange(value);
  };

  const handleProfileChange = (value) => {
    if (onProfileChange) onProfileChange(profile, value);
  };

  return (
    <ProfileBlock
      title="Public Information"
      profile={profile}
      onChange={handleProfileChange}
      before={(profile, handleValueChange) => {
        return (
          <>
            <Row>
              <Col className="mb-5">
                <h3>Company Information</h3>
                <EditableInput
                  className="align-items-center"
                  label="Company Logo"
                  renderValue={() => {
                    if (company.logo) {
                      return (
                        <img
                          src={company.logo}
                          alt="Company Logo"
                          className="logo-preview"
                        />
                      );
                    } else {
                      return <span>Upload a Logo</span>;
                    }
                  }}
                  value={company.logo}
                  renderInput={({ setEdit, setValue }) => (
                    <SingleImageUpload
                      preview={company.logo}
                      onUpload={(file) => {
                        handleLogoChange(file);
                        setValue(file);
                        setEdit(false);
                      }}
                      onCancel={() => {
                        setEdit(false);
                      }}
                    />
                  )}
                  onChange={() => {}}
                />
              </Col>
            </Row>
            <Row key={`public-company-row`} className="mb-5">
              <Col xs={12} sm={6}>
                <EditableInput
                  key={`public-company-name`}
                  label="Company Name"
                  value={company.name || ""}
                  onChange={(value) => handleCompanyChange({ name: value })}
                />
              </Col>
              <Col xs={12} sm={6}>
                <EditableInput
                  key={`public-company-website`}
                  label="Website"
                  value={company.website || ""}
                  onChange={(value) => handleCompanyChange({ website: value })}
                />
              </Col>
              <RoleRenderer role="super-admin">
                <Col xs={12} sm={6}>
                  <EditableInput
                    key={`public-company-legacyId`}
                    label="Legacy ID"
                    value={company.legacyId || ""}
                    onChange={(value) =>
                      handleCompanyChange({ legacyId: value })
                    }
                  />
                </Col>
              </RoleRenderer>
            </Row>
            <Row>
              <Col>
                <h3>Public Profile (Visible to Everyone)</h3>
              </Col>
            </Row>
          </>
        );
      }}
    />
  );
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    addLogo: (slug, logo) => dispatch(addLogo(slug, logo)),
  };
};

export default connect(
  keyStateToProps,
  keyDispatchToProps
)(EditCompanyPublicProfile);
