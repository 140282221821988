import { RESET_DASHBOARD, SET_DASHBOARD } from "actions/dashboard/actionTypes";

const initialState = {};

export default function userReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case SET_DASHBOARD:
      return Object.assign({}, state, {
        ...payload,
      });
    case RESET_DASHBOARD:
      return Object.assign({}, initialState);
    default:
      return state;
  }
}
