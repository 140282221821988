import React from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = (props) => {
  const { onChange } = props;

  const handleChange = (value) => {
    if (onChange) onChange(value);
  };

  return (
    <ReCAPTCHA
      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
      onChange={handleChange}
    />
  );
};

export default Captcha;
