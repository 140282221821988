import React from "react";
import { Link, generatePath } from "react-router-dom";

import Routes from "routes";
import ListingLink from "utils/links";
import trimText from "utils/trimText";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Favorite from "components/frontend/listings/_shared/favorite";
import ImagePlaceholder from "images/listings/placeholder.png";

export default class SponsoredItem extends React.Component {
  ListingHeader = (data) => {
    const { listing, company } = data;
    return (
      <header className="listing-header">
        <div className="listing-title title">
          <h3>
            <Link to={ListingLink(listing)}>{data.title}</Link>
          </h3>
          <Favorite listing={listing} />
        </div>

        <div className="listing-company company">
          Company:{" "}
          <Link
            to={generatePath(Routes.company, { company: company.slug })}
            className="listing-company-link company-link"
          >
            {company.name}
          </Link>
        </div>
      </header>
    );
  };

  priceDisplay = (price, salePrice) => {
    let salePriceDisplay;
    if (salePrice) {
      switch (salePrice.value) {
        case "price":
          if (price) {
            salePriceDisplay = new Intl.NumberFormat("en-us", {
              style: "currency",
              currency: "USD",
            }).format(price);
          } else {
            salePriceDisplay = "Unknown";
          }

          break;
        default:
          salePriceDisplay = salePrice.title;
          break;
      }
    } else if (price) {
      salePriceDisplay = new Intl.NumberFormat("en-us", {
        style: "currency",
        currency: "USD",
      }).format(price);
    }

    if (salePriceDisplay) {
      return (
        <Col xs={12} md={6} className="meta-row meta-price">
          <span className="meta-label">Price:</span>{" "}
          <span className="meta-value">{salePriceDisplay}</span>
        </Col>
      );
    }
    return <></>;
  };

  locationDisplay = (listing) => {
    const addr = [];
    if (listing.city) addr.push(listing.city);
    if (listing.state) addr.push(listing.state.value);
    if (listing.zip) addr.push(listing.zip);
    if (listing.country) addr.push(listing.country.title);
    return addr.length > 0 ? addr.join(", ") : false;
  };
  SaleListing = () => {
    const { data } = this.props;
    const { listing, images } = data;
    const location = this.locationDisplay(listing);
    const description = trimText(data.subtitle || listing.description, 250);

    let imageStyle;
    if (images && images.length > 0) {
      imageStyle = {
        backgroundImage: `url('${images.slice().shift().url}')`,
      };
    }

    return (
      <Row>
        <Col xs={12} md={5}>
          <div className="listing-image listing-image image" style={imageStyle}>
            <Link
              to={generatePath(Routes.forSaleDetail, { listing: listing.slug })}
            >
              <img src={ImagePlaceholder} alt="" className="img-fluid" />
            </Link>
          </div>
        </Col>
        <Col xs={12} md={7}>
          {this.ListingHeader(data)}

          <Row className="listing-meta listing-meta">
            {this.priceDisplay(
              listing.saleDetails.price,
              listing.saleDetails.salePrice
            )}
            {listing.saleDetails.year && (
              <Col xs={12} md={6} className="meta-row meta-year">
                <span className="meta-label">Year:</span>{" "}
                <span className="meta-value">{listing.saleDetails.year}</span>
              </Col>
            )}
            {listing.saleDetails.registration && (
              <Col xs={12} md={6} className="meta-row meta-registration">
                <span className="meta-label">Registration Number:</span>{" "}
                <span className="meta-value">
                  {listing.saleDetails.registration}
                </span>
              </Col>
            )}
            {listing.saleDetails.totalTime && (
              <Col xs={12} md={6} className="meta-row meta-total-time">
                <span className="meta-label">Total Time:</span>{" "}
                <span className="meta-value">
                  {new Intl.NumberFormat("en-us").format(
                    listing.saleDetails.totalTime
                  )}
                </span>
              </Col>
            )}
            {location && (
              <Col xs={12} md={6} className="meta-row meta-total-time">
                <span className="meta-label">Location:</span>{" "}
                <span className="meta-value">{location}</span>
              </Col>
            )}
          </Row>
          {description && (
            <div className="listing-description listing-description description">
              {description}
            </div>
          )}
        </Col>
      </Row>
    );
  };

  renderContent = () => {
    const { data } = this.props;
    switch (data.listing.type.value) {
      case "sale":
        return this.SaleListing();
      default:
        return <></>;
    }
  };

  render() {
    return (
      <div className="sponsored-item list-item listing-item listing-item listing-listing">
        <div className="sponsored-bar">SPONSORED</div>
        {this.renderContent()}
      </div>
    );
  }
}
