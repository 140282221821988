import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, generatePath } from "react-router-dom";

import Routes from "routes";

// Spinner
import Spinner from "components/shared/spinner";

import Invalid from "./invalid";

import { verification } from "actions/user/actions";
import { parseAxiosError } from "utils/errors";

class Verification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: undefined,
      isLoaded: false,
    };
  }

  componentDidMount() {
    const { code } = this.props.match.params;
    this.props
      .verification(code)
      .then((res) => {
        this.setState({
          user: res.data,
          status: res.status,
          isLoaded: true,
        });
      })
      .catch((err) => {
        // Display error?
        this.setState({
          isLoaded: true,
          error: parseAxiosError(err),
        });
      });
  }

  render() {
    const { code } = this.props.match.params;
    if (this.state.isLoaded) {
      if (this.state.status === 401) {
        return <Invalid />;
      } else {
        return (
          <Redirect
            to={generatePath(Routes.registration, {
              code,
            })}
          />
        );
      }
    } else {
      return <Spinner />;
    }
  }
}

const keyDispatchToProps = (dispatch) => {
  return {
    verification: (code) => dispatch(verification(code)),
  };
};

export default connect(null, keyDispatchToProps)(Verification);
