import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import PasswordForm from "./passwordForm";

class ProfileContent extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  componentDidMount() {}
  render() {
    const { user, editor } = this.props;

    return (
      <div className="password">
        <Row>
          <Col xs={12}>
            <PasswordForm user={user} editor={editor} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default ProfileContent;
