import React from "react";

import InformationBlock from "components/frontend/listings/for-sale/shared/informationBlock";

export default function Avionics(props) {
  const { listing } = props;

  return (
    listing.avionics && (
      <InformationBlock name="avionics" title="Avionics">
        <p
          dangerouslySetInnerHTML={{ __html: listing.avionics }}
          className="white-space-pre"
        />
      </InformationBlock>
    )
  );
}
