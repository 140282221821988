import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { Form, Alert } from "react-bootstrap";

import { Formik } from "formik";
import { object, string } from "yup";

import { openForgotPassword, openSignup } from "actions/modal/actions";
import { logIn } from "actions/user/actions";

import Spinner from "components/shared/spinner";

import { parseAxiosError } from "utils/errors";

const LoginForm = (props) => {
  console.log(window.location);
  const { openSignup, openForgotPassword, logIn, location, history } = props;
  const [error, setError] = useState();

  const currentParams = queryString.parse(location.search, {
    arrayFormat: "comma",
  });

  const handleSubmit = (email, password, remember, setSubmitting) => {
    setError(undefined);
    logIn({
      email,
      password,
      remember,
    })
      .then(() => {
        if (currentParams && currentParams.redirect) {
          console.log(`Redirect Requested: ${currentParams.redirect}`);
          history.push(currentParams.redirect);
          //return <Redirect to={currentParams.redirect} />;
        }
      })
      .catch((err) => {
        const msg = parseAxiosError(err);
        setError(msg);
        setSubmitting(false); 
      });
  };

  return (
    <div>
      <div className="account-modal-title">
        READY FOR <span className="bold">TAKEOFF?</span>
      </div>
      <Formik
        initialValues={{
          email: "",
          password: "",
          remember: false,
        }}
        validationSchema={object().shape({
          email: string().required("Email is required").email("Invalid email"),
          password: string().required("Password is required"),
        })}
        onSubmit={({ email, password, remember }, { setSubmitting }) =>
          handleSubmit(email, password, remember, setSubmitting)
        }
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form
            onSubmit={isSubmitting ? (e) => e.preventDefault() : handleSubmit}
            className="account-form login-form"
          >
            {isSubmitting && (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
            {error && <Alert variant="danger">{error}</Alert>}
            <Form.Group>
              <Form.Label className="account-form-label">
                Email Address
              </Form.Label>
              <Form.Control
                className="account-form-input"
                type="text"
                id="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Johndoe@email.com"
              />
              {touched.email && errors.email && (
                <Form.Text className="account-form-error">
                  {errors.email}
                </Form.Text>
              )}
            </Form.Group>
            <Form.Group>
              <Form.Label className="account-form-label">Password</Form.Label>
              <Form.Control
                className="account-form-input"
                type="password"
                id="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder="Enter your password"
              />
              {touched.password && errors.password && (
                <Form.Text className="account-form-error">
                  {errors.password}
                </Form.Text>
              )}
            </Form.Group>
            <div className="d-md-flex align-items-center justify-content-between">
              <Link
                to="#"
                onClick={openForgotPassword}
                className="account-modal-link"
              >
                FORGOT YOUR PASSWORD?
              </Link>
              <div className="d-flex align-items-center justify-content-between justify-content-md-end mt-3 mt-md-0">
                <Form.Group className="mb-0 mr-5">
                  <Form.Check
                    type="checkbox"
                    className="account-form-remember"
                    label="Remember Me"
                    name="remember"
                    checked={values.remember}
                    onChange={handleChange}
                  />
                </Form.Group>
                <button className="btn btn-green" type="submit">
                  LOG IN
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <div className="login-footer">
        <div className="login-footer-title">
          New to Plane Mover? Join today!
        </div>
        <div className="login-footer-text">
          Opportunity awaits with listings, market change notifications,
          favorites, saved searches, and more.
        </div>
        <button className="btn btn-primary" onClick={openSignup}>
          CREATE AN ACCOUNT
        </button>
      </div>
    </div>
  );
};

const keyStateToProps = (state) => {
  return {};
};

const keyDispatchToProps = (dispatch) => {
  return {
    openSignup: () => dispatch(openSignup()),
    openForgotPassword: () => dispatch(openForgotPassword()),
    logIn: async (userInfo) => await dispatch(logIn(userInfo)),
  };
};

export default connect(
  keyStateToProps,
  keyDispatchToProps
)(withRouter(LoginForm));
