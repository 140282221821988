import React, { Component } from 'react';

import StandaloneTabs from 'components/shared/standaloneTabs';
import MySavedSearchesContent from './mySavedSearchesContent';

const pageValues = [
  {label: "5 per page", value: 5},
  {label: "10 per page", value: 10},
  {label: "15 per page", value: 15}
];

const FavoritesData = [
  [1,2],
  [1,2,3,4,5,6],
  [1]
];

const tabList = ["FOR SALE", "WANTED", "SERVICE"]

class MySavedSearches extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeNav: 0,
      selectedPages: [0, 0, 0]
    }
  }

  handleNavItemClick(num) {
    if(num !== this.state.activeNav) {
      this.setState({ activeNav: num })
    }
  }

  handlePageClick = (num) => {
    let { selectedPages } = this.state;
    selectedPages[this.state.activeNav] = num;
    this.setState({ selectedPages: selectedPages });
  };

  render() {
    const { activeNav, selectedPages } = this.state;

    const data =  FavoritesData[activeNav];
    const selectedPage = selectedPages[activeNav];

    return (
      <div id="my-saved-searches" className="app-content app-main my-saved-searches">
        <div className="row mb-5">
          <div className="col-12 position-relative col-md">
            <div className="my-saved-searches__title">MY <span>SAVED SEARCHES</span></div>
            <StandaloneTabs
              list={tabList}
              active={activeNav}
              handleNavItemClick={(num) => this.handleNavItemClick(num)}
            />
          </div>
        </div>
        <MySavedSearchesContent
          pageValues={pageValues}
          data={data}
          selectedPage={selectedPage}
          handlePageClick={(num) => this.handlePageClick(num)}
        />
      </div>
    );
  }
}

export default MySavedSearches;