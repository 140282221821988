import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { generatePath } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "components/shared/spinner";

import TabRoutes from "components/shared/tabRoutes";

import ProfileContent from "./profileContent";
import EmailContent from "./emailContent";
import PasswordContent from "./passwordContent";
import PaymentContent from "./paymentContent";
import UpgradeContent from "./upgradeContent";

import Routes, { createTitle } from "routes";

import {
  setUser,
  updateAccount,
  updateAvatar,
  upgradePlan,
} from "actions/user/actions";
import { parseAxiosError } from "utils/errors";

import { ReactComponent as EmailIcon } from "images/icons/reply.svg";
import { ReactComponent as PasswordIcon } from "images/icons/shield.svg";
import { ReactComponent as PaymentIcon } from "images/icons/dollar.svg";
import { ReactComponent as ProfileIcon } from "images/icons/user.svg";
import { ReactComponent as BriefcaseIcon } from "images/icons/briefcase.svg";

const AccountSettings = (props) => {
  const { editor, match } = props;
  const { updateAccount, updateAvatar, upgradePlan, setActiveUser } = props;

  const [user, setUser] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    document.title = createTitle("Account Settings", Routes);

    if (
      editor.role &&
      editor.role.value &&
      editor.role.value === "super-admin" &&
      match.params.email
    ) {
      axios
        .get(`/accounts/${match.params.email}`)
        .then((response) => {
          setUser(response.data.data);
        })
        .catch((error) => {
          setError(parseAxiosError(error));
        });
    } else {
      setUser(editor);
    }
  }, [editor, match.params.email]);

  const handleCompanyChange = (company) => {
    const accountInfo = {
      company,
    };
    updateAccount(accountInfo, user.email).then((data) => {
      setUser(data);
      if (user.email === editor.email) {
        setActiveUser(data);
      }
    });
  };

  const handleNameChange = (name) => {
    const accountInfo = {
      profile: {
        name: name,
      },
    };

    updateAccount(accountInfo, user.email).then((data) => {
      setUser(data);
      if (user.email === editor.email) {
        setActiveUser(data);
      }
    });
  };

  const handlePhoneChange = (phone) => {
    const accountInfo = {
      profile: {
        phone: phone,
      },
    };
    updateAccount(accountInfo, user.email).then((data) => {
      setUser(data);
      if (user.email === editor.email) {
        setActiveUser(data);
      }
    });
  };

  const handleAvatarChange = (file) => {
    // UPLOAD AVATAR
    if (file) {
      let formdata = new FormData();
      formdata.append("image", file);

      updateAvatar(formdata, user.email).then((data) => {
        setUser(data);
        if (user.email === editor.email) {
          setActiveUser(data);
        }
      });
      //setUser()
      // this.setState(
      //   (prevState) => ({
      //     results: { ...prevState.results, ...{ logo: undefined } },
      //   }),
      //   () => {
      //     let formdata = new FormData();
      //     formdata.append("image", file);

      //     updateAvatar(formdata);
      //   }
      // );
    }
  };

  const handleRoleChange = (role) => {
    axios
      .put(`accounts/role/${user.email}`, { role })
      .then((res) => {})
      .catch((err) => {});
  };

  const handleStatusChange = (checked) => {
    const status = checked ? "active" : "inactive";
    axios
      .put(`accounts/status/${user.email}`, {
        status,
      })
      .then((res) => {})
      .catch((err) => {});
  };

  const handlePlanChange = (plan) => {
    upgradePlan(user.email, plan).then((data) => {
      setUser(data);
      if (user.email === editor.email) {
        setActiveUser(data);
      }
    });
  };

  const content = () => {
    if (error) {
      // Error
      return (
        <div className="edit-error form-error error">
          <h1 className="favorite-title">Error!</h1>
          <p>{error}</p>
        </div>
      );
    } else if (user) {
      const tabs = [
        {
          url: generatePath(Routes.accountSettings, {
            email: user.email,
            tab: undefined,
          }),
          exact: false,
          title: "Profile",
          icon: ProfileIcon,
          component: () => (
            <ProfileContent
              user={user}
              editor={editor}
              onNameChange={handleNameChange}
              onPhoneChange={handlePhoneChange}
              onAvatarChange={handleAvatarChange}
              onStatusChange={handleStatusChange}
              onRoleChange={handleRoleChange}
              onCompanyChange={handleCompanyChange}
            />
          ),
        },
        {
          url: generatePath(Routes.accountSettings, {
            email: user.email,
            tab: "password",
          }),
          title: "Password",
          icon: PasswordIcon,
          component: () => <PasswordContent user={user} editor={editor} />,
        },
        {
          url: generatePath(Routes.accountSettings, {
            email: user.email,
            tab: "email",
          }),
          title: "Email",
          icon: EmailIcon,
          component: () => <EmailContent user={user} editor={editor} />,
        },
      ];

      if (user.role.value === "advertiser") {
        // User can have payments. Display tab.
        tabs.push({
          url: generatePath(Routes.accountSettings, {
            email: user.email,
            tab: "plan",
          }),
          icon: BriefcaseIcon,
          title: "Plan",
          component: () => (
            <UpgradeContent
              user={user}
              editor={editor}
              onSubmit={handlePlanChange}
            />
          ),
        });
      }

      if (user.role.value === "advertiser") {
        // User can have payments. Display tab.
        tabs.push({
          url: generatePath(Routes.accountSettings, {
            email: user.email,
            tab: "payment",
          }),
          icon: PaymentIcon,
          title: "Payment",
          component: () => <PaymentContent user={user} editor={editor} />,
        });
      }

      return (
        <TabRoutes
          tabs={tabs}
          redirectFrom={generatePath(Routes.accountSettings)}
          redirectTo={generatePath(Routes.accountSettings, {
            email: user.email,
            tab: undefined,
          })}
        />
      );
    } else {
      return (
        <div className="form-loading edit-loading loading">
          <Spinner />
        </div>
      );
    }
  };

  return (
    <section
      id="app-sitesettings"
      className="app-content app-main account-settings"
    >
      <Container>
        <Row mb={4}>
          <Col xs={12} md>
            <div className="title">
              ACCOUNT <span className="bold">SETTINGS</span>
            </div>
          </Col>
        </Row>

        {content()}
        <Row className="footer mt-5">
          <Col>
            To delete your account, please call{" "}
            <a href="tel:+17278235400">+1-727-823-5400</a> or email{" "}
            <a href="mailto:SALES@planemover.com?subject=Delete Account">
              SALES@planemover.com
            </a>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

const keyStateToProps = (state) => {
  return {
    editor: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    updateAccount: (accountInfo, email) =>
      dispatch(updateAccount(accountInfo, email)),
    updateAvatar: (formData, email) => dispatch(updateAvatar(formData, email)),
    upgradePlan: (email, plan) => dispatch(upgradePlan(email, plan)),
    setActiveUser: (user) => dispatch(setUser(user)),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(AccountSettings);
