import React, { Component } from "react";
import Routes, { createTitle } from "routes";

import PromotionList from "../shared/list";
import PromotionEdit from "./edit";

class SponsoredPromotions extends Component {
  componentDidMount() {
    document.title = createTitle(`Sponsored Promotions`, Routes);
  }

  render() {
    return (
      <PromotionList
        title="Sponsored Ad"
        url="/promotion/type/sponsored"
        editor={PromotionEdit}
      />
    );
  }
}

export default SponsoredPromotions;
