import axios from "axios";
import apis from "apis";

import {
  GET_LISTS,
  SET_LISTS,
  GET_SUBSCRIPTIONS,
  UPDATE_SUBSCRIPTIONS,
} from "./actionTypes.js";

export const getLists = () => (dispatch, getState) => {
  dispatch({
    type: GET_LISTS,
  });

  const { emails } = getState();
  if (emails && emails.length > 0) return emails;

  return axios
    .get(`${apis.email}/lists`)
    .then((response) => {
      const { lists } = response.data;
      dispatch(setLists(lists));
      return lists;
    })
    .catch((error) => {
      throw error;
    });
};

export const getSubscriptions = (email) => (dispatch) => {
  dispatch({
    type: GET_SUBSCRIPTIONS,
  });

  return axios
    .get(`${apis.email}/user/${email}`)
    .then((response) => {
      const { subscriptions } = response.data;
      return subscriptions;
    })
    .catch((error) => {
      throw error;
    });
};

export const updateSubscriptions = (email, slugs) => (dispatch) => {
  dispatch({
    type: UPDATE_SUBSCRIPTIONS,
  });

  return axios
    .put(`${apis.email}/user/${email}`, { subscriptions: slugs })
    .then((response) => {
      const { updated } = response.data;

      return updated;
    })
    .catch((error) => {
      throw error;
    });
};

export const setLists = (lists) => ({
  type: SET_LISTS,
  payload: lists,
});
