import React from "react";
import axios from "axios";
import queryString from "query-string";
import moment from "moment";

import Routes, { createTitle } from "routes";
import Table from "components/shared/table";
import MultiSelectFilter from "components/shared/multiSelectFilter";
import Avatar from "components/shared/avatar";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import Pagination from "components/frontend/listings/_shared/pagination";

import { ReactComponent as UserIcon } from "images/icons/user.svg";
import { ReactComponent as MailIcon } from "images/icons/mail.svg";
import { ReactComponent as FavoriteIcon } from "images/icons/heart.svg";
import { ReactComponent as AirplaneIcon } from "images/icons/airplane-ascent.svg";
import { ReactComponent as DollarIcon } from "images/icons/dollar.svg";
import { ReactComponent as PromotionIcon } from "images/icons/promotions.svg";
import { ReactComponent as PublishedIcon } from "images/icons/check.svg";
import { ReactComponent as UnpublishedIcon } from "images/icons/archive.svg";
import { ReactComponent as CompanyIcon } from "images/icons/briefcase.svg";
import {
  UserLink,
  ListingTitle,
  ListingLinkAndTitle,
  ListingEditAndTitle,
} from "utils/links";
import { generatePath, Link } from "react-router-dom";
import { getName } from "utils/user";

class NotificationPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      fetchUrl: false,
      isLoading: true,
      results: [],
      error: undefined,
      count: 0,
      pages: 0,
      filterReps: [],
      filterActions: [],
    };

    this.fetchUrl = `/notifications`;

    this.actionTypes = {
      // Listing
      INQUIRY: "inquiry",
      NEW_LISTING: "listing",
      PENDING_LISTING: "pending-listing",
      FAVORITE: "favorite",
      PRICE_CHANGE: "price-change",
      PUBLISHED: "published",
      UNPUBLISHED: "unpublished",
      SOLD: "sold",
      // Promotion
      PROMOTION: "promotion",
      // User
      NEW_USER: "new-user",
      PENDING_USER: "pending-user",
      USER_PAYMENT: "user-payment",
      // Company
      NEW_COMPANY: "new-company",
      COMPANY_PAYMENT: "company-payment",
      // Plan
      UPDATED_SUBSCRIPTION: "updated-subscription",
      UPDATED_PLAN: "updated-plan",
    };
  }

  componentDidMount() {
    document.title = createTitle("All Notifications", Routes);

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    this.setState({ search: currentParams["keyword"] || "" });

    axios.get("/masterData?keys=notificationType").then((response) => {
      const masterData = response.data.rows;

      // TODO: Replace with Redux?
      const filterActions = masterData
        .filter((data) => data.key === "notificationType")
        .map((data) => {
          return {
            value: data.value,
            title: data.title,
          };
        });

      this.setState({ filterActions });
    });

    // TODO: Replace with Redux?
    axios.get("/company?limit=0").then((response) => {
      const filterCompanies = response.data.data.rows.map((company) => {
        return {
          value: company.slug,
          title: company.name,
        };
      });
      this.setState({ filterCompanies });
    });

    this.fetchResults();
  }
  componentWillUnmount() {
    document.getElementById("body").className = "";
  }

  componentDidUpdate() {
    this.fetchResults();
  }

  getSearchParams() {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    const newParams = {};

    // Limit
    if (currentParams["show"]) {
      newParams["limit"] = currentParams["show"] || 10;
    }

    return { ...currentParams, ...newParams };
  }

  parseRequestUrl() {
    const fetchUrl = this.fetchUrl;
    const searchParams = this.getSearchParams();
    // Add Params
    if (searchParams) {
      // We have search params. Parse them!
      const searchParamsUrl = queryString.stringify(searchParams, {
        arrayFormat: "comma",
      });
      return `${fetchUrl}?${searchParamsUrl}`;
    }

    // Return URL
    return fetchUrl;
  }

  statusIcon(status, object) {
    switch (status.value) {
      case this.actionTypes.FAVORITE:
        return (
          <div className="icon-with-label">
            <FavoriteIcon title={status.title} />
            New Favorite
          </div>
        );
      case this.actionTypes.INQUIRY:
        return (
          <div className="icon-with-label">
            <MailIcon title={status.title} />
            New Inquiry
          </div>
        );
      case this.actionTypes.NEW_LISTING:
        return (
          <div className="icon-with-label">
            <AirplaneIcon title={status.title} />
            New Listing
          </div>
        );
      case this.actionTypes.PENDING_LISTING:
        return (
          <div className="icon-with-label">
            <AirplaneIcon title={status.title} />
            Pending Listing
          </div>
        );
      case this.actionTypes.PRICE_CHANGE:
        return (
          <div className="icon-with-label">
            <DollarIcon title={status.title} />
            Price Change
          </div>
        );
      case this.actionTypes.PROMOTION:
        return (
          <div className="icon-with-label">
            <PromotionIcon title={status.title} />
            New {object.type.title} Promotion
          </div>
        );
      case this.actionTypes.PUBLISHED:
        return (
          <div className="icon-with-label">
            <PublishedIcon title={status.title} />
            Listing Published
          </div>
        );

      case this.actionTypes.SOLD:
        return (
          <div className="icon-with-label">
            <DollarIcon title={status.title} />
            Listing Sold
          </div>
        );
      case this.actionTypes.UNPUBLISHED:
        return (
          <div className="icon-with-label">
            <UnpublishedIcon title={status.title} />
            Listing Removed
          </div>
        );
      case this.actionTypes.NEW_COMPANY:
        return (
          <div className="icon-with-label">
            <CompanyIcon title={status.title} />
            New Company
          </div>
        );
      case this.actionTypes.NEW_USER:
        return (
          <div className="icon-with-label">
            <UserIcon title={status.title} />
            New User
          </div>
        );
      case this.actionTypes.PENDING_USER:
        return (
          <div className="icon-with-label">
            <UserIcon title={status.title} />
            Pending User
          </div>
        );
      case this.actionTypes.UPDATED_SUBSCRIPTION:
      case this.actionTypes.UPDATED_PLAN:
        return (
          <div className="icon-with-label">
            <UserIcon title={status.title} />
            Updated Subscription
          </div>
        );
      case this.actionTypes.USER_PAYMENT:
      case this.actionTypes.COMPANY_PAYMENT:
        return (
          <div className="icon-with-label">
            <DollarIcon title={status.title} />
            Updated Payment Details
          </div>
        );
      default:
        return (
          <div className="icon-with-label">
            <em>Unknown Action</em>
          </div>
        );
    }
  }

  fetchResults() {
    let fetchUrl = this.parseRequestUrl();
    if (this.state.fetchUrl !== fetchUrl) {
      // Fetch URL has changed. Update state and get new results
      this.setState(
        {
          fetchUrl: fetchUrl,
          isLoading: true,
          results: [],
          error: undefined,
          count: 0,
          pages: 0,
        },
        () => {
          if (fetchUrl) {
            axios
              .get(fetchUrl)
              .then((response) => {
                const results = response.data.data.rows;
                let notifications = [];
                if (results) {
                  notifications = results.map((i, idx) => {
                    console.log(i);
                    const createdDate = i.createdAt
                      ? moment(i.createdAt).calendar()
                      : undefined;

                    const fromName = getName(i.from);

                    const payload = {
                      id: i.id || idx,
                      action: this.statusIcon(i.action, i.object),
                      from: fromName || i.object.name,
                      to: getName(i.to),
                      item: i.id,
                      createdAt: createdDate,
                      oAction: i.action,
                      oFrom: i.from,
                      oTo: i.to,
                      object: i.object,
                    };
                    return payload;
                  });
                }

                this.setState(
                  {
                    isLoading: false,
                    error: undefined,
                    results: notifications,
                    count: response.data.data.count,
                    pages: response.data.pageCount,
                  },
                  () => {
                    this.props.onFetchSuccess &&
                      this.props.onFetchSuccess(this.state);
                  }
                );
              })
              .catch((error) => {
                this.setState(
                  {
                    isLoading: false,
                    results: [],
                    error: error,
                    count: 0,
                    pages: 0,
                  },
                  () => {
                    this.props.onFetchError &&
                      this.props.onFetchError(this.state);
                  }
                );
              });
          }
        }
      );
    }
  }

  handlePageLimitChange = (event) => {
    const target = event.currentTarget;

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, show: target.value };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };
  getPageLimit = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const pageLimit = currentParams["show"] || 10;
    return parseInt(pageLimit);
  };
  handlePageChange = (pager) => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, page: pager.currentPage };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };
  getPage = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const page = currentParams["page"] || 1;
    return parseInt(page);
  };

  handleSearchChange = (e) => {
    this.setState({ search: e.target.value });
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, keyword: this.state.search };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };

  render() {
    const columns = [
      {
        dataField: "id",
        text: "ID",
        hidden: true,
      },
      {
        dataField: "to",
        text: "To",
        sort: true,
        //classes: "to",
        formatter: (cell, row, rowIndex) => {
          return (
            <div className="user-avatar-cell">
              <Avatar
                name={getName(row.oTo)}
                src={row.oTo.profile.profileImage}
                size={32}
              />
              {cell}
            </div>
          );
        },
      },
      {
        dataField: "from",
        text: "From",
        sort: true,
        //classes: "user-avatar-cell",
        formatter: (cell, row, rowIndex) => {
          return <div className="user-avatar-cell">{cell}</div>;
        },
      },
      {
        dataField: "action",
        text: "Action",
        sort: false,
        headerFormatter: (column, colIndex) => (
          <MultiSelectFilter column={column} items={this.state.filterActions} />
        ),
      },
      {
        dataField: "item",
        text: "Item",
        sort: false,
        //classes: "user-avatar-cell",
        formatter: (cell, row, rowIndex) => {
          return itemType(row);
        },
      },
      {
        dataField: "createdAt",
        text: "Timestamp",
        sort: true,
      },
    ];

    const itemType = (row) => {
      console.log(row);
      switch (row.oAction.value) {
        case this.actionTypes.FAVORITE:
        case this.actionTypes.PRICE_CHANGE:
        case this.actionTypes.PUBLISHED:
        case this.actionTypes.SOLD:
        case this.actionTypes.UNPUBLISHED:
          return <ListingLinkAndTitle listing={row.object} />;

        case this.actionTypes.NEW_LISTING:
        case this.actionTypes.PENDING_LISTING:
          return <ListingEditAndTitle listing={row.object} />;
        case this.actionTypes.PROMOTION:
          return <ListingLinkAndTitle listing={row.object.listing} />;
        case this.actionTypes.INQUIRY:
          console.log(row);
          return (
            <Link to={generatePath(Routes.inbox)}>
              {ListingTitle(row.object.listing)}
            </Link>
          );
        case this.actionTypes.NEW_COMPANY:
          return (
            <Link
              to={generatePath(Routes.companyEdit, {
                company: row.object.slug,
                tab: undefined,
              })}
            >
              {row.object.name}
            </Link>
          );
        case this.actionTypes.NEW_USER:
          return (
            <Link to={UserLink(row.object.email)}>{getName(row.object)}</Link>
          );
        case this.actionTypes.PENDING_USER:
          console.log(row);
          if (row.oTo.company)
            return (
              <Link
                to={generatePath(Routes.companyEdit, {
                  company: row.oTo.company.slug,
                  tab: "reps",
                })}
              >
                {getName(row.object)}
              </Link>
            );
          return <Link to={Routes.usersManage}>{getName(row.object)}</Link>;
        case this.actionTypes.UPDATED_SUBSCRIPTION:
          return (
            <Link to={UserLink(row.object.email, "plan")}>
              {row.object.plan.title}
            </Link>
          );
        case this.actionTypes.USER_PAYMENT:
          return (
            <Link to={UserLink(row.object.email, "payment")}>
              View Payment Information
            </Link>
          );
        case this.actionTypes.UPDATED_PLAN:
          return (
            <Link
              to={generatePath(Routes.companyEdit, {
                company: row.object.slug,
                tab: "plan",
              })}
            >
              {row.object.plan.title}
            </Link>
          );
        case this.actionTypes.COMPANY_PAYMENT:
          return (
            <Link
              to={generatePath(Routes.companyEdit, {
                company: row.object.slug,
                tab: "billing",
              })}
            >
              View Billing Information
            </Link>
          );
        default:
          return <span>{row.object.title || row.object.name}</span>;
      }
    };

    return (
      <section id="app-manage-table" className="app-admin-sales app-main">
        <Row className="d-flex justify-content-between m-b-15">
          <Col className="title">
            <span>
              All <strong>Notifications</strong>
            </span>
          </Col>
          <Col className="d-flex flex-column align-items-end"></Col>
        </Row>
        <div className="table-layout" id="table-sales">
          <header className="table-top table-header">
            <Row>
              <Col
                sm={6}
                className="table-header-actions table-actions justify-content-center justify-content-sm-start"
              ></Col>
              <Col
                sm={6}
                className="table-header-search table-search justify-content-center justify-content-sm-end"
              >
                <Form onSubmit={this.handleSearchSubmit}>
                  <Form.Control
                    as="input"
                    placeholder="Search"
                    onChange={this.handleSearchChange}
                    value={this.state.search}
                  />
                  <button type="submit" className="btn btn-icon">
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </Form>
              </Col>
            </Row>
          </header>
          <Table
            id="sales"
            keyField="id"
            columns={columns}
            data={this.state.results}
          />
          <footer className="table-bottom table-footer">
            <Row>
              <Col
                sm={6}
                className="table-footer-stats table-stats justify-content-center justify-content-sm-start"
              >
                <span className="row-count">
                  {this.state.count} total records.{" "}
                  {this.state.results && this.state.results.length} rows showing
                </span>
              </Col>
              <Col
                sm={6}
                className="table-footer-pagination table-pagination text-sm-right justify-content-center justify-content-sm-end"
              >
                <Pagination
                  totalRecords={this.state.count}
                  pageLimit={this.getPageLimit()}
                  pageNeighbours={1}
                  onPageChanged={this.handlePageChange}
                  currentPage={this.getPage()}
                />
              </Col>
            </Row>
          </footer>
        </div>
      </section>
    );
  }
}

const keyStateToProps = (state) => {
  return {
    toast: state.toast,
  };
};

export default connect(keyStateToProps)(NotificationPage);
