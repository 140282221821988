import React, { useState } from "react";

import DayPicker from "react-day-picker";
import moment from "moment";

import "react-day-picker/lib/style.css";

function getWeekDays(weekStart) {
  const days = [weekStart];
  for (let i = 1; i < 7; i += 1) {
    days.push(moment(weekStart).add(i, "days").toDate());
  }
  return days;
}

function getWeekRange(date) {
  return {
    from: moment(date).startOf("week").toDate(),
    to: moment(date).endOf("week").toDate(),
  };
}

const PromotionCalendar = (props) => {
  const { selectSingle, startDate } = props;

  let initialSelectedDays = [];
  if (startDate) {
    if (selectSingle) {
      initialSelectedDays = [moment(startDate).toDate()];
    } else {
      initialSelectedDays = getWeekDays(getWeekRange(startDate).from);
    }
  }

  const [hoverRange, setHoverRange] = useState(undefined);
  const [selectedDays, setSelectedDays] = useState(initialSelectedDays);

  const today = moment();
  const daysAreSelected = selectedDays.length > 0;
  const modifiers = {
    hoverRange,
    selectedRange: daysAreSelected && {
      from: selectedDays[0],
      to: selectedDays[6],
    },
    hoverRangeStart: hoverRange && hoverRange.from,
    hoverRangeEnd: hoverRange && hoverRange.to,
    selectedRangeStart: daysAreSelected && selectedDays[0],
    selectedRangeEnd: daysAreSelected && selectedDays[6],
  };

  return (
    <div className="promotion-calendar">
      <div className="promotion-daypicker">
        <div className="daypicker-head"></div>
        <DayPicker
          className="Range"
          fromMonth={today.endOf("week").toDate()}
          month={today.endOf("week").toDate()}
          showOutsideDays
          selectedDays={selectedDays}
          //disabledDays={disabledDays}
          disabledDays={{
            before: today.endOf("week").add(1, "days").toDate(),
          }}
          modifiers={modifiers}
          onDayClick={(day, modifiers = {}) => {
            if (modifiers.disabled) {
              return;
            }
            let selected;
            if (selectSingle) {
              selected = day;
              setSelectedDays(day);
            } else {
              selected = getWeekRange(day).from;
              setSelectedDays(getWeekDays(getWeekRange(day).from));
            }
            props.onChange && props.onChange(selected);
          }}
          onDayMouseEnter={(date) => {
            setHoverRange(getWeekRange(date));
          }}
          onDayMouseLeave={(date) => {
            setHoverRange(undefined);
          }}
          onWeekClick={(weekNumber, days, e) => {
            setSelectedDays(days);
          }}
          //onDayMouseEnter={(event)=>handleDayMouseEnter(event)}
        />
      </div>
    </div>
  );
};

export default PromotionCalendar;
