import React from "react";
import { connect } from "react-redux";

import { Formik } from "formik";
import Button from "react-bootstrap/Button";

import { openMessageModal, closeMessageModal } from "actions/modal/actions";
import { decrypt } from "utils/crypto";

const CreditCardView = (props) => {
  const { card, openMessageModal, closeMessageModal } = props;

  const requestKeyForm = () => {
    return (
      <Formik
        onSubmit={(values) => {
          decryptCard(values.privateKey);
        }}
        initialValues={{
          privateKey: "",
        }}
      >
        {({ handleSubmit, handleChange, values }) => (
          <form
            className="card-decryption-modal card-request-private-key"
            onSubmit={handleSubmit}
          >
            <h3>Provide Master Encryption Key</h3>
            <textarea
              name="privateKey"
              value={values.privateKey || ""}
              onChange={handleChange}
              className="d-block w-100"
            />
            <>
              <Button
                className="btn btn-white"
                onClick={() => {
                  closeMessageModal();
                }}
              >
                Cancel
              </Button>
              <Button className="btn btn-green" type="submit">
                Decrypt
              </Button>
            </>
          </form>
        )}
      </Formik>
    );
  };

  const requestKeyModal = () => {
    openMessageModal(
      () => {
        return requestKeyForm();
      },
      () => <></>
    );
  };

  const decryptCard = (privateKey) => {
    try {
      const unencrypted = decrypt(
        {
          iv: card.iv,
          key: card.key,
          data: card.card,
        },
        privateKey
      );

      openMessageModal(
        () => {
          return (
            <div className="card-decryption-modal card-details">
              <ul className="card-details-list">
                <li>
                  <label className="label" htmlFor="card-number">
                    Number:
                  </label>
                  <input
                    id="card-number"
                    name="card-number"
                    className="value"
                    readOnly
                    value={unencrypted.number}
                  />
                </li>
                <li>
                  <label className="label" htmlFor="card-cvv">
                    CVV:
                  </label>
                  <input
                    id="card-cvv"
                    name="card-cvv"
                    className="value"
                    readOnly
                    value={unencrypted.cvv}
                  />
                </li>
              </ul>
            </div>
          );
        },
        () => (
          <Button
            className="btn btn-white"
            onClick={() => {
              closeMessageModal();
            }}
          >
            Close
          </Button>
        )
      );
    } catch (ex) {
      openMessageModal("Unable to Decrypt!", () => (
        <Button
          className="btn btn-white"
          onClick={() => {
            closeMessageModal();
          }}
        >
          Close
        </Button>
      ));
    }
  };

  return (
    <Button onClick={requestKeyModal} className="btn btn-green">
      View Card
    </Button>
  );
};

const keyStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    openMessageModal: (message, actions) =>
      dispatch(openMessageModal({ message, actions })),
    closeMessageModal: () => dispatch(closeMessageModal()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(CreditCardView);
