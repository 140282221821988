import React from "react";
import { connect } from "react-redux";

import ProfileBlock from "./profileBlock";

function EditCompanyPrivateProfile(props) {
  const { profile, onProfileChange } = props;

  const handleProfileChange = (value) => {
    if (onProfileChange) onProfileChange(profile, value);
  };

  return (
    <ProfileBlock
      title="Private Information"
      profile={profile}
      onChange={handleProfileChange}
      before={() => {
        return <h3>Private Profile (Only Visible to Plane Mover staff)</h3>;
      }}
    />
  );
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {};
};

export default connect(
  keyStateToProps,
  keyDispatchToProps
)(EditCompanyPrivateProfile);
