import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { Form, Button, Row, Col } from "react-bootstrap";
import Spinner from "components/shared/spinner";

import { Formik } from "formik";
import { object, string, ref } from "yup";

import Routes from "routes";

const schema = object().shape({
  password: string()
    .required("Password is required")
    .min(8, "Password has to be longer than 8 characters")
    .matches(/[a-z]/, "at least one lowercase char")
    .matches(/[A-Z]/, "at least one uppercase char")
    .matches(/[^a-zA-Z\s]/, "at least 1 number or special char (@,!,#, etc)."),
  confirmPassword: string()
    .required("Confirm Password is required")
    .when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: string().oneOf(
        [ref("password")],
        "Both password need to be the same"
      ),
    }),
});

class PasswordForm extends Component {
  async handleSubmit(password, setSubmitting) {
    const userInfo = {
      password: password,
    };
    this.props.changeUserInfo(userInfo);
    this.props.submitUser().finally((res) => {
      setSubmitting(false);
    });
  }

  render() {
    const { userInfo } = this.props;
    return (
      <Formik
        validationSchema={schema}
        onSubmit={({ password }, { setSubmitting }) =>
          this.handleSubmit(password, setSubmitting)
        }
        initialValues={{
          password: userInfo.password,
          confirmPassword: userInfo.password,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          isSubmitting,
        }) => (
          <Form className="account-form" onSubmit={handleSubmit}>
            {isSubmitting && <Spinner />}
            <Form.Group controlId="password">
              <Form.Label className="account-form-label">Password</Form.Label>
              <Form.Control
                className="account-form-input"
                type="password"
                placeholder="Create password"
                name="password"
                value={values.password}
                onChange={handleChange}
                isInvalid={touched.password && !!errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group controlId="confirmPassword">
              <Form.Label className="account-form-label">
                Confirm Password
              </Form.Label>
              <Form.Control
                className="account-form-input"
                type="password"
                placeholder="Repeat password"
                name="confirmPassword"
                value={values.confirmPassword}
                onChange={handleChange}
                isInvalid={touched.confirmPassword && !!errors.confirmPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Row>
              <Col xs={12} className="d-flex justify-content-center mb-4">
                <div className="account-form-uppercase">
                  Must be at least 8 characters and contain at least one
                  uppercase letter, number, and special character
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} className="d-flex justify-content-center mb-4">
                <Button className="btn-green" type="submit">
                  Create my account
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={12} className="d-flex justify-content-center">
                <div className="account-form-uppercase">
                  <span>By clicking above you agree to our </span>
                  <Link
                    to={Routes.terms}
                    className="account-modal-link"
                    target="_blank"
                  >
                    Terms
                  </Link>
                  <span> and </span>
                  <Link
                    to={Routes.privacy}
                    className="account-modal-link"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>

                  <span>.</span>
                </div>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

PasswordForm.propTypes = {
  userInfo: PropTypes.shape({
    password: PropTypes.string.isRequired,
  }),
  changeUserInfo: PropTypes.func.isRequired,
  submitUser: PropTypes.func.isRequired,
};

export default PasswordForm;
