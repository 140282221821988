import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, generatePath } from "react-router-dom";
import _ from "lodash";

import Routes from "routes";
import { getDashboard } from "actions/dashboard/actions";

const SubscriptionInfo = (props) => {
  const { user, dashboard, getDashboard } = props;

  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  // const subscriptionRenewDate = (date) => {
  //   if (!date) return `Never Expire`;
  // };

  if (!_.isEmpty(dashboard) && user && user.role && user.role.value) {
    switch (user.role.value) {
      case "super-admin":
        return (
          <div className="d-flex justify-content-between bottom-content">
            <p>
              You're a <strong>{user.role.title}</strong>.
            </p>
          </div>
        );
      case "company-admin":
        return (
          <div className="d-flex justify-content-between bottom-content">
            <div>
              <p>
                SUBSCRIPTION: <strong>{user.company.plan.title}</strong>
              </p>
              <p>
                TOTAL ACTIVE LISTINGS:{" "}
                <strong>{dashboard.listings.live.total}</strong> of{" "}
                <strong>{user.company.plan.premiumListings}</strong>{" "}
              </p>
              <p>
                <Link to={generatePath(Routes.forSaleManage)}>
                  Aircraft for Sale:{" "}
                  <strong>{dashboard.listings.sales.total}</strong>
                </Link>
                ,{" "}
                <Link to={generatePath(Routes.wantedManage)}>
                  Aircraft Wanted:{" "}
                  <strong>{dashboard.listings.wanted.total}</strong>
                </Link>
                ,{" "}
                <Link to={generatePath(Routes.servicesManage)}>
                  Services: <strong>{dashboard.listings.service.total}</strong>
                </Link>
              </p>
            </div>
            <div>
              <Link
                className="btn btn-green"
                to={generatePath(Routes.companyEdit, {
                  company: user.company.slug,
                  tab: "plan",
                })}
              >
                UPGRADE
              </Link>
            </div>
          </div>
        );
      case "sales-rep":
      case "sales-rep-view":
        return (
          <div className="d-flex justify-content-between bottom-content">
            <div>
              <p>
                COMPANY SUBSCRIPTION: <strong>{user.company.plan.title}</strong>
              </p>
              <p>
                COMPANY TOTAL ACTIVE LISTINGS:{" "}
                <strong>{dashboard.companyListings.live.total}</strong> of{" "}
                <strong>{user.company.plan.premiumListings}</strong>{" "}
              </p>
              <p>
                <Link to={generatePath(Routes.forSaleManage)}>
                  Aircraft for Sale:{" "}
                  <strong>{dashboard.companyListings.sales.total}</strong>
                </Link>
                ,{" "}
                <Link to={generatePath(Routes.wantedManage)}>
                  Aircraft Wanted:{" "}
                  <strong>{dashboard.companyListings.wanted.total}</strong>
                </Link>
                ,{" "}
                <Link to={generatePath(Routes.servicesManage)}>
                  Services:{" "}
                  <strong>{dashboard.companyListings.service.total}</strong>
                </Link>
              </p>
            </div>
          </div>
        );
      case "advertiser":
      default:
        return (
          <div className="d-flex justify-content-between bottom-content">
            <div>
              <p>
                SUBSCRIPTION: <strong>{user.plan.title}</strong>
              </p>
              <p>
                TOTAL ACTIVE LISTINGS:{" "}
                <strong>{dashboard.listings.live.total}</strong> of{" "}
                <strong>{user.plan.premiumListings}</strong>{" "}
              </p>
              <p>
                <Link to={generatePath(Routes.forSaleManage)}>
                  Aircraft for Sale:{" "}
                  <strong>{dashboard.listings.sales.total}</strong>
                </Link>
                ,{" "}
                <Link to={generatePath(Routes.wantedManage)}>
                  Aircraft Wanted:{" "}
                  <strong>{dashboard.listings.wanted.total}</strong>
                </Link>
                ,{" "}
                <Link to={generatePath(Routes.servicesManage)}>
                  Services: <strong>{dashboard.listings.service.total}</strong>
                </Link>
              </p>
            </div>
            <div>
              <Link
                className="btn btn-green"
                to={generatePath(Routes.accountSettings, {
                  email: user.email,
                  tab: "plan",
                })}
              >
                UPGRADE
              </Link>
            </div>
          </div>
        );
    }
  }
  return "---";
};

const keyStateToProps = (state) => {
  return {
    user: state.user,
    dashboard: state.dashboard,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    getDashboard: () => dispatch(getDashboard()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(SubscriptionInfo);
