import React, { Component } from "react";
import axios from "axios";

import { Row, Col } from "react-bootstrap";
import Spinner from "components/shared/spinner";

import Pill from "components/backend/shared/pills";

class SaleHighlights extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: "sale",
      isLoading: true,
      results: [],
      error: undefined,
      isAdding: false,
    };
  }

  componentDidMount() {
    axios.get(`/highlights/type/${this.state.type}`).then((response) => {
      this.setState({
        isLoading: false,
        error: undefined,
        results: response.data.data.rows,
      });
    });
  }

  content() {
    if (this.state.error) {
      // Error
      return (
        <div className="sale-highlights sale-highlights-error error">
          <h1 className="favorite-title">{this.state.error.message}</h1>
          <p>{this.ErrorMessage(this.state.error)}</p>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div className="form-loading edit-loading loading">
          <Spinner />
        </div>
      );
    } else if (this.state.results) {
      return <this.EditContent />;
    }
  }

  //   getSettingValue = section => {
  //     const thisSetting = this.state.results
  //       .filter(s => s.section === section)
  //       .shift();
  //     if (thisSetting) return thisSetting.content;
  //     return;
  //   };

  //   setSettingValue = (section, content) => {
  //     let updatedSettings = this.state.results;
  //     let previousResults = this.state.results;
  //     let updatedSetting;
  //     const thisSetting = this.state.results
  //       .filter(s => s.section === section)
  //       .shift();
  //     if (thisSetting) {
  //       // Update Setting
  //       updatedSettings = this.state.results.map((s, i) => {
  //         if (s.section === section) {
  //           // Update
  //           updatedSetting = s;
  //           updatedSetting.content = content;
  //           return updatedSetting;
  //         }
  //         return s;
  //       });
  //     } else {
  //       // Add New Setting
  //       updatedSetting = {
  //         group: this.state.group,
  //         section,
  //         title: null,
  //         content
  //       };
  //       updatedSettings.push(updatedSetting);
  //     }

  //     this.setState({ results: updatedSettings }, () => {
  //       axios
  //         .put(`/settings/${this.state.group}/${section}`, updatedSetting)
  //         .catch(error => {
  //           this.setState({ results: previousResults });
  //         });
  //     });
  //   };

  //   handleTextEditorChange = (name, value) => {
  //     let cleanedValue = stripHTML(value).trim();
  //     if (!cleanedValue) {
  //       value = null;
  //     }

  //     this.setSettingValue(name, value);
  //   };

  addHighlight = (title) => {
    this.setState({ isAdding: true }, () => {
      return axios
        .post(`/highlights/type/${this.state.type}`, {
          name: title,
        })
        .then((response) => {
          const updatedResults = this.state.results.slice();
          updatedResults.push(response.data.data);
          this.setState({ results: updatedResults, isAdding: false });
        });
    });
  };

  removeHighlight = (value) => {
    const previousState = this.state.results.slice();
    const cleanedResults = this.state.results.filter((h) => h.value !== value);
    this.setState({ results: cleanedResults }, () => {
      axios
        .delete(`/highlights/type/${this.state.type}/${value}`)
        .catch((err) => {
          this.setState({ results: previousState });
        });
    });
  };

  EditContent = () => {
    const { results } = this.state;
    return (
      <section className="sale-highlights">
        <Row>
          <Col>
            <div className="site-settings__subtitle mb">
              Edit Aircraft for Sale Highlights here.
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            {results
              .sort((a, b) => (a.value > b.value ? 1 : -1))
              .map((h) => (
                <Pill
                  key={`pill-${h.value}`}
                  type="delete"
                  value={h.value}
                  title={h.name}
                  onRemove={(value) => this.removeHighlight(value)}
                />
              ))}
          </Col>
        </Row>
        <Row>
          <Col>
            <Pill
              type="add"
              placeholder="ADD HIGHLIGHT"
              onAdd={(title) => this.addHighlight(title)}
            />
            {this.state.isAdding && (
              <Spinner animation="border" role="status">
                <span className="sr-only">Adding...</span>
              </Spinner>
            )}
          </Col>
        </Row>
      </section>
    );
  };

  render() {
    return (
      <section
        id="sale-highlights-settings"
        className="sale-highlights-settings"
      >
        {this.content()}
      </section>
    );
  }
}

export default SaleHighlights;
