import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import CookieSettingPopup from "components/backend/promotions/shared/cookieSettingsPopup";

const Cookies = () => {
  const [showCookieSettingPopup, setShowCookieSettingPopup] = useState(false);
  const [showNotice, setShowNotice] = useState();

  const getCookieSettings = () => {
    const cookieString = document.cookie
      .split('; ')
      .find(row => row.startsWith('cookieSettings='));
    return cookieString ? JSON.parse(decodeURIComponent(cookieString.split('=')[1])) : null;
  };

  useEffect(() => {
    const cookieSettings = getCookieSettings();
    if (cookieSettings && cookieSettings.necessaryCookies) {
    } else {
      setShowNotice(true);
    }
  }, []);

  const handleAcceptAllCookie = () => {
    const settings = {
      analyticsCookies: true,
      advertisingCookies: true,
      necessaryCookies: true
    };
    document.cookie = `cookieSettings=${encodeURIComponent(JSON.stringify(settings))}; path=/; expires=${new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString()}`;
    setShowNotice(false);
  };
  const handleRejectAllCookie = () => {
    const settings = {
      analyticsCookies: false,
      advertisingCookies: false,
      necessaryCookies: true
    };
    document.cookie = `cookieSettings=${encodeURIComponent(JSON.stringify(settings))}; path=/; expires=${new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString()}`;
    setShowNotice(false);
  }
  const handleOpenCookieSettingPopup = () => {
    setShowCookieSettingPopup(true);
  };

  const handleCloseCookieSettingPopup = () => {
    setShowCookieSettingPopup(false);
  };
  const afterSettingsSaved = () => {
    setShowNotice(false); // Hide the cookie notice
    setShowCookieSettingPopup(false); // Close the popup
  };
  if (showNotice) {
    return (
      <>
        <aside id="cookie-notice">
          <div className="cookie-container">
            <Row className="py-4">
            <Col xs={12} md={6}>
                We use cookies and other tracking technologies to improve your
                browsing experience on our website, to show you personalized
                content and targeted ads, to analyze our website traffic, and to
                understand where our visitors are coming from.
              </Col>
              {/*  */}
              <Col  xs={12} md={6}>
                <div className="cookie-buttons">
                <Button className="text-nowrap  cookie-btn" onClick={handleOpenCookieSettingPopup} >
                  Cookies Setting
                </Button>
                <Button className="text-nowrap cookie-btn" onClick={handleRejectAllCookie}>
                  Reject All Cookies
                </Button>
                <Button className="text-nowrap cookie-btn" onClick={handleAcceptAllCookie}>
                  Accept All Cookies
                </Button>
                </div>
              </Col>
              
            </Row>
          </div>
        </aside>
        {showCookieSettingPopup && (
          <CookieSettingPopup
            show={showCookieSettingPopup}
            onClose={handleCloseCookieSettingPopup}
            afterSave={afterSettingsSaved}
          />
        )}
      </>
    );
  }
  return null;
};
export default Cookies;
