/* eslint-disable no-fallthrough */
import React from "react";
import axios from "axios";
import queryString from "query-string";
import moment from "moment";

import Routes, { createTitle } from "routes";

import { openMessageModal, closeMessageModal } from "actions/modal/actions";
import Table from "components/shared/table";
import MultiSelectFilter from "components/shared/multiSelectFilter";
import Avatar from "components/shared/avatar";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link, generatePath } from "react-router-dom";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import Pagination from "components/frontend/listings/_shared/pagination";

import { ReactComponent as PendingIcon } from "images/icons/eye.svg";
import { ReactComponent as ActiveIcon } from "images/icons/check.svg";
import { ReactComponent as InactiveIcon } from "images/icons/archive.svg";

class UsersManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      fetchUrl: false,
      isLoading: true,
      results: [],
      error: undefined,
      count: 0,
      pages: 0,
      selected: [],
      filterCompanies: [],
      filterUsers: [],
      filterRoles: [],
      filterStatuses: [],
    };

    this.fetchUrl = `/accounts`;
  }

  componentDidMount() {
    document.title = createTitle("Active Users", Routes);

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    this.setState({ search: currentParams["keyword"] || "" });

    axios.get("/masterData?keys=accountRole,accountStatus").then((response) => {
      const masterData = response.data.rows;

      const filterRoles = masterData
        .filter((data) => data.key === "accountRole")
        .map((data) => {
          return {
            value: data.value,
            title: data.title,
          };
        });

      const filterStatuses = masterData
        .filter((data) => data.key === "accountStatus")
        .map((data) => {
          return {
            value: data.value,
            title: data.title,
          };
        });

      this.setState({ filterRoles, filterStatuses });
    });

    axios.get("/company?limit=0").then((response) => {
      const filterCompanies = response.data.data.rows.map((company) => {
        return {
          value: company.slug,
          title: company.name,
        };
      });
      this.setState({ filterCompanies });
    });

    this.fetchResults();
  }

  componentDidUpdate() {
    this.fetchResults();
  }

  getSearchParams() {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    const newParams = {};

    // Limit
    if (currentParams["show"]) {
      newParams["limit"] = currentParams["show"] || 10;
    }

    return { ...currentParams, ...newParams };
  }

  parseRequestUrl() {
    const fetchUrl = this.fetchUrl;
    const searchParams = this.getSearchParams();
    // Add Params
    if (searchParams) {
      // We have search params. Parse them!
      const searchParamsUrl = queryString.stringify(searchParams, {
        arrayFormat: "comma",
      });
      return `${fetchUrl}?${searchParamsUrl}`;
    }

    // Return URL
    return fetchUrl;
  }

  statusIcon(status) {
    switch (status.value) {
      case "inactive":
        return <InactiveIcon title={status.title} />;
      case "pending":
        return <PendingIcon title={status.title} />;
      case "active":
      default:
        return <ActiveIcon title={status.title} />;
    }
  }

  fetchResults(reload) {
    let fetchUrl = this.parseRequestUrl();
    if (this.state.fetchUrl !== fetchUrl || reload) {
      // Fetch URL has changed. Update state and get new results
      this.setState(
        {
          fetchUrl: fetchUrl,
          isLoading: true,
          results: [],
          error: undefined,
          count: 0,
          pages: 0,
        },
        () => {
          if (fetchUrl) {
            axios
              .get(fetchUrl)
              .then((response) => {
                const results = response.data.data.rows;
                let accounts = [];
                if (results) {
                  accounts = results.map((i) => {
                    const createdDate = i.createdAt
                      ? moment(i.createdAt).calendar()
                      : undefined;
                    const updatedDate = i.updatedAt
                      ? moment(i.updatedAt).calendar()
                      : undefined;

                    return {
                      name: i.profile.name,
                      status: this.statusIcon(i.status),
                      email: i.email,
                      company:
                        i.company && i.company.name ? i.company.name : "",
                      role: i.role.title,
                      saleListings: i.saleListingsCount,
                      wantedListings: i.wantedListingsCount,
                      serviceListings: i.serviceListingsCount,
                      totalListings: i.totalListingsCount,
                      createdAt: createdDate,
                      updatedAt: updatedDate,
                      oStatus: i.status,
                      oRole: i.role,
                      oCompany: i.company,
                      oProfile: i.profile,
                    };
                  });
                }

                this.setState(
                  {
                    isLoading: false,
                    error: undefined,
                    results: accounts,
                    count: response.data.data.count,
                    pages: response.data.pageCount,
                  },
                  () => {
                    this.props.onFetchSuccess &&
                      this.props.onFetchSuccess(this.state);
                  }
                );
              })
              .catch((error) => {
                this.setState(
                  {
                    isLoading: false,
                    results: [],
                    error: error,
                    count: 0,
                    pages: 0,
                  },
                  () => {
                    this.props.onFetchError &&
                      this.props.onFetchError(this.state);
                  }
                );
              });
          }
        }
      );
    }
  }

  handlePageLimitChange = (event) => {
    const target = event.currentTarget;

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, show: target.value };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };
  getPageLimit = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const pageLimit = currentParams["show"] || 10;
    return parseInt(pageLimit);
  };
  handlePageChange = (pager) => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, page: pager.currentPage };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };
  getPage = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const page = currentParams["page"] || 1;
    return parseInt(page);
  };

  handleSearchChange = (e) => {
    this.setState({ search: e.target.value });
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, keyword: this.state.search };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };

  pageActions(status) {
    return (
      <Link
        className="btn btn-green m-b-15"
        to={generatePath(Routes.forSaleEdit, {
          listing: null,
          step: null,
        })}
      >
        + New
      </Link>
    );
  }

  handleUserDelete = (name, email, totalListings) => {
    const { openMessageModal, closeMessageModal } = this.props;
    openMessageModal(
      `You are about to delete '${name}' from the database. This user has ${totalListings} total listings (including active & archived). If you continue, all listings will be deleted from the database.`,
      () => (
        <>
          <Button
            className="btn btn-white"
            onClick={() => {
              closeMessageModal();
              this.doUserDelete(email);
            }}
          >
            Confirm Deletion
          </Button>
          <Button
            className="btn btn-white"
            onClick={() => {
              closeMessageModal();
            }}
          >
            Cancel
          </Button>
        </>
      )
    );
  };

  doUserDelete = (email) => {
    // Delete the User
    this.setState({ isLoading: true }, () => {
      axios
        .delete(`/accounts/${email}`)
        .then(() => {
          this.fetchResults(true);
        })
        .catch((error) => {
          this.setState(
            {
              isLoading: false,
              results: [],
              error: error,
              count: 0,
              pages: 0,
            },
            () => {
              this.props.onFetchError && this.props.onFetchError(this.state);
            }
          );
        });
    });
  };

  render() {
    const columns = [
      {
        dataField: "status",
        text: "",
        sort: false,
        headerFormatter: (column, colIndex) => (
          <MultiSelectFilter
            column={column}
            items={this.state.filterStatuses}
          />
        ),
      },
      {
        dataField: "name",
        text: "User",
        sort: true,
        classes: "user-avatar-cell",
        formatter: (cell, row, rowIndex) => {
          return (
            <>
              <Avatar
                name={row.oProfile.name}
                src={row.oProfile.profileImage}
                size={32}
              />
              {cell}
            </>
          );
        },
      },
      {
        dataField: "email",
        text: "Email",
        sort: true,
      },
      {
        dataField: "company",
        text: "Company",
        sort: true,
        headerFormatter: (column, colIndex) => (
          <MultiSelectFilter
            column={column}
            items={this.state.filterCompanies}
          />
        ),
      },
      {
        dataField: "role",
        text: "Role",
        sort: true,
        headerFormatter: (column, colIndex) => (
          <MultiSelectFilter column={column} items={this.state.filterRoles} />
        ),
      },
      {
        dataField: "saleListings",
        text: "ACFS",
        sort: false,
      },
      {
        dataField: "wantedListings",
        text: "ACW",
        sort: false,
      },
      {
        dataField: "serviceListings",
        text: "SVC",
        sort: false,
      },
      {
        dataField: "totalListings",
        text: "Total",
        sort: false,
      },
      {
        dataField: "createdAt",
        text: "Created",
        sort: true,
      },
      {
        dataField: "updatedAt",
        text: "Updated",
        sort: true,
      },
    ];

    return (
      <section id="app-manage-table" className="app-admin-sales app-main">
        <Row className="d-flex justify-content-between m-b-15">
          <Col className="title">
            <span>
              All <strong>Users</strong>
            </span>
          </Col>
          <Col className="d-flex flex-column align-items-end">
            <Link to={Routes.usersAdd} className="btn btn-primary btn-red">
              + NEW
            </Link>
          </Col>
        </Row>
        <div className="table-layout" id="table-sales">
          <header className="table-top table-header">
            <Row>
              <Col
                sm={6}
                className="table-header-actions table-actions justify-content-center justify-content-sm-start"
              ></Col>
              <Col
                sm={6}
                className="table-header-search table-search justify-content-center justify-content-sm-end"
              >
                <Form onSubmit={this.handleSearchSubmit}>
                  <Form.Control
                    as="input"
                    placeholder="Search"
                    onChange={this.handleSearchChange}
                    value={this.state.search}
                  />
                  <button type="submit" className="btn btn-icon">
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </Form>
              </Col>
            </Row>
          </header>
          <Table
            id="sales"
            keyField="email"
            columns={columns}
            data={this.state.results}
            expandRow={{
              onlyOneExpanding: true,
              renderer: (row) => {
                return (
                  <div className="row-actions">
                    <Link
                      to={generatePath(Routes.accountSettings, {
                        email: row.email,
                        tab: undefined,
                      })}
                      className="btn btn-green"
                    >
                      Edit
                    </Link>
                    <Button
                      className="btn btn-red"
                      onClick={() => {
                        console.log(`Deleting User: ${row.email}`);
                        this.handleUserDelete(
                          row.name,
                          row.email,
                          row.totalListings
                        );
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                );
              },
            }}
          />
          <footer className="table-bottom table-footer">
            <Row>
              <Col
                sm={6}
                className="table-footer-stats table-stats justify-content-center justify-content-sm-start"
              >
                <span className="row-count">
                  {this.state.count} total records. {this.state.results.length}{" "}
                  rows showing ({this.state.selected.length} selected)
                </span>
              </Col>
              <Col
                sm={6}
                className="table-footer-pagination table-pagination text-sm-right justify-content-center justify-content-sm-end"
              >
                <Pagination
                  totalRecords={this.state.count}
                  pageLimit={this.getPageLimit()}
                  pageNeighbours={1}
                  onPageChanged={this.handlePageChange}
                  currentPage={this.getPage()}
                />
              </Col>
            </Row>
          </footer>
        </div>
      </section>
    );
  }
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    openMessageModal: (message, actions) =>
      dispatch(openMessageModal({ message, actions })),
    closeMessageModal: () => dispatch(closeMessageModal()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(UsersManage);
