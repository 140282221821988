import React, { useState, useEffect, useMemo } from "react";
import {
  CSSTransition,
  TransitionGroup,
  SwitchTransition,
} from "react-transition-group";
import "./styles.scss";

import VisaLogo from "images/cards/visa.png";
import MasterCardLogo from "images/cards/mastercard.png";
import DiscoverLogo from "images/cards/discover.png";
import AmexLogo from "images/cards/amex.png";
import ChipImg from "images/cards/chip.png";

import CardFrontImg from "images/cards/card-front.jpg";
import CardBackImg from "images/cards/card-back.jpg";

import cardType from "utils/cards";
import formatCardNumber from "../formatter";

//const CardFrontImg = cardBackgroundName();

const Card = (props) => {
  const {
    values,
    isCardFlipped,
    currentFocusedElm,
    onCardElementClick,
    cardNumberRef,
    cardHolderRef,
    cardDateRef,
  } = props;

  const cardHolder = values.cardHolder || "";
  const cardNumber = values.cardNumber
    ? formatCardNumber(values.cardNumber)
    : "";
  const cardMonth = values.cardMonth || "";
  const cardYear = values.cardYear || "";
  const cardCvv = values.cardCvv || "";
  const [style, setStyle] = useState(null);

  const useCardType = useMemo(() => {
    return cardType(cardNumber);
  }, [cardNumber]);

  const outlineElementStyle = (element) => {
    return element
      ? {
          width: `${element.offsetWidth}px`,
          height: `${element.offsetHeight}px`,
          transform: `translateX(${element.offsetLeft}px) translateY(${element.offsetTop}px)`,
        }
      : null;
  };

  const cardLogo = (type) => {
    switch (type) {
      case "amex":
        return (
          <img
            src={AmexLogo}
            alt="American Express"
            className="card-item__typeImg"
          />
        );
      case "mastercard":
        return (
          <img
            src={MasterCardLogo}
            alt="MasterCard"
            className="card-item__typeImg"
          />
        );
      case "discover":
        return (
          <img
            src={DiscoverLogo}
            alt="Discover"
            className="card-item__typeImg"
          />
        );
      case "visa":
      default:
        return <img src={VisaLogo} alt="Visa" className="card-item__typeImg" />;
    }
  };

  useEffect(() => {
    if (currentFocusedElm) {
      const style = outlineElementStyle(currentFocusedElm.current);
      setStyle(style);
    }
  }, [currentFocusedElm]);

  const maskCardNumber = (cardNumber) => {
    if (cardNumber) {
      let cardNumberArr = cardNumber.split("");
      cardNumberArr.forEach((val, index) => {
        if (index < 14) {
          if (cardNumberArr[index] !== " ") {
            cardNumberArr[index] = "*";
          }
        }
      });

      return cardNumberArr;
    }
    return [];
  };

  return (
    <div
      className={"card-item " + (isCardFlipped ? "-active" : "")}
      style={props.style ? props.style : {}}
    >
      <div className="card-item__side -front">
        <div
          className={`card-item__focus ${currentFocusedElm ? `-active` : ``}`}
          style={style}
        />
        <div className="card-item__cover card-item__front">
          <img alt="" src={CardFrontImg} className="card-item__bg" />
        </div>

        <div className="card-item__wrapper">
          <div className="card-item__top">
            <img src={ChipImg} alt="" className="card-item__chip" />
            <div className="card-item__type">{cardLogo(useCardType)}</div>
          </div>

          <label
            className="card-item__number"
            ref={cardNumberRef}
            onClick={() =>
              onCardElementClick && onCardElementClick("cardNumber")
            }
          >
            <TransitionGroup className="slide-fade-up" component="div">
              {cardNumber ? (
                maskCardNumber(cardNumber).map((val, index) => (
                  <CSSTransition
                    classNames="slide-fade-up"
                    timeout={250}
                    key={index}
                  >
                    <div className="card-item__numberItem">{val}</div>
                  </CSSTransition>
                ))
              ) : (
                <CSSTransition classNames="slide-fade-up" timeout={250}>
                  <div className="card-item__numberItem card-item__numberDefault">
                    #### #### #### ####
                  </div>
                </CSSTransition>
              )}
            </TransitionGroup>
          </label>
          <div className="card-item__content">
            <label
              className="card-item__info"
              onClick={() =>
                onCardElementClick && onCardElementClick("cardHolder")
              }
              ref={cardHolderRef}
            >
              <div className="card-item__holder">Card Holder</div>
              <div className="card-item__name">
                <TransitionGroup component="div" className="slide-fade-up">
                  {cardHolder === "FULL NAME" ? (
                    <CSSTransition classNames="slide-fade-up" timeout={250}>
                      <div>FULL NAME</div>
                    </CSSTransition>
                  ) : (
                    cardHolder.split("").map((val, index) => (
                      <CSSTransition
                        timeout={250}
                        classNames="zoom-in-out"
                        key={index}
                      >
                        <span className="card-item__nameItem">{val}</span>
                      </CSSTransition>
                    ))
                  )}
                </TransitionGroup>
              </div>
            </label>
            <div
              className="card-item__date"
              onClick={() =>
                onCardElementClick && onCardElementClick("cardDate")
              }
              ref={cardDateRef}
            >
              <label className="card-item__dateTitle">Expires</label>
              <label className="card-item__dateItem">
                <SwitchTransition in-out>
                  <CSSTransition
                    classNames="slide-fade-up"
                    timeout={200}
                    key={cardMonth}
                  >
                    <span>{!cardMonth ? "MM" : cardMonth} </span>
                  </CSSTransition>
                </SwitchTransition>
              </label>
              /
              <label htmlFor="cardYear" className="card-item__dateItem">
                <SwitchTransition out-in>
                  <CSSTransition
                    classNames="slide-fade-up"
                    timeout={250}
                    key={cardYear}
                  >
                    <span>
                      {!cardYear ? "YY" : cardYear.toString().substr(-2)}
                    </span>
                  </CSSTransition>
                </SwitchTransition>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="card-item__side -back">
        <div className="card-item__cover card-item__back">
          <img alt="" src={CardBackImg} className="card-item__bg" />
        </div>
        <div className="card-item__band" />
        <div className="card-item__cvv">
          <div className="card-item__cvvTitle">CVV</div>
          <div className="card-item__cvvBand">
            <TransitionGroup>
              {cardCvv.split("").map((val, index) => (
                <CSSTransition
                  classNames="zoom-in-out"
                  key={index}
                  timeout={250}
                >
                  <span>{val}</span>
                </CSSTransition>
              ))}
            </TransitionGroup>
          </div>
          <div className="card-item__type">{cardLogo(useCardType)}</div>
        </div>
      </div>
    </div>
  );
};

export default Card;
