import React from "react";
import axios from "axios";
import queryString from "query-string";
import classNames from "classnames";
import { withRouter } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "components/shared/spinner";

import FilterDropdown from "components/frontend/listings/_shared/filterDropdown";
import Pagination from "components/frontend/listings/_shared/pagination";

import { ReactComponent as FiltersIcon } from "images/icons/filters.svg";

class Listings extends React.Component {
  constructor(props) {
    super(props);

    this.noResultsMessage = this.props.noResultsMessage || "No Results Found";
    this.resultCountMessage =
      this.props.resultCountMessage || "#### Results Found";

    this.state = {
      fetchUrl: false,
      isLoading: true,
      results: undefined,
      count: 0,
      pages: 0,
      error: undefined,
    };

    this.sorting = [
      { sort: null, order: null, label: "Best Match" },
      { sort: "price", order: "DESC", label: "Price: Highest" },
      { sort: "price", order: "ASC", label: "Price: Lowest" },
      { sort: "serial", order: "ASC", label: "SN#: Low to High" },
      { sort: "serial", order: "DESC", label: "SN#: High to Low" },
      { sort: "total_time", order: "DESC", label: "Total Time: Highest" },
      { sort: "total_time", order: "ASC", label: "Total Time: Lowest" },
      { sort: "year", order: "DESC", label: "Year: Newest" },
      { sort: "year", order: "ASC", label: "Year: Oldest" },
      { sort: "created", order: "DESC", label: "Date Listed: Newest" },
      { sort: "created", order: "ASC", label: "Date Listed: Oldest" },
    ];

    this.paging = [
      { count: 10, label: "10 per page" },
      { count: 25, label: "25 per page" },
      { count: 50, label: "50 per page" },
      { count: 100, label: "100 per page" },
    ];

    // Fetch
    this.parseRequestUrl = this.parseRequestUrl.bind(this);
    this.ErrorMessage = this.ErrorMessage.bind(this);
    this.fetchResults = this.fetchResults.bind(this);

    // Order
    this.handleOrderByChange = this.handleOrderByChange.bind(this);
    this.getOrderBy = this.getOrderBy.bind(this);
    this.OrderDropdown = this.OrderDropdown.bind(this);

    // Pagination
    this.handlePageLimitChange = this.handlePageLimitChange.bind(this);
    this.getPageLimit = this.getPageLimit.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.getPage = this.getPage.bind(this);
    this.toggleMobileFilters = this.toggleMobileFilters.bind(this);
  }

  componentDidMount() {
    this.fetchResults();
  }

  componentDidUpdate() {
    this.fetchResults();
  }

  handleOrderByChange(event) {
    const target = event.currentTarget;

    const sort = this.sorting[target.value];

    if (sort) {
      const currentParams = queryString.parse(this.props.location.search, {
        arrayFormat: "comma",
      });
      const updatedObj = {
        ...currentParams,
        sort: sort.sort,
        order: sort.order,
      };
      const updatedParams = queryString.stringify(updatedObj, {
        arrayFormat: "comma",
      });

      this.props.history.push({
        pathname: this.props.location.pathname,
        search: "?" + new URLSearchParams(updatedParams).toString(),
      });
    }
  }

  getOrderBy() {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    if (currentParams["sort"] && currentParams["order"]) {
      for (const [i, v] of this.sorting.entries()) {
        if (
          v.sort === currentParams["sort"] &&
          v.order === currentParams["order"]
        ) {
          return i;
        }
      }
    }
    return 0;
  }

  handlePageLimitChange(event) {
    const target = event.currentTarget;

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, show: target.value, page: 1 };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  }
  getPageLimit() {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const pageLimit = currentParams["show"] || 10;
    return parseInt(pageLimit);
  }

  handlePageChange(pager) {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, page: pager.currentPage };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  }
  getPage() {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const page = currentParams["page"] || 1;
    return parseInt(page);
  }

  parseRequestUrl() {
    // Set the request params for the Listings component
    if (!this.props.url) {
      this.setState({
        isLoading: false,
        results: undefined,
        error: "A 'url' prop is required to fetch data.",
        count: 0,
        pages: 0,
      });
      return false;
    }

    let fetchUrl = this.props.url;
    // Add Params
    if (this.props.params) {
      // We have search params. Parse them!
      fetchUrl +=
        "?" +
        queryString.stringify(this.props.params, { arrayFormat: "comma" });
    }

    // Return URL
    return fetchUrl;
  }

  fetchResults() {
    const fetchUrl = this.parseRequestUrl();
    if (this.state.fetchUrl !== fetchUrl) {
      // Fetch URL has changed. Update state and get new results
      this.setState(
        {
          fetchUrl: fetchUrl,
          isLoading: true,
          results: undefined,
          error: undefined,
          count: 0,
          pages: 0,
        },
        () => {
          if (fetchUrl) {
            axios
              .get(fetchUrl)
              .then((response) => {
                this.setState(
                  {
                    isLoading: false,
                    error: undefined,
                    results: response.data.data.rows,
                    count: response.data.data.count,
                    pages: response.data.pageCount,
                  },
                  () => {
                    this.props.onFetchSuccess &&
                      this.props.onFetchSuccess(this.state);
                  }
                );
              })
              .catch((error) => {
                this.setState(
                  {
                    isLoading: false,
                    results: undefined,
                    error: error,
                    count: 0,
                    pages: 0,
                  },
                  () => {
                    this.props.onFetchError &&
                      this.props.onFetchError(this.state);
                  }
                );
              });
          }
        }
      );
    }
  }

  getResultsFoundMessage() {
    if (typeof this.resultCountMessage === "string") {
      return this.resultCountMessage.replace("####", this.getResultCount());
    }
    return this.resultCountMessage;
  }

  toggleMobileFilters(e) {
    if (this.props.onToggleMobileFilters) {
      this.props.onToggleMobileFilters(e);
    }
  }

  getResultCount() {
    if (this.state.results && this.state.count > 0) return this.state.count;
    return 0;
  }

  OrderDropdown() {
    if (this.props.showOrderBy === false || this.getResultCount() === 0) {
      return null;
    }
    return (
      <div className="sort-control listing-control">
        <FilterDropdown
          id="listing-sort"
          name="sort-by"
          label="Sort by:"
          onChange={this.handleOrderByChange}
          value={this.getOrderBy()}
        >
          {this.sorting.map(function (sort, i) {
            return (
              <option value={i} key={i}>
                {sort.label}
              </option>
            );
          }, this)}
        </FilterDropdown>
      </div>
    );
  }

  ErrorMessage(error) {
    if (error.response) {
      // Response Error
      return error.data;
    } else if (error.request) {
      return "Unable to connect. Please try again later.";
    } else {
      return "Unknown Error.";
    }
  }

  render() {
    const { id, className } = this.props;
    if (this.state.error) {
      return (
        <div
          id={id + "-error"}
          className={classNames(
            "listings",
            "listings-error",
            "error",
            className,
            className + "-error"
          )}
        >
          <h1 className="listings-title">{this.state.error.message}</h1>
          <p>{this.ErrorMessage(this.state.error)}</p>
        </div>
      );
    } else if (this.state.results) {
      return (
        <div
          id={id}
          className={classNames(
            "listings",
            "listings-results",
            "results",
            className,
            className + "-results"
          )}
        >
          <header className="listings-header listings-results-header">
            <Row>
              <Col xs={10} md={8}>
                <h1 className="listings-title">
                  {this.getResultsFoundMessage()}
                </h1>
              </Col>
              <Col xs={2} className="d-sm-none">
                <button
                  className="listing-mobile-filter-trigger"
                  onClick={this.toggleMobileFilters}
                >
                  <FiltersIcon />
                </button>
              </Col>
              <Col sm={4} className="d-none d-sm-block text-right">
                {this.OrderDropdown()}

                {this.state.pages > 1 && (
                  <div className="paging-control listing-control">
                    <FilterDropdown
                      id="listing-paging"
                      name="show"
                      label="View:"
                      onChange={this.handlePageLimitChange}
                      value={this.getPageLimit()}
                    >
                      {this.paging.map(function (paging, i) {
                        return (
                          <option value={paging.count} key={i}>
                            {paging.label}
                          </option>
                        );
                      }, this)}
                    </FilterDropdown>
                  </div>
                )}
              </Col>
            </Row>
          </header>
          <main className="listings-items">
            {this.state.results.map((item, i, results) => {
              return this.props.children(item, i, results);
              // return React.Children.map(this.props.children, child =>
              //     React.cloneElement(child, {result, i}),
              //   )
              //return React.cloneElement(this.props.children, {result, i});
            }, this)}
          </main>

          <footer className="listings-footer listings-results-footer">
            {this.state.pages > 1 && (
              <div className="paging-control listing-control">
                <FilterDropdown
                  id="listing-paging"
                  name="show"
                  label="View:"
                  onChange={this.handlePageLimitChange}
                  value={this.getPageLimit()}
                >
                  {this.paging.map(function (paging, i) {
                    return (
                      <option value={paging.count} key={i}>
                        {paging.label}
                      </option>
                    );
                  }, this)}
                </FilterDropdown>
                <Pagination
                  totalRecords={this.state.count}
                  pageLimit={this.getPageLimit()}
                  pageNeighbours={1}
                  onPageChanged={this.handlePageChange}
                  currentPage={this.getPage()}
                />
              </div>
            )}
          </footer>
        </div>
      );
    } else {
      return (
        <div
          id={id}
          className={classNames(
            "listings",
            "listings-loading",
            "results",
            className,
            className + "-loading"
          )}
        >
          <Spinner />
        </div>
      );
    }
  }
}

export default withRouter(Listings);

// class ListingsComponent extends React.Component {
//   render() {
//     return (
//       <Listings>
//         <Listings.Loading>My Loading</Listings.Loading>
//       </Listings>
//     );
//   }
// }
