import React, { Component } from "react";
import axios from "axios";

import { Form } from "react-bootstrap";

class PrivateCompanyProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      heard: [],
    };
  }

  componentDidMount() {
    axios.get("/masterData?keys=accountHeard").then((response) => {
      const masterData = response.data.rows;
      this.setState({
        heard: masterData
          .filter((data) => data.key === "accountHeard")
          .sort((a, b) => (a.order > b.order ? 1 : -1)),
      });
    });
  }

  render() {
    const {
      countries,
      states,
      handleChange,
      values,
      touched,
      errors,
    } = this.props;
    return (
      <div className="private-company-profile">
        <Form.Group controlId="fullname">
          <Form.Label>Full Name</Form.Label>
          <Form.Control
            type="text"
            name="fullname"
            value={values.fullname}
            onChange={handleChange}
            isInvalid={touched.fullname && !!errors.fullname}
            className="input-long"
          />
          <Form.Control.Feedback type="invalid">
            {errors.fullname}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="employedTitle">
          <div className="sales-form-label">
            <Form.Label>Title:</Form.Label>
            <p className="sales-form-label-tip">Optional</p>
          </div>
          <Form.Control
            type="text"
            name="employedTitle"
            value={values.employedTitle}
            onChange={handleChange}
            isInvalid={touched.employedTitle && !!errors.employedTitle}
            className="input-long"
          />
        </Form.Group>
        <Form.Group controlId="privateCompanyName">
          <div className="sales-form-label">
            <Form.Label>Company Name:</Form.Label>
          </div>
          <Form.Control
            type="text"
            name="privateCompanyName"
            value={values.privateCompanyName}
            onChange={handleChange}
            isInvalid={
              touched.privateCompanyName && !!errors.privateCompanyName
            }
            className="input-long"
          />
          <Form.Control.Feedback type="invalid">
            {errors.privateCompanyName}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="privateCompanyPhone">
          <Form.Label>Company Phone:</Form.Label>
          <Form.Control
            type="text"
            name="privateCompanyPhone"
            value={values.privateCompanyPhone}
            onChange={handleChange}
            isInvalid={
              touched.privateCompanyPhone && !!errors.privateCompanyPhone
            }
            className="input-short"
          />
          <Form.Control.Feedback type="invalid">
            {errors.privateCompanyPhone}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="privateCompanyFax">
          <div className="sales-form-label">
            <Form.Label>Fax:</Form.Label>
            <p className="sales-form-label-tip">Optional</p>
          </div>
          <Form.Control
            type="text"
            name="privateCompanyFax"
            value={values.privateCompanyFax}
            onChange={handleChange}
            isInvalid={touched.privateCompanyFax && !!errors.privateCompanyFax}
            className="input-short"
          />
          <Form.Control.Feedback type="invalid">
            {errors.privateCompanyFax}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="privateCompanyAddress1">
          <Form.Label>Company Address:</Form.Label>
          <Form.Control
            type="text"
            name="privateCompanyAddress1"
            value={values.privateCompanyAddress1}
            onChange={handleChange}
            isInvalid={
              touched.privateCompanyAddress1 && !!errors.privateCompanyAddress1
            }
            className="input-long"
          />
        </Form.Group>
        <Form.Group controlId="privateCompanyAddress2">
          <Form.Control
            type="text"
            name="privateCompanyAddress2"
            value={values.privateCompanyAddress2}
            onChange={handleChange}
            isInvalid={
              touched.privateCompanyAddress1 && !!errors.privateCompanyAddress1
            }
            className="input-long"
          />
          <Form.Control.Feedback type="invalid">
            {errors.privateCompanyAddress1}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="privateCompanyCity">
          <Form.Label>City:</Form.Label>
          <Form.Control
            type="text"
            name="privateCompanyCity"
            value={values.privateCompanyCity}
            onChange={handleChange}
            isInvalid={
              touched.privateCompanyCity && !!errors.privateCompanyCity
            }
            className="input-long"
          />
          <Form.Control.Feedback type="invalid">
            {errors.privateCompanyCity}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="privateCompanyZip">
          <Form.Label>Zip Code/Postal Code:</Form.Label>
          <Form.Control
            type="text"
            name="privateCompanyZip"
            value={values.privateCompanyZip}
            onChange={handleChange}
            isInvalid={touched.privateCompanyZip && !!errors.privateCompanyZip}
            className="input-short"
          />
          <Form.Control.Feedback type="invalid">
            {errors.privateCompanyZip}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group controlId="privateCompanyCountry">
          <Form.Label>Country:</Form.Label>
          <Form.Control
            name="privateCompanyCountry"
            value={values.privateCompanyCountry}
            onChange={handleChange}
            isInvalid={
              touched.privateCompanyCountry && !!errors.privateCompanyCountry
            }
            as="select"
            className="input-short"
          >
            <option value="">Select Country</option>
            {countries &&
              countries.map((country, key) => (
                <option key={key} value={country.value}>
                  {country.title}
                </option>
              ))}
          </Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.privateCompanyCountry}
          </Form.Control.Feedback>
        </Form.Group>
        {values &&
          values.privateCompanyCountry &&
          values.privateCompanyCountry === "US" && (
            <Form.Group controlId="privateCompanyState">
              <Form.Label>State/Province:</Form.Label>
              <Form.Control
                name="privateCompanyState"
                value={values.privateCompanyState}
                onChange={handleChange}
                isInvalid={
                  touched.privateCompanyState && !!errors.privateCompanyState
                }
                as="select"
                className="input-short"
              >
                <option value="">Select State</option>
                {states &&
                  states.map((state, key) => (
                    <option key={key} value={state.value}>
                      {state.title}
                    </option>
                  ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.privateCompanyState}
              </Form.Control.Feedback>
            </Form.Group>
          )}

        <Form.Group controlId="privateCompanyEmail">
          <Form.Label>Main Email:</Form.Label>
          <Form.Control
            type="text"
            name="privateCompanyEmail"
            value={values.privateCompanyEmail}
            onChange={handleChange}
            isInvalid={
              touched.privateCompanyEmail && !!errors.privateCompanyEmail
            }
            className="input-long"
          />
          <Form.Control.Feedback type="invalid">
            {errors.privateCompanyEmail}
          </Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>How did you hear about us?</Form.Label>
          <div className="hear">
            <Form.Control
              name="heard"
              value={values.heard}
              onChange={handleChange}
              as="select"
              className="hear-medium"
            >
              <option></option>
              {this.state.heard.map((h) => (
                <option key={`heard-${h.value}`} value={h.value}>
                  {h.title}
                </option>
              ))}
            </Form.Control>
            {values.heard && ["referral", "other"].includes(values.heard) && (
              <Form.Control
                name="heardOther"
                className="other-medium"
                onChange={handleChange}
                placeholder="Explain"
                value={values.heardOther}
              />
            )}
          </div>
        </Form.Group>
      </div>
    );
  }
}

export default PrivateCompanyProfile;
