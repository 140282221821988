import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import Routes from "routes";

export default class ServicesLinks extends Component {
  render() {
    return (
      <Row {...this.props} className={"services-links " + this.props.className}>
        <Col xs={10} sm={6}>
          <Link
            to={{
              pathname: Routes.services,
              search: "?category=brokers-dealers",
            }}
            className="btn"
          >
            Brokers
          </Link>
          <Link
            to={{ pathname: Routes.services, search: "?category=completions" }}
            className="btn"
          >
            Completions
          </Link>
          <Link
            to={{ pathname: Routes.services, search: "?category=financing" }}
            className="btn"
          >
            Financing
          </Link>
        </Col>
        <Col xs={10} sm={6}>
          <Link
            to={{ pathname: Routes.services, search: "?category=maintenance" }}
            className="btn"
          >
            Maintenance
          </Link>
          <Link
            to={{ pathname: Routes.services, search: "?category=parts" }}
            className="btn"
          >
            Parts
          </Link>
          <Link to={Routes.services} className="btn">
            All Services
          </Link>
        </Col>
      </Row>
    );
  }
}
