import React from "react";
import { withRouter } from "react-router";
import { Route, Switch, Link, Redirect } from "react-router-dom";
import classNames from "classnames";

import { Row, Col } from "react-bootstrap";

function TabRoutes(props) {
  const { tabs, match, redirectFrom, redirectTo } = props;

  const getTabClass = (url) => {
    if (match.url === url) {
      return "menu-item active";
    }
    return "menu-item";
  };

  return (
    <div
      className={classNames(
        "tab-routes",
        `tab-routes-${props.id}`,
        props.className
      )}
    >
      <Row className="pb-5 py-5">
        <Col>
          <nav className="tab-routes-nav">
            <ul className="tab-routes-tabs tabs centered">
              {tabs.map((t, i) => (
                <li key={`tab-route-tab-${i}`} className={getTabClass(t.url)}>
                  <Link to={t.url}>
                    <span className="item-num">{t.icon ? <t.icon /> : i}</span>
                    <span className="item-name">{t.title}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </Col>
      </Row>
      <Row>
        <Col>
          <Switch>
            {redirectFrom && redirectTo && (
              <Route exact path={redirectFrom}>
                <Redirect to={redirectTo} />
              </Route>
            )}

            {tabs.reverse().map((t, i) => (
              <Route
                key={`tab-route-route-${i}`}
                path={t.url}
                component={t.component}
                exact={t.exact || true}
              />
            ))}
          </Switch>
        </Col>
      </Row>
    </div>
  );
}
export default withRouter(TabRoutes);
