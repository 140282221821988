import React from "react";
import axios from "axios";
import { connect } from "react-redux";

import { openLogin } from "actions/modal/actions";

import { ReactComponent as HeartOutline } from "images/icons/heart.svg";
import { ReactComponent as HeartFilled } from "images/icons/heart-filled.svg";

class Favorite extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isFavorited: this.props.listing.isFavorite || false,
    };
  }

  componentDidMount() {
    const { listing } = this.props;
    if (!listing) {
      throw new Error("A Listing is Required");
    } else if (listing.isFavorite === 1) {
      this.setState({ isFavorited: true });
    }
  }

  handleClick = (evt) => {
    const { listing, userInfo } = this.props;
    if (userInfo) {
      if (this.state.isFavorited) {
        // Unset favorite
        this.setState({ isFavorited: false });
        axios
          .put(`/listings/slug/${listing.slug}/favorite`, { favorite: false })
          .catch((err) => {
            this.setState({ isFavorited: true }, () => {
              console.error("Unable to Remove Favorite");
            });
          });
      } else {
        // Set Favorite
        this.setState({ isFavorited: true });
        axios
          .put(`/listings/slug/${listing.slug}/favorite`, { favorite: true })
          .catch((err) => {
            this.setState({ isFavorited: false }, () => {
              console.error("Unable to Add Favorite");
            });
          });
      }
    } else {
      // Not logged in. Show the login modal!
      this.props.openLogin();
    }
  };

  heartIcon = () => {
    switch (this.state.isFavorited) {
      case true:
        return <HeartFilled />;
      case false:
      default:
        return <HeartOutline />;
    }
  };

  render() {
    return (
      <button className="btn-favorite" onClick={(e) => this.handleClick(e)}>
        {this.heartIcon()}
      </button>
    );
  }
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    openLogin: () => dispatch(openLogin()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(Favorite);
