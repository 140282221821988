import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Routes from "routes";
import Avatar from "components/shared/avatar";

import { toggleUserSidebar, closeMobileMenu } from "actions/app/actions";
import { openLogin } from "actions/modal/actions";

// Icons
import { ReactComponent as SearchIcon } from "images/icons/search.svg";
import { ReactComponent as MenuIcon } from "images/icons/menu.svg";
import { ReactComponent as LanguageIcon } from "images/icons/flag-us.svg";

const MobileMenu = (props) => {
  const { userInfo } = props;
  const { openLogin, closeMobileMenu, toggleUserSidebar } = props;
  return (
    <div
      id="mobile-menu-overlay"
      className="overlay d-flex justify-content-between flex-column"
    >
      <Row id="mobile-menu-overlay-top" className="m-4">
        <Col>
          <a
            href={Routes.null}
            className="close-button"
            onClick={closeMobileMenu}
          >
            <MenuIcon />
          </a>
        </Col>
        <Col className="text-right">
          {!_.isEmpty(userInfo) && userInfo.profile && (
            <div
              className="menu-item menu-icon avatar-button d-inline-block"
              onClick={() => {
                closeMobileMenu();
                toggleUserSidebar();
              }}
            >
              <Avatar
                name={userInfo.profile.name}
                src={userInfo.profile.profileImage}
                size={32}
              />
            </div>
          )}
        </Col>
      </Row>

      <div id="mobile-menu-overlay-middle" className="m-4">
        <ul
          id="mobile-menu-list"
          className="mobile-menu-items menu-items overlay-content"
        >
          <li>
            <Link
              to={Routes.forSale}
              className="menu-item menu-text"
              onClick={closeMobileMenu}
            >
              For Sale
            </Link>
          </li>
          <li>
            <Link
              to={Routes.services}
              className="menu-item menu-text"
              onClick={closeMobileMenu}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to={Routes.wanted}
              className="menu-item menu-text"
              onClick={closeMobileMenu}
            >
              Wanted
            </Link>
          </li>
          <li>
            <Link
              to={Routes.advertise}
              className="menu-item menu-text"
              onClick={closeMobileMenu}
            >
              Advertise
            </Link>
          </li>
          <li>
            {_.isEmpty(userInfo) && (
              <a
                href={Routes.null}
                onClick={() => {
                  openLogin();
                  closeMobileMenu();
                }}
                className="menu-item menu-text"
              >
                Log In
              </a>
            )}
            {!_.isEmpty(userInfo) && (
              <Link
                to={Routes.admin}
                className="menu-item menu-text"
                onClick={closeMobileMenu}
              >
                Dashboard
              </Link>
            )}
          </li>
        </ul>
      </div>

      <div
        id="mobile-menu-overlay-bottom"
        className="justify-content-between m-4 d-flex"
      >
        <a href={Routes.null} className="menu-item menu-icon search-button">
          <SearchIcon />
        </a>
        <a href={Routes.null} className="menu-item menu-icon language-button">
          <LanguageIcon />
        </a>
      </div>
    </div>
  );
};

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    openLogin: () => dispatch(openLogin()),
    toggleUserSidebar: () => dispatch(toggleUserSidebar()),
    closeMobileMenu: () => dispatch(closeMobileMenu()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(MobileMenu);
