import React from "react";
import axios from "axios";
import { Formik } from "formik";

import { Button, Row, Col, Form } from "react-bootstrap";
import Spinner from "components/shared/spinner";
import RichTextEditor from "components/shared/richEditor";
import SaveButtonText from "components/backend/listings/fields/saveButtonText";
import RoleRenderer from "components/shared/roleRenderer";
import stripHTML from "utils/stripHTML";
import { connect } from "react-redux";

// import CommonInput from "components/backend/listings/fields/commonInput";

// const yup = require("yup");

class Step6 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      error: undefined,
      conditions: [],
      refurbishments: [],
      lavatories: [],
      gallies: [],
    };
  }

  componentDidMount = () => {
    const proms = [];
    proms.push(
      axios
        .get(
          "/masterData?keys=aircraftCondition,aircraftRefurbishment,aircraftLavatory,aircraftGalley"
        )
        .then((response) => {
          const masterData = response.data.rows;
          this.setState({
            conditions: masterData.filter(
              (data) => data.key === "aircraftCondition"
            ),
            refurbishments: masterData.filter(
              (data) => data.key === "aircraftRefurbishment"
            ),
            lavatories: masterData.filter(
              (data) => data.key === "aircraftLavatory"
            ),
            gallies: masterData.filter((data) => data.key === "aircraftGalley"),
          });
        })
    );

    Promise.all(proms)
      .then(() => {
        // Finished Loading
        this.setState({ isLoading: false, error: undefined });
      })
      .catch((err) => {
        this.setState({ isLoading: false, error: err });
      })
      .finally(() => {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 500);
      });
  };

  handleValidation = (values) => {};

  handleChange = (e) => {
    //this.props.onChange(e);
    const name = e.target.name;
    const value = e.target.value;

    this.props.onChange(name, value);
  };

  handleSingleCheckbox = (e) => {
    this.props.onChange(e.target.name, e.currentTarget.checked);
  };

  textEditorHandler = (value, name) => {
    let cleanedValue = stripHTML(value).trim();
    if (!cleanedValue) {
      value = null;
    }

    this.props.onChange(name, value);
  };

  saveButtonText = () => {
    const { userInfo } = this.props;
    if (userInfo && userInfo.role && userInfo.role.value) {
      if (
        userInfo.role.value === "super-admin" ||
        userInfo.role.value === "company-admin"
      ) {
        return "Publish Listing";
      }
    }

    return "Submit for Review";
  };

  render() {
    if (this.state.error) {
      return (
        <div id="step-2-error" className="stepper-error step-2-error error">
          <h1 className="listings-title">{this.state.error.message}</h1>
          <p>{this.ErrorMessage(this.state.error)}</p>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div
          id="step-2-loading"
          className="stepper-loading step-2-loading loading"
        >
          <Spinner />
        </div>
      );
    }

    return (
      <Formik
        //validationSchema={schema}
        //onSubmit={values => this.handleSubmit(values)}
        onSubmit={this.handleValidation}
        initialValues={{}}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          isSubmitting,
          setSubmitting,
          validateForm,
          isValid,
        }) => (
          <Form onSubmit={handleSubmit} noValidate>
            <Row className="stepper_5">
              <Col>
                <Form.Label className="subHeader">
                  Your interior is not inferior to us!
                </Form.Label>
                <Form.Row>
                  <Form.Group as={Col} md={4} lg={3} className="d-flex">
                    <Form.Label>Lavatory:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="lavatory"
                      value={this.props.values.lavatory || ""}
                    >
                      <option value=""></option>
                      {this.state.lavatories.map((cb, i) => {
                        return (
                          <option key={i} value={cb.value}>
                            {cb.title}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} md={4} lg={3} className="d-flex">
                    <Form.Label>Galley:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="galley"
                      value={this.props.values.galley || ""}
                    >
                      <option value=""></option>
                      {this.state.gallies.map((cb, i) => {
                        return (
                          <option key={i} value={cb.value}>
                            {cb.title}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group as={Col} md={4} lg={2} className="d-flex">
                    <Form.Check
                      custom
                      type="checkbox"
                      name="interiorCrewRest"
                      id="checkbox-crew-rest"
                      label="Crew Rest?"
                      onChange={this.handleSingleCheckbox}
                      checked={this.props.values.interiorCrewRest || false}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md={4} lg={2} className="d-flex">
                    <Form.Check
                      custom
                      type="checkbox"
                      name="interiorBeltedPotty"
                      id="checkbox-belted-potty"
                      label="Belted Potty"
                      onChange={this.handleSingleCheckbox}
                      checked={this.props.values.interiorBeltedPotty || false}
                    />
                  </Form.Group>
                </Form.Row>

                <Form.Label className="line-height">
                  Any other interior equipment or features?
                </Form.Label>
                <RichTextEditor
                  name="interiorDetails"
                  value={this.props.values.interiorDetails || ""}
                  onChange={this.textEditorHandler}
                />
                <Form.Group className="w-100 mt-4">
                  <Form.Label className="subHeader">
                    How does the aircraft’s exterior look?
                  </Form.Label>
                  <RichTextEditor
                    name="exteriorDetails"
                    value={this.props.values.exteriorDetails || ""}
                    onChange={this.textEditorHandler}
                  />
                </Form.Group>
                <Row className="stepper_btns">
                  <Col md="auto">
                    <RoleRenderer role="sales-rep" exact={true}>
                      <Button
                        type="submit"
                        className="btn-green m-b-15"
                        disabled={isSubmitting}
                        onClick={(e) => {
                          handleSubmit(e);
                          if (isValid) {
                            this.props.onNext().then(() => {
                              setSubmitting(false);
                            });
                          } else {
                            setSubmitting(false);
                          }
                        }}
                      >
                        Submit for Review
                      </Button>
                    </RoleRenderer>
                    <RoleRenderer role="company-admin">
                      <Button
                        type="submit"
                        className="btn-red m-b-15"
                        disabled={isSubmitting}
                        onClick={(e) => {
                          handleSubmit(e);
                          if (isValid) {
                            this.props.onNext().then(() => {
                              setSubmitting(false);
                            });
                          } else {
                            setSubmitting(false);
                          }
                        }}
                      >
                        Publish Listing
                      </Button>
                    </RoleRenderer>
                    <RoleRenderer role="advertiser" exact>
                      <Button
                        type="submit"
                        className="btn-red m-b-15"
                        disabled={isSubmitting}
                        onClick={(e) => {
                          handleSubmit(e);
                          if (isValid) {
                            this.props.onNext().then(() => {
                              setSubmitting(false);
                            });
                          } else {
                            setSubmitting(false);
                          }
                        }}
                      >
                        Publish Listing
                      </Button>
                    </RoleRenderer>
                  </Col>
                  <Col md="auto">
                    <Button
                      className="btn-green"
                      disabled={isSubmitting}
                      onClick={(e) => {
                        handleSubmit(e);
                        if (isValid) {
                          this.props.onSave().then(() => {
                            setSubmitting(false);
                          });
                        } else {
                          setSubmitting(false);
                        }
                      }}
                    >
                      {SaveButtonText(this.props.values.status)}
                    </Button>
                  </Col>
                  <Col md="auto">
                    <RoleRenderer role="sales-rep" exact={true}>
                      <h5>
                        Your company admin must approve all listings before
                        being published
                      </h5>
                    </RoleRenderer>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

export default connect(keyStateToProps)(Step6);
