export const SET_APP_READY = "SET_APP_READY";
export const OPEN_USER_SIDEBAR = "OPEN_USER_SIDEBAR";
export const CLOSE_USER_SIDEBAR = "CLOSE_USER_SIDEBAR";
export const TOGGLE_USER_SIDEBAR = "TOGGLE_USER_SIDEBAR";

export const OPEN_MOBILE_MENU = "OPEN_MOBILE_MENU";
export const CLOSE_MOBILE_MENU = "CLOSE_MOBILE_MENU";
export const TOGGLE_MOBILE_MENU = "TOGGLE_MOBILE_MENU";

export const SHOW_COOKIES_NOTICE = "SHOW_COOKIES_NOTICE";
export const HIDE_COOKIES_NOTICE = "HIDE_COOKIES_NOTICE";
