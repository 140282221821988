import { combineReducers } from "redux";

import app from "./app";
import user from "./user";
import dashboard from "./dashboard";
import modal from "./modal";
import notifications from "./notification";
import toast from "./toast";
import companies from "./companies";
import masterData from "./masterData";
import plans from "./plans";
import lists from "./lists";
import listing from "./listing";

export default combineReducers({
  app,
  user,
  dashboard,
  modal,
  notifications,
  toast,
  companies,
  masterData,
  plans,
  lists,
  listing,
});
