import React from "react";
import { connect } from "react-redux";
import { Link, generatePath } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Routes from "routes";
import Favorite from "components/frontend/listings/_shared/favorite";
import ImagePlaceholder from "images/listings/placeholder.png";

import { openContactForm } from "actions/modal/actions";

class ListingsItem extends React.Component {
  render() {
    const { mode } = this.props;

    switch (mode) {
      case "featured":
        return <FeaturedListing {...this.props} />;
      case "subscriber":
      case "basic":
      default:
        return <SubscriberListing {...this.props} />;
    }
  }
}
ListingsItem.propTypes = {
  type: PropTypes.oneOf([
    "jet",
    "turboprop",
    "twin-piston",
    "single-piston",
    "helicopter",
    "other",
  ]),
};

const keyDispatchToProps = (dispatch) => {
  return {
    openContactForm: (listing) => dispatch(openContactForm(listing)),
  };
};

export default connect(null, keyDispatchToProps)(ListingsItem);

function ListingHeader(props) {
  return (
    <header className="listing-header">
      <div className="listing-title title">
        <h3>
          <Link to={props.link}>{props.title}</Link>
        </h3>
        <Favorite listing={props.listing} />
      </div>

      {props.company && (
        <div className="listing-company company">
          Company:{" "}
          <Link
            to={generatePath(Routes.company, { company: props.company.slug })}
            className="listing-company-link company-link"
          >
            {props.company.name}
          </Link>
        </div>
      )}
      {!props.company &&
        props.account &&
        props.account.profile &&
        props.account.profile.name && (
          <div className="listing-company company">
            Rep:{" "}
            <button
              className="btn-link listing-company-link company-link"
              onClick={() => props.openContactForm(props.listing)}
            >
              {props.account.profile.name}
            </button>
          </div>
        )}
    </header>
  );
}

function ListingFooter(props) {
  return (
    <footer className="listing-footer">
      <Row className="listing-button ">
        <Col className="d-flex justify-content-center justify-content-lg-end">
          <Link to={props.link} className="btn btn-primary mb-2 ml-2">
            Read More
          </Link>
          <button
            className="btn btn-secondary mb-2 ml-2"
            onClick={() => props.openContactForm(props.listing)}
          >
            Contact
          </button>
        </Col>
      </Row>
    </footer>
  );
}

function FeaturedListing(props) {
  const { className, children, type, image, description, hideDefaultImage } =
    props;
  const imageCss = image ? { backgroundImage: "url(" + image + ")" } : {};
  return (
    <div
      className={classNames(
        "list-item",
        "listing-item",
        "featured-item",
        "featured-listing",
        className,
        type
      )}
    >
      <div className="listing-featured">FEATURED</div>
      <Row>
        {(image || !hideDefaultImage) && (
          <Col xs={12} md={6} xl={8}>
            <div className="listing-image image" style={imageCss}>
              <Link to={props.link}>
                <img src={ImagePlaceholder} alt="" className="img-fluid" />
              </Link>
            </div>
          </Col>
        )}

        <Col xs={12} md xl className="listing-content">
          <article>
            <ListingHeader {...props} />
            <div className="listing-content">
              <div className="listing-meta meta">{children}</div>
              <div className="listing-description description d-none">
                {description}
              </div>
            </div>
            <ListingFooter {...props} />
          </article>
        </Col>
      </Row>
    </div>
  );
}

function SubscriberListing(props) {
  const { className, children, type, image, description, hideDefaultImage } =
    props;
  const imageCss = image ? { backgroundImage: "url(" + image + ")" } : {};
  return (
    <div
      className={classNames(
        "list-item",
        "listing-item",
        "subscriber-item",
        "subscriber-listing",
        className,
        type
      )}
    >
      <Row>
        {(image || !hideDefaultImage) && (
          <Col xs={12} md={6} xl={4}>
            <div className="listing-image image" style={imageCss}>
              <Link to={props.link}>
                <img src={ImagePlaceholder} alt="" className="img-fluid" />
              </Link>
            </div>
          </Col>
        )}

        <Col xs={12} md xl className="listing-content">
          <article>
            <ListingHeader {...props} />
            <div className="listing-content">
              <div className="listing-meta meta">{children}</div>
              <div className="listing-description description d-none">
                {description}
              </div>
            </div>
          </article>
          <ListingFooter {...props} />
        </Col>
      </Row>
    </div>
  );
}
