export const GET_COMPANIES = "GET_COMPANIES";
export const SET_COMPANIES = "SET_COMPANIES";

export const ADD_COMPANY = "ADD_COMPANY";
export const REGISTER_COMPANY = "REGISTER_COMPANY";
export const UPDATE_COMPANY = "UPDATE_COMPANY";

export const ADD_COMPANY_LOGO = "ADD_COMPANY_LOGO";

export const ADD_COMPANY_PAYMENT = "ADD_COMPANY_PAYMENT";
export const DELETE_COMPANY_PAYMENT = "DELETE_COMPANY_PAYMENT";

export const UPGRADE_COMPANY_PLAN = "UPGRADE_COMPANY_PLAN";
