import React, { useState } from "react";

import { Row, Col, Form, Tab } from "react-bootstrap";

import SingleImageBrickUpload from "components/backend/shared/singleImageBrickUpload";
import PromoteCalendar from "components/backend/promotions/shared/calendar";
import TextareaAutosize from "react-textarea-autosize";
const HomepageTab = (props) => {
  //   const { from, enteredTo } = this.state;
  //   const modifiers = { start: from, end: enteredTo };
  //   const disabledDays = { before: this.state.from };
  //   const selectedDays = [from, { from, to: enteredTo }];

  const [titlePlaceholder] = useState(props.promotion.title);
  const [subtitlePlaceholder] = useState(props.promotion.subtitle);

  const handleChange = (evt) => {
    props.onChange && props.onChange(evt.target.name, evt.target.value);
  };

  return (
    <Tab.Pane eventKey="#homepage">
      {!props.active && <div className="form-overlay"></div>}
      <div className="form-content feature-detail py-2 px-4">
        <Row className={"mt-2"}>
          <Col xs={12} lg={6} xl={6} className="pr-0">
            <h6>1. Please attach your featured photo for display.</h6>
            <div className="text-uppercase mt-3">
              Recommended size 1280x720 pixels. Please, no advertisements.
              Photograph only. the photograph will be displayed next to the
              company information and logo, along with a link to the full
              listing.
            </div>
          </Col>
          <Col xs={12} lg={6} xl={6} className="d-flex mt-3 promote-new-img">
            <h6 className="mr-3">Image:</h6>
            <SingleImageBrickUpload
              image={
                props.promotion.image ? props.promotion.image.url : undefined
              }
              onAdd={(image) => {
                if (props.onChange) {
                  props.onChange("image", image);
                }
              }}
              onDelete={() => {
                if (props.onChange) {
                  props.onChange("image", undefined);
                }
              }}
            />
          </Col>
        </Row>

        <Row className={"mt-4 pt-2"}>
          <Col xs={12} className={"my-3 pr-0"}>
            <h6>2. Please define the title and subtitle of your listing.</h6>
            <div className={"d-flex align-items-center"}>
              <h6 className="w-auto mr-2">Title:</h6>
              <Form.Control
                as="input"
                name="title"
                value={props.promotion.title || ""}
                placeholder={titlePlaceholder}
                disabled={!props.active}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col xs={12} className={"my-2 pr-0"}>
            <div className={"d-flex align-items-center"}>
              <h6 className="w-auto mr-2">Subtitle:</h6>
              <Form.Control
                as="input"
                name="subtitle"
                value={props.promotion.subtitle || ""}
                placeholder={subtitlePlaceholder}
                disabled={!props.active}
                onChange={handleChange}
              />
            </div>
          </Col>
        </Row>

        <Row className={"mt-4 pt-4"}>
          <Col xs={12}>
            <h6>3. Please select desired promotion week.</h6>

            <div className="text-uppercase my-3">
              No dates are guaranteed and all are subject to review, approval,
              and availability of Plane Mover. A Plane Mover Representative will
              contact you with confirmation.
            </div>
          </Col>
          <Col className={"Calendar-div d-inline-block"} xs={12}>
            <PromoteCalendar
              onChange={(date) => {
                if (props.onChange) {
                  props.onChange("startDate", date);
                }
              }}
            />
          </Col>
        </Row>
        <Row className={"mt-4 pt-4"}>
          <Col xs={12}>
            <div className={"mt-2"}>
              <h6>4. Comments:</h6>
              <TextareaAutosize
                as="textarea"
                className={"w-100 mt-3"}
                name="comments"
                rows="5"
                placeholder=""
                onChange={handleChange}
              />
            </div>
          </Col>
        </Row>
      </div>
    </Tab.Pane>
  );
};

export default HomepageTab;
