import React from "react";
import Slider from "react-slick";

import Card from './card';

import { ReactComponent as PreviousArrowIcon } from "images/icons/arrow-left.svg";
import { ReactComponent as NextArrowIcon } from "images/icons/arrow-right.svg";

const settings = {
  className: "search-slider",
  dots: false,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  swipeToSlide: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};

export default function SearchCardSlider() {
  return (
    <div className="search-block__slider">
      <Slider {...settings}>
        {
          [0, 1, 3, 4, 5, 6].map((item, i) => <Card key={i} />)
        }
      </Slider>
    </div>
  );
}

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <button className={className} onClick={onClick}>
      <NextArrowIcon />
    </button>
  );
}

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <button className={className} onClick={onClick}>
      <PreviousArrowIcon />
    </button>
  );
}