import React from "react";
import { connect } from "react-redux";
import { Link, generatePath } from "react-router-dom";
import _ from "lodash";
import { CSSTransition } from "react-transition-group";

import Routes from "routes";
import Avatar from "components/shared/avatar";

import { closeUserSidebar } from "actions/app/actions";
import { logOut } from "actions/user/actions";

import Button from "react-bootstrap/Button";

// Icons
import { ReactComponent as MenuIcon } from "images/icons/menu.svg";
import { ReactComponent as HomeIcon } from "images/icons/home.svg";
import { ReactComponent as ArrowRightIcon } from "images/icons/arrow-right.svg";
import { ReactComponent as ArrowDownIcon } from "images/icons/arrow-down.svg";
import { ReactComponent as BriefcaseIcon } from "images/icons/briefcase.svg";
import { ReactComponent as AirplaneAscentIcon } from "images/icons/airplane-ascent.svg";
import { ReactComponent as AirplaneDescentIcon } from "images/icons/airplane-descent.svg";
import { ReactComponent as ServicesIcon } from "images/icons/services.svg";
import { ReactComponent as GearIcon } from "images/icons/settings.svg";
import { ReactComponent as PromotionsIcon } from "images/icons/promotions.svg";
import { ReactComponent as HeartIcon } from "images/icons/heart.svg";
import { ReactComponent as MailIcon } from "images/icons/mail.svg";
import { ReactComponent as UsersIcon } from "images/icons/users.svg";
import { ReactComponent as UserIcon } from "images/icons/user.svg";
// import { ReactComponent as SavedSearchIcon } from "images/icons/saved-search.svg";

const Sidebar = (props) => {
  const { logOut } = props;
  const companyName = () => {
    const { userInfo } = props;
    if (!_.isEmpty(userInfo)) {
      if (userInfo.company && userInfo.company) {
        return userInfo.company.name;
      } else if (userInfo.role && userInfo.role.title) {
        return userInfo.role.title;
      }
    }

    return "";
  };

  const memberSince = () => {
    const { userInfo } = props;
    if (!_.isEmpty(userInfo)) {
      if (userInfo.createdAt) {
        const createDate = new Date(userInfo.createdAt);
        return `Member Since ${createDate.getFullYear()}`;
      }
    }

    return "";
  };

  const sidebarNav = () => {
    const { userInfo } = props;
    if (!_.isEmpty(userInfo)) {
      if (userInfo.role && userInfo.role.value) {
        switch (userInfo.role.value) {
          case "super-admin":
            return superAdminNav();
          case "company-admin":
            return companyAdminNav();
          case "sales-rep":
          case "advertiser":
            return salesRepNav();
          case "sales-rep-view":
          default:
            return userNav();
        }
      }
    }

    return userNav();
  };

  const superAdminNav = () => {
    return (
      <div className="app-sidebar-nav-admin">
        <ul
          id="app-sidebar-nav-dashboard"
          className="menu-items dashboard-menu"
        >
          <li>
            <Link to={Routes.admin} className="menu-item menu-text">
              <HomeIcon />
              Dashboard
            </Link>
          </li>
        </ul>
        <ul id="app-sidebar-nav-admin" className="menu-items admin-menu">
          <li>
            <Link to={Routes.companyManage} className="menu-item menu-text">
              <BriefcaseIcon />
              Companies
            </Link>
          </li>
          <li>
            <Link to={Routes.usersManage} className="menu-item menu-text">
              <UsersIcon />
              Users
            </Link>
          </li>
          <li>
            <h4 className="sub-header">
              <ArrowDownIcon />
              All Listings
            </h4>
            <ul className="sub-menu-items">
              <li>
                <Link to={Routes.forSaleManage} className="menu-item menu-text">
                  <AirplaneAscentIcon />
                  Aircraft For Sale
                </Link>
              </li>
              <li>
                <Link to={Routes.wantedManage} className="menu-item menu-text">
                  <AirplaneDescentIcon />
                  Aircraft Wanted
                </Link>
              </li>
              <li>
                <Link
                  to={Routes.servicesManage}
                  className="menu-item menu-text"
                >
                  <ServicesIcon />
                  Services
                </Link>
              </li>
            </ul>
          </li>
        </ul>

        <ul
          id="app-sidebar-nav-promotions"
          className="menu-items promotions-menu"
        >
          <li>
            <Link
              to={generatePath(Routes.promotions, { section: null })}
              className="menu-item menu-text"
            >
              <PromotionsIcon />
              Promotions
            </Link>
          </li>
        </ul>
        <ul
          id="app-sidebar-nav-communications"
          className="menu-items communications-menu"
        >
          <li>
            <Link to={Routes.inbox} className="menu-item menu-text">
              <MailIcon />
              Inbox
            </Link>
          </li>
          <li>
            <Link
              to={generatePath(Routes.siteSettings, { section: null })}
              className="menu-item menu-text"
            >
              <GearIcon />
              Site Settings
            </Link>
          </li>
        </ul>
        <ul id="app-sidebar-nav-settings" className="menu-items settings-menu">
          <li>
            <Link
              to={generatePath(Routes.accountSettings, { email: null })}
              className="menu-item menu-text"
            >
              <UserIcon />
              Account Settings
            </Link>
          </li>
        </ul>
      </div>
    );
  };
  const companyAdminNav = () => {
    const { userInfo } = props;
    return (
      <div className="app-sidebar-nav-admin">
        <ul
          id="app-sidebar-nav-dashboard"
          className="menu-items dashboard-menu"
        >
          <li>
            <Link to={Routes.admin} className="menu-item menu-text">
              <HomeIcon />
              Dashboard
            </Link>
          </li>
        </ul>
        <ul id="app-sidebar-nav-listings" className="menu-items listings-menu">
          <li>
            <h4 className="sub-header">
              <ArrowDownIcon />
              My Listings
            </h4>
            <ul className="sub-menu-items">
              <li>
                <Link to={Routes.forSaleManage} className="menu-item menu-text">
                  <AirplaneAscentIcon />
                  Aircraft For Sale
                </Link>
              </li>
              <li>
                <Link to={Routes.wantedManage} className="menu-item menu-text">
                  <AirplaneDescentIcon />
                  Aircraft Wanted
                </Link>
              </li>
              <li>
                <Link
                  to={Routes.servicesManage}
                  className="menu-item menu-text"
                >
                  <ServicesIcon />
                  Services
                </Link>
              </li>
            </ul>
          </li>
        </ul>
        <ul
          id="app-sidebar-nav-promotions"
          className="menu-items promotions-menu"
        >
          <li>
            <Link to={Routes.promotionsNew} className="menu-item menu-text">
              <PromotionsIcon />
              Promote
            </Link>
          </li>
        </ul>
        <ul
          id="app-sidebar-nav-communications"
          className="menu-items communications-menu"
        >
          <li>
            <Link to={Routes.inbox} className="menu-item menu-text">
              <MailIcon />
              Inbox
            </Link>
          </li>
          <li>
            <Link
              to={generatePath(Routes.companyEdit, {
                company: userInfo.company.slug,
                tab: null,
              })}
              className="menu-item menu-text"
            >
              <GearIcon />
              Company Settings
            </Link>
          </li>
        </ul>
        <ul id="app-sidebar-nav-settings" className="menu-items settings-menu">
          <li>
            <Link
              to={generatePath(Routes.accountSettings, { email: null })}
              className="menu-item menu-text"
            >
              <UserIcon />
              Account Settings
            </Link>
          </li>
          <li>
            <Link
              to={generatePath(Routes.favorites, { type: null })}
              className="menu-item menu-text"
            >
              <HeartIcon />
              Favorites
            </Link>
          </li>
          {/* <li>
            <Link to={Routes.mySavedSearches} className="menu-item menu-text">
              <SavedSearchIcon />
              Saved Searches
            </Link>
          </li> */}
        </ul>
      </div>
    );
  };

  const salesRepNav = () => {
    return (
      <div className="app-sidebar-nav-admin">
        <ul
          id="app-sidebar-nav-dashboard"
          className="menu-items dashboard-menu"
        >
          <li>
            <Link to={Routes.admin} className="menu-item menu-text">
              <HomeIcon />
              Dashboard
            </Link>
          </li>
        </ul>
        <ul id="app-sidebar-nav-listings" className="menu-items listings-menu">
          <li>
            <h4 className="sub-header">
              <ArrowDownIcon />
              My Listings
            </h4>
            <ul className="sub-menu-items">
              <li>
                <Link to={Routes.forSaleManage} className="menu-item menu-text">
                  <AirplaneAscentIcon />
                  Aircraft For Sale
                </Link>
              </li>
              <li>
                <Link to={Routes.wantedManage} className="menu-item menu-text">
                  <AirplaneDescentIcon />
                  Aircraft Wanted
                </Link>
              </li>
              <li>
                <Link
                  to={Routes.servicesManage}
                  className="menu-item menu-text"
                >
                  <ServicesIcon />
                  Services
                </Link>
              </li>
            </ul>
          </li>
        </ul>
        <ul
          id="app-sidebar-nav-promotions"
          className="menu-items promotions-menu"
        >
          <li>
            <Link to={Routes.promotionsNew} className="menu-item menu-text">
              <PromotionsIcon />
              Promote
            </Link>
          </li>
        </ul>
        <ul
          id="app-sidebar-nav-communications"
          className="menu-items communications-menu"
        >
          <li>
            <Link to={Routes.inbox} className="menu-item menu-text">
              <MailIcon />
              Inbox
            </Link>
          </li>
        </ul>
        <ul id="app-sidebar-nav-settings" className="menu-items settings-menu">
          <li>
            <Link
              to={generatePath(Routes.accountSettings, { email: null })}
              className="menu-item menu-text"
            >
              <UserIcon />
              Account Settings
            </Link>
          </li>
          <li>
            <Link
              to={generatePath(Routes.favorites, { type: null })}
              className="menu-item menu-text"
            >
              <HeartIcon />
              Favorites
            </Link>
          </li>
          {/* <li>
            <Link to={Routes.mySavedSearches} className="menu-item menu-text">
              <SavedSearchIcon />
              Saved Searches
            </Link>
          </li> */}
        </ul>
      </div>
    );
  };

  const userNav = () => {
    return (
      <div className="app-sidebar-nav-admin">
        <ul
          id="app-sidebar-nav-dashboard"
          className="menu-items dashboard-menu"
        >
          <li>
            <Link to={Routes.admin} className="menu-item menu-text">
              <HomeIcon />
              Dashboard
            </Link>
          </li>
        </ul>
        <ul id="app-sidebar-nav-listings" className="menu-items listings-menu">
          <li>
            <h4 className="sub-header">
              <ArrowDownIcon />
              My Listings
            </h4>
            <ul className="sub-menu-items">
              <li>
                <Link to={Routes.forSaleManage} className="menu-item menu-text">
                  <AirplaneAscentIcon />
                  Aircraft For Sale
                </Link>
              </li>
              <li>
                <Link to={Routes.wantedManage} className="menu-item menu-text">
                  <AirplaneDescentIcon />
                  Aircraft Wanted
                </Link>
              </li>
              <li>
                <Link
                  to={Routes.servicesManage}
                  className="menu-item menu-text"
                >
                  <ServicesIcon />
                  Services
                </Link>
              </li>
            </ul>
          </li>
        </ul>
        <ul
          id="app-sidebar-nav-communications"
          className="menu-items communications-menu"
        >
          <li>
            <Link to={Routes.inbox} className="menu-item menu-text">
              <MailIcon />
              Inbox
            </Link>
          </li>
        </ul>
        <ul id="app-sidebar-nav-settings" className="menu-items settings-menu">
          <li>
            <Link
              to={generatePath(Routes.accountSettings, { email: null })}
              className="menu-item menu-text"
            >
              <UserIcon />
              Account Settings
            </Link>
          </li>
          <li>
            <Link
              to={generatePath(Routes.favorites, { type: null })}
              className="menu-item menu-text"
            >
              <HeartIcon />
              Favorites
            </Link>
          </li>
          {/* <li>
            <Link to={Routes.mySavedSearches} className="menu-item menu-text">
              <SavedSearchIcon />
              Saved Searches
            </Link>
          </li> */}
        </ul>
      </div>
    );
  };

  const { userInfo } = props;
  if (!_.isEmpty(userInfo)) {
    return (
      <CSSTransition
        in={props.showSidebar}
        timeout={5000}
        classNames="sidebar-transition"
      >
        <aside id="app-sidebar" className="app-sidebar">
          <div className="app-sidebar-content">
            <div id="app-sidebar-toggle-row" className="text-right">
              <a
                href="#arrow"
                id="app-sidebar-toggle"
                onClick={props.closeUserSidebar}
                className="menu-icon icon"
              >
                <MenuIcon />
                <ArrowRightIcon />
              </a>
            </div>
            <div className="app-sidebar-scrollable">
              <div id="app-sidebar-header" className="sidebar-header">
                <h3
                  id="app-sidebar-header-company"
                  className="sidebar-company company"
                >
                  {companyName()}
                </h3>
                <div
                  id="app-sidebar-header-member-since"
                  className="sidebar-member-since member-since"
                >
                  {memberSince()}
                </div>
                <Avatar
                  name={userInfo.profile.name}
                  src={userInfo.profile.profileImage}
                  size={100}
                />
                <h4
                  id="app-sidebar-header-user-name"
                  className="sidebar-user-name user-name"
                >
                  {userInfo.profile.name}
                </h4>
              </div>
              <nav id="app-sidebar-nav" className="app-sidebar-nav sidebar-nav">
                <div id="app-sidebar-footer" className="sidebar-footer">
                  <Button className="btn btn-green btn-sm" onClick={logOut}>
                    Log Out
                  </Button>
                </div>
                {sidebarNav()}
              </nav>
            </div>
          </div>
        </aside>
      </CSSTransition>
    );
  } else {
    return <div></div>;
  }
};

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
    showSidebar: state.app.showUserSidebar,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    closeUserSidebar: () => dispatch(closeUserSidebar()),
    logOut: () => dispatch(logOut()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(Sidebar);
