import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

class RoleRenderer extends React.Component {
  canContentShow = () => {
    const { userInfo, anonymous } = this.props;
    if (userInfo && userInfo.role && userInfo.role.value) {
      if (userInfo.role.value === this.props.role) {
        return true;
      }
      if (!this.props.exact) {
        // Exact is disabled, meaning higher roles are also accepted.
        switch (this.props.role) {
          case "advertiser":
            return ["super-admin"].includes(userInfo.role.value);
          case "sales-rep-view":
            return [
              "advertiser",
              "sales-rep",
              "company-admin",
              "super-admin",
            ].includes(userInfo.role.value);
          case "sales-rep":
            return ["advertiser", "company-admin", "super-admin"].includes(
              userInfo.role.value
            );
          case "company-admin":
            return ["super-admin"].includes(userInfo.role.value);
          default:
            break;
        }
      }
    } else if (anonymous) {
      return true;
    }
    return false;
  };
  render() {
    if (this.canContentShow()) {
      return this.props.children;
    } else if (this.props.noContent) {
      return this.props.noContent();
    }
    return <></>;
  }
}

RoleRenderer.propTypes = {
  role: PropTypes.oneOf([
    "advertiser",
    "sales-rep-view",
    "sales-rep",
    "company-admin",
    "super-admin",
  ]).isRequired,
  exact: PropTypes.bool,
  noContent: PropTypes.func,
};

RoleRenderer.defaultProps = {
  exact: false,
};

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

export default connect(keyStateToProps)(RoleRenderer);
