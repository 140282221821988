import React, { Component } from "react";
import Routes, { createTitle } from "routes";

import PromotionList from "../shared/list";
import PromotionEdit from "./edit";

class BlitzPromotions extends Component {
  componentDidMount() {
    document.title = createTitle(`Blitz Promotions`, Routes);
  }

  render() {
    return (
      <PromotionList
        title="Blitz Featured"
        url="/promotion/type/blitz"
        editor={PromotionEdit}
      />
    );
  }
}

export default BlitzPromotions;
