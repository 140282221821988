import React from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { ReactComponent as TrashIcon } from "images/icons/trash.svg";

function Card({ id, index, image, removeItem }) {
  // const originalIndex = findItem(id)

  // const [{ isDragging }, drag] = useDrag({
  //   item: { type: 'card', id, originalIndex },
  //   collect: monitor => ({
  //     isDragging: monitor.isDragging(),
  //   }),
  //   end: (dropResult, monitor) => {
  //     const { id: droppedId, originalIndex } = monitor.getItem()
  //     const didDrop = monitor.didDrop()
  //     if (!didDrop) {
  //       moveItem(droppedId, originalIndex)
  //     }
  //   },
  // })

  // const [, drop] = useDrop({
  //   accept: 'card',
  //   canDrop: () => false,
  //   hover({ id: draggedId }) {
  //     if (draggedId !== id) {
  //       const overIndex = findItem(id)
  //       moveItem(draggedId, overIndex)
  //     }
  //   },
  // })

  const opacity = 1;

  return (
    <div className="preview-card">
      <Link
        //ref={node => drag(drop(node))}
        to="#"
        className={classNames(
          "image",
          { primary: index === 0 },
          { feature: index > 0 && index < 5 }
        )}
        style={{ opacity }}
      >
        <TrashIcon className="image-trash" onClick={() => removeItem(index)} />
        <div className="image-overlay"></div>
        <img src={image.url} alt={image.name} />
      </Link>
      {index === 0 && <div className="text">Primary</div>}
      {index > 0 && index < 5 && <div className="text">Feature</div>}
    </div>
  );
}

export default Card;
