import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";

import { Formik } from "formik";
import { object, string, mixed } from "yup";

import stage from "../stage";
import PrivateCompanyProfile from "./privateCompanyProfile";
import PublicCompanyProfile from "./publicCompanyProfile";
import Spinner from "components/shared/spinner";
import { getCountries, getStates } from "actions/masterData/actions";

const LOGO_FILE_SIZE = 20 * 1024 * 1024; // 20MB * KB * bytes
const LOGO_SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];

const schema = object().shape({
  fullname: string().required("Full Name is required"),
  website: string()
    .url("This must be a URL, and must include http:// or https://")
    .notRequired(),
  privateCompanyName: string().required("Company Name is required"),
  privateCompanyPhone: string().required("Company Phone is required"),
  privateCompanyAddress1: string().required("Company Address is required"),
  privateCompanyCity: string().required("City is required"),
  privateCompanyZip: string().required("Zip Code is required"),
  privateCompanyCountry: string().required("Country is required"),
  privateCompanyState: string().when("privateCompanyCountry", {
    is: (f) => f === "US",
    then: string().required("State is required"),
  }),
  privateCompanyEmail: string()
    .required("Main Email is required")
    .email("Invalid email"),

  publicCompanyName: string().when("publicCompanyNameCheckbox", {
    is: true,
    then: string().required("Company Name is required"),
  }),
  publicCompanyPhone: string().when("publicCompanyPhoneCheckbox", {
    is: true,
    then: string().required("Company Phone is required"),
  }),
  publicCompanyFax: string().when("publicCompanyFaxCheckbox", {
    is: true,
    then: string().required("Company Fax is required"),
  }),
  publicCompanyAddress1: string().when("publicCompanyAddress1Checkbox", {
    is: true,
    then: string().required("Company Address is required"),
  }),
  publicCompanyCity: string().when("publicCompanyCityCheckbox", {
    is: true,
    then: string().required("City is required"),
  }),
  publicCompanyZip: string().when("publicCompanyZipCheckbox", {
    is: true,
    then: string().required("Zip Code is required"),
  }),
  publicCompanyCountry: string().when("publicCompanyCountryCheckbox", {
    is: true,
    then: string().required("Country is required"),
  }),
  publicCompanyState: string().when("publicCompanyStateCheckbox", {
    is: true,
    then: string().required("State is required"),
  }),
  publicCompanyEmail: string().when("publicCompanyEmailCheckbox", {
    is: true,
    then: string().required("Main Email is required").email("Invalid email"),
  }),
  logo: mixed()
    .notRequired()
    .test("fileSize", "File too large", (value) => {
      if (value && value.size && value.size > LOGO_FILE_SIZE) return false;

      return true;
    })
    .test("fileFormat", "Unsupported Format", (value) => {
      if (value && !LOGO_SUPPORTED_FORMATS.includes(value.type)) {
        return false;
      }
      return true;
    }),
});

class AddCompany extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company: {
        isLoaded: false,
      },
    };

    this.props.getCountries();
    this.props.getStates();
  }

  componentDidMount() {
    const registration = JSON.parse(localStorage.getItem("registration"));

    this.setState({
      company: {
        ...registration.sales.company,
        isLoaded: true,
      },
    });
  }

  handleSubmit(values, setSubmitting) {
    // Submit image to parent state
    if (values.logo) {
      this.props.onLogoChange(values.logo);
    }

    setSubmitting(false);
    this.handleContinue(values);
  }

  handleContinue(values) {
    let registration = JSON.parse(localStorage.getItem("registration"));
    registration.sales.company = values;
    registration.sales.step++;

    localStorage.setItem("registration", JSON.stringify(registration));

    if (this.props.handleChangeStage)
      this.props.handleChangeStage(stage.payment);
  }

  handleGoToBack(values, nextStage) {
    let registration = JSON.parse(localStorage.getItem("registration"));
    registration.stage = nextStage;
    registration.sales.company = values;
    registration.sales.step = 0;

    localStorage.setItem("registration", JSON.stringify(registration));
    this.props.handleChangeStage(nextStage);
  }

  handleLogoChange = (image) => {
    this.props.onLogoChange(image);
  };

  render() {
    const { countries, states } = this.props;
    const { company } = this.state;
    if (company.isLoaded) {
      return (
        <div className="sales-content add-company">
          <Formik
            validationSchema={schema}
            validateOnBlur={true}
            onSubmit={(values, { setSubmitting }) =>
              this.handleSubmit(values, setSubmitting)
            }
            initialValues={{
              fullname: company.fullname || "",
              employedTitle: company.employedTitle || "",
              heard: company.heard || "0",
              heardOther: company.heardOther || "",
              privateCompanyName: company.privateCompanyName || "",
              privateCompanyPhone: company.privateCompanyPhone || "",
              privateCompanyFax: company.privateCompanyFax || "",
              privateCompanyAddress1: company.privateCompanyAddress1 || "",
              privateCompanyAddress2: company.privateCompanyAddress2 || "",
              privateCompanyCity: company.privateCompanyCity || "",
              privateCompanyZip: company.privateCompanyZip || "",
              privateCompanyState: company.privateCompanyState || "",
              privateCompanyCountry: company.privateCompanyCountry || "",
              privateCompanyEmail: company.privateCompanyEmail || "",
              publicCompanyName: company.publicCompanyName || "",
              publicCompanyPhone: company.publicCompanyPhone || "",
              publicCompanyFax: company.publicCompanyFax || "",
              publicCompanyAddress1: company.publicCompanyAddress1 || "",
              publicCompanyAddress2: company.publicCompanyAddress2 || "",
              publicCompanyCity: company.publicCompanyCity || "",
              publicCompanyZip: company.publicCompanyZip || "",
              publicCompanyState: company.publicCompanyState || "",
              publicCompanyCountry: company.publicCompanyCountry || "",
              publicCompanyEmail: company.publicCompanyEmail || "",
              website: company.website || "",
              publicCompanyNameCheckbox:
                company.publicCompanyNameCheckbox || false,
              publicCompanyPhoneCheckbox:
                company.publicCompanyPhoneCheckbox || false,
              publicCompanyFaxCheckbox:
                company.publicCompanyFaxCheckbox || false,
              publicCompanyAddress1Checkbox:
                company.publicCompanyAddress1Checkbox || false,
              publicCompanyAddress2Checkbox:
                company.publicCompanyAddress2Checkbox || false,
              publicCompanyCityCheckbox:
                company.publicCompanyCityCheckbox || false,
              publicCompanyZipCheckbox:
                company.publicCompanyZipCheckbox || false,
              publicCompanyStateCheckbox:
                company.publicCompanyStateCheckbox || false,
              publicCompanyCountryCheckbox:
                company.publicCompanyCountryCheckbox || false,
              publicCompanyEmailCheckbox:
                company.publicCompanyEmailCheckbox || false,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              errors,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form
                onSubmit={
                  isSubmitting
                    ? (e) => {
                        e.preventDefault();
                      }
                    : handleSubmit
                }
              >
                <Row>
                  <Col sm={12} lg={6}>
                    <p className="sub-header">Account Information</p>
                    <p>
                      Please tell us who will be the main point of contact for
                      this account. If you are an individual seller, please
                      provide your personal information in place of company
                      information.
                    </p>
                    <PrivateCompanyProfile
                      handleChange={handleChange}
                      values={values}
                      touched={touched}
                      errors={errors}
                      countries={countries}
                      states={states}
                    />
                  </Col>
                  <Col sm={12} lg={6}>
                    <PublicCompanyProfile
                      handleChange={handleChange}
                      values={values}
                      logo={this.props.logo}
                      onLogoChange={this.handleLogoChange}
                      countries={countries}
                      states={states}
                      touched={touched}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      onBlur={handleBlur}
                    />
                  </Col>
                </Row>
                <div className="buttons">
                  <Button
                    className="btn-green back-button"
                    onClick={() => this.handleGoToBack(values, stage.advertise)}
                  >
                    Back
                  </Button>
                  <Button className="btn-green continue-button" type="submit">
                    Continue
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

const keyStateToProps = (state) => {
  return {
    user: state.user,
    countries: state.masterData.countries,
    states: state.masterData.states,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    getCountries: () => dispatch(getCountries()),
    getStates: () => dispatch(getStates()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(AddCompany);
