import React, { Fragment } from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default (props) => {
  const { company } = props;
  const profile = props.company.profiles
    .filter((p) => p.type.value === "public")
    .shift();

  const addr = [];
  if (profile.address1) addr.push(profile.address1);
  if (profile.address1 && profile.address2) addr.push(<br key="addr-1-br" />);
  if (profile.address2) addr.push(profile.address2);
  if (addr.length > 0) addr.push(<br key="addr-2-br" />);
  if (profile.city) addr.push(profile.city);
  if (profile.city && profile.state) addr.push(",");
  if (profile.state) addr.push(profile.state.value);
  if (profile.zip) addr.push(profile.zip);
  if (profile.country) addr.push(profile.country.title);

  return (
    <header className="company-detail-header detail-header">
      <Container className="pb-5">
        <Row className="mt-4">
          {company.logo && (
            <Col sm={12} md={6} lg={3} className="logo-column mb-4">
              <div className="company-logo">
                <div className="company-logo-centered">
                  <img
                    src={company.logo}
                    alt={`${company.name} Logo`}
                    className="img-fluid"
                  />
                </div>
              </div>
            </Col>
          )}
          <Col sm={12} md lg className="title-column mb-4">
            <h1 className="title company-name mt-0">{company.name}</h1>
            {addr.length > 0 && (
              <div className="sub-title location">
                Located at{" "}
                <address className="bold">
                  {addr.map((l, i) => {
                    if (typeof l === "string")
                      return <Fragment key={i}>{l} </Fragment>;
                    return l;
                  })}
                </address>
              </div>
            )}
          </Col>
          <Col sm={12} md={6} lg={4} className="action-column mb-4">
            <div className="info">
              {profile.phone && (
                <div className="detail-header-info company-phone">
                  Company Phone:{" "}
                  <a href={`tel:${profile.phone}`}>{profile.phone}</a>
                </div>
              )}

              {profile.fax && (
                <div className="detail-header-info company-fax">
                  Fax Number: <a href={`tel:${profile.fax}`}>{profile.fax}</a>
                </div>
              )}
              {profile.email && (
                <div className="detail-header-info sales-email">
                  Main Email:{" "}
                  <a href={`mailto:${profile.email}`}>{profile.email}</a>
                </div>
              )}

              {company.website && (
                <div className="detail-header-info website">
                  Website:{" "}
                  <a
                    href={company.website}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {company.website}
                  </a>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};
