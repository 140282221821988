import React, { Component } from "react";
import { connect } from "react-redux";

import { Formik } from "formik";
import { object, string, ref } from "yup";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { updatePassword } from "actions/user/actions";

class PasswordForm extends Component {
  handleSubmitPassword(
    password,
    newPassword,
    confirmPassword,
    setSubmitting,
    resetForm
  ) {
    const { user } = this.props;
    const accountInfo = {
      password: password,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
      email: user.email,
    };

    this.props.updatePassword(accountInfo).finally(() => {
      setSubmitting(false);
      resetForm();
    });
  }
  render() {
    const { user, editor } = this.props;
    let self = true;
    if (user.email !== editor.email) self = false;
    const schema = {
      confirmPassword: string()
        .required("Confirm Password is required")
        .when("newPassword", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: string().oneOf(
            [ref("newPassword")],
            "Confirm password need to be the same"
          ),
        }),
    };

    const newPasswordSchema = string()
      .required("New Password is required")
      .min(8, "Password has to be longer than 8 characters")
      .matches(/[a-z]/, "at least one lowercase char")
      .matches(/[A-Z]/, "at least one uppercase char")
      .matches(
        /[^a-zA-Z\s]/,
        "at least 1 number or special char (@,!,#, etc)."
      );

    if (!self) {
      // Editing a different user. We don't need the old password
      schema.newPassword = newPasswordSchema;
    } else {
      // Editing myself. Need current password.
      schema.password = string().required("Current Password is required");
      schema.newPassword = newPasswordSchema.when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: string().notOneOf(
          [ref("password")],
          "New Password need to be a different"
        ),
      });
    }

    return (
      <Row>
        <Col xs={12}>
          <Formik
            initialValues={{
              password: "",
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={object().shape(schema)}
            onSubmit={(
              { password, newPassword, confirmPassword },
              { setSubmitting, resetForm }
            ) =>
              this.handleSubmitPassword(
                password,
                newPassword,
                confirmPassword,
                setSubmitting,
                resetForm
              )
            }
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form
                onSubmit={
                  isSubmitting
                    ? (e) => {
                        e.preventDefault();
                      }
                    : handleSubmit
                }
                className="password-form"
              >
                <div>
                  <Form.Group
                    className="password-form-group"
                    controlId="password"
                  >
                    <Form.Label>Password:</Form.Label>
                    <Form.Label className="form-label-star">
                      **********
                    </Form.Label>
                  </Form.Group>
                  {self && (
                    <Form.Group
                      className="password-form-group"
                      controlId="password"
                    >
                      <Form.Label>Current Password:</Form.Label>
                      <div>
                        <Form.Control
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          type="password"
                          isInvalid={touched.password && !!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </div>
                    </Form.Group>
                  )}

                  <Form.Group
                    className="password-form-group"
                    controlId="newPassword"
                  >
                    <Form.Label>New Password:</Form.Label>
                    <div>
                      <Form.Control
                        name="newPassword"
                        value={values.newPassword}
                        onChange={handleChange}
                        type="password"
                        isInvalid={touched.newPassword && !!errors.newPassword}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.newPassword}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                  <Form.Group
                    className="password-form-group"
                    controlId="confirmPassword"
                  >
                    <Form.Label>Repeat New Password:</Form.Label>
                    <div>
                      <Form.Control
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleChange}
                        type="password"
                        isInvalid={
                          touched.confirmPassword && !!errors.confirmPassword
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword}
                      </Form.Control.Feedback>
                    </div>
                  </Form.Group>
                </div>
                <div className="password-form-save">
                  <button
                    type="submit"
                    className="btn btn-tertiary password-form-save-button"
                  >
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    );
  }
}

const keyStateToProps = (state) => {
  return {};
};
const keyDispatchToProps = (dispatch) => {
  return {
    updatePassword: (accountInfo) => dispatch(updatePassword(accountInfo)),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(PasswordForm);
