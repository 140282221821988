import React, { Component } from "react";
import axios from "axios";

import Slider from "react-slick";
import FeaturedSlide from "components/frontend/pages/home/featured-slide";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { ReactComponent as PreviousArrowIcon } from "images/icons/arrow-left.svg";
import { ReactComponent as NextArrowIcon } from "images/icons/arrow-right.svg";

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      data-role="none"
      className={className}
      onClick={onClick}
      style={style}
    >
      <PreviousArrowIcon />
    </button>
  );
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      type="button"
      data-role="none"
      className={className}
      onClick={onClick}
      style={style}
    >
      <NextArrowIcon />
    </button>
  );
}

export default class FeaturedCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      results: [],
      error: undefined,
    };
  }

  componentDidMount() {
    axios
      .get(`promotions/homepage`)
      .then((response) => {
        const results = response.data.data;

        this.setState({
          isLoading: false,
          error: undefined,
          results: results,
        });
      })
      .catch((error) => {
        this.setState({
          isLoading: false,
          results: [],
          error: error,
        });
      });
  }

  Slideshow = () => {
    const { results } = this.state;
    const settings = {
      className: "center",
      autoplay: false,
      centerMode: true,
      infinite: true,
      slidesToShow: 1,
      speed: 500,
      autoplaySpeed: 3000,
      cssEase: "ease-in-out",
      dots: true,
      dotsClass: "slick-dots slick-thumb",
      customPaging: function (i) {
        return <a href="#num">{i + 1}</a>;
      },
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
    };
    return (
      <Slider {...settings}>
        {results.map((p, i) => (
          <FeaturedSlide key={`promo-${i}`} promo={p} />
        ))}
      </Slider>
    );
  };

  render() {
    if (!this.state.isLoading && !this.state.error) {
      return this.Slideshow();
    }
    return <></>;
  }
}
