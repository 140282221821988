import React from 'react';
import PropTypes from 'prop-types';

import 'rc-steps/assets/index.css';
import 'rc-steps/assets/iconfont.css';
import Steps, { Step } from 'rc-steps';

export default function Stepper({data, currentStep, onChangeStep}) {
  return (
    <Steps
      labelPlacement="vertical"
      icons={{finish: <Icon />}}
      current={currentStep}
      onChange={onChangeStep ? (val) => onChangeStep(val) : null}
    >
      {
        data.map((item, i) => <Step key={i} description={item} />)
      }
    </Steps>
  );
}

Stepper.propTypes = {
  data: PropTypes.array.isRequired,
  currentStep: PropTypes.number.isRequired,
  onChangeStep: PropTypes.func
};

function Icon() {
  return (
    <svg fill="#ffffff" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M31.52,4.36a2,2,0,0,1,0,2.58L11.8,27.31a2,2,0,0,1-2.59,0L.48,17.93a2,2,0,0,1,0-2.58,2,2,0,0,1,2.59,0l7.76,8.08L29.25,4.36A1.25,1.25,0,0,1,31.52,4.36Z"/></svg>
  );
}