import React, { useState, useEffect } from "react";
import axios from "axios";
import Routes, { createTitle } from "routes";
import { handleAxiosError } from "components/shared/errors";

import Spinner from "components/shared/spinner";
import classNames from "classnames";

import Removed from "components/frontend/listings/_shared/removed";

import SaleHeader from "components/frontend/listings/for-sale/shared/header";
import SaleInformation from "components/frontend/listings/for-sale/shared/information";

import GetInTouch from "components/frontend/listings/_shared/contact";
import ImageCarousel from "components/shared/imageCarousel";

import Disclaimer from "components/frontend/listings/for-sale/shared/disclaimer";
import AirframeInformation from "components/frontend/listings/for-sale/shared/airframe";
import AuxilliaryPower from "components/frontend/listings/for-sale/shared/auxilliaryPower";
import EngineInformation from "components/frontend/listings/for-sale/shared/engine";
import PropellerInformation from "components/frontend/listings/for-sale/shared/propeller";
import MaintenanceInformation from "components/frontend/listings/for-sale/shared/maintenance";
import AvionicsInformation from "components/frontend/listings/for-sale/shared/avionics";
import AdditionalFeatures from "components/frontend/listings/for-sale/shared/additional";
import WeightsInformation from "components/frontend/listings/for-sale/shared/weights";
import InteriorInformation from "components/frontend/listings/for-sale/shared/interior";
import ExteriorInformation from "components/frontend/listings/for-sale/shared/exterior";

import SimilarListings from "components/frontend/listings/_shared/similar";

export default (props) => {
  const { match } = props;

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [results, setResults] = useState();
  const [similar, setSimilar] = useState();

  useEffect(() => {
    setLoading(true);
    document.title = createTitle("Loading...", Routes);

    axios
      .get(`/listings/slug/${match.params.listing}`)
      .then((response) => {
        if (response.data.data) {
          const listing = response.data.data;
          // We got data! Display it
          setError();
          setResults(listing);
          setSimilar(response.data.similar);

          // Update Page Title
          switch (response.data.data.status.value) {
            case "removed":
              document.title = createTitle("No Longer Available", Routes);
              break;
            case "sold":
              document.title = createTitle(`SOLD! ${listing.title}`, Routes);
              break;
            case "draft":
              document.title = createTitle(`[DRAFT] ${listing.title}`, Routes);
              break;
            case "published":
            default:
              document.title = createTitle(listing.title, Routes);
              break;
          }
        } else {
          // Something went wrong?
          setError("Unknown error loading listing");
        }
      })
      .catch((error) => {
        setSimilar(error.response.data.similar);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [match.params.listing]);

  const drawListing = (listing) => {
    let listingMode = listing.level.value;
    if (listingMode !== "featured") {
      // Set display type to Subscriber or Basic/Free
      if (
        listing.company &&
        listing.company.plan &&
        listing.company.plan.rank &&
        listing.company.plan.rank > 0
      ) {
        listingMode = "subscriber";
      } else {
        listingMode = "basic";
      }
    }

    let reorderedImages = [];
    if (
      listing.images &&
      Array.isArray(listing.images) &&
      listing.images.length > 0
    ) {
      reorderedImages = listing.images.slice();
      const firstImage = reorderedImages.shift();
      reorderedImages.push(firstImage);
    }

    return (
      <div
        id="sale-detail-results"
        className={classNames("listings", "listings-results", "results")}
      >
        <section
          className={classNames(
            "app-main",
            "listing-detail",
            "for-sale-detail",
            listing.saleDetails.model.type.value,
            `${listingMode}-detail`,
            `${listingMode}-listing`
          )}
        >
          <SaleHeader listing={listing} />
          {listing.status.value === "sold" && similar && (
            <SimilarListings similar={similar} count={3} />
          )}
          <SaleInformation listing={listing} />

          <ImageCarousel images={reorderedImages} />
          {listing.status.value !== "sold" && (
            <div className="detail-content">
              <Disclaimer />
              <AirframeInformation listing={listing.saleDetails} />
              <EngineInformation listing={listing.saleDetails} />
              <AuxilliaryPower listing={listing.saleDetails} />
              <PropellerInformation listing={listing.saleDetails} />
              <MaintenanceInformation listing={listing.saleDetails} />
              <AvionicsInformation listing={listing.saleDetails} />
              <AdditionalFeatures listing={listing.saleDetails} />
              <WeightsInformation listing={listing.saleDetails} />
              <InteriorInformation listing={listing.saleDetails} />
              <ExteriorInformation listing={listing.saleDetails} />
            </div>
          )}

          {listing.status.value !== "sold" && (
            <>
              <GetInTouch listing={listing} />
              {listingMode === "basic" && similar && (
                <SimilarListings similar={similar} count={6} />
              )}
            </>
          )}
        </section>
      </div>
    );
  };

  const content = (loading, error, listing) => {
    if (error) {
      if (error.response && error.response.status === 410) {
        // Listing was removed
        return <Removed similar={similar} />;
      }
      return handleAxiosError(error);
    }

    if (loading) {
      return (
        <div
          id="sale-detail-loading"
          className={classNames("listings", "listings-loading", "results")}
        >
          <Spinner />
        </div>
      );
    }

    // No error and done loading. Show the listing!
    return drawListing(listing);
  };

  return content(loading, error, results);
};
