import React from "react";
import { connect } from "react-redux";

import EditableInput from "../shared/editableInput";

import Form from "react-bootstrap/Form";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { addLogo } from "actions/companies/actions";

function EditCompanyProfileBlock(props) {
  const { profile, countries, states } = props;

  const handleValueChange = (value) => {
    if (props.onChange) props.onChange(value);
  };
  console.log("profile",props);
  return (
    <div
      className={`profile-fields ${profile.type.value}-profile-fields ${profile.type.value}-profile`}
    >
      {props.before && props.before()}
      <Row>
        <Col xs={12} sm={6}>
          <EditableInput
            label="Contact Name"
            value={profile.contact || ""}
            onChange={(value) => {
              handleValueChange({ contact: value });
            }}
          />
        </Col>
        <Col xs={12} sm={6}>
          <EditableInput
            label="Contact Email"
            value={profile.email || ""}
            onChange={(value) => handleValueChange({ email: value })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <EditableInput
            label="Phone"
            value={profile.phone || ""}
            onChange={(value) => handleValueChange({ phone: value })}
          />
        </Col>
        <Col xs={12} sm={6}>
          <EditableInput
            label="Fax"
            value={profile.fax || ""}
            onChange={(value) => handleValueChange({ fax: value })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <EditableInput
            label="Address 1"
            value={profile.address1 || ""}
            onChange={(value) => handleValueChange({ address1: value })}
          />
        </Col>
        <Col xs={12} sm={6}>
          <EditableInput
            label="Address 2"
            value={profile.address2 || ""}
            onChange={(value) => handleValueChange({ address2: value })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <EditableInput
            label="Country"
            value={profile.country ? profile.country.title : ""}
            onChange={() => {}}
            renderInput={({ setEdit, setValue }) => (
              <Form.Control
                as="select"
                value={profile.country ? profile.country.value : ""}
                onChange={(e) => {
                  const newCountry = countries
                    .filter((c) => c.value === e.target.value)
                    .shift();
                  handleValueChange({
                    country: newCountry ? newCountry : "null",
                  });
                  setValue(newCountry ? newCountry.title : "");
                  setEdit(false);
                }}
              >
                <option>No Country</option>
                {countries.map((opt, i) => (
                  <option key={i} value={opt.value}>
                    {opt.title}
                  </option>
                ))}
              </Form.Control>
            )}
          />
        </Col>
        <Col xs={12} sm={6}>
          {profile.country &&
            profile.country.value &&
            profile.country.value === "US" && (
              <EditableInput
                label="State"
                value={profile.state ? profile.state.title : ""}
                onChange={() => {}}
                renderInput={({ setEdit, setValue }) => (
                  <Form.Control
                    as="select"
                    value={profile.state ? profile.state.value : ""}
                    onChange={(e) => {
                      const newState = states
                        .filter((s) => s.value === e.target.value)
                        .shift();
                      handleValueChange({
                        state: newState,
                      });
                      setValue(newState ? newState.title : "");
                      setEdit(false);
                    }}
                  >
                    <option></option>
                    {states.map((opt, i) => (
                      <option key={i} value={opt.value}>
                        {opt.title}
                      </option>
                    ))}
                  </Form.Control>
                )}
              />
            )}
        </Col>
      </Row>
      <Row>
        <Col xs={12} sm={6}>
          <EditableInput
            label="City"
            value={profile.city || ""}
            onChange={(value) => handleValueChange({ city: value })}
          />
        </Col>

        <Col xs={12} sm={6}>
          <EditableInput
            label="Zip"
            value={profile.zip || ""}
            onChange={(value) => handleValueChange({ zip: value })}
          />
        </Col>
      </Row>
      {props.after && props.after(profile, handleValueChange)}
    </div>
  );
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
    countries: state.masterData.countries,
    states: state.masterData.states,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    addLogo: (slug, logo) => dispatch(addLogo(slug, logo)),
  };
};

export default connect(
  keyStateToProps,
  keyDispatchToProps
)(EditCompanyProfileBlock);
