import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { generatePath } from "react-router-dom";
import classNames from "classnames";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "components/shared/spinner";

import Routes from "routes";
import { addLogo, upgradePlan } from "actions/companies/actions";

import { openMessageModal, closeMessageModal } from "actions/modal/actions";

import { getStates, getCountries } from "actions/masterData/actions";
import TabRoutes from "components/shared/tabRoutes";
import { parseAxiosError } from "utils/errors";

import EditPublicProfile from "./profilePublic";
import EditPrivateProfile from "./profilePrivate";
import EditBillingProfile from "./profileBilling";
import EditCompanyReps from "./salesReps";
import EditPlan from "./upgradeContent";

import { ReactComponent as PublicIcon } from "images/icons/eye.svg";
import { ReactComponent as PrivateIcon } from "images/icons/shield.svg";
import { ReactComponent as BillingIcon } from "images/icons/dollar.svg";
import { ReactComponent as RepsIcon } from "images/icons/users.svg";
import { ReactComponent as BriefcaseIcon } from "images/icons/briefcase.svg";

function EditCompany(props) {
  const {
    userInfo,
    match,
    history,
    getCountries,
    getStates,
    addLogo,
    upgradePlan,
    openMessageModal,
    closeMessageModal,
  } = props;

  console.log(props);

  const [company, setCompany] = useState();
  const [profiles, setProfiles] = useState([]);
  const [reps, setReps] = useState([]);
  const [error, setError] = useState();
  const [slug, setSlug] = useState();

  if (userInfo.role && userInfo.role.value && !slug) {
    if (userInfo.role.value === "super-admin") {
      setSlug(match.params.company);
    } else {
      setSlug(userInfo.company.slug);
    }
  }

  useEffect(() => {
    getCountries();
    getStates();
    if (slug) {
      axios
        .get(`/company/${slug}`)
        .then((response) => {
          const { data } = response.data;
          setCompany(data);
          setProfiles(data.profiles);
          setReps(data.accounts);
        })
        .catch((error) => {
          setError(parseAxiosError(error));
        });
    } else {
      setError("Unknown Company");
    }
  }, [slug, getCountries, getStates]);

  const handleCompanyChange = (value) => {
    const prevCompany = company;
    setCompany(undefined);

    axios
      .put(`/company/${slug}`, value)
      .then((response) => {
        const { data } = response.data;
        if (data.slug !== company.slug) {
          // Slug has changed. Redirect!
          history.push(
            generatePath(Routes.companyEdit, {
              company: data.slug,
              tab: undefined,
            })
          );
        }
        setSlug(data.slug);
        setCompany(data);
      })
      .catch((error) => {
        setCompany(prevCompany);
      });
  };

  const handleProfileChange = (profile, value) => {
    const prevProfiles = profiles;
    const thisProfile = profiles
      .filter((p) => p.type.value === profile.type.value)
      .shift();
    const otherProfiles = profiles.filter(
      (p) => p.type.value !== profile.type.value
    );

    setProfiles([...otherProfiles, { ...thisProfile, ...value }]);

    axios
      .put(`/company/${slug}`, {
        profiles: [
          {
            type: profile.type.value,
            ...value,
          },
        ],
      })
      .catch((error) => {
        setProfiles(prevProfiles);
      });
  };

  const handleLogoChange = (file) => {
    if (file) {
      addLogo(slug, file)
        .then((res) => {
          if (res.logo) {
            setCompany({ ...company, logo: res.logo });
          }
        })
        .catch((err) => {
          if (openMessageModal) {
            openMessageModal(`Error Uploading Logo: ${err}.`, () => (
              <button
                onClick={() => {
                  closeMessageModal();
                }}
              >
                Okay
              </button>
            ));
          }
        });
    }
  };

  const handlePlanChange = (plan) => {
    upgradePlan(slug, plan).then((data) => {
      setCompany(data);
    });
  };

  const content = () => {
    if (error) {
      return (
        <div
          id={"company-detail-error"}
          className={classNames(
            "company-detail",
            "company-edit",
            "company-detail-error"
          )}
        >
          <Container className="py-5">
            <Row>
              <Col>
                <h1 className="listings-title">{error}</h1>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
    if (company && profiles.length > 0) {
      const tabs = [
        {
          url: generatePath(Routes.companyEdit, {
            company: slug,
            tab: undefined,
          }),
          title: "Public",
          icon: PublicIcon,
          component: () => (
            <EditPublicProfile
              profile={profiles
                .filter((p) => p.type.value === "public")
                .shift()}
              company={company}
              onCompanyChange={handleCompanyChange}
              onLogoChange={handleLogoChange}
              onProfileChange={handleProfileChange}
            />
          ),
        },
        {
          url: generatePath(Routes.companyEdit, {
            company: slug,
            tab: "private",
          }),
          title: "Private",
          icon: PrivateIcon,
          component: () => (
            <EditPrivateProfile
              profile={profiles
                .filter((p) => p.type.value === "private")
                .shift()}
              onProfileChange={handleProfileChange}
            />
          ),
        },
        {
          url: generatePath(Routes.companyEdit, {
            company: slug,
            tab: "plan",
          }),
          title: "Plan",
          icon: BriefcaseIcon,
          component: () => (
            <EditPlan
              company={company}
              editor={userInfo}
              onSubmit={handlePlanChange}
            />
          ),
        },
        {
          url: generatePath(Routes.companyEdit, {
            company: slug,
            tab: "billing",
          }),
          title: "Billing",
          icon: BillingIcon,
          component: () => (
            <EditBillingProfile
              company={company}
              profile={profiles
                .filter((p) => p.type.value === "public")
                .shift()}
              onProfileChange={handleProfileChange}
            />
          ),
        },
        {
          url: generatePath(Routes.companyEdit, {
            company: slug,
            tab: "reps",
          }),
          icon: RepsIcon,
          title: "Sales Reps",
          component: () => <EditCompanyReps reps={reps} />,
        },
      ];
      return (
        <div
          id={`${slug}-detail`}
          className={classNames(
            "company-detail",
            "company-edit",
            "company-detail-results"
          )}
        >
          <section className="app-main listing-detail company-detail">
            <Container className="py-5">
              <TabRoutes tabs={tabs} />
            </Container>
          </section>
        </div>
      );
    }

    return (
      <div
        id={`company-detail-loading`}
        className={classNames(
          "company-detail",
          "company-edit",
          "company-detail-loading"
        )}
      >
        <Spinner animation="border" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </div>
    );
  };

  return (
    <section
      id="app-company-settings"
      className="app-content app-main company-settings"
    >
      <Container>
        <Row mb={4}>
          <Col xs={12} md>
            <h1 className="title">
              COMPANY <span className="bold">SETTINGS</span>
            </h1>
          </Col>
        </Row>
      </Container>
      {content()}
    </section>
  );
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    addLogo: (slug, logo) => dispatch(addLogo(slug, logo)),
    openMessageModal: (message, actions) =>
      dispatch(openMessageModal({ message, actions })),
    closeMessageModal: () => dispatch(closeMessageModal()),
    getCountries: () => dispatch(getCountries()),
    getStates: () => dispatch(getStates()),
    upgradePlan: (slug, plan) => dispatch(upgradePlan(slug, plan)),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(EditCompany);
