import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { generatePath } from "react-router-dom";
import Routes from "routes";

import ImagePlaceholder from "images/listings/placeholder.png";

const SimilarItem = (props) => {
  const { listing } = props;

  const imageCss = listing.thumbnail
    ? { backgroundImage: "url(" + listing.thumbnail + ")" }
    : {};

  let link;
  let listingType;
  switch (listing.type.value) {
    case "sale":
    default:
      listingType = "for-sale-listing";
      link = generatePath(Routes.forSaleDetail, {
        listing: listing.slug,
      });
      break;
  }

  return (
    <div
      className={classNames(
        "list-item",
        "listing-item",
        "similar-item",
        "similar-listing",
        listingType,
        listing.saleDetails && listing.saleDetails.model.type.value
      )}
    >
      <div className="listing-image image" style={imageCss}>
        <Link to={link}>
          <img src={ImagePlaceholder} alt="" className="img-fluid" />
        </Link>
      </div>
      <Row>
        <Col>
          <Link to={link} className="listing-title title">
            {listing.title}
          </Link>
        </Col>
        <Col xs="auto" className="text-right">
          <Link to={link} className="btn btn-green listing-link listing-button">
            View Listing
          </Link>
        </Col>
      </Row>
    </div>
  );
};

export default SimilarItem;
