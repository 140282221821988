import { SET_TOAST } from "actions/toast/actionTypes";

const initialState = {
  type: "",
  message: "",
  createdAt: "",
};

export default function toastReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case SET_TOAST:
      return Object.assign({}, payload, {
        createdAt: Date.now(),
      });
    default:
      return state;
  }
}
