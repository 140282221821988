import React from "react";
import PropTypes from "prop-types";
import Pagination from "react-bootstrap/Pagination";

const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";
const PREV_PAGE = "PREV";
const NEXT_PAGE = "NEXT";

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
	let i = from;
	const range = [];

	while (i <= to) {
		range.push(i);
		i += step;
	}

	return range;
};

class Pager extends React.Component {
	constructor(props) {
		super(props);

		this.state = { currentPage: this.props.currentPage || 1 };

		this.fetchPageNumbers = this.fetchPageNumbers.bind(this);
		this.calculateTotalPages = this.calculateTotalPages.bind(this);
		this.calculatePageLimit = this.calculatePageLimit.bind(this);
		this.calculateTotalRecords = this.calculateTotalRecords.bind(this);
		this.calculatePageNeighbors = this.calculatePageNeighbors.bind(this);
		this.gotoPage = this.gotoPage.bind(this);
		this.handleClick = this.handleClick.bind(this);
		this.handlePrev = this.handlePrev.bind(this);
		this.handleNext = this.handleNext.bind(this);
		this.handleLeft = this.handleLeft.bind(this);
		this.handleRight = this.handleRight.bind(this);

		//this.componentDidUpdate();
	}

	componentDidMount() {
		//this.gotoPage(this.props.currentPage);
	}

	/**
	 * Let's say we have 10 pages and we set pageNeighbours to 2
	 * Given that the current page is 6
	 * The pagination control will look like the following:
	 *
	 * (1) < {4 5} [6] {7 8} > (10)
	 *
	 * (x) => terminal pages: first and last page(always visible)
	 * [x] => represents current page
	 * {...x} => represents page neighbours
	 */
	fetchPageNumbers = () => {
		const totalPages = this.calculateTotalPages();
		const currentPage = this.state.currentPage;
		const pageNeighbours = this.calculatePageNeighbors();

		/**
		 * totalNumbers: the total page numbers to show on the control
		 * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
		 */
		const totalNumbers = pageNeighbours * 2 + 3;
		const totalBlocks = totalNumbers + 2;

		if (totalPages > totalBlocks) {
			const startPage = Math.max(2, currentPage - pageNeighbours);
			const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);

			let pages = range(startPage, endPage);

			/**
			 * hasLeftSpill: has hidden pages to the left
			 * hasRightSpill: has hidden pages to the right
			 * spillOffset: number of hidden pages either to the left or to the right
			 */
			const hasLeftSpill = startPage > 2;
			const hasRightSpill = totalPages - endPage > 1;
			const spillOffset = totalNumbers - (pages.length + 1);

			switch (true) {
				// handle: (1) < {5 6} [7] {8 9} (10)
				case hasLeftSpill && !hasRightSpill: {
					const extraPages = range(startPage - spillOffset, startPage - 1);
					pages = [LEFT_PAGE, PREV_PAGE, ...extraPages, ...pages];
					break;
				}

				// handle: (1) {2 3} [4] {5 6} > (10)
				case !hasLeftSpill && hasRightSpill: {
					const extraPages = range(endPage + 1, endPage + spillOffset);
					pages = [...pages, ...extraPages, NEXT_PAGE, RIGHT_PAGE];
					break;
				}

				// handle: (1) < {4 5} [6] {7 8} > (10)
				case hasLeftSpill && hasRightSpill:
				default: {
					pages = [LEFT_PAGE, PREV_PAGE, ...pages, NEXT_PAGE, RIGHT_PAGE];
					break;
				}
			}

			return [1, ...pages, totalPages];
		}

		return range(1, totalPages);
    };
    calculatePageNeighbors() {
        return typeof this.props.pageNeighbours === "number" ? Math.max(0, Math.min(this.props.pageNeighbours, 2)) : 0;
    }
    calculateTotalRecords() {
        return typeof this.props.totalRecords === "number" ? this.props.totalRecords : 0;
    }
    calculatePageLimit() {
        return typeof this.props.pageLimit === "number" ? this.props.pageLimit : 10;
    }

	calculateTotalPages() {
		const pageLimit = this.calculatePageLimit();
		const totalRecords = this.calculateTotalRecords();
		return Math.ceil(totalRecords / pageLimit);
	}

	gotoPage = page => {
        const { onPageChanged = f => f } = this.props;

        const totalPages = this.calculateTotalPages();
		const currentPage = Math.max(0, Math.min(page, totalPages));

		const paginationData = {
			currentPage,
			totalPages: totalPages,
			pageLimit: this.calculatePageLimit(),
			totalRecords: this.calculateTotalRecords()
		};

		this.setState({ currentPage }, () => onPageChanged(paginationData));
	};

	handleClick = page => evt => {
		evt.preventDefault();
		this.gotoPage(page);
	};

	handleLeft = evt => {
        evt.preventDefault();
        const pageNeighbours = this.calculatePageNeighbors();
		this.gotoPage(this.state.currentPage - pageNeighbours * 2 - 1);
	};

	handleRight = evt => {
        evt.preventDefault();
        const pageNeighbours = this.calculatePageNeighbors();
		this.gotoPage(this.state.currentPage + pageNeighbours * 2 + 1);
	};

	handlePrev = evt => {
		evt.preventDefault();
		this.gotoPage(this.state.currentPage - 1);
	};

	handleNext = evt => {
		evt.preventDefault();
		this.gotoPage(this.state.currentPage + 1);
	};

	render() {
		if (!this.props.totalRecords || this.calculateTotalPages() === 0) return null;

		const { currentPage } = this.state;
		const pages = this.fetchPageNumbers();
		return (
			<Pagination>
				{pages.map((page, index) => {
					if (page === LEFT_PAGE) return <Pagination.First key={index} onClick={this.handleLeft} />;
					if (page === RIGHT_PAGE) return <Pagination.Last key={index} onClick={this.handleRight} />;
					if (page === PREV_PAGE) return <Pagination.Prev key={index} onClick={this.handlePrev} />;
					if (page === NEXT_PAGE) return <Pagination.Next key={index} onClick={this.handleNext} />;

					return (
						<Pagination.Item key={index} active={currentPage === page} onClick={this.handleClick(page)}>
							{page}
						</Pagination.Item>
					);
				})}
			</Pagination>
		);
	}
}

Pager.propTypes = {
	totalRecords: PropTypes.number.isRequired,
	pageLimit: PropTypes.number,
	pageNeighbours: PropTypes.number,
	currentPage: PropTypes.number,
	onPageChanged: PropTypes.func
};

export default Pager;