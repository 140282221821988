import React from "react";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import ReactBreakpoints from "react-breakpoints";
import Routes, {
  UserRoute,
  SalesRepViewRoute,
  SalesRepRoute,
  CompanyAdminRoute,
  SuperAdminRoute,
} from "./routes";

// Errors
import { ErrorBoundary } from "components/shared/errors";
import UnauthorizedPage from "components/frontend/pages/401";
import ForbiddenPage from "components/frontend/pages/403";
import NotFoundPage from "components/frontend/pages/404";
import Legacy from "legacy";

// Layout
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import Sidebar from "./components/layout/sidebar/sidebar";
import CookiesNotice from "./components/layout/cookies";

// Frontent Pages
import HomePage from "components/frontend/pages/home";
import AdvertisePage from "components/frontend/pages/advertise";
import PricingPage from "components/frontend/pages/pricing";
import SubmittedListingsPage from "./components/backend/promotions/new/submitted-listings";
import PrivacyPage from "components/frontend/pages/privacy";
import TermsPage from "components/frontend/pages/terms";

// Frontent Listings
import ForSale from "components/frontend/listings/for-sale/index";
import ForSaleDetail from "components/frontend/listings/for-sale/detail";
import Wanted from "components/frontend/listings/wanted/index";
import WantedDetail from "components/frontend/listings/wanted/detail";
import Services from "components/frontend/listings/services/index";
import ServicesDetail from "components/frontend/listings/services/detail";
import Company from "components/frontend/company/index";

// Account
import Login from "components/frontend/account/login";
import Signup from "components/frontend/account/signup/signup";
import ForgotPassword from "components/frontend/account/password/forgotPassword";
import ResetPassword from "components/frontend/account/password/resetPassword";
import Verification from "components/frontend/account/verification/verification";
import Registration from "components/frontend/account/registration/index";

// Contact
import ContactForm from "components/frontend/listings/contactForm";
import MessageModal from "components/shared/messageModal";
import NewsletterModal from "components/shared/newsletterModal";

// Backend & Admin
import DashboardPage from "components/backend/dashboard";
import NotificationPage from "components/backend/notification";
import SiteSettings from "components/backend/siteSettings/index";
import FavoritesManage from "components/backend/favorites/index";
import MySavedSearches from "components/backend/mySavedSearches/mySavedSearches";
import InboxPage from "components/backend/inbox";
import InboxArchivedPage from "components/backend/inbox/archive";

import PromotionsManage from "components/backend/promotions/index";
import PromotionsNew from "components/backend/promotions/new/index";

// Backend Listings
import ForSaleManage from "components/backend/listings/sale/index";
import ForSaleArchive from "components/backend/listings/sale/archive";
import ForSaleEdit from "components/backend/listings/sale/edit";
import ForSaleStatus from "components/backend/listings/sale/status";
import WantedManage from "components/backend/listings/wanted/index";
import WantedArchive from "components/backend/listings/wanted/archive";
import WantedEdit from "components/backend/listings/wanted/edit";
import WantedStatus from "components/backend/listings/wanted/status";
import ServicesManage from "components/backend/listings/services/index";
import ServicesArchive from "components/backend/listings/services/archive";
import ServicesEdit from "components/backend/listings/services/edit";
import ServicesStatus from "components/backend/listings/services/status";

// Backend Companies
import CompanyManage from "components/backend/company/index";
import CompanyAdd from "components/backend/company/add";
import CompanyEdit from "components/backend/company/edit";

// Backend Users & Accounts
import UsersManage from "components/backend/users/index";
import UsersAdd from "components/backend/users/add";
// import CompanyEdit from "components/backend/company/edit";
import AccountSettings from "components/backend/accountSettings/accountSettings";

// Spinner
import Spinner from "components/shared/spinner";

// Scroll to Top
import ScrollToTop from "components/shared/scrollToTop";

import "./App.scss";

const HeaderComponent = withRouter((props) => <Header {...props} />);
const ErrorBoundaryComponent = withRouter((props) => (
  <ErrorBoundary {...props} />
));

const breakpoints = {
  forMobile: 0,
  forMobileUp: 576,
  forTabletPortraitUp: 768,
  forTabletLandscapeUp: 992,
  forDesktopUp: 1200,
  forBigDesktopUp: 1900,
};

class App extends React.Component {
  componentDidUpdate(prevProps) {
    if (prevProps.toast !== this.props.toast) {
      this.notify(this.props.toast);
    }
  }

  notify(msg) {
    if (msg.type === "success") {
      toast.success(msg.message, {
        containerId: "notify",
      });
    } else if (msg.type === "error") {
      toast.error(msg.message, {
        containerId: "notify",
      });
    }
  }

  render() {
    if (this.props.appReady) {
      return (
        <div className="App app">
          <ReactBreakpoints breakpoints={breakpoints}>
            <Router>
              <ScrollToTop />
              <div className="content-wrapper app-wrapper wrapper">
                <HeaderComponent />
                <ErrorBoundaryComponent>
                  <Switch>
                    <Route path={Routes.home} exact component={HomePage} />
                    <UserRoute
                      path={Routes.admin}
                      exact
                      component={DashboardPage}
                    />
                    <Route
                      path={Routes.advertise}
                      component={AdvertisePage}
                      exact
                    />
                    <Route path={Routes.pricing} component={PricingPage} />
                    <Route path={Routes.privacy} component={PrivacyPage} />
                    <Route path={Routes.terms} component={TermsPage} />

                    <Route
                      path={Routes.forSaleDetail}
                      component={ForSaleDetail}
                    />
                    <Route path={Routes.forSale} exact component={ForSale} />
                    <Route
                      path={Routes.wantedDetail}
                      component={WantedDetail}
                    />
                    <Route path={Routes.wanted} exact component={Wanted} />
                    <Route
                      path={Routes.servicesDetail}
                      component={ServicesDetail}
                    />
                    <Route path={Routes.services} exact component={Services} />

                    <Route
                      path={Routes.companyWanted}
                      exact
                      component={Company}
                    />
                    <Route
                      path={Routes.companyServices}
                      exact
                      component={Company}
                    />
                    <Route path={Routes.company} component={Company} />

                    <SalesRepRoute
                      path={Routes.promotionsNew}
                      component={PromotionsNew}
                    />
                    <SuperAdminRoute
                      path={Routes.promotions}
                      component={PromotionsManage}
                    />
                    <SalesRepRoute
                      path={Routes.promotionsSubmitted}
                      component={SubmittedListingsPage}
                    />

                    <UserRoute
                      path={Routes.inboxArchived}
                      component={InboxArchivedPage}
                      exact
                    />
                    <UserRoute
                      path={Routes.inbox}
                      component={InboxPage}
                      exact
                    />
                    <UserRoute
                      path={Routes.inboxArchived}
                      component={InboxArchivedPage}
                    />
                    {/* Backend listings */}
                    <SalesRepViewRoute
                      path={Routes.forSaleManage}
                      exact
                      component={ForSaleManage}
                    />
                    <SalesRepViewRoute
                      path={Routes.forSaleArchive}
                      exact
                      component={ForSaleArchive}
                    />
                    <SalesRepRoute
                      path={Routes.forSaleEdit}
                      exact
                      component={ForSaleEdit}
                    />
                    <SalesRepRoute
                      path={Routes.forSaleStatus}
                      exact
                      component={ForSaleStatus}
                    />

                    <SalesRepViewRoute
                      path={Routes.wantedManage}
                      exact
                      component={WantedManage}
                    />
                    <SalesRepViewRoute
                      path={Routes.wantedArchive}
                      exact
                      component={WantedArchive}
                    />
                    <SalesRepRoute
                      path={Routes.wantedEdit}
                      exact
                      component={WantedEdit}
                    />
                    <SalesRepRoute
                      path={Routes.wantedStatus}
                      exact
                      component={WantedStatus}
                    />

                    <SalesRepViewRoute
                      path={Routes.servicesManage}
                      exact
                      component={ServicesManage}
                    />
                    <SalesRepViewRoute
                      path={Routes.servicesArchive}
                      exact
                      component={ServicesArchive}
                    />
                    <SalesRepRoute
                      path={Routes.servicesEdit}
                      exact
                      component={ServicesEdit}
                    />
                    <SalesRepRoute
                      path={Routes.servicesStatus}
                      exact
                      component={ServicesStatus}
                    />

                    <UserRoute
                      path={Routes.notifications}
                      component={NotificationPage}
                    />
                    <SuperAdminRoute
                      path={Routes.siteSettings}
                      component={SiteSettings}
                    />

                    <CompanyAdminRoute
                      path={Routes.companyManage}
                      exact
                      component={CompanyManage}
                    />

                    <SuperAdminRoute
                      path={Routes.companyAdd}
                      exact
                      component={CompanyAdd}
                    />

                    <CompanyAdminRoute
                      path={Routes.companyEdit}
                      exact
                      component={CompanyEdit}
                    />

                    <SuperAdminRoute
                      path={Routes.usersAdd}
                      exact
                      component={UsersAdd}
                    />
                    <SuperAdminRoute
                      path={Routes.usersManage}
                      exact
                      component={UsersManage}
                    />

                    <UserRoute
                      path={Routes.accountSettings}
                      exact
                      component={AccountSettings}
                    />
                    <UserRoute
                      path={Routes.favorites}
                      exact
                      component={FavoritesManage}
                    />
                    <UserRoute
                      path={Routes.mySavedSearches}
                      exact
                      component={MySavedSearches}
                    />

                    <Route path={Routes.forbidden} component={ForbiddenPage} />
                    <Route path={Routes.login} component={UnauthorizedPage} />

                    {/* Accounts */}
                    <Route
                      path={Routes.verification}
                      component={Verification}
                    />
                    <Route
                      path={Routes.registration}
                      component={Registration}
                    />
                    <Route
                      path={Routes.resetPassword}
                      component={ResetPassword}
                    />

                    <Route path="/index.php" component={Legacy} />
                    <Route component={NotFoundPage} />
                  </Switch>
                </ErrorBoundaryComponent>
                <Footer />
                <CookiesNotice />
              </div>
              <Sidebar ref={this.sidebarRef} />

              <Login />
              <ForgotPassword />
              <Signup />
              <ContactForm />
              <MessageModal />
              <NewsletterModal />
            </Router>
            <ToastContainer
              containerId="notify"
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
          </ReactBreakpoints>
        </div>
      );
    } else {
      return <Spinner />;
    }
  }
}

const keyStateToProps = (state) => {
  return {
    appReady: state.app.appReady,
    toast: state.toast,
  };
};

export default connect(keyStateToProps)(App);
