
import React, { useRef, useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
// import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
// import {Underline} from '@ckeditor/ckeditor5-basic-styles/src/underline';




export default function RichTextEditor(props) {
  const { name, value, onChange } = props;
  const [content, setContent] = useState(value);
  const editorRef = useRef();

  const handleChange = (event, editor) => {
    const data = editor.getData();
    setContent(data);
    onChange(data, name);
  };

  useEffect(() => {
    if (editorRef.current && value !== editorRef.current.getData()) {
      // Set initial content in the CKEditor
      const editorInstance = editorRef.current;
      editorInstance.setData(value);
      setContent(value);
    }
  }, [value]);

  return (
    <CKEditor
      editor={ClassicEditor}
      data={content}
      onChange={handleChange}
      onReady={(editor) => {
        editorRef.current = editor;
      }}
      onInit={(editor)=>{

      }}
      // config={{
      //   // plugins: [
      //   //   Underline,
      //   //   // ... other plugins ...
      //   // ],
      //   toolbar: [
      //     'undo', 'redo', '|',
      //     'bold', 'italic', '|',
      //     'underline','|',
      //     'blockQuote', 'indent', '|',
      //     'link', '|',
      //     'bulletedList', 'numberedList'
      //   ],
      // }}
    />
  );
}