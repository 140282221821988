import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

export default class FilterControl extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value
    };

    this.handleChange = this.handleChange.bind(this);
  }
  handleChange = evt => {
    this.setState({ value: evt.target.value });
  };

  controlPrepend = (id, prepend) => {
    if (prepend) {
      return (
        <InputGroup.Prepend>
          <InputGroup.Text id={`filter-control-${id}-prepend`}>
            {prepend}
          </InputGroup.Text>
        </InputGroup.Prepend>
      );
    }
    return <></>;
  };
  controlAppend = (id, append) => {
    if (append) {
      return (
        <InputGroup.Append>
          <InputGroup.Text id={`filter-control-${id}-append`}>
            {append}
          </InputGroup.Text>
        </InputGroup.Append>
      );
    }
    return <></>;
  };

  describedBy = props => {
    if (this.props.prepend) {
      return `filter-control-${props.id}-prepend`;
    } else if (this.props.append) {
      return `filter-control-${props.id}-append`;
    }
    return null;
  };
  render() {
    return (
      <Form.Group controlId={this.props.id} className="filter-control">
        <Form.Label>{this.props.label}</Form.Label>
        <InputGroup>
          {this.controlPrepend(this.props.id, this.props.prepend)}
          <Form.Control
            name={this.props.name}
            type={this.props.type || "text"}
            onChange={e => this.handleChange(e)}
            onBlur={this.props.onBlur}
            value={this.state.value}
            aria-describedby={this.describedBy(this.props)}
          />
          {this.controlAppend(this.props.id, this.props.append)}
          <Form.Control.Feedback type="invalid">
            Please only enter numbers
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
    );
  }
}
