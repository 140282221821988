import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Select from "react-dropdown-select";
import ReactPaginate from 'react-paginate';

class Pagination extends Component {
  render() {
    const { pageValues, perPage, pageCount, selectedPage, onChangePerPageLimit, handlePageClick } = this.props;

    return (
      <div className="pagination-control">
        <div className="pagination-control__select">
          <div className="perpage">View:</div>
          <Select
            values={[{label: perPage+" per page", value: perPage}]}
            searchable={false}
            options={pageValues}
            labelField="label"
            valueField="value"
            onChange={(value) => onChangePerPageLimit(value)}
            className="select-control"
            style={{
              minWidth: '100px'
            }}
          />
        </div>
        <div className="pagination-control__pagination">
          <ReactPaginate
            previousLabel={'<'}
            nextLabel={'>'}
            breakClassName={'break-me'}
            pageCount={pageCount}
            marginPagesDisplayed={0}
            pageRangeDisplayed={4}
            onPageChange={handlePageClick}
            containerClassName={'pagination'}
            subContainerClassName={'pages pagination'}
            activeClassName={'active'}
            forcePage={selectedPage}
          />
        </div>
      </div>
    );
  }
}

Pagination.propTypes = {
  handlePageClick: PropTypes.func.isRequired,
  onChangePerPageLimit: PropTypes.func.isRequired,
  pageValues: PropTypes.array.isRequired,
  perPage: PropTypes.number.isRequired,
  pageCount: PropTypes.number.isRequired,
  selectedPage: PropTypes.node.isRequired,
};

export default Pagination;
