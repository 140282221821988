import React from "react";
import { connect } from "react-redux";

import EditableInput from "../shared/editableInput";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { openMessageModal, closeMessageModal } from "actions/modal/actions";

import ToggleSwitch from "components/shared/toggleSwitch";

function EditCompanySalesRepBlock(props) {
  const { rep, accountRoles } = props;

  const {
    onRepChange,
    onRoleChange,
    onStatusChange,
    openMessageModal,
    closeMessageModal,
  } = props;

  const handleActiveToggle = (checked) => {
    onStatusChange && onStatusChange(rep.email, checked);
    if (!checked) {
      openMessageModal(
        "All active listings assigned to this user are still live. Please go to My Listings to edit Sales Rep information to reassign inquiry notification emails.",
        () => (
          <>
            <Button
              className="btn btn-white"
              onClick={() => {
                closeMessageModal();
              }}
            >
              Confirm
            </Button>
          </>
        )
      );
    }
  };

  return (
    <Row>
      <Col xs={12} sm="auto" className="mt-3">
        <ToggleSwitch
          onLabel="Active"
          offLabel="Inactive"
          width={120}
          checked={rep.status.value === "active"}
          onChange={handleActiveToggle}
        />
      </Col>
      <Col>
        <EditableInput
          label="Name"
          value={rep.profile.name || ""}
          onChange={(value) =>
            onRepChange(rep.email, { profile: { name: value } })
          }
        />
        <EditableInput
          label="Email"
          value={rep.email || ""}
          onChange={(value) => onRepChange(rep.email, { email: value })}
        />
        <EditableInput
          label="Phone"
          value={rep.profile.phone || ""}
          onChange={(value) =>
            onRepChange(rep.email, { profile: { phone: value } })
          }
        />
        <EditableInput
          label="Role"
          value={rep.role ? rep.role.title : ""}
          onChange={() => {}}
          renderInput={({ setEdit, setValue }) => (
            <Form.Control
              as="select"
              value={rep.role ? rep.role.value : ""}
              onChange={(e) => {
                const newRole = accountRoles
                  .filter((r) => r.value === e.target.value)
                  .shift();
                onRoleChange(rep.email, { role: newRole });
                setValue(newRole ? newRole.title : "");
                setEdit(false);
              }}
            >
              {accountRoles
                .filter((r) =>
                  ["company-admin", "sales-rep", "sales-rep-view"].includes(
                    r.value
                  )
                )
                .sort((a, b) => (a.title > b.title ? 1 : -1))
                .map((opt, i) => (
                  <option key={i} value={opt.value}>
                    {opt.title}
                  </option>
                ))}
            </Form.Control>
          )}
        />
      </Col>
    </Row>
  );
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
    accountRoles: state.masterData.accountRoles,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    openMessageModal: (message, actions) =>
      dispatch(openMessageModal({ message, actions })),
    closeMessageModal: () => dispatch(closeMessageModal()),
  };
};

export default connect(
  keyStateToProps,
  keyDispatchToProps
)(EditCompanySalesRepBlock);
