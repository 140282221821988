import React from "react";
import axios from "axios";
import { Formik } from "formik";

import { Button, Row, Col, Form, Table } from "react-bootstrap";
import RichTextEditor from "components/shared/richEditor";
import Spinner from "components/shared/spinner";
import CommonInput from "components/backend/listings/fields/commonInput";
import SaveButtonText from "components/backend/listings/fields/saveButtonText";
import stripHTML from "utils/stripHTML";
import { connect } from "react-redux";

const yup = require("yup");
const NUMERIC_REGEXP = /^\d*\.?\d*$/;

class Step3 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      error: undefined,
      engineMaintenances: [],
      apuMaintenances: [],
    };
  }

  componentDidMount = () => {
    const proms = [];
    proms.push(
      axios
        .get("/masterData?keys=engineMaintenance,apuMaintenance")
        .then((response) => {
          const masterData = response.data.rows;
          this.setState({
            engineMaintenances: masterData
              .filter((data) => data.key === "engineMaintenance")
              .sort((a, b) => (a.title > b.title ? 1 : -1)),
            apuMaintenances: masterData
              .filter((data) => data.key === "apuMaintenance")
              .sort((a, b) => (a.title > b.title ? 1 : -1)),
          });
        })
    );

    Promise.all(proms)
      .then(() => {
        // Finished Loading
        this.setState({ isLoading: false, error: undefined });
      })
      .catch((err) => {
        this.setState({ isLoading: false, error: err });
      })
      .finally(() => {
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 500);
      });
  };

  handleValidation = (values) => {};

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.props.onChange(name, value);
  };

  handleIntChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (value === undefined || value === "" || isNaN(value)) {
      value = null;
    } else {
      value = parseInt(value.match(NUMERIC_REGEXP).join(""));
    }

    this.props.onChange(name, value);
  };

  handleFloatChange = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    if (value === undefined || value === "" || isNaN(value)) {
      value = null;
    } else {
      value = value.match(NUMERIC_REGEXP).join("");
    }

    this.props.onChange(name, value);
  };

  handleSingleCheckbox = (e) => {
    this.props.onChange(e.target.name, e.currentTarget.checked);
  };

  textEditorHandler = (value, name) => {
    let cleanedValue = stripHTML(value).trim();
    if (!cleanedValue) {
      value = null;
    }

    this.props.onChange(name, value);
  };

  sanitizeInt = (value) => {
    if (value === undefined || value === "" || isNaN(value)) {
      value = null;
    } else {
      value = parseInt(value);
    }

    return value;
  };
  sanitizeFloat = (value) => {
    if (value === undefined || value === "" || isNaN(value)) {
      value = null;
    } else {
      value = parseFloat(value);
    }

    return value;
  };

  handleEngineChange = (seq, name, value) => {
    let engines = this.props.values.engines || [];
    const thisEngine = engines
      .filter((engine) => engine.engineSeq === seq)
      .shift();
    if (thisEngine) {
      // This engine is already present in the array. Map and update it.
      engines = engines.map((engine) => {
        if (engine.engineSeq === seq) {
          // Update this field name and value
          engine = { ...engine, [name]: value };
        }
        return engine;
      });
    } else {
      // This engine is not in the array yet. Add it.
      engines.push({
        engineSeq: seq,
        [name]: value,
      });
    }

    this.props.onChange("engines", engines);
  };

  getEngineValue = (seq, name) => {
    let engines = this.props.values.engines || [];
    const thisEngine = engines
      .filter((engine) => engine.engineSeq === seq)
      .shift();

    if (
      thisEngine &&
      thisEngine[name] !== undefined &&
      thisEngine[name] !== null
    ) {
      return thisEngine[name];
    }
    return "";
  };

  render() {
    const schema = yup.object().shape({
      apuTotalTime: yup
        .number("Total Time can only include numbers and decimals")
        .notRequired(),
      //make: yup.string().required("A Make must be selected"),
      //modelId: yup.number().required("A Model must be selected")
    });

    if (this.state.error) {
      return (
        <div id="step-2-error" className="stepper-error step-2-error error">
          <h1 className="listings-title">{this.state.error.message}</h1>
          <p>{this.ErrorMessage(this.state.error)}</p>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div
          id="step-2-loading"
          className="stepper-loading step-2-loading loading"
        >
          <Spinner />
        </div>
      );
    } else {
      return (
        <Formik
          validationSchema={schema}
          //onSubmit={values => this.handleSubmit(values)}
          onSubmit={this.handleValidation}
          initialValues={{
            apuTotalTime: this.props.values.apuTotalTime || "",
          }}
          //validateOnChange={true}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            isSubmitting,
            setSubmitting,
            validateForm,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <div className="stepper_2">
                <Form.Label className="subHeader">
                  Prepare for takeoff
                </Form.Label>
                <Form.Row>
                  <Form.Group className="d-flex" as={Col} sm={5}>
                    <Form.Label> Engine(s) Model: </Form.Label>
                    <CommonInput
                      as="input"
                      type="text"
                      name="engineManufacturer"
                      value={this.props.values.engineManufacturer || ""}
                      placeholder="XXXX"
                      handleChange={this.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="d-flex" as={Col} sm={5}>
                    <Form.Label>Engine Maintenance Plan:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="engineMaintenance"
                      value={this.props.values.engineMaintenance || ""}
                    >
                      <option></option>
                      {this.state.engineMaintenances.map((opt, i) => (
                        <option key={i} value={opt.value}>
                          {opt.title}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Col className="table-layout">
                    <Table striped bordered hover className="">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Engine 1</th>
                          <th>Engine 2</th>
                          <th>Engine 3</th>
                          <th>Engine 4</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Total Time:</td>
                          <td className="p-0">
                            <Form.Control
                              name="totalTime"
                              value={this.getEngineValue(1, "totalTime")}
                              onChange={(e) => {
                                this.handleEngineChange(
                                  1,
                                  e.target.name,
                                  this.sanitizeFloat(e.target.value)
                                );
                              }}
                              type="number"
                              pattern="[0-9]*"
                              placeholder="XXXX"
                              className="w-100"
                            />
                          </td>
                          <td className="p-0">
                            <Form.Control
                              name="totalTime"
                              value={this.getEngineValue(2, "totalTime")}
                              onChange={(e) => {
                                this.handleEngineChange(
                                  2,
                                  e.target.name,
                                  this.sanitizeFloat(e.target.value)
                                );
                              }}
                              type="number"
                              pattern="[0-9]*"
                              placeholder="XXXX"
                              className="w-100"
                            />
                          </td>
                          <td className="p-0">
                            <Form.Control
                              name="totalTime"
                              value={this.getEngineValue(3, "totalTime")}
                              onChange={(e) => {
                                this.handleEngineChange(
                                  3,
                                  e.target.name,
                                  this.sanitizeFloat(e.target.value)
                                );
                              }}
                              type="number"
                              pattern="[0-9]*"
                              placeholder="XXXX"
                              className="w-100"
                            />
                          </td>
                          <td className="p-0">
                            <Form.Control
                              name="totalTime"
                              value={this.getEngineValue(4, "totalTime")}
                              onChange={(e) => {
                                this.handleEngineChange(
                                  4,
                                  e.target.name,
                                  this.sanitizeFloat(e.target.value)
                                );
                              }}
                              type="number"
                              pattern="[0-9]*"
                              placeholder="XXXX"
                              className="w-100"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Cycles:</td>
                          <td className="p-0">
                            <Form.Control
                              name="cycles"
                              value={this.getEngineValue(1, "cycles")}
                              onChange={(e) => {
                                this.handleEngineChange(
                                  1,
                                  e.target.name,
                                  this.sanitizeInt(e.target.value)
                                );
                              }}
                              type="number"
                              pattern="[0-9]*"
                              placeholder="XXXX"
                              className="w-100"
                            />
                          </td>
                          <td className="p-0">
                            <Form.Control
                              name="cycles"
                              value={this.getEngineValue(2, "cycles")}
                              onChange={(e) => {
                                this.handleEngineChange(
                                  2,
                                  e.target.name,
                                  this.sanitizeInt(e.target.value)
                                );
                              }}
                              type="number"
                              pattern="[0-9]*"
                              placeholder="XXXX"
                              className="w-100"
                            />
                          </td>
                          <td className="p-0">
                            <Form.Control
                              name="cycles"
                              value={this.getEngineValue(3, "cycles")}
                              onChange={(e) => {
                                this.handleEngineChange(
                                  3,
                                  e.target.name,
                                  this.sanitizeInt(e.target.value)
                                );
                              }}
                              type="number"
                              pattern="[0-9]*"
                              placeholder="XXXX"
                              className="w-100"
                            />
                          </td>
                          <td className="p-0">
                            <Form.Control
                              name="cycles"
                              value={this.getEngineValue(4, "cycles")}
                              onChange={(e) => {
                                this.handleEngineChange(
                                  4,
                                  e.target.name,
                                  this.sanitizeInt(e.target.value)
                                );
                              }}
                              type="number"
                              pattern="[0-9]*"
                              placeholder="XXXX"
                              className="w-100"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Time Since Major:</td>
                          <td className="p-0">
                            <Form.Control
                              name="timeSinceMajor"
                              value={this.getEngineValue(1, "timeSinceMajor")}
                              onChange={(e) => {
                                this.handleEngineChange(
                                  1,
                                  e.target.name,
                                  this.sanitizeFloat(e.target.value)
                                );
                              }}
                              type="number"
                              pattern="[0-9]*"
                              placeholder="XXXX"
                              className="w-100"
                            />
                          </td>
                          <td className="p-0">
                            <Form.Control
                              name="timeSinceMajor"
                              value={this.getEngineValue(2, "timeSinceMajor")}
                              onChange={(e) => {
                                this.handleEngineChange(
                                  2,
                                  e.target.name,
                                  this.sanitizeFloat(e.target.value)
                                );
                              }}
                              type="number"
                              pattern="[0-9]*"
                              placeholder="XXXX"
                              className="w-100"
                            />
                          </td>
                          <td className="p-0">
                            <Form.Control
                              name="timeSinceMajor"
                              value={this.getEngineValue(3, "timeSinceMajor")}
                              onChange={(e) => {
                                this.handleEngineChange(
                                  3,
                                  e.target.name,
                                  this.sanitizeFloat(e.target.value)
                                );
                              }}
                              type="number"
                              pattern="[0-9]*"
                              placeholder="XXXX"
                              className="w-100"
                            />
                          </td>
                          <td className="p-0">
                            <Form.Control
                              name="timeSinceMajor"
                              value={this.getEngineValue(4, "timeSinceMajor")}
                              onChange={(e) => {
                                this.handleEngineChange(
                                  4,
                                  e.target.name,
                                  this.sanitizeFloat(e.target.value)
                                );
                              }}
                              type="number"
                              pattern="[0-9]*"
                              placeholder="XXXX"
                              className="w-100"
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Time Since Mid:</td>
                          <td className="p-0">
                            <Form.Control
                              name="timeSinceMid"
                              value={this.getEngineValue(1, "timeSinceMid")}
                              onChange={(e) => {
                                this.handleEngineChange(
                                  1,
                                  e.target.name,
                                  this.sanitizeFloat(e.target.value)
                                );
                              }}
                              type="number"
                              pattern="[0-9]*"
                              placeholder="XXXX"
                              className="w-100"
                            />
                          </td>
                          <td className="p-0">
                            <Form.Control
                              name="timeSinceMid"
                              value={this.getEngineValue(2, "timeSinceMid")}
                              onChange={(e) => {
                                this.handleEngineChange(
                                  2,
                                  e.target.name,
                                  this.sanitizeFloat(e.target.value)
                                );
                              }}
                              type="number"
                              pattern="[0-9]*"
                              placeholder="XXXX"
                              className="w-100"
                            />
                          </td>
                          <td className="p-0">
                            <Form.Control
                              name="timeSinceMid"
                              value={this.getEngineValue(3, "timeSinceMid")}
                              onChange={(e) => {
                                this.handleEngineChange(
                                  3,
                                  e.target.name,
                                  this.sanitizeFloat(e.target.value)
                                );
                              }}
                              type="number"
                              pattern="[0-9]*"
                              placeholder="XXXX"
                              className="w-100"
                            />
                          </td>
                          <td className="p-0">
                            <Form.Control
                              name="timeSinceMid"
                              value={this.getEngineValue(4, "timeSinceMid")}
                              onChange={(e) => {
                                this.handleEngineChange(
                                  4,
                                  e.target.name,
                                  this.sanitizeFloat(e.target.value)
                                );
                              }}
                              type="number"
                              pattern="[0-9]*"
                              placeholder="XXXX"
                              className="w-100"
                            />
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                </Form.Row>

                <Form.Row>
                  <Form.Group className="d-flex" as={Col} md={6} lg={4}>
                    <Form.Check
                      custom
                      name="engineOnCondition"
                      id="checkbox-engine-on-condition"
                      type="checkbox"
                      label="Engines On-Condition"
                      onChange={this.handleSingleCheckbox}
                      checked={this.props.values.engineOnCondition || false}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Group className="w-100">
                  <Form.Label>Additional Engine Information:</Form.Label>
                  <RichTextEditor
                    name="engineAdditional"
                    value={this.props.values.engineAdditional || ""}
                    onChange={this.textEditorHandler}
                  />
                </Form.Group>
                <Form.Row>
                  <Form.Group className="d-flex" as={Col} md={4}>
                    <Form.Label>Auxiliary Power Unit Model:</Form.Label>
                    <CommonInput
                      as="input"
                      type="text"
                      name="apu"
                      value={this.props.values.apu || ""}
                      placeholder="XXXX"
                      handleChange={this.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="d-flex" as={Col} md={5}>
                    <Form.Label>APU Maintenance Plan:</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={this.handleChange}
                      name="apuMaintenance"
                      value={this.props.values.apuMaintenance || ""}
                    >
                      <option></option>
                      {this.state.apuMaintenances.map((opt, i) => (
                        <option key={i} value={opt.value}>
                          {opt.title}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="d-flex" as={Col} md={3}>
                    <Form.Label> APU Total Time: </Form.Label>
                    <CommonInput
                      as="input"
                      type="text"
                      name="apuTotalTime"
                      value={this.props.values.apuTotalTime || ""}
                      placeholder="XXXX"
                      handleChange={this.handleFloatChange}
                    />
                  </Form.Group>
                </Form.Row>
                <Form.Group className="w-100">
                  <Form.Label>Propeller:</Form.Label>
                  <RichTextEditor
                    name="propeller"
                    value={this.props.values.propeller || ""}
                    onChange={this.textEditorHandler}
                  />
                </Form.Group>
                <Row className="stepper_btns">
                  <Col md="auto">
                    <Button
                      type="submit"
                      className="btn-red m-b-15"
                      disabled={isSubmitting}
                      onClick={(e) => {
                        handleSubmit(e);
                        if (isValid) {
                          this.props.onNext().then(() => {
                            setSubmitting(false);
                          });
                        } else {
                          setSubmitting(false);
                        }
                      }}
                    >
                      On to Maintenance
                    </Button>
                  </Col>
                  <Col md="auto">
                    <Button
                      className="btn-green"
                      disabled={isSubmitting}
                      onClick={(e) => {
                        handleSubmit(e);
                        if (isValid) {
                          this.props.onSave().then(() => {
                            setSubmitting(false);
                          });
                        } else {
                          setSubmitting(false);
                        }
                      }}
                    >
                      {SaveButtonText(this.props.values.status)}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          )}
        </Formik>
      );
    }
  }
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

export default connect(keyStateToProps)(Step3);
