import React from "react";
import { connect } from "react-redux";

import { Row, Col, Form } from "react-bootstrap";

import RoleRenderer from "components/shared/roleRenderer";

const PromoteHeader = (props) => {
  const {
    listingTypes,
    listingType,
    listings,
    listing,
    companies,
    company,
    reps,
    rep,
  } = props;
  const { onChange } = props;

  const handleChange = (name, value) => {
    if (onChange) {
      onChange(name, value);
    }
  };

  const companyDropdown = () => {
    if (companies) {
      return (
        <Form.Group className="d-flex col col-auto mr-4">
          <Form.Label className="mr-2">Company:</Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => {
              handleChange("company", e.target.value);
            }}
            name="company"
            value={company ? company.slug : ""}
          >
            <option></option>
            {companies.map((opt, i) => (
              <option key={i} value={opt.slug}>
                {opt.name}
              </option>
            ))}
          </Form.Control>
        </Form.Group>
      );
    } else if (company) {
      return (
        <Form.Group className="d-flex col col-auto mr-4">
          <Form.Label>
            Company: <span className="text-green">{company.name}</span>
          </Form.Label>
        </Form.Group>
      );
    }

    return;
  };

  const companySubscriptionLabel = () => {
    if (company && company.plan) {
      return <span className="text-green">{company.plan.title}</span>;
    }
    return (
      <span className="text-gray">
        <em>Unknown</em>
      </span>
    );
  };

  const repDropdown = () => {
    const theseReps = company ? company.accounts : reps;
    if (theseReps) {
      // Map List
      const options = theseReps.map((opt, i) => (
        <option key={i} value={opt.email}>
          {opt.profile.name}
        </option>
      ));
      options.unshift(<option key="select-rep">Select a Rep</option>);
      return (
        <Form.Group className="d-flex col col-auto">
          <Form.Label>Sales Rep: </Form.Label>
          <Form.Control
            as="select"
            onChange={(e) => {
              handleChange("account", e.target.value);
            }}
            name="account"
            value={rep ? rep.email : ""}
          >
            {options}
          </Form.Control>
        </Form.Group>
      );
    }
    return;
  };

  const listingsDropdown = (listingType) => {
    if (listings) {
      // Map List
      const options = listings.map((opt, i) => (
        <option key={i} value={opt.slug}>
          {opt.title}
        </option>
      ));
      return [
        <option key="select-listing">Select a Listing</option>,
        ...options,
      ];
    } else {
      return <option disabled>Select a Type first</option>;
    }
  };

  return (
    <div className={"promote-new-head"}>
      <Row>
        <Col>
          <h1 className={"title mb-4"}>
            Promote &nbsp;<strong>Listings</strong>
          </h1>
        </Col>
      </Row>
      <RoleRenderer role="company-admin">
        <Row>
          <Col className="mb-4">
            <h6 className={"sm-title"}>Select a sales rep and a listing</h6>
          </Col>
        </Row>
        <Row>
          {companyDropdown()}

          {company && company.plan && (
            <Form.Group className="d-flex col col-auto mr-4">
              <Form.Label>
                Subscription: {companySubscriptionLabel()}
              </Form.Label>
            </Form.Group>
          )}

          {repDropdown()}
        </Row>
      </RoleRenderer>

      {rep && (
        <Row>
          <Form.Group className="d-flex col col-auto mr-4">
            <Form.Label className="mr-2">Type:</Form.Label>
            <Form.Control
              as="select"
              onChange={(e) => {
                const type = e.target.value;
                if (onChange) {
                  onChange("listingType", type);
                }
              }}
              name="listingType"
              value={listingType || ""}
            >
              <option></option>
              {listingTypes.map((opt, i) => (
                <option key={i} value={opt.value}>
                  {opt.title}
                </option>
              ))}
            </Form.Control>
          </Form.Group>

          {listingType && listings && (
            <Form.Group className="d-flex col col-auto mr-4">
              <Form.Label className="mr-2">Listings:</Form.Label>
              <Form.Control
                as="select"
                onChange={(e) => {
                  const listingSlug = e.target.value;
                  if (onChange) {
                    onChange("listing", listingSlug);
                  }
                }}
                name="listing"
                value={listing ? listing.slug : ""}
              >
                {listingsDropdown(listingType)}
              </Form.Control>
            </Form.Group>
          )}
        </Row>
      )}
    </div>
  );
};

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {};
};

export default connect(keyStateToProps, keyDispatchToProps)(PromoteHeader);
