import React, { Component } from "react";
import { Link } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ListingLink from "utils/links";
import FeaturedListingPlaceholder from "images/content/homepage/featured-listing-placeholder.png";

export default class FeaturedSlide extends Component {
  render() {
    const { promo } = this.props;

    let imageStyle;
    if (promo.images && promo.images.length > 0) {
      imageStyle = {
        backgroundImage: `url('${promo.images.slice().shift().url}')`,
      };
    }

    let view = {};
    if (promo.company) {
      view = promo.company.profiles
        .filter((p) => p.type.value === "public")
        .shift();

      const locations = [];
      if (view.city) locations.push(view.city);
      if (view.state) locations.push(view.state.value);
      if (view.country) locations.push(view.country.value);
      view.location = locations.length > 0 ? locations.join(", ") : false;
    }

    return (
      <Row id={this.props.id} className="featured-slide-type">
        <Col xs={12} md={6} className="featured-image-col" style={imageStyle}>
          <img
            src={FeaturedListingPlaceholder}
            className="img-fluid"
            alt="featured-listing"
          />
        </Col>
        <Col xs={12} md={6} className="featured-content-col">
          <div className="featured-content">
            <h3 className="featured-title">
              FEATURED: <strong>{promo.title}</strong>
            </h3>
            <p className="featured-description">{promo.subtitle}</p>
            <Row className="featured-company-row">
              {promo.company && promo.company.logo && (
                <Col xs={12} md={6} className="featured-company-logo-col">
                  <div className="company-logo">
                    <div className="company-logo-centered">
                      <img
                        src={promo.company.logo}
                        className="img-fluid"
                        alt={`${promo.company.name} Logo`}
                      />
                    </div>
                  </div>
                </Col>
              )}

              <Col xs={12} md={6} className="featured-company-data-col">
                {promo.listing && (
                  <div className="featured-type">
                    {promo.listing.type.title}
                  </div>
                )}
                {view.location && (
                  <div className="featured-location">
                    Located in {view.location}
                  </div>
                )}

                {view.phone && (
                  <div className="featured-phone">
                    <strong>Phone:</strong> {view.phone}
                  </div>
                )}

                {promo.company && promo.company.website && (
                  <div className="featured-website">
                    <strong>Website:</strong> {promo.company.website}
                  </div>
                )}
              </Col>
            </Row>
            <div className="featured-read-more-row">
              <Link
                to={ListingLink(promo.listing)}
                className="btn btn-primary featured-button"
              >
                Read More
              </Link>
            </div>
          </div>
        </Col>
      </Row>
    );
  }
}
