import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import filterFactory from "react-bootstrap-table2-filter";


class TableLayout extends React.Component {
  handleTableChange = (type, { filters, sortField, sortOrder }) => {
    switch (type) {
      case "sort":
        this.handleSort(sortField, sortOrder);
        break;
      default:
        break;
    }
  };

  handleSort = (sort, order) => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma"
    });
    const updatedObj = {
      ...currentParams,
      sort,
      order
    };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma"
    });
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString()
    });
  };

  render() {
    return (
      <BootstrapTable
        {...this.props}
        striped
        remote={{ sort: true, filter: true }}
        onTableChange={this.handleTableChange}
        filter={filterFactory()}
        rowClasses="item-row"
      />
    );
  }
}

export default withRouter(TableLayout);
