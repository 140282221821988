import React, { useState } from "react";
import { Modal, Button, Form, Row, Col, Table } from "react-bootstrap";
import PropTypes from "prop-types";
import LogoRed from "images/plane-mover-logo-red.png";


const CookieSettingPopup = ({ show, onClose, afterSave }) => {
    const [analyticsCookies, setAnalyticsCookies] = useState(false);
    const [advertisingCookies, setAdvertisingCookies] = useState(false);
    const [necessaryCookies, setNecessaryCookies] = useState(true);
    const [cookiesdiscription, setCookiesdiscription] = useState([]);

    const handleSaveSettings = () => {
        const settings = {
            analyticsCookies,
            advertisingCookies,
            necessaryCookies
        };
        document.cookie = `cookieSettings=${encodeURIComponent(JSON.stringify(settings))}; path=/; expires=${new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toUTCString()}`;
        onClose();
        afterSave();
    };
    const handleAllowAll = () => {
        console.log("Enter in handleAllowAll");
        setAnalyticsCookies(true);
        setAdvertisingCookies(true);
        handleSaveSettings();
    };
    const handleCookieClick = (cookieType) => {
        if (cookiesdiscription.includes(cookieType)) {
            setCookiesdiscription(cookiesdiscription.filter(cookie => cookie !== cookieType));
        } else {
            setCookiesdiscription([...cookiesdiscription, cookieType]);
        }
        
    }
    return (
        <Modal show={show} onHide={onClose} centered className="privacy-prefer-modal">
            <Modal.Header closeButton>
                <Modal.Title> <img
                    id="app-header-logo-desktop"
                    src={LogoRed}
                    alt="Plane Mover"
                    className="app-logo img-fluid d-none d-md-block"
                /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>Privacy Preference Center</h4>
                <p> When you visit any website, it may store or retrieve information on your browser, mostly in the form of cookies. This information might be about you, your preferences, or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category headings to find out more and change our default settings. However, blocking some types of cookies may impact your experience of the site and the services we are able to offer.</p>
                <Button color="success" onClick={handleAllowAll}>
                    Allow All
                </Button>

                <Form>
                    <p  style={{ margin: "10px" }}>Manage Consent preferences</p>
                    <Table borderless>
                        <tbody>
                            <tr style={{ border: "1px solid #e9ecef", borderRadius: "8px", padding: "20px" }} onClick={() => handleCookieClick("analytics")}>
                                <p style={{ margin: "14px" }}><b>{cookiesdiscription.includes("analytics")? "-" : "+"} </b> Analytics Cookies</p>
                                {cookiesdiscription.includes("analytics") && (
                                    <p style={{ margin: "14px" }}>These cookies allow us to track analytics by counting visits and traffic sources so we can measure and improve the performance of our website. They may be set by us or by third party providers to help us to
                                        know which pages are the most and least popular and see how visitors move around the website.</p>
                                )}
                                <td className="text-right">
                                    <Form.Check
                                        type="switch"
                                        id="analytics-cookies-switch"
                                        checked={analyticsCookies}
                                        onChange={(e) => setAnalyticsCookies(e.target.checked)}
                                        style={{ marginBottom: "10px" }}
                                    />
                                </td>
                            </tr>
                            <tr style={{ border: "1px solid #e9ecef", borderRadius: "8px", padding: "20px" }} onClick={() => handleCookieClick("advertising")}>
                                <p style={{ margin: "14px" }}><b>{cookiesdiscription.includes("advertising")? "-" : "+"} </b> Advertising Cookies</p>
                                {cookiesdiscription.includes("advertising") && (
                                    <p style={{ margin: "14px" }}>These cookies may be set through our site by our advertising partners. They may be used by those partners to build a profile of your interests and show you relevant advertisements on other websites.</p>
                                )}
                                <td className="text-right">
                                    <Form.Check
                                        type="switch"
                                        id="advertising-cookies-switch"
                                        checked={advertisingCookies}
                                        onChange={(e) => setAdvertisingCookies(e.target.checked)}
                                        style={{ marginBottom: "10px" }}
                                    />
                                </td>
                            </tr>
                            <tr style={{ border: "1px solid #e9ecef", borderRadius: "8px", padding: "20px" }} onClick={() => handleCookieClick("sricklyNecessary")}>
                                <p style={{ margin: "14px" }}><b>{cookiesdiscription.includes("sricklyNecessary")? "-" : "+"} </b>Strickly Necessary Cookies</p>
                                {cookiesdiscription.includes("sricklyNecessary") && (
                                    <p style={{ margin: "14px" }}>These cookies are necessary for the website to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.</p>
                                )}
                                <td>
                                    <p className="text-right text-nowrap " style={{ color: "blue" }}>Always Active</p>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Form>
                <div className="w-100 d-flex justify-content-end">
                    <Button color="success" onClick={handleSaveSettings} style={{ marginTop: "20px" }}>
                        Confirm my choice
                    </Button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button color="secondary" onClick={onClose}>
                    Close
                </Button>

            </Modal.Footer>
        </Modal>
    );
};
CookieSettingPopup.propTypes = {
    show: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    afterSave: PropTypes.func.isRequired
};

export default CookieSettingPopup;
