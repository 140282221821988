/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Link, generatePath } from "react-router-dom";
import Routes, { createTitle } from "routes";
import { connect } from "react-redux";

import Spinner from "components/shared/spinner";
import Step1 from "./stepper/step1";

import { openMessageModal, closeMessageModal } from "actions/modal/actions";
import {
  clearListing,
  getListing,
  saveListing,
  updateListing,
} from "actions/listing/actions";
import { parseAxiosError } from "utils/errors";

const WantedInfo = (props) => {
  const {
    listing,
    getListing,
    clearListing,
    updateListing,
    saveListing,
    user,
    match,
    history,
    openMessageModal,
    closeMessageModal,
  } = props;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [slug, setSlug] = useState();

  useEffect(() => {
    // Initial Load
    clearListing();

    // Set Slug, if any
    if (match.params.listing) {
      setSlug(match.params.listing);
      getListing("wanted", match.params.listing)
        .then((listing) => {
          document.title = createTitle(`Edit ${listing.title}`, Routes);
          setLoading(false);
        })
        .catch((err) => {
          setError(parseAxiosError(err));
        });
    } else {
      document.title = createTitle("Add New Aircraft Wanted", Routes);
      setLoading(false);
    }
  }, [clearListing, getListing]);

  // Direct the user to the specified step
  const goToStep = (step, newSlug) => {
    history.push(
      generatePath(Routes.wantedEdit, {
        ...match.params,
        listing: newSlug || slug,
        step,
      })
    );
  };

  // User has clicked the Next button
  const handleNext = () => {
    return new Promise((resolve, reject) => {
      saveListingData().then((newSlug) => {
        // State updated. Proceed to next step.
        switch (user.role.value) {
          case "sales-rep":
            history.push(
              generatePath(Routes.wantedStatus, {
                ...match.params,
                listing: newSlug,
                status: "pending",
              })
            );
            break;
          case "super-admin":
          case "company-admin":
          default:
            history.push(
              generatePath(Routes.wantedStatus, {
                ...match.params,
                listing: newSlug,
                status: "published",
              })
            );
            break;
        }
      });

      setLoading(false);
      resolve();
    });
  };

  // User has clicked the Update/Save button
  const handleSave = () => {
    return new Promise((resolve, reject) => {
      return saveListingData()
        .then((newSlug) => {
          resolve();
          openMessageModal("Listing Saved!", () => (
            <>
              <button
                className="btn btn-primary btn-green"
                onClick={() => {
                  goToStep("overview", newSlug);
                  setLoading(false);
                  closeMessageModal();
                }}
              >
                Continue Editing
              </button>
              <Link
                className="btn btn-secondary btn-red"
                to={Routes.wantedManage}
                onClick={() => {
                  setLoading(false);
                  closeMessageModal();
                }}
              >
                Exit to Listings
              </Link>
              <Link
                className="btn btn-secondary btn-red"
                to={generatePath(Routes.wantedDetail, {
                  listing: newSlug,
                })}
                onClick={() => {
                  setLoading(false);
                }}
                target="_blank"
              >
                View Listing
              </Link>
            </>
          ));
        })
        .catch((err) => reject());
    });
  };

  const saveListingData = () => {
    setLoading(true);
    return saveListing("wanted", listing)
      .then((updated) => {
        return updated.slug;
      })
      .catch((err) => {
        const msg = parseAxiosError(err);
        openMessageModal(
          `Error Saving: ${msg}. Please try saving again later, or contact us if you continue to experience problems.`,
          () => (
            <button
              className="btn btn-green"
              onClick={() => {
                closeMessageModal();
                setLoading(false);
              }}
            >
              Okay
            </button>
          )
        );
        throw err;
      });
  };

  const handleChange = (name, value) => {
    updateListing(name, value);
  };

  const content = (loading, listing, error) => {
    if (error) {
      return (
        <div className="edit-error form-error error">
          <h1 className="listings-title">Error!</h1>
          <p>{error}</p>
        </div>
      );
    }

    if (loading) {
      return (
        <div className="form-loading edit-loading loading">
          <Spinner />
        </div>
      );
    }

    // Form Content
    return (
      <div className="edit-error form-error error">
        <Step1
          onNext={handleNext}
          onChange={handleChange}
          onSave={handleSave}
          values={listing || {}}
        />
      </div>
    );
  };

  return (
    <section
      id="app-manage-table-info"
      className="app-content app-main listings-manage listings-manage-list listings-section"
    >
      <div className="container stepper">
        <div className="title">
          Aircraft Wanted <strong>Info</strong>
        </div>

        {content(loading, listing, error)}
      </div>
    </section>
  );
};

const keyStateToProps = (state) => {
  return {
    user: state.user,
    listing: state.listing,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    openMessageModal: (message, actions) =>
      dispatch(openMessageModal({ message, actions })),
    closeMessageModal: () => dispatch(closeMessageModal()),
    clearListing: () => dispatch(clearListing()),
    saveListing: (type, payload) => dispatch(saveListing(type, payload)),
    getListing: (type, slug) => dispatch(getListing(type, slug)),
    updateListing: (name, value) => dispatch(updateListing(name, value)),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(WantedInfo);
