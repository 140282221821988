import React, { Component } from 'react';

class SearchFilter extends Component {
  render() {
    return (
      <div className="search-block__search-filter">
        <span className="search-block__search-filter__label">FILTERS</span>
        <span className="search-block__search-filter__item">“red propeller”</span>
      </div>
    );
  }
}

export default SearchFilter;