import React, { useState, useRef } from "react";

export default function SingleImageUpload(props) {
  const fileInputRef = useRef(null);

  const [image, setImage] = useState(
    props.image ? { preview: props.image } : null
  );

  const handleImageUpload = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      const imageObj = {
        preview: reader.result,
        file
      };
      setImage(imageObj);

      if (props.onAdd) {
        props.onAdd(imageObj);
      }
    };

    reader.readAsDataURL(file);
  };

  const handleImageDelete = () => {
    setImage(null);
    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = null;
    }

    if (props.onDelete) {
      props.onDelete();
    }
  };

  return (
    <div className="single-image-brick-upload">
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleImageUpload}
        className="file-input"
      />
      <div className="image-upload-container">
        {image && (
          <div className="image-brick">
            <div className="image-delete" onClick={handleImageDelete}>
              X
            </div>
            <img
              src={image.preview}
              className="image-preview"
              alt="Uploaded Preview"
            />
          </div>
        )}
        {!image && (
          <div className="image-upload">
            <button onClick={() => fileInputRef.current.click()}>
              Upload Image
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
