import React from 'react';
import PropTypes from 'prop-types';

export default function StandaloneTabs(props) {
  return (
    <div className={`standalone-tabs ${props.className? props.className:''}`}>
      <ul className="standalone-tabs__list">
        {props.list.map((item, i) => {
          return (
            <li
              key={i}
              className={`${props.active === i? "active":""}`}
              onClick={() => props.handleNavItemClick(i)}
            >
              <span className="item-name">{item}</span>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

StandaloneTabs.propTypes = {
  list: PropTypes.array.isRequired,
  active: PropTypes.number.isRequired,
  handleNavItemClick: PropTypes.func.isRequired,
};