import crypto from "crypto";

const cryptoPublicKey = `-----BEGIN PUBLIC KEY-----MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAyHleoja+90JoADWYqhW3yycy2G2JzhVRyQqyZvIwIg7oPGgT543DaDaXz/zylDnPM7zhxFCaj2kVRHu62Jr9QStTkEX7sM8e3lA08GRU53cbCiYW9uEI1g3XJHSqhqu92fgwkVR02ePSHEUD/rvFMsWjVD+65WXl7LXFg2vP9nRbG2vXqsTJRP/peV35Ri6DmyaGYvq+QQ4Etnq/PCaWiOY9vmngi9QpnN2lxzTfIhIHMDIUqeUM5nRCEqwxcrqgJGMHDiC2L+jgkOJyx7ZlGUan9X4kNSKo0IyBGCENbRuqpurnsYklGtZPtrraKynBwSsSX+LRxFubCkR2uhgwowIDAQAB-----END PUBLIC KEY-----`;

export const encrypt = (payload) => {
  const payloadString = JSON.stringify(payload);
  const encryptedData = crypto.publicEncrypt(cryptoPublicKey, Buffer.from(payloadString, 'utf-8'));
  const encryptedDataData = encryptedData.toString('base64');
  return encryptedDataData;

};

export const decrypt = (payload, privateKey) => {
  try {
    const decryptedData = crypto.privateDecrypt(privateKey, Buffer.from(payload.data, 'base64'));
    const decryptedObject = decryptedData.toString('utf-8');
    return JSON.parse(decryptedObject);
  } catch (error) {
    console.error('Error during decryption:', error.message);
    throw error;
  }
};

