import React from "react";
import { Link, generatePath } from "react-router-dom";
import Routes from "routes";

export default function ListingLink(listing) {
  return TypeLink(listing.type.value, listing.slug);
}

export function ListingEdit(listing) {
  return TypeEdit(listing.type.value, listing.slug);
}

export function ListingTitle(listing) {
  if (listing && listing.type) {
    return listing.title;
  }
  return <strong>Listing Unavailable</strong>;
}

export function ListingLinkAndTitle(props) {
  const { listing } = props;
  if (listing && listing.type) {
    return <Link to={ListingLink(listing)}>{listing.title}</Link>;
  }
  return <strong>Listing Unavailable</strong>;
}

export function ListingEditAndTitle(props) {
  const { listing } = props;
  if (listing && listing.type) {
    return <Link to={ListingEdit(listing)}>{listing.title}</Link>;
  }
  return <strong>Listing Unavailable</strong>;
}

export function TypeLink(type, slug) {
  switch (type) {
    case "wanted":
      return generatePath(Routes.wantedDetail, {
        listing: slug,
      });
    case "service":
      return generatePath(Routes.servicesDetail, {
        listing: slug,
      });
    case "sale":
    default:
      return generatePath(Routes.forSaleDetail, {
        listing: slug,
      });
  }
}

export function TypeEdit(type, slug) {
  switch (type) {
    case "wanted":
      return generatePath(Routes.wantedEdit, {
        listing: slug,
      });
    case "service":
      return generatePath(Routes.servicesEdit, {
        listing: slug,
      });
    case "sale":
    default:
      return generatePath(Routes.forSaleEdit, {
        listing: slug,
      });
  }
}

export function UserLink(email, tab) {
  return generatePath(Routes.accountSettings, {
    email,
    tab,
  });
}

export function CompanyLink(slug, tab) {
  return generatePath(Routes.companyEdit, {
    company: slug,
    tab,
  });
}

export function PromotionLink(id) {
  return generatePath(Routes.promotionsEdit, {
    promotion: id,
  });
}
