import React, { useEffect } from "react";
import { connect } from "react-redux";

import { withFormik } from "formik";

import Card from "components/shared/planCard";

import { getPlans } from "actions/plan/actions";

function PlanForm(props) {
  const { values, handleChange, onChange, getPlans, plans } = props;

  useEffect(() => {
    getPlans();
  }, [getPlans]);

  useEffect(() => {
    if (onChange) {
      onChange(values);
    }
  }, [onChange, values]);

  return (
    <div className="plans plans-form">
      <div className="plans-cards">
        {plans.map((plan, key) => (
          <Card
            key={key}
            handleChange={handleChange}
            currentPlan={values.plan}
            type={plan.slug}
            mostPopular={plan.slug === "gold"}
            title={plan.title}
            price={plan.price}
            description={plan.description}
            homepageDisplayCost={plan.homepageDisplayCost}
            featuredListingCost={plan.featuredListingCost}
            sponsoredAdCost={plan.sponsoredAdCost}
            blitzEmailCost={plan.blitzEmailCost}
            socialFacebookCost={plan.socialFacebookCost}
            socialTwitterCost={plan.socialTwitterCost}
            socialInstagramCost={plan.socialInstagramCost}
          />
        ))}
      </div>
      {!values.plan ? (
        <p className="selected-plan">You haven’t selected a plan yet.</p>
      ) : (
        <p className="selected-plan">
          <span>You’ve selected a </span>
          <span className="selected-plan-item">{values.plan || "Unknown"}</span>
          <span> subscription plan.</span>
        </p>
      )}
    </div>
  );
}

const keyStateToProps = (state) => {
  return {
    plans: state.plans,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    getPlans: () => dispatch(getPlans()),
  };
};

const PlanWithFormik = withFormik({
  mapPropsToValues: (props) => {
    return {
      plan: props.plan || "",
    };
  },
})(PlanForm);

export default connect(keyStateToProps, keyDispatchToProps)(PlanWithFormik);
