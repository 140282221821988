import React from "react";

import { Row, Col } from "react-bootstrap";

import EditableInput from "components/backend/shared/editableInput";
import PromoteCalendar from "components/backend/promotions/shared/calendar";
import SaleListItem from "components/frontend/listings/for-sale/shared/listItem";
import WantedListItem from "components/frontend/listings/wanted/shared/listItem";
import ServiceListItem from "components/frontend/listings/services/shared/listItem";

export default function FeaturedPromotionEdit(props) {
  const { promotion, onChange } = props;

  const updatePromotion = (value) => {
    if (onChange) onChange(value);
  };

  const promotionTemplate = (promo) => {
    switch (promo.listing.type.value) {
      case "wanted":
        return (
          <WantedListItem
            listing={promo.listing}
            plan={promo.company.plan}
            company={promo.company}
          />
        );
      case "service":
        return (
          <ServiceListItem
            listing={promo.listing}
            plan={promo.company.plan}
            company={promo.company}
          />
        );
      case "sale":
      default:
        return (
          <SaleListItem
            listing={promo.listing}
            plan={promo.company.plan}
            company={promo.company}
            featured
          />
        );
    }
  };

  return (
    <div className="promotion-edit">
      <div className="promotion-edit-form">
        <Row>
          <Col xs={12} md={8}>
            <EditableInput
              label="Comments"
              value={promotion.comments || ""}
              onChange={(value) => updatePromotion({ comments: value })}
            />
          </Col>
          <Col xs={12} md={4}>
            <div className="Calendar-div">
              <PromoteCalendar
                startDate={promotion.startDate}
                onChange={(date) => {
                  updatePromotion({ startDate: date });
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="promotion-edit-preview">
        {promotionTemplate(promotion)}
      </div>
    </div>
  );
}
