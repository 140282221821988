import React, { useEffect, useState } from "react";
import axios from "axios";

import { Row, Col } from "react-bootstrap";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

import EditableInput from "components/backend/shared/editableInput";
import SingleImageBrickUpload from "components/backend/shared/singleImageBrickUpload";
import PromoteCalendar from "components/backend/promotions/shared/calendar";
import Sponsored from "components/frontend/listings/_shared/sponsored";

export default function SponsoredPromotionEdit(props) {
  const { promotion, onChange, onMutate } = props;

  //const [listingType, setListingType] = useState(promotion.categoryType.value || "");
  const [listingTypes, setListingTypes] = useState([]);
  // const [serviceCategory, setServiceCategory] = useState(undefined);
  const [serviceCategories, setServiceCategories] = useState([]);
  // const [aircraftType, setAircraftType] = useState(undefined);
  const [aircraftTypes, setAircraftTypes] = useState([]);
  // const [aircraftSize, setAircraftSize] = useState(undefined);
  const [aircraftSizes, setAircraftSizes] = useState([]);

  useEffect(() => {
    axios
      .get(
        "/masterData?keys=listingType,aircraftType,aircraftSize,serviceCategory"
      )
      .then((response) => {
        const masterData = response.data.rows;
        setListingTypes(
          masterData.filter((data) => data.key === "listingType")
        );
        setServiceCategories(
          masterData.filter((data) => data.key === "serviceCategory")
        );
        setAircraftTypes(
          masterData.filter((data) => data.key === "aircraftType")
        );
        setAircraftSizes(
          masterData.filter((data) => data.key === "aircraftSize")
        );
      });
  }, []);

  const updatePromotion = (value) => {
    if (onChange) onChange(value);
  };

  const updateImage = (image) => {
    // This is a new image. Upload it.
    let formdata = new FormData();
    formdata.append("image", image.file);

    return axios
      .post(`/promotion/${promotion.id}/image`, formdata, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (onMutate) onMutate({ images: response.data.data });
      });
  };

  const removeImage = (image) => {
    // Delete the image(s)
    return axios
      .delete(`/promotion/${promotion.id}/image/${image.uid}`)
      .then((response) => {
        if (onMutate) onMutate({ images: undefined });
      });
  };

  const categoryDropdowns = () => {
    switch (promotion.categoryType.value) {
      case "service":
        return serviceDropdowns();
      case "sale":
      case "wanted":
        return aircraftDropdowns();
      default:
        return <></>;
    }
  };

  const aircraftDropdowns = () => {
    return (
      <>
        <Col xs={12} md={6}>
          <EditableInput
            label="Category"
            value={promotion.category.title || ""}
            renderInput={({ setEdit, setValue }) => (
              <select
                className="bg-transparent"
                onChange={(evt) => {
                  const value = evt.target.value;
                  updatePromotion({
                    categoryKey: "aircraftType",
                    categoryValue: value,
                  });
                  const label = aircraftTypes
                    .filter((s) => s.value === value)
                    .shift().title;
                  setValue(label);
                  setEdit(false);
                }}
                value={promotion.category.value || ""}
              >
                <option></option>
                {aircraftTypes.map((t) => (
                  <option
                    key={`aircraft-type-dropdown-${t.value}`}
                    value={t.value}
                  >
                    {t.title}
                  </option>
                ))}
              </select>
            )}
            onChange={() => {}}
          />
        </Col>
        {promotion.category && promotion.category.value === "jet" && (
          <Col xs={12} md={6}>
            <EditableInput
              label="Category"
              value={promotion.subcategory ? promotion.subcategory.title : ""}
              renderInput={({ setEdit, setValue }) => (
                <select
                  className="bg-transparent"
                  onChange={(evt) => {
                    const value = evt.target.value;
                    updatePromotion({
                      subcategoryKey: "aircraftSize",
                      subcategoryValue: value,
                    });
                    const label = aircraftSizes
                      .filter((s) => s.value === value)
                      .shift().title;
                    setValue(label);
                    setEdit(false);
                  }}
                  value={
                    promotion.subcategory ? promotion.subcategory.value : ""
                  }
                >
                  <option></option>
                  {aircraftSizes.map((s) => (
                    <option
                      key={`aircraft-size-dropdown-${s.value}`}
                      value={s.value}
                    >
                      {s.title}
                    </option>
                  ))}
                </select>
              )}
              onChange={() => {}}
            />
          </Col>
        )}
      </>
    );
  };

  const serviceDropdowns = () => {
    return (
      <Col xs={12} md={6}>
        <EditableInput
          label="Category"
          value={promotion.category.title || ""}
          renderInput={({ setEdit, setValue }) => (
            <select
              className="bg-transparent"
              onChange={(evt) => {
                const value = evt.target.value;
                updatePromotion({
                  categoryKey: "serviceCategory",
                  categoryValue: value,
                });
                const label = serviceCategories
                  .filter((s) => s.value === value)
                  .shift().title;
                setValue(label);
                setEdit(false);
              }}
              value={promotion.category.value || ""}
            >
              <option></option>
              {serviceCategories.map((c) => (
                <option
                  key={`service-category-dropdown-${c.value}`}
                  value={c.value}
                >
                  {c.title}
                </option>
              ))}
            </select>
          )}
          onChange={() => {}}
        />
      </Col>
    );
  };

  return (
    <div className="promotion-edit">
      <div className="promotion-edit-form">
        <Row>
          <Col xs={12} md={8}>
            <EditableInput
              label="Listing Type"
              value={promotion.categoryType.title || ""}
              renderInput={({ setEdit, setValue }) => (
                <ToggleButtonGroup
                  type="radio"
                  name="options"
                  onChange={(value) => {
                    //setListingType(value);
                    //sendChange("listingType", value);
                    const label = listingTypes
                      .filter((l) => l.value === value)
                      .shift().title;
                    setValue(label);
                    updatePromotion({ categoryType: value });
                    setEdit(false);
                  }}
                  //value={listingType}
                  value={promotion.categoryType.value || ""}
                >
                  {listingTypes.map((lt) => (
                    <ToggleButton
                      key={`toggle-listing-type-${lt.value}`}
                      value={lt.value}
                      variant="outline-secondary"
                      className={"toggle-btn"}
                    >
                      {lt.title}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              )}
              onChange={() => {}}
            />

            <Row className="mb-3">{categoryDropdowns()}</Row>
            <SingleImageBrickUpload
              image={
                promotion.images && promotion.images.length > 0
                  ? promotion.images.slice().shift().url
                  : undefined
              }
              onAdd={updateImage}
              onDelete={() => {
                removeImage(promotion.images.slice().shift());
              }}
            />
            <EditableInput
              label="Comments"
              value={promotion.comments || ""}
              onChange={(value) => updatePromotion({ comments: value })}
            />
          </Col>
          <Col xs={12} md={4}>
            <div className="Calendar-div">
              <PromoteCalendar
                startDate={promotion.startDate}
                onChange={(date) => {
                  updatePromotion({ startDate: date });
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="promotion-edit-preview">
        <Sponsored data={promotion} />
      </div>
    </div>
  );
}
