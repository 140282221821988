import {
  OPEN_LOGIN,
  CLOSE_LOGIN,
  OPEN_SIGNUP,
  CLOSE_SIGNUP,
  OPEN_CONTACTFORM,
  CLOSE_CONTACTFORM,
  OPEN_MESSAGE_MODAL,
  CLOSE_MESSAGE_MODAL,
  OPEN_FORGOT_PASSWORD,
  CLOSE_FORGOT_PASSWORD,
  OPEN_NEWSLETTER_MODAL,
  CLOSE_NEWSLETTER_MODAL,
} from "actions/modal/actionTypes.js";

const initialState = {
  openLogin: false,
  openSignup: false,
  contactForm: {
    open: false,
    listing: null,
  },
  messageModal: {
    open: false,
    message: undefined,
    actions: undefined,
  },
  newsletterModal: false,
};

export default function modalReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case OPEN_LOGIN:
      return {
        ...initialState,
        openLogin: true,
      };
    case CLOSE_LOGIN:
      return {
        ...state,
        openLogin: false,
      };
    case OPEN_SIGNUP:
      return {
        ...initialState,
        openSignup: true,
      };
    case CLOSE_SIGNUP:
      return {
        ...state,
        openSignup: false,
      };
    case OPEN_FORGOT_PASSWORD:
      return {
        ...initialState,
        openForgotPassword: true,
      };
    case CLOSE_FORGOT_PASSWORD:
      return {
        ...state,
        openForgotPassword: false,
      };
    case OPEN_CONTACTFORM:
      return {
        ...initialState,
        contactForm: {
          open: true,
          listing: payload,
        },
      };
    case CLOSE_CONTACTFORM:
      return {
        ...state,
        contactForm: {
          open: false,
          listing: null,
        },
      };
    case OPEN_MESSAGE_MODAL:
      return {
        ...initialState,
        messageModal: {
          open: true,
          message: payload.message,
          actions: payload.actions,
          css: payload.css,
          showClose: payload.showClose,
        },
      };
    case CLOSE_MESSAGE_MODAL:
      return {
        ...state,
        messageModal: {
          open: false,
          message: undefined,
          actions: undefined,
        },
      };
    case OPEN_NEWSLETTER_MODAL:
      return {
        ...initialState,
        newsletterModal: true,
      };
    case CLOSE_NEWSLETTER_MODAL:
      return {
        ...state,
        newsletterModal: false,
      };
    default:
      return state;
  }
}
