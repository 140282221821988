import React, { Component } from "react";
import { Form } from "react-bootstrap";

class PublicCompanyProfile extends Component {
  validateLogo = (file) => {
    if (file && file.type) {
      switch (file.type) {
        case "image/jpeg":
        case "image/gif":
        case "image/png":
          return true;
        default:
          return false;
      }
    }
    return false;
  };

  render() {
    const {
      countries,
      states,
      handleChange,
      values,
      touched,
      errors,
      onBlur,
      setFieldValue,
    } = this.props;

    const handleCheck = (field, checked) => {
      setFieldValue(field, checked);
    };

    return (
      <div className="public-company-profile">
        <p className="sub-header">
          Select which fields you’d like to be viewable by the public, and edit
          any information that needs to be different.
        </p>
        <Input
          name="publicCompanyName"
          label="Company Name:"
          tip="NO COMPANY NAME? JUST PUT YOURS."
          handleChange={handleChange}
          value={values.publicCompanyName}
          defaultValue={values.privateCompanyName}
          setFieldValue={setFieldValue}
          handleCheck={handleCheck}
          checked={values.publicCompanyNameCheckbox}
          classes="input-long"
          isInvalid={touched.publicCompanyName && !!errors.publicCompanyName}
          errors={errors.publicCompanyName}
        />
        <Input
          name="publicCompanyPhone"
          label="Company Phone:"
          handleChange={handleChange}
          value={values.publicCompanyPhone}
          defaultValue={values.privateCompanyPhone}
          setFieldValue={setFieldValue}
          handleCheck={handleCheck}
          checked={values.publicCompanyPhoneCheckbox}
          classes="input-short"
          isInvalid={touched.publicCompanyPhone && !!errors.publicCompanyPhone}
          errors={errors.publicCompanyPhone}
        />
        <Input
          name="publicCompanyFax"
          label="Fax:"
          handleChange={handleChange}
          handleCheck={handleCheck}
          checked={values.publicCompanyFaxCheckbox}
          value={values.publicCompanyFax}
          defaultValue={values.privateCompanyFax}
          setFieldValue={setFieldValue}
          classes="input-short"
          isInvalid={touched.publicCompanyFax && !!errors.publicCompanyFax}
          errors={errors.publicCompanyFax}
        />
        <Input
          name="publicCompanyAddress1"
          name2="publicCompanyAddress2"
          label="Company Address:"
          handleChange={handleChange}
          handleCheck={handleCheck}
          checked={values.publicCompanyAddress1Checkbox}
          value={values.publicCompanyAddress1}
          defaultValue={values.privateCompanyAddress1}
          setFieldValue={setFieldValue}
          value2={values.publicCompanyAddress2}
          classes="input-long"
          isInvalid={
            touched.publicCompanyAddress1 && !!errors.publicCompanyAddress1
          }
          errors={errors.publicCompanyAddress1}
        />
        <Input
          name="publicCompanyCity"
          label="City:"
          handleChange={handleChange}
          value={values.publicCompanyCity}
          defaultValue={values.privateCompanyCity}
          setFieldValue={setFieldValue}
          handleCheck={handleCheck}
          checked={values.publicCompanyCityCheckbox}
          classes="input-long"
          isInvalid={touched.publicCompanyCity && !!errors.publicCompanyCity}
          errors={errors.publicCompanyCity}
        />
        <Input
          name="publicCompanyZip"
          label="Zip Code/Postal Code:"
          handleChange={handleChange}
          handleCheck={handleCheck}
          checked={values.publicCompanyZipCheckbox}
          value={values.publicCompanyZip}
          defaultValue={values.privateCompanyZip}
          setFieldValue={setFieldValue}
          classes="input-short"
          isInvalid={touched.publicCompanyZip && !!errors.publicCompanyZip}
          errors={errors.publicCompanyZip}
        />

        <Input
          name="publicCompanyCountry"
          label="Country:"
          handleChange={handleChange}
          value={values.publicCompanyCountry}
          defaultValue={values.privateCompanyCountry}
          setFieldValue={setFieldValue}
          handleCheck={handleCheck}
          checked={values.publicCompanyCountryCheckbox}
          classes="input-short"
          as="select"
          default="Select Country"
          items={countries}
          isInvalid={
            touched.publicCompanyCountry && !!errors.publicCompanyCountry
          }
          errors={errors.publicCompanyCountry}
        />
        {values &&
          values.publicCompanyCountry &&
          values.publicCompanyCountry === "US" && (
            <Input
              name="publicCompanyState"
              label="State:"
              handleChange={handleChange}
              handleCheck={handleCheck}
              checked={values.publicCompanyStateCheckbox}
              value={values.publicCompanyState}
              defaultValue={values.privateCompanyState}
              setFieldValue={setFieldValue}
              classes="input-short"
              as="select"
              default="Select State"
              items={states}
              isInvalid={
                touched.publicCompanyState && !!errors.publicCompanyState
              }
              errors={errors.publicCompanyState}
            />
          )}
        <Input
          name="publicCompanyEmail"
          label="Main Email:"
          handleChange={handleChange}
          handleCheck={handleCheck}
          checked={values.publicCompanyEmailCheckbox}
          value={values.publicCompanyEmail}
          defaultValue={values.privateCompanyEmail}
          setFieldValue={setFieldValue}
          classes="input-long"
          isInvalid={touched.publicCompanyEmail && !!errors.publicCompanyEmail}
          errors={errors.publicCompanyEmail}
        />
        <div className="public-company-profile-footer">
          <p className="sub-header">Share a little more about your company.</p>
          <Form.Group controlId="website">
            <div className="sales-form-label">
              <Form.Label>Website URL:</Form.Label>
              <p className="sales-form-label-tip">Optional</p>
            </div>
            <Form.Control
              value={values.website}
              onChange={handleChange}
              className="input-long"
              isInvalid={touched.website && !!errors.website}
            />
            <Form.Control.Feedback type="invalid">
              {errors.website}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="logo-upload">
            <div className="sales-form-label">
              <Form.Label>Logo upload:</Form.Label>
              <p className="sales-form-label-tip">Optional</p>
            </div>
            {this.props.logo && this.props.logo.thumbnail && (
              <img
                src={this.props.logo.thumbnail}
                alt=""
                className="thumbnail"
                style={{ maxWidth: "150px", maxHeight: "150px" }}
              />
            )}
            <Form.Control
              name="logo"
              className="fileInput"
              type="file"
              as="input"
              onBlur={onBlur}
              //value={values.logo ? values.logo.filename : ""}
              onChange={(e) => {
                //e.preventDefault();
                let reader = new FileReader();
                let file = e.currentTarget.files[0];
                if (file) {
                  reader.readAsDataURL(file);
                  //this.props.setFieldValue("logo", file);

                  reader.onloadend = () => {
                    this.props.onLogoChange({
                      file: file,
                      thumbnail: reader.result,
                    });
                  };
                  //this.props.setFieldValue("logo", file);
                }
              }}
              // onChangeNULL={(e) => {
              //   e.preventDefault();
              //   this.props.setFieldValue("logo", [...e.target.files]);
              // }}
              // onChange={(event) => {

              // }}
              isInvalid={touched.logo && !!errors.logo}
            />

            <Form.Control.Feedback type="invalid">
              {errors.logo}
            </Form.Control.Feedback>
          </Form.Group>
        </div>
      </div>
    );
  }
}

export default PublicCompanyProfile;

class Input extends Component {
  handleChangeCheck = (evt) => {
    const checked =
      evt && evt.target && evt.target.checked ? evt.target.checked : false;

    if (checked && this.props.defaultValue) {
      this.props.setFieldValue(this.props.name, this.props.defaultValue);
    } else {
      this.props.setFieldValue(this.props.name, "");
    }

    if (this.props.handleCheck) {
      this.props.handleCheck(`${this.props.name}Checkbox`, checked);
    }
  };

  render() {
    return (
      <div className="public-company-profile-formgroup">
        <Form.Check
          custom
          id={`${this.props.name}-checkbox`}
          label=""
          checked={this.props.checked}
          onChange={this.handleChangeCheck}
        />
        <div className="public-company-profile-input">
          <Form.Group controlId={this.props.name}>
            <div className="sales-form-label">
              <Form.Label>{this.props.label}</Form.Label>
              <p className="sales-form-label-tip">{this.props.tip}</p>
            </div>
            {this.props.as === "select" ? (
              <Form.Control
                name={this.props.name}
                value={this.props.value}
                onChange={this.props.handleChange}
                as="select"
                className={this.props.classes}
                disabled={!this.props.checked}
                isInvalid={this.props.isInvalid}
              >
                <option value="">{this.props.default}</option>
                {this.props.items &&
                  this.props.items.map((item, key) => (
                    <option key={key} value={item.value}>
                      {item.title}
                    </option>
                  ))}
              </Form.Control>
            ) : (
              <Form.Control
                disabled={!this.props.checked}
                value={this.props.value}
                onChange={this.props.handleChange}
                className={this.props.classes}
                isInvalid={this.props.isInvalid}
              />
            )}
            <Form.Control.Feedback type="invalid">
              {this.props.errors}
            </Form.Control.Feedback>
          </Form.Group>
          {this.props.name2 && (
            <Form.Group controlId={this.props.name2}>
              <Form.Control
                disabled={!this.props.checked}
                value={this.props.value2}
                onChange={this.props.handleChange}
                className={this.props.classes}
              />
            </Form.Group>
          )}
        </div>
      </div>
    );
  }
}
