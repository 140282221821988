import { SET_COMPANIES } from "actions/companies/actionTypes";

const initialState = [];

export default function userReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case SET_COMPANIES:
      return payload.map((c) => ({
        slug: c.slug,
        name: c.name,
      }));
    default:
      return state;
  }
}
