import axios from "axios";
import apis from "apis";
import _ from "lodash";

import {
  SIGN_UP,
  FORGOT_PASSWORD,
  CHECK_PASSWORD_TOKEN,
  RESET_PASSWORD,
  SIGN_IN,
  SIGN_OUT,
  GET_USER,
  SET_USER,
  VERIFICATION,
  UPDATE_PASSWORD,
  UPDATE_PROFILE,
  UPDATE_AVATAR,
  FINALIZE_ACCOUNT,
  ADD_ACCOUNT_PAYMENT,
  DELETE_ACCOUNT_PAYMENT,
  UPGRADE_ACCOUNT_PLAN,
} from "./actionTypes.js";

import { setNotifications } from "actions/notification/actions";
import { closeLogin } from "actions/modal/actions";
import { setToast } from "actions/toast/actions.js";
import { closeUserSidebar, openUserSidebar } from "actions/app/actions.js";
import { getDashboard, resetDashboard } from "actions/dashboard/actions.js";

export const signUp = (userInfo) => (dispatch) => {
  dispatch({
    type: SIGN_UP,
  });

  return axios({
    method: "post",
    url: apis.register,
    data: userInfo,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const logIn = (userInfo) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: SIGN_IN,
    });
    console.log("Enter in login reducer ",apis.login);
    axios({
      method: "post",
      url: apis.login,
      data: userInfo,
    })
      .then((response) => {
        console.log("response---------");
      console.log(response);
        const { status, token, notifications } = response.data;
        if (status === 1) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          localStorage.setItem("token", token);
          dispatch(setNotifications(notifications.rows));
          dispatch(getUser()).then(() => {
            dispatch(closeLogin());
            dispatch(openUserSidebar());
            resolve(token);
          });
        }
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
      
  });

export const logOut = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: SIGN_OUT,
    });

    dispatch(resetDashboard());
    dispatch(closeUserSidebar());

    // return axios({
    //   method: "get",
    //   url: apis.logout,
    // })
    //   .then((response) => {
    //     const { status } = response.data;
    //     if (status === 1) {
    //       delete axios.defaults.headers.common["Authorization"];
    //       localStorage.removeItem("token");
    //     }
    //   })
    //   .catch((error) => {});

    delete axios.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
    resolve();
  });

export const getUser = () => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const { notifications, user } = getState();
    dispatch({
      type: GET_USER,
    });
    console.log("Enter in get User UI ",apis.me);

    axios({
      method: "get",
      url: apis.me,
    })
      .then((response) => {
        const { data } = response.data;
        console.log("data");
        console.log(data);
        // setTimeout(() => {
        //   dispatch(getUser());
        // }, 60000);

        // Add notifications if they aren't already in state
        const newNotifications = response.data.notifications.rows.filter(
          (n) => {
            const idx = notifications.findIndex((no) => no.id === n.id);
            if (idx >= 0) {
              return false;
            }

            return true;
          }
        );
        if (newNotifications && newNotifications.length > 0) {
          dispatch(setNotifications(newNotifications));
        }

        dispatch(getDashboard());

        if (!_.isEqual(user, data)) {
          dispatch(setUser(data));
        }
        resolve(data);
      })
      .catch((error) => {
        //delete axios.defaults.headers.common["Authorization"];
        //localStorage.removeItem("token");
        console.error("Errorrrr",error);
        resolve(error);
      });
  });

export function setUser(userInfo) {
  return {
    type: SET_USER,
    payload: userInfo,
  };
}

export function clearUser() {
  delete axios.defaults.headers.common["Authorization"];
  localStorage.removeItem("token");
}

export const verification = (code) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({
      type: VERIFICATION,
    });

    return axios({
      method: "get",
      url: apis.verification + "/" + code,
    })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch((error) => {
        //console.error("Error verifying code. Returning axios error");
        reject(error);
      });
  });

export const finalizeAccount = (code, userInfo) => (dispatch) =>
  new Promise(function (resolve, reject) {
    dispatch({
      type: FINALIZE_ACCOUNT,
    });

    const url = `${apis.register}/${code}`;

    axios({
      method: "put",
      url,
      data: userInfo,
    })
      .then((response) => {
        const { data, token } = response.data;
        console.log(data);
        if (token) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
          localStorage.setItem("token", token);
          dispatch(setUser(data));
        }
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });

export function updatePassword(userInfo) {
  return function (dispatch) {
    dispatch({
      type: UPDATE_PASSWORD,
    });

    return axios({
      method: "put",
      url: apis.updatePassword,
      data: userInfo,
    })
      .then((response) => {
        const { message } = response.data;
        return dispatch(
          setToast({
            type: "success",
            message: message,
          })
        );
      })
      .catch((error) => {
        return dispatch(
          setToast({
            type: "error",
            message: error.response.data.message,
          })
        );
      });
  };
}

export const updateAccount = (userInfo, email) => (dispatch) => {
  dispatch({
    type: UPDATE_PROFILE,
  });

  let url = apis.updateAccount;
  if (email) url = `${url}/${email}`;

  return axios({
    method: "put",
    url,
    data: userInfo,
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

export const updateAvatar = (formData, email) => (dispatch) => {
  dispatch({
    type: UPDATE_AVATAR,
  });

  let url = apis.updateAvatar;
  if (email) url = `${url}/${email}`;

  return axios({
    method: "post",
    url,
    data: formData,
    headers: {
      "content-type": "multipart/form-data",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      console.error(error);
      return error;
    });
};

export const addPayment = (email, payment) => (dispatch) => {
  dispatch({
    type: ADD_ACCOUNT_PAYMENT,
  });

  return axios({
    method: "put",
    url: `${apis.updateAccount}/${email}/payment`,
    data: payment,
  })
    .then((response) => {
      const { payment } = response.data;
      return payment;
    })
    .catch((error) => {
      throw error;
    });
};

export const deletePayment = (email) => (dispatch) => {
  dispatch({
    type: DELETE_ACCOUNT_PAYMENT,
  });

  return axios({
    method: "delete",
    url: `${apis.updateAccount}/${email}/payment`,
  })
    .then((response) => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
};

export const forgotPassword = (email) => (dispatch) => {
  dispatch({
    type: FORGOT_PASSWORD,
  });

  console.log(`Forgot Password for ${email}`);

  return axios({
    method: "post",
    url: apis.forgotPassword,
    data: { email },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const checkPasswordToken = (token) => (dispatch) => {
  dispatch({
    type: CHECK_PASSWORD_TOKEN,
  });

  return axios({
    method: "get",
    url: `${apis.resetPassword}/${token}`,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const resetPassword = (token, password) => (dispatch) => {
  dispatch({
    type: RESET_PASSWORD,
  });

  return axios({
    method: "put",
    url: apis.resetPassword,
    data: { token, password },
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw error;
    });
};

export const upgradePlan = (email, plan) => (dispatch) => {
  dispatch({
    type: UPGRADE_ACCOUNT_PLAN,
  });

  return axios({
    method: "put",
    url: `${apis.updateAccount}/${email}/plan`,
    data: { plan },
  }).then((response) => {
    const { data } = response.data;

    //dispatch(setUser(data));
    return data;

    // TODO: Re-Fetch user data?
  });
};
