import React from "react";

import InformationBlock from "components/frontend/listings/for-sale/shared/informationBlock";

export default function Interior(props) {
  const { listing } = props;

  const interiorCondition = () => {
    if (listing.interiorCondition) {
      return listing.interiorCondition.title;
    }
    return <em>Unknown</em>;
  };

  const interiorDate = () => {
    if (listing.interiorYear) {
      let output = listing.interiorYear.toString();
      if (listing.interiorMonth) {
        const date = new Date(
          new Date().getFullYear() + 1,
          listing.interiorMonth - 1,
          1
        );
        const month = date.toLocaleString("default", { month: "long" });
        output = `${month} ${output}`;
      }

      return output;
    }

    return <em>Unknown Date</em>;
  };

  const interiorRefurbished = () => {
    if (listing.interiorRefurbished) {
      return listing.interiorRefurbished.title;
    }
    return <em>Unknown</em>;
  };

  const passengers = () => {
    if (listing.passengers) {
      const formattedString = new Intl.NumberFormat("en-us").format(
        listing.passengers
      );
      return `${formattedString} Seats`;
    }
    return <em>Unknown</em>;
  };

  const interiorBeltedPotty = () => {
    if (listing.interiorBeltedPotty) {
      return "Yes";
    }
    return "No";
  };

  const interiorCrewRest = () => {
    if (listing.interiorCrewRest) {
      return "Yes";
    }
    return "No";
  };

  const lavatory = () => {
    if (listing.lavatory) {
      return listing.lavatory.title;
    }
    return <em>Unknown</em>;
  };

  const galley = () => {
    if (listing.galley) {
      return listing.galley.title;
    }
    return <em>Unknown</em>;
  };

  const interiorDetails = () => {
    if (listing.interiorDetails) {
      return (
        <p
          dangerouslySetInnerHTML={{ __html: listing.interiorDetails }}
          className="white-space-pre"
        />
      );
    }
    return <></>;
  };

  return (
    (listing.interiorCondition ||
      listing.interiorYear ||
      listing.interiorRefurbished ||
      listing.passengers ||
      listing.interiorBeltedPotty ||
      listing.interiorCrewRest ||
      listing.lavatory ||
      listing.galley ||
      listing.interiorDetails) && (
      <InformationBlock name="interior" title="Interior">
        <ul className="for-sale-detail-interior-list detail-aiframe-list list no-bullets mb-4">
          {(listing.interiorCondition || listing.interiorYear) && (
            <li>
              <strong>Condition:</strong> {interiorCondition()} -{" "}
              {interiorDate()}
            </li>
          )}
          {listing.interiorRefurbished && (
            <li>
              <strong>Refurbished:</strong> {interiorRefurbished()}
            </li>
          )}

          {listing.passengers && (
            <li>
              <strong>Passengers:</strong> {passengers()}
            </li>
          )} 
          {  listing.interiorBeltedPotty ===1  && (
            <li>
              <strong>Belted Potty:</strong> {interiorBeltedPotty()}
            </li>
          )}
          {listing.interiorCrewRest ===1 && (
            <li>
              <strong>Crew Rest:</strong> {interiorCrewRest()}
            </li>
          )}
          {listing.lavatory && (
            <li>
              <strong>Lavatory:</strong> {lavatory()}
            </li>
          )}
          {listing.galley && (
            <li>
              <strong>Galley:</strong> {galley()}
            </li>
          )}
        </ul>

        {listing.interiorDetails && interiorDetails()}
      </InformationBlock>
    )
  );
}
