import React from 'react';

import { Button, Row, Col, Container } from 'react-bootstrap';

export default function ResendEmail({userInfo, sendEmail}) {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col xs={10}>
          <div className="text-center mt-5 mb-4">Please check your {userInfo.accountEmail} inbox for a confirmation email.</div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={10}>
          <div className="text-center mb-4">Click the link in your email to confirm your account. If you can’t find the email, check your spam folder or click below to resend.</div>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col xs={10} className="d-flex justify-content-center">
          <Button className="btn-green" onClick={() => sendEmail()}>
            Resend email
          </Button>
        </Col>
      </Row>
    </Container>
  );
}