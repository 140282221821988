import React, { Component } from "react";
import axios from "axios";

import { Row, Col, Button } from "react-bootstrap";
import Spinner from "components/shared/spinner";

import EditableInput from "components/backend/shared/editableInput";
import RichTextEditor from "components/shared/richEditor";

import stripHTML from "utils/stripHTML";

class Policies extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group: "policies",
      isLoading: true,
      results: [],
      error: undefined,
    };
  }

  componentDidMount() {
    axios.get("/settings/policies").then((response) => {
      this.setState({
        isLoading: false,
        error: undefined,
        results: response.data.rows,
      });
    });
  }

  content() {
    if (this.state.error) {
      // Error
      return (
        <div className="policies policies-error error">
          <h1 className="favorite-title">{this.state.error.message}</h1>
          <p>{this.ErrorMessage(this.state.error)}</p>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div className="form-loading edit-loading loading">
          <Spinner />
        </div>
      );
    } else if (this.state.results) {
      return <this.EditContent />;
    }
  }

  getSettingValue = (section) => {
    const thisSetting = this.state.results
      .filter((s) => s.section === section)
      .shift();
    if (thisSetting) return thisSetting.content;
    return;
  };

  setSettingValue = (section, content) => {
    let updatedSettings = this.state.results;
    let previousResults = this.state.results;
    let updatedSetting;
    const thisSetting = this.state.results
      .filter((s) => s.section === section)
      .shift();
    if (thisSetting) {
      // Update Setting
      updatedSettings = this.state.results.map((s, i) => {
        if (s.section === section) {
          // Update
          updatedSetting = s;
          updatedSetting.content = content;
          return updatedSetting;
        }
        return s;
      });
    } else {
      // Add New Setting
      updatedSetting = {
        group: this.state.group,
        section,
        title: null,
        content,
      };
      updatedSettings.push(updatedSetting);
    }

    this.setState({ results: updatedSettings }, () => {
      axios
        .put(`/settings/${this.state.group}/${section}`, updatedSetting)
        .catch((error) => {
          this.setState({ results: previousResults });
        });
    });
  };

  handleTextEditorChange = (name, value) => {
    let cleanedValue = stripHTML(value).trim();
    if (!cleanedValue) {
      value = null;
    }

    this.setSettingValue(name, value);
  };

  EditContent = () => {
    return (
      <section className="policies">
        <Row>
          <Col>
            <div className="site-settings__subtitle mb">
              Edit Terms &amp; Conditions
            </div>
          </Col>
        </Row>
        <div className="policies__form">
          <Row id="section-terms" className="policies__form__block">
            <Col xs={12}>
              <EditableInput
                label="Terms &amp; Conditions"
                value={this.getSettingValue("terms-conditions") || ""}
                renderValue={(value) => (
                  <div
                    dangerouslySetInnerHTML={{ __html: value }}
                    className="white-space-pre"
                  />
                )}
                renderInput={({ setEdit, setValue, getValue }) => (
                  <>
                    <RichTextEditor
                      name="terms-conditions"
                      value={this.getSettingValue("terms-conditions") || ""}
                      onChange={(value, name) => {
                        setValue(value);
                      }}
                    />
                    <Button
                      className="mt-1"
                      onClick={(e) => {
                        this.handleTextEditorChange(
                          "terms-conditions",
                          getValue()
                        );
                        setEdit(false);
                      }}
                    >
                      Done
                    </Button>
                  </>
                )}
                onChange={() => {}}
              />
            </Col>
          </Row>
          <Row id="section-privacy" className="policies__form__block">
            <Col xs={12}>
              <EditableInput
                label="Privacy Policy"
                value={this.getSettingValue("privacy") || ""}
                renderValue={(value) => (
                  <div
                    dangerouslySetInnerHTML={{ __html: value }}
                    className="white-space-pre"
                  />
                )}
                renderInput={({ setEdit, setValue, getValue }) => (
                  <>
                    <RichTextEditor
                      name="privacy"
                      value={this.getSettingValue("privacy") || ""}
                      onChange={(value, name) => {
                        setValue(value);
                      }}
                    />
                    <Button
                      className="mt-1"
                      onClick={(e) => {
                        this.handleTextEditorChange("privacy", getValue());
                        setEdit(false);
                      }}
                    >
                      Done
                    </Button>
                  </>
                )}
                onChange={() => {}}
              />
            </Col>
          </Row>
        </div>
      </section>
    );
  };

  render() {
    return (
      <section id="policies-settings" className="policies-settings">
        {this.content()}
      </section>
    );
  }
}

export default Policies;
