import React, { useEffect } from "react";
import Routes, { createTitle } from "routes";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import SimilarListings from "components/frontend/listings/_shared/similar";

const RemovedListing = (props) => {
  useEffect(() => {
    document.title = createTitle(`Listing Removed`, Routes);
  }, []);

  const { similar } = props;

  return (
    <section
      id="app-page-home"
      className="error-page-content app-removed removed-page"
    >
      <header className="removed-page-hader page-header">
        <Container id="removed-header-row">
          <Row>
            <Col>
              <h1 className="page-title">
                This Listing is No Longer Available
              </h1>
              <div className="sub-header">
                <Link to={Routes.home} className="btn btn-primary btn-red">
                  Return to Home
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      {similar && <SimilarListings similar={similar} />}
    </section>
  );
};

export default RemovedListing;
