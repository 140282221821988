import React, { Fragment } from "react";
import { CSSTransition } from "react-transition-group";
import axios from "axios";
import queryString from "query-string";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Listings from "components/frontend/listings/_shared/listings";
import SaleListItem from "components/frontend/listings/for-sale/shared/listItem";
import FilterGroup from "components/frontend/listings/_shared/filterGroup";
import FilterCheckbox from "components/frontend/listings/_shared/filterCheckbox";
import FilterDropdown from "components/frontend/listings/_shared/filterDropdown";
import FilterControl from "components/frontend/listings/_shared/filterControl";
import Sponsored from "components/frontend/listings/_shared/sponsored";

import Routes, { createTitle } from "routes";
import { ReactComponent as FiltersIcon } from "images/icons/filters.svg";
import { ReactComponent as ArrowRight } from "images/icons/arrow-right.svg";

export default class ForSale extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMobileFiltersOpen: false,
      filterTypes: [],
      filterSizes: [],
      filterMakes: [],
      filterModels: [],
      filterCompanies: [],
      results: 0,
    };

    this.sorting = [
      { sort: null, order: null, label: "Best Match" },
      { sort: "price", order: "DESC", label: "Price: Highest" },
      { sort: "price", order: "ASC", label: "Price: Lowest" },
      { sort: "serial", order: "ASC", label: "SN#: Low to High" },
      { sort: "serial", order: "DESC", label: "SN#: High to Low" },
      { sort: "total_time", order: "DESC", label: "Total Time: Highest" },
      { sort: "total_time", order: "ASC", label: "Total Time: Lowest" },
      { sort: "year", order: "DESC", label: "Year: Newest" },
      { sort: "year", order: "ASC", label: "Year: Oldest" },
      { sort: "created", order: "DESC", label: "Date Listed: Newest" },
      { sort: "created", order: "ASC", label: "Date Listed: Oldest" },
    ];

    // Filters
    this.toggleMobileFilters = this.toggleMobileFilters.bind(this);
    this.resetFilterParams = this.resetFilterParams.bind(this);
    this.Filters = this.Filters.bind(this);

    // Order
    this.handleOrderByChange = this.handleOrderByChange.bind(this);
    this.getOrderBy = this.getOrderBy.bind(this);
  }

  componentDidMount() {
    document.title = createTitle("Aircraft for Sale", Routes);
    //this.fetchResults();

    axios.get("/masterData?keys=aircraftType,aircraftSize").then((response) => {
      const masterData = response.data.rows;
      this.setState({
        filterTypes: masterData.filter((data) => data.key === "aircraftType"),
        filterSizes: masterData.filter((data) => data.key === "aircraftSize"),
      });
    });
    axios.get("/makes?limit=0").then((response) => {
      this.setState({ filterMakes: response.data.data.rows });
    });
    axios.get("/models?limit=0").then((response) => {
      this.setState({ filterModels: response.data.data.rows });
    });
    axios.get("/company?limit=0").then((response) => {
      this.setState({ filterCompanies: response.data.data.rows });
    });
  }

  toggleMobileFilters(evt) {
    evt.preventDefault();
    this.setState({ isMobileFiltersOpen: !this.state.isMobileFiltersOpen });
    return false;
  }

  resetFilterParams() {
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "",
    });
  }

  handleFilterChange = (params) => {
    const updatedObj = {
      ...params,
      page: 1,
    };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };

  handleOrderByChange(event) {
    const target = event.currentTarget;

    const sort = this.sorting[target.value];

    if (sort) {
      const currentParams = queryString.parse(this.props.location.search, {
        arrayFormat: "comma",
      });
      const updatedObj = {
        ...currentParams,
        sort: sort.sort,
        order: sort.order,
      };
      const updatedParams = queryString.stringify(updatedObj, {
        arrayFormat: "comma",
      });

      this.props.history.push({
        pathname: this.props.location.pathname,
        search: "?" + new URLSearchParams(updatedParams).toString(),
      });
    }
  }

  getOrderBy() {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    if (currentParams["sort"] && currentParams["order"]) {
      for (const [i, v] of this.sorting.entries()) {
        if (
          v.sort === currentParams["sort"] &&
          v.order === currentParams["order"]
        ) {
          return i;
        }
      }
    }
    return 0;
  }

  Filters() {
    const years = (start, end) =>
      Array.from({ length: end - start }, (v, k) => k + start);

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    const selectedTypes = currentParams["types"]
      ? currentParams["types"].split(",")
      : [];

    return (
      <div className="filters-pane">
        <FilterGroup
          id="type-filters"
          name="types"
          label="Types"
          onChange={this.handleFilterChange}
        >
          {this.state.filterTypes.map((type) => (
            <FilterCheckbox
              key={type.value}
              value={type.value}
              label={type.title}
            />
          ))}
        </FilterGroup>
        {selectedTypes.includes("jet") && (
          <FilterGroup
            id="size-filters"
            name="size"
            label="Size"
            onChange={this.handleFilterChange}
          >
            {this.state.filterSizes.map((size) => (
              <FilterCheckbox
                key={size.value}
                value={size.value}
                label={size.title}
              />
            ))}
          </FilterGroup>
        )}

        <FilterGroup
          id="make-filters"
          name="make"
          label="Make"
          onChange={this.handleFilterChange}
          className="scrollable"
        >
          {this.state.filterMakes.map((make) => (
            <FilterCheckbox
              key={make.slug}
              value={make.slug}
              label={make.name}
            />
          ))}
        </FilterGroup>
        {currentParams["make"] && (
          <FilterGroup
            id="model-filters"
            name="model"
            label="Model"
            onChange={this.handleFilterChange}
            className="scrollable"
          >
            {this.state.filterModels
              .filter((m) => currentParams["make"].includes(m.make.slug))
              .sort((a, b) =>
                a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1
              )
              .map((model) => (
                <FilterCheckbox
                  key={model.id}
                  value={model.id}
                  label={model.name}
                />
              ))}
          </FilterGroup>
        )}

        <FilterGroup id="year-filters" label="Years">
          <FilterDropdown id="year-min-filter" name="yearMin" label="Min">
            <option></option>
            {years(1900, new Date().getFullYear() + 2)
              .reverse()
              .map((year, i) => {
                return (
                  <option value={year} key={i}>
                    {year}
                  </option>
                );
              }, this)}
          </FilterDropdown>
          <FilterDropdown id="year-max-filter" name="yearMax" label="Max">
            <option></option>
            {years(1900, new Date().getFullYear() + 2)
              .reverse()
              .map((year, i) => {
                return (
                  <option value={year} key={i}>
                    {year}
                  </option>
                );
              }, this)}
          </FilterDropdown>
        </FilterGroup>
        <FilterGroup
          id="company-filters"
          name="company"
          label="Company"
          onChange={this.handleFilterChange}
          className="scrollable company-filters"
        >
          {this.state.filterCompanies.map((company) => (
            <FilterCheckbox
              key={company.id}
              value={company.slug}
              label={company.name}
            />
          ))}
        </FilterGroup>
        {currentParams["company"] && (
          <FilterGroup
            id="rep-filters"
            name="rep"
            label="Reps"
            onChange={this.handleFilterChange}
            className="scrollable company-filters"
          >
            {this.state.filterCompanies
              .filter((c) => currentParams["company"].includes(c.slug))
              .reduce((list, item) => {
                return list.concat(
                  item.accounts
                    .filter((r) => r.saleListingsCount > 0)
                    .map((a) => ({
                      name: a.profile.name,
                      email: a.email,
                    }))
                );
              }, [])
              .map((rep) => (
                <FilterCheckbox
                  key={rep.email}
                  value={rep.email}
                  label={rep.name}
                />
              ))}
          </FilterGroup>
        )}
        <FilterGroup id="price-filters" label="Price">
          <FilterControl
            id="price-min-filter"
            name="priceMin"
            label="Min"
            type="number"
            prepend="$"
          />
          <FilterControl
            id="price-max-filter"
            name="priceMax"
            label="Max"
            type="number"
            prepend="$"
          />
        </FilterGroup>
      </div>
    );
  }

  getSearchParams() {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    const newParams = {};

    // Limit
    if (currentParams["show"]) {
      newParams["limit"] = currentParams["show"] || 10;
    }

    return { ...currentParams, ...newParams };
  }

  fetchSponsored = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    const newParams = {};

    // Category / Types
    if (currentParams["types"]) {
      newParams["types"] = currentParams["types"];
    }

    // Subcategory / Sizes
    if (currentParams["size"]) {
      newParams["size"] = currentParams["size"];
    }

    const params = queryString.stringify(newParams, { arrayFormat: "comma" });
    axios
      .get(`promotions/sponsored?listings=sale&${params}`)
      .then((response) => {
        const results = response.data.data;
        if (results.length > 0) {
          const sponsored = results[Math.floor(Math.random() * results.length)];
          this.setState({ sponsored });
        } else {
          this.setState({ sponsored: undefined });
        }
      })
      .catch((error) => {
        this.setState({ sponsored: undefined });
      });
  };

  render() {
    // Choose random slot for Sponsor ad

    return (
      <section id="app-manage-table-list" className="app-content app-main">
        <CSSTransition
          in={this.state.isMobileFiltersOpen}
          timeout={250}
          classNames="mobile-filters-transition"
        >
          <aside id="mobile-filters" className="for-sale-filters filters">
            <header className="filters-header">
              <Row className="m-0 d-flex align-items-center">
                <Col xs={3}>
                  <button
                    id="filters-toggle"
                    onClick={this.toggleMobileFilters}
                  >
                    <FiltersIcon />
                    <ArrowRight />
                  </button>
                </Col>
                <Col xs={9} className="text-center">
                  <h2>Filter &amp; Sort</h2>
                  <span className="filters-header-results">
                    {this.state.results} Results
                  </span>
                </Col>
              </Row>
            </header>
            <div className="filters-content">
              <Col xs={12} className="filters-sort-pane">
                <FilterDropdown
                  id="listing-sort"
                  name="sort-by"
                  label="Sort:"
                  onChange={this.handleOrderByChange}
                  value={this.getOrderBy()}
                >
                  {this.sorting.map(function (sort, i) {
                    return (
                      <option value={i} key={i}>
                        {sort.label}
                      </option>
                    );
                  }, this)}
                </FilterDropdown>
              </Col>

              <this.Filters />
            </div>
            <footer className="filters-footer text-center">
              <button
                className="btn btn-primary btn-green btn-outline"
                onClick={this.resetFilterParams}
              >
                Clear All
              </button>
              <button
                className="btn btn-secondary btn-green"
                onClick={this.toggleMobileFilters}
              >
                Done
              </button>
            </footer>
          </aside>
        </CSSTransition>
        <Container>
          <Row>
            <Col xs={12} sm={{ span: 8, order: 2 }} md={9} lg={10}>
              <Listings
                className="for-sale-listings"
                url="/listings/type/sale"
                params={this.getSearchParams()}
                onToggleMobileFilters={this.toggleMobileFilters}
                onFetchSuccess={(state) => {
                  if (state.results && state.results.length > 0) {
                    this.fetchSponsored();
                    this.setState({
                      results: state.count,
                      sponsoredIndex: Math.floor(
                        Math.random() * state.results.length
                      ),
                    });
                  }
                }}
              >
                {(item, i, results) => {
                  let sponsor = <></>;
                  if (
                    this.state.sponsored &&
                    this.state.sponsoredIndex &&
                    i === this.state.sponsoredIndex
                  ) {
                    const { sponsored } = this.state;
                    sponsor = <Sponsored data={sponsored} />;
                  }
                  return (
                    <Fragment key={`list-item-${i}`}>
                      <SaleListItem
                        listing={item}
                        plan={item.account.plan || item.company.plan}
                      />
                      {sponsor}
                    </Fragment>
                  );
                }}
              </Listings>
            </Col>
            <Col
              sm={{ span: 4, order: 1 }}
              md={3}
              lg={2}
              className="d-none d-sm-block"
            >
              <aside id="sidebar-filters" className="for-sale-filters filters">
                <h3>Filter By</h3>
                <this.Filters />
                <footer className="filters-footer text-center mt-3">
                  <button
                    className="btn btn-primary btn-green"
                    onClick={this.resetFilterParams}
                  >
                    Reset
                  </button>
                </footer>
              </aside>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
