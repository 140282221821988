import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

export default function Disclaimer(props) {
  return (
    <div className="for-sale-detail-disclaimer detail-disclaimer">
      <Container className="py-5">
        <Row>
          <Col className="text-center">
            These specifications are presented as introductory information only.
            They do not constitute representations or warranties of any kind.
            Accordingly, you should rely on your own inspection of this
            aircraft. The aircraft is subject to prior sale, and/or removal from
            the market.
          </Col>
        </Row>
      </Container>
    </div>
  );
}
