import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Form, Button, Row, Col } from 'react-bootstrap';

import { Formik } from 'formik';
import { object, string } from 'yup';

const schema = object().shape({
  fullname: string().required('Full Name is required'),
  zip: string().required('Zip Code is required'),
});

class NameForm extends Component {
  handleSubmit(fullname, zip) {
    const userInfo = {
      name: fullname,
      zip: zip
    }
    this.props.changeUserInfo(userInfo)
    this.props.onChangeNextStep()
  }

  render() {
    const { userInfo } = this.props;

    return (
      <Formik
        validationSchema={schema}
        onSubmit={({fullname, zip}) => this.handleSubmit(fullname, zip)}
        initialValues={{
          fullname: userInfo.name,
          zip: userInfo.zip
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
        }) => (
          <Form className="account-form" onSubmit={handleSubmit}>
            <Form.Group controlId="fullname">
              <Form.Label className="account-form-label">Full Name</Form.Label>
              <Form.Control
                className="account-form-input"
                type="text"
                placeholder="John Doe"
                name="fullname"
                value={values.fullname}
                onChange={handleChange}
                isValid={touched.fullname && !errors.fullname}
                isInvalid={touched.fullname && !!errors.fullname}
              />
              <Form.Control.Feedback type="invalid">
                {errors.fullname}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Row>
              <Form.Group as={Col} md="6" lg="4" controlId="zip">
                <Form.Label className="account-form-label">Zip Code or Postal Code</Form.Label>
                <Form.Control
                  autoComplete="off"
                  autoCorrect="off"
                  spellCheck="off"
                  className="account-form-input"
                  type="text"
                  placeholder="12345"
                  name="zip"
                  value={values.zip}
                  onChange={handleChange}
                  isValid={touched.zip && !errors.zip}
                  isInvalid={touched.zip && !!errors.zip}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.zip}
                </Form.Control.Feedback>
              </Form.Group>
            </Form.Row>

            <Row>
              <Col xs={12} className="d-flex justify-content-center">
                <Button className="btn-green" type="submit">
                  almost done!
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

NameForm.propTypes = {
  userInfo: PropTypes.shape({
    name: PropTypes.string.isRequired,
    zip: PropTypes.string.isRequired,
  }),
  changeUserInfo: PropTypes.func,
  onChangeNextStep: PropTypes.func
};

export default NameForm;