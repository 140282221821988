import {
  OPEN_LOGIN,
  CLOSE_LOGIN,
  OPEN_SIGNUP,
  CLOSE_SIGNUP,
  OPEN_FORGOT_PASSWORD,
  CLOSE_FORGOT_PASSWORD,
  OPEN_CONTACTFORM,
  CLOSE_CONTACTFORM,
  OPEN_MESSAGE_MODAL,
  CLOSE_MESSAGE_MODAL,
  OPEN_NEWSLETTER_MODAL,
  CLOSE_NEWSLETTER_MODAL,
} from "./actionTypes.js";

export function openLogin() {
  return {
    type: OPEN_LOGIN,
  };
}

export function closeLogin() {
  return {
    type: CLOSE_LOGIN,
  };
}

export function openSignup() {
  return {
    type: OPEN_SIGNUP,
  };
}

export function closeSignup() {
  return {
    type: CLOSE_SIGNUP,
  };
}

export function openForgotPassword() {
  return {
    type: OPEN_FORGOT_PASSWORD,
  };
}

export function closeForgotPassword() {
  return {
    type: CLOSE_FORGOT_PASSWORD,
  };
}

export function openContactForm(listing) {
  return {
    type: OPEN_CONTACTFORM,
    payload: listing,
  };
}

export function closeContactForm() {
  return {
    type: CLOSE_CONTACTFORM,
  };
}

export function openMessageModal(payload) {
  return {
    type: OPEN_MESSAGE_MODAL,
    payload,
  };
}

export function closeMessageModal() {
  return {
    type: CLOSE_MESSAGE_MODAL,
  };
}

export function openNewsletterModal(payload) {
  return {
    type: OPEN_NEWSLETTER_MODAL,
    payload,
  };
}

export function closeNewsletterModal() {
  return {
    type: CLOSE_NEWSLETTER_MODAL,
  };
}
