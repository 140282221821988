/* eslint-disable no-fallthrough */
import React from "react";
import axios from "axios";
import queryString from "query-string";
import moment from "moment";
import _ from "lodash";

import Routes, { createTitle } from "routes";
import Table from "components/shared/table";
import RoleRenderer from "components/shared/roleRenderer";
import MultiSelectFilter from "components/shared/multiSelectFilter";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link, generatePath } from "react-router-dom";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faHeart } from "@fortawesome/free-solid-svg-icons";

import { ReactComponent as DraftIcon } from "images/icons/file.svg";
import { ReactComponent as PendingIcon } from "images/icons/eye.svg";
import { ReactComponent as PublishedIcon } from "images/icons/check.svg";
// import { ReactComponent as PremiumIcon } from "images/icons/shield.svg";
import { ReactComponent as SoldIcon } from "images/icons/dollar.svg";
import { ReactComponent as RemovedIcon } from "images/icons/archive.svg";

import Pagination from "components/frontend/listings/_shared/pagination";
import SubscriptionInfo from "components/backend/listings/shared/subscription";
import FilterDropdown from "components/frontend/listings/_shared/filterDropdown";
import { openMessageModal, closeMessageModal } from "actions/modal/actions";
import { updateStatusListing } from "actions/listing/actions";
import { logIn } from "actions/user/actions";
class ForSaleManage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: "",
      fetchUrl: false,
      isLoading: true,
      results: [],
      error: undefined,
      count: 0,
      pages: 0,
      selected: [],
      filterStatuses: [],
      filterTypes: [],
      filterSizes: [],
      filterMakes: [],
      filterModels: [],
      filterCompanies: [],
      filterReps: [],
      showDropdown: false,
      selectedCheckboxes: [],
      openMessageModal,
      closeMessageModal,
      setLoading: false,
      selectAll: false,
      randerResult: []

    };

    this.fetchUrl = this.props.status
      ? `/manage/type/sale/status/${this.props.status}`
      : `/manage/type/sale`;
  }

  componentDidMount() {
    document.title = createTitle("Aircraft for Sale", Routes);

    //
    const { userInfo } = this.props;

    axios.get("/masterData?keys=listingStatus").then((response) => {
      const masterData = response.data.rows;

      const filterStatuses = masterData
        .filter((data) => data.key === "listingStatus")
        .map((data) => {
          return {
            value: data.value,
            title: data.title,
          };
        });

      this.setState({ filterStatuses });
    });
    axios.get("/makes?limit=0").then((response) => {
      const filterMakes = response.data.data.rows.map((make) => {
        return {
          value: make.slug,
          title: make.name,
        };
      });

      this.setState({ filterMakes });
    });
    axios.get("/models?limit=0").then((response) => {
      const filterModels = response.data.data.rows.map((model) => {
        return {
          value: model.id,
          title: model.name,
        };
      });
      this.setState({ filterModels });
    });

    if (userInfo && userInfo.role) {
      if (userInfo.role.value === "super-admin") {
        axios.get("/company?limit=0").then((response) => {
          const filterCompanies = response.data.data.rows
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((company) => {
              return {
                value: company.slug,
                title: company.name,
              };
            });
          this.setState({ filterCompanies });
        });
      }
      if (
        userInfo.role.value === "company-admin" ||
        userInfo.role.value === "super-admin"
      ) {
        axios.get("/accounts?limit=0").then((response) => {
          const filterReps = response.data.data.rows
            .sort((a, b) => (a.profile.name > b.profile.name ? 1 : -1))
            .map((rep) => {
              return {
                value: rep.email,
                title: rep.profile.name,
              };
            });
          this.setState({ filterReps });
        });
      }
    }

    this.fetchResults();
  }

  componentDidUpdate() {
    this.fetchResults();
  }

  duplicateListing = (slug) => {
    this.setState({ isLoading: true }, () => {
      axios
        .post(`/manage/type/sale/slug/${slug}`)
        .then((response) => {
          const { listing } = response.data;

          this.props.history.push({
            pathname: generatePath(Routes.forSaleEdit, {
              listing: listing.slug,
              step: null,
            }),
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
            error: err,
          });
        });
    });
  };

  getSearchParams() {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    const newParams = {};

    // Limit
    if (currentParams["show"]) {
      newParams["limit"] = currentParams["show"] || 10;
    }

    return { ...currentParams, ...newParams };
  }

  parseRequestUrl() {
    const fetchUrl = this.fetchUrl;
    const searchParams = this.getSearchParams();
    // Add Params
    if (searchParams) {
      // We have search params. Parse them!
      const searchParamsUrl = queryString.stringify(searchParams, {
        arrayFormat: "comma",
      });
      return `${fetchUrl}?${searchParamsUrl}`;
    }

    // Return URL
    return fetchUrl;
  }

  statusIcon(status, slug) {
    let icons = [];
    switch (status.value) {
      case "published":
        icons.push(<PublishedIcon style={{ display: 'inline-block' }} title={status.title} />);
        break;
      case "removed":
        icons.push(<RemovedIcon style={{ display: 'inline-block' }} title={status.title} />);
        break;
      case "sold":
        icons.push(<SoldIcon style={{ display: 'inline-block' }} title={status.title} />);
        break;
      case "pending":
        icons.push(<PendingIcon style={{ display: 'inline-block' }} title={status.title} />);
        break;
      case "draft":
      default:
        icons.push(<DraftIcon style={{ display: 'inline-block' }} title={status.title} />);
    }
    return icons;
  }
  statusCheckBox(slug) {
    return <>
      <div className="custom-checkbox-2" key={slug} onClick={() => this.toggleCheckBox(slug)}>
        <input type="checkbox" name='changeStatus' value={slug} checked={this.state.selectedCheckboxes[slug] || false} />
        <span className="checkmark"></span>
      </div>
    </>
  }
  toggleCheckBox = (slug) => {
    this.setState((prevState) => {
      this.setState((prevState) => {
        const selectedCheckboxes = { ...prevState.selectedCheckboxes };
        if (selectedCheckboxes[slug]) {
          delete selectedCheckboxes[slug];
        } else {
          selectedCheckboxes[slug] = true;
        }
        return { selectedCheckboxes };
      })
    }, () => {
      this.randerData();
    });
  };
  handleSelectAll = () => {
    this.setState((prevState) => {
      const { selectAll, results } = prevState;
      const updatedSelectedCheckboxes = { ...prevState.selectedCheckboxes };
      if (selectAll) {
        results.forEach((sale) => {
          updatedSelectedCheckboxes[sale.slug] = false;
        });
      } else {
        results.forEach((sale) => {
          updatedSelectedCheckboxes[sale.slug] = true;
        });
      }
      const newState = {
        selectAll: !selectAll,
        selectedCheckboxes: selectAll ? {} : updatedSelectedCheckboxes,
      };
      return newState;
    }, () => {
      this.randerData();
    });
  }
  randerData = () => {
    let listings = [];
    if (this.state.randerResult) {
      listings = this.state.randerResult.map((i) => {
        const createdDate = i.createdAt
          ? moment(i.createdAt).calendar()
          : undefined;
        const updatedDate = i.updatedAt
          ? moment(i.updatedAt).calendar()
          : undefined;
        const publishedDate = i.publishedAt
          ? moment(i.publishedAt).calendar()
          : undefined;
        const unPublishedDate = i.unPublishedAt
          ? moment(i.unPublishedAt).calendar()
          : undefined;
        return {
          id: i.id,
          checkBox: this.statusCheckBox(i.slug),
          status: this.statusIcon(i.status, i.slug),
          title: i.title,
          slug: i.slug,
          make: i.saleDetails.model.make.name,
          model: i.saleDetails.model.name,
          serial: i.saleDetails.serial,
          registration: i.saleDetails.registration,
          year: i.saleDetails.year,
          price: i.saleDetails.price,
          company: i.company ? i.company.name : "",
          rep: i.account.profile.name,
          createdAt: createdDate,
          updatedAt: updatedDate,
          publishedAt: publishedDate,
          unPublishedAt: unPublishedDate,
          views: i.stats.detail,
          leads: i.stats.inquiries,
          favorites: i.stats.favorites,
          oCompany: i.company,
          oAccount: i.account,
          oStatus: i.status,
        };
      });
    }

    this.setState(
      {
        isLoading: false,
        error: undefined,
        results: listings,       
      },
      () => {
        this.props.onFetchSuccess &&
          this.props.onFetchSuccess(this.state);
      }
    );
  }
  isDropdownVisible = () => {
    const { selectAll, selectedCheckboxes } = this.state;
    const anySelected = Object.values(selectedCheckboxes).some(value => value);
    return selectAll || anySelected || Object.keys(selectedCheckboxes).length > 0;
  };

  handleStatusChange = (e) => {
    e.persist();
    var selectedIds = Object.keys(this.state.selectedCheckboxes);
    console.log("Enter in........");
    const modifiedURL = this.removeQueryParamsFromURL(this.props.location.pathname);
    var target = '';
    if (e.target.value === 'published') {
      target = 'publish'
    } else if (e.target.value === 'removed') {
      target = 'remove'
    } else if (e.target.value === 'deleted') {
      target = 'delete'
    } else {
      target = '';
    }
    var count = selectedIds.length;
    if (count > 0) {
      this.props.openMessageModal(
        `Please confirm you wish to ${target} ${count} records`,
        () => (
          <>
            <button
              className="btn btn-secondary btn-green"
              onClick={() => {
                this.props.updateStatusListing(selectedIds, e.target.value, modifiedURL);
                this.props.setLoading(true);
              }}
            >
              YES
            </button>

            <button
              className="btn btn-secondary btn-red"
              onClick={() => {
                this.props.closeMessageModal();
              }}
            >
              NO
            </button></>
        )
      );
    }
    console.log("open model")
  }

  removeQueryParamsFromURL(url) {
    const questionMarkIndex = url.indexOf("?");
    if (questionMarkIndex !== -1) {
      return url.slice(0, questionMarkIndex);
    }
    return url;
  }

  fetchResults(isStatusChnage = null) {
    let fetchUrl = this.parseRequestUrl();
    if (this.state.fetchUrl !== fetchUrl) {
      // Fetch URL has changed. Update state and get new results
      this.setState(
        {
          fetchUrl: fetchUrl,
          isLoading: true,
          results: [],
          error: undefined,
          count: 0,
          pages: 0,
        },
        () => {
          if (fetchUrl) {
            axios
              .get(fetchUrl)
              .then((response) => {
                const results = response.data.data.rows;
                this.setState(
                  {
                    randerResult: results,
                    count: response.data.data.count,
                    pages: response.data.data.pageCount,

                  }, () => {
                    this.randerData();
                    this.isDropdownVisible();
                    this.props.onFetchSuccess &&
                      this.props.onFetchSuccess(this.state);
                  })  

                // let listings = [];
                // if (results) {
                //   listings = results.map((i) => {
                //     const createdDate = i.createdAt
                //       ? moment(i.createdAt).calendar()
                //       : undefined;
                //     const updatedDate = i.updatedAt
                //       ? moment(i.updatedAt).calendar()
                //       : undefined;
                //     const publishedDate = i.publishedAt
                //       ? moment(i.publishedAt).calendar()
                //       : undefined;
                //     const unPublishedDate = i.unPublishedAt
                //       ? moment(i.unPublishedAt).calendar()
                //       : undefined;
                //     return {
                //       id: i.id,
                //       checkBox: this.statusCheckBox(i.slug),
                //       status: this.statusIcon(i.status, i.slug),
                //       title: i.title,
                //       slug: i.slug,
                //       make: i.saleDetails.model.make.name,
                //       model: i.saleDetails.model.name,
                //       serial: i.saleDetails.serial,
                //       registration: i.saleDetails.registration,
                //       year: i.saleDetails.year,
                //       price: i.saleDetails.price,
                //       company: i.company ? i.company.name : "",
                //       rep: i.account.profile.name,
                //       createdAt: createdDate,
                //       updatedAt: updatedDate,
                //       publishedAt: publishedDate,
                //       unPublishedAt: unPublishedDate,
                //       views: i.stats.detail,
                //       leads: i.stats.inquiries,
                //       favorites: i.stats.favorites,
                //       oCompany: i.company,
                //       oAccount: i.account,
                //       oStatus: i.status,
                //     };
                //   });
                // }

                // this.setState(
                //   {
                //     isLoading: false,
                //     error: undefined,
                //     results: listings,
                //     count: response.data.data.count,
                //     pages: response.data.data.pageCount,
                //   },
                //   () => {
                //     this.props.onFetchSuccess &&
                //       this.props.onFetchSuccess(this.state);
                //   }
                // );
              })
              .catch((error) => {
                this.setState(
                  {
                    isLoading: false,
                    results: [],
                    error: error,
                    count: 0,
                    pages: 0,
                  },
                  () => {
                    this.props.onFetchError &&
                      this.props.onFetchError(this.state);
                  }
                );
              });
          }
        }
      );
    }
  }

  handlePageLimitChange = (event) => {
    const target = event.currentTarget;

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, show: target.value };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };
  getPageLimit = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const pageLimit = currentParams["show"] || 10;
    return parseInt(pageLimit);
  };
  handlePageChange = (pager) => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, page: pager.currentPage };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });
    this.setState({
      selectedCheckboxes: {}, // Initialize as an empty object
      selectAll: '',
    });
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };
  getPage = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const page = currentParams["page"] || 1;
    return parseInt(page);
  };

  handleSearchChange = (e) => {
    this.setState({ search: e.target.value });
  };

  handleSearchSubmit = (e) => {
    e.preventDefault();

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, keyword: this.state.search };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };

  publishButton = (row) => {
    if (["draft", "pending"].includes(row.oStatus.value)) {
      return (
        <Link
          to={generatePath(Routes.forSaleStatus, {
            listing: row.slug,
            status: "published",
          })}
          className="btn btn-primary btn-green"
        >
          Publish
        </Link>
      );
    }
    return <></>;
  };

  removeButton = (row) => {
    if (row.oStatus.value === "published" || row.oStatus.value === "sold") {
      return (
        <Link
          to={generatePath(Routes.forSaleStatus, {
            listing: row.slug,
            status: "removed",
          })}
          className="btn btn-red"
        >
          Remove
        </Link>
      );
    }
    return <></>;
  };

  deleteButton = (row) => {
    const { userInfo } = this.props;
    if (userInfo.role && userInfo.role.value) {
      if (userInfo.role.value === "super-admin") {
        return (
          <Link
            to={generatePath(Routes.forSaleStatus, {
              listing: row.slug,
              status: "deleted",
            })}
            className="btn btn-red"
          >
            Delete
          </Link>
        );
      } else if (
        userInfo.role.value === "company-admin" ||
        row.oAccount.email === userInfo.email
      ) {
        if (row.oStatus.value === "draft") {
          return (
            <Link
              to={generatePath(Routes.forSaleStatus, {
                listing: row.slug,
                status: "deleted",
              })}
              className="btn btn-red"
            >
              Delete Draft
            </Link>
          );
        }
      }
    }

    return <></>;
  };

  soldButton = (row) => {
    if (row.oStatus.value === "published") {
      return (
        <Link
          to={generatePath(Routes.forSaleStatus, {
            listing: row.slug,
            status: "sold",
          })}
          className="btn btn-red"
        >
          Mark as Sold
        </Link>
      );
    }
    return <></>;
  };

  restoreButton = (row) => {
    if (row.oStatus.value === "sold" || row.oStatus.value === "removed") {
      return (
        <Link
          to={generatePath(Routes.forSaleStatus, {
            listing: row.slug,
            status: "published",
          })}
          className="btn btn-red"
        >
          Restore
        </Link>
      );
    }
    return <></>;
  };

  pageTitle(status) {
    switch (status) {
      case "archived":
        return (
          <span>
            Aircraft For Sale <strong>Archive</strong>
          </span>
        );
      default:
        return (
          <span>
            Aircraft <strong>For Sale</strong>
          </span>
        );
    }
  }

  pageActions(status) {
    switch (status) {
      case "archived":
        return (
          <>
            <Link className="btn btn-red" to={Routes.forSaleManage}>
              BACK TO ACTIVE
            </Link>
          </>
        );
      default:
        return (
          <>
            <RoleRenderer role="sales-rep">
              <Link
                className="btn btn-green m-b-15"
                to={generatePath(Routes.forSaleEdit, {
                  listing: null,
                  step: null,
                })}
              >
                + New
              </Link>
            </RoleRenderer>
            <Link className="btn btn-red" to={Routes.forSaleArchive}>
              ARCHIVE
            </Link>
          </>
        );
    }
  }

  iconHeader(column, colIndex) {
    switch (column.dataField) {
      case "favorites":
        return <FontAwesomeIcon icon={faHeart} title={column.text} />;
      default:
        return <></>;
    }
  }

  render() {
    const columnsFirst = [
      {
        dataField: "checkBox",
        text: "",
        sort: false,
        headerFormatter: (column, colIndex) => (
          <>
            <div className="custom-checkbox-2" onClick={() => this.handleSelectAll()}>
              <input type="checkbox" name='changeStatus' checked={this.state.selectAll} />
              <span className="checkmark"></span>
            </div>
          </>
        ),
      },
      {
        dataField: "status",
        text: "",
        sort: false,
        headerFormatter: (column, colIndex) => (
          <MultiSelectFilter
            column={column}
            items={this.state.filterStatuses}
          />
        ),
      },
      {
        dataField: "make",
        text: "Make",
        sort: false,
        headerFormatter: (column, colIndex) => (
          <MultiSelectFilter column={column} items={this.state.filterMakes} />
        ),
      },
      {
        dataField: "model",
        text: "Model",
        sort: false,
        headerFormatter: (column, colIndex) => (
          <MultiSelectFilter column={column} items={this.state.filterModels} />
        ),
      },
      {
        dataField: "serial",
        text: "SN #",
        sort: true,
      },
      {
        dataField: "registration",
        text: "Reg #",
        sort: true,
      },
      {
        dataField: "year",
        text: "Year",
        sort: true,
      },
      {
        dataField: "price",
        text: "Price",
        sort: true,
      },
    ];

    const columnsStats = [
      {
        dataField: "views",
        text: "Views",
        sort: false,
      },
      {
        dataField: "leads",
        text: "Leads",
        sort: false,
      },
      {
        dataField: "favorites",
        text: "Favorites",
        sort: false,
        headerFormatter: this.iconHeader,
      },
    ];

    const roleColumns = [];
    const { userInfo } = this.props;
    if (userInfo.role && userInfo.role.value) {
      switch (userInfo.role.value) {
        case "super-admin":
          roleColumns.push({
            dataField: "company",
            text: "Company",
            sort: false,
            headerFormatter: (column, colIndex) => (
              <MultiSelectFilter
                column={column}
                items={this.state.filterCompanies}
              />
            ),
          });
        case "company-admin":
          roleColumns.push({
            dataField: "rep",
            text: "Sales Rep",
            sort: false,
            headerFormatter: (column, colIndex) => (
              <MultiSelectFilter
                column={column}
                items={this.state.filterReps}
              />
            ),
          });
          break;
        case "sales-rep":
        case "sales-rep-view":
        case "advertiser":
        default:
          break;
      }
    }

    const columnsDates = [];
    if (this.props.status && this.props.status === "archived") {
      // Add Archived Columns
      columnsDates.push(
        {
          dataField: "publishedAt",
          text: "Published",
          sort: true,
        },
        {
          dataField: "unPublishedAt",
          text: "UnPublished",
          sort: false,
        }
      );
    } else {
      // Add Published/Draft Columns
      columnsDates.push(
        {
          dataField: "createdAt",
          text: "Created",
          sort: true,
        },
        {
          dataField: "updatedAt",
          text: "Updated",
          sort: true,
        },
        {
          dataField: "publishedAt",
          text: "Published",
          sort: true,
        }
      );
    }
    const columns = [
      ...columnsFirst,
      ...roleColumns,
      ...columnsDates,
      ...columnsStats,
    ];

    return (
      <section
        id="app-manage-table"
        className="app-admin-sales app-main manage-listings-index"
      >
        <div className="d-flex justify-content-between m-b-15">
          <div className="title">{this.pageTitle(this.props.status)}</div>
          <div className="d-flex flex-column align-items-end">
            {this.pageActions(this.props.status)}
          </div>
        </div>
        <div className="table-layout" id="table-sales">
          <header className="table-top table-header">
            <Row>
              <Col
                sm={6}
                className="table-header-actions table-actions justify-content-center justify-content-sm-start"
              >
                {console.log("this.props.status - ", this.props.status)}
                <select
                  id="change-status"
                  name="changeStatus"
                  label=""
                  onChange={this.handleStatusChange}
                  disabled={!this.isDropdownVisible()}
                >
                  <option value='' key=''>Bulk Actions</option>
                  {this.props.status === 'archived' ? (
                    <option value='published' key='restore'>Restore</option>
                  ) : (
                    <><option value='published' key='restore'>Publish</option>
                      <option value='removed' key='remove'>Remove</option></>
                  )}

                  <option value='deleted' key='delete'>Delete</option>
                </select>
              </Col>
              <Col
                sm={6}
                className="table-header-search table-search justify-content-center justify-content-sm-end"
              >
                <Form onSubmit={this.handleSearchSubmit}>
                  <Form.Control
                    as="input"
                    placeholder="Search"
                    onChange={this.handleSearchChange}
                  />
                  <button type="submit" className="btn btn-icon">
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </Form>
              </Col>
            </Row>
          </header>
          {console.log('this.state.results', this.state.results)}
          <Table
            id="sales"
            keyField="id"
            //columnHeader={columns}
            columns={columns}

            data={this.state.results}
            //data={this.state.listings}
            // selectRow={{
            //   mode: "checkbox",
            //   clickToSelect: false,
            //   clickToExpand: true,
            //   selected: this.state.selected,
            //   //onClick: this.handleOnClick,
            //   onSelect: this.handleOnSelect,
            //   onSelectAll: this.handleOnSelectAll
            // }}
            expandRow={{
              onlyOneExpanding: true,
              renderer: (row) => {
                return (
                  <div className="row-actions">
                    <Link
                      to={generatePath(Routes.forSaleDetail, {
                        listing: row.slug,
                      })}
                      className="btn btn-green"
                      target="_blank"
                    >
                      View
                    </Link>
                    <RoleRenderer role="sales-rep">
                      <Link
                        to={generatePath(Routes.forSaleEdit, {
                          listing: row.slug,
                          step: null,
                        })}
                        className="btn btn-green"
                      >
                        Edit
                      </Link>
                    </RoleRenderer>
                    <RoleRenderer role="sales-rep">
                      <Button
                        className="btn btn-green"
                        onClick={() => this.duplicateListing(row.slug)}
                      >
                        Duplicate
                      </Button>
                      {this.soldButton(row)}
                      {this.removeButton(row)}
                    </RoleRenderer>

                    <RoleRenderer role="company-admin">
                      {this.publishButton(row)}
                    </RoleRenderer>
                    <RoleRenderer role="super-admin">
                      {this.restoreButton(row)}
                    </RoleRenderer>
                    {this.deleteButton(row)}
                  </div>
                );
              },
            }}
          />
          <footer className="table-bottom table-footer">
            <Row>
              <Col
                sm={6}
                className="table-footer-stats table-stats justify-content-center justify-content-sm-start"
              >
                <span className="row-count">
                  {this.state.count} total records. {this.state.results.length}{" "}
                  rows showing ({this.state.selected.length} selected)
                </span>
              </Col>
              <Col
                sm={6}
                className="table-footer-pagination table-pagination text-sm-right justify-content-center justify-content-sm-end"
              >
                <Pagination
                  totalRecords={this.state.count}
                  pageLimit={this.getPageLimit()}
                  pageNeighbours={1}
                  onPageChanged={this.handlePageChange}
                  currentPage={this.getPage()}
                />
              </Col>
            </Row>
          </footer>
        </div>
        <SubscriptionInfo />
      </section>
    );
  }
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    openMessageModal: (message, actions) =>
      dispatch(openMessageModal({ message, actions })),
    closeMessageModal: () => dispatch(closeMessageModal()),
    updateStatusListing: (selectedIds, status, modifiedURL) =>
      dispatch(updateStatusListing(selectedIds, status, modifiedURL)),
  };
};
export default connect(keyStateToProps, keyDispatchToProps)(ForSaleManage);
