import axios from "axios";
import apis from "apis";

import { GET_NOTIFICATIONS, SET_NOTIFICATIONS } from "./actionTypes.js";

export const getNotifications = () => (dispatch) => {
  dispatch({
    type: GET_NOTIFICATIONS,
  });

  return axios({
    method: "get",
    url: `${apis.notifications}/recent`,
  })
    .then((response) => {
      const { rows } = response.data.data;
      dispatch(setNotifications(rows));
      return rows;
    })
    .catch((error) => {
      throw error;
    });
};

export function setNotifications(notification) {
  return {
    type: SET_NOTIFICATIONS,
    payload: notification,
  };
}
