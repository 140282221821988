import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { setToast } from "actions/toast/actions.js";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "components/shared/spinner";
import { Formik } from "formik";

import {
  getLists,
  getSubscriptions,
  updateSubscriptions,
} from "actions/email/actions";

import { parseAxiosError } from "utils/errors";

const EmailContent = (props) => {
  const { user, lists } = props;
  const { getLists, getSubscriptions, updateSubscriptions, setToast } = props;

  const [error, setError] = useState();
  const [subscriptions, setSubscriptions] = useState();

  useEffect(() => {
    try {
      getLists();
      getSubscriptions(user.email)
        .then((subs) => {
          setSubscriptions(subs);
        })
        .catch(() => {
          setSubscriptions([]);
        });
    } catch (ex) {
      setError(parseAxiosError(ex));
    }
  }, [user.email, getLists, getSubscriptions]);

  const handleSave = (values, { setSubmitting }) => {
    setSubmitting(true);
    updateSubscriptions(user.email, values.subscriptions)
      .then(() => {
        setToast({
          type: "success",
          message: "Email Preferences Successfully Updated",
        });
      })
      .catch((err) => {
        setToast({
          type: "error",
          message: "Unable to Update Email Preferences",
        });
        setError(parseAxiosError(err));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (error) {
    return (
      <div className="edit-error form-error error">
        <h1 className="favorite-title">Error!</h1>
        <p>{error}</p>
      </div>
    );
  }

  if (lists && subscriptions) {
    console.log("Drawing formik with defaults:");
    console.log(subscriptions);
    return (
      <Row>
        <Col xs={12}>
          <div className="subtitle">Update your Blitz email preferences.</div>
          <div className="email-form-title">
            Select the content you are most interested in. Selecting anything
            other than All Emails will limit the Blitzes you receive, including
            your own.
          </div>

          <Formik initialValues={{ subscriptions }} onSubmit={handleSave}>
            {({
              values,
              handleChange,
              handleSubmit,
              isSubmitting,
              setValues,
            }) => (
              <Form
                onSubmit={
                  isSubmitting
                    ? (e) => {
                        e.preventDefault();
                      }
                    : handleSubmit
                }
                className="email"
              >
                <div className="email-form">
                  {lists &&
                    lists.length > 0 &&
                    lists.map((list, index) => (
                      <Form.Group controlId={list.slug} key={index}>
                        <Form.Check
                          type="checkbox"
                          name="subscriptions"
                          value={list.slug}
                          checked={values.subscriptions.includes(list.slug)}
                          label={list.name}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    ))}
                </div>
                <div className="email-save">
                  <button
                    type="submit"
                    className="btn btn-tertiary email-form-save-button"
                  >
                    Save
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Col>
      </Row>
    );
  }

  return (
    <div className="form-loading edit-loading loading">
      <Spinner />
    </div>
  );
};

const keyStateToProps = (state) => {
  return {
    lists: state.lists,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    getLists: () => dispatch(getLists()),
    getSubscriptions: (email) => dispatch(getSubscriptions(email)),
    updateSubscriptions: (email, slugs) =>
      dispatch(updateSubscriptions(email, slugs)),
    setToast: (payload) => dispatch(setToast(payload)),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(EmailContent);
