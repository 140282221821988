import React from "react";

import { Row, Col, Tab } from "react-bootstrap";

import MultiImageBrickUpload from "components/backend/shared/multiImageBrickUpload";
import PromoteCalendar from "components/backend/promotions/shared/calendar";
import TextareaAutosize from "react-textarea-autosize";
const BlitzTab = (props) => {
  const handleChange = (evt) => {
    props.onChange && props.onChange(evt.target.name, evt.target.value);
  };

  return (
    <Tab.Pane eventKey="#blitz">
      {!props.active && <div className="form-overlay"></div>}
      <div className="form-content feature-detail py-2 px-4">
        <Row className={"mt-2"}>
          <Col xs={12}>
            <div>
              Highlights: &nbsp;
              <span className="text-green">{props.promotion.highlights}</span>
            </div>
          </Col>
        </Row>

        <Row className={"mt-4"}>
          <Col xs={12}>
            <h6>1. Any additional features you’d like to highlight?</h6>
          </Col>
          <Col xs={12}>
            <TextareaAutosize
              className={"w-100 mt-2"}
              rows="3"
              placeholder={"Word Count?"}
              name="content"
              onChange={handleChange}
            />
          </Col>
        </Row>

        <Row className={"mt-4"}>
          <Col xs={12}>
            <h6>
              2. Attach featured photos &nbsp;
              <label>Limit 5</label>
            </h6>
            <MultiImageBrickUpload
              images={props.promotion.images.map((i) => i.url)}
              maxImages={5}
              onChange={(images) => {
                console.log("Changed images");
                console.log(images);
                if (props.onImagesChange) {
                  props.onImagesChange(images);
                }
              }}
            />
          </Col>
        </Row>

        <Row className={"mt-4"}>
          <Col xs={12}>
            <h6>3. Please select desired promotion day.</h6>
            <div className="text-uppercase my-1">
              No dates are guaranteed and all are subject to review, approval,
              and availability of Plane Mover. A Plane Mover Representative will
              contact you with confirmation.
            </div>
          </Col>
          <Col className={"Calendar-div"} xs={12}>
            <PromoteCalendar
              selectSingle
              onChange={(date) => {
                if (props.onChange) {
                  props.onChange("startDate", date);
                }
              }}
            />
          </Col>
        </Row>
        <Row className={"mt-4"}>
          <Col xs={12}>
            <h6>4. Comments:</h6>
            <TextareaAutosize
               className={"w-100 mt-2"}
              //className="form-control"
              rows="7"
              name="comments"
              onChange={handleChange}
            />
          </Col>
        </Row>
      </div>
    </Tab.Pane>
  );
};

export default BlitzTab;
