import React, { useState, useEffect } from "react";
import axios from "axios";
import { connect } from "react-redux";

import { Container, Row, Col } from "react-bootstrap";
import Spinner from "components/shared/spinner";
import { Link, generatePath } from "react-router-dom";
import Routes, { createTitle } from "routes";
import { handleAxiosError, NotFoundError } from "components/shared/errors";
import {
  planLimitText,
  managePlanButton,
} from "components/backend/listings/shared/planLimits";

const Status = (props) => {
  const { userInfo } = props;
  const { status, listing } = props.match.params;
  const [error, setError] = useState();
  const [result, setResult] = useState();

  console.log(status);
  console.log(listing);

  useEffect(() => {
    document.title = createTitle(`Working...`, Routes);

    console.log(status);
    console.log(listing);

    if (!listing || !status)
      throw new NotFoundError("The link you clicked is not working.");

    axios
      .put(`/manage/slug/${listing}/status`, { status })
      .then((response) => {
        const values = response.data;
        document.title = createTitle(`Listing ${values.status.title}`, Routes);

        setResult(values);
      })
      .catch((err) => {
        setError(err);
      });
  }, [status, listing]);

  const displayMessage = (status) => {
    switch (status) {
      case "published":
        return displayPublishedContent();
      case "pending":
        return displayPendingContent();
      case "removed":
        return displayRemovedContent();
      case "deleted":
        return displayDeletedContent();
      case "limit":
        return displayPlanLimitContent();
      default:
        return displayDraftContent();
    }
  };

  const displayDraftContent = () => {
    return (
      <>
        <header>
          <div className="title">Draft Saved</div>
        </header>
        <div className="status-content mb-4">
          Your listing has been saved as a draft.
        </div>
      </>
    );
  };

  const displayPublishedContent = () => {
    return (
      <>
        <header>
          <div className="title">Good Job!</div>
        </header>
        <div className="status-content mb-4">
          <p>Your listing is published!</p>
          <Link
            className="btn btn-primary btn-green mr-3"
            target="_blank"
            to={generatePath(Routes.servicesDetail, {
              listing,
            })}
          >
            View Listing
          </Link>
          <Link className="btn btn-green mr-3" to={Routes.servicesManage}>
            Back to All Services
          </Link>
        </div>
      </>
    );
  };

  const displayPendingContent = () => {
    return (
      <>
        <header>
          <div className="title">Submitted for Review</div>
        </header>
        <div className="status-content mb-4">
          <p>
            Your user level requires your company administrator to approve your
            listings before they’re published. If you have any questions, please
            contact your company administrator.
          </p>
          <Link className="btn btn-green mr-3" to={Routes.servicesManage}>
            Back to All Services
          </Link>
        </div>
      </>
    );
  };

  const displayRemovedContent = () => {
    return (
      <>
        <header>
          <div className="title">Listing Removed</div>
        </header>
        <div className="status-content mb-4">
          <p>
            Your listing has been removed from public view, but you can still
            view its details in your archive.{" "}
            <Link className="btn btn-red ml-3" to={Routes.servicesArchive}>
              View Archive
            </Link>
          </p>
          <Link className="btn btn-green" to={Routes.servicesManage}>
            Back to All Services
          </Link>
        </div>
      </>
    );
  };

  const displayDeletedContent = () => {
    return (
      <>
        <header>
          <div className="title">Listing Deleted</div>
        </header>
        <div className="status-content mb-4">
          <p>Your listing has been permanently deleted.</p>
          <Link className="btn btn-green" to={Routes.servicesManage}>
            Back to All Services
          </Link>
        </div>
      </>
    );
  };

  const displayPlanLimitContent = () => {
    return (
      <>
        <header>
          <div className="title">Not Cleared For Takeoff</div>
        </header>
        <div className="status-content mb-4">
          <p>
            Your listing cannot be published because you have reached your plan
            limit.
          </p>
          <p>{planLimitText(userInfo)}</p>
          <p>
            Don’t worry. Your listing has been saved as a draft so you can
            publish it after you upgrade!
          </p>
          {managePlanButton(userInfo)}
          <br />
          <Link className="" to={Routes.servicesManage}>
            Back to My Services
          </Link>
        </div>
      </>
    );
  };

  if (error) {
    if (
      error.response &&
      error.response.status &&
      error.response.status === 402
    ) {
      switch (error.response.status) {
        case 402:
          return (
            <section id="app-manage-table" className="app-admin-sales app-main">
              <Container>
                <Row>
                  <Col>{displayMessage("limit")}</Col>
                </Row>
              </Container>
            </section>
          );
        default:
          handleAxiosError(error);
          break;
      }
    } else {
      handleAxiosError(error);
    }
  } else if (result) {
    return (
      <section id="app-manage-table" className="app-admin-sales app-main">
        <Container>
          <Row>
            <Col>{displayMessage(status)}</Col>
          </Row>
        </Container>
      </section>
    );
  } else {
    return (
      <div className="form-loading edit-loading loading">
        <Spinner />
      </div>
    );
  }
};

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {};
};

export default connect(keyStateToProps, keyDispatchToProps)(Status);
