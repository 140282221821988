import React from "react";
import { Link, generatePath } from "react-router-dom";
import axios from "axios";

import { connect } from "react-redux";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Routes, { createTitle } from "routes";

import { ReactComponent as AirplaneAscendingIcon } from "images/icons/airplane-ascent.svg";
import { ReactComponent as AirplaneDescendingIcon } from "images/icons/airplane-descent.svg";
import { ReactComponent as ServicesIcon } from "images/icons/services.svg";
import DigitalChannelsImg from "images/content/advertise/planemover-about-digital-channels.png";
import EmailBlitzImg from "images/content/advertise/planemover-about-email-blitz.png";
import ListingsImg from "images/content/advertise/planemover-about-listings.png";
import SocialMediaImg from "images/content/advertise/planemover-about-social-media.png";
import RoleRenderer from "components/shared/roleRenderer";

class AdvertisePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      results: [],
      error: undefined,
    };
  }

  componentDidMount() {
    document.title = Routes.site;
    document.title = createTitle("Advertise", Routes);

    axios.get("/settings/advertise").then((response) => {
      this.setState({
        isLoading: false,
        error: undefined,
        results: response.data.rows,
      });
    });
  }

  getSettingValue = (section) => {
    if (this.state.results) {
      const thisSetting = this.state.results
        .filter((s) => s.section === section)
        .shift();
      if (thisSetting) return thisSetting.content;
    }

    return;
  };

  render() {
    return (
      <section
        id="app-page-advertise"
        className="app-advertise-page advertise-page"
      >
        <header className="advertise-page-hader page-header">
          <Container
            id="advertise-header-row"
            className="header-row"
            fluid={true}
          >
            <Row>
              <Col className="text-center justify-content-center">
                <h1
                  className="page-title"
                  dangerouslySetInnerHTML={{
                    __html: this.getSettingValue("page-title"),
                  }}
                />
                <div
                  className="sub-header"
                  dangerouslySetInnerHTML={{
                    __html: this.getSettingValue("page-subtitle"),
                  }}
                />
              </Col>
            </Row>
          </Container>
        </header>

        <Container id="advertise-multichannel-row" fluid={true}>
          <Row>
            <Col xs={12} md={6} className="section-text-column px-0">
              <Container className="container-half container-half-md-left">
                <Row>
                  <Col>
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: this.getSettingValue(
                          "section-advertising-title"
                        ),
                      }}
                    />
                    <p
                      className="section-subheader wanted-subheader"
                      dangerouslySetInnerHTML={{
                        __html: this.getSettingValue(
                          "section-advertising-description"
                        ),
                      }}
                    />
                    <Link
                      to={{ pathname: Routes.pricing }}
                      className="btn btn-primary d-none d-md-inline-block btn-lg"
                    >
                      {this.getSettingValue(
                        "section-advertising-started-button"
                      )}
                    </Link>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs={12} md={6} className="section-image-column px-0">
              <Link
                to={{ pathname: Routes.pricing }}
                className="btn btn-primary d-md-none btn-lg"
              >
                {this.getSettingValue("section-advertising-started-button")}
              </Link>
            </Col>
          </Row>
        </Container>

        <Container id="advertise-promote-row" fluid={true}>
          <Container>
            <Row>
              <Col className="text-center justify-content-center">
                <h2>
                  <strong>Promote Your</strong>
                </h2>
                <Row>
                  <Col xs={12} sm={4}>
                    <span className="icon-bubble">
                      <AirplaneAscendingIcon />
                    </span>
                    <h3>Aircraft For Sale</h3>
                  </Col>
                  <Col xs={12} sm={4}>
                    <span className="icon-bubble">
                      <AirplaneDescendingIcon />
                    </span>
                    <h3>Aircraft Wanted</h3>
                  </Col>
                  <Col xs={12} sm={4}>
                    <span className="icon-bubble">
                      <ServicesIcon />
                    </span>
                    <h3>Aviation Services</h3>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container id="advertise-experience-row" fluid={true}>
          <Container>
            <Row className="align-items-center mx-n5">
              <Col xs={12} md={8} className="section-text-column px-5">
                <h2
                  dangerouslySetInnerHTML={{
                    __html: this.getSettingValue("section-experience-title"),
                  }}
                />
                <p
                  className="section-subheader wanted-subheader"
                  dangerouslySetInnerHTML={{
                    __html: this.getSettingValue(
                      "section-experience-description"
                    ),
                  }}
                />
              </Col>
              <Col xs={12} md={4} className="section-image-column px-5">
                <div className="image-pane">
                  <img
                    src={DigitalChannelsImg}
                    alt="Three digital channels pie chart: Online Listings, Email Blitz, and Social Media"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Container>

        <Container id="advertise-listings-row" fluid={true}>
          <Row>
            <Col xs={12} md={6} className="section-image-column px-0">
              <Container className="container-half container-half-md-left">
                <Row>
                  <Col>
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: this.getSettingValue("section-listings-title"),
                      }}
                    />
                    <div className="image-pane">
                      <img
                        src={ListingsImg}
                        alt="Desktop search of online listings"
                        className="img-fluid"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs={12} md={6} className="section-text-column px-0">
              <Container className="container-half container-half-md-right">
                <Row>
                  <Col>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: this.getSettingValue(
                          "section-listings-subtitle"
                        ),
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.getSettingValue(
                          "section-listings-description"
                        ),
                      }}
                    />
                    <div className="action-buttons">
                      <a
                        href="https://s3.us-east-2.amazonaws.com/images.planemover.com/advertise/PM_1908-0008+Media+Kit+Dec+2021+V1+(listings).pdf"
                        className="btn btn-green btn-wide btn-lg"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {this.getSettingValue("section-listings-learn-button")}
                      </a>
                      <RoleRenderer role="advertiser">
                        <Link
                          to={generatePath(Routes.forSaleEdit, {
                            listing: null,
                            step: null,
                          })}
                          className="btn btn-primary btn-wide btn-lg"
                        >
                          Create Listing
                        </Link>
                      </RoleRenderer>
                      <RoleRenderer anonymous>
                        <Link
                          to={{ pathname: Routes.pricing }}
                          className="btn btn-primary btn-wide btn-lg"
                        >
                          {this.getSettingValue(
                            "section-listings-signup-button"
                          )}
                        </Link>
                      </RoleRenderer>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container id="advertise-blitz-row" fluid={true}>
          <Row>
            <Col xs={12} md={6} className="section-image-column px-0">
              <Container className="container-half container-half-md-left">
                <Row>
                  <Col>
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: this.getSettingValue("section-email-title"),
                      }}
                    />
                    <div className="image-pane">
                      <img
                        src={EmailBlitzImg}
                        alt="Sample email blitz screenshot layout"
                        className="img-fluid"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs={12} md={6} className="section-text-column px-0">
              <Container className="container-half container-half-md-right">
                <Row>
                  <Col>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: this.getSettingValue("section-email-subtitle"),
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.getSettingValue(
                          "section-email-description"
                        ),
                      }}
                    />
                    <div className="action-buttons">
                      <a
                        href="https://s3.us-east-2.amazonaws.com/images.planemover.com/advertise/PM_1908-0008+Media+Kit+Dec+2021+V1+(email).pdf"
                        className="btn btn-green btn-wide btn-lg"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {this.getSettingValue("section-email-learn-button")}
                      </a>
                      <RoleRenderer role="advertiser">
                        <Link
                          to={{ pathname: Routes.promotions }}
                          className="btn btn-primary btn-wide btn-lg"
                        >
                          Request a Blitz
                        </Link>
                      </RoleRenderer>
                      <RoleRenderer anonymous>
                        <Link
                          to={{ pathname: Routes.pricing }}
                          className="btn btn-primary btn-wide btn-lg"
                        >
                          {this.getSettingValue("section-email-signup-button")}
                        </Link>
                      </RoleRenderer>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>

        <Container id="advertise-social-row" fluid={true}>
          <Row>
            <Col xs={12} md={6} className="section-image-column px-0">
              <Container className="container-half container-half-md-left">
                <Row>
                  <Col>
                    <h2
                      dangerouslySetInnerHTML={{
                        __html: this.getSettingValue("section-social-title"),
                      }}
                    />
                    <div className="image-pane">
                      <img
                        src={SocialMediaImg}
                        alt="Mobile social media feed screenshot"
                        className="img-fluid"
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs={12} md={6} className="section-text-column px-0">
              <Container className="container-half container-half-md-right">
                <Row>
                  <Col>
                    <h3
                      dangerouslySetInnerHTML={{
                        __html: this.getSettingValue("section-social-subtitle"),
                      }}
                    />
                    <p
                      dangerouslySetInnerHTML={{
                        __html: this.getSettingValue(
                          "section-social-description"
                        ),
                      }}
                    />
                    <div className="action-buttons">
                      <a
                        href="https://s3.us-east-2.amazonaws.com/images.planemover.com/advertise/PM_1908-0008+Media+Kit+Dec+2021+V1+(social).pdf"
                        className="btn btn-green btn-wide btn-lg"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {this.getSettingValue("section-social-learn-button")}
                      </a>
                      <RoleRenderer role="advertiser">
                        <Link
                          to={{ pathname: Routes.promotions }}
                          className="btn btn-primary btn-wide btn-lg"
                        >
                          Promote on Social Media
                        </Link>
                      </RoleRenderer>
                      <RoleRenderer anonymous>
                        <Link
                          to={{ pathname: Routes.pricing }}
                          className="btn btn-primary btn-wide btn-lg"
                        >
                          {this.getSettingValue("section-social-signup-button")}
                        </Link>
                      </RoleRenderer>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {};
};

export default connect(keyStateToProps, keyDispatchToProps)(AdvertisePage);
