import React from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";

import { Collapse } from "react-collapse";
import classNames from "classnames";

import { ReactComponent as ArrowIcon } from "images/icons/arrow-up.svg";

import FilterCheckbox from "components/frontend/listings/_shared/filterCheckbox";
import FilterDropdown from "components/frontend/listings/_shared/filterDropdown";
import FilterControl from "components/frontend/listings/_shared/filterControl";

class FilterGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpened: this.props.isOpened || false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.handleDropdownChange = this.handleDropdownChange.bind(this);
    this.getTheseParamValues = this.getTheseParamValues.bind(this);
    this.getTheseParamCounts = this.getTheseParamCounts.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  componentDidMount() {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    if (this.props.name) {
      // Group of multi-select controls
      if (currentParams[this.props.name]) {
        // At least 1 item is visible in thi group. Change it to be displayed.
        this.setState({ isOpened: true });
      }
    } else {
      // Must be several individual controls.
      React.Children.map(this.props.children, (child) => {
        if (currentParams[child.props.name]) {
          // At least 1 item is visible in thi group. Change it to be displayed.
          this.setState({ isOpened: true });
        }
      });
    }
  }

  getTheseParamValues = (key) => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    let theseParams = [];
    if (currentParams[key]) {
      if (Array.isArray(currentParams[key])) {
        theseParams = currentParams[key] || [];
      } else {
        theseParams = currentParams[key].split(",") || [];
      }
    }
    return theseParams;
  };

  getTheseParamCounts = (key) => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    if (currentParams[key]) {
      if (Array.isArray(currentParams[key])) {
        return currentParams[key].length + " Selected";
      } else {
        return "1 Selected";
      }
    }
    return "";
  };

  cleanParams = (obj) =>
    Object.keys(obj)
      .filter((k) => obj[k] != null) // Remove undef. and null.
      .reduce((newObj, k) => ({ ...newObj, [k]: obj[k] }), {});

  handleChange = (event, component) => {
    const target = event.currentTarget;

    let updatedObj = null;
    switch (component.type) {
      case FilterCheckbox:
        updatedObj = this.handleCheckboxChange(target);
        break;
      case FilterDropdown:
        updatedObj = this.handleDropdownChange(target);
        break;
      case FilterControl:
        updatedObj = this.handleControlChange(target);
        break;
      default:
        break;
    }

    if (updatedObj) {
      if (this.props.onChange) {
        this.props.onChange(this.cleanParams(updatedObj));
      } else {
        const updatedParams = queryString.stringify(
          this.cleanParams(updatedObj),
          {
            arrayFormat: "comma",
          }
        );

        this.props.history.push({
          pathname: this.props.location.pathname,
          search: "?" + new URLSearchParams(updatedParams).toString(),
        });
      }
    }
  };

  handleCheckboxChange = (target) => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    let theseParams = this.getTheseParamValues(this.props.name);
    if (target.checked) {
      // Add this value to our params
      if (!theseParams.includes(target.value)) theseParams.push(target.value);
    } else {
      // Remove this value from our params
      theseParams.splice(theseParams.indexOf(target.value), 1);
    }

    const updatedObj = { ...currentParams, [this.props.name]: theseParams };
    return updatedObj;
  };

  handleDropdownChange = (target) => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    let theseParams = this.getTheseParamValues(target.name);
    // Only allow a single value here
    let thisParam = theseParams.shift();
    if (target.value) {
      if (thisParam !== target.value) {
        thisParam = target.value;
      }
    } else {
      // Remove the key entirely
      thisParam = null;
    }

    const updatedObj = { ...currentParams, [target.name]: thisParam };
    return updatedObj;
  };

  handleControlChange = (target) => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    let theseParams = this.getTheseParamValues(target.name);
    // Only allow a single value here
    let thisParam = theseParams.shift();
    if (target.value) {
      if (thisParam !== target.value) {
        thisParam = target.value;
      }
    } else {
      // Remove the key entirely
      thisParam = null;
    }

    const updatedObj = { ...currentParams, [target.name]: thisParam };
    return updatedObj;
  };

  toggleCollapse(evt) {
    this.setState({ isOpened: !this.state.isOpened });
  }

  render() {
    const { id, className, label, children } = this.props;

    return (
      <div
        className={classNames(
          this.state.isOpened ? "is-expanded" : "is-collapsed",
          className,
          "filter-group"
        )}
      >
        <h4 onClick={this.toggleCollapse}>
          <ArrowIcon />
          <span className="filter-group-title">{label}</span>
          <span className="filter-group-count">
            {this.getTheseParamCounts()}
          </span>
        </h4>
        <Collapse isOpened={this.state.isOpened}>
          {React.Children.map(children, (child) => {
            switch (child.type) {
              case FilterCheckbox:
                const theseParams = this.getTheseParamValues(this.props.name);
                return React.cloneElement(child, {
                  id: id + "-" + child.props.value,
                  checked: theseParams.includes(child.props.value.toString()),
                  onChange: (e) => this.handleChange(e, child),
                });
              case FilterControl:
                const controlParam = this.getTheseParamValues(
                  child.props.name
                ).shift();
                return React.cloneElement(child, {
                  id: id + "-" + child.props.value,
                  value: controlParam,
                  onBlur: (e) => this.handleChange(e, child),
                });
              case FilterDropdown:
              default:
                const dropdownParam = this.getTheseParamValues(
                  child.props.name
                ).shift();
                return React.cloneElement(child, {
                  id: id + "-" + child.props.value,
                  value: dropdownParam,
                  onChange: (e) => this.handleChange(e, child),
                });
            }
          })}
        </Collapse>
      </div>
    );
  }
}

export default withRouter(FilterGroup);
