import React, { Component } from "react";
import Routes, { createTitle } from "routes";

import PromotionList from "../shared/list";
import PromotionEdit from "./edit";

class FeaturedPromotions extends Component {
  componentDidMount() {
    document.title = createTitle(`Featured Promotions`, Routes);
  }

  render() {
    return (
      <PromotionList
        title="Featured Listing"
        url="/promotion/type/featured"
        editor={PromotionEdit}
      />
    );
  }
}

export default FeaturedPromotions;
