import axios from "axios";
import apis from "apis";
import {
  GET_COUNTRIES,
  SET_COUNTRIES,
  GET_STATES,
  SET_STATES,
  SET_ACCOUNT_ROLES,
  GET_ACCOUNT_ROLES,
  SET_ACCOUNT_STATUSES,
} from "./actionTypes.js";

export function getCountries() {
  return function (dispatch, getState) {
    const { masterData } = getState();
    dispatch({
      type: GET_COUNTRIES,
    });

    if (masterData && masterData.countries && masterData.countries.length > 0)
      return;

    return axios({
      method: "get",
      url: apis.masterData + "/country",
    })
      .then((response) => {
        const { rows } = response.data;
        return dispatch(
          setCountries(rows.sort((a, b) => (a.title > b.title ? 1 : -1)))
        );
      })
      .catch((error) => {});
  };
}

export function setCountries(country) {
  return {
    type: SET_COUNTRIES,
    payload: country,
  };
}

export function getStates() {
  return function (dispatch, getState) {
    const { masterData } = getState();
    dispatch({
      type: GET_STATES,
    });

    if (masterData && masterData.states && masterData.states.length > 0) return;

    return axios({
      method: "get",
      url: apis.masterData + "/state",
    })
      .then((response) => {
        const { rows } = response.data;
        return dispatch(
          setStates(rows.sort((a, b) => (a.title > b.title ? 1 : -1)))
        );
      })
      .catch((error) => {});
  };
}

export function setStates(state) {
  return {
    type: SET_STATES,
    payload: state,
  };
}

export function getAccountRoles() {
  return function (dispatch, getState) {
    const { masterData } = getState();
    dispatch({
      type: GET_ACCOUNT_ROLES,
    });

    if (
      masterData &&
      masterData.accountRoles &&
      masterData.accountRoles.length > 0
    )
      return;

    return axios({
      method: "get",
      url: apis.masterData + "?keys=accountRole,accountStatus",
    })
      .then((response) => {
        const { rows } = response.data;
        dispatch(
          setAccountStatuses(
            rows
              .filter((r) => r.key === "accountStatus")
              .sort((a, b) => (a.title > b.title ? 1 : -1))
          )
        );
        dispatch(
          setAccountRoles(
            rows
              .filter((r) => r.key === "accountRole")
              .sort((a, b) => (a.title > b.title ? 1 : -1))
          )
        );
        return;
      })
      .catch((error) => {});
  };
}

export function setAccountRoles(state) {
  return {
    type: SET_ACCOUNT_ROLES,
    payload: state,
  };
}

export function setAccountStatuses(state) {
  return {
    type: SET_ACCOUNT_STATUSES,
    payload: state,
  };
}
