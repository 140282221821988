export default function trimText(text, maxLength = 50, lastChar = " ") {
  if (text && text.length > maxLength) {
    const limited = text.substring(0, maxLength);

    if (limited.lastIndexOf(lastChar) >= 0) {
      return limited.substring(0, limited.lastIndexOf(lastChar)).trim();
    }

    return limited;
  }

  return text;
}
