export const GET_COUNTRIES = "GET_COUNTRIES";
export const SET_COUNTRIES = "SET_COUNTRIES";

export const GET_STATES = "GET_STATES";
export const SET_STATES = "SET_STATES";

export const GET_ACCOUNT_ROLES = "GET_ACCOUNT_ROLES";
export const SET_ACCOUNT_ROLES = "SET_ACCOUNT_ROLES";

export const GET_ACCOUNT_STATUSES = "GET_ACCOUNT_STATUSES";
export const SET_ACCOUNT_STATUSES = "SET_ACCOUNT_STATUSES";
