import React, { useState, useRef } from "react";

export default function MultiImageUpload(props) {
  const fileInputRef = useRef(null);

  const [images, setImages] = useState(
    props.images ? props.images.map(i => ({ preview: i })) : []
  );

  

  const handleImageUpload = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      const imagesObj = [
        ...images,
        {
          preview: reader.result,
          file
        }
      ];
      setImages(imagesObj);
      if (props.onChange) {
        props.onChange(imagesObj);
      }

      if (fileInputRef && fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    };

    reader.readAsDataURL(file);
  };

  const handleImageDelete = i => {
    const imagesObj = images
      .slice(0, i)
      .concat(images.slice(i + 1, images.length));

    setImages(imagesObj);
    if (props.onChange) {
      props.onChange(imagesObj);
    }

    if (fileInputRef && fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const uploadButton = () => {
    if (props.maxImages && images.length >= props.maxImages) {
      return <></>;
    }

    return (
      <div className="image-upload">
        <button onClick={() => fileInputRef.current.click()}>
          Upload Image
        </button>
      </div>
    );
  };

  return (
    <div className="multi-image-brick-upload">
      <input
        type="file"
        accept="image/*"
        ref={fileInputRef}
        onChange={handleImageUpload}
        className="file-input"
      />
      <div className="image-upload-container">
        {images.map((p, i) => (
          <div className="image-brick" key={`image-brick-${i}`}>
            <div
              className="image-delete"
              onClick={() => {
                handleImageDelete(i);
              }}
            >
              X
            </div>
            <img
              src={p.preview}
              className="image-preview"
              alt="Uploaded Preview"
            />
          </div>
        ))}
        {uploadButton()}
      </div>
    </div>
  );
}
