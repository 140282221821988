import React, { Component } from "react";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
//import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

// Icons
import { ReactComponent as AirplaneAscentIcon } from "images/icons/airplane-ascent.svg";
import { ReactComponent as AirplaneDescentIcon } from "images/icons/airplane-descent.svg";
import { ReactComponent as ServicesIcon } from "images/icons/services.svg";
import { ReactComponent as SearchIcon } from "images/icons/search.svg";

import Routes from "routes";

class SearchTabs extends Component {
	constructor(props) {
		super(props);

		this.handleSaleSearch = this.handleSaleSearch.bind(this);
		this.handleWantedSearch = this.handleWantedSearch.bind(this);
		this.handleServicesSearch = this.handleServicesSearch.bind(this);

		this.saleInputRef = React.createRef();
		this.wantedInputRef = React.createRef();
		this.serviceInputRef = React.createRef();
	}

	handleSaleSearch(evt) {
		evt.preventDefault();
		if (this.saleInputRef.current && this.saleInputRef.current.value) {
			// Do the search
			const searchKeyword = encodeURI(this.saleInputRef.current.value);
			this.props.history.push({ pathname: Routes.forSale, search: `?keyword=${searchKeyword}` });
		}

		// No value? Do nothing
		return false;
	}

	handleWantedSearch(evt) {
		evt.preventDefault();
		if (this.wantedInputRef.current && this.wantedInputRef.current.value) {
			// Do the search
			const searchKeyword = encodeURI(this.wantedInputRef.current.value);
			this.props.history.push({ pathname: Routes.wanted, search: `?keyword=${searchKeyword}` });
		}

		// No value? Do nothing
		return false;
	}

	handleServicesSearch(evt) {
		evt.preventDefault();
		if (this.serviceInputRef.current && this.serviceInputRef.current.value) {
			// Do the search
			const searchKeyword = encodeURI(this.serviceInputRef.current.value);
			this.props.history.push({ pathname: Routes.services, search: `?keyword=${searchKeyword}` });
		}

		// No value? Do nothing
		return false;
	}

	render() {
		return (
			<Tab.Container id="home-search-tabs-container" defaultActiveKey="sale">
				<Nav className="row">
					<Nav.Item className="col first pr-1">
						<Nav.Link eventKey="sale">
							<AirplaneAscentIcon /> For Sale
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="col middle pl-1 pr-1">
						<Nav.Link eventKey="wanted">
							<AirplaneDescentIcon /> Wanted
						</Nav.Link>
					</Nav.Item>
					<Nav.Item className="col last pl-1">
						<Nav.Link eventKey="services">
							<ServicesIcon /> Services
						</Nav.Link>
					</Nav.Item>
				</Nav>
				<Tab.Content>
					<Tab.Pane eventKey="sale">
						<Form onSubmit={this.handleSaleSearch}>
							<InputGroup>
								<FormControl placeholder="" aria-label="Search Aircraft For Sale" aria-describedby="sale-button" ref={this.saleInputRef} />
								<InputGroup.Append>
									<Button type="submit">
										<SearchIcon />
									</Button>
								</InputGroup.Append>
							</InputGroup>
						</Form>
					</Tab.Pane>
					<Tab.Pane eventKey="wanted">
						<Form onSubmit={this.handleWantedSearch}>
							<InputGroup>
								<FormControl placeholder="" aria-label="Search Aircraft Wanted" aria-describedby="wanted-button" ref={this.wantedInputRef} />
								<InputGroup.Append>
									<Button type="submit">
										<SearchIcon />
									</Button>
								</InputGroup.Append>
							</InputGroup>
						</Form>
					</Tab.Pane>
					<Tab.Pane eventKey="services">
						<Form onSubmit={this.handleServicesSearch}>
							<InputGroup>
								<FormControl placeholder="" aria-label="Search Aircraft Services" aria-describedby="services-button" ref={this.serviceInputRef} />
								<InputGroup.Append>
									<Button type="submit">
										<SearchIcon />
									</Button>
								</InputGroup.Append>
							</InputGroup>
						</Form>
					</Tab.Pane>
				</Tab.Content>
			</Tab.Container>
		);
	}
}

export default withRouter(SearchTabs);
