import React, { Component } from "react";
import Routes, { createTitle } from "routes";

import PromotionList from "../shared/list";
import PromotionEdit from "./edit";

class SocialPromotions extends Component {
  componentDidMount() {
    document.title = createTitle(`Social Promotions`, Routes);
  }

  render() {
    return (
      <PromotionList
        title="Social Featured"
        url="/promotion/type/social"
        editor={PromotionEdit}
      />
    );
  }
}

export default SocialPromotions;
