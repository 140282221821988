import {
  SET_LISTING,
  CLEAR_LISTING,
  UPDATE_LISTING,
} from "actions/listing/actionTypes";

const initialState = {};

export default function listingReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case SET_LISTING:
      return { ...state, ...payload };
    case UPDATE_LISTING:
      return Object.assign({}, state, payload);
    case CLEAR_LISTING:
      return Object.assign({}, initialState);
    default:
      return state;
  }
}
