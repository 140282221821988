import React from "react";
import BadRequestPage from "components/frontend/pages/400";
import UnauthorizedPage from "components/frontend/pages/401";
import ForbiddenPage from "components/frontend/pages/403";
import NotFoundPage from "components/frontend/pages/404";
import InternalServerPage from "components/frontend/pages/500";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: undefined, hasError: false };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setState({ error: undefined, hasError: false });
    }
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error, hasError: true };
  }

  componentDidCatch(error, stack) {
    // You can also log the error to an error reporting service
    console.error(error);
  }

  render() {
    if (this.state.hasError) {
      switch (true) {
        case this.state.error instanceof BadRequestError:
          return <BadRequestPage error={this.state.error.error} />;
        case this.state.error instanceof UnauthorizedError:
          return <UnauthorizedPage error={this.state.error.error} />;
        case this.state.error instanceof ForbiddenError:
          return <ForbiddenPage error={this.state.error.error} />;
        case this.state.error instanceof NotFoundError:
          return <NotFoundPage error={this.state.error.error} />;
        case this.state.error instanceof ConflictError:
        case this.state.error instanceof InternalError:
        default:
          return <InternalServerPage error={this.state.error.error} />;
      }
    }

    return this.props.children;
  }
}

export class BadRequestError extends Error {
  constructor(message, error = null) {
    super(message);
    this.name = "Bad Request Error";
    this.code = 400;
    this.error = error || null;
  }
}

export class UnauthorizedError extends Error {
  constructor(message, error = null) {
    super(message);
    this.name = "Unauthorized Error";
    this.code = 401;
    this.error = error || null;
  }
}

export class PaymentRequiredError extends Error {
  constructor(message, error = null) {
    super(message);
    this.name = "Subscription Limit";
    this.code = 402;
    this.error = error || null;
  }
}

export class ForbiddenError extends Error {
  constructor(message, error = null) {
    super(message);
    this.name = "Forbidden Error";
    this.code = 403;
    this.error = error || null;
  }
}

export class NotFoundError extends Error {
  constructor(message, error = null) {
    super(message);
    this.name = "Not Found Error";
    this.code = 404;
    this.error = error || null;
  }
}

export class ConflictError extends Error {
  constructor(message, error = null) {
    super(message);
    this.name = "Conflict Error";
    this.code = 409;
    this.error = error || null;
  }
}

export class InternalError extends Error {
  constructor(message, error = null) {
    super(message);
    this.name = "Internal Server Error";
    this.code = 500;
    this.error = error || null;
  }
}

export function handleAxiosError(error) {
  if (error) {
    if (error.response) {
      console.error("Axios reported an error:");
      console.error(error.response);
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      switch (error.response.status) {
        case 400:
          throw new BadRequestError("Bad Request!", error);
        case 401:
          throw new UnauthorizedError("Unauthorized Error!", error);
        case 402:
          throw new PaymentRequiredError("Subscription Limit Reached!", error);
        case 403:
          throw new ForbiddenError("Forbidden Error!", error);
        case 404:
          throw new NotFoundError("Not Found Error!", error);
        case 409:
          throw new ConflictError("Conflict Error!", error);
        case 500:
          throw new InternalError("Internal Server Error!", error);
        default:
          throw new InternalError("Unknown Server Error!", error);
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       * is an instance of XMLHttpRequest in the browser and an instance
       * of http.ClientRequest in Node.js
       */
      return new BadRequestError("Bad Request Error!", error);
    }
  }

  return new InternalError("Unknown Error", error);
}
