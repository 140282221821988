import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";

import deepMerge from "utils/deepMerge";
import { getAccountRoles } from "actions/masterData/actions";

import SalesRepBlock from "./salesRepBlock";

function EditCompanySalesReps(props) {
  const { userInfo, reps, getAccountRoles, accountStatuses } = props;

  const [accounts, setAccounts] = useState(reps);

  useEffect(() => {
    getAccountRoles();
  }, [getAccountRoles]);

  const updateRep = (email, value) => {
    const updatedAccounts = accounts.map((a, i) => {
      if (a.email === email) {
        // Modify this account
        return { ...deepMerge(a, value), v: Date.now() };
      }
      return a;
    });

    setAccounts([...updatedAccounts]);
  };

  const handleRepChange = (email, value) => {
    axios
      .put(`accounts/${email}`, value)
      .then((res) => {
        updateRep(email, value);
      })
      .catch((err) => {});
  };

  const handleRoleChange = (email, value) => {
    axios
      .put(`accounts/role/${email}`, { role: value.role.value })
      .then((res) => {
        updateRep(email, value);
      })
      .catch((err) => {});
  };

  const handleStatusChange = (email, checked) => {
    const statusValue = checked ? "active" : "inactive";
    axios
      .put(`accounts/status/${email}`, {
        status: statusValue,
      })
      .then((res) => {
        const newStatus = accountStatuses
          .filter((s) => s.value === statusValue)
          .shift();
        updateRep(email, { status: newStatus });
      })
      .catch((err) => {});
  };

  const editRepSection = (title, slug, theseReps) => {
    return (
      <div key={`section-${slug}`} className={`rep-list ${slug}`}>
        <h3>{title}</h3>
        <ul>
          {theseReps.map((r, i) => {
            return (
              <li
                key={`rep-${slug}-${r.email}`}
                className="rep-block"
                data-email={r.email}
              >
                <SalesRepBlock
                  email={r.email}
                  rep={r}
                  onRepChange={handleRepChange}
                  onRoleChange={handleRoleChange}
                  onStatusChange={handleStatusChange}
                />
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const companyAdmins = (filteredReps) => {
    if (filteredReps.length > 0) {
      return editRepSection("Company Admins", "company-admins", filteredReps);
    }
  };

  const salesReps = (filteredReps) => {
    if (filteredReps.length > 0) {
      return editRepSection("Sales Reps", "sales-reps", filteredReps);
    }
  };

  const salesRepsViews = (filteredReps) => {
    if (filteredReps.length > 0) {
      return editRepSection(
        "Sales Reps (View Only)",
        "sales-rep-views",
        filteredReps
      );
    }
  };

  if (reps && reps.length > 0) {
    return (
      <div className="reps-lists">
        {companyAdmins(
          reps.filter(
            (a) =>
              a.role.value === "company-admin" && a.email !== userInfo.email
          )
        )}
        {salesReps(reps.filter((a) => a.role.value === "sales-rep"))}
        {salesRepsViews(reps.filter((a) => a.role.value === "sales-rep-view"))}
      </div>
    );
  }
  return (
    <div className="no-reps">
      <h3>No Sales Reps</h3>
      <p>You have no sales rep accounts (besides your own)</p>
    </div>
  );
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
    accountStatuses: state.masterData.accountStatuses,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    getAccountRoles: () => dispatch(getAccountRoles()),
  };
};

export default connect(
  keyStateToProps,
  keyDispatchToProps
)(EditCompanySalesReps);
