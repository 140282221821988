/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useCallback } from "react";
import CForm from "./components/form";
import Card from "./components/card";

const CreditCardForm = (props) => {
  const { onChange, onValidation } = props;
  const { values, touched, errors } = props;
  const [cardFlipped, setCardFlipped] = useState(false);
  const [currentFocusedElm, setCurrentFocusedElm] = useState(null);

  const handleFlipCard = (flip) => {
    setCardFlipped(flip);
  };

  const handleFormChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  // References for the Form Inputs used to focus corresponding inputs.
  let formFieldsRefObj = {
    cardNumber: useRef(),
    cardHolder: useRef(),
    cardDate: useRef(),
    cardCvv: useRef(),
  };

  let focusFormFieldByKey = useCallback((key) => {
    formFieldsRefObj[key].current.focus();
  });

  // This are the references for the Card DIV elements.
  let cardElementsRef = {
    cardNumber: useRef(),
    cardHolder: useRef(),
    cardDate: useRef(),
  };

  let onCardFormInputFocus = (_event, inputName) => {
    const refByName = cardElementsRef[inputName];
    setCurrentFocusedElm(refByName);
  };

  let onCardInputBlur = useCallback(() => {
    setCurrentFocusedElm(null);
  }, []);

  const handleValidation = (valid) => {
    if (onValidation) {
      onValidation(valid);
    }
  };

  return (
    <div
      className="credit-card-form-wrapper"
      style={props.style ? props.style : {}}
    >
      <CForm
        values={values}
        touched={touched}
        errors={errors}
        onValidation={handleValidation}
        onFlipCard={handleFlipCard}
        //onUpdateState={updateStateValues}
        onChange={handleFormChange}
        cardNumberRef={formFieldsRefObj.cardNumber}
        cardHolderRef={formFieldsRefObj.cardHolder}
        cardDateRef={formFieldsRefObj.cardDate}
        cardCvvRef={formFieldsRefObj.cardCvv}
        onCardInputFocus={onCardFormInputFocus}
        onCardInputBlur={onCardInputBlur}
      >
        <Card
          values={values}
          isCardFlipped={cardFlipped}
          currentFocusedElm={currentFocusedElm}
          onCardElementClick={focusFormFieldByKey}
          cardNumberRef={cardElementsRef.cardNumber}
          cardHolderRef={cardElementsRef.cardHolder}
          cardDateRef={cardElementsRef.cardDate}
        ></Card>
      </CForm>
    </div>
  );
};

export default CreditCardForm;
