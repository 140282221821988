import React from "react";
import { connect } from "react-redux";
import Routes, { createTitle } from "routes";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import LoginForm from "components/frontend/account/loginForm";
import { ReactComponent as AirplaneGrounded } from "images/icons/airplane-grounded.svg";

class UnauthorizedPage extends React.Component {
  componentWillMount() {
    const { userInfo } = this.props;
    if (userInfo) {
      // this.props.history.push({
      //   pathname: Routes.admin,
      // });
    }
  }
  componentDidMount() {
    document.title = createTitle("401 Unauthorized", Routes);
    document.getElementById("body").className =
      "unauthorized unauthorized-page 401 401-page error-page";
  }
  componentWillUnmount() {
    document.getElementById("body").className = "";
  }

  componentDidUpdate() {
    const { userInfo } = this.props;
    if (userInfo) {
      // this.props.history.push({
      //   pathname: Routes.admin,
      // });
    }
  }

  render() {
    return (
      <section
        id="app-page-unauthorized"
        className="error-page-content app-unauthorized unauthorized-page"
      >
        <Container id="unauthorized-header-row">
          <Row className="justify-content-center">
            <Col xs={12} sm={12} md={10} lg={8} xl={6}>
              <header className="unauthorized-page-hader page-header">
                <h1 className="page-title">
                  Oops! <strong>You Need to Log in First.</strong>
                </h1>
                <div className="airplane-graphic">
                  <AirplaneGrounded />
                </div>
              </header>
              <div className="login-form-container">
                <LoginForm />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {};
};

export default connect(keyStateToProps, keyDispatchToProps)(UnauthorizedPage);
