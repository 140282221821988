import { SET_USER, SIGN_OUT } from "actions/user/actionTypes";

const initialState = null;

export default function userReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case SET_USER:
      return Object.assign({}, state, {
        ...payload,
      });
    case SIGN_OUT:
      return Object.assign({}, null);
    default:
      return state;
  }
}
