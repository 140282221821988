import { SET_PLANS } from "actions/plan/actionTypes";

const initialState = [];

export default function masterDataReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case SET_PLANS:
      return payload;
    default:
      return state;
  }
}
