import React, { useState } from "react";
import { withFormik } from "formik";

import { object, string, number } from "yup";

import { Form } from "react-bootstrap";
import "./styles.scss";
import formatCardNumber from "../formatter";

const schema = object().shape({
  cardNumber: string().required("A card number is required"),
  //.min(16, "Card need a minimum of 14 digits")
  //.max(19, "Card number is too long"),
  cardHolder: string()
    .required("Card Holder name is required")
    .max(26, "Card Holder name is too long"),
  cardMonth: number()
    .required("An expiration Month is required")
    .min(1)
    .max(12),
  cardYear: number().required("An expiration Year is required"),
  cardCvv: string().required(
    "The 3 or 4 digit CVV (on the back of your card) is required."
  ),
  //.max(9999, "CVV cannot be more than 4 characters"),
});

const currentYear = new Date().getFullYear();
const monthsArr = Array.from({ length: 12 }, (x, i) => {
  const month = i + 1;
  return month <= 9 ? "0" + month : month;
});
const yearsArr = Array.from({ length: 10 }, (_x, i) => currentYear + i);

function CForm(props) {
  // Passed props
  const {
    cardNumberRef,
    cardHolderRef,
    cardDateRef,
    cardCvvRef,
    onCardInputFocus,
    onCardInputBlur,
    children,
    onFlipCard,
    onChange,
  } = props;

  // Formik props
  const { values, touched, errors, handleChange, handleBlur } = props;

  const [cardNum, setCardNum] = useState(
    values.cardNumber ? formatCardNumber(values.cardNumber).trimRight() : ""
  );

  const handleFormChange = (event) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleFormBlur = (event) => {
    handleBlur(event);
    onCardInputBlur(event);
  };

  // TODO: We can improve the regex check with a better approach like in the card component.
  const onCardNumberChange = (event) => {
    if (onChange) {
      onChange(event);
    }
    let { value } = event.target;

    setCardNum(formatCardNumber(value).trimRight());
    handleChange(event);
    //onUpdateState(name, cardNum);
  };

  const onCvvFocus = () => {
    onFlipCard(true);
    //onUpdateState("isCardFlipped", true);
  };

  const onCvvBlur = (event) => {
    handleFormBlur(event);
    //onUpdateState("isCardFlipped", false);
    onFlipCard(false);
  };

  // NOTE: Currently the cursor on the card number field gets reset if we remove a number, the code bellow was used
  // in class components, need to transform this to work with functional components.
  // getSnapshotBeforeUpdate() {
  //     return this.props.cardNumberRef.current.selectionStart;
  // }

  // const componentDidUpdate = function (prevProps, prevState, cursorIdx) {
  //     const node = cardNumberRef.current;
  //     const { cardNumber: cardNum } = state;
  //     const { cardNumber: prevCardNum } = prevState;
  //     if (
  //         cardNum.length > prevCardNum.length &&
  //         cardNum[cursorIdx - 1] === ' '
  //     ) {
  //         cursorIdx += 1;
  //     } else if (prevCardNum[cursorIdx - 1] === ' ') {
  //         cursorIdx -= 1;
  //     }
  //     node.selectionStart = node.selectionEnd = cursorIdx;
  // };

  return (
    <div className="card-form">
      <div className="card-list">{children}</div>
      <div className="card-form__inner">
        <Form.Group className="card-input">
          <Form.Label
            htmlFor="cardNumber"
            className="card-input__label form-label"
          >
            Card Number
          </Form.Label>
          <Form.Control
            type="tel"
            name="cardNumber"
            className="card-input__input form-control"
            autoComplete="off"
            onChange={onCardNumberChange}
            maxLength="19"
            placeholder="#### #### #### ####"
            ref={cardNumberRef}
            onFocus={(e) => onCardInputFocus(e, "cardNumber")}
            onBlur={handleFormBlur}
            value={cardNum}
            isInvalid={touched.cardNumber && !!errors.cardNumber}
          />
          <Form.Control.Feedback type="invalid">
            {errors.cardNumber}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="card-input">
          <Form.Label htmlFor="cardName" className="card-input__label">
            Card Holder
          </Form.Label>
          <Form.Control
            type="text"
            className="card-input__input form-control"
            autoComplete="off"
            name="cardHolder"
            placeholder="FULL NAME"
            maxLength="26"
            onChange={handleFormChange}
            value={values.cardHolder || ""}
            ref={cardHolderRef}
            onFocus={(e) => onCardInputFocus(e, "cardHolder")}
            onBlur={handleFormBlur}
            isInvalid={touched.cardHolder && !!errors.cardHolder}
          />
          <Form.Control.Feedback type="invalid">
            {errors.cardHolder}
          </Form.Control.Feedback>
        </Form.Group>

        <div className="card-form__row">
          <div className="card-form__col">
            <Form.Group className="card-form__group">
              <Form.Label htmlFor="cardMonth" className="card-input__label">
                Expiration Date
              </Form.Label>
              <Form.Control
                as="select"
                className="card-input__input -select form-control"
                value={values.cardMonth || ""}
                name="cardMonth"
                onChange={handleFormChange}
                ref={cardDateRef}
                onFocus={(e) => onCardInputFocus(e, "cardDate")}
                onBlur={handleFormBlur}
                isInvalid={touched.cardMonth && !!errors.cardMonth}
              >
                <option value="" disabled>
                  Month
                </option>

                {monthsArr.map((val, index) => (
                  <option key={index} value={val}>
                    {val}
                  </option>
                ))}
              </Form.Control>
              <Form.Control
                as="select"
                name="cardYear"
                className="card-input__input -select form-control"
                value={values.cardYear || ""}
                onChange={handleFormChange}
                onFocus={(e) => onCardInputFocus(e, "cardDate")}
                onBlur={handleFormBlur}
                isInvalid={touched.cardYear && !!errors.cardYear}
              >
                <option value="" disabled>
                  Year
                </option>

                {yearsArr.map((val, index) => (
                  <option key={index} value={val}>
                    {val}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.cardMonth || errors.cardYear}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
          <div className="card-form__col -cvv">
            <Form.Group className="card-input">
              <Form.Label htmlFor="cardCvv" className="card-input__label">
                CVV
              </Form.Label>
              <Form.Control
                type="tel"
                className="card-input__input form-control"
                maxLength="4"
                autoComplete="off"
                name="cardCvv"
                value={values.cardCvv || ""}
                placeholder="###"
                onChange={handleFormChange}
                onFocus={onCvvFocus}
                onBlur={onCvvBlur}
                ref={cardCvvRef}
                isInvalid={touched.cardCvv && !!errors.cardCvv}
              />
              <Form.Control.Feedback type="invalid">
                {errors.cardCvv}
              </Form.Control.Feedback>
            </Form.Group>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withFormik({
  mapPropsToValues: (props) => {
    return props.values;
  },
  validationSchema: schema,
  enableReinitialize: true,
  validateOnBlur: true,
  validateOnMount: true,
  validate: (values, props) => {
    schema.isValid(values).then((valid) => {
      if (props.onValidation) {
        props.onValidation(valid);
      }
    });
  },
  mapPropsToErrors: (props) => {
    return props.errors;
  },
})(CForm);
