import React from "react";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Form, Popover, OverlayTrigger } from "react-bootstrap";

class MultiSelectFilter extends React.Component {
  // static propTypes = {
  //   column: PropTypes.object.isRequired,
  //   onFilter: PropTypes.func.isRequired
  // }
  constructor(props) {
    super(props);
    // Set internal state
    this.state = {
      selected: [],
    };

    this.node = React.createRef();
  }

  cleanParams = (obj) =>
    Object.keys(obj)
      .filter((k) => obj[k] != null) // Remove undef. and null.
      .reduce((newObj, k) => ({ ...newObj, [k]: obj[k] }), {});

  getTheseParamValues = (key) => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    let theseParams = [];
    if (currentParams[key]) {
      if (Array.isArray(currentParams[key])) {
        theseParams = currentParams[key] || [];
      } else {
        theseParams = currentParams[key].split(",") || [];
      }
    }
    return theseParams;
  };

  handleFilter = (target) => {
    const { column } = this.props;
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    let theseParams = this.getTheseParamValues(column.dataField);
    if (target.checked) {
      // Add this value to our params
      if (!theseParams.includes(target.value)) theseParams.push(target.value);
    } else {
      // Remove this value from our params
      theseParams.splice(theseParams.indexOf(target.value), 1);
    }

    const updatedObj = { ...currentParams, [column.dataField]: theseParams };

    const updatedParams = queryString.stringify(this.cleanParams(updatedObj), {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
    //return updatedObj;
    // let theseParams = this.state.selected || [];
    // if (target.checked) {
    //   // Add this value to our params
    //   if (!theseParams.includes(target.value)) theseParams.push(target.value);
    // } else {
    //   // Remove this value from our params
    //   theseParams.splice(theseParams.indexOf(target.value), 1);
    // }

    // this.setState({ selected: theseParams }, () => {
    //   this.props.onFilter(theseParams);
    // });
  };

  handleSort = (direction) => {
    const { column } = this.props;
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = {
      ...currentParams,
      sort: column.dataField,
      order: direction,
    };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });
    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };

  popover = () => {
    const { column, items } = this.props;
    const theseParams = this.getTheseParamValues(column.dataField);
    return (
      <Popover id="admin-table-popover-positioned-bottom">
        <Popover.Content>
          <Row>
            <Col>
              <button
                className="filter-sort-asc"
                onClick={(e) => {
                  this.handleSort("asc");
                }}
              >
                <strong>SORT A TO Z</strong>
              </button>
              <button
                className="filter-sort-desc"
                onClick={(e) => {
                  this.handleSort("desc");
                }}
              >
                <strong>SORT Z TO A</strong>
              </button>
              <div className="filter-checkboxes">
                <strong>FILTER</strong>
                {items.map((opt, i) => (
                  <Form.Group className="m-0" key={i}>
                    <Form.Check
                      custom
                      type="checkbox"
                      id={`filter-${column.dataField}-${opt.value}-${i}`}
                      name={column.dataField}
                      label={opt.title}
                      value={opt.value}
                      onChange={(e) => this.handleFilter(e.target)}
                      checked={theseParams.includes(opt.value.toString())}
                    />
                  </Form.Group>
                ))}
              </div>
            </Col>
          </Row>
        </Popover.Content>
      </Popover>
    );
  };

  render() {
    const { column } = this.props;
    return (
      <OverlayTrigger
        trigger="click"
        rootClose={true}
        placement="bottom"
        overlay={this.popover()}
        target={this.node.current}
      >
        <div
          className="d-flex justify-content-between bar w-100 no-cursor"
          onClick={(e) => {
            e.stopPropagation();
          }}
          ref={this.node}
        >
          <div
            className="filter-label filter"
            onClick={this.handlePopoverToggle}
          >
            {column.text}
          </div>
          <div className="filter-trigger popover-trigger">
            <FontAwesomeIcon icon={faBars} />
          </div>
        </div>
      </OverlayTrigger>
    );
  }
}

export default withRouter(MultiSelectFilter);
