import React from "react";
import PropTypes from "prop-types";
import UserAvatar from "react-user-avatar";

export default class Avatar extends React.Component {
  static defaultProps = {
    size: 48,
  };

  CssClasses() {
    return "app-avatar avatar" + this.props.className;
  }

  render() {
    const name = this.props.name.split(" ");
    return (
      <div className={this.CssClasses()}>
        <UserAvatar
          size={this.props.size}
          name={name.slice(0, 3).join(" ")}
          src={this.props.src}
          colors={[
            "#009490", // Teal
            "#00d7d1", // Light Teal
            "#006764", // Dark Teal
            "#6d0020", // Red
            "#a40030", // Light Red
            "#4b0016", // Dark Red
            "#b2b5b6", // Gray
            "#787878", // Dark Gray
            "#ececec", // Light Gray
          ]}
        />
      </div>
    );
  }
}

Avatar.propTypes = {
  size: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  src: PropTypes.any,
};
