import React, { Component } from "react";
import Routes, { createTitle } from "routes";

import PromotionList from "../shared/list";
import PromotionEdit from "./edit";

class HomepagePromotions extends Component {
  componentDidMount() {
    document.title = createTitle(`Homepage Promotions`, Routes);
  }

  render() {
    return (
      <PromotionList
        title="Homepage Featured"
        url="/promotion/type/homepage"
        editor={PromotionEdit}
      />
    );
  }
}

export default HomepagePromotions;
