import React, { Component } from "react";
import axios from "axios";

import { Container, Row, Col, Button } from "react-bootstrap";
import Spinner from "components/shared/spinner";

import EditableInput from "components/backend/shared/editableInput";
import RichTextEditor from "components/shared/richEditor";

import stripHTML from "utils/stripHTML";

// Images
import AdvertisingImage from "images/content/advertise/sunset.jpg";
import ExperienceImage from "images/content/advertise/planemover-about-digital-channels.png";
import ListingsImage from "images/content/advertise/planemover-about-listings.png";
import EmailImage from "images/content/advertise/planemover-about-email-blitz.png";
import SocialImage from "images/content/advertise/planemover-about-social-media.png";

class Advertising extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group: "advertise",
      isLoading: true,
      results: [],
      error: undefined,
    };
  }

  componentDidMount() {
    axios.get("/settings/advertise").then((response) => {
      this.setState({
        isLoading: false,
        error: undefined,
        results: response.data.rows,
      });
    });
  }

  content() {
    if (this.state.error) {
      // Error
      return (
        <div className="advertising advertising-error error">
          <h1 className="favorite-title">{this.state.error.message}</h1>
          <p>{this.ErrorMessage(this.state.error)}</p>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div className="form-loading edit-loading loading">
          <Spinner />
        </div>
      );
    } else if (this.state.results) {
      return <this.EditContent />;
    }
  }

  getSettingValue = (section) => {
    const thisSetting = this.state.results
      .filter((s) => s.section === section)
      .shift();
    if (thisSetting) return thisSetting.content;
    return;
  };

  setSettingValue = (section, content) => {
    let updatedSettings = this.state.results;
    let previousResults = this.state.results;
    let updatedSetting;
    const thisSetting = this.state.results
      .filter((s) => s.section === section)
      .shift();
    if (thisSetting) {
      // Update Setting
      updatedSettings = this.state.results.map((s, i) => {
        if (s.section === section) {
          // Update
          updatedSetting = s;
          updatedSetting.content = content;
          return updatedSetting;
        }
        return s;
      });
    } else {
      // Add New Setting
      updatedSetting = {
        group: this.state.group,
        section,
        title: null,
        content,
      };
      updatedSettings.push(updatedSetting);
    }

    this.setState({ results: updatedSettings }, () => {
      axios
        .put(`/settings/${this.state.group}/${section}`, updatedSetting)
        .catch((error) => {
          this.setState({ results: previousResults });
        });
    });
  };

  handleTextEditorChange = (name, value) => {
    let cleanedValue = stripHTML(value).trim();
    if (!cleanedValue) {
      value = null;
    }

    this.setSettingValue(name, value);
  };

  EditContent = () => {
    return (
      <section className="advertising">
        <Row>
          <Col>
            <div className="site-settings__subtitle mb">
              Edit the Advertising page here.
            </div>
          </Col>
        </Row>
        <Container className="advertising__form" fluid={true}>
          <Row id="page-titles" className="advertising__form__block">
            <Col xs={12}>
              <EditableInput
                label="Page Title"
                value={this.getSettingValue("page-title") || ""}
                renderValue={(value) => (
                  <h2
                    dangerouslySetInnerHTML={{ __html: value }}
                    className="white-space-pre"
                  />
                )}
                renderInput={({ setEdit, setValue, getValue }) => (
                  <>
                    <RichTextEditor
                      name="page-title"
                      value={this.getSettingValue("page-title") || ""}
                      onChange={(value, name) => {
                        setValue(value);
                      }}
                    />
                    <Button
                      className="mt-1"
                      onClick={(e) => {
                        this.handleTextEditorChange("page-title", getValue());
                        setEdit(false);
                      }}
                    >
                      Done
                    </Button>
                  </>
                )}
                onChange={() => {}}
              />
            </Col>
            <Col xs={12}>
              <EditableInput
                label="Subtitle"
                value={this.getSettingValue("page-subtitle") || ""}
                onChange={(value) => {
                  this.setSettingValue("page-subtitle", value);
                }}
              />
            </Col>
          </Row>
          <Row id="section-advertising" className="advertising__form__block">
            <Col xs={12} sm={6}>
              <Row>
                <Col xs={12}>
                  <EditableInput
                    label="Section Title"
                    value={
                      this.getSettingValue("section-advertising-title") || ""
                    }
                    renderValue={(value) => (
                      <h3
                        dangerouslySetInnerHTML={{ __html: value }}
                        className="white-space-pre"
                      />
                    )}
                    renderInput={({ setEdit, setValue, getValue }) => (
                      <>
                        <RichTextEditor
                          name="section-advertising-title"
                          value={
                            this.getSettingValue("section-advertising-title") ||
                            ""
                          }
                          onChange={(value, name) => {
                            setValue(value);
                          }}
                        />
                        <Button
                          className="mt-1"
                          onClick={(e) => {
                            this.handleTextEditorChange(
                              "section-advertising-title",
                              getValue()
                            );
                            setEdit(false);
                          }}
                        >
                          Done
                        </Button>
                      </>
                    )}
                    onChange={() => {}}
                  />
                </Col>
                <Col xs={12}>
                  <EditableInput
                    label="Brief Description"
                    value={
                      this.getSettingValue("section-advertising-description") ||
                      ""
                    }
                    renderValue={(value) => (
                      <span
                        dangerouslySetInnerHTML={{ __html: value }}
                        className="white-space-pre"
                      />
                    )}
                    renderInput={({ setEdit, setValue, getValue }) => (
                      <>
                        <RichTextEditor
                          name="section-advertising-description"
                          value={
                            this.getSettingValue(
                              "section-advertising-description"
                            ) || ""
                          }
                          onChange={(value, name) => {
                            setValue(value);
                          }}
                        />
                        <Button
                          className="mt-1"
                          onClick={(e) => {
                            this.handleTextEditorChange(
                              "section-advertising-description",
                              getValue()
                            );
                            setEdit(false);
                          }}
                        >
                          Done
                        </Button>
                      </>
                    )}
                    onChange={() => {}}
                  />
                </Col>
                <Col xs={12}>
                  <EditableInput
                    label="Button Text"
                    value={
                      this.getSettingValue(
                        "section-advertising-started-button"
                      ) || ""
                    }
                    renderValue={(value) => (
                      <Button className="btn btn-primary btn-red">
                        {value}
                      </Button>
                    )}
                    onChange={(value) => {
                      this.setSettingValue(
                        "section-advertising-started-button",
                        value
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={6}>
              <img
                src={AdvertisingImage}
                alt="Section 1"
                className="img-fluid"
              />
            </Col>
          </Row>
          <Row id="section-experience" className="advertising__form__block">
            <Col xs={12} sm={8}>
              <Row>
                <Col xs={12}>
                  <EditableInput
                    label="Section Title"
                    value={
                      this.getSettingValue("section-experience-title") || ""
                    }
                    renderValue={(value) => (
                      <h3
                        dangerouslySetInnerHTML={{ __html: value }}
                        className="white-space-pre"
                      />
                    )}
                    renderInput={({ setEdit, setValue, getValue }) => (
                      <>
                        <RichTextEditor
                          name="section-experience-title"
                          value={
                            this.getSettingValue("section-experience-title") ||
                            ""
                          }
                          onChange={(value, name) => {
                            setValue(value);
                          }}
                        />
                        <Button
                          className="mt-1"
                          onClick={(e) => {
                            this.handleTextEditorChange(
                              "section-experience-title",
                              getValue()
                            );
                            setEdit(false);
                          }}
                        >
                          Done
                        </Button>
                      </>
                    )}
                    onChange={() => {}}
                  />
                </Col>
                <Col xs={12}>
                  <EditableInput
                    label="Brief Description"
                    value={
                      this.getSettingValue("section-experience-description") ||
                      ""
                    }
                    renderValue={(value) => (
                      <span
                        dangerouslySetInnerHTML={{ __html: value }}
                        className="white-space-pre"
                      />
                    )}
                    renderInput={({ setEdit, setValue, getValue }) => (
                      <>
                        <RichTextEditor
                          name="section-experience-description"
                          value={
                            this.getSettingValue(
                              "section-experience-description"
                            ) || ""
                          }
                          onChange={(value, name) => {
                            setValue(value);
                          }}
                        />
                        <Button
                          className="mt-1"
                          onClick={(e) => {
                            this.handleTextEditorChange(
                              "section-experience-description",
                              getValue()
                            );
                            setEdit(false);
                          }}
                        >
                          Done
                        </Button>
                      </>
                    )}
                    onChange={() => {}}
                  />
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={4}>
              <img
                src={ExperienceImage}
                alt="Section 2"
                className="img-fluid"
              />
            </Col>
          </Row>
          <Row id="section-listings" className="advertising__form__block">
            <Col xs={12} sm={6}>
              <img src={ListingsImage} alt="Section 3" className="img-fluid" />
            </Col>
            <Col xs={12} sm={6}>
              <Row>
                <Col xs={12}>
                  <EditableInput
                    label="Section Title"
                    value={this.getSettingValue("section-listings-title") || ""}
                    renderValue={(value) => (
                      <h3
                        dangerouslySetInnerHTML={{ __html: value }}
                        className="white-space-pre"
                      />
                    )}
                    renderInput={({ setEdit, setValue, getValue }) => (
                      <>
                        <RichTextEditor
                          name="section-listings-title"
                          value={
                            this.getSettingValue("section-listings-title") || ""
                          }
                          onChange={(value, name) => {
                            setValue(value);
                          }}
                        />
                        <Button
                          className="mt-1"
                          onClick={(e) => {
                            this.handleTextEditorChange(
                              "section-listings-title",
                              getValue()
                            );
                            setEdit(false);
                          }}
                        >
                          Done
                        </Button>
                      </>
                    )}
                    onChange={() => {}}
                  />
                </Col>
                <Col xs={12}>
                  <EditableInput
                    label="Subtitle"
                    value={
                      this.getSettingValue("section-listings-subtitle") || ""
                    }
                    onChange={(value) => {
                      this.setSettingValue("section-listings-subtitle", value);
                    }}
                  />
                </Col>
                <Col xs={12}>
                  <EditableInput
                    label="Brief Description"
                    value={
                      this.getSettingValue("section-listings-description") || ""
                    }
                    renderValue={(value) => (
                      <span
                        dangerouslySetInnerHTML={{ __html: value }}
                        className="white-space-pre"
                      />
                    )}
                    renderInput={({ setEdit, setValue, getValue }) => (
                      <>
                        <RichTextEditor
                          name="section-listings-description"
                          value={
                            this.getSettingValue(
                              "section-listings-description"
                            ) || ""
                          }
                          onChange={(value, name) => {
                            setValue(value);
                          }}
                        />
                        <Button
                          className="mt-1"
                          onClick={(e) => {
                            this.handleTextEditorChange(
                              "section-listings-description",
                              getValue()
                            );
                            setEdit(false);
                          }}
                        >
                          Done
                        </Button>
                      </>
                    )}
                    onChange={() => {}}
                  />
                </Col>
                <Col xs={12}>
                  <EditableInput
                    label="Learn More Button Text"
                    value={
                      this.getSettingValue("section-listings-learn-button") ||
                      ""
                    }
                    renderValue={(value) => (
                      <Button className="btn btn-primary btn-red">
                        {value}
                      </Button>
                    )}
                    onChange={(value) => {
                      this.setSettingValue(
                        "section-listings-learn-button",
                        value
                      );
                    }}
                  />
                </Col>
                <Col xs={12}>
                  <EditableInput
                    label="Get Started Button Text"
                    value={
                      this.getSettingValue("section-listings-signup-button") ||
                      ""
                    }
                    renderValue={(value) => (
                      <Button className="btn btn-secondary btn-green">
                        {value}
                      </Button>
                    )}
                    onChange={(value) => {
                      this.setSettingValue(
                        "section-listings-signup-button",
                        value
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row id="section-email" className="advertising__form__block">
            <Col xs={12} sm={6}>
              <img src={EmailImage} alt="Section 4" className="img-fluid" />
            </Col>
            <Col xs={12} sm={6}>
              <Row>
                <Col xs={12}>
                  <EditableInput
                    label="Section Title"
                    value={this.getSettingValue("section-email-title") || ""}
                    renderValue={(value) => (
                      <h3
                        dangerouslySetInnerHTML={{ __html: value }}
                        className="white-space-pre"
                      />
                    )}
                    renderInput={({ setEdit, setValue, getValue }) => (
                      <>
                        <RichTextEditor
                          name="section-email-title"
                          value={
                            this.getSettingValue("section-email-title") || ""
                          }
                          onChange={(value, name) => {
                            setValue(value);
                          }}
                        />
                        <Button
                          className="mt-1"
                          onClick={(e) => {
                            this.handleTextEditorChange(
                              "section-email-title",
                              getValue()
                            );
                            setEdit(false);
                          }}
                        >
                          Done
                        </Button>
                      </>
                    )}
                    onChange={() => {}}
                  />
                </Col>
                <Col xs={12}>
                  <EditableInput
                    label="Subtitle"
                    value={this.getSettingValue("section-email-subtitle") || ""}
                    onChange={(value) => {
                      this.setSettingValue("section-email-subtitle", value);
                    }}
                  />
                </Col>
                <Col xs={12}>
                  <EditableInput
                    label="Brief Description"
                    value={
                      this.getSettingValue("section-email-description") || ""
                    }
                    renderValue={(value) => (
                      <span
                        dangerouslySetInnerHTML={{ __html: value }}
                        className="white-space-pre"
                      />
                    )}
                    renderInput={({ setEdit, setValue, getValue }) => (
                      <>
                        <RichTextEditor
                          name="section-email-description"
                          value={
                            this.getSettingValue("section-email-description") ||
                            ""
                          }
                          onChange={(value, name) => {
                            setValue(value);
                          }}
                        />
                        <Button
                          className="mt-1"
                          onClick={(e) => {
                            this.handleTextEditorChange(
                              "section-email-description",
                              getValue()
                            );
                            setEdit(false);
                          }}
                        >
                          Done
                        </Button>
                      </>
                    )}
                    onChange={() => {}}
                  />
                </Col>
                <Col xs={12}>
                  <EditableInput
                    label="Learn More Button Text"
                    value={
                      this.getSettingValue("section-email-learn-button") || ""
                    }
                    renderValue={(value) => (
                      <Button className="btn btn-primary btn-red">
                        {value}
                      </Button>
                    )}
                    onChange={(value) => {
                      this.setSettingValue("section-email-learn-button", value);
                    }}
                  />
                </Col>
                <Col xs={12}>
                  <EditableInput
                    label="Get Started Button Text"
                    value={
                      this.getSettingValue("section-email-signup-button") || ""
                    }
                    renderValue={(value) => (
                      <Button className="btn btn-secondary btn-green">
                        {value}
                      </Button>
                    )}
                    onChange={(value) => {
                      this.setSettingValue(
                        "section-email-signup-button",
                        value
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row id="section-social" className="advertising__form__block">
            <Col xs={12} sm={6}>
              <img src={SocialImage} alt="Section 5" className="img-fluid" />
            </Col>
            <Col xs={12} sm={6}>
              <Row>
                <Col xs={12}>
                  <EditableInput
                    label="Section Title"
                    value={this.getSettingValue("section-social-title") || ""}
                    renderValue={(value) => (
                      <h3
                        dangerouslySetInnerHTML={{ __html: value }}
                        className="white-space-pre"
                      />
                    )}
                    renderInput={({ setEdit, setValue, getValue }) => (
                      <>
                        <RichTextEditor
                          name="section-social-title"
                          value={
                            this.getSettingValue("section-social-title") || ""
                          }
                          onChange={(value, name) => {
                            setValue(value);
                          }}
                        />
                        <Button
                          className="mt-1"
                          onClick={(e) => {
                            this.handleTextEditorChange(
                              "section-social-title",
                              getValue()
                            );
                            setEdit(false);
                          }}
                        >
                          Done
                        </Button>
                      </>
                    )}
                    onChange={() => {}}
                  />
                </Col>
                <Col xs={12}>
                  <EditableInput
                    label="Subtitle"
                    value={
                      this.getSettingValue("section-social-subtitle") || ""
                    }
                    onChange={(value) => {
                      this.setSettingValue("section-social-subtitle", value);
                    }}
                  />
                </Col>
                <Col xs={12}>
                  <EditableInput
                    label="Brief Description"
                    value={
                      this.getSettingValue("section-social-description") || ""
                    }
                    renderValue={(value) => (
                      <span
                        dangerouslySetInnerHTML={{ __html: value }}
                        className="white-space-pre"
                      />
                    )}
                    renderInput={({ setEdit, setValue, getValue }) => (
                      <>
                        <RichTextEditor
                          name="section-social-description"
                          value={
                            this.getSettingValue(
                              "section-social-description"
                            ) || ""
                          }
                          onChange={(value, name) => {
                            setValue(value);
                          }}
                        />
                        <Button
                          className="mt-1"
                          onClick={(e) => {
                            this.handleTextEditorChange(
                              "section-social-description",
                              getValue()
                            );
                            setEdit(false);
                          }}
                        >
                          Done
                        </Button>
                      </>
                    )}
                    onChange={() => {}}
                  />
                </Col>
                <Col xs={12}>
                  <EditableInput
                    label="Learn More Button Text"
                    value={
                      this.getSettingValue("section-social-learn-button") || ""
                    }
                    renderValue={(value) => (
                      <Button className="btn btn-primary btn-red">
                        {value}
                      </Button>
                    )}
                    onChange={(value) => {
                      this.setSettingValue(
                        "section-social-learn-button",
                        value
                      );
                    }}
                  />
                </Col>
                <Col xs={12}>
                  <EditableInput
                    label="Get Started Button Text"
                    value={
                      this.getSettingValue("section-social-signup-button") || ""
                    }
                    renderValue={(value) => (
                      <Button className="btn btn-secondary btn-green">
                        {value}
                      </Button>
                    )}
                    onChange={(value) => {
                      this.setSettingValue(
                        "section-social-signup-button",
                        value
                      );
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    );
  };

  render() {
    return (
      <section id="advertising-settings" className="advertising-settings">
        {this.content()}
      </section>
    );
  }
}

export default Advertising;
