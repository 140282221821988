import React from "react";
import { connect } from "react-redux";
import { Link, generatePath } from "react-router-dom";
import moment from "moment";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Routes from "routes";

import { openContactForm } from "actions/modal/actions";

class GetInTouch extends React.Component {
  render() {
    const { listing } = this.props;
    const publicProfile =
      listing.company &&
      listing.company.profiles &&
      listing.company.profiles.filter((p) => p.type.value === "public").shift();

    const createdDate = listing.createdAt
      ? moment(listing.createdAt).format("MMMM Do, YYYY")
      : undefined;
    const updatedDate = listing.updatedAt
      ? moment(listing.updatedAt).format("MMMM Do, YYYY")
      : undefined;

    return (
      <div className="for-sale-detail-contact detail-contact">
        <Container className="py-5">
          <Row>
            <Col>
              <h2 className="detail-contact-title contact-title mb-4">
                Get <strong>In touch</strong>
              </h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col
              xs={12}
              md={8}
              className="detail-contact-company-info contact-company-info company-info"
            >
              <Row>
                <Col xs={12} sm={7} className="flex-shrink-1 mb-4">
                  <ul className="detail-contact-company-contact-list contact-company-contact-list company-contact-list">
                    {listing &&
                      listing.account &&
                      listing.account.profile &&
                      listing.account.profile.name && (
                        <li className="detail-contact-company-contact-item contact-company-contact-item company-contact-item">
                          <span>Sales Representative: </span>
                          <span>{listing.account.profile.name}</span>
                        </li>
                      )}
                    {listing &&
                      listing.account &&
                      listing.account.profile &&
                      listing.account.profile.phone && (
                        <li className="detail-contact-company-contact-item contact-company-contact-item company-contact-item">
                          <span>Sales Rep Phone: </span>
                          <span>{listing.account.profile.phone}</span>
                        </li>
                      )}
                    {listing && listing.company && listing.company.name && (
                      <li className="detail-contact-company-contact-item contact-company-contact-item company-contact-item">
                        <span>Company: </span>
                        <span>{listing.company.name}</span>
                      </li>
                    )}
                    {publicProfile && publicProfile.phone && (
                      <li className="detail-contact-company-contact-item contact-company-contact-item company-contact-item">
                        <span>Company Phone: </span>
                        <span>{publicProfile.phone}</span>
                      </li>
                    )}
                  </ul>
                </Col>
                <Col
                  xs={12}
                  sm={5}
                  className="d-none detail-contact-company-logo contact-company-logo company-logo flex-shrink-1 mb-4 d-flex d-sm-block justify-content-center"
                >
                  {listing && listing.company && listing.company.logo && (
                    <img
                      src={listing.company.logo}
                      alt={`${listing.company.name} Logo`}
                      className="img-fluid"
                    />
                  )}
                </Col>
              </Row>
              <Row>
                <Col className="detail-contact-company-buttons contact-company-buttons company-buttons d-flex d-md-block justify-content-center">
                  <button
                    className="btn btn-primary mb-4 mr-3"
                    onClick={() => this.props.openContactForm(listing)}
                  >
                    contact
                  </button>
                  {listing && listing.company && listing.company.slug && (
                    <Link
                      to={generatePath(Routes.company, {
                        company: listing.company.slug,
                      })}
                      className="btn btn-primary mb-4"
                    >
                      See All Company Listings
                    </Link>
                  )}
                </Col>
              </Row>
            </Col>
            <Col
              xs={12}
              md={4}
              className="detail-contact-company-time contact-company-time company-time"
            >
              <ul className="detail-contact-company-time-list contact-company-time-list company-time-list">
                {createdDate && (
                  <li className="detail-contact-company-time-item contact-company-time-item company-time-item text-center text-md-right">
                    <span className="">Created: </span>
                    <span>{createdDate}</span>
                  </li>
                )}
                {updatedDate && (
                  <li className="detail-contact-company-time-item contact-company-time-item company-time-item text-center text-md-right">
                    <span>Last Updated: </span>
                    <span>{updatedDate}</span>
                  </li>
                )}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const keyDispatchToProps = (dispatch) => {
  return {
    openContactForm: (listing) => dispatch(openContactForm(listing)),
  };
};

export default connect(null, keyDispatchToProps)(GetInTouch);
