import React, { Component } from "react";
import { Route, Switch, Link, generatePath } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

import Homepage from "./homepage/index";
import Featured from "./featured/index";
import Sponsored from "./sponsored/index";
import Blitz from "./blitz/index";
import Social from "./social/index";
import Schedule from "./schedule/index";

import { ReactComponent as HomepageIcon } from "images/icons/promotions.svg";
import { ReactComponent as CategoryIcon } from "images/icons/star.svg";
import { ReactComponent as SponsoredIcon } from "images/icons/flag.svg";
import { ReactComponent as BlitzIcon } from "images/icons/blitz.svg";
import { ReactComponent as SocialIcon } from "images/icons/comment.svg";
import { ReactComponent as ScheduleIcon } from "images/icons/calendar.svg";

import Routes from "routes";

class Promotions extends Component {
  constructor(props) {
    super(props);

    this.sections = [
      {
        isDefault: true,
        slug: "homepage",
        title: "Homepage Featured",
        component: Homepage,
        icon: <HomepageIcon />,
      },
      {
        slug: "featured",
        title: "Category Featured",
        component: Featured,
        icon: <CategoryIcon />,
      },
      {
        slug: "sponsored",
        title: "Sponsored Ads",
        component: Sponsored,
        icon: <SponsoredIcon />,
      },
      {
        slug: "blitz",
        title: "Blitz",
        component: Blitz,
        icon: <BlitzIcon />,
      },
      {
        slug: "social",
        title: "Social Media",
        component: Social,
        icon: <SocialIcon />,
      },
      {
        slug: "schedule",
        title: "Schedule",
        component: Schedule,
        icon: <ScheduleIcon />,
      },
      //   {
      //     slug: "sale-highlights",
      //     title: "ACFS Highlights",
      //     component: SaleHighlights
      //   },
      //   {
      //     slug: "wanted-highlights",
      //     title: "ACW Highlights",
      //     component: WantedHighlights
      //   },
      //   {
      //     slug: "makes-models",
      //     title: "Makes & Models",
      //     component: MakesModels
      //   },
      //   {
      //     slug: "advertising",
      //     title: "Advertising Page",
      //     component: Advertising
      //   },
      //   {
      //     slug: "policies",
      //     title: "Policies",
      //     component: Policies
      //   }
    ];
  }
  //const list = ["edit subscriptions", "EDIT ACFS HIGHLIGHTS", "EDIT ACW HIGHLIGHTS", "ADD NEW AIRCRAFT", "EDIT ADVERTISING PAGE", "EDIT POLICIES"]

  getTabClass = (section) => {
    if (
      this.props.match.params.section === section ||
      (!this.props.match.params.section && section === "homepage")
    ) {
      return "menu-item active";
    }
    return "menu-item";
  };

  SectionNav = (active) => {
    return (
      <ul className="tabbed-nav promotions-nav">
        {this.sections.map((section, i) => (
          <li
            key={`site-setting-nav-${section.slug}`}
            className={this.getTabClass(section.slug)}
          >
            <Link
              to={generatePath(Routes.promotions, {
                section: section.slug,
              })}
            >
              <span className="item-num">{section.icon}</span>
              <span className="item-name">{section.title}</span>
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    return (
      <section
        id="app-promotions"
        className="app-content app-main promotions promotion-page"
      >
        <Container>
          <Row className="mb-4">
            <Col xs={12} md>
              <div className="site-settings__title">
                ALL <span>PROMOTIONS</span>
              </div>
            </Col>
            <Col xs={12} md={8} lg={9}>
              {this.SectionNav()}
            </Col>
          </Row>
          <Row>
            <Col className="mb-4 text-right">
              <Link
                to={Routes.promotionsNew}
                className="btn btn-primary btn-green"
              >
                + NEW
              </Link>
            </Col>
          </Row>
          <Switch>
            {this.sections
              .slice()
              .reverse()
              .map((section, i) => (
                <Route
                  key={`promotion-route-${section.slug}`}
                  exact={!section.isDefault}
                  path={generatePath(Routes.promotions, {
                    section: section.isDefault ? null : section.slug,
                  })}
                  component={section.component}
                />
              ))}
          </Switch>
        </Container>
      </section>
    );
  }
}

export default Promotions;
