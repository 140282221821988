import React from "react";
import Routes, { createTitle } from "routes";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ReactComponent as AirplaneGrounded } from "images/icons/airplane-grounded.svg";

export default class NotFoundPage extends React.Component {
  componentDidMount() {
    document.title = createTitle("403 Forbidden", Routes);
    document.getElementById("body").className =
      "forbidden forbidden-page 403 403-page error-page";
  }
  componentWillUnmount() {
    document.getElementById("body").className = "";
  }

  render() {
    return (
      <section
        id="app-page-forbidden"
        className="error-page-content app-forbidden forbidden-page d-flex justify-content-center"
      >
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={12} md={10} lg={9} xl={8}>
              <header className="forbidden-page-hader page-header">
                <h1 className="page-title">
                  Oops! <strong>You're Not Cleared for Takeoff.</strong>
                </h1>
                <div className="sub-header">
                  <strong>
                    Your account is not authorized to view this page.
                  </strong>
                </div>
              </header>
              <AirplaneGrounded />
              <p className="page-content">
                If you feel you've reached this page in error, please contact
                your company administrator or{" "}
                <a href="mailto:sales@planemover.com?subject=Unauthorized Account Error">
                  sales@planemover.com
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
