import React, { useState } from "react";
import axios from "axios";

import { Row, Col, Button, Form } from "react-bootstrap";

import EditableInput from "components/backend/shared/editableInput";
import PromoteCalendar from "components/backend/promotions/shared/calendar";
import MultiImageBrickUpload from "components/backend/shared/multiImageBrickUpload";

export default function BlitzPromotionEdit(props) {
  const { promotion, onChange, onMutate } = props;

  const [images, setImages] = useState(props.promotion.images);

  const updatePromotion = (value) => {
    if (onChange) onChange(value);
  };

  const findAddedImages = (changed) => {
    // Next, determine the "Order" of the last surviving image
    // We'll use it as the starting point for new images
    let order = 0;
    const lastImage = images.slice().pop();
    if (lastImage) {
      order = lastImage.MediaAttachment.order;
    }

    // Finally, upload and attach the new images added
    return changed
      .filter((i) => i.file)
      .map((f, i) => ({ ...f, order: order + i + 1 }));
  };

  const findRemovedImages = (changed) => {
    return images.filter((i) => {
      if (changed.some((j) => j.preview === i.url)) {
        return false;
      }
      return true;
    });
  };

  const addImages = (changed) => {
    return changed.map((image) => {
      // This is a new image. Upload it.
      let formdata = new FormData();
      formdata.append("image", image.file);
      formdata.append("order", image.order);

      return axios
        .post(`/promotion/${promotion.id}/image`, formdata, {
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .then((response) => {
          //return response;
          setImages(response.data.data);
          //if (onMutate) onMutate({ images: response.data.data });
        })
        .catch((err) => {
          console.error(err);
        });
    });
  };

  const removeImages = (changed) => {
    // Delete the image(s)
    return changed.map((image) => {
      return axios
        .delete(`/promotion/${promotion.id}/image/${image.uid}`)
        .then((response) => {
          //if (onMutate) onMutate({ images: undefined });
          setImages(images.filter((i) => i.uid !== image.uid));
        })
        .catch((err) => {
          //return props.promotion.images;
          console.error(err);
        });
    });
  };

  const listingHighlights = (listing) => {
    if (listing.highlights && listing.highlights.length > 0) {
      return listing.highlights.map((h) => h.name).join(", ");
    }
    return ";";
  };

  return (
    <div className="promotion-edit">
      <div className="promotion-edit-form">
        <Row>
          <Col xs={12} md={8}>
            <div className="editable-input-group input-group">
              <label className="editable-input-group-label input-group-label group-label label form-label">
                Highlights:
              </label>
              <div className="editable-input-group-reading input-group-reading group-reading reading">
                <div className="editable-input-group-read-value input-group-read-value group-read-value read-value value">
                  {listingHighlights(promotion.listing)}
                </div>
              </div>
            </div>
            <EditableInput
              label="Additional Features to Highlight"
              value={promotion.content || ""}
              renderInput={({ setEdit, setValue, getValue }) => (
                <>
                  <Form.Control
                    as="textarea"
                    rows="3"
                    value={promotion.content || ""}
                    onChange={(value) => updatePromotion({ content: value })}
                  />
                  <Button
                    className="mt-1"
                    onClick={(e) => {
                      this.handleTextEditorChange("page-title", getValue());
                      setEdit(false);
                    }}
                  >
                    Done
                  </Button>
                </>
              )}
              onChange={() => {}}
            />
            <MultiImageBrickUpload
              images={images.map((i) => i.url)}
              maxImages={5}
              onChange={(changed) => {
                // First, check to see if any images were removed.
                // If so, add them to a new array that we can process with delete requests.
                const removed = findRemovedImages(changed);

                // Next, find any new images
                // Add them to a nw array so they can be uploaded
                const added = findAddedImages(changed);

                const proms = [];
                if (removed) proms.push(removeImages(removed));
                if (added) proms.push(addImages(added));

                Promise.all(proms)
                  .then(([removed, added]) => {
                    if (onMutate) onMutate({ images });
                  })
                  .catch((err) => console.error(err));
              }}
            />

            <EditableInput
              label="Comments"
              value={promotion.comments || ""}
              onChange={(value) => updatePromotion({ comments: value })}
            />
          </Col>
          <Col xs={12} md={4}>
            <div className="Calendar-div">
              <PromoteCalendar
                selectSingle
                startDate={promotion.startDate}
                onChange={(date) => {
                  updatePromotion({ startDate: date });
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
