import React, { Component } from "react";

import Dropzone from "react-dropzone";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from "array-move";

import Card from "./card";
import { ReactComponent as PlusIcon } from "images/icons/plus-thick.svg";

const SortableItem = SortableElement(({ children }) => <div>{children}</div>);

const SortableList = SortableContainer(({ children }) => {
  return <div className="dropzone-container">{children}</div>;
});

class ImageUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const images = arrayMove(this.props.images, oldIndex, newIndex);
    this.setState({ images });
    if (this.props.onChange) this.props.onChange(images);
  };

  onDrop = (acceptedFiles) => {
    // do nothing if no files
    if (acceptedFiles.length === 0) {
      return;
    }

    // on drop we add to the existing files
    let images = this.props.images.slice(0);
    images = images.concat(
      acceptedFiles.map((file, i) =>
        Object.assign(file, {
          id: this.props.images.length + i,
          url: URL.createObjectURL(file),
          upload: true,
        })
      )
    );

    if (this.props.onChange) this.props.onChange(images);

    // this.setState({
    //   images: images,
    // });
  };

  removeItem = (index) => {
    if (this.props.onRemove) this.props.onRemove(this.state.removed);
  };

  render() {
    return (
      <div className="image-upload-panel">
        <p>
          Select a primary photo and the four images you would like to appear
          first in the gallery on your listing by dragging and dropping. Arrange
          the rest of the images in the order you’d like them to appear.
        </p>
        <small className="text-uppercase">
          Acceptable file types are: JPG, JPEG, PNG, GIF
        </small>
        <br />
        <small className="text-uppercase">Files Cannot Exceed 20MB</small>

        <Dropzone
          accept="image/*"
          onDrop={(acceptedFiles) => this.onDrop(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <SortableList onSortEnd={this.onSortEnd} axis="xy">
              {this.props.images.map((image, index) => (
                <SortableItem key={`image-${image.id}`} index={index}>
                  <Card
                    id={index}
                    index={index}
                    image={image}
                    removeItem={(index) => {
                      if (this.props.onRemove) this.props.onRemove(index);
                    }}
                  />
                </SortableItem>
              ))}
              <div {...getRootProps()} className="add-image">
                <input {...getInputProps()} />
                <PlusIcon />
              </div>
            </SortableList>
          )}
        </Dropzone>
      </div>
    );
  }
}

export default ImageUpload;
