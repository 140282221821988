import React, { Component } from 'react';

class SearchActions extends Component {
  render() {
    return (
      <div className="search-block__actions">
        <div className="search-block__actions__label">
          <span>24</span>
          <span> matches</span>
        </div>
        <button className="search-block__actions__button">Delete</button>
      </div>
    );
  }
}

export default SearchActions;