import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { object, string, ref } from "yup";

import { Container, Row, Col, Alert, Form } from "react-bootstrap";

import { resetPassword, checkPasswordToken } from "actions/user/actions";

import Spinner from "components/shared/spinner";
import Routes from "routes";
import { parseAxiosError } from "utils/errors";

const ResetPassword = (props) => {
  const { user, resetPassword, checkPasswordToken } = props;
  const { history, match } = props;

  // Only non-logged in users can view this page
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [loading, setLoading] = useState(true);

  const { code } = match.params;

  useEffect(() => {
    // Check Code
    checkPasswordToken(code)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        history.push(Routes.forbidden);
      });
  }, [checkPasswordToken, code, history]);

  if (!_.isEmpty(user)) history.push(Routes.forbidden);

  const schema = {
    newPassword: string()
      .required("New Password is required")
      .min(8, "Password has to be longer than 8 characters")
      .matches(/[a-z]/, "at least one lowercase char")
      .matches(/[A-Z]/, "at least one uppercase char")
      .matches(
        /[^a-zA-Z\s]/,
        "at least 1 number or special char (@,!,#, etc)."
      ),
    confirmPassword: string()
      .required("Confirm Password is required")
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: string().oneOf(
          [ref("newPassword")],
          "Confirm password need to be the same"
        ),
      }),
  };

  const handleSubmitPassword = (newPassword, setSubmitting) => {
    setLoading(true);
    setSubmitting(true);
    resetPassword(code, newPassword)
      .then((res) => {
        setSuccess("Password Changed!");
      })
      .catch((err) => {
        setError(parseAxiosError(err));
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  const content = (error, success, loading) => {
    if (loading) {
      return <Spinner />;
    }

    if (error) {
      return <Alert variant="danger">{error}</Alert>;
    }

    if (success) {
      return (
        <>
          <Alert variant="success">{success}</Alert>
          <Link to={Routes.login} className="btn btn-primary btn-green">
            Login
          </Link>
        </>
      );
    }

    return (
      <Formik
        initialValues={{
          newPassword: "",
          confirmPassword: "",
        }}
        validationSchema={object().shape(schema)}
        onSubmit={({ newPassword }, { setSubmitting }) =>
          handleSubmitPassword(newPassword, setSubmitting)
        }
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form
            onSubmit={
              isSubmitting
                ? (e) => {
                    e.preventDefault();
                  }
                : handleSubmit
            }
            className="password-form"
          >
            <div>
              <Form.Group
                className="password-form-group"
                controlId="newPassword"
              >
                <Form.Label>New Password:</Form.Label>
                <div>
                  <Form.Control
                    name="newPassword"
                    className="w-100"
                    value={values.newPassword}
                    onChange={handleChange}
                    type="password"
                    isInvalid={touched.newPassword && !!errors.newPassword}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.newPassword}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group
                className="password-form-group"
                controlId="confirmPassword"
              >
                <Form.Label>Confirm Password:</Form.Label>
                <div>
                  <Form.Control
                    name="confirmPassword"
                    className="w-100"
                    value={values.confirmPassword}
                    onChange={handleChange}
                    type="password"
                    isInvalid={
                      touched.confirmPassword && !!errors.confirmPassword
                    }
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.confirmPassword}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
            <div className="password-form-save">
              <button
                type="submit"
                className="btn btn-tertiary password-form-save-button"
              >
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <Container className="registration">
      <div className="sales">
        <Row>
          <Col xs={12}>
            <h1 className="page-title">
              Reset Your <strong>Password</strong>
            </h1>
          </Col>
        </Row>
      </div>
      {content(error, success, loading)}
    </Container>
  );
};

const keyStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    checkPasswordToken: (token) => dispatch(checkPasswordToken(token)),
    resetPassword: (token, password) =>
      dispatch(resetPassword(token, password)),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(ResetPassword);
