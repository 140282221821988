import React from "react";

import InformationBlock from "components/frontend/listings/for-sale/shared/informationBlock";

export default function Weights(props) {
  const { listing } = props;

  return (
    listing.weights && <InformationBlock name="weights" title="Weights">
      <p dangerouslySetInnerHTML={{ __html: listing.weights }} className="white-space-pre" />
    </InformationBlock>
  );
}
