import {
  SET_ACCOUNT_ROLES,
  SET_ACCOUNT_STATUSES,
  SET_COUNTRIES,
  SET_STATES,
} from "actions/masterData/actionTypes";

const initialState = {
  countries: [],
  states: [],
  accountRoles: [],
  accountStatuses: [],
};

export default function masterDataReducer(state = initialState, action) {
  const { payload, type } = action;

  switch (type) {
    case SET_COUNTRIES:
      return Object.assign({}, state, {
        countries: payload,
      });
    case SET_STATES:
      return Object.assign({}, state, {
        states: payload,
      });
    case SET_ACCOUNT_ROLES:
      return Object.assign({}, state, {
        accountRoles: payload,
      });
    case SET_ACCOUNT_STATUSES:
      return Object.assign({}, state, {
        accountStatuses: payload,
      });
    default:
      return state;
  }
}
