import React, { useEffect } from "react";
import { connect } from "react-redux";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import Routes, { createTitle } from "routes";

import Spinner from "components/shared/spinner";
import Card from "components/shared/planCard";

import { getPlans } from "actions/plan/actions";
import { openSignup } from "actions/modal/actions";

const PricingPage = (props) => {
  const { plans, getPlans, openSignup } = props;

  useEffect(() => {
    document.title = createTitle("Advertising Subscriptions", Routes);
    getPlans();
  }, [getPlans]);

  const headerContent = () => {
    return (
      <>
        <h1 className="page-title">
          Advertising <strong>Subscriptions</strong>
        </h1>
        <p className="sub-header">
          <strong>
            Help your listing soar by upgrading your account level
          </strong>
        </p>
        <Row className="justify-content-center">
          <Col xs={12} sm={11} md={10} lg={9}>
            <p>
              Try Plane Mover for free with one listing and discover how you can
              promote your aircraft for sale, aircraft wanted or services.
              Listings include images and videos, detailed descriptions and
              more. Choose the subscription that's right for you.
            </p>
          </Col>
        </Row>
      </>
    );
  };

  const planContent = (plans) => {
    if (plans) {
      return (
        <div className="plans plans-form">
          <div className="plans-cards">
            {plans.map((plan, key) => (
              <Card
                key={key}
                selectable={false}
                type={plan.slug}
                mostPopular={plan.slug === "gold"}
                title={plan.title}
                price={plan.price}
                description={plan.description}
                homepageDisplayCost={plan.homepageDisplayCost}
                featuredListingCost={plan.featuredListingCost}
                sponsoredAdCost={plan.sponsoredAdCost}
                blitzEmailCost={plan.blitzEmailCost}
                socialFacebookCost={plan.socialFacebookCost}
                socialTwitterCost={plan.socialTwitterCost}
                socialInstagramCost={plan.socialInstagramCost}
                footerRender={() => (
                  <Button onClick={openSignup}>Sign Up</Button>
                )}
              />
            ))}
          </div>
        </div>
      );
    }

    return <Spinner />;
  };

  return (
    <section id="app-page-pricing" className="app-pricing-page pricing-page">
      <header className="pricing-page-hader page-header mt-5">
        <Container id="pricing-header-row" className="header-row">
          <Row className="justify-content-center">
            <Col className="text-center justify-content-center">
              {headerContent()}
            </Col>
          </Row>
        </Container>
      </header>

      <Container id="pricing-columns-row">
        <Row className="mb-5">
          <Col className="mb-5">{planContent(plans)}</Col>
        </Row>
      </Container>
    </section>
  );
};

const keyStateToProps = (state) => {
  return {
    plans: state.plans,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    openSignup: () => dispatch(openSignup()),
    getPlans: () => dispatch(getPlans()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(PricingPage);
