import React, { useEffect, useState, useRef } from "react";

import { Container, Row, Col } from "react-bootstrap";

import SocialButtons from "components/frontend/listings/_shared/socialButtons";

export default function WantedInformation(props) {
  const { listing } = props;

  const [expanded, setExpanded] = useState(false);
  const descriptionBox = useRef(null);
  const [totalLines, setTotalLines] = useState(3); 

  useEffect(() => {
    if (descriptionBox.current) {
      if(props.listing.description!==null){  
      const maxHeight = descriptionBox.current.scrollHeight;
      const lineHeight = parseFloat(getComputedStyle(descriptionBox.current).lineHeight);
      const maxLines = Math.floor(maxHeight / lineHeight);
      setTotalLines(maxLines + (props.listing.description.split("\n").length - 1));
      }
    }
  }, [props.listing.description]);

  let listingMode = listing.level.value;
  console.log("listing",listing.level);
  console.log("company",listing.company);
  if (listingMode !== "featured") {
    // Set display type to Subscriber or Basic/Free
    if (
      listing.company &&
      listing.company.plan &&
      listing.company.plan.rank &&
      listing.company.plan.rank > 0
    ) {
      listingMode = "subscriber";
    } else {
      listingMode = "basic";
    }

  }
  return (
    <div className="for-sale-detail-information detail-information">
      <Container className="py-5">
        <Row className="for-sale-detail-information-header detail-information-header">
          <Col className="d-md-flex align-items-center justify-content-between">
            <h2 className="detail-information-title information-title mb-4 mb-md-0">
              Service <strong>Information</strong>
            </h2>
            <SocialButtons listing={listing} />
          </Col>
        </Row>
        {/* {listingMode !== "basic" && ( */}
          <Row className="mt-3">
            <Col className="detail-information-description information-description pb-4">
              <div
                className={`overflow-container ${expanded ? "expanded" : "collapsed"}`}       
                ref={descriptionBox}
              >
                {listing.description &&
                  listing.description.split("\n").map((line, i) => {
                    return <p key={`description-line-${i}`}>{line}</p>;
                  })}
              </div>
              {totalLines > 3 && (
                <div className="overflow-button text-center mt-3">
                  <button
                    onClick={(e) => {
                      setExpanded(!expanded);
                    }}
                    className="btn btn-black read-more-button"
                  >
                    {expanded ? "Read Less" : "Read More"}
                  </button>
                </div>
              )}
            </Col>
          </Row>
        {/* )} */}
      </Container>
    </div>
  );
}
