import React from "react";

export default class SingleImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { file: undefined, preview: this.props.preview };
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.props.onUpload) {
      this.props.onUpload(this.state.file);
    }
  }

  handleCancel(e) {
    e.preventDefault();
    if (this.props.onCancel) {
      this.props.onCancel();
    }
  }

  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        file: file,
        preview: reader.result
      });
    };

    reader.readAsDataURL(file);
  }

  render() {
    let { preview } = this.state;
    let $imagePreview = null;
    if (preview) {
      $imagePreview = <img src={preview} alt="Logo Upload Preview" />;
    } else {
      $imagePreview = <div className="previewText"></div>;
    }

    return (
      <div className="single-image-upload">
        <form onSubmit={e => this.handleSubmit(e)}>
          <input
            className="fileInput"
            type="file"
            onChange={e => this.handleImageChange(e)}
          />
          <div className="imgPreview image-preview">{$imagePreview}</div>
          <button
            className="submitButton btn btn-green"
            type="submit"
            onClick={e => this.handleSubmit(e)}
            disabled={!this.state.file}
          >
            Upload Image
          </button>
          <button
            className="submitButton btn btn-red"
            onClick={e => this.handleCancel(e)}
          >
            Cancel
          </button>
        </form>
      </div>
    );
  }
}
