import React from "react";
import axios from "axios";
import { Formik } from "formik";

import { Button, Row, Col, Form, Modal } from "react-bootstrap";

import Spinner from "components/shared/spinner";
import EditableInput from "components/backend/shared/editableInput";
import SaveButtonText from "components/backend/listings/fields/saveButtonText";
import RoleRenderer from "components/shared/roleRenderer";

import ImageUpload from "../../imageUpload/imageUpload";

import { ReactComponent as CheckIcon } from "images/icons/check.svg";
import { ReactComponent as ErrorIcon } from "images/icons/close.svg";
import { connect } from "react-redux";

const yup = require("yup");

class Step2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      images: this.props.values.images || [],
      removed: [],
      specFile: undefined,
      specError: undefined,
      specRemoved: false,
      uploading: false,
      uploadTotal: 0,
      uploadComplete: false,
      uploadProgress: [],
      uploadSuccess: [],
      uploadError: [],
      action: "save",
    };
  }

  handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.props.onChange(name, value);
  };

  validateImage = (file) => {
    if (file && file.hasOwnProperty("type")) {
      switch (file.type) {
        case "image/jpeg":
        case "image/gif":
        case "image/png":
          return true;
        default:
          return false;
      }
    }
    return false;
  };

  validateSpecSheet = (file) => {
    if (file && file.type) {
      switch (file.type) {
        case "application/msword":
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        case "application/pdf":
          return true;
        default:
          return false;
      }
    }
    return false;
  };

  handleImageChange = (images) => {
    this.setState({ images });
  };

  handleImageRemove = (index) => {
    const { images, removed } = this.state;
    const deleted = images.filter((file, i) => i === index);
    const updated = images.filter((file, i) => i !== index);
    //let images = this.state.images.slice(0);
    //images = images.filter((file, i) => i !== index);
    this.setState({
      images: updated,
      removed: [...removed, ...deleted],
    });
  };

  parseAxiosError = (error) => {
    if (
      error.response &&
      error.response.status &&
      error.response.data &&
      error.response.data.message
    ) {
      // Request made and server responded
      return `${error.response.status}: ${error.response.data.message}`;
    } else if (
      error.request &&
      error.request.status &&
      error.request.data &&
      error.request.data.message
    ) {
      // The request was made but no response was received
      return `${error.request.status}: ${error.request.data.message}`;
    } else if (error.message) {
      // Something happened in setting up the request that triggered an Error
      return `Unknown Error: ${error.message}`;
    } else {
      return `Unknown Error`;
    }
  };

  upload = () => {
    return new Promise((resolve, reject) => {
      const { images, removed, specFile, specRemoved } = this.state;
      if (
        (images && images.length > 0) ||
        (removed && removed.length > 0) ||
        specFile ||
        specRemoved
      ) {
        this.setState(
          {
            uploading: true,
            uploadComplete: false,
            uploadTotal: 0,
            uploadProgress: [],
            uploadError: [],
            uploadSuccess: [],
          },
          () => {
            const uploadImages = this.uploadImages();
            const uploadSpecs = this.uploadSpecSheet();

            const uploads = [
              ...uploadImages.uploadRequests,
              ...uploadSpecs.uploadRequests,
            ];
            const progresses = [
              ...uploadImages.progresses,
              ...uploadSpecs.progresses,
            ];
            this.setState(
              { uploadTotal: progresses.length, uploadProgress: progresses },
              () => {
                axios
                  .all(uploads)
                  .then(() => {
                    this.setState({ uploadComplete: true }, () => {
                      resolve();
                    });
                  })
                  .catch((err) => {
                    reject(err);
                  })
                  .finally(() => {
                    this.setState({ uploadComplete: true }, () => {
                      resolve();
                    });
                  });
              }
            );
          }
        );
      } else {
        this.postUpload().finally(() => {
          resolve();
        });
      }
    });
  };

  uploadImages = () => {
    const { slug } = this.props.values;
    const { images, removed } = this.state;
    const uploadRequests = [];
    const progresses = [];

    if (images && images.length > 0) {
      images.forEach((img, order) => {
        if (img.upload) {
          
          // This is a new image. Upload it.
          let formdata = new FormData();
          const ext = img.name.split('.').pop();

          // Create a timestamped filename
          const timestampedFilename = `${Date.now()}.${ext}`;
          formdata.append("image", img, timestampedFilename);
          formdata.append("order", order);

          progresses.push({ filename: timestampedFilename, order, type: "upload" });

          uploadRequests.push(
            axios
              .post(`/manage/slug/${slug}/image`, formdata, {
                headers: {
                  "content-type": "multipart/form-data",
                },
              })
              .then((res) => {
                const { uploadProgress, uploadSuccess } = this.state;
                const finished = uploadProgress.filter(
                  (u) => u.order === order
                );
                const progressed = uploadProgress.filter(
                  (u) => u.order !== order
                );
                this.setState({
                  uploadProgress: progressed,
                  uploadSuccess: [...uploadSuccess, ...finished],
                });
              })
              .catch((err) => {
                const { uploadProgress, uploadError } = this.state;
                const errored = uploadProgress
                  .filter((u) => u.order === order)
                  .shift();

                const erroredMsg = [
                  {
                    ...errored,
                    message: this.parseAxiosError(err),
                    error: err,
                  },
                ];
                const progressed = uploadProgress.filter(
                  (u) => u.order !== order
                );
                this.setState({
                  uploadProgress: progressed,
                  uploadError: [...uploadError, ...erroredMsg],
                });
              })
          );
        } else {
          // This is an existing image. Update the order
          if (img.order !== order) {
            progresses.push({
              filename: img.uid.split("/").pop(),
              order,
              type: "update",
            });
            uploadRequests.push(
              axios
                .put(`/manage/slug/${slug}/image/${img.uid}`, {
                  order,
                })
                .then((res) => {
                  const { uploadProgress, uploadSuccess } = this.state;
                  const finished = uploadProgress.filter(
                    (u) => u.order === order
                  );
                  const progressed = uploadProgress.filter(
                    (u) => u.order !== order
                  );
                  this.setState({
                    uploadProgress: progressed,
                    uploadSuccess: [...uploadSuccess, ...finished],
                  });
                })
                .catch((err) => {
                  const { uploadProgress, uploadError } = this.state;
                  const errored = uploadProgress
                    .filter((u) => u.order === order)
                    .shift();
                  const erroredMsg = [
                    {
                      ...errored,
                      message: this.parseAxiosError(err),
                      error: err,
                    },
                  ];
                  const progressed = uploadProgress.filter(
                    (u) => u.order !== order
                  );
                  this.setState({
                    uploadProgress: progressed,
                    uploadError: [...uploadError, ...erroredMsg],
                  });
                })
            );
          }
        }
      });
    }

    if (removed && removed.length > 0) {
      removed.forEach((img, order) => {
        if (img.upload) {
          // This is a new image. And it got removed before upload. Just ignore it.
        } else {
          // This is an existing image. DELETE IT!
          uploadRequests.push(
            axios.delete(`/manage/slug/${slug}/image/${img.uid}`)
          );
        }
      });
    }

    return { uploadRequests, progresses };

    // return Promise.all(uploadRequests).then(() => {
    //   return slug;
    // });
  };

  uploadSpecSheet = () => {
    const { slug } = this.props.values;
    // Upload Spec Sheet, if any
    const { specFile, specRemoved } = this.state;

    const uploadRequests = [];
    const progresses = [];

    if (specFile) {
      let formdata = new FormData();
      formdata.append("file", specFile);

      progresses.push({
        filename: specFile.name,
        order: "spec",
        type: "upload",
      });

      uploadRequests.push(
        axios
          .post(`/manage/slug/${slug}/spec`, formdata, {
            headers: {
              "content-type": "multipart/form-data",
            },
          })
          .then((res) => {
            const { uploadProgress, uploadSuccess } = this.state;
            const finished = uploadProgress.filter((u) => u.order === "spec");
            const progressed = uploadProgress.filter((u) => u.order !== "spec");
            this.setState({
              uploadProgress: progressed,
              uploadSuccess: [...uploadSuccess, ...finished],
            });
          })
          .catch((err) => {
            const { uploadProgress, uploadError } = this.state;
            const errored = uploadProgress.filter((u) => u.order === "spec");
            const progressed = uploadProgress.filter((u) => u.order !== "spec");
            this.setState({
              uploadProgress: progressed,
              uploadError: [...uploadError, ...errored],
            });
          })
      );
    } else if (specRemoved) {
      uploadRequests.push(axios.delete(`/manage/slug/${slug}/spec`));
    }
    return { uploadRequests, progresses };
  };

  uploadProgressLabel = () => {
    const { uploadSuccess, uploadTotal, uploadError, uploadProgress } =
      this.state;

    let blackText;
    let greenText;
    let redText;

    if (uploadProgress.length !== 0) {
      blackText = `Uploading ${uploadSuccess.length} / ${uploadTotal} Files`;
    } else {
      greenText = `${uploadSuccess.length} Files Uploaded Successfully`;
    }

    if (uploadError.length > 0) {
      redText = `${uploadError.length} File(s) Failed`;
    }

    return (
      <div className="upload-status">
        <div className="upload-progress">{blackText}</div>
        <div className="upload-success">{greenText}</div>
        <div className="upload-error">{redText}</div>
      </div>
    );
  };

  postUpload = () => {
    return new Promise((resolve, reject) => {
      const { action } = this.state;
      if (action === "next") {
        this.props.onNext().then(() => {
          resolve();
        });
      } else {
        this.props.onSave().then(() => {
          this.setState({ uploading: false }, () => {
            resolve();
          });
        });
      }

      reject();
    });
  };

  render() {
    const schema = yup.object().shape({
      video: yup.string().url("This must be a URL").notRequired(),
      video360: yup.string().url("This must be a URL").notRequired(),
    });

    return (
      <>
        <Formik
          validationSchema={schema}
          //onSubmit={values => this.handleSubmit(values)}
          onSubmit={this.handleValidation}
          initialValues={{
            video: this.props.values.video || "",
            video360: this.props.values.video360 || "",
          }}
          validateOnChange={true}
        >
          {({
            handleSubmit,
            handleChange,
            values,
            touched,
            errors,
            isSubmitting,
            setSubmitting,
            validateForm,
            isValid,
          }) => (
            <Form onSubmit={handleSubmit} noValidate>
              <div className="stepper_4">
                <Form.Label className="subHeader">Flaunt Your Work</Form.Label>
                <Row className="m-b-15">
                  <Col>
                    <ImageUpload
                      images={this.state.images || []}
                      onChange={this.handleImageChange}
                      onRemove={this.handleImageRemove}
                    />
                  </Col>
                </Row>
                <Form.Row className="stepper_2_upload upload-section mt-5">
                  <Form.Group className="d-flex video" as={Col} sm={12} md={6}>
                    <Form.Label>Video Link:</Form.Label>
                    <Form.Control
                      as="input"
                      type="url"
                      name="video"
                      value={this.props.values.video || ""}
                      placeholder="http://"
                      onChange={(e) => {
                        handleChange(e);
                        this.handleChange(e);
                      }}
                      isInvalid={touched.video && !!errors.video}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.video}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="d-flex 360" as={Col} sm={12} md={6}>
                    <Form.Label>360&deg; Video Link:</Form.Label>
                    <Form.Control
                      as="input"
                      type="url"
                      name="video360"
                      value={this.props.values.video360 || ""}
                      placeholder="http://"
                      onChange={(e) => {
                        handleChange(e);
                        this.handleChange(e);
                      }}
                      isInvalid={touched.video360 && !!errors.video360}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.video360}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group
                    className="d-flex spec-sheet-row"
                    as={Col}
                    sm={12}
                  >
                    <EditableInput
                      label="Upload Brochure"
                      edit={this.props.values.document ? false : true}
                      value={this.props.values.document || ""}
                      renderValue={(value) => {
                        const filename = value.split("/").pop();
                        return (
                          <a
                            href={value}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {filename}
                          </a>
                        );
                      }}
                      renderInput={({ setEdit, setValue }) => (
                        <div>
                          <input
                            className="fileInput"
                            type="file"
                            onChange={(e) => {
                              const { target } = e;
                              const file = target.files[0];

                              this.validateSpecSheet(file);
                              this.setState({ specError: undefined }, () => {
                                if (this.validateSpecSheet(file)) {
                                  //this.props.onChange("specsheet", file);
                                  this.setState({
                                    specFile: file,
                                    specRemoved: false,
                                  });
                                  setValue(file);
                                } else {
                                  target.value = null;
                                  setValue(null);

                                  this.setState({
                                    specFile: undefined,
                                    specError: "Invalid File Type",
                                  });
                                }
                              });
                            }}
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                          />
                          {this.props.values.document && (
                            <span>
                              <button
                                className="submitButton btn btn-green mr-3"
                                onClick={(e) => setEdit(false)}
                              >
                                Cancel
                              </button>
                              <button
                                className="submitButton btn btn-red"
                                onClick={(e) => {
                                  this.props.onChange("document", undefined);
                                  this.setState({
                                    specFile: undefined,
                                    specError: undefined,
                                    specRemoved: true,
                                  });
                                  setValue("");
                                }}
                              >
                                Delete
                              </button>
                            </span>
                          )}
                          {this.state.specError && (
                            <div className="error invalid-feedback d-block pl-1">
                              {this.state.specError}
                            </div>
                          )}
                        </div>
                      )}
                      onChange={() => {}}
                    />
                    <small className="text-uppercase">
                      Acceptable file types: PDF, DOC, DOCX
                    </small>
                  </Form.Group>
                </Form.Row>
                <Row className="stepper_btns">
                  <Col md="auto">
                    <RoleRenderer role="sales-rep" exact={true}>
                      <Button
                        type="submit"
                        className="btn-red m-b-15"
                        disabled={isSubmitting}
                        onClick={(e) => {
                          handleSubmit(e);
                          if (isValid) {
                            this.setState({ action: "next" }, () => {
                              this.upload().finally(() => {
                                setSubmitting(false);
                              });
                            });
                          } else {
                            setSubmitting(false);
                          }
                        }}
                      >
                        Submit for Review
                      </Button>
                    </RoleRenderer>
                    <RoleRenderer role="company-admin">
                      <Button
                        type="submit"
                        className="btn-red m-b-15"
                        disabled={isSubmitting}
                        onClick={(e) => {
                          handleSubmit(e);
                          if (isValid) {
                            this.setState({ action: "next" }, () => {
                              this.upload().finally(() => {
                                setSubmitting(false);
                              });
                            });
                          } else {
                            setSubmitting(false);
                          }
                        }}
                      >
                        Publish Listing
                      </Button>
                    </RoleRenderer>
                    <RoleRenderer role="advertiser" exact>
                      <Button
                        type="submit"
                        className="btn-red m-b-15"
                        disabled={isSubmitting}
                        onClick={(e) => {
                          handleSubmit(e);
                          if (isValid) {
                            this.setState({ action: "next" }, () => {
                              this.upload().finally(() => {
                                setSubmitting(false);
                              });
                            });
                          } else {
                            setSubmitting(false);
                          }
                        }}
                      >
                        Publish Listing
                      </Button>
                    </RoleRenderer>
                  </Col>
                  <Col md="auto">
                    <Button
                      className="btn-green"
                      disabled={isSubmitting}
                      type="submit"
                      onClick={(e) => {
                        handleSubmit(e);
                        if (isValid) {
                          this.setState({ action: "save" }, () => {
                            this.upload().finally(() => {
                              setSubmitting(false);
                            });
                          });
                        } else {
                          setSubmitting(false);
                        }
                      }}
                    >
                      {SaveButtonText(this.props.values.status)}
                    </Button>
                  </Col>
                </Row>
              </div>
              <Modal
                className="image-upload-modal"
                show={this.state.uploading}
                //onShow={this.handleOpen}
                //onHide={this.handleClose}
                size="sm"
                centered
                backdrop="static"
              >
                <Modal.Header>
                  <Col xs={4}>
                    <strong>File Upload</strong>
                  </Col>
                  <Col xs={8} className="text-right">
                    {this.uploadProgressLabel()}
                  </Col>
                </Modal.Header>
                <Modal.Body>
                  <ul className="uploads scrollable">
                    {this.state.uploadError &&
                      this.state.uploadError.map((u, i) => {
                        return (
                          <li key={`upload-${i}`} className="upload complete">
                            <Col xs={9} className="filename">
                              <div>{u.filename}</div>
                              <div className="error-message">{u.message}</div>
                            </Col>
                            <Col xs={3} className="icon text-right">
                              <span className="icon-error">
                                <ErrorIcon />
                              </span>
                            </Col>
                          </li>
                        );
                      })}
                    {this.state.uploadProgress &&
                      this.state.uploadProgress.map((u, i) => {
                        return (
                          <li
                            key={`upload-${i}`}
                            className="upload in-progress"
                          >
                            <Col xs={9} className="filename">
                              {u.filename}
                            </Col>
                            <Col xs={3} className="icon text-right">
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Uploading...</span>
                              </Spinner>
                            </Col>
                          </li>
                        );
                      })}
                    {this.state.uploadSuccess &&
                      this.state.uploadSuccess.map((u, i) => {
                        return (
                          <li key={`upload-${i}`} className="upload complete">
                            <Col xs={9} className="filename">
                              {u.filename}
                            </Col>
                            <Col xs={3} className="icon text-right">
                              <span className="icon-success">
                                <CheckIcon />
                              </span>
                            </Col>
                          </li>
                        );
                      })}
                  </ul>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="btn-red"
                    onClick={() => {
                      this.props.onSave().then(() => {
                        setSubmitting(false);
                        this.setState({ uploading: false });
                      });
                    }}
                    disabled={!this.state.uploadComplete}
                  >
                    Back
                  </Button>
                  <Button
                    variant="primary"
                    className="btn-primary btn-green"
                    onClick={() => {
                      this.postUpload().finally(() => {
                        setSubmitting(false);
                      });
                    }}
                    disabled={!this.state.uploadComplete}
                  >
                    Continue
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

export default connect(keyStateToProps)(Step2);
