import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";

import { closeLogin } from "actions/modal/actions";

import { ReactComponent as CloseIcon } from "images/icons/close-circle.svg";
import LoginForm from "components/frontend/account/loginForm";

class Login extends Component {
  render() {
    const { show, handleClose } = this.props;

    return (
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <div className="account-modal login">
          <button className="btn account-modal-close" onClick={handleClose}>
            <CloseIcon />
          </button>
          <LoginForm />
        </div>
      </Modal>
    );
  }
}

const keyStateToProps = (state) => {
  return {
    show: state.modal.openLogin,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    handleClose: () => dispatch(closeLogin()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(Login);
