import React from "react";
import PropTypes from "prop-types";
import Avatar from "components/shared/avatar";
import { Row, Col, Form } from "react-bootstrap";
import moment from "moment";
import { Link, generatePath } from "react-router-dom";

import trimText from "utils/trimText";

import Routes from "routes";
import { getName } from "utils/user";

class InboxTable extends React.Component {
  listingLink = (type, slug) => {
    switch (type) {
      case "wanted":
        return generatePath(Routes.wantedDetail, {
          listing: slug,
        });
      case "service":
        return generatePath(Routes.servicesDetail, {
          listing: slug,
        });
      case "sale":
      default:
        return generatePath(Routes.forSaleDetail, {
          listing: slug,
        });
    }
  };
  render() {
    return (
      <>
        <div className="inbox-table">
          <Row className="header">
            <Col>
              <Form.Group className="checkbox-wrapper">
                <Form.Check
                  type="checkbox"
                  label="Select All"
                  onChange={(evt) =>
                    this.props.onSelect &&
                    this.props.onSelectAll(evt.target.checked)
                  }
                />
              </Form.Group>
            </Col>
          </Row>
          {this.props.data.map((inquiry, key) => {
            return (
              <Row
                key={key}
                className={`item-row inquiry-row status-${inquiry.status.value}`}
                onClick={() => {
                  if (this.props.showPopup) {
                    this.props.showPopup(inquiry);
                  }
                }}
              >
                <div className="column-wrapper">
                  <Col
                    xs={{ span: 12, order: 0 }}
                    md="auto"
                    className="select-column item-column"
                  >
                    <Row>
                      <Col xs={6} md={12} className="checkbox-column">
                        <Form.Check
                          type="checkbox"
                          checked={
                            this.props.selected
                              ? this.props.selected.includes(inquiry.id)
                              : false
                          }
                          onChange={(evt) =>
                            this.props.onSelect &&
                            this.props.onSelect(inquiry.id, evt.target.checked)
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </Col>
                      <Col xs={6} md={false} className="d-md-none time-column">
                        <div className="time">
                          <span className="value">
                            {moment(inquiry.createdAt).calendar()}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    md={5}
                    lg={{ span: 4, order: 1 }}
                    className="from-column d-flex item-column mx-n1"
                  >
                    <div className="from-avatar px-1">
                      <Avatar name={inquiry.name} size={32} />
                    </div>
                    <div className="from-info px-1">
                      <div className="from-name">
                        <span className="label">From:</span>{" "}
                        <span className="value">{inquiry.name}</span>
                      </div>
                      {inquiry.email && (
                        <div className="from-email">
                          <span className="label">Email:</span>{" "}
                          <a href={`mailto:${inquiry.email}`} className="value">
                            {inquiry.email}
                          </a>
                        </div>
                      )}
                      {inquiry.phone && (
                        <div className="from-phone">
                          <span className="label">Phone:</span>{" "}
                          <a href={`tel:${inquiry.phone}`} className="value">
                            {inquiry.phone}
                          </a>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    sm={6}
                    md={6}
                    lg={{ span: 4, order: 3 }}
                    className="to-column d-none d-sm-flex item-column mx-n1"
                  >
                    <div className="to-avatar px-1">
                      <Avatar
                        name={getName(inquiry.listing.account)}
                        size={32}
                      />
                    </div>
                    <div className="to-info px-1">
                      <div className="to-name">
                        {inquiry.listing.account && (
                          <span>
                            <span className="label">To:</span>{" "}
                            <a href={`mailto:${inquiry.listing.account.email}`}>
                              {getName(inquiry.listing.account)}
                            </a>{" "}
                          </span>
                        )}

                        {inquiry.listing.company && (
                          <span>
                            at{" "}
                            <Link
                              to={generatePath(Routes.company, {
                                company: inquiry.listing.company.slug,
                              })}
                            >
                              {inquiry.listing.company.name}
                            </Link>
                          </span>
                        )}
                      </div>
                      {inquiry.listing.account.email && (
                        <div className="to-email">
                          <span className="label">Email:</span>{" "}
                          <a
                            href={`mailto:${inquiry.listing.account.email}`}
                            className="value"
                          >
                            {inquiry.listing.account.email}
                          </a>
                        </div>
                      )}
                      {inquiry.listing.account.profile.phone && (
                        <div className="to-phone">
                          <span className="label">Phone:</span>{" "}
                          <a
                            href={`tel:${inquiry.listing.account.profile.phone}`}
                            className="value"
                          >
                            {inquiry.listing.account.profile.phone}
                          </a>
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col
                    xs={12}
                    md={12}
                    lg={{ span: 3, order: 2 }}
                    className="listing-column item-column"
                  >
                    <Row>
                      <Col xs={12} md={6} lg={12} className="listing">
                        <span className="label">Listing:</span>{" "}
                        <Link
                          to={this.listingLink(
                            inquiry.listing.type.value,
                            inquiry.listing.slug
                          )}
                          className="value"
                        >
                          {inquiry.listing.title}
                        </Link>
                      </Col>
                      <Col
                        xs={false}
                        md={6}
                        lg={12}
                        className="time d-none d-md-block"
                      >
                        <span className="value">
                          {moment(inquiry.createdAt).calendar()}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                  <Col
                    xs={12}
                    md={12}
                    lg={{ span: 12, order: 4 }}
                    className="message-column item-column"
                  >
                    <span className="status label">
                      {inquiry.status.title}:
                    </span>{" "}
                    <span className="value">
                      {trimText(inquiry.message, 100)}
                    </span>
                  </Col>
                </div>
              </Row>
            );
          })}
        </div>
      </>
    );
  }
}

InboxTable.propTypes = {
  data: PropTypes.array,
  selected: PropTypes.array,
  onSelect: PropTypes.func,
  onSelectAll: PropTypes.func,
  showPopup: PropTypes.func,
};

export default InboxTable;
