import React from "react";
// import { withRouter } from "react-router-dom";

import Form from "react-bootstrap/Form";

class FilterDropdown extends React.Component {
	constructor(props) {
		super(props);

		this.CurrentValue = this.CurrentValue.bind(this);
	}
	
	CurrentValue() {

	}

	render() {
        const { id, label, children } = this.props;
		return (
			<Form.Group controlId={id} className="filter-dropdown">
				<Form.Label>{label}</Form.Label>
				<Form.Control as="select" onChange={this.props.onChange} name={this.props.name} value={this.props.value}>
					{React.Children.map(children, opt => {
						return React.cloneElement(opt, {
							onChange: this.handleChange
						});
					})}
				</Form.Control>
			</Form.Group>
		);
	}
}

export default FilterDropdown; // withRouter(FilterDropdown);