import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Button from "react-bootstrap/Button";

import Card from "components/shared/planCard";
import Spinner from "components/shared/spinner";

import { getPlans } from "actions/plan/actions";

function UpgradeContent(props) {
  const { getPlans, plans, user, editor, onSubmit } = props;

  const oldPlan = user.plan ? user.plan.slug : "free";
  const oldPlanRank = user.plan ? user.plan.rank : 0;
  const [newPlan, setNewPlan] = useState(oldPlan);

  useEffect(() => {
    getPlans();
  }, [getPlans]);

  const isSuperAdmin = (user) => {
    return (
      user && user.role && user.role.value && user.role.value === "super-admin"
    );
  };

  const content = (plans) => {
    if (plans)
      return (
        <div className="plans plans-form">
          <div className="plans-cards">
            {plans.map((plan, key) => (
              <Card
                key={key}
                handleChange={(e) => {
                  const { value } = e.target;
                  setNewPlan(value);
                }}
                disabled={!isSuperAdmin(editor) && plan.rank < oldPlanRank}
                currentPlan={newPlan}
                type={plan.slug}
                mostPopular={plan.slug === "gold"}
                title={plan.title}
                price={plan.price}
                description={plan.description}
                homepageDisplayCost={plan.homepageDisplayCost}
                featuredListingCost={plan.featuredListingCost}
                sponsoredAdCost={plan.sponsoredAdCost}
                blitzEmailCost={plan.blitzEmailCost}
                socialFacebookCost={plan.socialFacebookCost}
                socialTwitterCost={plan.socialTwitterCost}
                socialInstagramCost={plan.socialInstagramCost}
              />
            ))}
          </div>
          {!newPlan || newPlan === oldPlan ? (
            <p className="selected-plan">
              <span>You’re currently on a </span>
              <span className="selected-plan-item">{oldPlan || "Free"}</span>
              <span> subscription plan.</span>
            </p>
          ) : (
            <p className="selected-plan">
              <span>You’ve selected a </span>
              <span className="selected-plan-item">{newPlan || "Unknown"}</span>
              <span> subscription plan to upgrade to.</span>
            </p>
          )}

          <p></p>
          <Button
            disabled={oldPlan === newPlan}
            onClick={() => {
              onSubmit(newPlan);
            }}
          >
            Upgrade
          </Button>
        </div>
      );

    return <Spinner />;
  };

  return content(plans);
}

const keyStateToProps = (state) => {
  return {
    plans: state.plans,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    getPlans: () => dispatch(getPlans()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(UpgradeContent);
