import React from "react";
import Routes, { createTitle } from "routes";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ReactComponent as AirplaneGrounded } from "images/icons/airplane-grounded.svg";

export default class InternalServerPage extends React.Component {
  componentDidMount() {
    document.title = createTitle("500 Internal Server Error", Routes);
    document.getElementById("body").className =
      "internal internal-page 500 500-page error-page";
  }
  componentWillUnmount() {
    document.getElementById("body").className = "";
  }

  render() {
    return (
      <section
        id="app-page-internal"
        className="error-page-content app-internal internal-page d-flex justify-content-center"
      >
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={12} md={10} lg={9} xl={8}>
              <header className="internal-page-hader page-header">
                <h1 className="page-title">
                  Oops! <strong>Unknown Operation.</strong>
                </h1>
                <div className="sub-header">
                  <strong>
                    The action you are attempting to take resulted in a Internal Server Error.
                  </strong>
                </div>
              </header>
              <AirplaneGrounded />
              <p className="page-content">
                If you feel you've reached this page in error, please contact
                your company administrator or{" "}
                <a href="mailto:sales@planemover.com?subject=Bad Request Error">
                  sales@planemover.com
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
