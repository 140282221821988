import React, { useState, useEffect } from "react";
import axios from "axios";

import { Row, Col, Form } from "react-bootstrap";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";

import Tab from "react-bootstrap/Tab";

import SingleImageBrickUpload from "components/backend/shared/singleImageBrickUpload";
import PromoteCalendar from "components/backend/promotions/shared/calendar";
import TextareaAutosize from "react-textarea-autosize";
const SponsoredTab = (props) => {
  const [titlePlaceholder] = useState(props.promotion.title);
  const [subtitlePlaceholder] = useState(props.promotion.subtitle);

  const [listingType, setListingType] = useState(undefined);
  const [listingTypes, setListingTypes] = useState([]);
  const [serviceCategory, setServiceCategory] = useState(undefined);
  const [serviceCategories, setServiceCategories] = useState([]);
  const [aircraftType, setAircraftType] = useState(undefined);
  const [aircraftTypes, setAircraftTypes] = useState([]);
  const [aircraftSize, setAircraftSize] = useState(undefined);
  const [aircraftSizes, setAircraftSizes] = useState([]);

  useEffect(() => {
    axios
      .get(
        "/masterData?keys=listingType,aircraftType,aircraftSize,serviceCategory"
      )
      .then((response) => {
        const masterData = response.data.rows;
        setListingTypes(
          masterData.filter((data) => data.key === "listingType")
        );
        setServiceCategories(
          masterData.filter((data) => data.key === "serviceCategory")
        );
        setAircraftTypes(
          masterData.filter((data) => data.key === "aircraftType")
        );
        setAircraftSizes(
          masterData.filter((data) => data.key === "aircraftSize")
        );
      });
  }, []);

  const handleChange = (evt) => {
    sendChange(evt.target.name, evt.target.value);
  };

  const sendChange = (name, value) => {
    if (props.onChange) {
      props.onChange(name, value);
    }
  };

  const categoryDropdowns = () => {
    switch (listingType) {
      case "service":
        return serviceDropdowns();
      case "sale":
      case "wanted":
        return aircraftDropdowns();
      default:
        return <></>;
    }
  };

  const aircraftDropdowns = () => {
    return (
      <>
        <div className={"d-flex mr-3 mb-2"}>
          <h6 className=" mr-2 text-nowrap mb-0">
            <label>Type:</label>
          </h6>
          <select
            className="bg-transparent"
            onChange={(evt) => {
              setAircraftType(evt.target.value);
              sendChange("aircraftType", evt.target.value);
            }}
            value={aircraftType || ""}
          >
            <option></option>
            {aircraftTypes.map((t) => (
              <option key={`aircraft-type-dropdown-${t.value}`} value={t.value}>
                {t.title}
              </option>
            ))}
          </select>
        </div>

        {aircraftType && aircraftType === "jet" && (
          <div className={"d-flex mb-2"}>
            <h6 className="mr-2 text-nowrap mb-0">
              <label>Size:</label>
            </h6>
            <select
              className="bg-transparent"
              onChange={(evt) => {
                setAircraftSize(evt.target.value);
                sendChange("aircraftSize", evt.target.value);
              }}
              value={aircraftSize || ""}
            >
              <option></option>
              {aircraftSizes.map((s) => (
                <option
                  key={`aircraft-size-dropdown-${s.value}`}
                  value={s.value}
                >
                  {s.title}
                </option>
              ))}
            </select>
          </div>
        )}
      </>
    );
  };

  const serviceDropdowns = () => {
    return (
      <>
        <div className={"d-flex mr-3 mb-2"}>
          <h6 className=" mr-2 text-nowrap mb-0">
            <label>Category:</label>
          </h6>
          <select
            className="bg-transparent"
            onChange={(evt) => {
              setServiceCategory(evt.target.value);
              sendChange("serviceCategory", evt.target.value);
            }}
            value={serviceCategory || ""}
          >
            <option></option>
            {serviceCategories.map((c) => (
              <option
                key={`service-category-dropdown-${c.value}`}
                value={c.value}
              >
                {c.title}
              </option>
            ))}
          </select>
        </div>
      </>
    );
  };

  return (
    <Tab.Pane eventKey="#sponsored">
      {!props.active && <div className="form-overlay"></div>}
      <div className="form-content feature-detail py-2 px-4">
        <Row className={"mt-2"}>
          <Col xs={12}>
            <h6>1. Select which category you'd like to display in:</h6>

            <div className="text-uppercase mobile-block my-3 align-items-center ">
              <h6 md={12} className="w-auto mr-2 text-nowrap mb-0">
                <label>Listing Type:</label>
              </h6>
              <div className={"w-100"}>
                <ToggleButtonGroup
                  type="radio"
                  name="options"
                  onChange={(value) => {
                    setListingType(value);
                    sendChange("listingType", value);
                  }}
                >
                  {listingTypes.map((lt) => (
                    <ToggleButton
                      key={`toggle-listing-type-${lt.value}`}
                      value={lt.value}
                      variant="outline-secondary"
                      className={"toggle-btn"}
                    >
                      {lt.title}
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>
            </div>
            <div className="text-uppercase mobile-block mt-2 align-items-center">
              {categoryDropdowns()}
            </div>
          </Col>
        </Row>
        <Row className={"mt-4 pt-2"}>
          <Col xs={12}>
            <h6>
              2. Attach a photo <label>JPEG OR PNG</label>
            </h6>
            <SingleImageBrickUpload
              image={
                props.promotion.image ? props.promotion.image.url : undefined
              }
              onAdd={(image) => {
                if (props.onChange) {
                  props.onChange("image", image);
                }
              }}
              onDelete={() => {
                if (props.onChange) {
                  props.onChange("image", undefined);
                }
              }}
            />
          </Col>
        </Row>
        <Row className={"mt-4 pt-2"}>
          <Col xs={12} className={"my-2 pr-0"}>
            <h6>3. Please define the title and subtitle of your listing.</h6>
            <div className={"d-flex align-items-center"}>
              <h6 className="w-auto mr-2">Title:</h6>
              <Form.Control
                as="input"
                name="title"
                value={props.promotion.title || ""}
                placeholder={titlePlaceholder}
                disabled={!props.active}
                onChange={handleChange}
              />
            </div>
          </Col>
          <Col xs={12} className={"my-2 pr-0"}>
            <div className={"d-flex align-items-center"}>
              <h6 className="w-auto mr-2">Subtitle:</h6>
              <Form.Control
                as="input"
                name="subtitle"
                value={props.promotion.subtitle || ""}
                placeholder={subtitlePlaceholder}
                disabled={!props.active}
                onChange={handleChange}
              />
            </div>
          </Col>
        </Row>
        <Row className={"mt-4 pt-4"}>
          <Col xs={12}>
            <div className={"pb-3"}>
              <h6>4. Please select desired promotion week.</h6>

              <div className="text-uppercase my-3">
                No dates are guaranteed and all are subject to review, approval,
                and availability of Plane Mover. A Plane Mover Representative
                will contact you with confirmation.
              </div>
            </div>
          </Col>
          <Col className={"Calendar-div"} xs={12}>
            <PromoteCalendar
              onChange={(date) => {
                if (props.onChange) {
                  props.onChange("startDate", date);
                }
              }}
            />
          </Col>
        </Row>
        <Row className={"mt-4 pt-4"}>
          <Col xs={12}>
            <h6>5. Comments:</h6>
            <TextareaAutosize
              className={"w-100 mt-3"}
              rows="5"
              name="comments"
              onChange={handleChange}
            />
          </Col>
        </Row>
      </div>
    </Tab.Pane>
  );
};

export default SponsoredTab;
