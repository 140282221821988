import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { Formik } from "formik";
import { object, string } from "yup";

import { Form, Alert } from "react-bootstrap";
import Spinner from "components/shared/spinner";

import Routes from "routes";
import { parseAxiosError } from "utils/errors";

import { updateSubscriptions } from "actions/email/actions";

import { ReactComponent as CloseIcon } from "images/icons/close-circle.svg";
import { closeNewsletterModal, openSignup } from "actions/modal/actions";

const NewsletterModal = (props) => {
  const { show, handleClose, updateSubscriptions, openSignup } = props;

  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  const handleSubmit = ({ email }, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    updateSubscriptions(email)
      .then((res) => {
        setSuccess(true);
      })
      .catch((err) => {
        setError(parseAxiosError(err));
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const formContent = () => {
    return (
      <div className="form-content">
        <h2>
          Love Aircraft? <strong>Sign Up For Our Email Blitzes</strong>
        </h2>
        <div className="message">
          Receive the latest aircraft and aviation services broadcasts straight
          to your inbox!
        </div>
        <div className="actions">
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={object().shape({
              email: string()
                .required("Email is required")
                .email("Invalid email"),
            })}
            onSubmit={handleSubmit}
          >
            {({
              values,
              touched,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <Form
                onSubmit={
                  isSubmitting
                    ? (e) => {
                        e.preventDefault();
                      }
                    : handleSubmit
                }
                className="newsletter-form"
              >
                {isSubmitting && <Spinner />}
                {error && <Alert variant="danger">{error}</Alert>}
                <Form.Group>
                  <Form.Label className="email-label">Email Address</Form.Label>
                  <Form.Control
                    className="account-form-input"
                    type="text"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    placeholder="Johndoe@email.com"
                  />
                  {touched.email && errors.email && (
                    <Form.Text className="account-form-error">
                      {errors.email}
                    </Form.Text>
                  )}
                </Form.Group>
                <div className="newsletter-form-save">
                  <button
                    type="submit"
                    className="btn btn-tertiary newsletter-form-save-button"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <footer className="fineprint">
            By clicking submit you agree to our{" "}
            <Link to={Routes.terms}>Terms &amp; Conditions</Link> and{" "}
            <Link to={Routes.privacy}>Privacy Policy</Link>.
          </footer>
        </div>
      </div>
    );
  };
  const successContent = () => {
    return (
      <div className="success-content">
        <h2>
          Thanks For <strong>Signing Up For Our Email Blitzes</strong>
        </h2>
        <div className="message mt-4">
          Now get access to saved searches, notifications, favorites, and more
          with a website account!
        </div>
        <div className="actions mt-4">
          <button className="btn btn-primary" onClick={openSignup}>
            CREATE AN ACCOUNT
          </button>
        </div>
      </div>
    );
  };

  const content = () => {
    if (success) return successContent();
    return formContent();
  };

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      <div className="newsletter-modal">
        <button className="btn close" onClick={handleClose}>
          <CloseIcon />
        </button>
        <div className="content">{content()}</div>
      </div>
    </Modal>
  );
};

const keyStateToProps = (state) => {
  return {
    show: state.modal.newsletterModal,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    handleClose: () => dispatch(closeNewsletterModal()),
    openSignup: () => dispatch(openSignup()),
    updateSubscriptions: (email, slugs) =>
      dispatch(updateSubscriptions(email, slugs)),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(NewsletterModal);
