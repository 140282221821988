/* eslint-disable no-template-curly-in-string */
import React from "react";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";

import Routes, { createTitle } from "routes";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import Spinner from "components/shared/spinner";

import Tab from "react-bootstrap/Tab";
import ListGroup from "react-bootstrap/ListGroup";

import PromoteHeader from "./header";
import HomepageTab from "components/backend/promotions/homepage/add";
import FeaturedTab from "components/backend/promotions/featured/add";
import SponsoredTab from "components/backend/promotions/sponsored/add";
import BlitzTab from "components/backend/promotions/blitz/add";
import SocialTab from "components/backend/promotions/social/add";

const listGroupItem = [
  {
    id: "homepage",
    title: "Feature on the homepage",
    smallDetail: "$${price} per week - ${subscription} subscription",
    content:
      "Feature your listing on our homepage. Space is limited, so request your\n" +
      "spot today. Week long display runs Monday 12:00 am through Sunday 11:59\n" +
      "pm EST.",
  },
  {
    id: "featured",
    title: "Feature your listing in search results",
    smallDetail: "$${price} per week - ${subscription} subscription",
    content:
      "Featured listings appear first in search results. Space is limited, so\n" +
      "request your spot today. Week long display runs Monday 12:00 am through\n" +
      "Sunday 11:59 pm EST.",
  },
  {
    id: "sponsored",
    title: "Sponsor in another listing type",
    smallDetail: "$${price} per week - ${subscription} subscription",
    content:
      "Your listing will appear in another listing type search results. Space\n" +
      "is limited, so request your spot today. Week long display runs Monday\n" +
      "12:00 am through Sunday 11:59 pm EST.",
  },
  {
    id: "blitz",
    title: "Buy a Blitz",
    smallDetail: "$${price} per blitz - ${subscription} subscription",
    content:
      " Send an email to our carefully curated Plane Mover database. Our Blitzes\n" +
      "are customized emails, featuring just your listing. Space is limited, so\n" +
      "request your spot today.",
  },
  {
    id: "social",
    title: "Promote on Social Media",
    smallDetail: "$${price} per post (FB/IG/TW) - ${subscription} subscription",
    content:
      "We'll post your listing to our social media channels: Facebook,\n" +
      "Instagram and Twitter. A customized post, featuring just your listing or\n" +
      "service. Space is limited, so request your spot today.",
  },
];

class PromoteNewPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...this.getInitialState(),
      plans: undefined,
      isLoading: true,
    };
  }

  getInitialState = () => {
    return {
      error: undefined,
      company: undefined,
      rep: undefined,
      listingType: undefined,
      listing: undefined,
      activeTab: "#homepage",
      homepage: {},
      homepageActive: false,
      featured: {},
      featuredActive: false,
      sponsored: {},
      sponsoredActive: false,
      blitz: {},
      blitzActive: false,
      social: {},
      socialActive: false,
      submissionError: false,
      submitted: false,
    };
  };

  resetInitialState = () => {
    //this.setState(this.getInitialState());
    window.location.reload();
  };

  componentDidMount = () => {
    document.title = createTitle("Promote Listings", Routes);

    // Create array of promises to run prior to displaying the form
    const proms = [
      axios.get("/plans").then((response) => {
        this.setState({ plans: response.data.data });
      }),
      axios.get("/masterData?keys=listingType").then((response) => {
        const masterData = response.data.rows;
        this.setState({
          listingTypes: masterData.filter((data) => data.key === "listingType"),
        });
      }),
    ];

    const { userInfo } = this.props;
    if (userInfo && userInfo.role && userInfo.role.value) {
      if (userInfo.role.value === "super-admin") {
        // Get all companies
        proms.push(
          axios.get("/company?limit=-1").then((response) => {
            this.setState({ companies: response.data.data.rows });
          }),
          axios.get("/accounts?company=null&limit=-1").then((response) => {
            this.setState({ reps: response.data.data.rows });
          })
        );
      } else if (userInfo.company) {
        proms.push(
          axios.get(`/company/${userInfo.company.slug}`).then((response) => {
            const company = response.data.data;
            this.setState({ company }, () => {
              if (userInfo.role.value === "sales-rep") {
                this.setState({
                  rep: company.accounts
                    .filter((a) => a.email === userInfo.email)
                    .shift(),
                });
              }
            });
          })
        );
      } else {
        this.setState({
          rep: userInfo,
        });
      }
    }

    Promise.all(proms)
      .then(() => {
        // Finished Loading
        this.setState({ isLoading: false, error: undefined });
      })
      .catch((err) => {
        this.setState({ isLoading: false, error: err });
      });
  };

  generateSubtitle = (listing) => {
    if (listing.highlights && listing.highlights.length > 0) {
      return listing.highlights.map((h) => h.name).join(", ");
    }
    return "A List of Highlights";
  };

  handleHeaderChange = (name, value) => {
    switch (name) {
      case "company":
        this.setState({ company: undefined, rep: undefined }, () => {
          // Fetch specific Make
          axios.get(`/company/${value}`).then((response) => {
            this.setState({ company: response.data.data }, () => {
              this.fetchListings();
            });
          });
        });
        break;
      case "account":
        if (this.state.company) {
          this.setState(
            {
              rep: this.state.company.accounts
                .filter((a) => a.email === value)
                .shift(),
            },
            () => {
              this.fetchListings();
            }
          );
        } else {
          this.setState(
            {
              rep: this.state.reps.filter((a) => a.email === value).shift(),
            },
            () => {
              this.fetchListings();
            }
          );
        }

        break;
      case "listingType":
        //TODO: Make COmpany and Rep querystrings dynamic based on role?
        this.setState(
          {
            listingType: value,
          },
          () => {
            this.fetchListings();
          }
        );

        break;
      case "listing":
        this.setState({ listing: undefined }, () => {
          axios.get(`/listings/slug/${value}`).then((response) => {
            const listing = response.data.data;

            // Image & Images
            let image = null;
            let images3 = [];
            let images5 = [];
            if (listing.images && listing.images.length > 0) {
              image = listing.images.slice(0, 1).shift();
              images3 = listing.images.slice(0, 3);
              images5 = listing.images.slice(0, 5);
            }

            this.setState({
              listing,
              homepage: {
                title: listing.title,
                subtitle: this.generateSubtitle(listing),
                image,
              },
              sponsored: {
                title: listing.title,
                subtitle: this.generateSubtitle(listing),
                image,
              },
              blitz: {
                highlights: this.generateSubtitle(listing),
                images: images5,
              },
              social: {
                highlights: this.generateSubtitle(listing),
                images: images3,
              },
            });
          });
        });

        break;
      default:
        this.setState({
          [name]: value,
        });
        break;
    }
  };

  fetchListings = () => {
    const { listingType, company, rep } = this.state;
    this.setState({ listings: [], listing: undefined }, () => {
      if (listingType) {
        let params = {
          limit: -1,
          status: "published",
        };
        if (company && company.slug)
          params = { ...params, company: company.slug };
        if (rep && rep.email) params = { ...params, rep: rep.email };
        axios
          .get(`/manage/type/${listingType}`, { params })
          .then((response) => {
            this.setState({
              listings: response.data.data.rows,
            });
          });
      }
    });
  };

  handleCompanySelection = (e) => {
    const companySlug = e.target.value;
    this.props.onChange("company", companySlug);

    this.setState({ company: undefined, rep: undefined }, () => {
      // Fetch specific Make
      axios.get(`/company/${companySlug}`).then((response) => {
        this.setState({ company: response.data.data });
      });
    });
  };

  handlePromotionChange = (promo, name, value) => {
    this.setState({ [promo]: { ...this.state[promo], [name]: value } });
  };

  handleImagesChange = (name, newImages, oldImages) => {
    const mergedImages = newImages.map((i) => {
      if (i.file) return i;
      const oldImg = oldImages.filter((o) => o.url === i.preview).shift();
      return oldImg;
    });
    this.handlePromotionChange(name, "images", mergedImages);
  };

  validatePromotions = () => {
    const { homepage, homepageActive } = this.state;
    const { featured, featuredActive } = this.state;
    const { sponsored, sponsoredActive } = this.state;
    const { blitz, blitzActive } = this.state;
    const { social, socialActive } = this.state;

    const active =
      homepageActive ||
      featuredActive ||
      sponsoredActive ||
      blitzActive ||
      socialActive;

    const errors = [];
    if (active) {
      // Homepage
      if (homepageActive) {
        if (!homepage.startDate)
          errors.push("Featured on Homepage: Please select a week");
      }

      // Featured
      if (featuredActive) {
        if (!featured.startDate)
          errors.push("Featured Listing in Search: Please select a week");
      }

      // Sponsored
      if (sponsoredActive) {
        if (!sponsored.startDate)
          errors.push("Sponsor in Listing Type: Please select a week");
        if (!sponsored.listingType) {
          errors.push("Sponsor in Listing Type: Please select a Listing Type");
        } else {
          if (
            sponsored.listingType === "sale" ||
            sponsored.listingType === "wanted"
          ) {
            if (!sponsored.aircraftType)
              errors.push(
                "Sponsor in Listing Type: Please select an Aircraft Type"
              );
          } else if (sponsored.listingType === "service") {
            if (!sponsored.serviceCategory)
              errors.push(
                "Sponsor in Listing Type: Please select a Service Category"
              );
          }
        }
      }

      // Blitz
      if (blitzActive) {
        if (!blitz.startDate) errors.push("Blitz: Please select a week");
      }

      // Social
      if (socialActive) {
        if (!social.startDate)
          errors.push("Social Media: Please select a week");
        if (!social.social || social.social.length < 1)
          errors.push("Social Media: Please select at least 1 social channel");
      }
    }

    if (errors && errors.length > 0) {
      return errors;
    }

    return active;
  };

  savePromotions = (e) => {
    e.preventDefault();

    const {
      homepageActive,
      featuredActive,
      sponsoredActive,
      blitzActive,
      socialActive,
      social,
    } = this.state;

    const submitAllowed = this.validatePromotions();
    if (submitAllowed || submitAllowed.length === 0) {
      // Set to Loading State and Submit
      // TODO: wrap in setState Loading before redirecting
      const proms = [];

      if (homepageActive) proms.push(this.saveHomepagePromotion());
      if (featuredActive) proms.push(this.saveFeaturedPromotion());
      if (sponsoredActive) proms.push(this.saveSponsoredPromotion());
      if (blitzActive) proms.push(this.saveBlitzPromotion());
      if (socialActive) {
        social.social.forEach((s) => {
          proms.push(this.saveSocialPromotion(s));
        });
      }

      try {
        axios
          .all(proms)
          .then((submitted) => {
            // Finished Loading
            this.setState({ isLoading: false, error: undefined, submitted });
          })
          .catch((err) => {
            this.setState({ isLoading: false, error: err });
          });
      } catch (err) {
        this.setState({ isLoading: false, error: err });
      }
    }
  };

  generateGlobalPayload = (payload) => {
    const { userInfo } = this.props;
    const { company, rep, listing } = this.state;
    if (userInfo && userInfo.role && userInfo.role.value) {
      if (userInfo.role.value === "super-admin") {
        payload.company = company ? company.slug : null;
        payload.rep = rep.email;
      }
      if (userInfo.role.value === "company-admin") {
        payload.rep = rep.email;
      }
    }

    payload.listing = listing.slug;

    return payload;
  };

  saveHomepagePromotion = () => {
    const { homepage } = this.state;
    const payload = this.generateGlobalPayload(homepage);

    return axios.post(`/promotion/type/homepage`, payload).then((response) => {
      const promotion = response.data.data;

      // Check for Image and Upload
      const { image } = homepage;

      if (image) {
        // We have an image - either existing, or new
        if (image.file) {
          // This is a new image. Upload it.
          let formdata = new FormData();
          formdata.append("image", image.file);

          return axios
            .post(`/promotion/${promotion.id}/image`, formdata, {
              headers: {
                "content-type": "multipart/form-data",
              },
            })
            .then((image) => {
              return { homepage: promotion };
            });
        } else if (image.uid) {
          // This is an existing image. Attach it.
          return axios
            .put(`/promotion/${promotion.id}/image/${image.uid}`, { order: 0 })
            .then((image) => {
              return { homepage: promotion };
            });
        }
      }

      return { homepage: promotion };
    });
  };

  saveFeaturedPromotion = () => {
    const { featured } = this.state;
    const payload = this.generateGlobalPayload(featured);

    return axios.post(`/promotion/type/featured`, payload).then((response) => {
      return { featured: response.data.data };
    });
  };

  saveSponsoredPromotion = () => {
    const { sponsored } = this.state;

    const body = {
      title: sponsored.title,
      subtitle: sponsored.subtitle,
      content: sponsored.content,
      comments: sponsored.comments,
      startDate: sponsored.startDate,
      categoryType: sponsored.listingType,
    };

    switch (sponsored.listingType) {
      case "service":
        body.categoryKey = "serviceCategory";
        body.categoryValue = sponsored.serviceCategory;
        break;
      case "sale":
      case "wanted":
      default:
        body.categoryKey = "aircraftType";
        body.categoryValue = sponsored.aircraftType;
        if (sponsored.aircraftType === "jet") {
          body.subcategoryKey = "aircraftSize";
          body.subcategoryValue = sponsored.aircraftSize;
        }
        break;
    }

    const payload = this.generateGlobalPayload(body);

    return axios.post(`/promotion/type/sponsored`, payload).then((response) => {
      const promotion = response.data.data;

      // Check for Image and Upload
      const { image } = sponsored;

      if (image) {
        // We have an image - either existing, or new
        if (image.file) {
          // This is a new image. Upload it.
          let formdata = new FormData();
          formdata.append("image", image.file);

          return axios
            .post(`/promotion/${promotion.id}/image`, formdata, {
              headers: {
                "content-type": "multipart/form-data",
              },
            })
            .then((image) => {
              return { sponsored: promotion };
            });
        } else if (image.uid) {
          // This is an existing image. Attach it.
          return axios
            .put(`/promotion/${promotion.id}/image/${image.uid}`, { order: 0 })
            .then((image) => {
              return { sponsored: promotion };
            });
        }
      }

      return { sponsored: promotion };
    });
  };

  saveBlitzPromotion = () => {
    const { blitz } = this.state;
    const payload = this.generateGlobalPayload(blitz);

    return axios.post(`/promotion/type/blitz`, payload).then((response) => {
      const promotion = response.data.data;

      // Check for Image and Upload
      console.log(blitz);
      const { images } = blitz;
      console.log(images);

      if (images && images.length > 0) {
        // We have a images - either existing, or new. Map them into Promises
        const imageUploads = images.map((image, order) => {
          console.log(`Uploading Blitz Image:`);
          console.log(image);
          console.log(image.file);
          console.log(image.uid);
          if (image.file) {
            // This is a new image. Upload it.
            let formdata = new FormData();
            formdata.append("image", image.file);
            formdata.append("order", order);

            return axios.post(`/promotion/${promotion.id}/image`, formdata, {
              headers: {
                "content-type": "multipart/form-data",
              },
            });
          } else if (image.uid) {
            // This is an existing image. Attach it.
            return axios.put(`/promotion/${promotion.id}/image/${image.uid}`, {
              order,
            });
          }
          throw new Error("Error uploading images");
        });

        return Promise.all(imageUploads).then((res) => {
          return { blitz: promotion };
        });
      }

      return { blitz: promotion };
    });
  };

  saveSocialPromotion = (s) => {
    const { social } = this.state;

    const payload = this.generateGlobalPayload({
      content: social.content,
      comments: social.comments,
      startDate: social.startDate,
    });

    return axios
      .post(`/promotion/type/social-${s}`, payload)
      .then((response) => {
        const promotion = response.data.data;

        // Check for Image and Upload

        console.log(social);
        const { images } = social;
        console.log(images);

        if (images && images.length > 0) {
          // We have a images - either existing, or new. Map them into Promises
          const imageUploads = images.map((image, order) => {
            console.log(`Uploading Social Image:`);
            console.log(image);
            console.log(image.file);
            console.log(image.uid);
            if (image.file) {
              // This is a new image. Upload it.
              let formdata = new FormData();
              formdata.append("image", image.file);
              formdata.append("order", order);

              return axios.post(`/promotion/${promotion.id}/image`, formdata, {
                headers: {
                  "content-type": "multipart/form-data",
                },
              });
            } else if (image.uid) {
              // This is an existing image. Attach it.
              return axios.put(
                `/promotion/${promotion.id}/image/${image.uid}`,
                {
                  order,
                }
              );
            }
            throw new Error("Error uploading images");
          });

          return Promise.all(imageUploads).then((res) => {
            return { [`social-${s}`]: promotion };
          });
        }

        return { [`social-${s}`]: promotion };
      });
  };

  SubmissionResults = () => {
    const title = (slug) => {
      const social = listGroupItem
        .filter((i) => i.id === "social")
        .shift().title;
      switch (slug) {
        case "social-facebook":
          return `${social} (Facebook)`;
        case "social-instagram":
          return `${social} (Instagram)`;
        case "social-twitter":
          return `${social} (Twitter)`;
        default:
          return listGroupItem.filter((i) => i.id === slug).shift().title;
      }
    };

    const dates = (promo) => {
      const start = moment(promo.startDate).format("MMMM Do");
      const end = moment(promo.endDate).format("MMMM Do, YYYY");
      return `${start} to ${end}`;
    };

    return (
      <section
        id="app-page-home"
        className="app-content app-main submitted-listing"
      >
        <div className="container">
          <div className="row">
            <div className="col">
              <div className={"title mb-2 submitted-title"}>
                Promote<strong> Listings</strong>
              </div>
              <div className={"submited-details"}>
                This promotion request has been submitted. See details below.
              </div>

              <div className="container bg-light p-4 mt-5 w-75 mx-0 submitted-container">
                <div className={"mb-3 submitted-content"}>
                  <h6>
                    REQUESTED:{" "}
                    {
                      this.state.listingTypes
                        .filter((t) => t.value === this.state.listingType)
                        .shift().title
                    }{" "}
                    -{" "}
                    <span className="text-green">
                      {this.state.listing.title}
                    </span>
                  </h6>
                </div>
                <div className={"submitted-content"}>
                  <div className={"mb-1"}>Items selected:</div>
                  <ol>
                    {this.state.submitted.map((r, i) => {
                      return (
                        <li key={`submitted-${i}`} className="mb-4">
                          <span className="promotion-title">
                            {title(Object.keys(r).shift())}
                          </span>{" "}
                          -{" "}
                          <span className="promotion-dates">
                            {dates(r[Object.keys(r).shift()])}
                          </span>
                        </li>
                      );
                    })}
                  </ol>
                </div>
              </div>
            </div>
          </div>

          <div className={"mt-5 submit-button-group"}>
            <button
              className={"btn btn-red btn-primary mr-4"}
              onClick={this.resetInitialState}
            >
              NEW PROMOTION
            </button>
            {/* <Button
              className={"submit-btn-gray "}
              onClick={() => this.goToSchedule()}
            >
              GO TO SCHEDULE
            </Button> */}
          </div>
        </div>
      </section>
    );
  };

  getPlan = (company, account) => {
    if (company) return company.plan;
    return account.plan;
  };

  SmallDetail = (item) => {
    const { company, rep } = this.state;
    const plan = this.getPlan(company, rep);
    let detail = item.smallDetail;
    if (plan) {
      const subscription = plan.title;
      let price;
      switch (item.id) {
        case "homepage":
          price = plan.homepageDisplayCost.replace(".00", "");
          break;
        case "featured":
          price = plan.featuredListingCost.replace(".00", "");
          break;
        case "sponsored":
          price = plan.sponsoredAdCost.replace(".00", "");
          break;
        case "blitz":
          price = plan.blitzEmailCost.replace(".00", "");
          break;
        case "social":
          const fbCost = plan.socialFacebookCost.replace(".00", "");
          const igCost = plan.socialInstagramCost.replace(".00", "");
          const twCost = plan.socialTwitterCost.replace(".00", "");
          price = `${fbCost}/${igCost}/${twCost}`;
          break;
        default:
          break;
      }
      detail = detail
        .replace("${price}", price)
        .replace("${subscription}", subscription);
    }
    return detail;
  };

  PromotionTab = (item, key) => {
    return (
      <>
        <Form.Control
          type="checkbox"
          className="checkbox"
          checked={this.state[item.id + `Active`]}
          onChange={(e) => {
            const activeTab = this.state.activeTab;
            const thisTab = `#${item.id}`;
            const checked = e.target.checked;
            if (activeTab === thisTab) {
              if (checked) {
                this.setState({ [item.id + `Active`]: true });
              } else {
                this.setState({ [item.id + `Active`]: false });
              }
            }
          }}
        />

        <div className="list-item-text">
          <div className="promote-head-text">
            <div className="strong">{key + 1 + ". " + item.title}</div>{" "}
            &nbsp;&nbsp;&nbsp;
            <small className="text-uppercase">{this.SmallDetail(item)}</small>
          </div>
          {item.content}
        </div>
      </>
    );
  };

  PromotionContent = (id) => {
    switch (id) {
      case "homepage":
        return (
          <HomepageTab
            active={this.state.homepageActive}
            listing={this.state.listing}
            promotion={this.state.homepage}
            onChange={(name, value) => {
              this.handlePromotionChange("homepage", name, value);
            }}
          />
        );
      case "featured":
        return (
          <FeaturedTab
            active={this.state.featuredActive}
            listing={this.state.listing}
            promotion={this.state.featured}
            onChange={(name, value) => {
              this.handlePromotionChange("featured", name, value);
            }}
          />
        );
      case "sponsored":
        return (
          <SponsoredTab
            active={this.state.sponsoredActive}
            listing={this.state.listing}
            promotion={this.state.sponsored}
            onChange={(name, value) => {
              this.handlePromotionChange("sponsored", name, value);
            }}
          />
        );
      case "blitz":
        return (
          <BlitzTab
            active={this.state.blitzActive}
            listing={this.state.listing}
            promotion={this.state.blitz}
            onChange={(name, value) => {
              this.handlePromotionChange("blitz", name, value);
            }}
            onImagesChange={(newImages) => {
              this.handleImagesChange(
                "blitz",
                newImages,
                this.state.blitz.images
              );
            }}
          />
        );
      case "social":
        return (
          <SocialTab
            active={this.state.socialActive}
            listing={this.state.listing}
            promotion={this.state.social}
            onChange={(name, value) => {
              this.handlePromotionChange("social", name, value);
            }}
            onImagesChange={(newImages) => {
              this.handleImagesChange(
                "social",
                newImages,
                this.state.social.images
              );
            }}
          />
        );
      default:
        return <></>;
    }
  };

  PromotionTabs = () => {
    const { activeTab } = this.state;

    return (
      <Tab.Container id="list-group-tabs-example " defaultActiveKey={activeTab}>
        <Row>
          <Col md={6} lg={6} xl={6} className="px-0 tab-selectors">
            <ListGroup>
              {listGroupItem.map((item, i) => {
                return (
                  <ListGroup.Item
                    key={`tab-${i}`}
                    action
                    href={"#" + item.id}
                    className="d-flex"
                    onClick={() => {
                      this.setState({ activeTab: `#${item.id}` });
                    }}
                  >
                    {this.PromotionTab(item, i)}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Col>
          <Col
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            className="px-0"
            id="tab-content"
          >
            <Tab.Content>
              <ListGroup>
                {listGroupItem.map((item, key) => {
                  return (
                    <>
                      <ListGroup.Item
                        key={`tab-content-${key}`}
                        action
                        href={"#" + item.id}
                        className="d-flex"
                        onClick={() => {
                          this.setState({ activeTab: `#${item.id}` });
                        }}
                      >
                        {this.PromotionTab(item, key)}
                      </ListGroup.Item>
                      {this.PromotionContent(item.id)}
                    </>
                  );
                })}
              </ListGroup>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    );
  };

  PromotionForms() {
    const formErrors = this.validatePromotions();

    return (
      <Container>
        <PromoteHeader
          onChange={this.handleHeaderChange}
          companies={this.state.companies}
          company={this.state.company}
          reps={this.state.reps}
          rep={this.state.rep}
          listingTypes={this.state.listingTypes}
          listingType={this.state.listingType}
          listings={this.state.listings}
          listing={this.state.listing}
        />
        <div className="promote-new-content">
          {this.state.listing && (
            <>
              <div className="disclaimer">
                <h4>
                  Please select all the ways you would like to promote your
                  listing.
                </h4>
                <p>
                  Please note that this request will be sent to Plane Mover for
                  review and processing. All requests are based on availability.
                  You'll receive an email confirmation of your request upon
                  completion and a follow up email from a member of our team.
                </p>
              </div>

              {this.PromotionTabs()}
              {formErrors && formErrors.length > 0 && (
                <div className="errors">
                  <ul>
                    {formErrors.map((e, i) => (
                      <li key={`error-${i}`}>{e}</li>
                    ))}
                  </ul>
                </div>
              )}
              <div className="submit-button">
                <Button
                  disabled={!formErrors || formErrors.length > 0}
                  className="btn-green mt-4 "
                  // onClick={() =>
                  //   this.props.history.push("/admin/submitted-listings")
                  // }
                  onClick={this.savePromotions}
                >
                  SUBMIT ALL PROMOTIONS
                </Button>
              </div>
            </>
          )}
        </div>
      </Container>
    );
  }

  renderContent = () => {
    if (this.state.error) {
      return (
        <div
          id="promotion-error"
          className="stepper-error promotion-error error"
        >
          <h1 className="listings-title">{this.state.error.message}</h1>
          <p>{this.state.error.message}</p>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div
          id="promotion-loading"
          className="stepper-loading promotion-loading loading"
        >
          <Spinner />
        </div>
      );
    } else if (this.state.submitted) {
      return this.SubmissionResults();
    } else {
      return this.PromotionForms();
    }
  };

  render() {
    return (
      <section
        id="app-page-promote-new"
        className="app-content app-main promotionsNewPage"
      >
        {this.renderContent()}
      </section>
    );
  }
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {};
};

export default connect(keyStateToProps, keyDispatchToProps)(PromoteNewPage);
