import React from "react";
import axios from "axios";
import moment from "moment";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Routes, { createTitle } from "routes";

export default class PrivacyPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      results: [],
      error: undefined
    };
  }

  componentDidMount() {
    document.title = createTitle("Privacy Policy", Routes);

    axios.get("/settings/policies/privacy").then(response => {
      this.setState({
        isLoading: false,
        error: undefined,
        results: response.data
      });
    });
  }

  render() {
    const { results } = this.state;
    return (
      <section
        id="app-page-privacy"
        className="app-privacy-page privacy-page content-page"
      >
        <header className="privacy-page-hader page-header">
          <Container
            id="privacy-header-row"
            className="header-row"
            fluid={true}
          >
            <Row>
              <Col className="text-center justify-content-center">
                <h1 className="page-title">
                  Privacy <strong>Policy</strong>
                </h1>
                <div className="sub-header">
                  <strong>Last Updated:</strong>{" "}
                  {results ? moment(results.updatedAt).calendar() : "Unknown"}
                </div>
              </Col>
            </Row>
          </Container>
        </header>

        <Container id="privacy-policy-row">
          <Row>
            <Col className="py-5">
              <div
                dangerouslySetInnerHTML={{
                  __html: results ? results.content : ""
                }}
              />
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
