import React from "react";

import Spinner from "react-bootstrap/Spinner";

export default () => (
  <div className="spinner planemover-spinner">
    <Spinner animation="border" variant="primary" role="status">
      <span className="sr-only">Loading...</span>
    </Spinner>
  </div>
);
