import React, { Component } from "react";
import axios from "axios";
import queryString from "query-string";
import { withRouter } from "react-router-dom";

import { Row, Col, Button } from "react-bootstrap";
import Spinner from "components/shared/spinner";

import { ReactComponent as TrashIcon } from "images/icons/archive.svg";
import { ReactComponent as RestoreIcon } from "images/icons/unarchive.svg";
import { ReactComponent as UnreadIcon } from "images/icons/mail.svg";
import { ReactComponent as ReadIcon } from "images/icons/mail-read.svg";
import { ReactComponent as FlagIcon } from "images/icons/flag.svg";

import Pagination from "components/frontend/listings/_shared/pagination";
import FilterDropdown from "components/frontend/listings/_shared/filterDropdown";

import PopupPreview from "../shared/popup";
import PromotionTable from "../shared/table";

class PromotionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fetchUrl: false,
      isLoading: true,
      results: [],
      error: undefined,
      count: 0,
      pages: 0,
      selected: [],
      popup: false,
      popupLoading: false,
    };

    this.paging = [
      { count: 10, label: "10 per page" },
      { count: 25, label: "25 per page" },
      { count: 50, label: "50 per page" },
      { count: 100, label: "100 per page" },
    ];

    this.sorting = [
      { sort: null, order: null, label: "Default" },
      { sort: "created", order: "DESC", label: "Date Submitted: Newest" },
      { sort: "created", order: "ASC", label: "Date Submitted: Oldest" },
      { sort: "start", order: "DESC", label: "Start Date: Soonest" },
      { sort: "start", order: "ASC", label: "Start Date: Farthest" },
      { sort: "subscription", order: "DESC", label: "Subscription: Highest" },
      { sort: "subscription", order: "ASC", label: "Subscription: Lowest" },
    ];

    this.fetchUrl = this.props.url;
  }
  componentDidMount() {
    axios.get("/masterData?keys=promotionStatus").then((response) => {
      const masterData = response.data.rows;

      const filterStatuses = masterData.map((data) => {
        return {
          value: data.value,
          title: data.title,
        };
      });

      this.setState({
        filterStatuses: [
          ...filterStatuses,
          { value: "active", title: "Active" },
          { value: "inactive", title: "Inactive" },
          { value: "flagged", title: "Flagged" },
          { value: "all", title: "All" },
        ],
      });
    });

    this.fetchResults();
  }

  componentDidUpdate() {
    this.fetchResults();
  }

  getSearchParams() {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });

    const newParams = {};

    // Limit
    if (currentParams["show"]) {
      newParams["limit"] = currentParams["show"] || 10;
    }

    return { ...currentParams, ...newParams };
  }

  parseRequestUrl() {
    const fetchUrl = this.fetchUrl;
    const searchParams = this.getSearchParams();
    // Add Params
    if (searchParams) {
      // We have search params. Parse them!
      const searchParamsUrl = queryString.stringify(searchParams, {
        arrayFormat: "comma",
      });
      return `${fetchUrl}?${searchParamsUrl}`;
    }

    // Return URL
    return fetchUrl;
  }

  handlePageLimitChange = (event) => {
    const target = event.currentTarget;

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, show: target.value };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };
  getPageLimit = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const pageLimit = currentParams["show"] || 10;
    return parseInt(pageLimit);
  };
  handlePageChange = (pager) => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, page: pager.currentPage };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };
  getPage = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const page = currentParams["page"] || 1;
    return parseInt(page);
  };

  handleOrderByChange = (event) => {
    const target = event.currentTarget;

    const sort = this.sorting[target.value];

    if (sort) {
      const currentParams = queryString.parse(this.props.location.search, {
        arrayFormat: "comma",
      });
      const updatedObj = {
        ...currentParams,
        sort: sort.sort,
        order: sort.order,
      };
      const updatedParams = queryString.stringify(updatedObj, {
        arrayFormat: "comma",
      });

      this.props.history.push({
        pathname: this.props.location.pathname,
        search: "?" + new URLSearchParams(updatedParams).toString(),
      });
    }
  };

  getOrderBy = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    if (currentParams["sort"] && currentParams["order"]) {
      for (const [i, v] of this.sorting.entries()) {
        if (
          v.sort === currentParams["sort"] &&
          v.order === currentParams["order"]
        ) {
          return i;
        }
      }
    }
    return 0;
  };

  fetchResults() {
    let fetchUrl = this.parseRequestUrl();
    if (this.state.fetchUrl !== fetchUrl) {
      // Fetch URL has changed. Update state and get new results
      this.setState(
        {
          fetchUrl: fetchUrl,
          isLoading: true,
          results: [],
          selected: [],
          error: undefined,
          count: 0,
          pages: 0,
        },
        () => {
          if (fetchUrl) {
            axios
              .get(fetchUrl)
              .then((response) => {
                const results = response.data.data.rows;

                this.setState(
                  {
                    isLoading: false,
                    error: undefined,
                    results: results,
                    count: response.data.data.count,
                    pages: response.data.pageCount,
                  },
                  () => {
                    this.props.onFetchSuccess &&
                      this.props.onFetchSuccess(this.state);
                  }
                );
              })
              .catch((error) => {
                this.setState(
                  {
                    isLoading: false,
                    results: [],
                    error: error,
                    count: 0,
                    pages: 0,
                  },
                  () => {
                    this.props.onFetchError &&
                      this.props.onFetchError(this.state);
                  }
                );
              });
          }
        }
      );
    }
  }

  handleSelect = (id, selected) => {
    if (selected) {
      if (!this.state.selected.includes(id))
        this.setState({ selected: [...this.state.selected, id] });
    } else {
      this.setState({
        selected: this.state.selected.filter((x) => x !== id),
      });
    }
  };

  handleSelectAll = (selected) => {
    if (selected) {
      this.setState({ selected: this.state.results.map((i) => i.id) });
    } else {
      this.setState({ selected: [] });
    }
  };

  showPopup = (promo) => {
    this.setState(
      {
        popupLoading: true,
      },
      () => {
        axios
          .get(`/promotion/${promo.id}`)
          .then((response) => {
            const promotion = response.data.data;

            this.setState({
              popupLoading: false,
              popup: promotion,
            });
          })
          .catch((error) => {
            this.setState({
              popupLoading: false,
              error: error,
            });
          });
      }
    );
  };
  closePopup = () => {
    this.setState({
      popup: false,
    });
  };

  handleStatusChange = (event) => {
    const target = event.currentTarget;

    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    const updatedObj = { ...currentParams, status: target.value };
    const updatedParams = queryString.stringify(updatedObj, {
      arrayFormat: "comma",
    });

    this.props.history.push({
      pathname: this.props.location.pathname,
      search: "?" + new URLSearchParams(updatedParams).toString(),
    });
  };

  getStatus = () => {
    const currentParams = queryString.parse(this.props.location.search, {
      arrayFormat: "comma",
    });
    return currentParams["status"] || "unread";
  };

  handlePromotionUpdate = (payload, ids) => {
    let useIds = this.state.selected;

    if (ids) {
      if (Array.isArray(ids)) {
        useIds = ids;
      } else {
        useIds = [ids];
      }
    }
    if (useIds && useIds.length > 0) {
      axios
        .put(`/promotion`, { ids: useIds, ...payload })
        .then((response) => {
          const updatedPromoList = this.state.results.map((p) => {
            if (useIds.includes(p.id)) {
              if (payload.hasOwnProperty("status")) {
                const newStatus = this.state.filterStatuses
                  .filter((s) => s.value === payload.status)
                  .shift();
                return {
                  ...p,
                  status: newStatus,
                };
              }
              return { ...p, ...payload };
            }
            return p;
          });

          this.setState({ results: updatedPromoList });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
            results: [],
            error: err,
            count: 0,
            pages: 0,
          });
        });
    }
  };

  onPromotionUpdated = (id, promotion) => {
    const updatedPromoList = this.state.results.map((p) => {
      if (p.id === id) {
        return promotion;
      }
      return p;
    });

    this.setState({ results: updatedPromoList, popup: promotion });
  };

  handlePromotionMutation = (id, promotion) => {
    let updatedPromotion;
    const updatedPromoList = this.state.results.map((p) => {
      if (p.id === id) {
        updatedPromotion = { ...p, ...promotion };
        return updatedPromotion;
      }
      return p;
    });

    this.setState({ results: updatedPromoList, popup: updatedPromotion });
  };

  actionButtons = (status, promotion) => {
    const promotionId = promotion ? promotion.id : undefined;
    const archiveButton = () => {
      if (
        (!promotion && status !== "archived") ||
        (promotion && promotion.status.value !== "archived")
      )
        return (
          <Button
            className="btn-square btn-white"
            disabled={this.state.selected.length < 1}
            onClick={() =>
              this.handlePromotionUpdate({ status: "archived" }, promotionId)
            }
          >
            <TrashIcon />
            <span className="label">Archive</span>
          </Button>
        );
    };
    const unarchiveButton = () => {
      if (
        (!promotion && status === "archived") ||
        (promotion && promotion.status.value === "archived")
      )
        return (
          <Button
            className="btn-square btn-white"
            disabled={this.state.selected.length < 1}
            onClick={() =>
              this.handlePromotionUpdate({ status: "read" }, promotionId)
            }
          >
            <RestoreIcon />
            <span className="label">Unarchive</span>
          </Button>
        );
    };
    const unreadButton = () => {
      if (
        (!promotion && status !== "archived") ||
        (promotion && promotion.status.value === "read")
      )
        return (
          <Button
            className="btn-square btn-white"
            disabled={this.state.selected.length < 1}
            onClick={() =>
              this.handlePromotionUpdate({ status: "unread" }, promotionId)
            }
          >
            <UnreadIcon />
            <span className="label">Mark as Unread</span>
          </Button>
        );
    };
    const readButton = () => {
      if (
        (!promotion && status !== "archived") ||
        (promotion && promotion.status.value === "unread")
      )
        return (
          <Button
            className="btn-square btn-white"
            disabled={this.state.selected.length < 1}
            onClick={() =>
              this.handlePromotionUpdate({ status: "read" }, promotionId)
            }
          >
            <ReadIcon />
            <span className="label">Mark as Read</span>
          </Button>
        );
    };
    const flagButton = () => {
      if (
        (!promotion && status !== "archived") ||
        (promotion && promotion.status.value === "unread")
      )
        return (
          <Button
            className="btn-square btn-white"
            disabled={this.state.selected.length < 1}
            onClick={() =>
              this.handlePromotionUpdate({ flag: true }, promotionId)
            }
          >
            <FlagIcon />
            <span className="label">Flag</span>
          </Button>
        );
    };

    switch (status) {
      default:
        return (
          <div className="action-buttons promotion-actions active-actions">
            {archiveButton()}
            {unarchiveButton()}
            {unreadButton()}
            {readButton()}
            {flagButton()}
          </div>
        );
    }
  };

  ErrorMessage = (error) => {
    if (error.response) {
      // Response Error
      return error.response.data.message;
    } else if (error.request) {
      return "Unable to connect. Please try again later.";
    } else {
      return "Unknown Error.";
    }
  };

  content = () => {
    if (this.state.error) {
      // Error
      return (
        <div className="edit-error form-error error">
          <h1 className="promotion-title">{this.state.error.message}</h1>
          <p>{this.ErrorMessage(this.state.error)}</p>
        </div>
      );
    } else if (this.state.isLoading) {
      return (
        <div className="form-loading edit-loading loading">
          <Spinner />
        </div>
      );
    } else if (this.state.results) {
      if (this.state.results.length > 0) {
        return (
          <>
            <PromotionTable
              data={this.state.results}
              selected={this.state.selected}
              onSelect={this.handleSelect}
              onSelectAll={this.handleSelectAll}
              onUpdate={this.handlePromotionUpdate}
              showPopup={this.showPopup}
            />
            {this.state.pages > 1 && (
              <div className="d-flex flex-row justify-content-end mt-3">
                <FilterDropdown
                  id="promotion-page-limit"
                  name="show"
                  label="View:"
                  onChange={this.handlePageLimitChange}
                  value={this.getPageLimit()}
                >
                  {this.paging.map((paging, i) => {
                    return (
                      <option value={paging.count} key={i}>
                        {paging.label}
                      </option>
                    );
                  })}
                </FilterDropdown>
                <Pagination
                  totalRecords={this.state.count}
                  pageLimit={this.getPageLimit()}
                  pageNeighbours={1}
                  onPageChanged={this.handlePageChange}
                  currentPage={this.getPage()}
                />
              </div>
            )}
          </>
        );
      }

      return (
        <div className="no-results">
          <h2 className="promotion-title">No Promotions</h2>
          <p>You don't have any promotions</p>
        </div>
      );
    }
  };

  render() {
    return (
      <>
        {this.state.popup && (
          <PopupPreview
            promotion={this.state.popup}
            type={this.props.title}
            onClose={this.closePopup}
            onChange={this.onPromotionUpdated}
            onMutate={this.handlePromotionMutation}
            //actionButtons={this.actionButtons}
          >
            <this.props.editor promotion={this.state.popup} />
          </PopupPreview>
        )}
        <header>
          <Row>
            <Col xs={12} sm={6}>
              {this.actionButtons(this.props.status)}
            </Col>
            <Col xs={12} sm={6} className="text-left text-sm-right">
              <Row>
                <Col>
                  <FilterDropdown
                    id="promotion-status"
                    name="status"
                    label="Filter by:"
                    onChange={this.handleStatusChange}
                    value={this.getStatus()}
                  >
                    {this.state.filterStatuses &&
                      this.state.filterStatuses.map((status, i) => {
                        return (
                          <option value={status.value} key={i}>
                            {status.title}
                          </option>
                        );
                      })}
                  </FilterDropdown>
                </Col>
                <Col>
                  <FilterDropdown
                    id="promotion-sort"
                    name="sort-by"
                    label="Sort by:"
                    onChange={this.handleOrderByChange}
                    value={this.getOrderBy()}
                  >
                    {this.sorting.map(function (sort, i) {
                      return (
                        <option value={i} key={i}>
                          {sort.label}
                        </option>
                      );
                    }, this)}
                  </FilterDropdown>
                </Col>
              </Row>
            </Col>
          </Row>
        </header>
        <main>
          <Row>
            <Col>{this.content()}</Col>
          </Row>
        </main>
      </>
    );
  }
}

export default withRouter(PromotionList);
