import React, { Component } from 'react';
import PropTypes from 'prop-types';
import OutsideClickHandler from 'react-outside-click-handler';
import Button from 'react-bootstrap/Button';

class SearchTitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isEdit: false,
      value: props.value || ''
    }
  }

  onOutsideClick() {
    const { value } = this.state;

    if(value.length !== 0 && value !== this.props.value) {
      // this.props.onChange(value)
      this.setState({ isEdit: false });
    } else {
      this.setState({
        value: this.props.value,
        isEdit: false
      });
    }
  }

  render() {
    const { value, isEdit } = this.state;

    return (
      <div className="search-block__title">
        {
          isEdit?
          <OutsideClickHandler
            onOutsideClick={() => this.onOutsideClick()}
          >
            <input
              type="text"
              className="input-control"
              defaultValue={value}
              onChange={(e) => this.setState({value: e.target.value})}
            />
          </OutsideClickHandler>
          :
          <div className="editable-input-control">
            <span className="editable-input-control__label">{value}</span>
            <Button variant="link" className="editable-input-control__button" onClick={() => this.setState({isEdit: true})}>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M3.32,6.16A1.43,1.43,0,0,0,3,7.2V27.63a2.42,2.42,0,0,0,.35,1.05A1.29,1.29,0,0,0,4.37,29H24.8a2.34,2.34,0,0,0,1-.35,1.29,1.29,0,0,0,.35-1.05V19.78a1.4,1.4,0,1,1,2.8,0v7.85a5.08,5.08,0,0,1-1.22,3.15A4.25,4.25,0,0,1,24.62,32H4.37a5,5,0,0,1-3.15-1.22A4.23,4.23,0,0,1,0,27.63V7.2A4.4,4.4,0,0,1,1.22,4.06,4.73,4.73,0,0,1,4.37,2.84h7.68a1.4,1.4,0,1,1,0,2.79H4.37A1.65,1.65,0,0,0,3.32,6.16Z"/><path d="M23.75.39a1.6,1.6,0,0,1,2.09,0l5.77,5.77a1.6,1.6,0,0,1,0,2.09L17.11,22.74a1.28,1.28,0,0,1-1,.35H10.3A1.37,1.37,0,0,1,8.91,21.7V15.93a2.42,2.42,0,0,1,.35-1Zm-12,16.07V20.3h3.84L28.64,7.2,24.8,3.36Z"/></svg>
            </Button>
          </div>
        }
      </div>
    );
  }
}

SearchTitle.propTypes = {
  value: PropTypes.string.isRequired
};

export default SearchTitle;