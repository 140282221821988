import React from "react";
import { Link, generatePath } from "react-router-dom";
import Routes from "routes";
import Avatar from "components/shared/avatar";
import { Row, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";
import { getName } from "utils/user";

class MessagePopup extends React.Component {
  onClickToggleButton(value) {}

  listingLink = (type, slug) => {
    switch (type) {
      case "wanted":
        return generatePath(Routes.wantedDetail, {
          listing: slug,
        });
      case "service":
        return generatePath(Routes.servicesDetail, {
          listing: slug,
        });
      case "sale":
      default:
        return generatePath(Routes.forSaleDetail, {
          listing: slug,
        });
    }
  };

  render() {
    const { inquiry } = this.props;
    return (
      <div className="promotion-popup">
        <div className="popup-inner">
          <div className="popup-modal">
            <Row className="header-row">
              <Col
                xs={12}
                sm={6}
                lg={{ span: 4, order: 1 }}
                className="from-column d-flex item-column mx-n1"
              >
                <div className="from-avatar px-1">
                  <Avatar name={inquiry.name} size={32} />
                </div>
                <div className="from-info px-1">
                  <div className="from-name">
                    <span className="label">From:</span>{" "}
                    <span className="value">{inquiry.name}</span>
                  </div>
                  {inquiry.email && (
                    <div className="from-email">
                      <span className="label">Email:</span>{" "}
                      <a href={`mailto:${inquiry.email}`} className="value">
                        {inquiry.email}
                      </a>
                    </div>
                  )}
                  {inquiry.phone && (
                    <div className="from-phone">
                      <span className="label">Phone:</span>{" "}
                      <a href={`tel:${inquiry.phone}`} className="value">
                        {inquiry.phone}
                      </a>
                    </div>
                  )}
                </div>
              </Col>
              <Col
                xs={12}
                sm={6}
                lg={{ span: 4, order: 3 }}
                className="to-column item-column d-flex mx-n1"
              >
                <div className="to-avatar px-1">
                  <Avatar name={getName(inquiry.listing.account)} size={32} />
                </div>
                <div className="to-info px-1">
                  <div className="to-name">
                    <span className="label">To:</span>{" "}
                    <a href={`mailto:${inquiry.listing.account.email}`}>
                      {getName(inquiry.listing.account)}
                    </a>{" "}
                    {inquiry.listing.company && (
                      <span>
                        at{" "}
                        <Link
                          to={generatePath(Routes.company, {
                            company: inquiry.listing.company.slug,
                          })}
                        >
                          {inquiry.listing.company.name}
                        </Link>
                      </span>
                    )}
                  </div>
                  {inquiry.listing.account.email && (
                    <div className="to-email">
                      <span className="label">Email:</span>{" "}
                      <a
                        href={`mailto:${inquiry.listing.account.email}`}
                        className="value"
                      >
                        {inquiry.listing.account.email}
                      </a>
                    </div>
                  )}
                  {inquiry.listing.account.profile.phone && (
                    <div className="to-phone">
                      <span className="label">Phone:</span>{" "}
                      <a
                        href={`tel:${inquiry.listing.account.profile.phone}`}
                        className="value"
                      >
                        {inquiry.listing.account.profile.phone}
                      </a>
                    </div>
                  )}
                </div>
              </Col>
              <Col
                xs={12}
                md={12}
                lg={{ span: 4, order: 2 }}
                className="listing-column item-column"
              >
                <Row>
                  <Col xs={12} md={6} lg={12} className="listing">
                    <span className="label">Listing:</span>{" "}
                    <Link
                      to={this.listingLink(
                        inquiry.listing.type.value,
                        inquiry.listing.slug
                      )}
                      className="value"
                    >
                      {inquiry.listing.title}
                    </Link>
                  </Col>
                  <Col xs={false} md={6} lg={12} className="time">
                    <span className="value">
                      {moment(inquiry.createdAt).calendar()}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="actions-row">
              <Col className="actions-column">
                {this.props.actionButtons(inquiry.status.value, inquiry)}
              </Col>
            </Row>
            <Row className="popup-content message-row">
              <Col className="message-column item-column">
                <div className="message">{inquiry.message}</div>
              </Col>
            </Row>
            <Row className="close-row">
              <Col className="text-right">
                <Button
                  className="btn btn-primary"
                  onClick={this.props.closePopup}
                >
                  Close
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

MessagePopup.proptype = {
  inquiry: PropTypes.object,
};

export default MessagePopup;
