import React from "react";
import Routes, { createTitle } from "routes";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

import { ReactComponent as AirplaneGrounded } from "images/icons/airplane-grounded.svg";

export default class NotFoundPage extends React.Component {
	componentDidMount() {
		document.title = createTitle("404 Not Found", Routes);
	}

	render() {
		return (
			<section id="app-page-home" className="error-page-content app-not-found not-found-page">
				<header className="not-found-page-hader page-header">
					<Container id="not-found-header-row">
						<Row>
							<Col>
								<h1 className="page-title">
									Oops! <strong>Your Flight Is Delayed.</strong>
								</h1>
								<div className="sub-header">We can’t find what you’re looking for. <strong>Try these destinations instead:</strong></div>
							</Col>
						</Row>
					</Container>
				</header>
				<Container id="not-found-content-row">
					<Row>
            <Col xs={12} sm className="action-buttons-column">
              <div className="action-buttons">
                <Link to={Routes.forSale} className="btn btn-primary btn-wide">Aircraft For Sale</Link>
                <Link to={Routes.wanted} className="btn btn-primary btn-wide">Aircraft Wanted</Link>
                <Link to={Routes.services} className="btn btn-primary btn-wide">Aviation Services</Link>
                <Link to={Routes.home} className="btn btn-primary btn-wide">Homepage</Link>
              </div>
            </Col>
            <Col xs={12} sm>
              <AirplaneGrounded />
            </Col>
          </Row>
				</Container>
			</section>
		);
	}
}
