import axios from "axios";
import apis from "apis";

import {
  SET_DASHBOARD,
  GET_DASHBOARD,
  RESET_DASHBOARD,
} from "./actionTypes.js";

export const getDashboard = () => (dispatch) => {
  dispatch({
    type: GET_DASHBOARD,
  });

  return axios({
    method: "get",
    url: `${apis.dashboard}`,
  })
    .then((response) => {
      const { data } = response;
      dispatch(setDashboard(data));
      return data;
    })
    .catch((error) => {
      throw error;
    });
};

export const setDashboard = (payload) => {
  return {
    type: SET_DASHBOARD,
    payload,
  };
};

export const resetDashboard = () => {
  return {
    type: RESET_DASHBOARD,
  };
};
