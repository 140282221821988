import React from "react";

import InformationBlock from "components/frontend/listings/for-sale/shared/informationBlock";

export default function AuxiliaryPower(props) {
  const { listing } = props;

  const apu = () => {
    if (listing.apu) {
      return listing.apu;
    }
    return <em>Unknown</em>;
  };

  const apuMaintenance = () => {
    if (listing.apuMaintenance) {
      return listing.apuMaintenance.title;
    }
    return <em>Unknown</em>;
  };

  const apuTotalTime = () => {
    if (listing.apuTotalTime) {
      const formattedString = new Intl.NumberFormat("en-us").format(
        listing.apuTotalTime
      );
      return `${formattedString} Hours`;
    }
    return <em>Unknown</em>;
  };

  return (
    (listing.apu || listing.apuMaintenance || listing.apuTotalTime) && (
      <InformationBlock
        name="auxiliarry-power"
        title="Auxiliary Power Unit Details"
      >
        <ul className="for-sale-detail-apu-list detail-apu-list list no-bullets inline">
          {listing.apu && (
            <li>
              <strong>APU Model:</strong> {apu()}
            </li>
          )}
          {listing.apuMaintenance && (
            <li>
              <strong>APU Maintenance Plan:</strong> {apuMaintenance()}
            </li>
          )}
          {listing.apuTotalTime && (
            <li>
              <strong>Total Time:</strong> {apuTotalTime()}
            </li>
          )}
        </ul>
      </InformationBlock>
    )
  );
}
