import React from "react";
import Routes, { createTitle } from "routes";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { ReactComponent as AirplaneGrounded } from "images/icons/airplane-grounded.svg";

export default class BadRequestPage extends React.Component {
  componentDidMount() {
    document.title = createTitle("403 Forbidden", Routes);
    document.getElementById("body").className =
      "bad-request bad-request-page 403 403-page error-page";
  }
  componentWillUnmount() {
    document.getElementById("body").className = "";
  }

  errorMessage = () => {
    const {error} = this.props;
    if(error) {
      if(error.response && error.response.data && error.response.data.message) {
        return error.response.data.message;
      }
    }
    return "The action you are attempting to take is incorrect or not allowed."
  }

  render() {
    return (
      <section
        id="app-page-bad-request"
        className="error-page-content app-bad-request bad-request-page d-flex justify-content-center"
      >
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} sm={12} md={10} lg={9} xl={8}>
              <header className="bad-request-page-hader page-header">
                <h1 className="page-title">
                  Oops! <strong>Invalid Maneuver.</strong>
                </h1>
                <div className="sub-header">
                  <strong>
                    {this.errorMessage()}
                  </strong>
                </div>
              </header>
              <AirplaneGrounded />
              <p className="page-content">
                If you feel you've reached this page in error, please contact
                your company administrator or{" "}
                <a href="mailto:sales@planemover.com?subject=Bad Request Error">
                  sales@planemover.com
                </a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
