import React, { Component } from "react";
import { connect } from "react-redux";
import stage from "./stage";

import { Container, Row, Col } from "react-bootstrap";

import SelectedAdvertise from "./userType";
import WelcomeAdvertise from "./welcome";
import AddCompany from "./addCompany";
import PlanAndBill from "./planAndBill";

import Spinner from "components/shared/spinner";
import Stepper from "components/shared/stepper";

import Invalid from "../verification/invalid";

import { verification } from "actions/user/actions";
import { parseAxiosError } from "utils/errors";

class Index extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStage: stage.advertise,
      currentStagePayload: undefined,
      isLoaded: false,
      error: undefined,
      logo: undefined,
      thumbnail: undefined,
      registration: {
        stage: stage.advertise,
        accountType: {},
        sales: {
          step: 0,
          company: {},
          plan: "",
          billing: {},
        },
      },
    };
  }

  componentDidMount() {
    const { code } = this.props.match.params;
    this.props
      .verification(code)
      .then((res) => {
        const registration = JSON.parse(localStorage.getItem("registration"));

        if (!registration) {
          localStorage.setItem(
            "registration",
            JSON.stringify({
              ...this.state.registration,
              email: res.data.email,
            })
          );
        } else {
          if (registration.stage !== "") {
            this.handleChangeStage(registration.stage);
          }
        }

        // Verification promise returned successfully
        this.setState({
          user: res.data,
          status: res.status,
          isLoaded: true,
          code,
        });
      })
      .catch((err) => {
        // Display error?
        this.setState({
          isLoaded: true,
          error: parseAxiosError(err),
        });
      });
  }

  handleChangeStage = (nextStage, payload = undefined) => {
    this.setState({
      currentStage: nextStage,
      currentStagePayload: payload,
    });
  };

  handleLogoChange = (logo) => {
    this.setState({ logo });
  };

  stepContent() {
    const { code } = this.props.match.params;
    switch (this.state.currentStage) {
      case stage.newCompany:
        return (
          <div className="sales">
            <h2 className="page-title">
              sales soar at <strong>plane mover</strong>
            </h2>
            <Row>
              <Col sm={12} md={4}>
                <Stepper
                  data={["ACCOUNT", "PROFILE", "SUBSCRIPTION"]}
                  currentStep={1}
                />
              </Col>
            </Row>
            <AddCompany
              handleChangeStage={this.handleChangeStage}
              logo={this.state.logo}
              onLogoChange={this.handleLogoChange}
              code={code}
            />
          </div>
        );
      case stage.payment:
        return (
          <div className="sales">
            <h2 className="page-title">
              sales soar at <strong>plane mover</strong>
            </h2>
            <Row>
              <Col sm={12} md={4}>
                <Stepper
                  data={["ACCOUNT", "PROFILE", "SUBSCRIPTION"]}
                  currentStep={2}
                />
              </Col>
            </Row>
            <PlanAndBill
              handleChangeStage={this.handleChangeStage}
              logo={this.state.logo}
              code={code}
            />
          </div>
        );

      case stage.welcome:
        return (
          <WelcomeAdvertise
            handleChangeStage={this.handleChangeStage}
            payload={this.state.currentStagePayload}
          />
        );
      case stage.advertise:
      default:
        return (
          <SelectedAdvertise
            handleChangeStage={this.handleChangeStage}
            code={code}
          />
        );
    }
  }

  render() {
    if (this.state.error) {
      return <Invalid />;
    } else if (this.state.isLoaded) {
      return (
        <Container className="registration">{this.stepContent()}</Container>
      );
    } else {
      return <Spinner />;
    }
  }
}

const keyStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    verification: (code) => dispatch(verification(code)),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(Index);
