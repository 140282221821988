import React from "react";
import axios from "axios";

import { Row, Col } from "react-bootstrap";

import EditableInput from "components/backend/shared/editableInput";
import PromoteCalendar from "components/backend/promotions/shared/calendar";
import FeaturedSlide from "components/frontend/pages/home/featured-slide";
import SingleImageBrickUpload from "components/backend/shared/singleImageBrickUpload";

export default function HomepagePromotionEdit(props) {
  const { promotion, onChange, onMutate } = props;

  const updatePromotion = (value) => {
    if (onChange) onChange(value);
  };

  const updateImage = (image) => {
    // This is a new image. Upload it.
    let formdata = new FormData();
    formdata.append("image", image.file);

    return axios
      .post(`/promotion/${promotion.id}/image`, formdata, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        if (onMutate) onMutate({ images: response.data.data });
      });
  };

  const removeImage = (image) => {
    // Delete the image(s)
    return axios
      .delete(`/promotion/${promotion.id}/image/${image.uid}`)
      .then((response) => {
        if (onMutate) onMutate({ images: undefined });
      });
  };

  return (
    <div className="promotion-edit">
      <div className="promotion-edit-form">
        <Row>
          <Col xs={12} md={8}>
            <EditableInput
              label="Title"
              value={promotion.title || ""}
              onChange={(value) => updatePromotion({ title: value })}
            />
            <EditableInput
              label="Subtitle"
              value={promotion.subtitle || ""}
              onChange={(value) => updatePromotion({ subtitle: value })}
            />
            <SingleImageBrickUpload
              image={
                promotion.images && promotion.images.length > 0
                  ? promotion.images.slice().shift().url
                  : undefined
              }
              onAdd={updateImage}
              onDelete={() => {
                removeImage(promotion.images.slice().shift());
              }}
            />
            <EditableInput
              label="Comments"
              value={promotion.comments || ""}
              onChange={(value) => updatePromotion({ comments: value })}
            />
          </Col>
          <Col xs={12} md={4}>
            <div className="Calendar-div">
              <PromoteCalendar
                startDate={promotion.startDate}
                onChange={(date) => {
                  updatePromotion({ startDate: date });
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="promotion-edit-preview">
        <FeaturedSlide promo={promotion} />
      </div>
    </div>
  );
}
