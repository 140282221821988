import {
  SET_APP_READY,
  OPEN_USER_SIDEBAR,
  CLOSE_USER_SIDEBAR,
  TOGGLE_USER_SIDEBAR,
  OPEN_MOBILE_MENU,
  CLOSE_MOBILE_MENU,
  TOGGLE_MOBILE_MENU,
  SHOW_COOKIES_NOTICE,
  HIDE_COOKIES_NOTICE,
} from "./actionTypes.js";

import Cookies from "universal-cookie";

export const openUserSidebar = () => (dispatch) =>
  dispatch({ type: OPEN_USER_SIDEBAR });

export const closeUserSidebar = () => (dispatch) =>
  dispatch({ type: CLOSE_USER_SIDEBAR });

export const toggleUserSidebar = () => (dispatch) =>
  dispatch({ type: TOGGLE_USER_SIDEBAR });

export const openMobileMenu = () => (dispatch) =>
  dispatch({ type: OPEN_MOBILE_MENU });

export const closeMobileMenu = () => (dispatch) =>
  dispatch({ type: CLOSE_MOBILE_MENU });

export const toggleMobileMenu = () => (dispatch) =>
  dispatch({ type: TOGGLE_MOBILE_MENU });

export const showCookiesNotice = () => (dispatch) => {
  const cookies = new Cookies();

  if (!cookies.get("terms_accepted")) {
    dispatch({ type: SHOW_COOKIES_NOTICE });
  }
};

export const hideCookiesNotice = () => (dispatch) => {
  const expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 1);
  const cookies = new Cookies();
  cookies.set("terms_accepted", "true",{ path: "/" , expires: expirationDate});
  dispatch({ type: HIDE_COOKIES_NOTICE });
};

export function setAppReady() {
  return {
    type: SET_APP_READY,
  };
}
