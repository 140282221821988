const SaveButtonText = (props) => {
  if (props && props.value) {
    switch (props.value) {
      case "published":
      case "pending":
      case "sold":
      case "removed":
      case "deleted":
        return "Update Listing";
      case "draft":
      default:
        return "Save Draft";
    }
  }
  return "Save Draft";
};

export default SaveButtonText;
