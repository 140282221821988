import React from "react";

import InformationBlock from "components/frontend/listings/for-sale/shared/informationBlock";

export default function Maintenance(props) {
  const { listing } = props;

  return (
    listing.maintenance && (
      <InformationBlock name="maintenance" title="Maintenance">
        <p
          dangerouslySetInnerHTML={{ __html: listing.maintenance }}
          className="white-space-pre"
        />
      </InformationBlock>
    )
  );
}
