import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";

import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { openMessageModal, closeMessageModal } from "actions/modal/actions";

import Avatar from "components/shared/avatar";
import EditableInput from "../shared/editableInput";
import SingleImageUpload from "../shared/singleImageUpload";
import RoleRenderer from "components/shared/roleRenderer";

import ToggleSwitch from "components/shared/toggleSwitch";

class ProfileContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      roles: [],
      companies: [],
    };
  }
  componentDidMount() {
    axios.get("/masterData?keys=accountRole").then((response) => {
      const masterData = response.data.rows;
      this.setState({
        roles: masterData.filter((data) => data.key === "accountRole"),
      });
      return true;
    });

    axios.get("/company?limit=-1").then((response) => {
      this.setState({ companies: response.data.data.rows });
    });
  }

  render() {
    const { user, editor, openMessageModal, closeMessageModal } = this.props;

    const isSuperAdmin = () => {
      if (editor.role && editor.role.value) {
        if (editor.role.value === "super-admin") {
          return true;
        }
      }
      return false;
    };

    const filteredRoles = (company) => {
      if (company) {
        return this.state.roles.filter((r) =>
          [
            "company-admin",
            "sales-rep",
            "sales-rep-view",
            "unregistered",
          ].includes(r.value)
        );
      }

      return this.state.roles.filter((r) =>
        ["advertiser", "unregistered"].includes(r.value)
      );
    };

    const handleActiveToggle = (checked) => {
      this.props.onStatusChange && this.props.onStatusChange(checked);
      if (!checked) {
        openMessageModal(
          "All active listings assigned to this user are still live. Please go to My Listings to edit Sales Rep information to reassign inquiry notification emails.",
          () => (
            <>
              <Button
                className="btn btn-white"
                onClick={() => {
                  closeMessageModal();
                }}
              >
                Confirm
              </Button>
            </>
          )
        );
      }
    };

    return (
      <div className="profile">
        <Row>
          <Col xs={12}>
            <div className="subtitle">
              To edit, click the icon to the right of the item.
            </div>
            <RoleRenderer role="super-admin">
              <ToggleSwitch
                onLabel="Active"
                offLabel="Inactive"
                width={120}
                checked={user.status.value === "active"}
                onChange={handleActiveToggle}
              />
            </RoleRenderer>
            <EditableInput
              label="Email"
              value={user.email || ""}
              readOnly={true}
              onChange={() => {}}
            />
            <EditableInput
              label="Name"
              value={user.profile.name || ""}
              onChange={(value) => this.props.onNameChange(value)}
            />
            <EditableInput
              label="Profile Picture"
              renderValue={() => {
                if (user.profile.profileImage) {
                  return (
                    <Avatar
                      name={user.profile.name}
                      src={user.profile.profileImage}
                      size={64}
                    />
                  );
                } else {
                  return <span>Upload a Profile Photo</span>;
                }
              }}
              value={user.profile.profileImage}
              renderInput={({ setEdit, setValue }) => (
                <SingleImageUpload
                  preview={user.profile.profileImage}
                  onUpload={(file) => {
                    this.props.onAvatarChange(file);
                    setValue(file);
                    setEdit(false);
                  }}
                  onCancel={() => {
                    setEdit(false);
                  }}
                />
              )}
              onChange={() => {}}
            />
            <EditableInput
              label="Phone"
              value={user.profile.phone || ""}
              onChange={(value) => this.props.onPhoneChange(value)}
            />

            <RoleRenderer role="super-admin">
              <EditableInput
                readOnly={!isSuperAdmin}
                label="Company"
                value={user.company ? user.company.name : ""}
                onChange={() => {}}
                renderInput={({ setEdit, setValue }) => (
                  <Form.Control
                    as="select"
                    value={user.company ? user.company.slug : ""}
                    onChange={(e) => {
                      const newCompany = this.state.companies
                        .filter((c) => c.slug === e.target.value)
                        .shift();
                      this.props.onCompanyChange(
                        newCompany ? newCompany.slug : "null"
                      );
                      setValue(newCompany ? newCompany.name : "");
                      setEdit(false);
                    }}
                  >
                    <option>No Company</option>
                    {this.state.companies
                      .sort((a, b) => (a.title > b.title ? -1 : 1))
                      .map((opt, i) => (
                        <option key={i} value={opt.slug}>
                          {opt.name}
                        </option>
                      ))}
                  </Form.Control>
                )}
              />
              <EditableInput
                readOnly={!isSuperAdmin}
                label="Account Type"
                value={user.role ? user.role.title : ""}
                onChange={() => {}}
                renderInput={({ setEdit, setValue }) => (
                  <Form.Control
                    as="select"
                    value={user.role ? user.role.value : ""}
                    onChange={(e) => {
                      const newRole = this.state.roles
                        .filter((r) => r.value === e.target.value)
                        .shift();
                      this.props.onRoleChange(newRole.value);
                      setValue(newRole ? newRole.title : "");
                      setEdit(false);
                    }}
                  >
                    <option></option>
                    {filteredRoles(user.company)
                      .sort((a, b) => (a.title > b.title ? 1 : -1))
                      .map((opt, i) => (
                        <option key={i} value={opt.value}>
                          {opt.title}
                        </option>
                      ))}
                  </Form.Control>
                )}
              />
            </RoleRenderer>
          </Col>
        </Row>
      </div>
    );
  }
}

const keyStateToProps = (state) => {
  return {};
};

const keyDispatchToProps = (dispatch) => {
  return {
    openMessageModal: (message, actions) =>
      dispatch(openMessageModal({ message, actions })),
    closeMessageModal: () => dispatch(closeMessageModal()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(ProfileContent);
