import axios from "axios";
import {
  GET_LISTING,
  SET_LISTING,
  SAVE_LISTING,
  UPDATE_LISTING,
  CLEAR_LISTING,
} from "./actionTypes.js";
import { UPDATE_STATUS_LISTING } from './actionTypes';


export const clearListing = () => (dispatch) =>
  dispatch({ type: CLEAR_LISTING });

export const getListing = (type, slug) => (dispatch) => {
  dispatch({
    type: GET_LISTING,
  });

  return axios
    .get(`/manage/type/${type}/slug/${slug}?flat=true`)
    .then((response) => {
      // Flatten the result
      const listing = response.data.data;
      dispatch(setListing(listing));
      return listing;
    });
};

export const setListing = (listing) => {
  return {
    type: SET_LISTING,
    payload: listing,
  };
};

export const updateListing = (name, value) => {
  return {
    type: UPDATE_LISTING,
    payload: { [name]: value },
  };
};

export const saveListing = (type, listing) => (dispatch) => {
  dispatch({
    type: SAVE_LISTING,
  });

  if (listing.slug) {
    // This is an existing listing. Update it...
    return axios
      .put(`/manage/type/${type}/slug/${listing.slug}?flat=true`, listing)
      .then((response) => {
        const { data } = response.data;
        dispatch(setListing(data));
        return data;
      });
  }

  return axios
    .post(`/manage/type/${type}/?flat=true`, listing)
    .then((response) => {
      const { data } = response.data;
      dispatch(setListing(data));
      return data;
    });
};

export const updateStatusListing = (selectedIds, status,modifiedURL) => (dispatch) => {
  dispatch({
    type: UPDATE_STATUS_LISTING,
  });
  if (selectedIds.length !== 0) {
    const requestData = {
      selectedIds: selectedIds,
      status: status,
    };
    console.log("request data", requestData);
    console.log("request data", requestData);
    axios
      .post('/manage/slug/status', requestData)
      .then((response) => {
        console.log("Succsesssssssssssssssss",response);
        console.log("modifiedURL---",modifiedURL);
        window.location.href = modifiedURL;
      })
      .catch((err) => {
        console.log("err");
        return(err);
        // this.setState({
        //   isLoading: false,
        //   error: err,
        // });
      });

  }
 
};



export const listingRedirectCheck = (legacyId) => {
  return axios.get(`/listings/redirect/${legacyId}`).then((res) => {
    const { result } = res.data;
    return result.slug;
  });
};

