export default {
  // MasterData
  masterData: "masterData",

  // Accounts
  register: "accounts/register",
  login: "accounts/login",
  logout: "accounts/logout",
  me: "accounts/me",
  verification: "accounts/verification",
  updatePassword: "accounts/password",
  forgotPassword: "accounts/password/forgot",
  resetPassword: "accounts/password/reset",
  updateAccount: "accounts",
  updateAvatar: "accounts/avatar",
  dashboard: "dashboard",

  // Companies
  company: "company",

  // Plan
  plans: "plans",

  // Notifications
  notifications: "notifications",

  // Email
  email: "email",
};
