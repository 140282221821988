import React, { useState } from "react";

import { ReactComponent as CloseIcon } from "images/icons/close.svg";
import { ReactComponent as AddIcon } from "images/icons/plus.svg";

export default function Pill(props) {
  const [value, setValue] = useState("");
  const [adding, setAdding] = useState(false);

  const saveValue = () => {
    if (value !== undefined && value.trim() !== "" && value !== null) {
      if (props.onAdd) {
        setAdding(true);
        props.onAdd(value);
      }
      setValue("");
      setAdding(false);
    }
  };

  switch (props.type) {
    case "remove":
    case "delete":
    case "close":
      return (
        <div className="pill pill-close pill-delete pill-remove">
          {props.title}
          <CloseIcon
            onClick={() => {
              if (props.onRemove) {
                props.onRemove(props.value);
              }
            }}
          />
        </div>
      );
    case "add":
    case "plus":
    default:
      return (
        <div className="pill pill-add pill-plus">
          <input
            type="text"
            value={value}
            placeholder={props.placeholder}
            onChange={(e) => {
              setValue(e.target.value);
            }}
            onKeyPress={(e) => (e.key === "Enter" ? saveValue() : () => {})}
            readOnly={adding}
          />
          <AddIcon onClick={saveValue} />
        </div>
      );
  }
}
