import React from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Routes from "routes";

// Components for this page
import SearchTabs from "components/frontend/pages/home/search-tabs";
import FeaturedCarousel from "components/frontend/pages/home/featured-carousel";
import SaleLinks from "components/frontend/pages/home/sale-links";
import WantedLinks from "components/frontend/pages/home/wanted-links";
import ServicesLinks from "components/frontend/pages/home/services-links";

// Images
import CoastlineBG from "images/content/homepage/headers/coastline/background.jpg";
import CoastlineMG from "images/content/homepage/headers/coastline/middleground.png";
import CoastlineFG from "images/content/homepage/headers/coastline/foreground.png";
import FallBG from "images/content/homepage/headers/fall/background.jpg";
import FallMG from "images/content/homepage/headers/fall/middleground.png";
import FallFG from "images/content/homepage/headers/fall/foreground.png";
import MountainsBG from "images/content/homepage/headers/mountains/background.jpg";
import MountainsMG from "images/content/homepage/headers/mountains/middleground.png";
import MountainsFG from "images/content/homepage/headers/mountains/foreground.png";

export default class HomePage extends React.Component {
  constructor(props) {
    super(props);

    this.headers = [
      {
        name: "coastline",
        fg: CoastlineFG,
        mg: CoastlineMG,
        bg: CoastlineBG
      },
      {
        name: "fall",
        fg: FallFG,
        mg: FallMG,
        bg: FallBG
      },
      {
        name: "mountains",
        fg: MountainsFG,
        mg: MountainsMG,
        bg: MountainsBG
      }
    ];
    this.state = {
      activeHeader: null
    };
  }

  componentDidMount() {
    document.title = Routes.site;
    document.getElementById("body").className = "home";

    // Pick a random header to display
    if (this.headers.length > 0) {
      let header = this.headers[
        Math.floor(Math.random() * this.headers.length)
      ];
      this.setState({ activeHeader: header });
    }
  }
  componentWillUnmount() {
    document.getElementById("body").className = "";
  }

  HomepageClasses = () => {
    let def = "app-home";
    if (this.state.activeHeader) {
      return def + " " + this.state.activeHeader.name;
    }
    return def;
  };

  HompageBackground = () => {
    if (this.state.activeHeader) {
      return { backgroundImage: "url(" + this.state.activeHeader.bg + ")" };
    }
    return { backgroundImage: "none" };
  };
  HomepageMiddleground = () => {
    if (this.state.activeHeader) {
      let classes = "homepage-middleground " + this.state.activeHeader.name;
      return (
        <img
          src={this.state.activeHeader.mg}
          alt="Homepage Middleground Element"
          className={classes}
        />
      );
    }
    return <></>;
  };
  HomepageForeground = () => {
    if (this.state.activeHeader) {
      let classes = "homepage-foreground " + this.state.activeHeader.name;
      return (
        <img
          src={this.state.activeHeader.fg}
          alt="Homepage Foreground Element"
          className={classes}
        />
      );
    }
    return <></>;
  };

  render() {
    return (
      <section id="app-page-home" className={this.HomepageClasses()}>
        <Container fluid={true}>
          <Row id="home-search-row" style={this.HompageBackground()}>
            <this.HomepageMiddleground />
            <this.HomepageForeground />
            <Container>
              <Row className="justify-content-center">
                <Col
                  id="home-search-pane"
                  xs={12}
                  sm={11}
                  md={10}
                  lg={9}
                  xl={8}
                  className="text-center search-pane"
                >
                  <div
                    id="home-search-pane-content"
                    className="search-pane-content"
                  >
                    <h1 className="search-header">
                      Takeoff <strong>In Style</strong>
                    </h1>
                    <p className="search-subheader">
                      Find your next ride in the sky
                    </p>
                    <SearchTabs />
                  </div>
                </Col>
              </Row>
            </Container>
          </Row>
          <Row id="home-featured-row">
            <Col className="p-0">
              <FeaturedCarousel />
            </Col>
          </Row>
          <Row id="home-sale-row" className="justify-content-center">
            <Col xs={10} sm={9} md={7} lg={6} className="">
              <Row>
                <Col className="section-content text-center">
                  <h3 className="section-header sale-header">
                    Explore Aircraft <strong>For Sale</strong>
                  </h3>
                  <p className="section-subheader sale-subheader">
                    Now boarding five different types of aircraft
                  </p>
                  <SaleLinks />
                </Col>
              </Row>
            </Col>
          </Row>
          <Row id="home-wanted-row">
            <Col xs={12} md={6} className="section-text-column">
              <Row className="justify-content-center">
                <Col xs={12} lg={10} xl={9} className="section-content">
                  <h3 className="section-header wanted-header">
                    Aircraft <strong>Wanted</strong>
                  </h3>
                  <p className="section-subheader wanted-subheader">
                    Hoping to sell your aircraft? Search these wanted listings
                    to see if your make and model is wanted for purchase, lease
                    or trade.
                  </p>
                  <WantedLinks className="d-none d-md-flex justify-content-center" />
                  <p className="section-caption wanted-caption">
                    Looking for a specific plane?{" "}
                    <Link to={Routes.admin}>Post a listing!</Link>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} className="section-image-column">
              <WantedLinks className="d-md-none justify-content-center" />
            </Col>
          </Row>
          <Row id="home-services-row">
            <Col
              xs={{ span: 12, order: 1 }}
              md={{ span: 6, order: 2 }}
              className="section-text-column"
            >
              <Row className="justify-content-center">
                <Col xs={12} lg={10} xl={9} className="section-content">
                  <h3 className="section-header wanted-header">
                    Aircraft <strong>Services</strong>
                  </h3>
                  <p className="section-subheader wanted-subheader">
                    Discover services for all your aviation needs with these
                    providers.
                  </p>
                  <ServicesLinks className="d-none d-md-flex justify-content-center" />
                  <p className="section-caption wanted-caption">
                    Do you serve the aviation industry?{" "}
                    <Link to={Routes.admin}>List your service!</Link>
                  </p>
                </Col>
              </Row>
            </Col>
            <Col
              xs={{ span: 12, order: 2 }}
              md={{ span: 6, order: 1 }}
              className="section-image-column"
            >
              <ServicesLinks className="d-md-none justify-content-center" />
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}
