import React from "react";
import axios from "axios";

import { Link, generatePath } from "react-router-dom";
import Routes from "routes";
import Avatar from "components/shared/avatar";
import { Row, Col, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import moment from "moment";

import { ReactComponent as TrashIcon } from "images/icons/archive.svg";
import { ReactComponent as RestoreIcon } from "images/icons/unarchive.svg";
import { ReactComponent as UnreadIcon } from "images/icons/mail.svg";
import { ReactComponent as ReadIcon } from "images/icons/mail-read.svg";
import { ReactComponent as CloseIcon } from "images/icons/close-circle.svg";

class Popup extends React.Component {
  componentDidMount() {
    this.handlePromotionUpdate({ status: "read" }, this.props.promotion.id);
  }

  listingLink = (type, slug) => {
    switch (type) {
      case "wanted":
        return generatePath(Routes.wantedDetail, {
          listing: slug,
        });
      case "service":
        return generatePath(Routes.servicesDetail, {
          listing: slug,
        });
      case "sale":
      default:
        return generatePath(Routes.forSaleDetail, {
          listing: slug,
        });
    }
  };

  handlePromotionUpdate = (payload, id, close = false) => {
    const { onChange, onClose } = this.props;
    axios
      .put(`/promotion/${id}`, payload)
      .then((response) => {
        if (onChange) onChange(id, response.data.data);
        if (close && onClose) onClose();
      })
      .catch((err) => {
        console.error(err);
        if (close && onClose) onClose();
      });
  };

  handlePromotionMutation = (payload, id) => {
    const { onMutate } = this.props;

    if (onMutate) onMutate(id, payload);
  };

  actionButtons = (status, promotion) => {
    const promotionId = promotion ? promotion.id : undefined;
    const archiveButton = () => {
      if (
        (!promotion && status !== "archived") ||
        (promotion && promotion.status.value !== "archived")
      )
        return (
          <Button
            className="btn-square btn-white btn-sm"
            onClick={() =>
              this.handlePromotionUpdate(
                { status: "archived" },
                promotionId,
                true
              )
            }
          >
            <TrashIcon />
            <span className="label">Archive</span>
          </Button>
        );
    };
    const unarchiveButton = () => {
      if (
        (!promotion && status === "archived") ||
        (promotion && promotion.status.value === "archived")
      )
        return (
          <Button
            className="btn-square btn-white btn-sm"
            onClick={() =>
              this.handlePromotionUpdate({ status: "read" }, promotionId, true)
            }
          >
            <RestoreIcon />
            <span className="label">Unarchive</span>
          </Button>
        );
    };
    const unreadButton = () => {
      if (
        (!promotion && status !== "archived") ||
        (promotion && promotion.status.value === "read")
      )
        return (
          <Button
            className="btn-square btn-white btn-sm"
            onClick={() =>
              this.handlePromotionUpdate(
                { status: "unread" },
                promotionId,
                true
              )
            }
          >
            <UnreadIcon />
            <span className="label">Mark as Unread</span>
          </Button>
        );
    };
    const readButton = () => {
      if (
        (!promotion && status !== "archived") ||
        (promotion && promotion.status.value === "unread")
      )
        return (
          <Button
            className="btn-square btn-white btn-sm"
            onClick={() =>
              this.handlePromotionUpdate({ status: "read" }, promotionId, true)
            }
          >
            <ReadIcon />
            <span className="label">Mark as Read</span>
          </Button>
        );
    };

    switch (status) {
      default:
        return (
          <div className="action-buttons promotion-actions active-actions">
            {archiveButton()}
            {unarchiveButton()}
            {unreadButton()}
            {readButton()}
          </div>
        );
    }
  };

  render() {
    const { type, promotion, children } = this.props;
    return (
      <div className="promotion-popup">
        <div className="popup-inner">
          <div className="popup-modal">
            <header className="popup-header header-row">
              <Row>
                <Col
                  xs={12}
                  sm={6}
                  lg={3}
                  className="from-column d-flex item-column mx-n1"
                >
                  <div className="from-avatar px-1">
                    <Avatar
                      name={promotion.account.profile.name}
                      src={promotion.account.profile.profileImage}
                      size={32}
                    />
                  </div>
                  <div className="from-info px-1">
                    <div className="from-name">
                      <span className="label">From:</span>{" "}
                      <span className="value">
                        {promotion.account.profile.name}
                      </span>
                    </div>
                    <div className="from-listing">
                      <span className="label">Listing:</span>{" "}
                      <Link
                        to={this.listingLink(
                          promotion.listing.type.value,
                          promotion.listing.slug
                        )}
                        className="value"
                      >
                        {promotion.listing.title}
                      </Link>
                    </div>
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={3}
                  className="contact-column item-column"
                >
                  {promotion.account.email && (
                    <div className="from-email">
                      <span className="label">Email:</span>{" "}
                      <a
                        href={`mailto:${promotion.account.email}`}
                        className="value"
                      >
                        {promotion.account.email}
                      </a>
                    </div>
                  )}
                  {promotion.account.profile.phone && (
                    <div className="from-phone">
                      <span className="label">Phone:</span>{" "}
                      <a
                        href={`tel:${promotion.account.profile.phone}`}
                        className="value"
                      >
                        {promotion.account.profile.phone}
                      </a>
                    </div>
                  )}
                </Col>
                <Col xs={12} sm={6} lg={3} className="meta-column item-column">
                  <div className="promotion-type">
                    <span className="label">Promotion Type:</span>{" "}
                    <span className="value">{type}</span>
                  </div>
                  <div className="promotion-time">
                    <span className="label">Received:</span>{" "}
                    <span className="value">
                      {moment(promotion.createdAt).calendar()}
                    </span>
                  </div>
                </Col>
                <Col
                  xs={12}
                  sm={6}
                  lg={3}
                  className="actions-column item-column"
                >
                  {this.actionButtons(promotion.status.value, promotion)}
                </Col>
              </Row>
              <Button
                className="close-button popup-close modal-close"
                onClick={this.props.onClose}
              >
                <CloseIcon />
              </Button>
            </header>
            <main className="popup-content">
              {React.cloneElement(children, {
                onChange: (payload) => {
                  this.handlePromotionUpdate(payload, promotion.id);
                },
                onMutate: (payload) => {
                  this.handlePromotionMutation(payload, promotion.id);
                },
              })}
            </main>
            <footer className="popup-footer footer-row">
              <Row className="close-row">
                <Col className="text-right">
                  <Button
                    className="btn btn-red ml-2"
                    disabled={!promotion.active}
                    onClick={(e) =>
                      this.handlePromotionUpdate({ active: 0 }, promotion.id)
                    }
                  >
                    Deactivate
                  </Button>
                  <Button
                    className="btn btn-green ml-2"
                    disabled={promotion.active}
                    onClick={(e) =>
                      this.handlePromotionUpdate({ active: 1 }, promotion.id)
                    }
                  >
                    Activate
                  </Button>
                </Col>
              </Row>
            </footer>
          </div>
        </div>
      </div>
    );
  }
}

Popup.proptype = {
  type: PropTypes.string,
  promotion: PropTypes.object,
  content: PropTypes.func,
  onClose: PropTypes.func,
};

export default Popup;
