import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import axios from "axios";

import { Row, Col, Button, Form } from "react-bootstrap";
import { openMessageModal, closeMessageModal } from "actions/modal/actions";

import { Formik } from "formik";

import { encrypt } from "utils/crypto";

import RoleRenderer from "components/shared/roleRenderer";
import CreditCardForm from "components/shared/creditCard";
import CreditCardView from "components/shared/creditCard/view";
import CreditCard from "components/shared/creditCard/components/card";

import { parseAxiosError } from "utils/errors";
import { addPayment, deletePayment } from "actions/companies/actions";

import cardType from "utils/cards";

import ProfileBlock from "./profileBlock";

const initialCardState = {
  cardNumber: "",
  cardHolder: "",
  cardMonth: "",
  cardYear: "",
  cardCvv: "",
  isCardFlipped: false,
};

function EditCompanyBillingProfile(props) {
  const {
    company,
    profile,
    onProfileChange,
    openMessageModal,
    closeMessageModal,
    deletePayment,
    addPayment,
  } = props;

  const [payment, setPayment] = useState();
  const [card, setCard] = useState();

  const handleProfileChange = (value) => {
    if (onProfileChange) onProfileChange(profile, value);
  };

  const cardDisplay = (data) => {
    let number;
    switch (data.type) {
      case "discover":
        number = "6011";
        break;
      case "mastercard":
        number = "5111";
        break;
      case "amex":
        number = "3411";
        break;
      case "visa":
      default:
        number = "4111";
        break;
    }

    const expiration = new Date(data.expiration);

    return {
      cardHolder: data.holder,
      cardNumber: `${number} 1111 1111 ${data.lastFour}`,
      cardMonth: (expiration.getMonth() + 1).toString().padStart(2, "0"),
      cardYear: expiration.getFullYear().toString().substr(2, 2),
      cardCvv: 0,
    };
  };

  useEffect(() => {
    if (company && company.slug) {
      axios
        .get(`company/${company.slug}/payment`)
        .then((response) => {
          const { payment } = response.data;
          if (payment) {
            setPayment(payment);
            setCard(cardDisplay(payment));
          }
        })
        .catch((err) => {
          openMessageModal(parseAxiosError(err), () => (
            <Button
              className="btn btn-white"
              onClick={() => {
                closeMessageModal();
              }}
            >
              Okay
            </Button>
          ));
        });
    } else {
      openMessageModal("No Company Provided!", () => (
        <Button
          className="btn btn-white"
          onClick={() => {
            closeMessageModal();
          }}
        >
          Okay
        </Button>
      ));
    }
  }, [openMessageModal, closeMessageModal, company]);

  const createPaymentPayload = (values) => {
    const card = {
      number: values.cardNumber,
      cvv: values.cardCvv,
    };
    const encryptedCard = encrypt(card);

    // Generate encrypted card data
    return {
      type: cardType(values.cardNumber),
      holder: values.cardHolder,
      card: encryptedCard,
      expiration: new Date(values.cardYear, values.cardMonth - 1),
      lastFour: values.cardNumber.replace(/\s/g, "").slice(-4),
    };
  };

  const handleFormSubmit = (values, setSubmitting) => {
    addPayment(company.slug, createPaymentPayload(values))
      .then((payment) => {
        closeMessageModal();
        setPayment(payment);
        setCard(cardDisplay(payment));
      })
      .catch((err) => {
        openMessageModal(
          "Error Adding Card. Please contact an administrator.",
          () => (
            <Button
              className="btn btn-white"
              onClick={() => {
                closeMessageModal();
              }}
            >
              Okay
            </Button>
          )
        );
      });
  };

  const handleAddCardChanged = (evt, setFieldValue) => {
    const { name, value } = evt.target;
    setFieldValue(name, value);
  };

  const addCardModal = () => {
    let cardValid = false;
    openMessageModal(
      <Formik
        initialValues={initialCardState}
        validateOnChange={true}
        validate={(values) => {
          return cardValid;
        }}
        onSubmit={(values, { setSubmitting, validateForm }) => {
          validateForm().then((vals) => {
            handleFormSubmit(values, setSubmitting);
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          validateForm,
          /* and other goodies */
        }) => {
          return (
            <Form
              className="sales-content plan-and-bill"
              onSubmit={handleSubmit}
            >
              <CreditCardForm
                style={{ marginTop: "-100px" }}
                initialState={initialCardState}
                onChange={(e) => handleAddCardChanged(e, setFieldValue)}
                values={values}
                touched={touched}
                errors={errors}
                onValidation={(valid) => {
                  cardValid = valid;
                  validateForm();
                }}
              />
              <Button
                className="btn btn-green mr-3"
                type="submit"
                disabled={isSubmitting || !cardValid}
              >
                Add Card
              </Button>
              <Button
                className="btn btn-white"
                onClick={() => {
                  closeMessageModal();
                }}
              >
                Cancel
              </Button>
            </Form>
          );
        }}
      </Formik>,
      () => <></>
    );
  };

  const removeCardModal = () => {
    openMessageModal(
      "Are you sure you want to remove the billing card used with this account?",
      () => (
        <>
          <Button
            className="btn btn-green"
            onClick={() => {
              deleteCard();
            }}
          >
            Yes, Remove
          </Button>
          <Button
            className="btn btn-white"
            onClick={() => {
              closeMessageModal();
            }}
          >
            No, Cancel
          </Button>
        </>
      )
    );
  };

  const deleteCard = () => {
    deletePayment(company.slug)
      .then(() => {
        setCard(undefined);
        setPayment(undefined);
        closeMessageModal();
      })
      .catch((err) => {
        openMessageModal(
          "Error Deleting Card. Please contact an administrator.",
          () => (
            <Button
              className="btn btn-white"
              onClick={() => {
                closeMessageModal();
              }}
            >
              Okay
            </Button>
          )
        );
      });
  };

  return (
    <ProfileBlock
      title="Public Information"
      profile={profile}
      onChange={handleProfileChange}
      before={() => {
        return <h3>Billing Profile (Only Visible to Plane Mover staff)</h3>;
      }}
      after={(profile, handleValueChange) => {
        return (
          <>
            <Row className="my-5">
              <Col>
                <h3>Payment Details</h3>
                <Row>
                  <Col className="pt-3">
                    {!card && <Button onClick={addCardModal}>Add Card</Button>}
                    {card && (
                      <>
                        <CreditCard
                          style={{ marginLeft: 0 }}
                          values={card}
                          isCardFlipped={false}
                        />
                        <Button onClick={removeCardModal} className="btn mr-3">
                          Delete Card
                        </Button>
                        <RoleRenderer role="super-admin">
                          <CreditCardView card={payment} />
                        </RoleRenderer>
                      </>
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="my-5 d-none">
              <Col>
                <h3>Subscription</h3>
                SUBSCRIPTION DATA HERE
              </Col>
            </Row>
          </>
        );
      }}
    />
  );
}

const keyStateToProps = (state) => {
  return {
    userInfo: state.user,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    openMessageModal: (message, actions) =>
      dispatch(openMessageModal({ message, actions })),
    closeMessageModal: () => dispatch(closeMessageModal()),
    addPayment: (slug, payment) => dispatch(addPayment(slug, payment)),
    deletePayment: (slug) => dispatch(deletePayment(slug)),
  };
};

export default connect(
  keyStateToProps,
  keyDispatchToProps
)(EditCompanyBillingProfile);
