import React, { Component } from 'react';
import PropTypes from 'prop-types';

import SearchBlock from './searchBlock';

class SearchBlockList extends Component {
  render() {
    const { data } = this.props;

    return (
      <div className="search-block-list">
        {
          data.map((item, i) => <SearchBlock key={i} />)
        }
      </div>
    );
  }
}

SearchBlockList.propTypes = {
  data: PropTypes.array.isRequired
};

export default SearchBlockList;