import React from "react";

import InformationBlock from "components/frontend/listings/for-sale/shared/informationBlock";

export default function AdditionalFeatures(props) {
  const { listing } = props;

  if (listing.additional) {
    return (
      <InformationBlock
        name="additional-features"
        title="Additional Equipment/Features"
      >
        <p dangerouslySetInnerHTML={{ __html: listing.additional }} className="white-space-pre" />
        
      </InformationBlock>
    );
  }

  return <></>;
}
