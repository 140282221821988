import React, { useEffect } from "react";
import { connect } from "react-redux";

import { Form, Row, Col } from "react-bootstrap";
import { withFormik } from "formik";
import { object, string } from "yup";

import { getCountries, getStates } from "actions/masterData/actions";

const schema = object().shape({
  billingName: string().required("Billing Name is required"),
  billingPhone: string().required("Billing Phone is required"),
  billingAddress: string().required("Billing Mailing Address is required"),
  billingCity: string().required("City is required"),
  billingZip: string().required("Zip is required"),
  billingCountry: string().required("Country is required"),
  billingState: string().when("billingCountry", {
    is: (f) => f === "US",
    then: string().required("State is required"),
  }),
});

function BillingForm(props) {
  const { getCountries, countries, getStates, states, onChange } = props;
  const { values, errors, handleBlur } = props;

  useEffect(() => {
    getCountries();
    getStates();
  }, [getCountries, getStates]);

  const handleFieldChange = (e) => {
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <div className="billing billing-form">
      <h3 className="sub-header">Billing Information</h3>
      <p>Please provide the payment information for this account.</p>
      <Row>
        <Col sm={12} lg={6}>
          <Form.Group controlId="billingName">
            <Form.Label>Billing Name:</Form.Label>
            <Form.Control
              type="text"
              name="billingName"
              value={values.billingName || ""}
              onBlur={handleBlur}
              onChange={handleFieldChange}
              isInvalid={!!errors.billingName}
              className="input-long"
            />
            <Form.Control.Feedback type="invalid">
              {errors.billingName}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="billingPhone">
            <Form.Label>Billing Phone:</Form.Label>
            <Form.Control
              type="text"
              name="billingPhone"
              value={values.billingPhone || ""}
              onBlur={handleBlur}
              onChange={handleFieldChange}
              isInvalid={!!errors.billingPhone}
              className="input-short"
            />
            <Form.Control.Feedback type="invalid">
              {errors.billingPhone}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="billingAddress">
            <Form.Label>Billing Mailing Address:</Form.Label>
            <Form.Control
              type="text"
              name="billingAddress"
              value={values.billingAddress || ""}
              onBlur={handleBlur}
              onChange={handleFieldChange}
              isInvalid={!!errors.billingAddress}
              className="input-long"
            />
            <Form.Control.Feedback type="invalid">
              {errors.billingAddress}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="billingCity">
            <Form.Label>City:</Form.Label>
            <Form.Control
              type="text"
              name="billingCity"
              value={values.billingCity || ""}
              onBlur={handleBlur}
              onChange={handleFieldChange}
              isInvalid={!!errors.billingCity}
              className="input-long"
            />
            <Form.Control.Feedback type="invalid">
              {errors.billingCity}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="billingZip">
            <Form.Label>Zip Code/Postal Code:</Form.Label>
            <Form.Control
              type="text"
              name="billingZip"
              value={values.billingZip || ""}
              onBlur={handleBlur}
              onChange={handleFieldChange}
              isInvalid={!!errors.billingZip}
              className="input-short"
            />
            <Form.Control.Feedback type="invalid">
              {errors.billingZip}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="billingCountry">
            <Form.Label>Country:</Form.Label>
            <Form.Control
              name="billingCountry"
              value={values.billingCountry || ""}
              onBlur={handleBlur}
              onChange={handleFieldChange}
              isInvalid={!!errors.billingCountry}
              as="select"
              className="input-short"
            >
              <option value="">Select Country</option>
              {countries.map((country, key) => (
                <option key={key} value={country.value}>
                  {country.title}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              {errors.billingCountry}
            </Form.Control.Feedback>
          </Form.Group>
          {values.billingCountry && values.billingCountry === "US" && (
            <Form.Group controlId="billingState">
              <Form.Label>State/Province:</Form.Label>
              <Form.Control
                name="billingState"
                value={values.billingState || ""}
                onBlur={handleBlur}
                onChange={handleFieldChange}
                isInvalid={!!errors.billingState}
                as="select"
                className="input-short"
              >
                <option value="">Select State</option>
                {states.map((state, key) => (
                  <option key={key} value={state.value}>
                    {state.title}
                  </option>
                ))}
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {errors.billingState}
              </Form.Control.Feedback>
            </Form.Group>
          )}
          <Form.Group controlId="billingEmail">
            <Form.Label>Additional Email:</Form.Label>
            <Form.Control
              type="text"
              name="billingEmail"
              value={values.billingEmail || ""}
              onBlur={handleBlur}
              onChange={handleFieldChange}
              isInvalid={!!errors.billingEmail}
              className="input-long"
            />
            <Form.Control.Feedback type="invalid">
              {errors.billingEmail}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
}

const keyStateToProps = (state) => {
  return {
    countries: state.masterData.countries,
    states: state.masterData.states,
  };
};

const keyDispatchToProps = (dispatch) => {
  return {
    getCountries: () => dispatch(getCountries()),
    getStates: () => dispatch(getStates()),
  };
};

const BillingWithFormik = withFormik({
  mapPropsToValues: (props) => {
    return props.values;
  },
  validationSchema: schema,
  enableReinitialize: true,
  validateOnBlur: true,
  validateOnMount: true,
  validate: (values, props) => {
    schema.isValid(values).then((valid) => {
      if (props.onValidation) {
        props.onValidation(valid);
      }
    });
  },
  mapPropsToErrors: (props) => {
    return props.errors;
  },
})(BillingForm);

export default connect(keyStateToProps, keyDispatchToProps)(BillingWithFormik);
