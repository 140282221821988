import React, { useState } from "react";
import { connect } from "react-redux";
import { Form, Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import { Link } from "react-router-dom";

import { updateAccount, finalizeAccount } from "actions/user/actions";
import { registerCompany, addLogo } from "actions/companies/actions";
import { openMessageModal, closeMessageModal } from "actions/modal/actions";

import stage from "../stage";
import Plan from "./plan/plan";
import Billing from "./billing";

import CreditCardForm from "components/shared/creditCard";
import cardType from "utils/cards";
import routes from "routes";

import { encrypt } from "utils/crypto";
import { parseAxiosError } from "utils/errors";
  
const initialCardState = {
  cardNumber: "",
  cardHolder: "",
  cardMonth: "",
  cardYear: "",
  cardCvv: "",
  isCardFlipped: false,
};

function PlanAndBill(props) {
  const {
    code,
    logo,
    handleChangeStage,
    openMessageModal,
    finalizeAccount,
    addLogo,
    registerCompany,
  } = props;

  const [needsBilling, setNeedsBilling] = useState(false);
  const [billingValid, setBillingValid] = useState(false);
  const [cardValid, setCardValid] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [triggerValidation, setTriggerValidation] = useState();

  const getPlan = () => {
    const registration = JSON.parse(localStorage.getItem("registration"));
    if (registration && registration.sales && registration.sales.plan)
      return registration.sales.plan;
    return undefined;
  };

  const getAccountType = () => {
    const registration = JSON.parse(localStorage.getItem("registration"));
    if (
      registration &&
      registration.accountType &&
      registration.accountType.companyType
    )
      return registration.accountType.companyType;
    return undefined;
  };

  const ifNeedsBilling = () => {
    return getPlan() !== "free";
  };

  const handleGoToBack = (values) => {
    let registration = JSON.parse(localStorage.getItem("registration"));
    registration.sales.billing = values;
    registration.sales.step--;
    if (registration.accountType.companyType !== "new") {
      registration.stage = stage.advertise;
      localStorage.setItem("registration", JSON.stringify(registration));
      return handleChangeStage(stage.advertise);
    } else {
      localStorage.setItem("registration", JSON.stringify(registration));
      return handleChangeStage(stage.newCompany);
    }
  };

  const createPaymentPayload = () => {
    const registration = JSON.parse(localStorage.getItem("registration"));

    const card = {
      number: registration.sales.billing.cardNumber,
      cvv: registration.sales.billing.cardCvv,
    };
    const encryptedCard = encrypt(card);

    // Generate encrypted card data
    return {
      type: cardType(registration.sales.billing.cardNumber),
      holder: registration.sales.billing.cardHolder,
      card: encryptedCard,
      expiration: new Date(
        registration.sales.billing.cardYear,
        registration.sales.billing.cardMonth - 1
      ),
      lastFour: registration.sales.billing.cardNumber
        .replace(/\s/g, "")
        .slice(-4),
    };
  };

  const handleFinalizeRegistration = () => {
    const registration = JSON.parse(localStorage.getItem("registration"));

    if (getAccountType() === "individual") {
      try {
        // This is an individual Account.
        const accountPayload = {
          role: "advertiser",
          plan: registration.sales.plan,
          profile: {
            heard: Number(registration.sales.company.heard),
            heardOther: registration.sales.company.heardOther,
          },
        };

        if (needsBilling) {
          accountPayload.payment = createPaymentPayload();
        }
        props
          .finalizeAccount(code, accountPayload)
          .then((data) => {
            localStorage.removeItem("registration");
            handleChangeStage(stage.welcome, data);
          })
          .catch((err) => {
            console.error(err);
            console.error(parseAxiosError(err));
            openMessageModal(parseAxiosError(err));
          });
      } catch (ex) {
        if (openMessageModal) {
          openMessageModal(
            `Error Preparing Account Registration Data: ${ex.message}.`
          );
        }
      }
    } else {
      try {
        const companyPayload = {
          name: registration.sales.company.privateCompanyName,
          website: registration.sales.company.website,
          plan: registration.sales.plan,
          profiles: [
            {
              type: "public",
              name: registration.sales.company.publicCompanyName || null,
              email:
                registration.sales.company.publicCompanyEmail ||
                registration.sales.company.privateCompanyEmail,
              phone: registration.sales.company.publicCompanyPhone || null,
              fax: registration.sales.company.publicCompanyFax || null,
              address1:
                registration.sales.company.publicCompanyAddress1 || null,
              address2:
                registration.sales.company.publicCompanyAddress2 || null,
              city: registration.sales.company.publicCompanyCity || null,
              zip: registration.sales.company.publicCompanyZip || null,
              state: registration.sales.company.publicCompanyState || null,
              country: registration.sales.company.publicCompanyCountry || null,
            },
            {
              type: "private",
              name: registration.sales.company.privateCompanyName,
              email: registration.sales.company.privateCompanyEmail,
              phone: registration.sales.company.privateCompanyPhone,
              fax: registration.sales.company.privateCompanyFax || null,
              address1: registration.sales.company.privateCompanyAddress1,
              address2:
                registration.sales.company.privateCompanyAddress2 || null,
              city: registration.sales.company.privateCompanyCity,
              zip: registration.sales.company.privateCompanyZip,
              state: registration.sales.company.privateCompanyState,
              country: registration.sales.company.privateCompanyCountry,
            },
          ],
        };

        //Next, add Billing
        if (needsBilling) {
          companyPayload.profiles.push({
            type: "billing",
            contact: registration.sales.company.billingName,
            email: registration.sales.company.billingEmail,
            phone: registration.sales.company.billingPhone,
            address1: registration.sales.company.billingAddress,
            city: registration.sales.company.billingCity,
            zip: registration.sales.company.billingZip,
            state: registration.sales.company.billingState,
            country: registration.sales.company.billingCountry,
          });

          companyPayload.payment = createPaymentPayload();
        }

        return registerCompany(companyPayload, code)
          .then((company) => {
            if (logo) {
              // Upload logo
              return addLogo(company.slug, logo.file, code)
                .then((res) => {
                  return company;
                })
                .catch((err) => {});
            }
            return company;
          })
          .then((company) => {
            // Update user account
            const accountPayload = {
              company: company.slug,
              role: "company-admin",
              profile: {
                heard: Number(registration.sales.company.heard),
                heardOther: registration.sales.company.heardOther,
              },
            };
            return finalizeAccount(code, accountPayload)
              .then((account) => {
                //localStorage.removeItem("registration");
                handleChangeStage(stage.welcome, account);
              })
              .catch((err) => {
                console.error(`Error occured when updating user account!`);
                console.error(err);
              });
          })
          .catch((err) => {
            console.error(`Error occured when registering company!`);
            console.error(err);
          });
      } catch (ex) {
        if (openMessageModal) {
          openMessageModal(
            `Error Preparing Company Registration Data: ${ex.message}.`
          );
        }
      }
    }
  };

  const handleFormSubmit = (values, setSubmitting) => {
    let registration = JSON.parse(localStorage.getItem("registration"));
    registration.sales.billing = values;
    localStorage.setItem("registration", JSON.stringify(registration));

    handleFinalizeRegistration();
  };

  const handlePlanChange = (values) => {
    //setPlan(plan);
    const registration = JSON.parse(localStorage.getItem("registration"));
    if (values && values.plan) {
      registration.sales.plan = values.plan;

      if (values.plan === "free") {
        setFormValid(true);
      }

      localStorage.setItem("registration", JSON.stringify(registration));
      setNeedsBilling(ifNeedsBilling());
    }
  };

  const handleCardChange = (evt, setFieldValue) => {
    const { name, value } = evt.target;
    setFieldValue(name, value);
  };

  const handleBillingChange = (evt, setFieldValue) => {
    const { name, value } = evt.target;
    setFieldValue(name, value);
  };

  const registration = JSON.parse(localStorage.getItem("registration"));

  return (
    <Formik
      initialValues={registration.sales.billing}
      validateOnMount={true}
      validateOnChange={false}
      validate={(values) => {
        if (ifNeedsBilling()) {
          setTriggerValidation(Date.now);
          if (getAccountType() === "individual") {
            setFormValid(cardValid);
            return cardValid;
          } else {
            setFormValid(billingValid && cardValid);
            return billingValid && cardValid;
          }
        }
        return true;
      }}
      onSubmit={(values, { setSubmitting, validateForm }) => {
        validateForm().then((vals) => {
          if (formValid) {
            handleFormSubmit(values, setSubmitting);
            // setTimeout(() => {
            //   alert(JSON.stringify(values, null, 2));
            //   setSubmitting(false);
            // }, 400);
          }
        });
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        validateForm,
        /* and other goodies */
      }) => {
        return (
          <Form className="sales-content plan-and-bill" onSubmit={handleSubmit}>
            <Plan onChange={handlePlanChange} plan={getPlan()} />
            {needsBilling ? (
              <div className="mt-5">
                {getAccountType() !== "individual" ? (
                  <Billing
                    onChange={(e) => handleBillingChange(e, setFieldValue)}
                    values={values}
                    touched={touched}
                    errors={errors}
                    onValidation={(valid) => {
                      setBillingValid(valid);
                      validateForm();
                    }}
                    triggerValidation={triggerValidation}
                  />
                ) : (
                  <></>
                )}

                <Row className="credit-card-form-row mt-5">
                  <Col>
                    <h3 className="sub-header">Payment Information</h3>
                    <Form.Group>
                      <CreditCardForm
                        style={{ float: "left" }}
                        initialState={initialCardState}
                        onChange={(e) => handleCardChange(e, setFieldValue)}
                        values={values}
                        touched={touched}
                        errors={errors}
                        onValidation={(valid) => {
                          setCardValid(valid);
                          validateForm();
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <p className="billing-footer">
                  <span>By clicking continue you agree to our </span>
                  <Link
                    to={routes.terms}
                    className="billing-footer-link"
                    target="_blank"
                  >
                    Terms
                  </Link>
                  <span> and </span>
                  <Link
                    to={routes.privacy}
                    className="billing-footer-link"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                  <span>
                    . Payment will be processed automatically on a monthly
                    basis.{" "}
                  </span>
                </p>
                <div className="buttons mt-5">
                  <Button
                    className="btn-green back-button"
                    onClick={(e) => {
                      handleGoToBack(values);
                    }}
                  >
                    Back
                  </Button>
                  <Button
                    className="btn-green continue-button"
                    type="submit"
                    disabled={isSubmitting || !formValid}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            ) : (
              <div className="buttons mt-5">
                <Button
                  className="btn-green back-button"
                  onClick={(e) => {
                    handleGoToBack(values);
                  }}
                >
                  Back
                </Button>
                <Button
                  className="btn-green continue-button"
                  onClick={handleFinalizeRegistration}
                  disabled={!getPlan()}
                >
                  Continue
                </Button>
              </div>
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

const keyStateToProps = (state) => {
  return {};
};

const keyDispatchToProps = (dispatch) => {
  return {
    finalizeAccount: (code, accountInfo) =>
      dispatch(finalizeAccount(code, accountInfo)),
    updateAccount: (accountInfo) => dispatch(updateAccount(accountInfo)),
    registerCompany: (company, code) =>
      dispatch(registerCompany(company, code)),
    addLogo: (slug, logo, code) => dispatch(addLogo(slug, logo, code)),
    openMessageModal: (message, actions) =>
      dispatch(openMessageModal({ message, actions })),
    closeMessageModal: () => dispatch(closeMessageModal()),
  };
};

export default connect(keyStateToProps, keyDispatchToProps)(PlanAndBill);
