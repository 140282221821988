export function parseAxiosError(error) {
  if (
    error.response &&
    error.response.status &&
    error.response.data &&
    error.response.data.message
  ) {
    // Request made and server responded
    return `Response Error: ${error.response.data.message} (${error.response.status} ${error.response.statusText})`;
  } else if (
    error.request &&
    error.request.status &&
    error.request.data &&
    error.request.data.message
  ) {
    // The request was made but no response was received
    return `Request Error: ${error.request.data.message} (${error.request.status} ${error.request.statusText})`;
  } else if (error.message) {
    // Something happened in setting up the request that triggered an Error
    return `Unknown Error: ${error.message}`;
  } else {
    return `Unknown Error`;
  }
}
